import { api } from '@/helpers/api';
import { removeNamespaces, saveFile, showMessage } from '@/helpers/utils';
import * as types from '@/store/types';
import NProgress from 'nprogress/nprogress';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.STATISTICS_FETCH]({ commit }, {
    from,
    to,
    dateRangeKind,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('startDate', from);
      params.append('endDate', to);
      params.append('dateRangeKind', dateRangeKind);

      const {
        data: {
          common: {
            consultationsCommonStatistics: statisticsConsultations,
            ...statisticsConsultationsCommon
          },
          serviceLevel: {
            serviceLevels: statisticsService,
            ...statisticsServiceCommon
          },
        },
      } = await api.get('v2/consultations/statistics', {
        params,
      });

      commit(localTypes.STATISTICS_CONSULTATIONS_COMMON_SET, statisticsConsultationsCommon);
      commit(localTypes.STATISTICS_CONSULTATIONS_SET, statisticsConsultations);
      commit(localTypes.STATISTICS_COMMON_SERVICES_SET, statisticsServiceCommon);
      commit(localTypes.STATISTICS_SERVICE_SET, statisticsService);
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось загрузить статистику' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STATISTICS_UNLOAD_FETCH](ctx, {
    dateFrom,
    dateTo,
    reportDateType,
    // reportPartTypes,
    medicalInsuranceType,
    regions,
    insuranceProgramms,
    clinicNetworks,
    clinics,
  }) {
    NProgress.start();
    try {
      const params = new URLSearchParams();

      params.append('startDate', dateFrom);
      params.append('endDate', dateTo);

      if (medicalInsuranceType !== null) {
        params.append('medicalInsuranceType', medicalInsuranceType);
      }

      if (regions) {
        regions.forEach((region) => {
          params.append('regionIds', region);
        });
      }

      if (insuranceProgramms) {
        insuranceProgramms.forEach((program) => {
          params.append('insuranceProgramIds', program);
        });
      }

      clinicNetworks.forEach((network) => {
        params.append('clinicNetworkIds', network);
      });

      clinics.forEach((clinic) => {
        params.append('clinicIds', clinic);
      });

      params.append('reportDateType', reportDateType);

      // reportPartTypes.forEach((partType) => {
      //   params.append('reportPartTypes', partType);
      // });

      const { data } = await api.post('v2/consultations/export', null, {
        params,
        responseType: 'blob',
      });

      saveFile(data, { name: `Выгрузка ${dateFrom} - ${dateTo}.xlsx` });
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось выгрузить статистику' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CHECKUP_EXPORT](ctx, exportData) {
    NProgress.start();

    try {
      const { data } = await api.post('v2/appeal/checkups/export', exportData, {
        // params,
        responseType: 'blob',
      });

      saveFile(data, { name: 'Экспорт чекапов.xlsx' });
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось экпортировать чекапы' });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.STATISTICS_KTG_UNLOAD_FETCH](ctx, {
    startDate,
    endDate,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('startDate', startDate);
      params.append('endDate', endDate);

      const { data } = await api.post('v2/consultations/reportKTG', null, {
        params,
        responseType: 'blob',
      });

      saveFile(data, {
        name: `Выгрузка КТГ с ${startDate} по ${endDate} числа.xlsx`,
        open: true,
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STATISTICS_TMK_UNLOAD_FETCH](ctx, {
    startDate,
    endDate,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      params.append('startDate', startDate);
      params.append('endDate', endDate);
      params.append('medicalInsuranceType', '2');
      params.append('reportDateType', '2');

      const { data } = await api.get('v2/consultations/exportTMKReport', {
        params,
        responseType: 'blob',
      });

      saveFile(data, {
        name: `Выгрузка ТМК с ${startDate} по ${endDate} числа.xlsx`,
        open: true,
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
