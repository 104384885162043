<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <div
        v-for="item in 8"
        :key="item"
        class="mb-2"
      >
        <b-skeleton
          width="25%"
          class="mb-2"
        />
        <b-skeleton type="input" />
      </div>
    </template>
    <template v-else>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Изображение
        </div>

        <!-- @input="changeLogoName" -->
        <b-form-file
          v-model="$v.fileHero.$model"
          :state="checkValidation ? !$v.fileHero.$error : null"
          placeholder="Выберите изображение по клику, или перетащите"
          drop-placeholder="Поместите изображение в данное поле"
          @input="changeImage"
        />

        <div
          v-if="$v.fileHero && $v.fileHero.$error"
          class="mt-1 text-danger small"
        >
          {{ errorsValidation.fileHero[0] }}
        </div>
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Тип банера
        </div>

        <b-form-select
          v-model="$v.form.type.$model"
          :state="checkValidation ? form.type !== null : null"
          :options="BANNER_TYPE"
          text-field="title"
          value-field="id"
          size="sm"
          @change="resetEntityId"
        />

        <div
          v-if="$v.form.type.$error"
          class="mt-1 text-danger small"
        >
          {{ errorsValidation.type[0] }}
        </div>
      </div>
      <div
        v-if="form.type === 2"
        class="mb-2"
      >
        <b-input
          v-model="form.entityId"
          placeholder="Введите сслыку"
          size="sm"
        />
      </div>
      <div
        v-if="form.type === 10"
        class="mb-2"
      >
        <base-async-select
          v-model="selectedDoctor"
          :fetch-function="fetchDoctors"
          class="crm-form-field crm-form-field-base-select"
          placeholder="Выберите врача"
          server-paginated
          loading-title="Загрузка врачей"
          no-options-title="Врачи не найдены"
          @change="changeDoctor"
        />
      </div>
      <div
        v-if="form.type === 11"
        class="mb-2"
      >
        <v-select
          v-model="selectedSpecialization"
          :options="specializations"
          placeholder="Выберите специализацию"
          label="name"
          :clearable="true"
          class="crm-select"
          @input="changeSpecialization"
        />
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Категория банера
        </div>

        <b-form-select
          v-model="$v.form.category.$model"
          :state="checkValidation ? form.category !== null : null"
          :options="BANNER_CATEGORY"
          text-field="title"
          value-field="id"
          size="sm"
        />

        <div
          v-if="$v.form.category.$error"
          class="mt-1 text-danger small"
        >
          {{ errorsValidation.category[0] }}
        </div>
      </div>
      <!-- <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Лого
        </div>

        <b-input
          v-model="form.logo"
          placeholder="Ссылка на логотип"
        />
      </div> -->
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Устройство
        </div>

        <b-form-select
          v-model="form.clientType"
          :options="BANNER_CLIENT_TYPE"
          text-field="title"
          value-field="id"
          size="sm"
        />
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Стиль баннера
        </div>

        <b-form-select
          v-model="form.style"
          :options="BANNER_STYLE_INFO"
          text-field="title"
          value-field="id"
          size="sm"
        />
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Заголовок
        </div>

        <b-form-textarea
          v-model.trim="form.title"
          placeholder="Заголовок"
          rows="3"
          size="sm"
        />
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Текст
        </div>

        <b-form-textarea
          v-model.trim="form.text"
          placeholder="Текст"
          rows="3"
          size="sm"
        />
      </div>
      <div
        v-if="!isMobile"
        class="mb-2"
      >
        <div class="mb-1 font-weight-bold">
          Текст кнопки
        </div>

        <b-input
          v-model.trim="form.buttonText"
          placeholder="Текст кнопки"
        />
      </div>
      <div class="mb-2">
        <div class="mb-1 font-weight-bold">
          Позиция банера
        </div>

        <b-input
          v-model.trim="form.position"
          type="number"
          placeholder="Позиция банера"
        />
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <!-- <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button> -->
      <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="openPreview"
      >
        Превью
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { BaseAsyncSelect } from '@/components/base';
import { required } from 'vuelidate/lib/validators';
import { showMessage } from '@/helpers/utils';
import {
  BANNER_TYPE,
  BANNER_CATEGORY,
  BANNER_CLIENT_TYPE,
  BANNER_STYLE_INFO,
} from '@/helpers/consts';
import { validationMixin } from 'vuelidate';

export default {
  name: 'EditBannerModal',
  components: {
    BaseAsyncSelect,
  },
  mixins: [validationMixin],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Создание баннера',
    },
    propsData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isOpen: true,
    isLoading: false,
    isSaving: false,
    checkValidation: false,
    BANNER_TYPE,
    BANNER_CATEGORY,
    BANNER_CLIENT_TYPE,
    BANNER_STYLE_INFO,
    fileHero: null,
    imagePreview: null,
    form: {
      id: null,
      type: null,
      category: null,
      clientType: null,
      title: null,
      text: null,
      entityId: null,
      // image: null,
      buttonText: null,
      style: null,
      position: 0,
    },
    selectedDoctor: null,
    selectedSpecialization: null,
    specializations: [],
  }),
  validations() {
    const validations = {
      form: {
        type: { required },
        category: { required },
      },
    };
    validations.fileHero = {
      required: !this.isEdit ? required : true,
    };
    return validations;
  },
  computed: {
    isEdit() {
      return Object.keys(this.propsData).length;
    },
    errorsValidation() {
      const errors = {};

      errors.fileHero = [];
      if (!this.$v.fileHero.required) {
        errors.fileHero.push('Выберите файл');
      }

      errors.type = [];
      if (!this.$v.form.type.required) {
        errors.type.push('Выберите тип');
      }

      errors.category = [];
      if (!this.$v.form.category.required) {
        errors.category.push('Выберите категорию');
      }

      return errors;
    },
    selectedClientType() {
      return BANNER_CLIENT_TYPE.find((item) => this.form.clientType === item.id);
    },
    isMobile() {
      if (this.selectedClientType) return this.selectedClientType.isMobile;

      return false;
    },
  },

  async created() {
    this.isLoading = true;

    try {
      this.specializations = await this.fetchSpecializations('');

      if (this.isEdit) {
        this.form = JSON.parse(JSON.stringify(this.propsData));
        // this.form.logo = this.form.logo.replace(/(.*\/)+/g, '');
        // this.form.image = this.form.image.replace(/(.*\/)+/g, '');

        if (this.form.type === 11) {
          this.selectedSpecialization = this.specializations?.find((item) => item.id === Number(this.form.entityId));
        }
        if (this.form.type === 10) {
          const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
            take: 1,
            id: this.form.entityId,
            save: true,
          });
          if (Array.isArray(doctors) && doctors.length) {
            const [{ id, fullName }] = doctors;
            this.selectedDoctor = { id, name: fullName };
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    fetchSpecializations(query) {
      return this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);
    },
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        save: false,
      });

      return doctors.map((doctor) => ({ id: doctor.id, name: doctor.fullName }));
    },
    resetEntityId() {
      this.form.entityId = null;
    },
    changeSpecialization(spec) {
      this.form.entityId = spec ? spec.id : null;
    },
    changeDoctor(spec) {
      this.form.entityId = spec ? spec.id : null;
    },
    changeLogoName(file) {
      this.form.logo = file.name;
    },
    changeImage() {
      const reader = new FileReader();

      reader.readAsDataURL(this.fileHero);

      reader.onload = () => {
        this.imagePreview = reader.result;
      };
    },
    createBannerFormData() {
      const fd = new FormData();
      // const keys = [
      //   'id',
      //   'type',
      //   'category',
      //   'clientType',
      //   'title',
      //   'text',
      //   'entityId',
      //   // 'image',
      //   'buttonText',
      //   'style',
      //   'position',
      // ];

      fd.append('file', this.fileHero);

      // keys.forEach((item) => {
      //   if (this.form[item] !== null) fd.append(`${item}`, this.form[item]);
      // });

      return fd;
    },
    async updateBanner() {
      const fd = this.createBannerFormData();

      await this.$store.dispatch(this.$types.UPDATE_BANNER, [{
        ...this.form,
        buttonText: this.isMobile ? null : this.form.buttonText,
      }, fd]);
    },
    async createNewBanner() {
      const fd = this.createBannerFormData();

      await this.$store.dispatch(this.$types.CREATE_BANNER, [{
        ...this.form,
        buttonText: this.isMobile ? null : this.form.buttonText,
      }, fd]);
    },
    async onClickSave() {
      this.isSaving = true;
      try {
        if (this.isEdit) {
          await this.updateBanner();
        } else {
          await this.createNewBanner();
        }

        this.$store.commit(this.$types.TOGGLE_BANNERS_TRIGGER);
        this.onClose();
      } catch (e) {
        console.error(e);
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Что-то пошло не так',
        });
      } finally {
        this.isSaving = false;
      }
    },
    openPreview() {
      this.$v.$touch();
      this.checkValidation = true;

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });

        return;
      }

      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'BannerPreviewModal',
          props: {
            cb: this.onClickSave,
            banner: this.form,
            propImageUrl: this.imagePreview,
          },
        },
      );
    },
  },
};
</script>

<style scoped>

</style>
