import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    responsibles: [],
    informationTypes: [],
    gostelemedTypes: [],
    checkupProfiles: [],
    treatmentTypes: [],
    appeals: [],
  },
  actions,
  getters,
  mutations,
};
