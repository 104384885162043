<template>
  <div class="crm-info-item">
    <div class="crm-info-wrapper">
      <div class="avatar">
        <img
          :src="require('assets/images/empty-avatar.svg?inline')"
          alt="avatar"
          class="avatar"
        >
      </div>

      <div class="name-wrapper">
        <p class="crm-info-name">
          {{ fullname }}
        </p>
      </div>

      <div class="spec-wrapper">
        <p class="crm-info-spec">
          {{ specializations }}
        </p>
      </div>

      <div class="controls-wrapper-1">
        <icon-unpin
          class="crm-icon"
          @click="onClickUnpin"
        />
      </div>

      <div class="controls-wrapper-2">
        <icon-arrow-right
          class="crm-icon"
          @click="openWatchModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { parseISO, format } from 'date-fns';

import IconUnpin from 'assets/images/unpin-icon.svg';
import IconArrowRight from 'assets/images/arrow-right-icon.svg';

export default {
  name: 'DoctorInfoItem',
  components: {
    IconUnpin,
    IconArrowRight,
  },
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullname() {
      const { firstName, lastName, middleName } = this.doctor;
      return `${lastName}\n${firstName}\n${middleName}`;
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((doctorSpecialization) => doctorSpecialization.specialization.title).join(', ');
    },
  },
  methods: {
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    onClickUnpin() {
      this.$emit('toggle-pin');
    },
    openWatchModal() {
      Bus.$emit('open-modal:doctor-watch', { title: this.doctor.fullName, id: this.doctor.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-info-item {
  padding: 15px;
  box-sizing: border-box;
  flex-basis: 33%;
  border-radius: 10px;
  box-shadow: 0px 1px 2px $gray-shadow;
}
.crm-info-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 60px 1fr 30px;
  grid-row-gap: 15px;
  grid-column-gap: 15px;
  grid-template-areas:
    "avatar-wrapper name-wrapper controls-wrapper-1"
    "spec-wrapper spec-wrapper controls-wrapper-2";
}
.avatar {
  grid-area: avatar-wrapper;
  width: 60px;
  height: 60px;
}
.name-wrapper {
  grid-area: name-wrapper;
}
.crm-info-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black-light;
  white-space: pre-line;
  margin: 0;
}
.spec-wrapper {
  grid-area: spec-wrapper;
}
.crm-info-spec {
  margin: 0;
  font-size: 14px;
  line-height: 17px;
}
.controls-wrapper-1 {
  grid-area: controls-wrapper-1;
}
::v-deep.crm-icon {
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    path:not(:last-child) {
      stroke: $white;
      fill: $blue;
    }

    background-color: $blue;
  }
}
</style>
