<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field">
              <p class="page-title">
                Информация о пациенте
              </p>
            </div>

            <div class="crm-form-field crm-form-field_justify_end">
              <span class="program-name">
                {{ letter.insuranceProgramName }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field" />

            <div class="crm-form-field crm-form-field_justify_end">
              <icon-edit
                class="crm-icon-edit"
                :class="{ active: isEdit }"
                @click="isEdit = !isEdit"
              />
            </div>
          </div>

          <template v-if="isEdit">
            <div
              v-if="historyWithPolicy"
              class="crm-form-fields"
            >
              <div class="crm-form-field">
                <base-input
                  v-model="$v.insuranceProgramName.$model"
                  :error="$v.insuranceProgramName.$error"
                  :errors="errorsValidation.insuranceProgramName"
                  label="Страховая подпрограмма"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  fluid
                />
              </div>

              <div class="crm-form-field">
                <base-input
                  v-model="$v.policyId.$model"
                  :error="$v.policyId.$error"
                  :errors="errorsValidation.policyId"
                  label="Номер полиса страхования"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  fluid
                />
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <base-input
                  v-model="$v.firstName.$model"
                  :error="$v.firstName.$error"
                  :errors="errorsValidation.firstName"
                  label="Имя пациента"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  fluid
                />
              </div>

              <div class="crm-form-field">
                <base-input
                  v-model="$v.lastName.$model"
                  :error="$v.lastName.$error"
                  :errors="errorsValidation.lastName"
                  label="Фамилия пациента"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  fluid
                />
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <base-input
                  v-model="$v.middleName.$model"
                  :error="$v.middleName.$error"
                  :errors="errorsValidation.middleName"
                  label="Отчество пациента"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  fluid
                />
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-label">
                    Дата рождения
                  </p>

                  <base-date-picker
                    v-model="$v.birthDate.$model"
                    :clearable="false"
                    :error="$v.birthDate.$error"
                    :errors="errorsValidation.birthDate"
                    class="crm-datepicker"
                  />
                </div>
              </div>
            </div>
          </template>

          <template v-else>
            <div
              v-if="historyWithPolicy"
              class="crm-form-fields"
            >
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-form-title">
                    Страховая программа
                  </p>

                  <p class="crm-form-text">
                    {{ insuranceProgramName }}
                  </p>
                </div>
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-form-title">
                    Номер полиса страхования
                  </p>

                  <p class="crm-form-text">
                    {{ policyId }}
                  </p>
                </div>
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-form-title">
                    Имя пациента
                  </p>

                  <p class="crm-form-text">
                    {{ patientFullname }}
                  </p>
                </div>
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-form-title">
                    Дата рождения
                  </p>

                  <p class="crm-form-text">
                    {{ showedBirthDate }}
                  </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        :loading="isLetterSaving"
        @click="onClickCancel"
      >
        Отменить
      </base-button>

      <base-button
        :type="$const.PRIMARY_BUTTON"
        @click="onClickNext"
      >
        Продолжить
      </base-button>
    </div>
  </div>
</template>

<script>
import { parseISO, formatISO, format } from 'date-fns';

import IconEdit from 'assets/images/edit_icon.svg';

import { validationMixin } from 'vuelidate';

import { required } from 'vuelidate/lib/validators';
import { showMessage } from '@/helpers/utils';

import { BaseInput, BaseButton, BaseDatePicker } from '@/components/base';

export default {
  name: 'IndemnityModalStep1',
  components: {
    IconEdit,
    BaseInput,
    BaseButton,
    BaseDatePicker,
  },
  mixins: [validationMixin],
  data() {
    return {
      isEdit: false,
    };
  },
  validations() {
    return {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      middleName: {
        // required,
      },
      birthDate: {
        required,
      },
      insuranceProgramName: {
        required: this.historyWithPolicy ? required : () => true,
      },
      policyId: {
        required: this.historyWithPolicy ? required : () => true,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.insuranceProgramName = [];
      if (!this.$v.insuranceProgramName.required) {
        errors.insuranceProgramName.push('Поле не может быть пустым');
      }

      errors.policyId = [];
      if (!this.$v.policyId.required) {
        errors.policyId.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.lastName = [];
      if (!this.$v.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.middleName = [];
      if (!this.$v.middleName.required) {
        errors.middleName.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      return errors;
    },
    isLetterSaving() {
      return this.$store.state.Indemnity.isLetterSaving;
    },
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    patientFullname() {
      const { middleName, firstName, lastName } = this.letter.patient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    insuranceProgramName: {
      get() {
        return this.letter.insuranceProgramName;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, insuranceProgramName: newValue });
      },
    },
    policyId: {
      get() {
        return this.letter.policy?.policyId;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, policy: { ...this.letter.policy, policyId: newValue } });
      },
    },
    firstName: {
      get() {
        return this.letter.patient?.firstName;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, patient: { ...this.letter.patient, firstName: newValue } });
      },
    },
    lastName: {
      get() {
        return this.letter.patient?.lastName;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, patient: { ...this.letter.patient, lastName: newValue } });
      },
    },
    middleName: {
      get() {
        return this.letter.patient?.middleName;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, patient: { ...this.letter.patient, middleName: newValue } });
      },
    },
    birthDate: {
      get() {
        return parseISO(this.letter.patient?.birthDate);
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, patient: { ...this.letter.patient, birthDate: formatISO(newValue) } });
      },
    },
    showedBirthDate() {
      return this.letter.patient.birthDate ? format(parseISO(this.letter.patient.birthDate), 'dd.MM.yyyy') : '-';
    },
    historyWithPolicy() {
      return !!this.letter.policy?.id || this.$store.state.Indemnity.historyWithPolicy;
    },
  },
  created() {
    // this.isEdit = this.letter.isNew;
  },
  methods: {
    checkHasValidateErrors() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
      }

      return this.$v.$error;
    },
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickNext() {
      if (!this.checkHasValidateErrors()) {
        this.$emit('change-step', 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-icon-edit {
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: $blue;

    path {
      fill: white;
    }
  }
}
.crm-form-block {
  width: 100%;
}
.crm-datepicker {
  width: 100%;
}
.crm-form-title {
  font-size: 14px;
  line-height: 18px;
  color: $black-light;
  margin: 0 0 10px 0;
}
.crm-form-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $black-light;
  margin: 0;
}
</style>
