import { render, staticRenderFns } from "./PricesHistoryModal.vue?vue&type=template&id=609f1153&scoped=true"
import script from "./PricesHistoryModal.vue?vue&type=script&lang=js"
export * from "./PricesHistoryModal.vue?vue&type=script&lang=js"
import style0 from "./PricesHistoryModal.vue?vue&type=style&index=0&id=609f1153&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "609f1153",
  null
  
)

export default component.exports