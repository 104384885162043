import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.ACCOUNT_USERS_SET](state, users) {
    state.users = users;
  },
};
