import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.GET_APPEAL_RESPONSIBLES](state) {
    return state.responsibles;
  },
  [localTypes.GET_APPEAL_INFORMATION_TYPES](state) {
    return state.informationTypes;
  },
  [localTypes.GET_APPEAL_TREATMENT_TYPES](state) {
    return state.treatmentTypes;
  },
  [localTypes.GET_APPEAL_GOSTELEMED_TYPES](state) {
    return state.gostelemedTypes;
  },
  [localTypes.GET_APPEAL_CHECKUP_PROFILES](state) {
    return state.checkupProfiles;
  },
};
