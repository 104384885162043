<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Обработка консультации"
    :hide-footer="isLoading"
    :hide-header-close="!isClosed"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <div class="mb-1 font-weight-bold">
            ID
          </div>
          <div class="mt-1 text-muted">
            {{ consultation?.id }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Статус
          </div>
          <div class="mt-1 text-muted">
            {{ currentStatus }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Дата создания
          </div>
          <div class="mt-1 text-muted">
            {{ dates.createdAt }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Запись на
          </div>
          <div class="mt-1 text-muted">
            {{ dates.startedAt }}
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            Время взятия в работу оператором
          </div>
          <div class="mt-1 text-muted">
            {{ dates.startProcessingDate }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Время ожидания обработки
          </div>
          <div class="mt-1 text-muted">
            {{ dates.pendingTime }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Время завершения консультации
          </div>
          <div class="mt-1 text-muted">
            {{ dates.endProcessingDate }}
          </div>
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            ФИО пациента
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.patientPerson.fullName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Телефон пациента
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.patientPerson.phoneNumber || 'не указан' }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Текст консультации
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.body }}
          </div>
        </b-col>
      </b-row>

      <hr>

      <b-row class="mt-4">
        <b-col>
          <div class="mb-1 font-weight-bold">
            ФИО врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.lastName }} {{ consultation.targetDoctor.firstName }} {{ consultation.targetDoctor.middleName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Телефон врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.phoneNumber }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Специальность врача
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.targetDoctor.specialization }}
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col v-if="consultation.targetDoctor.regions">
          <div class="mb-1 font-weight-bold">
            Регионы
          </div>
          <div>
            <div
              v-for="region in consultation.targetDoctor.regions"
              :key="region.id"
              class="mt-1 text-muted"
            >
              {{ region }}
            </div>
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Название клиники
          </div>
          <div class="mt-1 text-muted">
            {{ consultation.clinic.clinicName }}
          </div>
        </b-col>

        <b-col>
          <div class="mb-1 font-weight-bold">
            Платформа
          </div>
          <div class="mt-1 text-muted">
            {{ clientType }}
          </div>
        </b-col>
      </b-row>

      <div class="mt-4">
        <div class="mb-1 font-weight-bold">
          Контакты клиники
        </div>
        <b-table
          v-if="consultation.clinic.contacts"
          :fields="contactsFields"
          :items="consultation.clinic.contacts"
          bordered
          hover
          small
        />
      </div>

      <div
        v-if="watchProccessingComments"
        class="mt-5"
      >
        <!-- <div class="mb-2 font-weight-bold">
           Причина попадания в этот раздел
        </div> -->
        <div>
          <div class="mb-1 small font-weight-bold">
            Комментарий от врача
          </div>

          <b-form-textarea
            v-model="proccessingComment"
            placeholder="Введите комментарий врача"
            :disabled="isClosed"
            rows="3"
            :state="validateInput(proccessingComment)"
          />
        </div>
        <div class="mt-2">
          <div class="mb-1 small font-weight-bold">
            Комментарий от пациента
          </div>

          <b-form-textarea
            v-model="proccessingPatientComment"
            placeholder="Введите комментарий пациента"
            :disabled="isClosed"
            rows="3"
            :state="validateInput(proccessingPatientComment)"
          />
        </div>
      </div>

      <!-- <div class="h6 mt-2">
          Консультация закрылась в течении 5 минут. Врач оставил заключение.
      </div> -->
    </template>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <b-button
          :variant="isClosed ? 'secondary' : 'outline-primary'"
          :disabled="isLoading || isClosed"
          size="sm"
          class="float-right"
          @click="onAction(1)"
        >
          Сохранить данные (без обработки)
        </b-button>
        <div class="d-flex align-items-center">
          <div class="mr-4">
            {{ statusText }}
          </div>

          <b-button
            :variant="isClosed ? 'secondary' : 'outline-primary'"
            class="float-right"
            :disabled="actionDisabled || isClosed"
            @click="onAction(2)"
          >
            Обработать
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import {
  format,
  parse,
} from 'date-fns';
import { mixinRoles } from '@/mixins';
import { showMessage } from '@/helpers/utils';
import { CONSULTATION_STATUSES, CLIENT_TYPES } from '@/helpers/consts';
import {
  createWordDeclensions,
  dayWordsArray,
  hoursWordsArray,
  minutesWordsArray,
} from '@/helpers/createWordDeclensions';

export default {
  name: 'ConsultationControlModal',
  components: {
    Preloader,
  },
  mixins: [mixinRoles],

  props: {
    consultation: {
      type: [Object],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      proccessingComment: null,
      proccessingPatientComment: null,
      statuses: CONSULTATION_STATUSES,
      contactsFields: [
        {
          key: 'fio',
          label: 'ФИО',
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'phoneNumber',
          label: 'Номер',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      startProcessingDate: null,
      endProcessingDate: null,
    };
  },
  computed: {
    dates() {
      const { date, slotDate, problemInfo } = this.consultation;
      const { startProcessingDate, endProcessingDate, pendingTime } = problemInfo;
      const formatDate = 'dd.MM.yyyy HH:mm';
      const emptyData = '-';
      function formatFunc(propDate) {
        return propDate ? format(new Date(propDate), formatDate) : emptyData;
      }

      const storedProcessingDates = JSON.parse(localStorage.getItem('processingDates') || '{}');
      const storedStartProcessingDate = storedProcessingDates[this.consultation.id];

      return {
        createdAt: formatFunc(date),
        startedAt: formatFunc(slotDate),
        startProcessingDate: storedStartProcessingDate ? formatFunc(storedStartProcessingDate) : formatFunc(startProcessingDate),
        endProcessingDate: formatFunc(endProcessingDate),
        pendingTime: this.formatTime(pendingTime),
      };
    },
    statusText() {
      const result = CONSULTATION_STATUSES.find((status) => status.index === this.consultation?.problemInfo?.status);
      return result?.title || 'В обработке';
    },
    watchProccessingComments() {
      // return this.checkRoleAccess_(['Tech.operator']);
      return this.checkFeatureAccess({ name: 'Обработка консультации. Причина попадания в раздел', url: '/consultations-control' });
    },
    actionDisabled() {
      if (!this.watchProccessingComments) {
        return this.isLoading;
      }
      const isProccessingCommentValid = this.validateInput(this.proccessingComment);
      const isProccessingPatientCommentValid = this.validateInput(this.proccessingPatientComment);
      return this.isLoading
        || !isProccessingCommentValid
        || !isProccessingPatientCommentValid;
    },
    clientType() {
      const { title = '' } = CLIENT_TYPES.find((item) => item.id === this.consultation.clientType);
      return title;
    },
    isClosed() {
      return this.consultation?.problemInfo?.status === 2;
    },
    currentStatus() {
      return this.statuses.find((s) => s.index === this.consultation?.status)?.title;
    },
  },

  created() {
    this.proccessingComment = this.consultation.problemInfo.doctorComment;
    this.proccessingPatientComment = this.consultation.problemInfo.patientComment;
    const storedProcessingDates = JSON.parse(localStorage.getItem('processingDates') || '{}');
    this.startProcessingDate = storedProcessingDates[this.consultation.id] || this.consultation?.problemInfo?.startProcessingDate || null;
  },
  methods: {
    updateProcessingDates(start = null, end = null) {
      if (start) {
        this.startProcessingDate = start;
      }
      if (end) {
        this.endProcessingDate = end;
      }
    },
    saveToLocalStorage() {
      const storedProcessingDates = JSON.parse(localStorage.getItem('processingDates') || '{}');
      storedProcessingDates[this.consultation.id] = this.startProcessingDate;
      localStorage.setItem('processingDates', JSON.stringify(storedProcessingDates));
    },
    removeFromLocalStorage() {
      const storedProcessingDates = JSON.parse(localStorage.getItem('processingDates') || '{}');
      delete storedProcessingDates[this.consultation.id];
      localStorage.setItem('processingDates', JSON.stringify(storedProcessingDates));
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onAction(status = 2) {
      try {
        const { consultation: { id: consultationId }, proccessingComment, proccessingPatientComment } = this;
        if (!this.startProcessingDate) {
          const startProcessingDate = new Date().toISOString();
          this.updateProcessingDates(startProcessingDate, null);
          this.saveToLocalStorage();
        }

        const response = await this.$store.dispatch(
          this.$types.CONSULATION_CONTROL_PROBLEM_RESOLVE,
          {
            consultationId, proccessingComment, proccessingPatientComment, status,
          },
        );
        const updatedConsultation = response.data;
        this.consultation = updatedConsultation;

        this.$store.commit(this.$types.TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });

        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Консультация обработана',
        });

        this.updateProcessingDates(updatedConsultation.problemInfo.startProcessingDate, updatedConsultation.problemInfo.endProcessingDate);

        // Clear local storage
        this.removeFromLocalStorage();
      } catch (error) {
        console.log(error);
      }
    },
    validateInput(value) {
      // Проверка на пустое поле
      if (!value || value.trim() === '') {
        return false;
      }
      // Удаляем начальные и конечные пробелы
      const trimmedValue = value.trim();
      // Проверка на соответствие регулярному выражению
      const isValidFormat = /^(?=.*[a-zA-Zа-яА-ЯёЁ0-9])([a-zA-Z0-9а-яА-ЯёЁ\s.,!?-]+)$/.test(trimmedValue);
      return isValidFormat;
    },
    formatTime(timeString) {
      const emptyData = '-';
      if (!timeString) {
        return emptyData;
      }

      const timePattern = /^(\d+)\.(\d{2}):(\d{2}):(\d{2})\.(\d+)$/;
      const match = timeString.match(timePattern);
      const formatParsedTime = (parsedTime) => {
        const hours = parseInt(format(parsedTime, 'HH'), 10).toString();
        const minutes = parseInt(format(parsedTime, 'mm'), 10).toString();
        return `
          ${hours} ${createWordDeclensions(hours, hoursWordsArray)} 
          ${minutes} ${createWordDeclensions(minutes, minutesWordsArray)}`;
      };

      if (!match) {
        const parsedTime = parse(timeString, 'HH:mm:ss.SSSSSSS', new Date());
        return formatParsedTime(parsedTime);
      }
      const days = match[1];
      const hours = parseInt(match[2], 10).toString();
      const minutes = parseInt(match[3], 10).toString();
      return `
        ${days} ${createWordDeclensions(days, dayWordsArray)} 
        ${hours} ${createWordDeclensions(hours, hoursWordsArray)} 
        ${minutes} ${createWordDeclensions(minutes, minutesWordsArray)}`;
    },
  },
};
</script>
