/* eslint-disable no-param-reassign */
<template>
  <div class="reestr-import">
    <custom-scrollbar>
      <div class="crm-container">
        <loading v-if="isLoading">
          Загрузка
        </loading>

        <template v-else>
          <div class="crm-header-block">
            <span class="crm-title">
              Проверка реестров - {{ legalPerson.name }}
            </span>

            <div class="crm-form-row">
              <div class="crm-form-field">
                <base-input
                  v-model="actNumber"
                  :input-style="{
                    padding: '11px 10px 12px',
                    resize: 'none',
                  }"
                  class="crm-form-field"
                  placeholder="Введите номер акта"
                />
              </div>

              <div class="crm-form-field crm-form-field--selects-row">
                <div class="crm-form-field crm-form-field-months">
                  <v-select
                    v-model="$v.month.$model"
                    :reduce="month => month.id"
                    :options="months"
                    :clearable="false"
                    placeholder="Месяц"
                    label="ru"
                    class="crm-form-field crm-form-field-months"
                  />
                  <div
                    v-if="$v.month.$error"
                    class="validation-errors"
                  >
                    <span
                      class="validation-error-text"
                    >
                      Выберите месяц
                    </span>
                  </div>
                </div>

                <v-select
                  v-model="year"
                  :options="years"
                  :clearable="false"
                  placeholder="Год"
                  class="crm-form-field crm-form-field-years"
                />
              </div>

              <div class="crm-form-field">
                <base-button
                  :type="$const.SECONDARY_BUTTON"
                  class="crm-button-file"
                  @click="onClickOpenFileModal"
                >
                  <icon-cross
                    class="crm-icon-cross"
                  />
                  <span>Добавить файл</span>
                </base-button>
              </div>
            </div>

            <div
              v-for="(insurance, i) in $v.insuranceRows.$each.$iter"
              :key="i"
              class="crm-form-row"
            >
              <div class="crm-form-field">
                <v-select
                  v-model="insurance.selectedCompanyId.$model"
                  :reduce="company => company.id"
                  :options="insuranceCompanies"
                  placeholder="Выберите компанию"
                  label="title"
                  @input="changeCompany(i)"
                />
                <div
                  v-if="insurance.selectedCompanyId.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    Необходимо выбрать один из вариантов
                  </span>
                </div>
              </div>

              <div class="crm-form-field">
                <v-select
                  v-model="insuranceRows[i].selectedProgramId"
                  :reduce="program => program.id"
                  :options="insuranceRows[i].insurancePrograms"
                  placeholder="Выберите программу"
                  label="name"
                  class="crm-form-field"
                  @input="changeProgram(i)"
                />

                <div
                  v-if="insurance.selectedProgramId.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    Необходимо выбрать один из вариантов
                  </span>
                </div>
              </div>

              <div class="crm-form-field">
                <v-select
                  v-model="insuranceRows[i].selectedSubprogramId"
                  :reduce="subprogram => subprogram.id"
                  :options="insuranceRows[i].insuranceSubprograms"
                  placeholder="Выберите подпрограмму"
                  label="name"
                  class="crm-form-field"
                />
                <div
                  v-if="insurance.selectedSubprogramId.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    Необходимо выбрать один из вариантов
                  </span>
                </div>
              </div>
            </div>

            <div class="mt-4 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="addInsuranceRow"
                >
                  Добавить страховую компанию
                </b-button>

                <b-button
                  title="Проверки только на номер полиса, ФИО, период действия полиса и дубли в документе"
                  :disabled="isDownloadLoading"
                  variant="primary"
                  size="sm"
                  class="ml-2"
                  @click="checkForAndrey"
                >
                  Для Андрея
                </b-button>
              </div>

              <div class="d-flex">
                <!-- <b-button
                  :disabled="isDownloadLoading"
                  variant="outline-danger"
                  size="sm"
                  @click="onClickSaveWithoutChecks"
                >
                  Загрузить без проверок
                </b-button> -->
                <b-button
                  :disabled="isDownloadLoading"
                  variant="primary"
                  size="sm"
                  class="ml-2"
                  @click="onClickOpenImportModal"
                >
                  Проверить и загрузить
                </b-button>
              </div>
            </div>
          </div>

          <preloader v-if="isLoadingFromFile" />

          <div
            v-if=" !isLoadingFromFile"
            class="policies-table"
          >
            <div class="policies-table-wrapper">
              <div
                ref="table-header"
                class="policies-table-header"
              >
                <div
                  v-for="(title, i) in selectedColumnNames"
                  :key="i"
                  class="policies-table-header-cell"
                >
                  <v-select
                    :value="availableColumnNames.filter((name) => name.id === selectedColumnNames[i])[0]"
                    :append-to-body="true"
                    :reduce="program => program.id"
                    :searchable="false"
                    :clearable="true"
                    :options="filteredAvailableColumnNames"
                    :calculate-position="setMaxHeight"
                    class="select-col-name"
                    label="name"
                    placeholder="Игнорировать"
                    @input="selectColumnName($event, i)"
                  />
                </div>
              </div>
              <div class="policies-table-body">
                <vuescroll
                  :ops="ops"
                  class="vuescroll-custom"
                  @handle-scroll="handleScroll"
                >
                  <div class="policies-table-inner">
                    <div
                      v-for="(row, rowIndex) in tableRows"
                      :key="rowIndex"
                      class="policies-table-row"
                    >
                      <input
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        readonly="readonly"
                        class="policies-table-cell"
                        type="text"
                        :value="cell"
                      >
                    </div>
                  </div>
                </vuescroll>
              </div>
            </div>
            <div
              v-if="tableRows.length < totalRowsInFile"
              class="show-more-btn"
              @click="showAllRows"
            >
              Показать полностью
            </div>
          </div>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import vuescroll from 'vuescroll';

import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';
import { MONTHS } from '@/helpers/consts';

import Loading from '@/components/Loading';
import Preloader from '@/components/Preloader';
import {
  BaseButton,
  BaseInput,
} from '@/components/base';
import IconCross from 'assets/images/cross.svg';

export default {
  name: 'LegalEntityReestrImport',
  page: {
    title: 'CRM Doctis - Импорт реестров юридического лица',
  },
  components: {
    Loading,
    IconCross,
    BaseButton,
    BaseInput,
    vuescroll,
    Preloader,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    legalPersonId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isDownloadLoading: false,
      isLoadingFromFile: false,
      fileGUID: undefined,
      insuranceData: null,
      insuranceCompanies: [],
      actNumber: null,
      month: null,
      year: 2021,
      totalRowsInFile: 0,
      availableColumnNames: [
        {
          id: 25,
          name: 'ФИО Пациента',
          key: 'personFullName',
        },
        {
          id: 0,
          name: 'Номер полиса',
          key: 'policyId',

        },
        {
          id: 26,
          name: 'Дата оказания',
          key: 'date',
        },
        {
          id: 27,
          name: 'Название услуги',
          key: 'name',
        },
        {
          id: 28,
          name: 'Код услуги',
          key: 'code',
        },
        {
          id: 29,
          name: 'Диагноз по мкб',
          key: 'mkbCode',
        },
        {
          id: 30,
          name: 'Количество услуг',
          key: 'count',
        },
        {
          id: 31,
          name: 'Цена услуги',
          key: 'price',
        },
        {
          id: 32,
          name: 'Итоговая стоимость',
          key: 'totalCost',
        },
      ],
      filteredAvailableColumnNames: [],
      miniDisplayedRows: 10,
      selectedColumnNames: [
        null, null, null, null, null,
      ],
      tableRows: [
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
      ],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#E0E0E0',
          minSize: 0.15,
          size: '5px',
        },
      },
      months: MONTHS,
      years: [
        2021,
        2022,
        2023,
        2024,
      ],
      insuranceRows: [
        {
          selectedCompanyId: null,
          selectedProgramId: null,
          selectedSubprogramId: null,
          insurancePrograms: [],
          insuranceSubprograms: [],
        },
      ],
    };
  },
  validations() {
    return {
      month: {
        required,
      },
      insuranceRows: {
        $each: {
          selectedCompanyId: {
            required,
          },
          selectedProgramId: {
            required,
          },
          selectedSubprogramId: {
            required,
          },
        },
      },
    };
  },
  computed: {
    formatedSelectedRowsForBackend() {
      return this.availableColumnNames.map((colType) => {
        let colIndexForThisColName = this.selectedColumnNames.indexOf(colType.id);
        if (colIndexForThisColName === -1) {
          colIndexForThisColName = null;
        }
        return { propertyId: colType.id, columnNumber: colIndexForThisColName };
      });
    },
  },
  async created() {
    this.isLoading = true;

    this.filteredAvailableColumnNames = this.availableColumnNames;

    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));
    this.legalPerson = await this.$store.dispatch(this.$types.LEGAL_ENTITY_FETCH, { id: this.legalPersonId });

    this.setInsuranceEntities();

    this.isLoading = false;
  },
  methods: {
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload-modern', { saveFileCallback: this.saveFileCallback, multiple: false, type: '.xlsx' });
    },
    checkValidity() {
      this.$v.$touch();
      const hasValidationError = this.$v.$invalid;

      if (hasValidationError) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Укажите недостающие данные',
        });
        return true;
      }

      return false;
    },
    collectRequestBody() {
      if (this.checkValidity()) return;

      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoadingFromFile;

      if (!hasFile) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо добавить файл',
        });
        return;
      }

      const companyIds = this.insuranceRows.filter((row) => row.selectedCompanyId).map((row) => row.selectedCompanyId);
      const programIds = this.insuranceRows.filter((row) => row.selectedProgramId).map((row) => row.selectedProgramId);
      const subprogramIds = this.insuranceRows.filter((row) => row.selectedSubprogramId).map((row) => row.selectedSubprogramId);

      const body = {
        fileId: this.fileGUID,
        companyIds,
        programIds,
        subprogramIds,
        allRowsCount: this.totalRowsInFile,
        columns: this.formatedSelectedRowsForBackend,
        legalPerson: this.legalPerson,
        actNumber: this.actNumber,
        month: this.month,
        year: this.year,
        useSimplifiedCheck: false,
      };

      return body;
    },
    async saveWithoutCheck() {
      // не работает
      const body = this.collectRequestBody();
      if (!body) return;

      const {
        actNumber, month, year, useSimplifiedCheck, ...request
      } = body;

      try {
        const response = await api.post(
          'v2/registry/saveWithoutCheck',
          request,
          {
            params: {
              month: this.month,
              year: this.year,
              actNumber: this.actNumber,
            },
          },
        );
        console.log(response);
        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Загрузка без проверок прошла успешно',
        });
      } catch (e) {
        console.error(e);
        showMessage({
          type: 'error',
          title: `Ошибка, ${e}`,
          message: `При загрузке без проверок произошла ошибка: ${e.response?.data?.message}`,
        });
      }
    },
    onClickSaveWithoutChecks() {
      // не работает
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            cb: this.saveWithoutCheck,
            title: 'Сохранение без проверок',
          },
        },
      );
    },
    checkForAndrey() {
      let body = this.collectRequestBody();
      if (!body) return;

      body = {
        ...body,
        useSimplifiedCheck: true,
      };

      Bus.$emit('open-modal:legal-entity-reestr-import', {
        body,
        forAndrey: true,
      });
    },
    onClickOpenImportModal() {
      const body = this.collectRequestBody();
      if (!body) return;

      Bus.$emit('open-modal:legal-entity-reestr-import', {
        body,
      });
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },

    generateEmptyRows(rowsCount, colsCount) {
      const rows = new Array(rowsCount).fill([]);

      for (let i = 0; i < rowsCount; i += 1) {
        rows[i] = new Array(colsCount).fill('');
      }

      return rows;
    },

    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoadingFromFile = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows?guid=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoadingFromFile = false;
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid;
      await this.getTableRows(10, true);
    },

    handleScroll(vertical, horizontal) {
      // Table header horizontal scroll
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
    },
    selectColumnName(e, i) {
      this.selectedColumnNames.splice(i, 1, e); // для реактивности
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.id));
    },
    setInsuranceEntities() {
      this.insuranceRows.forEach((row, index) => {
        const { selectedCompanyId, selectedProgramId } = row;

        if (!selectedCompanyId) return;

        const companyObject = this.insuranceData
          .filter(({ company: { id } }) => id === selectedCompanyId)[0];

        const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
        this.insuranceRows[index].insurancePrograms = programs;

        if (!selectedProgramId) return;

        const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === selectedProgramId)[0];
        this.insuranceRows[index].insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
      });
    },
    changeCompany(i) {
      this.insuranceRows[i].selectedProgramId = null;
      this.insuranceRows[i].selectedSubprogramId = null;
      this.insuranceRows[i].insurancePrograms = [];
      this.insuranceRows[i].insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram(i) {
      this.insuranceRows[i].selectedSubprogramId = null;
      this.insuranceRows[i].insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    addInsuranceRow() {
      this.insuranceRows.push({
        selectedCompanyId: null,
        selectedProgramId: null,
        selectedSubprogramId: null,
        insurancePrograms: [],
        insuranceSubprograms: [],
      });
    },
    setMaxHeight(dropdownList, component, { width, top, left }) {
      /* eslint-disable no-param-reassign */
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      // dropdownList.style.maxHeight = 'calc(100vh - 100px)';
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
  },
};
</script>

<style lang="scss" scoped>
.reestr-import {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 30px);
  margin-top: 30px;
}

.crm-container {
  flex-direction: column;
}

.crm-header-block {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.crm-form-row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.crm-form-row--buttons {
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  grid-column-gap: 40px;
}

.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

::v-deep.title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-container-buttons {
  display: flex;
  margin-left: auto;
}

::v-deep.crm-button-file {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-left: auto;
  background-color: transparent;

  .crm-icon-cross {
    margin-right: 15px;
    path {
      fill: $blue;
    }
  }
}

.button-import {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-left: auto;
}

.policies-table {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px  55px 30px;

  * {
    box-sizing: border-box;
  }
}

.policies-table-wrapper {
  width: 100%;
  // overflow: hidden;
}

.policies-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.policies-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.policies-table-body {
  height: 350px;
  position: relative;
}

::v-deep.policies-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
}

::v-deep.policies-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .policies-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.width-unset {
  width: auto;
}

.add-insurance-row {
  margin-right: auto;
}

.crm-form-field--selects-row {
  display: flex;
}

.crm-form-field-months {
  width: 180px;
  margin-right: 20px;
}

.crm-form-field-months {
  width: 180px;
  margin-right: 20px;
}

.crm-form-field-years {
  width: 120px;
}

.crm-form-field {
  position: relative;
}
</style>
