<template>
  <div :class="['serveys-option-card', { 'serveys-option-card--error': hasError }]">
    <div class="serveys-option-card_head">
      <span>Ответ {{ '№' + (index + 1) }}</span>
    </div>
    <div class="serveys-option-card_body d-flex">
      <BaseAsyncSelect
        v-model="selectedOption"
        :fetch-function="fetchOptions"
        :server-paginated="true"
        :placeholder="'Введите запрос...'"
        :no-options-title="'Нет доступных опций'"
        :loading-title="'Загрузка данных...'"
        :option-title="'title'"
        :clearable="false"
        :error="hasError"
        :disabled="!isActiveCheckbox"
        class="serveys-option-card_select flex-grow-1 mr-4"
        @change="onOptionChange"
      />
      <BaseSwitcher v-model="isActiveCheckbox" />
    </div>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { required } from 'vuelidate/lib/validators';
import BaseAsyncSelect from '@/components/base/BaseAsyncSelect.vue';
import BaseSwitcher from '@/components/base/BaseSwitcher.vue';

export default {
  name: 'ServeysOptionCard',
  components: {
    BaseAsyncSelect,
    BaseSwitcher,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    option: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selectedOption: this.option || {},
      editableOption: { ...this.option },
    };
  },
  validations() {
    return {
      selectedOption: {
        required,
        id: {
          required,
        },
      },
    };
  },
  computed: {
    isActiveCheckbox: {
      get() {
        return this.editableOption.active || false;
      },
      set(value) {
        this.editableOption.active = value;
        this.selectedOption.active = value;
        this.$emit('update:option', this.editableOption);
      },
    },
    hasError() {
      return this.$v.selectedOption.$invalid;
    },
  },
  watch: {
    hasError: {
      handler(value) {
        this.$emit('error', value, this.index);
      },
      immediate: true,
    },
  },
  methods: {
    // Метод для загрузки данных
    async fetchOptions({ query, skip, take }) {
      try {
        const response = await api.get('v3/survey/option', {
          params: { search: query, skip, take },
        });
        const optionsWithActive = response?.data.data.options.map((option) => ({
          ...option,
          active: true,
        }));
        return optionsWithActive;
      } catch (error) {
        console.error('Ошибка загрузки опций:', error);
        return [];
      }
    },
    onOptionChange(option) {
      this.selectedOption = option;
      this.$v.$touch();
      this.editableOption = { ...this.editableOption, ...option };
      this.$emit('addNewOption', this.editableOption, this.index);
    },
  },
};
</script>

<style lang="scss">
  .serveys-option-card {
    background: #F3F4F5;
    padding: 16px 16px 12px 16px;
    border-radius: 8px;
    border: 1px solid transparent;
    &--error {
      border-color: #FFA599;
    }
    &_head {
      margin-bottom: 8px;
      span {
        font-weight: 500;
        font-size: 14px;
        color: #161E2F;
        line-height: 1.2;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 24px;
      }
    }
    &_select {
      .v-select {
        background: #fff;
      }
      .vs__open-indicator {
        path {
          fill: #4071FF;
        }
      }
    }
  }
</style>
