<template>
  <VueCal
    ref="calendar"
    active-view="week"
    locale="ru"
    :disable-views="disableViews"
    :hide-view-selector="hideViewSelector"
    :hide-weekends="hideWeekends"
    :time-from="timeFrom"
    :time-to="timeTo"
    :draggable="false"
    :dragging="false"
    :dragging-static="false"
    :time-step="timeStep"
    sticky-split-labels
    :editable-events="editable"
    :events="scheduleEvents"
    :style="{ height: height }"
    :time-cell-height="88"
    class="schedule-calendar bg-white full-cal vuecal--full-height-delete"
    :on-event-click="onEventClick"
    @view-change="viewChange"
  />
<!--  @event-mouse-enter=""-->
<!--  @event-mouse-leave=""-->
</template>

<script>
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';

export default {
  name: 'ScheduleCalendar',
  components: { VueCal },

  props: {
    height: {
      type: String,
      default: '500px',
    },
    scheduleEvents: {
      type: Array,
      default: () => ([]),
    },
    editable: {
      type: Object,
      default: () => ({
        title: false, drag: false, resize: false, create: false, delete: false,
      }),
    },
  },
  data: () => ({
    timeFrom: 0, // колонки - начальное время
    timeTo: 24 * 60, // колонки - конечное время
    timeStep: 15, // интервал в минутах
    hideViewSelector: true, // скрыть селекторы сверху (год, день, неделя)
    hideWeekends: false, // скрыть выходные выходные
    disableViews: ['years', 'year', 'month', 'day'], // недоступные селекторы
  }),
  mounted() {
    this.$emit('viewChange', [this.$refs.calendar.view, false]);
  },

  methods: {
    onEventClick(event, e) {
      console.log(event, e);
      this.selectedEvent = event;

      this.$emit('onEventClick', event);

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    },
    viewChange(e) {
      this.$emit('viewChange', [e, true]);
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-calendar {
  border-radius: 6px !important;
  overflow: hidden;
}

::v-deep .vuecal__title-bar {
  background-color: #bdffc31f;
}

@media screen and (max-width: 499px) {
  .main-demo .day-split-header strong {display: none;}
}
</style>
