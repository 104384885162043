var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.variant,'crm-wrap-block',{['crm-fluid']: _vm.fluid }]},[(_vm.label && !_vm.material)?_c('div',{staticClass:"crm-label",class:{ required: _vm.required }},[_c('span',[_vm._v(_vm._s(_vm.label))]),(_vm.sublabel)?_c('span',{staticClass:"crm-sublabel"},[_vm._v(" "+_vm._s(_vm.sublabel)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"crm-wrap-input",class:{
      'with-prefix': !!_vm.$slots.prefix,
      'with-suffix': !!_vm.$slots.suffix,
      'with-clearable': _vm.clearable,
    }},[(_vm.type === 'textarea')?_c('textarea',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",staticClass:"crm-base-input",class:{ error: _vm.error, 'crm-base-input_material': _vm.material },style:({
        ..._vm.inputStyle,
      }),attrs:{"autocomplete":_vm.autocomplete},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}},'textarea',{
        ..._vm.inputProps,
        ..._vm.textareaProps
      },false),_vm.eventHandlers)):(_vm.mask)?_c('masked-input',_vm._g(_vm._b({ref:"input",staticClass:"crm-base-input",class:{ error: _vm.error, 'crm-base-input_material': _vm.material, },style:({
        ..._vm.inputStyle,
      }),attrs:{"mask":_vm.mask,"guide":_vm.guide,"autocomplete":_vm.autocomplete},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}},'masked-input',_vm.inputProps,false),_vm.eventHandlers)):(((_vm.inputProps).type)==='checkbox')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",staticClass:"crm-base-input",class:{ error: _vm.error, 'crm-base-input_material': _vm.material },style:({
        ..._vm.inputStyle,
      }),attrs:{"autocomplete":_vm.autocomplete,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.localValue)?_vm._i(_vm.localValue,null)>-1:(_vm.localValue)},on:{"change":function($event){var $$a=_vm.localValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localValue=$$c}}}},'input',_vm.inputProps,false),_vm.eventHandlers)):(((_vm.inputProps).type)==='radio')?_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",staticClass:"crm-base-input",class:{ error: _vm.error, 'crm-base-input_material': _vm.material },style:({
        ..._vm.inputStyle,
      }),attrs:{"autocomplete":_vm.autocomplete,"type":"radio"},domProps:{"checked":_vm._q(_vm.localValue,null)},on:{"change":function($event){_vm.localValue=null}}},'input',_vm.inputProps,false),_vm.eventHandlers)):_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.localValue),expression:"localValue"}],ref:"input",staticClass:"crm-base-input",class:{ error: _vm.error, 'crm-base-input_material': _vm.material },style:({
        ..._vm.inputStyle,
      }),attrs:{"autocomplete":_vm.autocomplete,"type":(_vm.inputProps).type},domProps:{"value":(_vm.localValue)},on:{"input":function($event){if($event.target.composing)return;_vm.localValue=$event.target.value}}},'input',_vm.inputProps,false),_vm.eventHandlers)),(_vm.material)?_c('label',{staticClass:"label_material",class:{ required: _vm.required }},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{staticClass:"crm-input-prefix"},[_vm._t("prefix")],2),_c('div',{staticClass:"crm-input-suffix"},[(_vm.clearable && _vm.localValue && _vm.localValue.length)?_c('i',{staticClass:"clear-icon",style:({
          marginRight: _vm.$slots.suffix ? '10px' : '',
          cursor: _vm.disabled ? 'not-allowed' : '',
        }),on:{"click":function($event){$event.stopPropagation();return _vm.onClickClear.apply(null, arguments)}}}):_vm._e(),(_vm.type === 'number' && !_vm.disableArrows)?_c('div',{staticClass:"crm-input-arrows"},[_c('div',{staticClass:"crm-input-arrow-block",class:{ disabled: _vm.disabled },on:{"mousedown":function($event){return _vm.onMouseDown('up')},"mouseup":function($event){return _vm.onMouseUp('up')},"mouseout":_vm.onMouseOut}},[_c('icon-arrow-up',{staticClass:"crm-input-arrow"})],1),_c('div',{staticClass:"crm-input-arrow-block",class:{ disabled: _vm.disabled },on:{"mousedown":function($event){return _vm.onMouseDown('down')},"mouseup":function($event){return _vm.onMouseUp('down')},"mouseout":_vm.onMouseOut}},[_c('icon-arrow-down',{staticClass:"crm-input-arrow"})],1)]):_vm._e(),_vm._t("suffix")],2)],1),(_vm.error && _vm.errors.length)?[_c('div',{staticClass:"validation-errors"},[_c('span',{staticClass:"validation-error-text"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")])])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }