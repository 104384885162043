<template>
  <custom-scrollbar>
    <Loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка промокода
    </Loading>

    <div
      v-else
      class="doctor-info"
    >
      <div class="doctor-info--block">
        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Дата рождения
            </p>

            <span class="crm-info-text">
              {{ birthDate }}
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Пол
            </p>

            <span class="crm-info-text">
              {{ sexType }}
            </span>
          </div>
        </div>
      </div>

      <div class="doctor-info--block">
        <div class="crm-form-fields">
          <div class="crm-info-block">
            <p class="crm-info-title">
              Признак врача
            </p>

            <span class="crm-info-text">
              {{ signType }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-info-block">
            <p class="crm-info-title">
              Название клиники
            </p>

            <span class="crm-info-text">
              {{ doctor.clinicName }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-info-block">
            <p class="crm-info-title">
              СНИЛС
            </p>

            <span class="crm-info-text">
              {{ doctor.snils || 'Не указано' }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Стоимость приема
            </p>

            <span class="crm-info-text">
              {{ doctor.settings.consultationPrice }} ₽
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-title">
              Стоимость депозита
            </p>

            <span class="crm-info-text">
              {{ doctor.settings.depositPrice }} ₽
            </span>
          </div>
        </div>

        <div
          v-if="doctor.settings.signType === 1"
          class="crm-form-fields"
        >
          <div class="crm-info-block">
            <p class="crm-info-title">
              Идентификатор МиД
              <span class="crm-info-title_subtitle">
                (только для врачей МиД)
              </span>
            </p>

            <span class="crm-info-text">
              {{ doctor.settings.midServiceId }}
            </span>
          </div>
        </div>
      </div>

      <div class="doctor-info--block">
        <div
          v-for="(specialization, index) in specializations"
          :key="index"
          class="crm-form-fields"
        >
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-text crm-info-text_block">
              Специализация
            </p>

            <span class="crm-info-title">
              {{ specializationsNames[specialization.specializationId] }}
            </span>
          </div>

          <div class="crm-form-field crm-info-block">
            <p class="crm-info-text crm-info-text_block">
              Опыт работы
              <span class="crm-info-text_subtext">
                (начиная с...)
              </span>
            </p>

            <span class="crm-info-title">
              {{ specialization.experience }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-text crm-info-text_block">
              Регионы врача
            </p>

            <span class="crm-info-title">
              {{ regions }}
            </span>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-field crm-info-block">
            <p class="crm-info-text crm-info-text_block">
              Тип прикрепления
            </p>

            <span class="crm-info-title">
              {{ medicalInsuranceTypes }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </custom-scrollbar>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { api } from '@/helpers/api';
import { DOCTOR_SIGN_TYPE, SEX_TYPE, POLICY_TYPE } from '@/helpers/consts';

import Loading from '@/components/Loading';

export default {
  name: 'PromocodeInfoModal',
  components: {
    Loading,
  },
  props: {
    doctorId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      doctor: {},
    };
  },
  computed: {
    birthDate() {
      return format(parseISO(this.doctor.birthDate), 'dd.MM.yyyy');
    },
    signType() {
      return DOCTOR_SIGN_TYPE[this.doctor.settings.signType];
    },
    sexType() {
      return SEX_TYPE[this.doctor.sex];
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((specialization) => ({
        ...specialization,
        experience: format(parseISO(specialization.experience), 'dd.MM.yyyy'),
      }));
    },
    specializationsNames() {
      return this.doctor.specializations.reduce((acc, specialization) => ({ ...acc, [specialization.id]: specialization.title }), {});
    },
    regions() {
      return this.doctor.regions.map((region) => region.name).join(', ') || '—';
    },
    medicalInsuranceTypes() {
      const decodedInsuranceType = POLICY_TYPE.decodeType(this.doctor.settings.medicalInsuranceType);
      let insuranceTypeArray = [];

      if (decodedInsuranceType === 'All') {
        insuranceTypeArray = ['ОМС', 'ДМС'];
      } else if (decodedInsuranceType === 'OMS') {
        insuranceTypeArray = ['ОМС'];
      } else if (decodedInsuranceType === 'DMS') {
        insuranceTypeArray = ['ДМС'];
      }

      return insuranceTypeArray.join(', ') || '—';
    },
  },
  async created() {
    try {
      this.isLoading = true;

      const { data: doctor } = await api.get(`v2/doctors/${this.doctorId}`);

      this.doctor = doctor;
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.doctor-info,
.doctor-info--block {
  width: 100%;
}
.doctor-info {
  margin-top: 30px;
}
.doctor-info--block {
  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid $gray-border;
  }

  &:not(:first-child) {
    padding-top: 30px;
  }
}
.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.crm-info-block {
  flex-direction: column;

  .crm-info-title,
  .crm-info-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  .crm-info-text {
    &_block {
      margin: 0 0 15px 0;
    }

    &_subtext {
      font-weight: normal;
      color: $gray-dark;
    }
  }

  .crm-info-title {
    color: $blue-magenta;
    margin: 0 0 15px 0;

    &_subtitle {
      color: $gray-border;
    }
  }
}
</style>
