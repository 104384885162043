<template>
  <div class="patient-add">
    <custom-scrollbar>
      <div class="patient-add-inner">
        <div class="clinics-form-fields">
          <base-input
            v-model.trim="$v.patient.lastName.$model"
            :error="$v.patient.lastName.$error"
            :errors="errorsValidation.lastName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Фамилия"
            placeholder="Введите фамилию"
            required
          />

          <base-input
            v-model="$v.patient.phoneNumber.$model"
            :error="$v.patient.phoneNumber.$error"
            :errors="errorsValidation.phoneNumber"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              maxWidth: '250px',
            }"
            class="crm-form-field"
            label="Телефон"
            placeholder="+"
            :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            :required="withUserIdPatient"
          />
        </div>
        <div class="clinics-form-fields">
          <base-input
            v-model.trim="$v.patient.firstName.$model"
            :error="$v.patient.firstName.$error"
            :errors="errorsValidation.firstName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Имя"
            placeholder="Введите имя"
            required
          />

          <!-- <base-async-select
            v-model="citySelect"
            :fetch-function="fetchCities"
            label="Город"
            class="crm-form-field"
            placeholder="Поиск города"
          /> -->
          <div class="crm-form-field">
            <div class="crm-form-block">
              <div class="crm-label">
                Город
              </div>
              <v-select
                v-model="patient.city"
                :options="cities"
                placeholder="Выберите город"
                label="name"
                :clearable="true"
                class="crm-select"
              />
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <base-input
            v-model="patient.middleName"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Отчество"
            placeholder="Введите отчество"
          />

          <base-input
            v-model="patient.snils"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              maxWidth: '250px',
            }"
            class="crm-form-field"
            label="Снилс"
            :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]"
            placeholder="XXX-XXX-XXX XX"
          />
        </div>

        <div class="clinics-form-fields">
          <base-input
            v-model="$v.patient.email.$model"
            :error="$v.patient.email.$error"
            :errors="errorsValidation.email"
            :input-style="{
              padding: '11px 15px 12px',
              backgroundColor: '#ffffff',
            }"
            :mask="emailMask"
            class="crm-form-field"
            label="E-mail"
          />
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Статус
              </p>
              <div class="crm-input-value crm-input-value--checkbox">
                <base-checkbox
                  v-model="patient.isVip"
                  class="crm-checkbox"
                  @change="patient.isTop = null"
                >
                  VIP
                </base-checkbox>
                <base-checkbox
                  v-model="patient.isTop"
                  class="crm-checkbox"
                  @change="patient.isVip = null"
                >
                  ТОП
                </base-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="clinics-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-field">
              <div
                class="crm-form-block"
                :style="{
                  minWidth: '150px',
                }"
              >
                <p class="crm-label required">
                  Дата рождения
                </p>
                <base-date-picker
                  v-model="$v.patient.birthDate.$model"
                  :error="$v.patient.birthDate.$error"
                  :max-date="maxDate"
                />
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label required">
                  Пол
                </p>
                <div class="crm-radio-buttons crm-radio-buttons_horizontal">
                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="true"
                  >
                    Мужской
                  </base-radio-button>

                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="false"
                  >
                    Женский
                  </base-radio-button>

                  <div
                    v-if="$v.patient.sex.$error"
                    class="validation-errors"
                  >
                    <span
                      class="validation-error-text"
                    >
                      {{ errorsValidation.sex[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="crm-form-field">
            {{ null }}
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <!-- <p class="crm-label">
              Статус
            </p> -->
            <div class="crm-input-value crm-input-value--checkbox">
              <!-- checkRoleAccess_(['polis.operator.dop']) -->
              <base-checkbox
                v-model="withUserIdPatient"
                :disabled="!checkFeatureAccess({ name: 'Возможность выбрать С UserID', url: '/patients' })"
                class="crm-checkbox"
              >
                С UserID
              </base-checkbox>
            </div>
          </div>
        </div>

        <patient-policy
          v-for="(policy, index) in patient.policies"
          :key="index"
          :policy="policy"
          :index="index"
          @delete="removePolicy(index)"
        />

        <div class="crm-wrapper-buttons">
          <!-- <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button> -->

          <base-button
            :type="$const.PRIMARY_BUTTON"
            :loading="isSaving"
            class="crm-button"
            @click="onClickSave"
          >
            Сохранить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import {
  // isValid,
  // parseISO,
  formatISO,
} from 'date-fns';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showMessage, dateWithoutTime } from '@/helpers/utils';
import emailMask from 'text-mask-addons/dist/emailMask';

import {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseRadioButton,
  BaseDatePicker,
} from '@/components/base';

import PatientPolicy from '../PatientAddDetailPolicy';

export default {
  name: 'PatientAddModal',
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseRadioButton,
    PatientPolicy,
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  data() {
    return {
      withUserIdPatient: true,
      cities: [],
      emailMask,
      isSaving: false,
      checkValidations: false,
      maxDate: new Date(),
      insuranceSubrograms: [],
      patient: {
        lastName: null,
        firstName: null,
        middleName: null,
        birthDate: null,
        sex: null,
        phoneNumber: null,
        email: null,
        isVip: false,
        isTop: false,
        city: null,
        snils: null,
        policies: [],
      },
    };
  },
  validations() {
    return {
      patient: {
        lastName: { required },
        firstName: { required },
        birthDate: { required },
        sex: { required },
        phoneNumber: {
          required: this.withUserIdPatient ? required : () => true,
        },
        email: {
          // required,
        },
        policies: {
          $each: {
            subProgramV2Id: { required },
            policyId: { required },
            buyDate: {},
            ensurerName: {},
            startDate: { required },
            endDate: { required },
          },
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.lastName = [];
      if (!this.$v.patient.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.patient.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.patient.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.email = [];
      if (!this.$v.patient.email.required) {
        errors.email.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.patient.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.patient.sex.required) {
        errors.sex.push('Выберите один из вариантов');
      }

      errors.policies = this.patient.policies.map((policy, index) => {
        const policyErrors = {};

        policyErrors.policyId = [];
        if (!this.$v.patient.policies.$each[index].policyId.required) {
          policyErrors.policyId.push('Поле не может быть пустым');
        }

        policyErrors.subProgramV2Id = [];
        if (!this.$v.patient.policies.$each[index].subProgramV2Id.required) {
          policyErrors.subProgramV2Id.push('Выберите один из вариантов');
        }

        policyErrors.startDate = [];
        if (!this.$v.patient.policies.$each[index].startDate.required) {
          policyErrors.startDate.push('Поле не может быть пустым');
        }

        policyErrors.endDate = [];
        if (!this.$v.patient.policies.$each[index].endDate.required) {
          policyErrors.endDate.push('Поле не может быть');
        }

        return policyErrors;
      });

      return errors;
    },
  },
  async created() {
    const insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.cities = (await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    this.$store.commit(this.$types.INSURANCE_COMPANIES_SET, insuranceCompanies);
  },
  methods: {
    fetchCities(query) {
      return this.$store.dispatch(this.$types.CITIES_FETCH, query);
    },
    openAttachPolicyModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'AttachPolicyModal', props: { personId: this.personId } });
    },
    addPolicy() {
      const newPolicy = {
        policyId: null,
        companyName: null,
        programName: null,
        subProgramV2Id: null,
        ensurerName: null,
        buyDate: null,
        startDate: null,
        endDate: null,
      };
      this.$v.patient.policies.$model.push(newPolicy);
    },
    removePolicy(index) {
      this.patient.policies.splice(index, index + 1);
    },
    onClickClose() {
      if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
        return;
      }
      this.$emit('vuedals:close');
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      const formattedPatientData = {
        ...this.patient,
        birthDate: dateWithoutTime(formatISO(this.patient.birthDate)),
      };

      if (typeof formattedPatientData.city === 'object' && formattedPatientData.city !== null) {
        formattedPatientData.city = formattedPatientData.city ? formattedPatientData.city.name : null;
      }

      formattedPatientData.phoneNumber = formattedPatientData.phoneNumber
        .split(' ')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('-')
        .join('');

      formattedPatientData.policies = formattedPatientData.policies.map((policyData) => {
        const {
          buyDate,
          startDate,
          endDate,
          policyId,
          ensurerName,
          subProgramV2Id,
          companyName,
          programName,
        } = policyData;

        const policy = {
          companyName,
          ensurerName,
          programName,
          buyDate: buyDate ? formatISO(buyDate) : null,
          startDate: formatISO(startDate),
          endDate: formatISO(endDate.setHours(23, 59, 0, 0)),
          policyId: policyId.trim(),
          subProgramV2Id,
        };

        return policy;
      });

      try {
        this.isSaving = true;

        const apiMethod = this.withUserIdPatient ? this.$types.PATIENT_CREATE : this.$types.PATIENT_ONE_TIME_CREATE;

        await this.$store.dispatch(apiMethod, formattedPatientData);

        Bus.$emit('patients:update');
        this.$emit('vuedals:close');
      } catch (e) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: e,
        });
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-add {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1px;
  height: calc(100vh - 170px);
}

.patient-add-inner {
  padding: 0 20px 0 0;
}
.crm-form-block {
  width: 100%;
  display: block;
}

.crm-radio-buttons {
  position: relative;
  display: flex;
  height: 40px;
  align-items: center;

  &.crm-radio-buttons_horizontal .crm-radio-button {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }
}

::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
.button_transparent {
  width: unset;
}
.add-policy-button {
  margin-bottom: 30px;
}

::v-deep.crm-input-value--checkbox {
  background: #fff;
  padding: 0;
  display: flex;
  align-items: center;
  height: 50px;

  .custom-checkbox {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    & + .custom-checkbox {
      margin-left: 20px;
    }
  }
}
</style>
