<template>
    <d-remote-monitoring-indicator-trends-create @on-create="onConfirmHandler" />
</template>

<script>
export default {
  name: 'RemoteMonitoringIndicatorTrendsCreate',
  methods: {
    onConfirmHandler() {
      this.$router.push('/remote-monitoring/indicator-trends/');
    },
  },
};
</script>
