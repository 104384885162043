<template>
  <div class="w-100 pt-4">
    <div class="d-flex flex-column overflow-hidden">
      <b-container fluid="xl">
        <div class="mt-4 d-flex flex-column">
          <div class="d-flex mb-4 align-items-center justify-content-between">
            <div class="h3">
              Информационные сообщения
            </div>
            <div class="d-flex">
              <b-button
                v-if="regions?.length"
                variant="primary"
                size="sm"
                class="text-nowrap"
                @click="openInfoMessageCreateModal"
              >
                Создать сообщение
              </b-button>
            </div>
          </div>
          <div
            class="d-flex w-100 overflow-auto mb-4"
            :style="{ maxHeight: '700px' }"
          >
            <b-table
              v-if="infoMessages?.length && regions.length && !isLoading"
              class="bg-white w-100 overflow-hidden"
              bordered
              :fields="fields"
              :items="infoMessages"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <template #cell(title)="row">
                {{ row.item.title }}
              </template>.
              <template #cell(message)="row">
                {{ row.item.message }}
              </template>
              <template #cell(link)="row">
                <div :style="{ maxWidth: '100px', overflowWrap: 'break-word' }">
                  <a
                    :href="row.item.link"
                    target="_blank"
                  >
                    {{ row.item.link }}
                  </a>
                </div>
              </template>
              <template #cell(linkTitle)="row">
                {{ row.item.linkTitle }}
              </template>
              <template #cell(type)="row">
                {{ infoMessageTypes[row.item.type] }}
              </template>
              <template #cell(region.id)="row">
                {{ row.item.region.name }}
              </template>
              <template #cell(isActive)="row">
                {{ row.item.isActive ? 'Да' : 'Нет' }}
              </template>
              <template #cell(Редактировать)="row">
                <div class="d-flex align-items-center">
                  <b-button
                    v-b-tooltip.hover
                    class="contact-legal-delete mr-2"
                    variant="primary"
                    size="sm"
                    title="Редактировать"
                    @click="openInfoMessageEditModal(row.item)"
                  >
                    <b-icon
                      icon="pencil-fill"
                    />
                  </b-button>
                  <b-button
                    v-b-tooltip.hover
                    class="contact-legal-delete"
                    variant="danger"
                    size="sm"
                    title="Удалить"
                    @click="openInfoMessageDeleteModal(row.item)"
                  >
                    <b-icon
                      icon="x"
                      font-scale="1.5"
                    />
                  </b-button>
                </div>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
          </div>

          <div class="d-flex align-items-center">
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="[5, 10, 25, 50]"
              style="width: 70px"
              class="mr-2"
            />
            <b-pagination
              v-model="currentPage"
              :total-rows="infoMessages.length"
              :per-page="perPage"
              size="sm"
              class="m-0"
            />
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

export default {
  name: 'InfoMessage',
  page: {
    title: 'CRM Doctis - Информационные сообщения',
  },
  mixins: [mixinRoles],

  data() {
    return {
      isLoading: false,
      perPage: 10,
      regions: [],
      currentPage: 1,
      infoMessageTypes: {
        0: 'Критичное',
        1: 'Требует внимания',
        2: 'Информационное',
      },
    };
  },

  computed: {
    fields() {
      return [
        {
          key: 'title',
          label: 'Заголовок',
          thStyle: { width: '20%' },
          class: 'cell-xl',
        },
        {
          key: 'message',
          label: 'Сообщение',
          thStyle: { width: '20%' },
          class: 'cell-xl',
        },
        {
          key: 'link',
          label: 'Ссылка',
          thStyle: { width: '10%' },
          class: 'cell-md',
        },
        {
          key: 'linkTitle',
          label: 'Заголовок ссылки',
          thStyle: { width: '20%' },
          class: 'cell-xl',
        },
        {
          key: 'type',
          label: 'Тип',
          thStyle: { width: '10%' },
          class: 'cell-md',
        },
        {
          key: 'region.id',
          label: 'Регион',
          thStyle: { width: '10%' },
          class: 'cell-md',
        },
        {
          key: 'isActive',
          label: 'Показывать',
          thStyle: { width: '5%' },
          class: 'cell-sm',
        },
        {
          key: 'Редактировать',
          label: '',
          thStyle: { width: '5%' },
          class: 'cell-sm',
        },
      ];
    },
    infoMessages() {
      return this.$store.state.InfoMessages.infoMessages;
    },
    totalCount() {
      return this.$store.state.InfoMessages.totalCount;
    },
  },
  created() {
    this.infoMessagesFetch();
    this.regionsFetch();
  },
  methods: {
    openInfoMessageCreateModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InfoMessageCreateModal',
        props: {
          regions: this.regions,
        },
      });
    },
    openInfoMessageEditModal(messageItem) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InfoMessageCreateModal',
        props: {
          regions: this.regions,
          messageItem,
        },
      });
    },
    openInfoMessageDeleteModal(messageItem) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InfoMessageDeleteModal',
        props: {
          messageItem,
        },
      });
    },
    async infoMessagesFetch() {
      try {
        await this.$store.dispatch(this.$types.INFO_MESSAGES_FETCH);
      } catch (e) {
        console.warn(e);
      }
    },
    async regionsFetch() {
      try {
        this.regions = await this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS);
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.messages {
  display: flex;
  flex-direction: column;
  width: 100%;
}

::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}
.message-list {
  height: 100vh;
}

::v-deep.timepicker {
  width: auto;

  .display-time {
    width: 100%;
  }
}

.messages-info-wrapper {
  height: calc(100vh - 265px);
  overflow: auto;
}
</style>
