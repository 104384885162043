import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage /* appendFormData */ } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.FETCH_BANNERS](_, params) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/Banner/list-new', {
        params,
      });

      return data;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При получении баннеров произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.UPDATE_BANNER](_, [
    params,
    body,
  ]) {
    NProgress.start();

    try {
      const response = await api.put('v2/banner/update', body, {
        params,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Банер обновлен',
        });
      }

      return response;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При обновлении баннера произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CREATE_BANNER](_, [
    params,
    body,
  ]) {
    NProgress.start();

    try {
      const response = await api.post('v2/banner/create', body, {
        params,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Банер создан',
        });
      }

      return response;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При создании баннера произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DELETE_BANNER](_, id) {
    NProgress.start();

    try {
      const response = await api.delete('v2/Banner', {
        params: { id },
      });

      if (response.status === 200) {
        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Банер удален',
        });
      }
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При удалении баннера произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REOPEN_BANNER](_, id) {
    NProgress.start();

    try {
      const response = await api.put('v2/Banner/restore', null, {
        params: { id },
      });

      if (response.status === 200) {
        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Банер восстановлен',
        });
      }
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При восстановлении баннера произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
};
