<template>
  <b-modal
    v-model="isOpen"
    centered
    :title="title"
    @hidden="onClose"
    @ok="onClose"
  >
    <p>
      {{ text }}
    </p>

    <template #modal-footer>
      <b-button
        class="float-right"
        variant="danger"
        :disabled="loading"
        @click="onClose"
      >
        Нет
      </b-button>

      <b-button
        variant="primary"
        class="float-right"
        :disabled="loading"
        @click="onSave"
      >
        Да
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AgreeModal',

  props: {
    text: {
      type: String,
      default: 'Вы уверены?',
    },
    title: {
      type: String,
      default: '',
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    cb: {
      type: Function,
      default: () => {},
    },
    cbArgs: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    isOpen: true,
    loading: false,
  }),

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      this.loading = true;

      try {
        await this.cb(...this.cbArgs);
      } finally {
        this.onClose();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
