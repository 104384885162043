<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    :title="`Устройства врача ${fio}`"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="content-wrapper">
        <div v-if="devices.length">
          <b-table
            :bordered="true"
            :fields="fields"
            :items="devices"
          >
            <!-- <template v-slot:cell(startDate)="row">
              <span>{{ parseData(row.item.startDate) }}</span>
            </template>
            -->
            <template v-slot:cell(deviceId)="row">
              <span class="device-id">{{ row.item.deviceId }}</span>
            </template>
          </b-table>
        </div>

        <div v-else>
          Устройства не найдены
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        @click="openDeleteModal"
      >
        Удалить все устройства
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="onClose"
      >
        Закрыть
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { CERTIFICATE_TYPE, CLIENT_TYPE, CLIENT_PLATFORM } from '@/helpers/consts';

export default {
  name: 'DoctorDeviceModal',
  components: {
    Preloader,
  },
  props: {
    personId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    fio: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      devices: [],
      fields: [
        {
          key: 'certificateType',
          label: 'Тип сертификата',
          formatter: (value) => CERTIFICATE_TYPE[value],
        },
        {
          key: 'clientPlatform',
          label: 'Платформа клиента',
          formatter: (value) => CLIENT_PLATFORM[value],
        },
        {
          key: 'clientType',
          label: 'Тип клиента',
          formatter: (value) => CLIENT_TYPE[value],
        },
        {
          key: 'deviceId',
          label: 'Id устройства',
        },
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'isEnabled',
          label: 'Статус',
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value) => value ? 'Включен' : 'Отключен',
        },
      ],
    };
  },
  computed: {},
  async created() {
    try {
      this.isLoading = true;
      this.devices = await this.$store.dispatch(this.$types.GET_USER_DEVICE_ID, this.personId);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openDeleteModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorDeviceDeleteModal',
        props: {
          personId: this.personId,
          afterDelete: this.onClose,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  min-height: 300px;
}

.device-id {
  word-break: break-all;
}
</style>
