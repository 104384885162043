import AgreeModal from '@/components/common/modals/AgreeModal';
import FilesUploadModal from '@/components/common/modals/FilesUploadModal';
import AttachPatientModal from '@/components/FamilyPolicies/Modals/AttachPatientModal';
import StatusRequestModal from '@/components/Prices/Modals/StatusRequestModal';

import AppealHistoryModal from '@/components/Appeals/Modals/AppealHistoryModal';
import EditingServicesModal from '@/components/GluingServices/Modals/EditingServicesModal';
import PromocodeEditModalNew from '@/components/Promocodes/Modals/PromocodeEditModalNew';
import SpecializationEditModal from '@/components/Specializations/Modals/SpecializationEditModal';
import DownloadExcelModal from '@/components/Statistics/Modals/DownloadExcelModal';
import DoctorEditModal from '@/components/Doctors/Modals/DoctorEditModal';
import EditLaboratoryServiceModal from '@/components/Laboratories/Modals/Services/EditLaboratoryServiceModal';
import IndemnityPriceTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateAddModal';
import IndemnityPriceTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateEditModal';
import IndemnityPriceTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceTemplateDeleteModal';
import IndemnityPriceClinicTemplateAddModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateAddModal';
import IndemnityPriceClinicTemplateEditModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateEditModal';
import IndemnityPriceClinicTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityPriceClinicTemplateDeleteModal';
import ConsultationHistoryModal from '@/components/Consultations/Modals/ConsultationHistoryModal';
import ConsultationTransferModal from '@/components/Consultations/Modals/ConsultationTransferModal';
import ConsultationDeletingModal from '@/components/Consultations/Modals/ConsultationDeletingModal';
import EditBannerModal from '@/components/Settings/BannerManagement/Modals/EditBannerModal';
import BannerPreviewModal from '@/components/Settings/BannerManagement/Modals/BannerPreviewModal';
import ConsultationCancelModal from '@/components/Consultations/Modals/ConsultationCancelModal';
import ScheduleMakeAnAppointmentModal from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/index';
import ScheduleTemplateEditModal from '@/components/Schedule/Modals/ScheduleTemplateEditModal';
import ScheduleTemplatesModal from '@/components/Schedule/Modals/ScheduleTemplatesModal';
import ScheduleModal from '@/components/Schedule/Modals/SсheduleModal';
import ScheduleInformationModal from '@/components/Schedule/Modals/ScheduleInformationModal';
import LaboratoryPromocodeEditModalNew from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodeEditModalNew';
import CombinePoliciesModal from '@/components/PolicyList/Modal/CombinePoliciesModal';
import DeletePolicyModal from '@/components/PolicyList/Modal/DeletePolicyModal';
import RegionEditModal from '@/components/Clinics/Regions/Modals/RegionEditModal';
import TaskViewModal from '../../../components/TaskTracker/Modals/TaskViewModal';
import UserViewModal from '../../../components/Users/Modals/UserViewModal';
import UserDeleteModal from '../../../components/Users/Modals/UserDeleteModal';
import UserClinicDeleteModal from '../../../components/Users/Modals/UserClinicDeleteModal';
import PolicyCreateModal from '../../../components/InsurancePrograms/Modals/PolicyCreateModal';
import PolicyViewModal from '../../../components/PolicyList/PolicyViewModal';
import CityCreateModal from '../../../components/Clinics/Modals/CityCreateModal';
import ClinicResponsibleModal from '../../../components/Clinics/Modals/ClinicResponsibleModal';
import ClinicResponsibleClinicsModal from '../../../components/Clinics/Modals/ClinicResponsibleClinicsModal';
import ConsultationControlModal from '../../../components/Consultations/Modals/ConsultationControlModal';
import ConsultationControlExportModal from '../../../components/Consultations/Modals/ConsultationControlExportModal';
import CheckupExportModal from '../../../components/Statistics/Modals/CheckupExportModal';
import ClinicHistoryModal from '../../../components/Clinics/Modals/ClinicHistoryModal';
import AddClinicDownloadFileModal from '../../../components/Clinics/Modals/AddClinicDownloadFileModal';
import EditClinicDocument from '../../../components/Clinics/Modals/EditClinicDocument';
import AddClinicDocument from '../../../components/Clinics/Modals/AddClinicDocument';
import AttachPolicyModal from '../../../components/Patients/Modals/AttachPolicyModal';
import PolicyHasIndemnityModal from '../../../components/Patients/Modals/PolicyHasIndemnityModal';
import PolicyLimitsModal from '../../../components/Patients/Modals/PolicyLimitsModal';
import PatientDeviceModal from '../../../components/Patients/Modals/PatientDeviceModal';
import PatientDeviceDeleteModal from '../../../components/Patients/Modals/PatientDeviceDeleteModal';
import DoctorReestablishModal from '../../../components/Doctors/Modals/DoctorReestablishModal';
import DoctorDeviceModal from '../../../components/Doctors/Modals/DoctorDeviceModal';
import DoctorDeviceDeleteModal from '../../../components/Doctors/Modals/DoctorDeviceDeleteModal';
import DoctorAvatarAddModal from '../../../components/Doctors/Modals/DoctorAvatarAddModal';
import DoctorAvatarDeleteModal from '../../../components/Doctors/Modals/DoctorAvatarDeleteModal';
import ClinicRecoveryModal from '../../../components/Clinics/Modals/ClinicRecoveryModal';
import PricesHistoryModal from '../../../components/Prices/PricesHistoryModal';
import PriceHistoryModal from '../../../components/Prices/PriceHistoryModal';
import PricesImportInClinic from '../../../components/Prices/PricesImportInClinic';
import PricesListViewModal from '../../../components/Prices/PricesListViewModal';
import AddPartnerModal from '../../../components/Laboratories/Modals/Partners/AddPartnerModal';
import EditPartnerModal from '../../../components/Laboratories/Modals/Partners/EditPartnerModal';
import AddLegalPartnerModal from '../../../components/Laboratories/Modals/LegalPartners/AddLegalPartnerModal';
import EditLegalPartnerModal from '../../../components/Laboratories/Modals/LegalPartners/EditLegalPartnerModal';
import RoleEditModal from '../../../components/Roles/Modals/RoleEditModal';
import PageEditModal from '../../../components/Pages/Modals/PageEditModal';
import ImportPartnerService from '../../../components/Laboratories/Modals/PartnerService/ImportPartnerService';
import ImportServices from '../../../components/Laboratories/Modals/Services/ImportServices';
import UploadOrderAnalysis from '../../../components/Laboratories/Modals/Orders/UploadOrderAnalysis';
import InsuranceSubprogramUploadFile from '../../../components/InsurancePrograms/Modals/InsuranceSubprogramUploadFile';
import PartnerServiceMap from '../../../components/Laboratories/Modals/PartnerService/PartnerServiceMap';
import AddBranchModal from '../../../components/Laboratories/Modals/Branches/AddBranchModal';
import ImportBranches from '../../../components/Laboratories/Modals/Branches/ImportBranches';
import EditBranchModal from '../../../components/Laboratories/Modals/Branches/EditBranchModal';
import AddServiceModal from '../../../components/Laboratories/Modals/Services/AddServiceModal';
import CityEditModal from '../../../components/Laboratories/Modals/Cities/CityEditModal';
import AddTagModal from '../../../components/Laboratories/Modals/Tags/AddTagModal';
import EditTagModal from '../../../components/Laboratories/Modals/Tags/EditTagModal';
import QueueDeleteModal from '../../../components/Queues/Modals/QueueDeleteModal';
import QueuesTagDeleteModal from '../../../components/Queues/Modals/QueuesTagDeleteModal';
import QueuesTagEditModal from '../../../components/Queues/Modals/QueuesTagEditModal';
import QueueEditModal from '../../../components/Queues/Modals/QueueEditModal';
import SmsSendModal from '../../../components/Sms/Modals/SmsSendModal';
import SmsSendingConfirmation from '../../../components/Sms/Modals/SmsSendingConfirmation';
import SmsSending from '../../../components/Sms/Modals/SmsSending';
import SmsTemplateEditModal from '../../../components/Sms/Modals/SmsTemplateEditModal';
import SmsTemplateDeleteModal from '../../../components/Sms/Modals/SmsTemplateDeleteModal';
import DiseaseDeleteModal from '../../../components/Diseases/Modals/DiseaseDeleteModal';
import InfoMessageCreateModal from '../../../components/InfoMessage/Modals/InfoMessageCreateModal';
import InfoMessageDeleteModal from '../../../components/InfoMessage/Modals/InfoMessageDeleteModal';
import StoryDeleteModal from '../../../components/Story/Modals/StoryDeleteModal';

export default [
  {
    name: 'AgreeModal',
    Component: AgreeModal,
  },
  {
    name: 'AppealHistoryModal',
    Component: AppealHistoryModal,
  },
  {
    // id: 0,
    // props: {},
    name: 'TaskViewModal',
    Component: TaskViewModal,
  },
  {
    name: 'UserViewModal',
    Component: UserViewModal,
  },
  {
    name: 'StatusRequestModal',
    Component: StatusRequestModal,
  },
  {
    name: 'EditingServicesModal',
    Component: EditingServicesModal,
  },
  {
    name: 'UserDeleteModal',
    Component: UserDeleteModal,
  },
  {
    name: 'UserClinicDeleteModal',
    Component: UserClinicDeleteModal,
  },
  {
    name: 'PolicyCreateModal',
    Component: PolicyCreateModal,
  },
  {
    name: 'PolicyViewModal',
    Component: PolicyViewModal,
  },
  {
    name: 'CombinePoliciesModal',
    Component: CombinePoliciesModal,
  },
  {
    name: 'DeletePolicyModal',
    Component: DeletePolicyModal,
  },
  {
    name: 'PolicyHasIndemnityModal',
    Component: PolicyHasIndemnityModal,
  },
  {
    name: 'PolicyLimitsModal',
    Component: PolicyLimitsModal,
  },
  {
    name: 'PromocodeEditModal',
    Component: PromocodeEditModalNew,
  },
  {
    name: 'LaboratoryPromocodeEditModal',
    Component: LaboratoryPromocodeEditModalNew,
  },
  {
    name: 'SpecializationEditModal',
    Component: SpecializationEditModal,
  },
  {
    name: 'CityCreateModal',
    Component: CityCreateModal,
  },
  {
    name: 'ConsultationHistoryModal',
    Component: ConsultationHistoryModal,
  },
  {
    name: 'ConsultationTransferModal',
    Component: ConsultationTransferModal,
  },
  {
    name: 'ConsultationDeletingModal',
    Component: ConsultationDeletingModal,
  },
  {
    name: 'EditBannerModal',
    Component: EditBannerModal,
  },
  {
    name: 'BannerPreviewModal',
    Component: BannerPreviewModal,
  },
  {
    name: 'ConsultationCancelModal',
    Component: ConsultationCancelModal,
  },
  {
    name: 'ConsultationControlModal',
    Component: ConsultationControlModal,
  },
  {
    name: 'ConsultationControlExportModal',
    Component: ConsultationControlExportModal,
  },
  {
    name: 'CheckupExportModal',
    Component: CheckupExportModal,
  },
  {
    name: 'ScheduleMakeAnAppointmentModal',
    Component: ScheduleMakeAnAppointmentModal,
  },
  {
    name: 'ScheduleTemplateEditModal',
    Component: ScheduleTemplateEditModal,
  },
  {
    name: 'ScheduleTemplatesModal',
    Component: ScheduleTemplatesModal,
  },
  {
    name: 'ScheduleModal',
    Component: ScheduleModal,
  },
  {
    name: 'ScheduleInformationModal',
    Component: ScheduleInformationModal,
  },
  {
    name: 'DownloadExcelModal',
    Component: DownloadExcelModal,
  },
  {
    name: 'ClinicHistoryModal',
    Component: ClinicHistoryModal,
  },
  {
    name: 'AttachPolicyModal',
    Component: AttachPolicyModal,
  },
  {
    name: 'PatientDeviceModal',
    Component: PatientDeviceModal,
  },
  {
    name: 'PatientDeviceDeleteModal',
    Component: PatientDeviceDeleteModal,
  },
  {
    name: 'AttachPatientModal',
    Component: AttachPatientModal,
  },
  {
    name: 'DoctorDeviceModal',
    Component: DoctorDeviceModal,
  },
  {
    name: 'DoctorDeviceDeleteModal',
    Component: DoctorDeviceDeleteModal,
  },
  {
    name: 'DoctorAvatarAddModal',
    Component: DoctorAvatarAddModal,
  },
  {
    name: 'DoctorAvatarDeleteModal',
    Component: DoctorAvatarDeleteModal,
  },
  {
    name: 'DoctorEditModal',
    Component: DoctorEditModal,
  },
  {
    name: 'AddClinicDownloadFileModal',
    Component: AddClinicDownloadFileModal,
  },
  {
    name: 'EditClinicDocument',
    Component: EditClinicDocument,
  },
  {
    name: 'AddClinicDocument',
    Component: AddClinicDocument,
  },
  {
    name: 'DoctorReestablishModal',
    Component: DoctorReestablishModal,
  },
  {
    name: 'ClinicResponsibleModal',
    Component: ClinicResponsibleModal,
  },
  {
    name: 'ClinicResponsibleClinicsModal',
    Component: ClinicResponsibleClinicsModal,
  },
  {
    name: 'ClinicRecoveryModal',
    Component: ClinicRecoveryModal,
  },
  {
    name: 'PricesHistoryModal',
    Component: PricesHistoryModal,
  },
  {
    name: 'PriceHistoryModal',
    Component: PriceHistoryModal,
  },
  {
    name: 'PricesImportInClinic',
    Component: PricesImportInClinic,
  },
  {
    name: 'PricesListViewModal',
    Component: PricesListViewModal,
  },
  {
    name: 'AddPartnerModal',
    Component: AddPartnerModal,
  },
  {
    name: 'EditPartnerModal',
    Component: EditPartnerModal,
  },
  {
    name: 'AddTagModal',
    Component: AddTagModal,
  },
  {
    name: 'EditTagModal',
    Component: EditTagModal,
  },

  {
    name: 'AddLegalPartnerModal',
    Component: AddLegalPartnerModal,
  },
  {
    name: 'EditLegalPartnerModal',
    Component: EditLegalPartnerModal,
  },
  {
    name: 'RoleEditModal',
    Component: RoleEditModal,
  },
  {
    name: 'PageEditModal',
    Component: PageEditModal,
  },
  {
    name: 'ImportPartnerService',
    Component: ImportPartnerService,
  },
  {
    name: 'ImportServices',
    Component: ImportServices,
  },
  {
    name: 'UploadOrderAnalysis',
    Component: UploadOrderAnalysis,
  },
  {
    name: 'PartnerServiceMap',
    Component: PartnerServiceMap,
  },
  {
    name: 'AddBranchModal',
    Component: AddBranchModal,
  },
  {
    name: 'EditBranchModal',
    Component: EditBranchModal,
  },
  {
    name: 'AddServiceModal',
    Component: AddServiceModal,
  },
  {
    name: 'EditLaboratoryServiceModal',
    Component: EditLaboratoryServiceModal,
  },
  {
    name: 'CityEditModal',
    Component: CityEditModal,
  },
  {
    name: 'ImportBranches',
    Component: ImportBranches,
  },
  {
    name: 'QueueDeleteModal',
    Component: QueueDeleteModal,
  },
  {
    name: 'QueuesTagDeleteModal',
    Component: QueuesTagDeleteModal,
  },
  {
    name: 'QueuesTagEditModal',
    Component: QueuesTagEditModal,
  },
  {
    name: 'QueueEditModal',
    Component: QueueEditModal,
  },
  {
    name: 'SmsSendModal',
    Component: SmsSendModal,
  },
  {
    name: 'SmsSending',
    Component: SmsSending,
  },
  {
    name: 'SmsSendingConfirmation',
    Component: SmsSendingConfirmation,
  },
  {
    name: 'SmsTemplateEditModal',
    Component: SmsTemplateEditModal,
  },
  {
    name: 'SmsTemplateDeleteModal',
    Component: SmsTemplateDeleteModal,
  },
  {
    name: 'DiseaseDeleteModal',
    Component: DiseaseDeleteModal,
  },
  {
    name: 'InsuranceSubprogramUploadFile',
    Component: InsuranceSubprogramUploadFile,
  },
  {
    name: 'FilesUploadModal',
    Component: FilesUploadModal,
  },

  {
    name: 'IndemnityPriceTemplateAddModal',
    Component: IndemnityPriceTemplateAddModal,
  },
  {
    name: 'IndemnityPriceTemplateEditModal',
    Component: IndemnityPriceTemplateEditModal,
  },
  {
    name: 'IndemnityPriceTemplateDeleteModal',
    Component: IndemnityPriceTemplateDeleteModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateAddModal',
    Component: IndemnityPriceClinicTemplateAddModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateEditModal',
    Component: IndemnityPriceClinicTemplateEditModal,
  },
  {
    name: 'IndemnityPriceClinicTemplateDeleteModal',
    Component: IndemnityPriceClinicTemplateDeleteModal,
  },
  {
    name: 'RegionEditModal',
    Component: RegionEditModal,
  },
  {
    name: 'InfoMessageCreateModal',
    Component: InfoMessageCreateModal,
  },
  {
    name: 'InfoMessageDeleteModal',
    Component: InfoMessageDeleteModal,
  },
  {
    name: 'StoryDeleteModal',
    Component: StoryDeleteModal,
  },
];
