<template>
  <div>
    <p
      v-if="$slots.label"
      class="h5 pb-3"
    >
      <slot name="label" />
    </p>

    <div
      v-for="(doctor, index) in doctors"
      :key="index"
      class="mt-4"
    >
      <doctor-info
        :doctor="doctor"
        :is-pinned="isPinned(doctor)"
        :mode-pin="modePin"
        @toggle-pin="$emit('toggle-pin', doctor)"
      />
    </div>
  </div>
</template>

<script>
import DoctorInfo from '@/components/Doctors/DoctorInfo';

export default {
  name: 'DoctorsList',
  components: {
    DoctorInfo,
  },

  props: {
    doctors: {
      type: Array,
      default: () => ([]),
    },
    modePin: {
      type: Boolean,
      default: false,
    },
    pinnedDoctors: {
      type: Array,
      default: () => ([]),
    },
  },
  methods: {
    isPinned(doctor) {
      return this.pinnedDoctors.findIndex((doc) => doc.id === doctor.id) !== -1;
    },
  },
};
</script>

<style scoped>

</style>
