import mutations from './mutations';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    tasks: [],
    filter: {
      searchString: null,
      createDate: null,
      status: null,
    },
    isLoading: false,
    isAllItemsLoaded: false,
  },
  mutations,
  actions,
};
