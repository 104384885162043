<template>
  <div class="appeal-detail">
    <custom-scrollbar>
      <div class="crm-inner">
        <div class="crm-title-row mb-4">
          <div class="crm-title mb-0">
            Информация об обращении
          </div>
          <div class="d-flex align-items-center">
            <!-- !checkRoleAccess_(['polis.operator.dop', 'Dis.monitoring.admin']) -->
            <b-button
              v-if="appeal.hasIndemnityLetters && checkFeatureAccess({ name: 'Пациент детальная информация. История ГП', url: '/patients' })"
              :type="$const.SECONDARY_BUTTON"
              class="mr-2"
              variant="outline-primary"
              size="sm"
              @click="openIndemnityHistoryModal"
            >
              История ГП
            </b-button>

            <icon-message
              class="icon-message crm-icon crm-border"
              @click="openSmsSendModal"
            />
            <a
              :href="`tel:${appeal.person.phoneNumber}`"
              class="crm-icon crm-border call-link"
            >
              <b-icon
                icon="telephone"
                height="20"
                width="20"
                style="fill: rgb(75, 98, 193)"
              />
            </a>
            <icon-history
              class="icon-history crm-icon"
              @click="openAppealHistoryModal"
            />
            <icon-edit
              class="edit-icon crm-icon"
              @click="openAppealEditorModal"
            />
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Статус
            </div>
            <div
              class="crm-value"
              :class="getStatusColorAppeal(appeal.status)"
            >
              {{ getStatusText(appeal.status, true) }}
            </div>
          </div>

          <div class="crm-column">
            <div class="crm-subtitle">
              ID обращения
            </div>
            <div class="crm-value">
              {{ appeal.id }}
            </div>
          </div>

          <div class="crm-column">
            <div class="crm-subtitle">
              Дата и время
            </div>
            <div class="crm-value">
              {{ getDate(appeal.dateTime) }}
            </div>
          </div>

          <div
            v-if="appeal.urgency || appeal.purpose === 2"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Срочность
            </div>
            <div
              class="crm-value"
              :class="appeal.urgency && 'red'"
            >
              {{ appeal.urgency ? 'Срочно' : 'Не срочно' }}
            </div>
          </div>
        </div>

        <div
          v-if="appeal.purpose === 1 && appeal.clinic"
          class="crm-row"
        >
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Клиника
            </div>
            <div class="crm-value">
              {{ appeal.clinic.title }}
            </div>
          </div>
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Город
            </div>
            <div class="crm-value">
              {{ appeal.clinic.address }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Цель обращения
            </div>
            <div class="crm-value">
              {{ purposes[appeal.purpose] }}
            </div>
          </div>

          <div
            v-if="appeal.type"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Тип обращения
            </div>
            <div class="crm-value">
              {{ typeTitle }}
            </div>
          </div>

          <div
            v-if="appeal.typeAnotherText"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Другой тип обращения
            </div>
            <div class="crm-value">
              {{ appeal.typeAnotherText }}
            </div>
          </div>

          <div
            v-if="appeal.gostelemedType"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Гостелемед
            </div>
            <div class="crm-value">
              {{ appeal.gostelemedType.title }}
            </div>
          </div>

          <div
            v-if="appeal.rejectionReason"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Причина отказа
            </div>
            <div class="crm-value">
              {{ appeal.rejectionReason }}
            </div>
          </div>

          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Профиль
            </div>
            <div
              v-if="appeal.checkupProfile"
              class="crm-value"
            >
              {{ appeal.checkupProfile.title }}
            </div>
            <div
              v-else
              class="crm-value"
            >
              {{ '---' }}
            </div>
          </div>
        </div>

        <div
          v-if="appeal.selectedPolicy"
          class="crm-row crm-row-1"
        >
          <div class="crm-column">
            <div class="crm-subtitle">
              Полис
            </div>
            <div class="crm-value">
              <PatientDetailPolicy
                :policy="appeal.selectedPolicy"
                :person-id="appeal.person.id"
                :show-new-indemnity-button="false"
              />
            </div>
          </div>
        </div>

        <div class="crm-row crm-row-1">
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий
            </div>
            <div class="crm-value">
              {{ appeal.comment }}
            </div>
          </div>
        </div>

        <div class="d-flex my-4">
          <div
            v-if="appeal.responsible"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Ответственный
            </div>
            <div class="crm-value">
              {{ appeal.responsible.displayName }}
            </div>
          </div>
          <div
            v-if="appeal.responsibleOperator"
            class="crm-column"
          >
            <div class="crm-subtitle">
              Оператор ответственный за обращение
            </div>
            <div class="crm-value">
              {{ appeal.responsibleOperator.displayName }}
            </div>
          </div>
        </div>

        <div
          v-if="appeal.fileIds.length"
          class="crm-row crm-row-1"
        >
          <div
            class="crm-column"
          >
            <div class="crm-subtitle">
              Файлы
            </div>
            <div class="crm-value">
              <div
                v-for="(file) in getComputedFiles(appeal.fileIds)"
                :key="file.id"
                class="appeal-file"
              >
                <base-file
                  :file="file"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="reconciliation in appeal.reconciliations"
          :key="reconciliation.id"
          class="crm-row crm-row-1"
        >
          <base-dropdown
            :open="false"
            header="Информация о согласовании"
          >
            <div class="crm-row">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Статус
                </div>
                <div
                  class="crm-value"
                  :class="getStatusColor(reconciliation.status)"
                >
                  {{ getStatusText(reconciliation.status) }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  ID согласования
                </div>
                <div class="crm-value">
                  {{ reconciliation.id }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Дата и время
                </div>
                <div class="crm-value">
                  {{ getDate(reconciliation.createDate) }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  ID связанного обращения
                </div>
                <div class="crm-value">
                  {{ reconciliation.appealId }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-2">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Согласованные услуги
                </div>
                <div class="crm-value">
                  {{ reconciliation.agreedServices }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Название клиники
                </div>
                <div class="crm-value">
                  {{ reconciliation.clinic.name }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-2">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Несогласованные услуги
                </div>
                <div class="crm-value">
                  {{ reconciliation.notAgreedServices }}
                </div>
              </div>

              <div class="crm-column">
                <div class="crm-subtitle">
                  Причина отказа
                </div>
                <div class="crm-value">
                  {{ reconciliation.regectionReason }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.diagnosis"
              class="crm-row crm-row-1"
            >
              <div
                class="crm-column"
              >
                <div class="crm-subtitle">
                  Диагноз
                </div>
                <div class="crm-value">
                  {{ reconciliation.diagnosis }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-1">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Комментарий врача-куратора
                </div>
                <div class="crm-value">
                  {{ reconciliation.doctorComment }}
                </div>
              </div>
            </div>

            <div class="crm-row crm-row-1">
              <div class="crm-column">
                <div class="crm-subtitle">
                  Комментарий для оператора
                </div>
                <div class="crm-value">
                  {{ reconciliation.commentForOperator }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.responsible"
              class="crm-row crm-row-1"
            >
              <div class="crm-column">
                <div class="crm-subtitle">
                  Ответственный за согласование
                </div>
                <div class="crm-value">
                  {{ reconciliation.responsible.displayName }}
                </div>
              </div>
            </div>

            <div
              v-if="reconciliation.files.length"
              class="crm-row crm-row-1"
            >
              <div
                class="crm-column"
              >
                <div class="crm-subtitle">
                  Файлы
                </div>
                <div class="crm-value">
                  <div
                    v-for="(file) in getComputedFiles(reconciliation.files)"
                    :key="file.id"
                    class="appeal-file"
                  >
                    <base-file
                      :file="file"
                    />
                  </div>
                </div>
              </div>
            </div>
          </base-dropdown>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { parseISO, format } from 'date-fns';
import { mixinRoles } from '@/mixins';

import {
  BaseFile,
  BaseDropdown,
} from '@/components/base';

import PatientDetailPolicy from '@/components/Patients/PatientDetailPolicy';

import IconMessage from 'assets/images/message.svg';
import IconHistory from 'assets/images/icon_history.svg';
import IconEdit from 'assets/images/edit_icon.svg';

export default {
  name: 'PatientDetailAppeal',
  components: {
    BaseDropdown,
    BaseFile,
    PatientDetailPolicy,
    IconHistory,
    IconEdit,
    IconMessage,
  },
  mixins: [mixinRoles],
  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: {
        0: 'Не выбрано',
        1: 'В работе',
        2: 'Готово',
        3: 'Передано',
        4: 'Отложено',
      },
      statusesAppeal: {
        0: 'В работе',
        1: 'Отложено',
        2: 'Закрыто',
      },
      purposes: {
        0: 'Информационное',
        1: 'Чекап',
        2: 'Лечение / плановая госпитализация',
        3: 'Экстренная госпитализация',
      },
    };
  },
  computed: {
    typeTitle() {
      return this.appeal?.type?.title || '';
    },
  },
  methods: {
    getComputedFiles(fileIds) {
      return fileIds.map((file) => ({
        guid: file.id,
        title: file.name,
      }));
    },
    getDate(dateTime) {
      return format(parseISO(dateTime), 'dd.MM.yyyy HH:mm');
    },
    getStatusColor(status) {
      const isGreen = [1, 2, 3].includes(status);

      if (status === 0) return 'red';
      if (isGreen) return 'green';
      if (status === 4) return 'yellow';

      return '';
    },
    getStatusColorAppeal() {
      if (this.appeal.status === 0) return 'green';
      if (this.appeal.status === 1) return 'yellow';
      if (this.appeal.status === 2) return 'red';

      return '';
    },
    getStatusText(status, isAppeal) {
      return isAppeal ? this.statusesAppeal[status] : this.statuses[status];
    },
    openSmsSendModal() {
      const { firstName, middleName } = this.appeal.person;
      const middleNameString = middleName ? ` ${middleName}` : '';
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: this.appeal.person.phoneNumber,
          name: `${firstName || ''}${middleNameString}`,
        },
      });
    },
    openAppealHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AppealHistoryModal',
        props: {
          appealId: this.appeal.id,
        },
      });
      // Bus.$emit('open-modal:appeal-history', this.appeal.id);
    },
    openIndemnityHistoryModal() {
      Bus.$emit('open-modal:indemnity-history-modal', {
        personId: this.appeal.person.id,
      });
    },
    openAppealEditorModal() {
      Bus.$emit('open-modal:appeal-editor', {
        initAppealData: this.appeal,
        initPatientData: {
          person: this.appeal.person,
          policies: this.patient.policies,
        },
        afterSaveFunc: () => Bus.$emit('patient-detail-appeals:get-appeals'),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-detail {
  box-sizing: border-box;
  height: 100%;
  flex: 1;
  width: calc(100% - 280px);
}

.crm-inner {
  height: 100%;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}

.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}

.crm-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc((100% - 60px)/4));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  width: 100%;

  &-1 {
    grid-template-columns: repeat(auto-fit, 100%);
  }

  &-2 {
    grid-template-columns: repeat(auto-fit, calc((100% - 20px)/2));
  }

  & + & {
    margin-top: 30px;
  }
}

.crm-column {
  flex: 1;
  max-width: 100%;
  word-break: break-word;
}

.crm-value {
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  position: relative;
  width: 100%;

  &.green {
    color: $green;
  }
  &.yellow {
    color: #F2994A;
  }
  &.red {
    color: $red;
  }
}

.appeal-file {
  margin-top: 10px;
}

::v-deep.base-dropdown-wrapper {
  margin-bottom: 0;
}

.crm-title-row {
  display: flex;
  justify-content: space-between;
}

.crm-icon {
  margin-left: 10px;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: 0.5;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}

.edit-icon {
  path {
    fill: $blue;
  }
}

.call-link {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
