<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div class="h3">
          Список правил
        </div>

        <b-button
          variant="primary"
          class="ml-2"
          size="sm"
          @click="choseRule()"
        >
          Добавить новое правило
        </b-button>
      </div>

      <div class="w-100 mt-3 mb-3">
        <b-input-group class="bg-white border border-primary rounded">
          <b-form-input
            v-model="rule"
            type="text"
            debounce="500"
            placeholder="Поиск по названию правила"
            class="border-0"
            @keyup.enter.native="searchRule()"
          />

          <b-button
            variant="outline-primary"
            class="rounded-0 border-0"
            @click="searchRule()"
          >
            <b-icon icon="search" />
          </b-button>
        </b-input-group>
      </div>

      <div
        v-if="!isLoading"
        class="w-100"
      >
        <b-table
          v-if="rules.length"
          class="bg-white"
          bordered
          :fields="fieldsRules"
          :items="rules"
        >
          <template #cell(isActive)="row">
            <b-badge
              v-if="row.item.isActive"
              variant="success"
            >
              Активно
            </b-badge>
            <b-badge
              v-else
              variant="danger"
            >
              Неактивно
            </b-badge>
          </template>
          <template #cell(action)="row">
            <b-button
              size="sm"
              variant="primary"
              @click="choseRule(row.item.id)"
            >
              Редактировать
            </b-button>
            <!--            <b-button-->
            <!--              size="sm"-->
            <!--              variant="primary"-->
            <!--              @click="deleteRule(row.item.id)"-->
            <!--            >-->
            <!--              Удалить-->
            <!--            </b-button>-->
          </template>
          <template #cell(updatedAt)="row">
            {{ new Date(row.item.updatedAt).toLocaleDateString() }}
          </template>
        </b-table>
        <div
          v-else
          class="px-3 py-5 text-center"
        >
          Ничего не найдено
        </div>
      </div>
      <Preloader
        v-else
        style="margin-top: 20px"
      />
    </b-container>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import Preloader from '@/components/Preloader';

export default {
  name: 'RolesPage',
  components: { Preloader },
  data() {
    return {
      isLoading: true,
      rule: '',
      rules: [],
      rulesFull: [],
    };
  },
  computed: {
    fieldsRules() {
      return [
        {
          key: 'id',
          label: 'id',
        },
        {
          key: 'name',
          label: 'Название',
        },
        {
          key: 'description',
          label: 'Описание',
        },
        {
          key: 'medicalSystemRouteId',
          label: 'ID медицинской системы',
        },
        {
          key: 'updatedAt',
          label: 'Дата обновления',
        },
        {
          key: 'isActive',
          label: 'Статус',
        },
        {
          key: 'action',
          label: '',
          class: 'td-button',
        },
      ];
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;

      try {
        const { data } = await api.get('v3/medical-system-consultation-rule');

        if (data?.data) {
          this.rules = data?.data;
          this.rulesFull = data?.data;
        }
      } catch (error) {
        this.$bvToast.toast('Ошибка при получении правил', {
          title: 'Ошибка',
          autoHideDelay: 5000,
          variant: 'danger',
        });
      }
      this.isLoading = false;
    },
    choseRule(id) {
      this.$router.push(`/rule/${id || 'new'}`);
    },
    searchRule() {
      this.rules = this.rulesFull?.filter((i) => !this.rule || (i.name && i.name.toLowerCase().includes(this.rule?.toLowerCase())));
    },
    async deleteRule(id) {
      await api.delete(`v3/medical-system-consultation-rule/${id}`);
    },
  },
  page: {
    title: 'CRM Doctis - Список правил',
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.bg-white .td-button {
    width: 100px;
  }
</style>
