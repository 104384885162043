<template>
  <div class="p-3 border rounded bg-light">
    <div class="d-flex justify-content-between">
      <div class="crm-info-details">
        <div class="h6">
          {{ specialization.title }}
        </div>
        <div class="small mt-1 text-secondary">
          ID: {{ specialization.id }}
        </div>
      </div>
      <div class="crm-info-controls">
        <b-button
          v-if="checkFeatureAccess({ name: 'Редактировать специальность', url: '/specializations' })"
          variant="primary"
          size="sm"
          class="p-1"
          title="Редактировать"
          @click="openEditModal"
        >
          <b-icon icon="pencil-square" />
        </b-button>
        <b-button
          v-if="checkFeatureAccess({ name: 'Удалить специальность', url: '/specializations' })"
          variant="danger"
          size="sm"
          class="p-1 mt-2"
          title="Редактировать"
          @click="deleteSpecialization"
        >
          <b-icon icon="trash" />
        </b-button>
      </div>
    </div>

    <div
      :class="[
        specialization.isHidden ? 'text-danger' : 'text-success',
        'mt-2'
      ]"
    >
      {{ specialization.isHidden ? 'Скрыт на сайте' : 'Есть на сайте' }}
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

// import { BaseButton } from '@/components/base';
import { mixinRoles } from '@/mixins';

export default {
  name: 'SpecializationInfo',
  mixins: [mixinRoles],
  props: {
    specialization: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    openEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SpecializationEditModal',
        props: {
          specializationId: this.specialization.id,
        },
      });
    },
    deleteSpecialization() {
      Bus.$emit('open-modal:specialization-delete', this.specialization);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-info-details {
  margin-right: 15px;
}

.crm-info-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
}

.crm-info-id {
  margin-top: 8px;
  color: #908F8D;
  font-size: 12px;
  line-height: 16px;
}

.crm-info-controls {
  display: flex;
  flex-direction: column;

  .crm-icon + .crm-icon {
    margin-top: 15px;
  }
}

::v-deep.crm-icon {
  cursor: pointer;

  &.icon-delete:hover {
    rect {
      stroke: $red;
    }
  }

  &:not(.icon-delete):hover {
    rect {
      stroke: $blue;
    }
    path {
      fill: $blue;
    }
  }
}
</style>
