import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.TOKEN_SET](state, token) {
    state.token = token;
  },
  [localTypes.USER_LOADING_SET](state, payload) {
    state.isUserLoading = payload;
  },
  [localTypes.USER_SET](state, payload) {
    state.user = payload;
  },
  [localTypes.ROLE_SET](state, payload) {
    state.role = payload;
  },
  [localTypes.LOGIN_SET](state, payload) {
    state.login = payload;
  },
};
