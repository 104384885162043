<template>
  <div
    class="clinic-doctors-edit-wrapper"
  >
    <div
      v-if="!isLoading"
      class="crm-form-fields"
    >
      <div class="crm-form-field">
        <base-button
          :type="$const.PRIMARY_BUTTON"
          @click="openPinModal"
        >
          Привязать врача
        </base-button>
      </div>
    </div>
    <div class="clinics-doctors-scroll-content">
      <custom-scrollbar>
        <div
          class="clinic-doctors-edit"
        >
          <Loading
            v-if="isLoading"
            class="crm-loading-wrapper"
          >
            Загрузка клиники
          </Loading>

          <template v-else>
            <!-- <div class="crm-form-fields">
        <div class="crm-form-field">
          <base-async-select
            :value="specialityNull"
            :fetch-function="fetchSpecializations"
            label="Специальности врачей"
            fluid
            @change="selectSpecialization($event)"
          />
        </div>
        <div class="crm-form-field" />
      </div>

      <div class="clinics-specialities">
        <doctor-speciality-item
          v-for="(specialization, index) in sortedSpecializations"
          :key="index"
          :speciality="specialization"
          edit
          class="clinics-speciality"
          @delete="deleteSpecialization(specialization)"
        />
      </div> -->

            <div class="clinics-doctors">
              <doctor-info-item
                v-for="doctor in pinnedDoctors"
                :key="doctor.id"
                class="doctor-item"
                :doctor="doctor"
                edit
                @toggle-pin="togglePin(doctor)"
              />
            </div>
          </template>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons crm-wrapper-buttons_clinic-edit">
      <!-- <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickClose"
      >
        Отменить
      </base-button> -->

      <base-button
        :type="$const.PRIMARY_BUTTON"
        :loading="isSaving"
        class="crm-button"
        @click="onClickSave"
      >
        Сохранить
      </base-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

import { mapGetters, mapActions } from 'vuex';
import * as types from '@/store/types';
import { alphabeticSort } from '@/helpers/utils';

import Bus from '@/eventBus';
import Loading from '@/components/Loading';

import { BaseButton /* , BaseAsyncSelect */ } from '@/components/base';

// import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';
import DoctorInfoItem from '@/components/Clinics/Modals/DoctorInfoItem';

export default {
  name: 'ClinicDoctorsEdit',
  components: {
    BaseButton,
    // BaseAsyncSelect,
    // DoctorSpecialityItem,
    DoctorInfoItem,
    Loading,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      unwatch: null,
      specialityNull: null,
      isSaving: false,
      isLoading: false,

      specializations: [],
      clinicInfo: null,
    };
  },
  computed: {
    ...mapGetters({
      pinnedDoctors: types.GET_DOCTORS_PINNED,
      isChangedDoctorsInfo: types.GET_IS_CHANGED_DOCTORS_INFO,
    }),
    sortedSpecializations() {
      return [...this.specializations].sort((a, b) => alphabeticSort(a.title, b.title));
    },
    resultDoctorsInfo() {
      return {
        specializations: this.specializations,
        doctors: this.pinnedDoctors,
      };
    },
  },
  async created() {
    if (this.id) {
      try {
        this.isLoading = true;
        const clinicInfo = await this.$store.dispatch(this.$types.CLINIC_FETCH, this.id);

        this.clinicInfo = clinicInfo;
        this.specializations = clinicInfo.specializations;
        this.$store.commit(this.$types.CLINICS_PINNED_DOCTORS_SET, clinicInfo.doctors);
      } finally {
        this.isLoading = false;
      }
    }

    this.setWatcher();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CLINICS_PINNED_DOCTORS_SET, []);
  },
  methods: {
    ...mapActions({
      togglePin: types.CLINICS_PINNED_DOCTORS_TOGGLE,
    }),
    onClickClose() {
      if (this.isChangedDoctorsInfo) {
        if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
          return;
        }
      }

      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);
      this.$emit('vuedals:close');
    },
    async onClickSave() {
      this.setWatcher();

      const preformattedClinicInfo = {
        ...this.clinicInfo,
        specializations: this.specializations,
        doctors: this.pinnedDoctors,
      };

      try {
        this.isSaving = true;
        await this.$store.dispatch(this.$types.CLINIC_UPDATE, preformattedClinicInfo);
      } finally {
        this.isSaving = false;
      }
    },
    openPinModal() {
      Bus.$emit('open-modal:doctors-pin');
    },
    setWatcher() {
      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);
      this.unwatch = this.$watch('resultDoctorsInfo',
        () => {
          if (this.unwatch) {
            this.unwatch();
          }
          this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, true);
        },
        {
          deep: true,
        });
    },
    deleteSpecialization(spec) {
      this.specializations = this.specializations.filter((item) => item.id !== spec.id);
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    selectSpecialization({ id, name }) {
      this.specializations = [...this.specializations, { id, title: name }];
    },
  },
};
</script>

<style lang="scss" scoped>
.clinics-doctors-scroll-content {
  height: calc(100vh - 372px);
  margin: 0 -10px;
}
.clinic-doctors-edit {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}
.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
</style>
