<template>
  <div
    class="d-flex align-items-center"
    @click="showFile"
  >
    <b-icon
      v-b-tooltip.hover
      icon="file-earmark-post"
      :title="downloadPermission ? 'Скачать файл': ''"
      :style="{cursor: downloadPermission ? 'pointer': 'default'}"
      class="document-icon"
      @click="$emit('downloadFile', fileName)"
    />

    <div class="ml-3 overflow-hidden">
      {{ fileName }}
    </div>
    <b-button
      v-if="edit"
      variant="outline-danger"
      class="clear-btn rounded-circle ml-4 p-0 d-flex align-items-center justify-content-center"
      @click="onClickDelete"
    >
      <b-icon
        icon="x"
        class="clear-icon"
      />
    </b-button>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';

export default {
  name: 'BaseFile',
  props: {
    file: {
      type: [Object, File],
      default: () => ({}),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    fileApi: {
      type: String,
      default: 'File/',
    },
    isShow: {
      type: Boolean,
      default: true,
    },
    open: {
      type: Boolean,
      default: true,
    },
    downloadPermission: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isNativeFile() {
      return this.file instanceof File;
    },
    fileName() {
      return (this.isNativeFile ? this.file.name : this.file.title) || 'Без имени';
    },
  },
  methods: {
    onClickDelete() {
      this.$emit('delete');
    },
    async showFile() {
      if (!this.isShow) return;
      if (!this.file) return;
      console.log(this.isShow);

      if (this.isNativeFile) {
        saveFile(this.file, { name: this.file.name, open: this.open });
      } else {
        const { data: file } = await api.get(`${this.fileApi}${this.file.guid}`, { responseType: 'blob' });

        saveFile(file, { name: this.file.title, open: this.open });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file {
  width: 100%;
  display: flex;
  align-items: center;
}

.document-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.clear-btn {
  width: 22px;
  max-width: 22px;
  height: 22px;
  min-width: 22px;
}

.clear-icon {
  width: 16px;
  height: 16px;
  min-width: 16px;
}
</style>
