import NProgress from 'nprogress/nprogress';
import { format, parseISO } from 'date-fns';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage, convertObjToQueryParams, saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.DOCTOR_AUTH_TOKEN_FETCH](ctx, personId) {
    NProgress.start();

    try {
      const { data: token } = await api.post(`v2/doctors/${personId}/auth`);

      return token;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data: doctor } = await api.get(`v2/doctors/${id}`);

      return doctor;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о враче',
      });
    } finally {
      NProgress.done();
    }
  },
  // вроде удалили этот метод так как он не используется
  // async [localTypes.GET_DOCTOR_SERVICES](ctx, doctorName) {
  //   NProgress.start();

  //   try {
  //     const { data } = await api.get('v2/doctors/get-services', {
  //       params: { doctorName },
  //     });

  //     return data;
  //   } catch (e) {
  //     console.error(e);
  //     throw e;
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  async [localTypes.DOCTOR_CREATE](ctx, doctor) {
    NProgress.start();

    try {
      await api.post('v2/doctors', doctor);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Врач успешно добавлен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_UPDATE](ctx, doctor) {
    NProgress.start();

    try {
      await api.put('v2/doctors', doctor);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Врач успешно изменен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_DELETE](ctx, { id, reestablish = false }) {
    NProgress.start();

    try {
      await api.delete(`v2/doctors/${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: reestablish ? 'Врач успешно восстановлен!' : 'Врач успешно удален!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTORS_FETCH]({ commit }, {
    skip,
    query,
    take,
    clear,
    doctorSpecializationId,
    medicalInsuranceType,
    regionId,
    id,
    save,
    isDeleted,
    snils, // Новый параметр для СНИЛС
  } = {}) {
    NProgress.start();
    if (save && clear) {
      commit(localTypes.DOCTORS_SET, []);
    }
    try {
      const params = new URLSearchParams();
      if (snils) {
        // Если передан параметр СНИЛС, добавляем его в параметры запроса
        params.append('Snils', snils);
      } else if (query) {
        // Иначе используем стандартный поиск по query
        params.append('search', query);
      }
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      if (isDeleted) params.append('isDeleted', isDeleted);
      if (doctorSpecializationId || doctorSpecializationId === 0) params.append('doctorSpecializationId', doctorSpecializationId);
      if (medicalInsuranceType || medicalInsuranceType === 0) params.append('medicalInsuranceType', medicalInsuranceType);
      if (regionId || regionId === 0) params.append('regionId', regionId);
      if (id || id === 0) params.append('id', id);
      const { data: doctors } = await api.get('v2/doctors/find', {
        params,
      });
      if (save) {
        commit(localTypes.DOCTORS_ADD, doctors);
      }
      return doctors;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTORS_CONSULTATIONS_FETCH]({ commit }, {
    skip,
    take,
    doctorId,
  }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('skip', skip);
      params.append('take', take);
      const { data: consultations } = await api.get(`v2/doctors/${doctorId}/consultations`, {
        params,
      });

      commit(localTypes.DOCTORS_CONSULTATIONS_ADD, consultations);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATIONS_SEARCH](ctx, query) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('search', query);
      const { data: specializations } = await api.get('v2/specializations/find', {
        params,
      });

      return specializations.map((specialization) => ({ id: specialization.id, name: specialization.title }));
    } catch (e) {
      console.warn(e);
      return [];
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_UNLOAD_FETCH](ctx, filter) {
    NProgress.start();
    const params = convertObjToQueryParams(filter);

    try {
      const { data } = await api.get('v2/doctors/report', {
        params,
        responseType: 'blob',
      });

      let title = '';

      if (filter.clinicName) {
        title = filter.clinicName;
      } else {
        title = `${format(parseISO(filter.startDate), 'dd/MM/yyyy')} - ${format(parseISO(filter.endDate), 'dd/MM/yyyy')}`;
      }

      saveFile(data, { name: `Выгрузка по врачам ${title}.xlsx` });
    } catch (e) {
      console.warn(e);
      showMessage({ type: 'error', message: 'Не удалось выгрузить врачей' });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DOCTOR_FETCH_REGIONS]() {
    NProgress.start();

    try {
      const { data: regions } = await api.get('v2/regions/list?isActive=true');

      return regions;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о регионах',
      });
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_GET](ctx, { avatarCloudKey }) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/doctors/get-avatar?key=${avatarCloudKey}`, { responseType: 'blob' });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_DELETE](ctx, { doctorId }) {
    NProgress.start();

    try {
      await api.delete(`v2/doctors/delete-avatar?doctorId=${doctorId}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Аватарка врача успешно удалена.',
      });
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить аватарку врача.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.DOCTOR_AVATAR_UPLOAD](ctx, { doctorId, file }) {
    NProgress.start();
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await api.post(`v2/doctors/upload-avatar?doctorId=${doctorId}`, formData, {
        'Content-Type': 'multipart/form-data',
      });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Аватарка врача успешно сохранена.',
      });

      return data;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сохранить аватарку врача.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
