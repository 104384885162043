<template>
  <div class="crm-scrollbar-wrapper">
    <custom-scrollbar>
      <div class="appeal-modal">
        <Preloader v-if="isLoading" />
        <div
          v-else
          class="appeal-form"
        >
          <b-form-group
            label="Дата и время"
            style="width: 130px;"
          >
            <b-form-input
              :value="getFormatDate(appealData.base.dateTime, 'dd.MM.yyyy HH:mm')"
              type="text"
              size="sm"
              :disabled="true"
            />
          </b-form-group>

          <b-form-group
            label="Цель обращения"
            label-class="required"
            :state="!$v.appealData.base.purpose.$error"
            :invalid-feedback="errorsValidation.purpose[0]"
            :disabled="disabledField.purpose"
          >
            <b-form-radio-group
              v-model="$v.appealData.base.purpose.$model"
              :options="appealPurposes"
              stacked
              size="sm"
              @change="onAppealPurposeChange"
            />
          </b-form-group>
          <!--  -->
          <!-- custom templates -->
          <template v-if="isInformationCard">
            <b-form-group
              v-if="!disabledField.type"
              label="Категория обращения"
            >
              <b-form-select
                v-model="informationTypeCategory"
                :options="informationTypeCategories"
                text-field="text"
                value-field="value"
                size="sm"
                @change="onAppealTypeCategoryChange"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >
                    Выберите категорию обращения
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Тип обращения"
              label-class="required"
              :state="!$v.appealData.type.$error"
              :invalid-feedback="errorsValidation.type[0]"
              :disabled="disabledField.type"
            >
              <b-form-select
                v-model="$v.appealData.type.$model"
                :options="filteredByCategoryInformationsTypeList"
                text-field="title"
                value-field="id"
                size="sm"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                  >
                    Выберите тип обращения
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              v-if="typeSubtypes.length"
              label="Подтип обращения"
              :disabled="disabledField.subtypeId"
            >
              <b-form-select
                v-model="appealData.subtypeId"
                :options="typeSubtypes"
                text-field="title"
                value-field="id"
                size="sm"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Выберите подтип обращения
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <template v-if="isConsultationSubtype">
              <b-form-group
                :state="!$v.appealData.consultationId.$error"
                invalid-feedback="Обязательное поле"
                label="Номер консультации"
                label-class="required"
                :disabled="disabledField.consultationId"
              >
                <b-form-input
                  v-model="$v.appealData.consultationId.$model"
                  placeholder="Введите номер консультации"
                  size="sm"
                />
              </b-form-group>

              <b-form-group
                :state="!$v.appealData.consultationReason.$error"
                invalid-feedback="Обязательное поле"
                label="Причина"
                label-class="required"
                :disabled="disabledField.consultationReason"
              >
                <b-form-input
                  v-model="$v.appealData.consultationReason.$model"
                  placeholder="Введите причину"
                  size="sm"
                />
              </b-form-group>

              <b-form-group
                label="Комментарий консультации"
                :disabled="disabledField.consultationComment"
                :state="!$v.appealData.consultationComment.$error"
                :label-class="+appealData.subtypeId !== 8 ? 'required' : ''"
                invalid-feedback="Обязательное поле"
              >
                <b-form-textarea
                  v-model="$v.appealData.consultationComment.$model"
                  placeholder="Введите комментарий консультации"
                  rows="3"
                  size="sm"
                />
              </b-form-group>
            </template>

            <template v-if="isPaymentReturnSubtype">
              <b-form-group
                :state="!$v.appealData.returnType.$error"
                invalid-feedback="Выберите один из вариантов"
                label="Тип возврата"
                label-class="required"
                :disabled="disabledField.returnType"
              >
                <b-form-select
                  v-model="$v.appealData.returnType.$model"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      Выберите тип возврата
                    </b-form-select-option>
                  </template>
                  <template>
                    <b-form-select-option
                      :value="0"
                    >
                      Гемотест
                    </b-form-select-option>
                  </template>
                  <template>
                    <b-form-select-option
                      :value="1"
                    >
                      Консультация
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                :state="!$v.appealData.returnComment.$error"
                invalid-feedback="Обязательное поле"
                label="Комментарий к возврату оплаты"
                label-class="required"
                :disabled="disabledField.returnComment"
              >
                <b-form-textarea
                  v-model="$v.appealData.returnComment.$model"
                  placeholder="Введите комментарий"
                  rows="3"
                  size="sm"
                />
              </b-form-group>
            </template>

            <b-form-group
              v-if="isAnotherTypeTextRequired"
              :state="!$v.appealData.anotherTypeText.$error"
              :invalid-feedback="errorsValidation.anotherTypeText[0]"
              label="Другой тип обращения"
              label-class="required"
            >
              <b-form-input
                v-model="$v.appealData.anotherTypeText.$model"
                :disabled="disabledField.anotherTypeText"
                placeholder="Введите название"
                size="sm"
              />
            </b-form-group>

            <template v-if="appealData.type === 21 || appealData.type === 22">
              <b-row>
                <b-col>
                  <b-form-group
                    label="Город"
                    label-class="required"
                    :state="!$v.appealData.city.id.$error"
                    :invalid-feedback="errorsValidation.city[0]"
                    :disabled="disabledField.city"
                  >
                    <b-form-select
                      v-model="$v.appealData.city.id.$model"
                      :options="citiesList"
                      text-field="name"
                      value-field="id"
                      size="sm"
                      @input="onAppealCityChange"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                          disabled
                        >
                          Выберите город
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group
                    label="Клиника"
                    label-class="required"
                    :state="!$v.selectedClinic.$error"
                    :invalid-feedback="errorsValidation.selectedClinic[0]"
                    :disabled="disabledField.clinic || !isAppealCitySelected"
                  >
                    <v-select
                      v-model="$v.selectedClinic.$model"
                      :reduce="clinic => clinic.id"
                      :options="clinicsForSelectedCity"
                      placeholder="Выберите клинику"
                      label="title"
                      :clearable="true"
                      class="crm-select"
                      @input="onClinicSelect"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </template>
            <template v-if="appealData.type === 20">
              <b-form-group
                label="Гостелемед"
                label-class="required"
                :state="!$v.appealData.gostelemedType.$error"
                :invalid-feedback="errorsValidation.gostelemedType[0]"
                :disabled="disabledField.gostelemedType"
              >
                <b-form-select
                  v-model="$v.appealData.gostelemedType.$model"
                  :options="gostelemedTypeList"
                  text-field="title"
                  value-field="id"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                      disabled
                    >
                      Выберите тип гостелемеда
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                v-if="appealData.gostelemedType === 35"
                :state="!$v.appealData.gostelemedTypeAnotherText.$error"
                :invalid-feedback="errorsValidation.gostelemedTypeAnotherText[0]"
                label="Другое гостелемед"
                label-class="required"
              >
                <b-form-input
                  v-model="$v.appealData.gostelemedTypeAnotherText.$model"
                  :disabled="disabledField.gostelemedTypeAnotherText"
                  placeholder="Введите название"
                  size="sm"
                />
              </b-form-group>
            </template>
          </template>

          <template v-if="isTreatmentCard">
            <b-form-group
              label="Тип обращения"
              label-class="required"
              :state="!$v.appealData.type.$error"
              :invalid-feedback="errorsValidation.type[0]"
              :disabled="disabledField.type"
            >
              <b-form-select
                v-model="$v.appealData.type.$model"
                :options="treatmentsTypeList"
                text-field="title"
                value-field="id"
                size="sm"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    Выберите тип обращения
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group
              v-if="isAnotherTypeTextRequired"
              :state="!$v.appealData.anotherTypeText.$error"
              :invalid-feedback="errorsValidation.anotherTypeText[0]"
              label="Другой тип обращения"
              label-class="required"
            >
              <b-form-input
                v-model="$v.appealData.anotherTypeText.$model"
                :disabled="disabledField.anotherTypeText"
                placeholder="Введите название"
                size="sm"
              />
            </b-form-group>
          </template>

          <!-- << ИНФОРМАЦИЯ О ПАЦИЕНТЕ -->
          <template v-if="isShowPatientDataSection">
            <b-row>
              <b-col>
                <b-form-group
                  label="Фамилия"
                  :label-class="$v.appealData.base.purpose.$model != 0 ? 'required' : ''"
                  :state="!$v.appealData.person.lastName.$error"
                  :invalid-feedback="errorsValidation.lastName[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.person.lastName.$model"
                    :disabled="disabledField.patientData
                      || (initPatientData && !!initPatientData.person.lastName)
                      || (panelTabData && !!panelTabData.person.lastName)"
                    placeholder="Введите фамилию"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Имя"
                  label-class="required"
                  :state="!$v.appealData.person.firstName.$error"
                  :invalid-feedback="errorsValidation.firstName[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.person.firstName.$model"
                    :disabled="disabledField.patientData
                      || (initPatientData && !!initPatientData.person.firstName)
                      || (panelTabData && !!panelTabData.person.firstName)"
                    placeholder="Введите имя"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Отчество"
                >
                  <b-form-input
                    v-model="$v.appealData.person.middleName.$model"
                    :disabled="disabledField.patientData
                      || (initPatientData && !!initPatientData.person.middleName)
                      || (panelTabData && !!panelTabData.person.middleName)"
                    placeholder="Введите отчество"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Дата рождения"
                      :label-class="!isInformationCard ? 'required text-nowrap' : 'text-nowrap'"
                      :state="!$v.appealData.person.birthDate.$error"
                      :invalid-feedback="errorsValidation.birthDate[0]"
                    >
                      <b-form-input
                        v-model="$v.appealData.person.birthDate.$model"
                        type="date"
                        :disabled="disabledField.patientData
                          || (initPatientData && !!initPatientData.person.birthDate)
                          || (panelTabData && !!panelTabData.person.birthDate)"
                        placeholder="Введите дату"
                        size="sm"
                        @paste="onPaste"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <b-form-group
                      label="Возраст"
                    >
                      <b-form-input
                        :value="personAge"
                        :disabled="true"
                        class="text-center"
                        style="width: 50px;"
                        size="sm"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-between">
              <div v-if="isShowPersonStatus">
                <div class="col-form-label">
                  Статус
                </div>
                <div v-if="!appealData.person.isVip && !appealData.person.isTop">
                  —
                </div>
                <div v-else>
                  <icon-vip
                    v-if="appealData.person.isVip"
                    class="is-vip mr-2"
                  />
                  <icon-top
                    v-if="appealData.person.isTop"
                    class="is-top"
                  />
                </div>
              </div>
              <b-button
                v-if="isHospitalizationCard"
                variant="primary"
                class="ml-auto align-self-center"
                size="sm"
                @click="openIndemnityBtnHandler"
              >
                <span>
                  Создать ГП
                </span>
              </b-button>
            </div>

            <b-row class="mt-3">
              <b-col>
                <b-form-group
                  v-if="!isHospitalizationCard"
                  label="Номер телефона"
                  label-class="required"
                  :state="!$v.appealData.person.phoneNumber.$error"
                  :invalid-feedback="errorsValidation.phoneNumber[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.person.phoneNumber.$model"
                    :mask="phoneMask"
                    placeholder="Введите телефон"
                    :disabled="disabledField.patientData || (initPatientData && !!initPatientData.person.phoneNumber)"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col />
            </b-row>

            <div
              v-if="isShowPersonPolicySelect"
              class="crm-row"
            >
              <div class="crm-column">
                <div class="col-form-label">
                  Выбрать полис
                </div>
                <policy-list
                  :person-data="initPatientData.person"
                  :active-policies="displayedPolices"
                  :selected-policy-id="appealData.policy.id"
                  :disabled="disabledField.policy"
                  @selectPolicy="selectPolicyHandler"
                />
                <div
                  v-if="checkValidation && !policyValidation"
                  class="text-danger mt-1 small"
                >
                  Выберите полис
                </div>
              </div>
            </div>
          </template>
          <!-- ИНФОРМАЦИЯ О ПАЦИЕНТЕ >> -->

          <template v-if="isCheckupCard">
            <b-row class="mt-3">
              <b-col>
                <b-form-group
                  label="Дата чекапа"
                  label-class="required"
                  :state="!$v.appealData.checkupDate.$error"
                  :invalid-feedback="errorsValidation.checkupDate[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.checkupDate.$model"
                    type="date"
                    :disabled="disabledField.checkupDate"
                    placeholder="Введите дату"
                    size="sm"
                    @paste="onPaste"
                  />
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  label="Профиль для чекапа"
                  label-class="required"
                  :state="!$v.appealData.checkupProfile.$error"
                  :invalid-feedback="errorsValidation.checkupProfile[0]"
                  :disabled="disabledField.checkupProfile"
                >
                  <b-form-select
                    v-model="$v.appealData.checkupProfile.$model"
                    :options="checkupProfilesList"
                    text-field="title"
                    value-field="id"
                    size="sm"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        Выберите профиль
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Город"
                  label-class="required"
                  :state="!$v.appealData.city.id.$error"
                  :invalid-feedback="errorsValidation.city[0]"
                  :disabled="disabledField.city"
                >
                  <v-select
                    v-model="$v.appealData.city.id.$model"
                    :reduce="city => city.id"
                    :options="citiesList"
                    placeholder="Выберите город"
                    label="name"
                    :clearable="true"
                    class="crm-select"
                    :disabled="disabledField.city"
                    @input="onAppealCityChange"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Клиника"
                  label-class="required"
                  :state="!$v.selectedClinic.$error"
                  :invalid-feedback="errorsValidation.selectedClinic[0]"
                  :disabled="disabledField.clinic || !isAppealCitySelected"
                >
                  <v-select
                    v-model="$v.selectedClinic.$model"
                    :reduce="clinic => clinic.id"
                    :options="clinicsForSelectedCity"
                    :disabled="!(!!appealData.city.id) || (disabledField.clinic || !isAppealCitySelected)"
                    placeholder="Выберите клинику"
                    label="title"
                    :clearable="true"
                    class="crm-select"
                    @input="onClinicSelect"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              v-if="isShowMIDRejectionReason"
              label="Причина отказа от записи в МИД"
              label-class="required"
              :state="!$v.appealData.rejectionReason.$error"
              :invalid-feedback="errorsValidation.rejectionReason[0]"
            >
              <b-form-textarea
                v-model="$v.appealData.rejectionReason.$model"
                placeholder="Введите текст"
                rows="3"
                size="sm"
                :disabled="disabledField.rejectionReason"
              />
            </b-form-group>
          </template>

          <template v-if="isTreatmentCard && !checkRoleAccess(['Operator', 'Tech.operator'])">
            <!-- <b-form-group
              :state="!$v.appealData.provisionalDiagnosis.$error"
              :invalid-feedback="errorsValidation.provisionalDiagnosis[0]"
              label="Предварительный диагноз"
              :label-class="isProvisionalDiagnosisRequired ? 'required' : ''"
              class="mt-3"
            >
              <b-form-input
                v-model="$v.appealData.provisionalDiagnosis.$model"
                :disabled="disabledField.provisionalDiagnosis"
                placeholder="Введите название"
                size="sm"
              />
            </b-form-group> -->

            <b-row>
              <b-col>
                <b-form-group
                  label="Город"
                  label-class="required"
                  :state="!$v.appealData.city.id.$error"
                  :invalid-feedback="errorsValidation.city[0]"
                  :disabled="disabledField.city"
                >
                  <v-select
                    v-model="$v.appealData.city.id.$model"
                    :reduce="city => city.id"
                    :options="citiesList"
                    placeholder="Выберите город"
                    label="name"
                    :clearable="true"
                    class="crm-select"
                    :disabled="disabledField.city"
                    @input="onAppealCityChange"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Клиника"
                  label-class="required"
                  :state="!$v.selectedClinic.$error"
                  :invalid-feedback="errorsValidation.selectedClinic[0]"
                  :disabled="disabledField.clinic || !isAppealCitySelected"
                >
                  <v-select
                    v-model="$v.selectedClinic.$model"
                    :reduce="clinic => clinic.id"
                    :options="clinicsForSelectedCity"
                    :disabled="!(!!appealData.city.id) || (disabledField.clinic || !isAppealCitySelected)"
                    placeholder="Выберите клинику"
                    label="title"
                    :clearable="true"
                    class="crm-select"
                    @input="onClinicSelect"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <!-- <b-row>
              <b-col>
                <b-form-group
                  label="Код по МКБ"
                  :label-class="isMKBCodeRequired ? 'required' : ''"
                  :state="!$v.appealData.mkbCode.$error"
                  :invalid-feedback="errorsValidation.mkbCode[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.mkbCode.$model"
                    :disabled="disabledField.mkbCode"
                    placeholder="Введите текст"
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Страховой лимит"
                  :label-class="isInsuranceLimitRequired ? 'required' : ''"
                  :state="!$v.appealData.insuranceLimit.$error"
                  :invalid-feedback="errorsValidation.insuranceLimit[0]"
                >
                  <b-form-input
                    v-model="$v.appealData.insuranceLimit.$model"
                    :disabled="disabledField.insuranceLimit"
                    placeholder="Введите лимит"
                    number
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row> -->

            <b-row
              v-if="isShowCheckup"
            >
              <b-col>
                <b-form-group
                  label="Чекап"
                  :label-class="isCheckupRequired ? 'required' : ''"
                  :state="!$v.appealData.checkup.$error"
                  :invalid-feedback="errorsValidation.checkup[0]"
                  :disabled="disabledField.checkup"
                >
                  <b-form-radio-group
                    v-model="$v.appealData.checkup.$model"
                    :options="checkupOptions"
                    stacked
                    size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label="Дополнительные опции"
                >
                  <b-form-checkbox
                    v-model="appealData.urgency"
                    :disabled="disabledField.urgency"
                    size="sm"
                  >
                    Срочность
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <template v-if="isHospitalizationCard">
            <b-form-group
              :state="!$v.appealData.provisionalDiagnosis.$error"
              :invalid-feedback="errorsValidation.provisionalDiagnosis[0]"
              label="Предварительный диагноз"
              :label-class="isProvisionalDiagnosisRequired ? 'required' : ''"
            >
              <b-form-input
                v-model="$v.appealData.provisionalDiagnosis.$model"
                :disabled="disabledField.provisionalDiagnosis"
                placeholder="Введите название"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              :state="!$v.appealData.hospitalizedFrom.$error"
              :invalid-feedback="errorsValidation.hospitalizedFrom[0]"
              label="Откуда госпитализирован"
              :label-class="isHospitalizedFromRequired ? 'required' : ''"
            >
              <b-form-input
                v-model="$v.appealData.hospitalizedFrom.$model"
                :disabled="disabledField.hospitalizedFrom"
                placeholder="Введите название"
                size="sm"
              />
            </b-form-group>

            <b-row>
              <b-col>
                <b-form-group
                  label="Город"
                  label-class="required"
                  :state="!$v.appealData.city.id.$error"
                  :invalid-feedback="errorsValidation.city[0]"
                  :disabled="disabledField.city"
                >
                  <v-select
                    v-model="$v.appealData.city.id.$model"
                    :reduce="city => city.id"
                    :options="citiesList"
                    placeholder="Выберите город"
                    label="name"
                    :clearable="true"
                    class="crm-select"
                    :disabled="disabledField.city"
                    @input="onAppealCityChange"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group
                  label="Клиника"
                  label-class="required"
                  :state="!$v.selectedClinic.$error"
                  :invalid-feedback="errorsValidation.selectedClinic[0]"
                  :disabled="disabledField.clinic || !isAppealCitySelected"
                >
                  <v-select
                    v-model="$v.selectedClinic.$model"
                    :reduce="clinic => clinic.id"
                    :options="clinicsForSelectedCity"
                    :disabled="!(!!appealData.city.id) || (disabledField.clinic || !isAppealCitySelected)"
                    placeholder="Выберите клинику"
                    label="title"
                    :clearable="true"
                    class="crm-select"
                    @input="onClinicSelect"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-form-group
              :state="!$v.appealData.curatorsPhone.$error"
              :invalid-feedback="errorsValidation.curatorsPhone[0]"
              label="Телефон куратора"
              :label-class="isCuratorsPhoneRequired ? 'required' : ''"
            >
              <b-form-input
                v-model="$v.appealData.curatorsPhone.$model"
                :disabled="disabledField.curatorsPhone"
                placeholder="Введите телефон"
                size="sm"
              />
            </b-form-group>

            <b-form-group
              label="Причина отказа в госпитализации"
              :label-class="isHospitalizationCard ? 'required' : ''"
              :state="!$v.appealData.rejectionReason.$error"
              :invalid-feedback="errorsValidation.rejectionReason[0]"
              class="mt-3"
            >
              <b-form-textarea
                v-model="$v.appealData.rejectionReason.$model"
                placeholder="Введите текст"
                rows="3"
                size="sm"
                :disabled="disabledField.rejectionReason"
              />
            </b-form-group>
          </template>

          <!--  -->
          <b-form-group
            label="Комментарий"
            label-class="required"
            :state="!$v.appealData.base.comment.$error"
            :invalid-feedback="errorsValidation.comment[0]"
            class="mt-3"
          >
            <b-form-textarea
              v-model="$v.appealData.base.comment.$model"
              placeholder="Введите текст"
              rows="3"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label="Статус обращения"
            label-class="required"
            :state="!$v.appealData.base.status.$error"
            :invalid-feedback="errorsValidation.status[0]"
            :disabled="disabledField.status"
          >
            <b-form-select
              v-model="$v.appealData.base.status.$model"
              :options="appealStatusOptions"
              text-field="title"
              value-field="id"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label="Ответственный"
          >
            <b-form-input
              :value="`${userInfo.lastName} ${userInfo.firstName} ${userInfo.middleName ? userInfo.middleName : ''}`"
              type="text"
              :disabled="true"
              size="sm"
            />
          </b-form-group>

          <div class="crm-row mb-3">
            <div class="crm-column files-section">
              <div
                v-if="files.length > 0"
                style="width: 100%"
              >
                <div class="crm-input-label">
                  Файлы
                </div>
                <div
                  v-for="(file, index) in files"
                  :key="index"
                  class="crm-file-item"
                  @click="downloadFile(file)"
                >
                  <document-icon class="document-icon" />
                  {{ file.title }}
                </div>
              </div>
              <div>
                <b-button
                  variant="primary"
                  class="ml-auto"
                  size="sm"
                  @click="onClickOpenFileModal"
                >
                  <b-icon icon="plus" />
                  <span>
                    Добавить файл
                  </span>
                </b-button>
                <div
                  v-if="$v.files.$error"
                  class="crm-error-text"
                >
                  {{ printError(errorsValidation.files) }}
                </div>
              </div>
            </div>
          </div>

          <div
            v-for="(reconciliation, index) in $v.appealData.reconciliations.$each.$iter"
            :key="index"
            class="crm-row"
          >
            <base-dropdown header="Согласование">
              <b-row>
                <b-col>
                  <b-form-group
                    label="Статус согласования"
                  >
                    <b-form-select
                      v-model="reconciliation.status.$model"
                      :options="reconciliationStatusOptions"
                      text-field="title"
                      :disabled="reconciliation.fieldsDisabled.$model"
                      value-field="id"
                      size="sm"
                    >
                      <template #first>
                        <b-form-select-option
                          :value="null"
                        >
                          Выберите статус
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Дата и время"
                    style="width: 130px;"
                  >
                    <b-form-input
                      :value="getFormatDate(reconciliation.createDate.$model, 'dd.MM.yyyy HH:mm')"
                      type="text"
                      size="sm"
                      :disabled="true"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-form-group
                label="Инициатор согласования"
              >
                <b-form-select
                  v-model="reconciliation.initiator.$model"
                  :options="initiators"
                  :disabled="reconciliation.fieldsDisabled.$model"
                  text-field="title"
                  value-field="id"
                  size="sm"
                >
                  <template #first>
                    <b-form-select-option
                      :value="null"
                    >
                      Выберите
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>

              <b-form-group
                label="Название клиники"
                label-class="required"
                :state="!reconciliation.clinicId.$error"
                invalid-feedback="Выберите клинику"
              >
                <v-select
                  v-model="reconciliation.clinicId.$model"
                  :reduce="clinic => clinic.id"
                  :options="clinics"
                  :disabled="reconciliation.fieldsDisabled.$model"
                  placeholder="Выберите клинику"
                  label="title"
                  :clearable="true"
                  class="crm-select"
                />
              </b-form-group>

              <b-form-group
                label="Диагноз"
                label-class="required"
                :state="!reconciliation.diagnosis.$error"
                invalid-feedback="Обязательное поле"
              >
                <b-form-textarea
                  v-model="reconciliation.diagnosis.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Код по МКБ"
              >
                <b-form-input
                  v-model="reconciliation.mkbCode.$model"
                  placeholder="Введите текст"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Страховой лимит"
              >
                <b-form-input
                  v-model="reconciliation.insuranceLimit.$model"
                  placeholder="Введите лимит"
                  number
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Согласованные услуги"
              >
                <b-form-textarea
                  v-model="reconciliation.agreedServices.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Несогласованные услуги"
              >
                <b-form-textarea
                  v-model="reconciliation.notAgreedServices.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Причина отказа"
              >
                <b-form-textarea
                  v-model="reconciliation.regectionReason.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Комментарий врача"
              >
                <b-form-textarea
                  v-model="reconciliation.doctorComment.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                  :disabled="reconciliation.fieldsDisabled.$model"
                />
              </b-form-group>

              <b-form-group
                label="Комментарий для оператора"
              >
                <b-form-textarea
                  v-model="reconciliation.commentForOperator.$model"
                  placeholder="Введите текст"
                  rows="3"
                  size="sm"
                />
              </b-form-group>

              <div class="crm-row">
                <div class="crm-column">
                  <div class="crm-input-label">
                    Ответственный за согласование
                  </div>
                  <div class="appeal-date">
                    <div>{{ `${userInfo.lastName} ${userInfo.firstName} ${userInfo.middleName || ''}` }}</div>
                  </div>
                </div>
              </div>

              <div class="crm-row">
                <!-- <div class="crm-column">
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="width-unset"
                    @click="onClickOpenFileModal"
                  >
                    <icon-cross
                      class="crm-icon-cross"
                    />
                    <span>Добавить файл</span>
                  </base-button>
                </div> -->
                <div
                  v-if="reconciliation.files.$model.length > 0"
                  class="crm-column files-section"
                >
                  <div
                    style="width: 100%"
                  >
                    <div class="crm-input-label">
                      Файлы
                    </div>
                    <div
                      v-for="(file, ind) in reconciliation.files.$model"
                      :key="ind"
                      class="crm-file-item"
                      @click="downloadFile(file)"
                    >
                      <document-icon class="document-icon" />
                      {{ file.title || file.name }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <b-button
                  variant="primary"
                  class="mt-2 ml-auto"
                  size="sm"
                  @click="onClickOpenFileReconciliationModal(index)"
                >
                  <b-icon icon="plus" />
                  <span>
                    Добавить файл
                  </span>
                </b-button>
              </div>

              <div class="mt-2">
                <b-button
                  v-if="!reconciliation.id || !checkRoleAccess(['Operator', 'Tech.operator'])"
                  variant="danger"
                  size="sm"
                  @click="deleteReconciliation(index)"
                >
                  <span>Удалить согласование</span>
                </b-button>
              </div>
            </base-dropdown>
          </div>

          <hr class="my-4">

          <div
            v-if="initAppealData && !isInformationCard && !isCheckupCard && !checkRoleAccess(['Operator', 'Tech.operator'])"
            class="mt-2"
          >
            <b-button
              variant="primary"
              size="sm"
              @click="addReconciliation"
            >
              Добавить согласование
            </b-button>
          </div>

          <div
            v-if="initAppealData && !isCheckupCard && !isTreatmentCard"
            class="mt-2"
          >
            <b-button
              variant="primary"
              size="sm"
              @click="openIndemnityModal"
            >
              Сформировать ГП без полиса
            </b-button>
          </div>

          <div class="crm-wrapper-buttons">
            <b-button
              variant="primary"
              class="mx-auto"
              @click="onClickSave"
            >
              Сохранить
            </b-button>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { showMessage, saveFile, onBInputDatePaste } from '@/helpers/utils';
import Preloader from '@/components/Preloader';
import { validationMixin } from 'vuelidate';
import { mixinRoles } from '@/mixins';
import { required } from 'vuelidate/lib/validators';
import IconVip from 'assets/images/icon_vip.svg';
import IconTop from 'assets/images/icon_top.svg';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import DocumentIcon from 'assets/images/document_icon.svg';
import { api } from '@/helpers/api';

import {
  BaseDropdown,
} from '@/components/base';

import {
  format,
  formatISO,
  parseISO,
  isEqual,
} from 'date-fns';

import PolicyList from '@/components/Appeals/PolicyList';

export default {
  name: 'AppealEditorModal',
  components: {
    Preloader,
    BaseDropdown,
    // BaseDatePicker,
    IconVip,
    IconTop,
    PolicyList,
    DocumentIcon,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    initPatientData: {
      type: Object,
      default: null,
    },

    initAppealData: {
      type: Object,
      default: null,
    },

    panelTabId: {
      type: Number,
      default: null,
    },

    fileApi: {
      type: String,
      default: '',
    },

    initDoctorData: {
      type: Object,
      default: null,
    },
    afterSaveFunc: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      phoneMask: ['+', /\d/, '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
      isLoading: false,
      selectedClinic: null,
      informationTypeCategory: null,
      checkValidation: false,
      appealData: {
        base: {
          id: 0,
          status: null,
          dateTime: null,
          purpose: 0, // Цель обращения
          comment: null,
          fileIds: [],
        },
        type: null,
        subtypeId: null,
        consultationId: null,
        consultationReason: null,
        consultationComment: null,
        returnType: null,
        returnComment: null,
        anotherTypeText: null,
        checkupDate: null,
        checkupProfile: null,
        rejectionReason: null,
        provisionalDiagnosis: null,
        // mkbCode: null,
        // insuranceLimit: null,
        checkup: null,
        urgency: false,
        hospitalizedFrom: null,
        curatorsPhone: null,
        city: {
          id: null,
        },
        // clinic: {
        //   id: null,
        // },
        gostelemedType: null,
        gostelemedTypeAnotherText: null,
        policy: {
          id: null,
        },
        person: {
          id: 0,
          firstName: null,
          lastName: null,
          middleName: null,
          phoneNumber: null,
          isVip: null,
          birthDate: null,
          city: null,
          isTop: null,
        },
        reconciliations: [],
      },

      appealPurposes: [
        {
          text: 'Информационное',
          value: 0,
        },
        {
          text: 'Чекап',
          value: 1,
        },
        {
          text: 'Лечение / плановая госпитализация',
          value: 2,
        },
        {
          text: 'Экстренная госпитализация',
          value: 3,
        },
      ],

      informationTypeCategories: [
        {
          text: 'Контроль качества врачи',
          value: 0,
          typeIds: [4, 5, 12, 13, 14, 3, 9, 20, 6, 7, 47, 10],
        },
        {
          text: 'Техподдержка',
          value: 1,
          typeIds: [2, 18, 19, 16, 17, 7, 46],
        },
        {
          text: 'Программы ДМС',
          value: 2,
          typeIds: [22, 21, 1, 7],
        },
        {
          text: 'Приложение Доктис',
          value: 3,
          typeIds: [1, 8, 11, 15, 7],
        },
        {
          text: 'Предложение о сотрудничестве',
          value: 4,
          typeIds: [45, 7, 43, 44],
        },
      ],

      checkupOptions: [
        {
          text: 'Пройден',
          value: 0,
        },
        {
          text: 'Не пройден',
          value: 1,
        },
        {
          text: 'Пройден частично',
          value: 2,
        },
      ],

      appealStatusOptions: [
        { id: null, title: 'Выберите статус' },
        { id: 0, title: 'Передано' },
        { id: 1, title: 'Отложено' },
        { id: 2, title: 'Закрыто' },
      ],

      reconciliationStatusOptions: [
        {
          id: 0,
          title: 'Не выбрано',
        },
        // {
        //   id: 1,
        //   title: 'В работе',
        // },
        {
          id: 2,
          title: 'Готово',
        },
        {
          id: 3,
          title: 'Передано',
        },
        {
          id: 4,
          title: 'Отложено',
        },
      ],

      initiators: [
        {
          id: 0,
          title: 'Клиника',
        },
        {
          id: 1,
          title: 'Пациент',
        },
        {
          id: 2,
          title: 'Другое',
        },
      ],

      citiesList: [],

      files: [],

      clinics: [],

      isNeedUpdatePatient: false,

      savedToTabPanel: false,
      panelTabData: null,
    };
  },
  validations() {
    return {
      selectedClinic: {
        required: this.isClinicRequired ? required : () => true,
      },
      files: {
        required: this.isFilesRequired ? required : () => true,
      },
      appealData: {
        base: {
          status: { required },
          purpose: { required },
          comment: { required: !this.isHospitalizationCard ? required : () => true },
        },
        type: {
          required: this.isTypeRequired ? required : () => true,
        },
        consultationId: {
          required: this.isConsultationSubtype ? required : () => true,
        },
        consultationReason: {
          required: this.isConsultationSubtype ? required : () => true,
        },
        consultationComment: {
          required: this.isConsultationSubtype && +this.appealData.subtypeId !== 8 ? required : () => true,
        },
        returnType: {
          required: this.isPaymentReturnSubtype ? required : () => true,
        },
        returnComment: {
          required: this.isPaymentReturnSubtype ? required : () => true,
        },
        anotherTypeText: {
          required: this.isAnotherTypeTextRequired ? required : () => true,
        },
        checkupDate: {
          required: this.isCheckupDateRequired ? required : () => true,
        },
        checkupProfile: {
          required: this.isCheckupProfileRequired ? required : () => true,
        },
        rejectionReason: {
          required: this.isRejectionReasonRequired ? required : () => true,
        },
        provisionalDiagnosis: {
          required: this.isProvisionalDiagnosisRequired ? required : () => true,
        },
        // mkbCode: {
        //   required: this.isMKBCodeRequired ? required : () => true,
        // },
        // insuranceLimit: {
        //   required: this.isInsuranceLimitRequired ? required : () => true,
        // },
        checkup: {
          // required: this.isCheckupRequired ? required : () => true,
        },
        hospitalizedFrom: {
          required: this.isHospitalizedFromRequired ? required : () => true,
        },
        curatorsPhone: {
          required: this.isCuratorsPhoneRequired ? required : () => true,
        },
        city: {
          id: {
            required: this.isCityRequired ? required : () => true,
          },
        },
        // clinic: { required },
        gostelemedType: {
          required: this.isGostelemedTypeRequired ? required : () => true,
        },
        gostelemedTypeAnotherText: {
          required: this.isGostelemedTypeAnotherTextRequired ? required : () => true,
        },
        person: {
          // required: this.type === 1 ? required : () => true,
          lastName: { required: !this.isInformationCard ? required : () => true },
          firstName: { required: this.isPersonDataRequired ? required : () => true },
          middleName: {},
          birthDate: { required: !this.isInformationCard ? required : () => true },
          city: {},
          phoneNumber: { required: this.isPersonPhoneRequired ? required : () => true },
        },
        reconciliations: {
          $each: {
            appealId: {},
            status: {},
            initiator: {},
            createDate: {},
            clinicId: {
              required,
            },
            diagnosis: {
              required,
            },
            agreedServices: {},
            notAgreedServices: {},
            regectionReason: {},
            mkbCode: {},
            insuranceLimit: {},
            doctorComment: {},
            commentForOperator: {},
            files: {},
            fileIds: {},
            fieldsDisabled: {},
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      informationsTypeList: types.GET_APPEAL_INFORMATION_TYPES,
      treatmentsTypeList: types.GET_APPEAL_TREATMENT_TYPES,
      gostelemedTypeList: types.GET_APPEAL_GOSTELEMED_TYPES,
      checkupProfilesList: types.GET_APPEAL_CHECKUP_PROFILES,
      userInfo: types.USER_INFO_GET,
    }),
    // < check fields for required

    clinicsForSelectedCity() {
      if (!this.isAppealCitySelected) return [];
      return this.clinics.filter((item) => item.cityId === this.appealData.city.id);
    },

    filteredByCategoryInformationsTypeList() {
      if (this.informationTypeCategory === null || this.informationTypeCategory === undefined) {
        return this.informationsTypeList;
      }

      const { typeIds } = this.informationTypeCategories.find((c) => c.value === this.informationTypeCategory);
      const filteredTypeList = this.informationsTypeList.filter((type) => typeIds.includes(type.id));

      return filteredTypeList;
    },

    isAppealCitySelected() {
      if (!this.appealData.city || !this.appealData.city.id) return false;
      return true;
    },

    isConsultationSubtype() {
      return [8].includes(this.appealData.subtypeId);
    },

    isPaymentReturnSubtype() {
      return [9].includes(this.appealData.subtypeId);
    },

    disabledField() {
      if (this.initAppealData?.purpose === 0 || !this.isEditMode) { // init appeal has information purpose
        return {
          purpose: !!this.initDoctorData,
        };
      }

      return {
        status: false,
        purpose: true,
        type: this.isInformationCard || this.isTreatmentCard,
        subtypeId: this.isInformationCard,
        consultationId: this.isInformationCard,
        consultationReason: this.isInformationCard,
        consultationComment: this.isInformationCard,
        returnType: this.isInformationCard,
        returnComment: this.isInformationCard,
        anotherTypeText: this.isInformationCard,
        city: this.isInformationCard || this.isTreatmentCard,
        clinic: this.isInformationCard || !!this.initAppealData?.clinic?.id,
        gostelemedType: this.isInformationCard,
        gostelemedTypeAnotherText: this.isInformationCard,
        patientData: this.isInformationCard || this.isCheckupCard,
        policy: this.isInformationCard,
        checkupDate: this.isInformationCard,
        checkupProfile: this.isInformationCard,
        rejectionReason: this.isInformationCard,
        provisionalDiagnosis: this.isInformationCard,
        // mkbCode: this.isInformationCard,
        // insuranceLimit: this.isInformationCard,
        checkup: this.isInformationCard,
        urgency: this.isInformationCard,
        hospitalizedFrom: this.isInformationCard,
        curatorsPhone: this.isInformationCard,
        fileUpload: this.isInformationCard,
      };
    },

    isSelectedClinicMotherAndChild() {
      const clinicId = this.selectedClinic?.id || this.selectedClinic;
      const clinic = this.clinics.find((c) => c.id === clinicId);
      return clinic.title.toLowerCase().includes('Мать и Дитя'.toLowerCase());
    },

    isShowMIDRejectionReason() {
      if (this.selectedClinic && !this.isSelectedClinicMotherAndChild) {
        return true;
      }

      return false;
    },
    isShowPersonStatus() {
      if (!this.initPatientData) {
        return false;
      }
      return true;
    },

    isShowPersonPolicySelect() {
      if (
        this.isHospitalizationCard
        || (!this.initPatientData && !this.initAppealData)
      ) {
        return false;
      }
      return true;
    },
    isInformationCard() {
      return this.appealData.base.purpose === 0;
    },

    isCheckupCard() {
      return this.appealData.base.purpose === 1;
    },

    isTreatmentCard() {
      return this.appealData.base.purpose === 2;
    },

    isHospitalizationCard() {
      return this.appealData.base.purpose === 3;
    },

    isCheckupDateRequired() {
      if (this.isCheckupCard) { // Чекап карточка
        return true;
      }
      return false;
    },

    isCheckupProfileRequired() {
      if (this.isCheckupCard) { // Чекап карточка
        return true;
      }
      return false;
    },

    isRejectionReasonRequired() {
      if (
        (this.isCheckupCard && this.isShowMIDRejectionReason)
      ) {
        return true;
      }

      return false;
    },

    isProvisionalDiagnosisRequired() {
      if (this.isHospitalizationCard) {
        return true;
      }
      return false;
    },

    isFilesRequired() {
      // if (
      //   this.isCheckupCard
      //   || this.isTreatmentCard
      //   || this.isHospitalizationCard
      // ) {
      //   return true;
      // }

      return false;
    },

    isTypeRequired() {
      if (
        this.isInformationCard
        || this.isTreatmentCard
      ) { // Информационная карточка
        return true;
      }

      return false;
    },

    isPersonDataRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.type !== 22) {
          return true;
        }
      }

      if (
        this.isCheckupCard
        || this.isTreatmentCard
        || this.isHospitalizationCard
      ) {
        return true;
      }

      return false;
    },
    isPersonPhoneRequired() {
      if (this.isPersonDataRequired && !this.isHospitalizationCard) return true;
      return false;
    },
    isAnotherTypeTextRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.type === 7) {
          return true;
        }
      }

      if (this.isTreatmentCard) { // Карточка лечения
        if (this.appealData.type === 42) {
          return true;
        }
      }

      return false;
    },
    isClinicRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.type === 21 || this.appealData.type === 22) {
          return true;
        }
      }

      if (
        (this.isCheckupCard || this.isTreatmentCard || this.isHospitalizationCard)
        && !this.checkRoleAccess(['Operator', 'Tech.operator'])
      ) {
        return true;
      }
      return false;
    },
    isCityRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.type === 21 || this.appealData.type === 22) {
          return true;
        }
      }
      return false;
    },
    isGostelemedTypeRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.type === 20) {
          return true;
        }
      }
      return false;
    },
    isGostelemedTypeAnotherTextRequired() {
      if (this.isInformationCard) { // Информационная карточка
        if (this.appealData.gostelemedType === 35) {
          return true;
        }
      }
      return false;
    },

    // isMKBCodeRequired() {
    //   return false;
    // },

    // isInsuranceLimitRequired() {
    //   return false;
    // },

    isCheckupRequired() {
      if (this.isTreatmentCard && this.isShowCheckup) return true;
      return false;
    },

    isHospitalizedFromRequired() {
      if (this.isHospitalizationCard) {
        return true;
      }
      return false;
    },

    isCuratorsPhoneRequired() {
      if (this.isHospitalizationCard) {
        return true;
      }
      return false;
    },

    // check fields for required >

    displayedPolices() {
      if (this.initPatientData?.policies?.length) {
        return this.initPatientData.policies.filter((item) => item.isActivated || item.policyStatus === 2);
      }

      if (this.initAppealData?.person?.policies?.length) {
        return this.initAppealData.person.policies.filter((item) => item.isActivated);
      }

      if (this.initAppealData?.selectedPolicy?.id) {
        return [this.initAppealData?.selectedPolicy];
      }

      return [];
    },
    policyValidation() {
      if (
        !this.displayedPolices.length
        || !this.isShowPersonPolicySelect
        || this.appealData.base.purpose === 0
      ) return true;

      return this.appealData.policy.id !== null && this.appealData.policy.id !== 0; // раньше вместо null по дефолту отправлялся 0. он в таком виде и сохранялся в базе)
    },
    isShowPatientDataSection() {
      if (
        (this.isInformationCard && this.appealData.type !== 22)
        || this.isCheckupCard
        || this.isTreatmentCard
        || this.isHospitalizationCard
      ) {
        return true;
      }
      return false;
    },
    isShowCheckup() {
      if (this.isTreatmentCard) return false;
      if (!this.appealData.policy?.id) return true;

      const policy = this.displayedPolices.find((p) => p.id === this.appealData.policy?.id);

      return policy?.showCheckup !== false;
    },
    personAge() {
      if (!this.appealData.person.birthDate) return '—';
      const date = new Date(this.appealData.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age < 0 ? 0 : age;
    },
    errorsValidation() {
      const errors = {};

      errors.purpose = [];
      if (!this.$v.appealData.base.purpose.required) {
        errors.purpose.push('Выберите цель обращения');
      }

      errors.status = [];
      if (!this.$v.appealData.base.status.required) {
        errors.status.push('Выберите статус обращения');
      }

      errors.type = [];
      if (!this.$v.appealData.type.required) {
        errors.type.push('Выберите тип обращения');
      }

      errors.selectedClinic = [];
      if (!this.$v.selectedClinic.required) {
        errors.selectedClinic.push('Выберите клинику');
      }

      errors.comment = [];
      if (!this.$v.appealData.base.comment.required) {
        errors.comment.push('Введите комментарий');
      }

      errors.anotherTypeText = [];
      if (!this.$v.appealData.anotherTypeText.required) {
        errors.anotherTypeText.push('Выберите тип обращения');
      }

      errors.lastName = [];
      if (!this.$v.appealData.person.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.appealData.person.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.appealData.person.birthDate.required) {
        errors.birthDate.push('Укажите дату');
      }

      // errors.personCity = [];
      // if (!this.$v.appealData.person.city.required) {
      //   errors.personCity.push('Поле не может быть пустым');
      // }

      errors.city = [];
      if (!this.$v.appealData.city.id.required) {
        errors.city.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.appealData.person.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.gostelemedType = [];
      if (!this.$v.appealData.gostelemedType.required) {
        errors.gostelemedType.push('Поле не может быть пустым');
      }

      errors.gostelemedTypeAnotherText = [];
      if (!this.$v.appealData.gostelemedTypeAnotherText.required) {
        errors.gostelemedTypeAnotherText.push('Поле не может быть пустым');
      }

      errors.files = [];
      if (!this.$v.files.required) {
        errors.files.push('Добавьте файл');
      }

      // checkup
      errors.checkupDate = [];
      if (!this.$v.appealData.checkupDate.required) {
        errors.checkupDate.push('Укажите дату чекапа');
      }

      errors.checkupProfile = [];
      if (!this.$v.appealData.checkupProfile.required) {
        errors.checkupProfile.push('Укажите профиль');
      }

      errors.rejectionReason = [];
      if (!this.$v.appealData.rejectionReason.required) {
        errors.rejectionReason.push('Укажите причину отказа');
      }

      errors.provisionalDiagnosis = [];
      if (!this.$v.appealData.provisionalDiagnosis.required) {
        errors.provisionalDiagnosis.push('Укажите предварительный диагноз');
      }

      // errors.mkbCode = [];
      // if (!this.$v.appealData.mkbCode.required) {
      //   errors.mkbCode.push('Укажите код МКБ');
      // }

      // errors.insuranceLimit = [];
      // if (!this.$v.appealData.insuranceLimit.required) {
      //   errors.insuranceLimit.push('Укажите страховой лимит');
      // }

      errors.checkup = [];
      if (!this.$v.appealData.checkup.required) {
        errors.checkup.push('Выберите вариант');
      }

      errors.hospitalizedFrom = [];
      if (!this.$v.appealData.hospitalizedFrom.required) {
        errors.hospitalizedFrom.push('Введите текст');
      }

      errors.curatorsPhone = [];
      if (!this.$v.appealData.curatorsPhone.required) {
        errors.curatorsPhone.push('Введите телефон');
      }

      return errors;
    },

    typeSubtypes() {
      const type = this.filteredByCategoryInformationsTypeList.find((t) => t.id === this.appealData.type);
      if (!type) return [];
      return type.subtypes;
    },
  },

  async created() {
    this.isLoading = true;
    await this.$store.dispatch(this.$types.APPEAL_INFORMATION_TYPES_FETCH);
    await this.$store.dispatch(this.$types.APPEAL_TREATMENT_TYPES_FETCH);
    await this.$store.dispatch(this.$types.APPEAL_GOSTELEMED_TYPES_FETCH);
    await this.$store.dispatch(this.$types.APPEAL_CHECKUP_PROFILES_FETCH);

    this.citiesList = (await this.$store.dispatch(this.$types.CITIES_ALL_FETCH, { query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    this.clinics = (await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH)).map(({
      id,
      name,
      cityId,
      clinicAddress,
    }) => ({ id, title: `${name}, ${clinicAddress}`, cityId }));

    if (this.panelTabId) {
      const { tabs } = this.$store.state.TabPanel;
      const { data } = tabs.filter((tab) => tab.id === this.panelTabId)[0];
      const {
        appealData,
        isEditMode,
        files,
        selectedClinic,
      } = data;
      this.panelTabData = JSON.parse(JSON.stringify(appealData));
      this.appealData = appealData;
      this.isEditMode = isEditMode;
      this.files = files;
      this.selectedClinic = selectedClinic;

      if (this.appealData?.base?.dateTime) this.appealData.base.dateTime = new Date(this.appealData.base.dateTime);
      if (this.appealData?.checkupDate) this.appealData.checkupDate = new Date(this.appealData.checkupDate);
      if (this.appealData?.person?.birthDate) this.appealData.person.birthDate = this.getFormatDate(this.appealData.person.birthDate, 'yyyy-MM-dd');
    } else if (this.initAppealData) {
      this.isEditMode = true;
      this.appealData.base.id = this.initAppealData.id;
      this.appealData.base.status = this.initAppealData.status;
      this.appealData.base.dateTime = parseISO(this.initAppealData.dateTime);
      this.appealData.base.purpose = this.initAppealData.purpose;
      this.appealData.base.comment = this.initAppealData.comment;
      this.appealData.base.fileIds = this.initAppealData.fileIds;
      this.appealData.type = this.initAppealData.type ? this.initAppealData.type.id : null;
      this.appealData.subtypeId = this.initAppealData.subtypeId ? this.initAppealData.subtypeId.id : null;
      this.appealData.returnComment = this.initAppealData.returnComment;
      this.appealData.returnType = this.initAppealData.returnType;
      // this.appealData.anotherTypeText = this.initAppealData.anotherTypeText || null;
      this.appealData.anotherTypeText = this.initAppealData.typeAnotherText || null;
      this.appealData.checkupDate = this.initAppealData.checkupDate ? this.getFormatDate(this.initAppealData.checkupDate, 'yyyy-MM-dd') : null;
      this.appealData.checkupProfile = this.initAppealData.checkupProfile?.id || null;
      this.appealData.rejectionReason = this.initAppealData.rejectionReason || null;
      this.appealData.provisionalDiagnosis = this.initAppealData.provisionalDiagnosis || null;
      this.appealData.consultationId = this.initAppealData.consultationId || null;
      this.appealData.consultationComment = this.initAppealData.consultationComment || null;
      this.appealData.consultationReason = this.initAppealData.consultationReason || null;
      // this.appealData.mkbCode = this.initAppealData.mkbCode || null;
      // this.appealData.insuranceLimit = this.initAppealData.insuranceLimit || null;
      this.appealData.checkup = this.initAppealData.checkup ?? null;
      this.appealData.urgency = this.initAppealData.urgency || false;
      this.appealData.hospitalizedFrom = this.initAppealData.hospitalizedFrom || null;
      this.appealData.curatorsPhone = this.initAppealData.curatorsPhone || null;
      this.appealData.city = this.initAppealData.city || { id: null };
      this.appealData.gostelemedType = this.initAppealData.gostelemedType?.id || null;
      this.appealData.gostelemedTypeAnotherText = this.initAppealData.gostelemedTypeAnotherText || null;
      this.appealData.policy = this.initAppealData.selectedPolicy ? { id: this.initAppealData.selectedPolicy.id } : { id: 0 };
      this.appealData.reconciliations = this.initAppealData.reconciliations.map((rec) => ({
        ...rec,
        clinicId: rec.clinic.id,
        files: rec.fileIds?.map((file) => ({ id: file.id || file, name: file.name || file }))
          || rec.files?.map((file) => ({ id: file.id || file, name: file.name || file })),
        fieldsDisabled: this.checkRoleAccess(['Operator', 'Tech.operator']),
      }));

      this.appealData.person = {
        id: this.initAppealData.person.id,
        firstName: this.initAppealData.person.firstName,
        lastName: this.initAppealData.person.lastName,
        middleName: this.initAppealData.person.middleName,
        phoneNumber: this.initAppealData.person.phoneNumber,
        isVip: this.initAppealData.person.isVip,
        isTop: this.initAppealData.person.isTop,
        birthDate: this.getFormatDate(this.initAppealData.person.birthDate, 'yyyy-MM-dd'),
        city: this.initAppealData.person.city,
      };

      this.files = this.initAppealData.fileIds.map((file) => ({ title: file.name, guid: file.id }));

      // if (this.initAppealData.fileIds.length > 0) { // Если нужна инфа о файлах
      //   const filesName = await Promise.all(this.initAppealData.fileIds
      //     .map((fileId) => this.$store.dispatch(this.$types.FILENAME_BY_ID_FETCH, fileId)));
      //   this.files = filesName.map((title, i) => ({ title, guid: this.initAppealData.fileIds[i] }));
      // }

      if (this.initAppealData?.clinic?.id) { // Если нужна инфа о клинике
        const clinic = await this.fetchClinicById(this.initAppealData.clinic.id);
        // this.selectedClinic = { id: clinic.id, title: clinic.title };
        this.selectedClinic = clinic.id;
        this.appealData.city = this.initAppealData?.clinic?.city || { id: null };
      }

      if (!this.appealData.reconciliations.length && !this.isInformationCard) this.addReconciliation();
    } else {
      // Новое обращение
      if (this.initDoctorData) {
        this.appealData.person = {
          firstName: this.initDoctorData.firstName,
          lastName: this.initDoctorData.lastName,
          middleName: this.initDoctorData.middleName,
          phoneNumber: this.initDoctorData.phoneNumber,
          birthDate: this.initDoctorData.birthDate ? this.getFormatDate(this.initDoctorData.birthDate, 'yyyy-MM-dd') : null,
        };

        this.appealData.base.purpose = 0;
      }

      // if (!this.appealData.reconciliations.length && !this.isInformationCard) this.addReconciliation();

      this.appealData.base.status = null;
      this.appealData.base.dateTime = parseISO(new Date().toISOString());

      if (this.initPatientData) {
        this.appealData.person = {
          id: this.initPatientData.person.id,
          firstName: this.initPatientData.person.firstName,
          lastName: this.initPatientData.person.lastName,
          middleName: this.initPatientData.person.middleName,
          phoneNumber: this.initPatientData.person.phoneNumber,
          isVip: this.initPatientData.person.isVip,
          isTop: this.initPatientData.person.isTop,
          // birthDate: parseISO(this.initPatientData.person.birthDate),
          birthDate: this.getFormatDate(this.initPatientData.person.birthDate, 'yyyy-MM-dd'),
          city: this.initPatientData.person.city,
          userId: this.initPatientData.person.userId,
          sex: this.initPatientData.person.sex,
          snils: this.initPatientData.person.snils,
          parentId: this.initPatientData.person.parentId,
          email: this.initPatientData.person.email,
        };
      }
    }

    this.isLoading = false;
    this.setWatcher();
    Bus.$on('appeal:save-to-tab', this.saveAppealDataToTabPanel);
  },

  beforeDestroy() {
    Bus.$off('appeal:save-to-tab', this.saveAppealDataToTabPanel);

    if (!this.savedToTabPanel && this.panelTabId) {
      this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
      this.$store.commit(this.$types.TAB_PANEL_ITEM_REMOVE_BY_ID, this.panelTabId);
    }
  },

  methods: {
    // openIndemnityModal(personId, clinicId) {
    //   Bus.$emit('open-modal:indemnity-modal', {
    //     personId,
    //     clinicId,
    //   });
    // },

    onPaste(e) {
      onBInputDatePaste(e);
    },

    openIndemnityModal() {
      Bus.$emit('open-modal:indemnity-modal', {
        personId: this.appealData.person.id,
        clinicId: null, // Денис, вместо null необходимо вставить id выбранной клиники (если Id нет, от можно оставить null)
      });
    },

    // openIndemnityModal() {
    //   console.log(this.appealData.person.id);
    // },

    onAppealPurposeChange() {
      this.informationTypeCategory = null;
      this.onAppealTypeCategoryChange();
    },

    onAppealTypeCategoryChange() {
      this.appealData.type = null;
      this.onAppealTypeChange();
    },

    onAppealTypeChange() {
      this.appealData.subtypeId = null;
      this.onAppealSubtypeChange();
    },

    onAppealSubtypeChange() {
      this.appealData.consultationId = null;
      this.appealData.consultationReason = null;
      this.appealData.consultationComment = null;
      this.appealData.appealReturnType = null;
      this.appealData.returnComment = null;
    },

    onAppealCityChange() {
      this.selectedClinic = null;
    },
    saveAppealDataToTabPanel() {
      this.$store.commit(this.$types.TAB_PANEL_ITEMS_FETCH_FROM_LOCALSTORAGE);
      const mutationName = this.panelTabId ? 'TAB_PANEL_ITEM_UPDATE_BY_ID' : 'TAB_PANEL_ITEM_ADD';

      const { lastName, firstName, middleName } = this.appealData.person;
      const personFIO = `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
      const tabTitle = lastName || firstName || middleName ? personFIO : 'Новое обращение';

      const tabData = {
        id: this.panelTabId || Date.now(),
        title: this.appealData?.base?.id ? `Обращение ${this.appealData.base.id}` : tabTitle,
        type: 0, // appeal type
        data: {
          appealData: this.appealData,
          isEditMode: this.isEditMode,
          files: this.files,
          selectedClinic: this.selectedClinic,
        },
      };

      this.$store.commit(this.$types[mutationName], tabData);
      this.savedToTabPanel = true;
    },
    async openIndemnityBtnHandler() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
      } else if (!this.appealData.person.id) {
        try {
          const newPerson = {
            ...this.appealData.person,
            birthDate: formatISO(this.appealData.person.birthDate),
            phoneNumber: this.phoneFormatToSave(this.appealData.person.phoneNumber),
          };
          const patient = await this.$store.dispatch(this.$types.PATIENT_CREATE, newPerson);
          this.openIndemnityModal(patient.id, this.selectedClinic.id);
        } catch {
          showMessage({
            type: 'warning',
            title: 'Ошибка',
            message: 'Не удалось создать пациента',
          });
        }
      } else {
        this.openIndemnityModal(this.appealData.person.id, this.selectedClinic.id);
      }
    },

    printError(errArray) {
      return errArray ? errArray.toString() : null;
    },

    async downloadFile(fileToDownload) {
      const { data: file } = await api.get(`File/${fileToDownload.guid || fileToDownload.id || fileToDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileToDownload.title || fileToDownload.name || fileToDownload });
    },

    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback, multiple: true });
    },
    saveFileCallback(newFilesArray) {
      const files = [];
      newFilesArray.map((fileObject) => {
        files.push({
          title: fileObject.name,
          guid: fileObject.guid,
        });
        return null;
      });
      this.files = [...this.files, ...files];
    },
    onClickOpenFileReconciliationModal(index) {
      Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileReconciliationCallback, multiple: true, otherProps: index });
    },
    saveFileReconciliationCallback(newFilesArray, index) {
      const files = [];
      newFilesArray.map((fileObject) => {
        files.push({
          title: fileObject.name,
          guid: fileObject.guid,
        });
        return null;
      });

      this.appealData.reconciliations[index].files = [...this.appealData.reconciliations[index].files, ...files];
    },
    selectPolicyHandler(policy) {
      if (this.appealData.policy.id === policy.id) {
        this.appealData.policy.id = null;
      } else {
        this.appealData.policy.id = policy.id;
      }
    },
    fetchCities({ skip, take, query }) {
      return this.$store.dispatch(this.$types.CITIES_FETCH, {
        take,
        query,
        skip,
      });
    },
    async fetchClinics({ skip, take, query }) {
      // return this.$store.dispatch(this.$types.NETWORK_V2_FETCH, params);
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
        },
      });

      return clinics.map((clinic) => ({ id: clinic.id, title: clinic.title }));
    },

    setWatcher() {
      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);
      this.unwatch = this.$watch('appealData.person',
        () => {
          // if (this.unwatch) {
          //   this.unwatch();
          // }

          if (
            this.initPatientData && this.isShowPatientDataSection
          ) {
            if (
              this.appealData.person.firstName !== this.initPatientData.person.firstName
              || this.appealData.person.lastName !== this.initPatientData.person.lastName
              || this.appealData.person.middleName !== this.initPatientData.person.middleName
              || this.phoneFormatToSave(this.appealData.person.phoneNumber) !== this.phoneFormatToSave(this.initPatientData.person.phoneNumber)
              || !isEqual(this.appealData.person.birthDate, parseISO(this.initPatientData.person.birthDate))
              || this.appealData.person.city !== this.initPatientData.person.city
            ) {
              this.isNeedUpdatePatient = true;
            } else {
              this.isNeedUpdatePatient = false;
            }
          } else {
            this.isNeedUpdatePatient = false;
          }
        },
        {
          deep: true,
        });
    },

    // changeCityForClinic() {
    //   this.selectedClinic = null;
    //   this.$refs.clinicByCity.forceServerPaginatedFetch(); // Используется для принудительного обновления списка клиник по фильтру города
    // },

    async fetchClinicsByCity({ skip, take, query }) {
      // return this.$store.dispatch(this.$types.NETWORK_V2_FETCH, params);
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          city: this.appealData.city,
        },
      });

      return clinics.map((clinic) => ({ id: clinic.id, title: clinic.title }));
    },

    fetchClinicById(id) {
      return this.$store.dispatch(this.$types.CLINIC_FETCH, id);
    },

    onClinicSelect(clinicId) {
      this.appealData.reconciliations = this.appealData.reconciliations.map((r) => ({ ...r, clinicId }));
    },

    phoneFormatToSave(phone) {
      if (phone) {
        return phone
          .split(' ')
          .join('')
          .split('(')
          .join('')
          .split(')')
          .join('')
          .split('-')
          .join('');
      }
      return '';
    },

    addReconciliation() {
      const reconciliation = {
        appealId: this.initAppealData?.id || 0,
        status: 0,
        createDate: new Date(),
        initiator: null,
        clinicId: null,
        diagnosis: null,
        agreedServices: null,
        notAgreedServices: null,
        regectionReason: null,
        doctorComment: null,
        commentForOperator: null,
        mkbCode: null,
        insuranceLimit: null,
        files: [],
        fileIds: [],
        fieldsDisabled: this.checkRoleAccess(['Operator', 'Tech.operator']),
      };

      this.appealData.reconciliations.push(reconciliation);
    },

    deleteReconciliation(index) {
      this.appealData.reconciliations.splice(index, 1);
      // console.log(this.appealData.reconciliations[index]);
    },

    getFormatDate(date, formatter) {
      return date && formatter ? format(new Date(date), formatter) : null;
    },

    async onClickSave() {
      try {
        this.$v.$touch();
        this.checkValidation = true;
        if (
          this.$v.$error
          || !this.policyValidation
        ) {
          showMessage({
            type: 'warning',
            title: 'Ошибка валидации',
            message: 'Проверьте правильность заполнения полей',
          });
        } else {
          this.isLoading = true;
          const appealDataToSave = {
            base: {
              ...this.appealData.base,
              id: this.appealData.base.id || 0, // 0 вместо null - для удобства бэка
              dateTime: formatISO(this.appealData.base.dateTime),
            },
          };

          // if (this.isClinicRequired)
          appealDataToSave.clinic = { id: this.selectedClinic };
          if (this.isTypeRequired) appealDataToSave.type = this.appealData.type;
          if (this.isAnotherTypeTextRequired) appealDataToSave.anotherTypeText = this.appealData.anotherTypeText;
          if (this.isCheckupDateRequired) appealDataToSave.checkupDate = formatISO(new Date(this.appealData.checkupDate));
          if (this.isCheckupProfileRequired) appealDataToSave.checkupProfile = this.appealData.checkupProfile;
          if (this.isRejectionReasonRequired) appealDataToSave.rejectionReason = this.appealData.rejectionReason;
          if (this.isProvisionalDiagnosisRequired) appealDataToSave.provisionalDiagnosis = this.appealData.provisionalDiagnosis;
          // if (this.isMKBCodeRequired) appealDataToSave.mkbCode = this.appealData.mkbCode;
          // if (this.isInsuranceLimitRequired) appealDataToSave.insuranceLimit = this.appealData.insuranceLimit;
          if (this.appealData.subtypeId) appealDataToSave.subtypeId = this.appealData.subtypeId;
          if (this.isConsultationSubtype) appealDataToSave.consultationId = +this.appealData.consultationId;
          if (this.isConsultationSubtype) appealDataToSave.consultationReason = this.appealData.consultationReason;
          if (this.isConsultationSubtype) appealDataToSave.consultationComment = this.appealData.consultationComment;
          if (this.isPaymentReturnSubtype) appealDataToSave.returnType = this.appealData.returnType;
          if (this.isPaymentReturnSubtype) appealDataToSave.returnComment = this.appealData.returnComment;
          // if (this.isCheckupRequired) appealDataToSave.checkup = this.appealData.checkup;
          appealDataToSave.checkup = this.appealData.checkup;
          if (this.isHospitalizedFromRequired) appealDataToSave.hospitalizedFrom = this.appealData.hospitalizedFrom;
          if (this.isHospitalizationCard) appealDataToSave.rejectionReason = this.appealData.rejectionReason;
          if (this.isCuratorsPhoneRequired) appealDataToSave.curatorsPhone = this.phoneFormatToSave(this.appealData.curatorsPhone);
          if (this.isCityRequired) appealDataToSave.city = this.appealData.city.id; // Решили город не отправлять, нужен только для поиска клиники (как фильтр)
          if (this.isGostelemedTypeRequired) appealDataToSave.gostelemedType = this.appealData.gostelemedType;
          if (this.isGostelemedTypeAnotherTextRequired) appealDataToSave.gostelemedTypeAnotherText = this.appealData.gostelemedTypeAnotherText;
          if (this.files.length > 0) appealDataToSave.base.fileIds = this.files.map((file) => file.guid);
          if (this.isShowPersonPolicySelect) appealDataToSave.policy = { id: this.appealData.policy.id || 0 }; // 0 вместо null - для удобства бэка

          if (!this.initAppealData) { // Создается новая карточка
            if (!this.initPatientData) { // Без привязки к существующему пациенту
              appealDataToSave.person = {
                ...this.appealData.person,
                birthDate: this.appealData.person.birthDate ? formatISO(new Date(this.appealData.person.birthDate)) : null,
              };
            } else {
              appealDataToSave.person = { id: this.appealData.person.id || 0 }; // 0 вместо null - для удобства бэка
            }
          } else {
            appealDataToSave.person = { id: this.appealData.person.id || 0 }; // 0 вместо null - для удобства бэка
          }

          if (this.isTreatmentCard) {
            appealDataToSave.provisionalDiagnosis = this.appealData.provisionalDiagnosis;
            // appealDataToSave.mkbCode = this.appealData.mkbCode;
            // appealDataToSave.insuranceLimit = this.appealData.insuranceLimit;
            appealDataToSave.urgency = this.appealData.urgency;
          }

          appealDataToSave.reconciliations = this.appealData.reconciliations
            .map((rec) => ({
              ...rec,
              appealId: this.appealData?.base?.id || 0,
              fileIds: rec.files?.map((file) => file.guid) || rec.fileIds,
            }));

          if (this.initPatientData && this.isNeedUpdatePatient) {
            const formattedPatientData = {
              ...this.appealData.person,
              birthDate: formatISO(new Date(this.appealData.person.birthDate)),
              phoneNumber: this.phoneFormatToSave(this.appealData.person.phoneNumber),
            };
            try {
              await this.$store.dispatch(this.$types.PATIENT_UPDATE, formattedPatientData);
            } catch (e) {
              showMessage({
                type: 'error',
                message: 'Не удалось обновить данные о пациенте',
              });
              this.isLoading = false;
            }
          }

          try {
            const dispatchType = this.isEditMode ? this.$types.APPEAL_UPDATE : this.$types.APPEAL_ADD;
            await this.$store.dispatch(dispatchType, appealDataToSave);
            this.$emit('vuedals:close');

            if (this.isEditMode) {
              Bus.$emit('appeals:fetch', true);
            }
            this.afterSaveFunc();
          } catch {
            showMessage({
              type: 'error',
              message: 'Не удалось сохранить обращение',
            });
            this.isLoading = false;
          }
          // console.log(appealDataToSave);
        }

        this.isLoading = false;

      // this.$emit('vuedals:close');
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-scrollbar-wrapper {
  height: calc(100vh - 202px);
  font-size: initial;
  line-height: initial;
  // margin-right: 10px;
  // margin: 0 -10px 10px;
  font-size: 1rem;
  line-height: 1.5;
}

.appeal-modal {
  padding-left: 5px;
  padding-right: 20px;
}

::v-deep.appeal-form .col-form-label.required::after {
  content: ' *';
  color: #dc3545;
}

.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

::v-deep.crm-comment-area {
  textarea {
    height: 94px;
  }
}
.crm-input-label {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.crm-radio-button {
  margin-top: 10px;
}
.appeal-date {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: $black-light;
  background-color: #F3F3F3;
  padding: 10px;
  width: fit-content;
  border-radius: 4px;
}

.person-age-block {
  width: 70px;
  text-align: center;
}

.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.crm-info-text {
  color: #908F8D;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0px;
}
.uploaded-document-name {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
   span {
     margin-left: 20px;
   }
}

.files-section {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.crm-icon-cross {
  margin-right: 5px;
  path {
    fill: $blue;
  }
}

.crm-file-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.document-icon {
  transform: scale(0.5);
  path {
    fill: $blue;
  }
}
.status-column {
  display: flex;
  justify-content: space-between;
}

.create-gp-btn {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: $blue;
  font-weight: 600;
  cursor: pointer;
  .gp-icon {
    margin-left: 10px;
  }
}

::v-deep.vs__dropdown-menu {
  max-height: 250px;
}

.reconciliation-button {
  width: unset;
  padding: 0;
}

.width-unset {
  width: unset;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
