<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xs"
    title="Рассылка СМС-сообщений"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-tabs
        v-model="tabIndex"
      >
        <b-tab
          title="Шаблон на номера"
          class="p-2 border border-top-0"
          @click="onTabChange"
        >
          <b-form-group
            invalid-feedback="Поле является обязательным"
            :state="!$v.text.$error"
          >
            <div class="d-flex align-items-center mb-2">
              <label class="col-form-label required">
                Текст сообщения
              </label>
            </div>

            <b-form-textarea
              v-model="$v.text.$model"
              placeholder="Введите текст"
              rows="4"
              required
              @input="onTextInput"
            />
            <small>
              Осталось символов {{ availableCharacters }}/{{ maxTextLength }}
            </small>
          </b-form-group>
          <b-form-group
            label="Импорт номеров"
          >
            <b-form-file
              v-model="importFile"
              accept=".xlsx,"
              plain
            />
            <transition name="fade">
              <div
                v-if="importFile"
                class="mt-2"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  @click="onClickImport"
                >
                  Импортировать
                </b-button>
                <b-table
                  v-if="phoneNumbers"
                  :items="phoneNumbers"
                  :fields="fields"
                  class="mt-3"
                  size="sm"
                />
              </div>
            </transition>
            <div v-if="$v.$error">
              <span class="required-text">
                Поле является обязательным *
              </span>
            </div>
          </b-form-group>
        </b-tab>
        <b-tab
          title="Импорт из файла"
          class="p-2 border border-top-0"
          @click="onTabChange"
        >
          <b-form-group
            label="Импорт номеров"
          >
            <b-form-file
              v-model="importFile"
              accept=".xlsx,"
              plain
            />
            <transition name="fade">
              <div
                v-if="importFile"
                class="mt-2"
              >
                <b-button
                  size="sm"
                  variant="primary"
                  @click="onClickImport"
                >
                  Импортировать
                </b-button>
                <b-table
                  v-if="phoneNumbers"
                  :items="phoneNumbers"
                  :fields="fields2"
                  class="mt-3"
                  size="sm"
                />
              </div>
            </transition>
            <div v-if="$v.$error">
              <span class="required-text">
                Поле является обязательным *
              </span>
            </div>
          </b-form-group>
        </b-tab>
      </b-tabs>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        class="float-right"
        @click="openSmsSendingModal"
      >
        Отправить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';

import Preloader from '@/components/Preloader';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'SmsSending',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    numberProp: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'phone',
          label: 'Номер телефона',
        },
      ],
      fields2: [
        {
          key: 'phone',
          label: 'Номер телефона',
        },
        {
          key: 'text',
          label: 'Текст сообщения',
        },
      ],
      importFile: null,
      isOpen: true,
      isSaving: false,
      text: '',
      phoneNumbers: null,
      maxTextLength: 1000,
      tabIndex: 0,
    };
  },
  validations() {
    return {
      phoneNumbers: {
        required,
        minLength: minLength(0),
      },
      text: {
        required: this.tabIndex === 0 ? required : () => true,
      },
    };
  },
  computed: {
    availableCharacters() {
      return this.maxTextLength - this.text.length;
    },
  },
  mounted() {
    const messageLocalStorage = localStorage.getItem('sending-modal-message');
    if (messageLocalStorage && typeof JSON.parse(messageLocalStorage) !== 'object') localStorage.removeItem('sending-modal-message');

    const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
    const message = messagesObject[this.numberProp];
    if (message) {
      try {
        this.text = message;
      } catch (e) {
        this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
      }
    } else {
      this.text = `Добрый день${this.name ? `, ${this.name}` : ''}!\nПо всем вопросам: 8-800-500-02-99.\nВаш Доктис.`;
    }

    if (this.numberProp) this.number = this.numberProp;
  },
  methods: {
    onTabChange() {
      this.importFile = null;
      this.phoneNumbers = null;
    },
    async onClickImport() {
      const params = {
        importFile: this.importFile,
      };
      this.phoneNumbers = await this.$store.dispatch(this.$types.SMS_IMPORT_PHONES, params);
    },
    openSmsSendingModal() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      if (this.tabIndex === 0) {
        this.$store.commit(this.$types.OPEN_MODAL, {
          name: 'SmsSendingConfirmation',
          props: {
            text: this.text,
            onSend: this.onSmsSend,
          },
        });
      } else {
        this.onSmsSend();
      }
    },
    async onSmsSend() {
      await this.$store.dispatch(this.$types.SMS_SENDING, {
        phoneNumbers: this.phoneNumbers,
        text: this.tabIndex === 0 ? this.text : null,
      });

      const messageLocalStorage = localStorage.getItem('sending-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      delete messagesObject[this.phoneNumbers];
      localStorage.setItem('sending-modal-message', JSON.stringify(messagesObject));

      this.onClose();
      Bus.$emit('messages:update');
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onTextInput(value) {
      const messageLocalStorage = localStorage.getItem('sending-modal-message');
      const messagesObject = messageLocalStorage ? JSON.parse(messageLocalStorage) : {};
      messagesObject[this.numberProp] = value;
      localStorage.setItem('sending-modal-message', JSON.stringify(messagesObject));
    },
  },
};
</script>

<style lang="scss" scoped>
.required-text {
  color: red;
  font-size: 13px;
}
</style>
