<template>
  <div class="w-100 p-4">
    <b-container fluid="xl">
      <b-row style="gap: 12px;">
        <b-col class="bg-white p-3 rounded border">
          <div class="h5 font-weight-bold">
            Статистика
          </div>

          <div>
            <div class="font-weight-bold mb-1 mt-3">
              Период
            </div>

            <b-row class="d-flex pb-5">
              <b-col>
                <base-date-picker
                  v-model="$v.unloadingFrom.$model"
                  :max-date="maxUnloadingDateFrom"
                />

                <div
                  v-if="$v.unloadingFrom.$error"
                  class="mt-2 text-danger small"
                >
                  {{ getValidationErrors('unloadingFrom')[0] }}
                </div>
              </b-col>

              <b-col>
                <base-date-picker
                  v-model="$v.unloadingTo.$model"
                  :min-date="minUnloadingDateTo"
                  :max-date="maxDateTo"
                />

                <div
                  v-if="$v.unloadingTo.$error"
                  class="mt-2 text-danger small"
                >
                  {{ getValidationErrors('unloadingTo')[0] }}
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="primary"
              class="ml-2"
              @click="openUnloadingModal"
            >
              Скачать Excel
            </b-button>
          </div>
        </b-col>

        <!-- v-if="!checkRoleAccess_(['Dis.monitoring.operator', 'ClinicAdministrator'])" -->
        <b-col class="bg-white p-3 rounded border">
          <div class="h5 font-weight-bold">
            Данные по ТМК
          </div>

          <div>
            <div class="font-weight-bold mb-1 mt-3">
              Период
            </div>

            <b-row class="d-flex pb-5">
              <b-col>
                <base-date-picker
                  v-model="$v.unloadingTmkFrom.$model"
                  :max-date="maxDateTo"
                />

                <div
                  v-if="$v.unloadingTmkFrom.$error"
                  class="mt-2 text-danger small"
                >
                  {{ getValidationErrors('unloadingTmkFrom')[0] }}
                </div>
              </b-col>

              <b-col>
                <base-date-picker
                  v-model="$v.unloadingTmkTo.$model"
                  :min-date="minUnloadingTmkDateTo"
                  :max-date="maxDateTo"
                />

                <div
                  v-if="$v.unloadingTmkTo.$error"
                  class="mt-2 text-danger small"
                >
                  {{ getValidationErrors('unloadingTmkTo')[0] }}
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="d-flex justify-content-end">
            <b-button
              size="sm"
              variant="primary"
              class="ml-2"
              @click="fetchTmk"
            >
              Скачать Excel
            </b-button>
          </div>
        </b-col>
      </b-row>
      <!-- v-if="!checkRoleAccess_(['Dis.monitoring.operator', 'ClinicAdministrator'])" -->
      <b-row
        v-if="checkFeatureAccess({ name: 'Чекап', url: '/reports' })"
        class="mt-3"
      >
        <b-col class="d-flex justify-content-between bg-white p-3 rounded border">
          <div class="h5 font-weight-bold">
            Чекап
          </div>

          <b-button
            size="sm"
            variant="primary"
            @click="openCheckupExportModal"
          >
            Экспорт
          </b-button>
        </b-col>
      </b-row>
      <!--      <b-row class="mt-3">-->
      <!--        <b-col-->
      <!--          v-if="checkFeatureAccess({ name: 'Отчет для КТГ', url: '/reports' })"-->
      <!--          class="bg-white p-3 rounded border"-->
      <!--        >-->
      <!--          <div class="h5 font-weight-bold">-->
      <!--            Отчет для КТГ-->
      <!--          </div>-->

      <!--          <div>-->
      <!--            <div class="font-weight-bold mb-1 mt-3">-->
      <!--              Период-->
      <!--            </div>-->

      <!--            <b-row class="d-flex pb-5">-->
      <!--              <b-col>-->
      <!--                <base-date-picker-->
      <!--                  v-model="$v.unloadingKtgFrom.$model"-->
      <!--                  :max-date="unloadingKtgTo"-->
      <!--                />-->

      <!--                <div-->
      <!--                  v-if="$v.unloadingKtgFrom.$error"-->
      <!--                  class="mt-2 text-danger small"-->
      <!--                >-->
      <!--                  {{ getValidationErrors('unloadingKtgFrom')[0] }}-->
      <!--                </div>-->
      <!--              </b-col>-->

      <!--              <b-col>-->
      <!--                <base-date-picker-->
      <!--                  v-model="$v.unloadingKtgTo.$model"-->
      <!--                  :min-date="minUnloadingKtgDateTo"-->
      <!--                  :max-date="maxDateTo"-->
      <!--                />-->

      <!--                <div-->
      <!--                  v-if="$v.unloadingKtgTo.$error"-->
      <!--                  class="mt-2 text-danger small"-->
      <!--                >-->
      <!--                  {{ getValidationErrors('unloadingKtgTo')[0] }}-->
      <!--                </div>-->
      <!--              </b-col>-->
      <!--            </b-row>-->
      <!--          </div>-->

      <!--          <div class="d-flex justify-content-end">-->
      <!--            <b-button-->
      <!--              size="sm"-->
      <!--              variant="light"-->
      <!--              class="border"-->
      <!--              @click="resetKtgFilters"-->
      <!--            >-->
      <!--              Сбросить даты-->
      <!--            </b-button>-->

      <!--            <b-button-->
      <!--              size="sm"-->
      <!--              variant="primary"-->
      <!--              :disabled="ktgLoading"-->
      <!--              class="ml-2"-->
      <!--              @click="fetchKtg"-->
      <!--            >-->
      <!--              Скачать-->

      <!--              <b-spinner-->
      <!--                v-if="ktgLoading"-->
      <!--                variant="light"-->
      <!--                small-->
      <!--              />-->
      <!--            </b-button>-->
      <!--          </div>-->
      <!--        </b-col>-->
      <!--      </b-row>-->
    </b-container>
  </div>
</template>

<script>
import {
  subMonths,
  formatISO,
} from 'date-fns';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mixinRoles } from '@/mixins';
import { dateWithoutTime } from '@/helpers/utils';

import { BaseDatePicker } from '@/components/base';

export default {
  name: 'Reports',
  page: {
    title: 'CRM Doctis - Статистика',
  },
  components: {
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  data() {
    return {
      unloadingFrom: subMonths(new Date(), 1),
      unloadingTo: new Date(),
      statisticsFrom: subMonths(new Date(), 1),
      statisticsTo: new Date(),
      statisticsPeriod: 2,
      unloadingKtgFrom: subMonths(new Date(), 1),
      unloadingKtgTo: new Date(),
      unloadingTmkFrom: subMonths(new Date(), 1),
      unloadingTmkTo: new Date(),
      ops: {
        scrollPanel: {
          scrollingX: true,
        },
      },
      ktgLoading: false,
      tmkLoading: false,
    };
  },
  validations: {
    unloadingFrom: {
      required,
    },
    unloadingTo: {
      required,
    },
    statisticsFrom: {
      required,
    },
    statisticsTo: {
      required,
    },
    unloadingKtgFrom: {
      required,
    },
    unloadingKtgTo: {
      required,
    },
    unloadingTmkFrom: {
      required,
    },
    unloadingTmkTo: {
      required,
    },
    unloadingGroup: ['unloadingFrom', 'unloadingTo'],
    statisticsGroup: ['statisticsFrom', 'statisticsTo'],
    ktgGroup: ['unloadingKtgFrom', 'unloadingKtgTo'],
    tmkGroup: ['unloadingTmkFrom', 'unloadingTmkTo'],
  },
  computed: {
    maxUnloadingDateFrom() {
      return this.unloadingTo || new Date();
    },
    minUnloadingDateTo() {
      return this.unloadingFrom || null;
    },
    maxDateTo() {
      return new Date();
    },
    maxStatisticsDateFrom() {
      return this.statisticsTo || new Date();
    },
    minStatisticsDateTo() {
      return this.statisticsFrom || null;
    },
    maxUnloadingKtgDateFrom() {
      return this.unloadingKtgFrom || new Date();
    },
    minUnloadingKtgDateTo() {
      return this.unloadingKtgFrom || null;
    },
    // maxUnloadingTmkDateFrom() {
    //   return this.unloadingTmkFrom || new Date();
    // },
    minUnloadingTmkDateTo() {
      return this.unloadingTmkFrom || null;
    },
  },
  created() {
    // this.fetchStatistics();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.STATISTICS_CLEAR);
  },
  methods: {
    getValidationErrors(field) {
      const errors = [];

      if (!this.$v[field].required || !this.$v[field].isValid) {
        errors.push('Неверное значение поля');
      }

      return errors;
    },
    async fetchStatistics() {
      if (this.$v.statisticsGroup.$error) return;

      try {
        this.$store.dispatch(this.$types.STATISTICS_FETCH, {
          to: this.statisticsTo && formatISO(this.statisticsTo),
          from: this.statisticsFrom && formatISO(this.statisticsFrom),
          dateRangeKind: this.statisticsPeriod,
        });
      } catch (e) {
        console.warn(e);
      }
    },
    resetStatisticsFilters() {
      this.statisticsFrom = null;
      this.statisticsTo = null;
      this.statisticsPeriod = 2;
    },
    async fetchKtg() {
      if (this.$v.ktgGroup.$error) return;

      this.ktgLoading = true;

      try {
        await this.$store.dispatch(this.$types.STATISTICS_KTG_UNLOAD_FETCH, {
          startDate: this.unloadingKtgFrom ? dateWithoutTime(formatISO(this.unloadingKtgFrom)) : null,
          endDate: this.unloadingKtgTo ? dateWithoutTime(formatISO(this.unloadingKtgTo)) : null,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        this.ktgLoading = false;
      }
    },
    async fetchTmk() {
      if (this.$v.tmkGroup.$error) return;

      this.tmkLoading = true;

      try {
        await this.$store.dispatch(this.$types.STATISTICS_TMK_UNLOAD_FETCH, {
          startDate: this.unloadingTmkFrom ? dateWithoutTime(formatISO(this.unloadingTmkFrom)) : null,
          endDate: this.unloadingTmkTo ? dateWithoutTime(formatISO(this.unloadingTmkTo)) : null,
        });
      } catch (e) {
        console.warn(e);
      } finally {
        this.tmkLoading = false;
      }
    },
    resetKtgFilters() {
      this.unloadingKtgFrom = null;
      this.unloadingKtgTo = null;
    },
    openUnloadingModal() {
      if (this.$v.unloadingGroup.$error) return;

      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DownloadExcelModal',
        props: {
          dateFrom: this.unloadingFrom ? dateWithoutTime(formatISO(this.unloadingFrom)) : null,
          dateTo: this.unloadingTo ? dateWithoutTime(formatISO(this.unloadingTo)) : null,
        },
      });
    },
    openCheckupExportModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'CheckupExportModal' });
    },
    dateForInput(date) {
      return new Date(date).toISOString().split('T')[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-scrollbar {
  height: 100vh;
  margin: 0 auto;
}
.statistics {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 800px;
}
.crm-container {
  &.crm-container--title {
    margin-bottom: 50px;
    justify-content: space-between;
  }

  &.crm-container--title,
  .crm-container--unloading {
    justify-content: space-between;
  }

  &.crm-container-ktg {
    margin-top: 40px;
  }
}

.unloading-menu {
  display: flex;
  flex-basis: 870px;
  align-items: center;
  justify-content: space-between;
}
.chartWrapper {
  display: flex;
  width: 90%;
  flex-flow: column;
}
.unloading-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 605px;
}
.statistics-datepickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statistics-radiobuttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.statistics-radiobuttons--title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-right: 20px;
}
.statistics-radiobuttons {
  margin-left: 20px;
}
.crm-radiobutton {
  &:not(:last-child) {
    margin-right: 20px;
  }
}
.title-unloading {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $black-light;
  margin-right: 20px;
}
.crm-datepicker {
  flex-basis: 168px;
}
.crm-buttons-wrapper {
  display: flex;
  width: 269px;
}
.button_transparent {
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 8px 0;
  flex-basis: 127px;

  &.without-border {
    border: none;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  &.unloading-button {
    flex-basis: 168px;
    //margin-left: 220px;
  }

  .exel-icon{
    margin-left: 6px;
  }
}
.crm-container--unloading {
  margin-bottom: 50px;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-radius: 12px;
  border-top: 2px solid rgba(0, 0, 0, 0.58);
}
</style>
