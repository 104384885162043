<template>
  <div class="page-monitoring-find">
    <div v-if="$store.state.Auth.user.doctorId" class="container-xl">
      <button type="primary" class="page-monitoring-find__lk-button btn ml-auto btn-primary" @click="openDoctorAuth">
        В ЛК врача
      </button>
    </div>
    <div class="page-monitoring-find__monitoring-table">
      <d-remote-monitoring-monitorings-table
        :user="user"
        :role="role"
      />
    </div>
  </div>
</template>

<script>
import { APP_DOCTOR_URL } from '@/app.config';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'RemoteMonitoringMonitoringsTable',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
  computed: {
    user() {
      return this.$store.state.Auth.user;
    },
    role() {
      return this.$store.state.Auth.role;
    },
  },
  methods: {
    async openDoctorAuth() {
      try {
        if (this.$store.state.Auth.user.doctorId) {
          const id = this.$store.state.Auth.user.doctorId;
          const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, id);
          const doctorUrl = APP_DOCTOR_URL;
          const url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;
          window.location.href = url;
        }
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось авторизовать врача',
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .page-monitoring-find {
    overflow: auto;
    width: 100%;
    .container-xl {
      position: relative;
    }
    .h-100 {
      height: auto!important;
    }
    &__lk-button {
      position: absolute!important;
      right: 16px!important;
      z-index: 3;
      top: 15px;
    }
  }
  .page-monitoring-find .d-remote-monitoring-monitorings-table .container-xl {
    overflow: hidden;
  }
</style>
