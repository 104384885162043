/* eslint-disable no-restricted-syntax */
import axios from 'axios';

import { showMessage } from '@/helpers/utils';
import { APP_ANALYSIS_API_TOKEN, APP_ANALYSIS_API_URL } from '@/app.config';

const api = axios.create({
  baseURL: APP_ANALYSIS_API_URL,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    Authorization: APP_ANALYSIS_API_TOKEN,
  },
  withCredentials: true,
  credentials: 'include',
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response = {} } = error;
    const { status = {}, data = {} } = response;

    const { errors = {} } = data;

    console.log(data.title);
    /* Нет ответа от сервера */
    if (!Object.keys(data).length || status === 500) {
      showMessage({ type: 'error', title: 'Ошибка соединения', message: 'Нет ответа от сервера' });

      return Promise.reject(error);
    }

    if (typeof data === 'string') {
      showMessage({ type: 'error', title: 'Ошибка', message: data });
    } else if (data.title) {
      showMessage({ type: 'error', title: 'Ошибка', message: data.title });
    } else if (data.Message) {
      showMessage({ type: 'error', title: 'Ошибка', message: data.Message });
    } else if (Object.entries(errors).length) {
      for (const [title, errorArray] of Object.entries(errors)) {
        const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');

        showMessage({ type: 'error', title, message: errorsString });
      }
    } else {
      for (const [title, errorArray] of Object.entries(data)) {
        const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');

        showMessage({ type: 'error', title, message: errorsString });
      }
    }

    if (status === 403) {
      showMessage({ type: 'error', title: 'Ошибка', message: 'Отсутствуют необходимые права' });
    }

    if (status === 401) {
      showMessage({ type: 'error', title: 'Ошибка', message: 'Не пройдена авторизация' });
    }

    return Promise.reject(error);
  },
);

export default api;
