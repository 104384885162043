<template>
  <div
    class="patient-detail"
  >
    <div class="crm-tab-row">
      <div
        v-for="(tab) in filteredTabs"
        :key="tab.index"
        class="crm-tab-item"
        :class="{ active: tab.index === activeTab}"
        @click="() => activeTab = tab.index"
      >
        {{ tab.text }}
      </div>
    </div>
    <div class="crm-tab-detail">
      <Loading
        v-if="isLoading"
        class="loading-wrapper"
      />
      <template v-else>
        <PatientDetailPerson
          v-if="activeTab === 0"
          :person="patient"
          @update-patient="updatePatient"
        />
        <PatientDetailConsultations
          v-if="activeTab === 1"
          :patient-id="patient.id"
        />
        <PatientDetailPolicies
          v-if="activeTab === 2"
          :policies="patient.policies"
          :person-id="patient.id"
        />
        <PatientDetailAppeals
          v-if="activeTab === 3"
          :patient-id="patient.id"
          :patient="patient"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';
import PatientDetailPerson from '@/components/Patients/PatientDetailPerson';
import PatientDetailPolicies from '@/components/Patients/PatientDetailPolicies';
import PatientDetailConsultations from '@/components/Patients/PatientDetailConsultations';
import PatientDetailAppeals from '@/components/Patients/PatientDetailAppeals';
import Loading from '@/components/Loading';

export default {
  name: 'PatientDetailModal',
  components: {
    PatientDetailPerson,
    PatientDetailPolicies,
    PatientDetailConsultations,
    PatientDetailAppeals,
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      insuranceProgramms: [],
      tabs: [
        {
          index: 0,
          text: 'Информация о пациенте',
        },
        {
          index: 1,
          text: 'Консультации',
          excludeRoles: ['polis.operator', 'polis.operator.dop'],
        },
        {
          index: 2,
          text: 'Страховые полисы',
        },
        {
          index: 3,
          text: 'Обращения',
          excludeRoles: ['polis.operator', 'polis.operator.dop'],
        },
        // {
        //   index: 4,
        //   text: 'Лимиты',
        // },
      ],
      patient: null,
      activeTab: 0,
    };
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter((role) => !this.checkRoleAccess(role.excludeRoles || []));
    },
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    Bus.$off('patient:update', this.getPatient);
    Bus.$off('set-patient-load', this.setLoad);
    this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, null);
  },
  methods: {
    async init() {
      this.isLoading = true;
      try {
        if (this.$store.state.Patients.activePolicyId) {
          this.activeTab = 2;
        }

        await this.getPatient({ manageLoading: false });
        await this.getInsuranceCompanies();
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }

      Bus.$on('patient:update', this.getPatient);
      Bus.$on('set-patient-load', this.setLoad);
    },
    async getPatient({ manageLoading } = { manageLoading: true }) {
      try {
        if (manageLoading) this.isLoading = true;
        const patient = await this.$store.dispatch(this.$types.PATIENT_FETCH, this.patientId);
        const deviceId = await this.$store.dispatch(this.$types.GET_USER_DEVICE_ID, this.patientId);
        this.patient = { ...patient, deviceId };
        this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, patient.id);
      } finally {
        if (manageLoading) this.isLoading = false;
      }
    },
    updatePatient() {
      this.init();
    },
    async getInsuranceCompanies() {
      const insuranceCompanies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
      this.$store.commit(this.$types.INSURANCE_COMPANIES_SET, insuranceCompanies);
    },
    setLoad(value) {
      this.isLoading = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 1px;
}

.crm-tab-row {
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  overflow: hidden;
}

::v-deep.crm-tab-item {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  min-height: 60px;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;
  background-color: #fff;

  &.active {
    background: #6E88F3;
    color: #ffffff;
  }
}
::v-deep.crm-tab-item + .crm-tab-item {
  border-left: 1px solid #E0E0E0;
}
.crm-tab-detail {
  width: 100%;
  margin-top: 10px;
  height: calc(100vh - 250px);
}
</style>
