<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="roleId ? 'Роль' : 'Новая роль'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название роли"
        :invalid-feedback="'Название не может быть пустым'"
        :state="role.title !== null && role.title !== ''"
      >
        <b-form-input
          v-model="role.title"
          type="text"
          placeholder="Введите название"
          trim
          :disabled="!!roleId"
        />
      </b-form-group>

      <!-- <b-form-group
        label="Страницы"
        description="Для выбора нескольких страниц, нажмите и удерживайте клавишу Ctrl и щелкайте левой кнопкой мыши по нужным страницам"
      >
        <b-form-select
          v-model="role.pageIds"
          :options="pageList"
          value-field="id"
          text-field="name"
          multiple
        >
          <b-form-select-option
            :value="[]"
            disabled
          >
            Выберите одну или несколько страниц
          </b-form-select-option>
        </b-form-select>
      </b-form-group> -->

      <b-form-group class="mt-4">
        <template #label>
          <div class="d-flex">
            <span>
              Страницы
            </span>

            <b-button
              class="ml-auto"
              variant="primary"
              size="sm"
              style="width: 200px"
              @click="toggleAllPages"
            >
              {{ allPageSelected ? 'Убрать все страницы' : 'Выбрать все страницы' }}
            </b-button>
          </div>
        </template>

        <b-form-checkbox-group
          v-model="role.pageIds"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <b-form-checkbox
              v-for="page in pageList"
              :key="page.id"
              :value="page.id"
              class="mr-0 w-100"
              :class="{
                'font-weight-bold': !page.pageId,
                'mt-2': !page.pageId,
                'ml-4': page.pageId
              }"
            >
              {{ page.name }}
            </b-form-checkbox>
          </div>
        </b-form-checkbox-group>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :disabled="isLoading || !(role.name !== null && role.name !== '')"
        @click="onSave"
      >
        Сохранить роль
      </b-button>

      <b-button
        variant="primary"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'RoleEditModal',
  components: {
    Preloader,
  },
  props: {
    roleId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      role: {
        name: null,
        pageIds: [],
      },
      pageList: [],
    };
  },
  computed: {
    allPageSelected() {
      return this.role.pageIds.length === this.pageList.length;
    },
  },
  async mounted() {
    try {
      this.isLoading = true;

      this.pageList = (await this.$store.dispatch(this.$types.PAGES_FETCH)).sort((a, b) => a.index - b.index);

      if (this.roleId) {
        const role = this.$store.state.Roles.roles.find((r) => r.id === this.roleId);
        this.role = { ...role };
      }
    } catch (error) {
      console.log(error);
      // this.onClose();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    toggleAllPages() {
      this.role.pageIds = this.allPageSelected ? [] : this.pageList.map((p) => p.id);
    },
    onClose() {
      this.isOpen = false;
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      try {
        this.isLoading = true;
        const method = this.roleId ? 'ROLE_UPDATE' : 'ROLE_CREATE';
        await this.$store.dispatch(this.$types[method], this.role);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });

        await this.$store.dispatch(this.$types.USER_FETCH);
        this.$store.commit(this.$types.ROLES_LOADING_SET, true);
        const roles = await this.$store.dispatch(this.$types.ROLES_FETCH);
        this.$store.commit(this.$types.ROLES_SET, roles);
        this.$store.commit(this.$types.ROLES_LOADING_SET, false);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
