<template>
  <div
    class="patient-info border rounded p-2 d-flex"
    :class="[active ? 'border-primary': '']"
  >
    <div
      v-if="patient.isVip || patient.isTop || patient.isGosuslugi || patient.isMango"
      class="patient-statuses"
    >
      <icon-vip v-if="patient.isVip" />

      <icon-top v-if="patient.isTop" />

      <icon-gosuslugi v-if="patient.isGosuslugi" />

      <icon-mango v-if="patient.isMango" />
    </div>

    <div class="ml-3">
      <div class="font-weight-bold">
        {{ patient.lastName }}
        {{ patient.firstName }}
        {{ patient.middleName }}
      </div>
      <div class="small">
        <div>
          {{ patient.phoneNumber || '-' }}
        </div>
        <div>
          {{ patient.isDMS ? 'ДМС' : '' }}
        </div>
        <div>
          {{ patient.isDMS && patient.isOMS ? ', ' : '' }}
        </div>
        <div>
          {{ patient.isOMS ? 'ОМС' : '' }}
        </div>
        <div>
          {{ parseDate(patient.birthDate) || '-' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import IconMango from 'assets/images/icon_mango.svg';
import IconGosuslugi from 'assets/images/icon_gosuslugi.svg';
import IconVip from 'assets/images/icon_vip.svg';
import IconTop from 'assets/images/icon_top.svg';

export default {
  name: 'PatientInfo',
  components: {
    IconMango,
    IconGosuslugi,
    IconVip,
    IconTop,
  },

  props: {
    patient: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    parseDate(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-info {
  min-width: 250px;
  cursor: pointer;
}
</style>
