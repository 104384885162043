<template>
  <div class="d-flex bg-white align-items-start justify-content-between p-2 rounded border border-info">
    <div>
      <slot />
    </div>

    <b-button
      v-if="littleBtn"
      v-b-tooltip.hover
      variant="danger"
      size="sm"
      class="border border-danger ml-3 p-0"
      title="Удалить"
      @click="$emit('remove')"
    >
      <b-icon icon="x" />
    </b-button>

    <b-button
      v-else
      size="sm"
      class="ml-3"
      @click="$emit('remove')"
    >
      Удалить
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'SelectedItem',

  props: {
    littleBtn: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
