<template>
  <div class="crm-modals">
    <template
      v-for="({ modalName, Component, props }) in modals"
    >
      <component
        :is="Component"
        :key="modalName"
        v-bind="{ modalName, ...props }"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: 'Modals',
  computed: {
    modals() {
      return this.$store.state.Modals.openedModals;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
