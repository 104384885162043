<template>
  <div
    class="knowledge-content"
  >
    <custom-scrollbar>
      <div class="knowledge-content-inner">
        <div class="crm-container">
          <div class="crm-title">
            База знаний
          </div>
          <div class="knowledge-list-wrapper">
            <div class="knowledge-list">
              <div
                v-for="item in knowledgeItems"
                :key="item.name[0]"
                class="knowledge-item"
              >
                <div class="knowledge-item-label">
                  <div
                    v-for="name in item.name"
                    :key="name"
                    class="knowledge-item-name"
                  >
                    <router-link :to="`/knowledge-base/${item.link}`">
                      <span @click="sectionHandler(item.type)">
                        {{ name }}
                      </span>
                    </router-link>
                  </div>
                </div>
                <component
                  :is="item.svgComponent"
                  class="knowledge-item-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { KNOWLEDGE_BASE_SECTIONS } from '@/helpers/consts';
import IconBook from 'assets/images/knowledge-base-book.svg';
import IconContactBook from 'assets/images/knowledge-base-contact-book.svg';
import IconCommunity from 'assets/images/knowledge-base-community.svg';
import IconGosUslugi from 'assets/images/knowledge-base-gosuslugi.svg';
import IconInstructions from 'assets/images/knowledge-base-instructions.svg';
import IconTelemed from 'assets/images/knowledge-base-telemed.svg';
import IconChecklist from 'assets/images/knowledge-base-checklist.svg';

export default {
  name: 'KnowledgeBase',
  components: {
    IconGosUslugi,
    IconBook,
    IconChecklist,
    IconInstructions,
    IconContactBook,
    IconTelemed,
    IconCommunity,
  },
  data() {
    return {
      knowledgeItems: KNOWLEDGE_BASE_SECTIONS,
    };
  },
  methods: {
    sectionHandler(type) {
      const sectionInfo = this.knowledgeItems.filter((item) => item.type === type);
      this.$store.dispatch(this.$types.SET_SECTION_TYPE, sectionInfo[0]);
    },
  },
};
</script>

<style lang="scss" scoped>

.knowledge-content {
  height: 100vh;
  transition: height 0.3s linear;
}

.knowledge-content-inner {
  padding: 30px 0px;
}

.crm-container {
  width: 100%;
  flex-direction: column;
}

.crm-title {
  width: 100%;
  margin-bottom: 20px;
  @extend .page-title;
}

.knowledge-list-wrapper {
  background-color: #FFFFFF;
  padding: 30px;
}

.knowledge-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.knowledge-item {
  display: flex;
  justify-content: space-between;
  background-color: #DDE3FC;
  border-radius: 4px;
  padding: 40px;
}

.knowledge-item-label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-item-name {
  & + & {
    margin-top: 12px;
  }

  a {
    color: #4B62C1;
    text-decoration: none;
  }
}

.knowledge-item-icon {
  flex-shrink: 0;
  align-self: flex-end;
}
</style>
