<template>
    <d-remote-monitoring-indicator-trends @on-create-link="onCreateHandler" @on-select-template="onSelect" />
</template>
<script>

export default {
  name: 'RemoteMonitoringIndicatorTrends',
  methods: {
    onCreateHandler() {
      this.$router.push('/remote-monitoring/indicator-trends/create');
    },
    onSelect(id) {
      this.$router.push(`/remote-monitoring/indicator-trends/view/${id}`);
    },
  },
};
</script>
