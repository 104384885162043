<template>
  <div>
    <custom-scrollbar>
      <div
        class="crm-container patient-container"
      >
        <div class="description">
          Вы уверены, что хотите восстановить пациента?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отмена
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            :loading="isLoading"
            class="crm-button"
            @click="onClickReestablish"
          >
            Восстановить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PatientReestablishModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickReestablish() {
      this.isLoading = true;

      try {
        await this.$store.dispatch(this.$types.PATIENT_REESTABLISH, this.id);

        Bus.$emit('patients:update');
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
        this.$emit('vuedals:close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
