<template>
  <div class="insurance-company">
    <custom-scrollbar>
      <div class="insurance-company-inner">
        <div class="crm-container">
          <loading v-if="isLoading">
            Загрузка программ
          </loading>

          <template v-else>
            <div class="crm-container-title">
              <div class="crm-title">
                <router-link
                  class="crm-title-link"
                  to="/insurance-companies"
                >
                  <arrow-icon class="arrow-left" />
                </router-link>

                <span>
                  {{ company.title }} / Программы
                </span>
              </div>

              <!-- !checkRoleAccess_(['Operator', 'Tech.operator', 'MedicalCurator', 'Dis.monitoring.operator']) -->
              <router-link
                v-if="checkFeatureAccess({ name: 'Создание подпрограммы', url: '/insurance-companies' })"
                class="crm-button-link"
                :to="`/insurance-companies/${companyId}/subprograms/new`"
              >
                <base-button
                  :type="$const.PRIMARY_BUTTON"
                  class="width-unset"
                >
                  <icon-cross
                    class="crm-icon-cross"
                  />
                  <span> Добавить подпрограмму</span>
                </base-button>
              </router-link>
            </div>

            <!-- !checkRoleAccess_(['Operator'])  -->
            <div
              v-if="checkFeatureAccess({ name: 'Возможность видеть скрытые подпрограммы', url: '/insurance-companies' })"
              class="w-100 mt-2 mb-4"
            >
              <b-button
                :variant="getHidden ? 'primary' : 'outline-primary'"
                size="sm"
                :disabled="isLoading"
                @click="getHidden = !getHidden"
              >
                {{ getHidden ? 'Показать не скрытые' : 'Показать скрытые' }}
              </b-button>
            </div>

            <div class="crm-container-program">
              <div
                v-if="!programs.length"
                class="not-found"
              >
                Программы не найдены
              </div>
              <div class="program-list">
                <insurance-program
                  v-for="program in programs"
                  :key="program.program.id"
                  :program="program"
                  class="program-list-item"
                  @deactivationHandler="deactivationHandler"
                  @reload="loadingData"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Loading from '@/components/Loading';
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';
import InsuranceProgram from '@/components/InsurancePrograms/InsuranceProgram';

import { BaseButton } from '@/components/base';
import IconCross from 'assets/images/cross.svg';
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';

export default {
  name: 'InsuranceProgramPage',
  page: {
    title: 'CRM Doctis - Страховая программа',
  },
  components: {
    Loading,
    InsuranceProgram,
    BaseButton,
    IconCross,
    ArrowIcon,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isLoading: false,
      programs: [],
      company: {},
      getHidden: false,
    };
  },
  computed: {
    companyId() {
      return this.$route.params.company;
    },
  },

  watch: {
    async getHidden() {
      await this.loadingData();
    },
  },
  created() {
    this.loadingData();
  },
  methods: {
    async loadingData() {
      this.isLoading = true;
      const { company } = await this.$store.dispatch(this.$types.COMPANY_FETCH, this.companyId);
      this.company = company;
      this.programs = await this.$store.dispatch(this.$types.PROGRAMS_FETCH, {
        idCompany: this.companyId,
        getHidden: this.getHidden,
      });
      this.isLoading = false;
    },
    deactivationHandler(program) {
      Bus.$emit('open-modal:insurance-program-deactivation', {
        program,
        deactivationCallback: this.deactivationCallback,
      });
    },
    deactivationCallback() {
      this.loadingData();
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.insurance-company-inner {
  padding: 30px 0;
}

.crm-container {
  flex-direction: column;
}
.crm-container-program {
  width: 100%;
}
.crm-container-title {
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}
.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

::v-deep.crm-title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.program-list {
  margin: 0 -10px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-items: stretch;
}
.program-list-item {
  margin: 10px;
  width: calc(50% - 20px);
}

::v-deep.crm-button.width-unset {
  width: unset;
  padding-left: 15px;
  padding-right: 15px;
  flex-shrink: 0;
}
.crm-button-link {
  text-decoration: none;

  .crm-icon-cross {
    margin-right: 15px;
  }
}

.not-found {
  text-align: center;
}
</style>
