<template>
  <div>
    <VTabs
      v-model="localSelectedTab"
      :options="computedTabs"
      size="m"
      class="mb-4 nps-v-tabs"
      variant="white-shadow"
    />
  </div>
</template>

<script>
import { VTabs } from '@doctis.front/doctis.designsystem';

export default {
  name: 'TabsNavigation',
  components: {
    VTabs,
  },
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    badgeNumber: {
      type: Number,
      default: null,
    },
    selectedTab: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      localSelectedTab: this.selectedTab || this.computedTabs[0] || null,
    };
  },
  computed: {
    computedTabs() {
      return this.tabs.length > 0
        ? this.tabs
        : [
          { label: 'Опросы', value: '/statistics/nps/surveys' },
          {
            label: 'Варианты ответов',
            value: '/statistics/nps/options',
            ...(this.badgeNumber ? { badge: +this.badgeNumber } : {}),
          },
        ];
    },
  },
  watch: {
    localSelectedTab(newTab) {
      this.$emit('update:selectedTab', newTab);
      if (newTab && this.$route.path !== newTab.value) {
        this.$router.push(newTab.value);
      }
    },
    selectedTab(newTab) {
      this.localSelectedTab = newTab;
    },
  },
};
</script>

<style lang="scss">
  .nps-v-tabs {
    .flex.justify-center.items-center {
      .w-min {
        background-color: #161E2F1A;
        color: #fff;
      }
    }
  }
</style>
