/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import store from '@/store';
import { LOGOUT } from '@/store/types';

import { showMessage, headers } from '@/helpers/utils';
import { APP_HOST } from '@/app.config';

export const api = axios.create({
  baseURL: APP_HOST,
  mode: 'cors',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    ...headers(),
  },
  withCredentials: true,
  credentials: 'include',
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response = {} } = error;
    const { status = {}, data = {} } = response;

    const { errors = {} } = data;

    const { message } = response.data;

    /* errorId и requestId ошибки */
    if (data.data?.errorId || data.data?.requestId) {
      const errorId = data.data?.errorId;
      const requestId = data.data?.requestId;

      let text = '';
      if (errorId) {
        text += `ErrorID - ${errorId} <br/>`;
      }

      if (requestId) {
        text += `RequestID - ${requestId}`;
      }

      showMessage({ type: 'error', title: 'Данные о ошибке', message: text });
    }

    if (status === 500 && message) {
      showMessage({ type: 'error', title: 'Ошибка при выполнении запроса', message });
      return Promise.reject(error);
    }

    if (status === 401) {
      showMessage({ type: 'error', title: 'Ошибка соединения', message: 'Ваша сессия устарела' });
      store.dispatch(LOGOUT);
      return Promise.reject(error);
    }

    /* Нет ответа от сервера */
    if (!Object.keys(data).length || status === 500) {
      showMessage({ type: 'error', title: 'Ошибка соединения', message: 'Нет ответа от сервера' });

      return Promise.reject(error);
    }

    if (typeof data === 'string') {
      showMessage({ type: 'error', title: 'Ошибка', message: data });
    } else if (data.title) {
      showMessage({ type: 'error', title: 'Ошибка', message: data.title });
    } else if (data.Message) {
      showMessage({ type: 'error', title: 'Ошибка', message: data.Message });
    } else if (Object.entries(errors).length) {
      for (const [title, errorArray] of Object.entries(errors)) {
        const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');

        showMessage({ type: 'error', title, message: errorsString });
      }
    } else {
      for (const [title, errorArray] of Object.entries(data)) {
        if (Array.isArray(errorArray)) {
          const errorsString = errorArray.reduce((acc, errorText) => `${acc}\n${errorText}`, '');
          showMessage({ type: 'error', title, message: errorsString });
        }
      }
    }

    if (status === 403) {
      showMessage({ type: 'error', title: 'Ошибка', message: 'Отсутствуют необходимые права' });
    }

    return Promise.reject(error);
  },
);

export function createApi(token) {
  api.defaults.headers = {
    ...api.defaults.headers,
    Authorization: `Bearer ${token}`,
  };
}
