var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative border-solid border rounded-full transition-colors cursor-pointer w-6 h-6",class:[
    _vm.disabled
      ? 'pointer-events-none !border-transparent !bg-v-ui-root-monochrome-10'
      : '',
    _vm.isChecked
      ? 'border-v-ui-root-main bg-v-ui-root-main'
      : 'border-v-ui-root-main bg-transparent'
  ]},[_c('label',{staticClass:"absolute w-full h-full flex justify-center items-center cursor-pointer",attrs:{"for":_vm.radioId}},[(_vm.isChecked)?_c('span',{staticClass:"absolute w-2 h-2 rounded-full m-auto inset-0 transition-colors",class:[
        _vm.disabled ? '!bg-v-ui-icon-monochrome-20' : '',
        _vm.isChecked ? 'bg-v-ui-background-monochrome-0' : ''
      ]}):_vm._e()]),_c('input',{attrs:{"id":_vm.radioId,"name":_vm.name,"hidden":"","type":"radio"},domProps:{"value":_vm.componentValue,"checked":_vm.isChecked},on:{"change":_vm.onSelect}})])
}
var staticRenderFns = []

export { render, staticRenderFns }