import MedicalOrganizationApi from './medical-organization.api';

class MedicalOrganizationService {
  constructor() {
    this.api = new MedicalOrganizationApi();
  }

  /**
   *  Метод для получения списка медицицинских организаций из справочника НСИ
   * «Реестр медицинских организаций Российской Федерации» (OID 1.2.643.5.1.13.13.11.1461)
   * @returns
   */
  getNSIList(params) {
    return this.api.getNSIList(params);
  }
}

const medicalOrganizationService = new MedicalOrganizationService();
export default medicalOrganizationService;
