/* eslint-disable vue/no-v-html */
<template>
  <div id="app">
    <loading v-if="isUserLoading">
      Загрузка данных пользователя
    </loading>
    <div
      v-else
      class="h-100"
    >
      <div class="d-flex h-100">
        <Navbar v-if="isLogged" />
        <router-view :key="$route.path" />
      </div>
      <TabPanel />
    </div>
    <vuedal />
    <modals />

    <notifications
      :max="5"
      :width="'50%'"
      group="global-notifications"
      position="top center"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <div
          :class="{
            [props.item.type]: true
          }"
          class="vue-notify"
        >
          <div
            v-if="props.item.title"
            class="vue-notify__title"
          >
            {{ props.item.title }}
          </div>

          <div
            v-if="props.item.text"
            class="vue-notify__content"
            v-html="props.item.text"
          />

          <span
            class="vue-notify__close-link"
            @click="props.close"
          >
            &times;
          </span>
        </div>
      </template>
    </notifications>

    <Call v-if="!isUserLoading && isLogged" />

    <common-modal-container />
  </div>
</template>

<script>
import { mixinModals } from '@/mixins/mixinModals';
import { domain } from '@/helpers/utils';

import Navbar from '@/components/Navbar';
import Call from '@/components/Call';
import TabPanel from '@/components/TabPanel';

import Loading from '@/components/Loading';
import Modals from '@/components/Modals';
import { Component as vuedal } from 'vuedals';
import CommonModalContainer from '@/components/common/CommonModalContainer.vue';

export default {
  name: 'App',
  components: {
    CommonModalContainer,
    Loading,
    vuedal,
    Modals,
    Navbar,
    TabPanel,
    Call,
  },
  mixins: [mixinModals],
  computed: {
    isUserLoading() {
      return this.$store.state.Auth.isUserLoading;
    },
    isLogged() {
      return this.$store.state.Auth.token;
    },
  },
  mounted() {
    this.setFavicon();
  },
  methods: {
    setFavicon() {
      const favicon = document.getElementById('favicon');
      const faviconUrl = `/${domain.favicon}.ico`;
      favicon.href = faviconUrl;
    },
  },
};
</script>

<style lang="scss">
* {
  font-family: 'Montserrat';
}
#app {
  width: 100vw;
  min-width: 740px;
  height: 100vh;
  background-color: #f3f3f3;
  color: $black-light;
  font-size: 14px;
  line-height: 17px;
}
</style>
