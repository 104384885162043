<template>
  <div
    class="company"
    :class="{'company_hidden': companyInfo.isHidden}"
  >
    <div class="company-detail">
      <router-link
        class="company-image"
        :to="`/insurance-companies/${companyInfo.id}/programs`"
      >
        <img :src="img">
      </router-link>

      <div class="company-info">
        <div class="company-name">
          {{ companyInfo.title }}
        </div>

        <div class="company-phone">
          {{ companyInfo.phoneNumber }}
        </div>

        <div class="company-control">
          <!-- <icon-letter class="company-control crm-icon" /> -->

          <!-- v-if="!checkRoleAccess_(['Operator', 'Tech.operator', 'MedicalCurator', 'Dis.monitoring.operator'])" -->
          <router-link
            v-if="checkFeatureAccess({ name: 'Редактирование компании', url: '/insurance-companies' })"
            class="company-control"
            :to="`/insurance-companies/${companyInfo.id}/edit`"
          >
            <icon-edit class="crm-icon" />
          </router-link>
        </div>
      </div>
    </div>
    <div class="company-subprograms">
      <div class="company-subprograms-title">
        Подпрограммы
      </div>

      <div class="company-subprograms-list">
        <template v-if="sortedSubprogram.length">
          <router-link
            v-for="subprogram in isHideSubprograms ? sortedSubprogram.slice(0, 3): sortedSubprogram"
            :key="subprogram.id"
            :class="['company-subprogram', { 'company-subprogram_deactivated' : !subprogram.isActive }]"
            :to="`/insurance-companies/${companyInfo.id}/programs/${subprogram.programId}/subprograms/${subprogram.id}`"
          >
            {{ subprogram.name }}
          </router-link>
        </template>

        <p
          v-else
          class="font-weight-bold"
        >
          Подпрограмм нет
        </p>

        <!-- v-if="!checkRoleAccess_(['Operator'])" -->
        <b-button
          v-if="checkFeatureAccess({ name: 'Возможность видеть скрытые подпрограммы', url: '/insurance-companies' })"
          size="sm"
          variant="info"
          class="text-nowrap px-1 my-1"
          :disabled="searchHiddens"
          @click="getSubprograms"
        >
          <b-icon
            v-if="showHiddens"
            icon="eye-fill"
          />

          <b-icon
            v-else
            icon="eye-slash-fill"
          />
          {{ showHiddens ? 'Показать не скрытые' : 'Показать скрытые' }}
        </b-button>

        <!-- v-if="!isHideSubprograms && !checkRoleAccess_(['Operator', 'Tech.operator', 'MedicalCurator', 'Dis.monitoring.operator'])" -->
        <b-button
          v-if="!isHideSubprograms && checkFeatureAccess({ name: 'Создание подпрограммы', url: '/insurance-companies' })"
          size="sm"
          variant="primary"
          class="text-nowrap px-1 my-1"
          @click="$router.push(`/insurance-companies/${companyInfo.id}/subprograms/new`)"
        >
          <b-icon icon="plus" />
          Добавить подпрограмму
        </b-button>

        <div
          v-if="subprograms.length > 3"
          class="subprogram-button"
          @click="() => isHideSubprograms = !isHideSubprograms"
        >
          <template v-if="isHideSubprograms">
            Показать все
          </template>
          <template v-else>
            Скрыть
          </template>
          <icon-arrow-dropdown
            width="20"
            height="20"
            viewBox="-4 -3 27 27"
            class="crm-icon-arrow-down"
            :class="{ reverse: !isHideSubprograms }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
// import IconLetter from 'assets/images/letter_icon.svg';
import IconEdit from 'assets/images/edit_icon.svg';
import IconArrowDropdown from 'assets/images/arrow-dropdown.svg';
import { concat, partition, sortBy } from 'lodash';
import { APP_HOST } from '@/app.config';
import { api } from '@/helpers/api';

export default {
  name: 'InsuranceCompany',
  components: {
    // IconLetter,
    IconEdit,
    IconArrowDropdown,
  },
  mixins: [mixinRoles],
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isHideSubprograms: true,
      showHiddens: false,
      searchHiddens: false,
      searchedSubprograms: null,
    };
  },
  computed: {
    companyInfo() {
      return this.company.company;
    },

    sortedSubprogram() {
      const groupedArrays = partition(this.subprograms, 'isActive');
      const sortedActive = sortBy(groupedArrays[0], 'name');
      const sortedNotActive = sortBy(groupedArrays[1], 'name');
      return concat(sortedActive, sortedNotActive);
    },

    subprograms() {
      if (this.searchedSubprograms) return this.searchedSubprograms;
      // eslint-disable-next-line max-len
      return this.company.programsWithSubprograms.reduce((acc, program) => [...acc, ...program.subPrograms.map((subprogram) => ({ ...subprogram, programId: program.program.id, id: subprogram.subprogramId }))], []);
    },
    img() {
      if (this.companyInfo.logo !== '00000000-0000-0000-0000-000000000000') {
        // eslint-disable-next-line max-len
        return `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${this.companyInfo.logo}`;
      }

      // eslint-disable-next-line global-require
      return require('assets/images/avatar-insurance-empty.svg?inline');
    },
  },
  created() {
    if (this.subprograms.length <= 3) this.isHideSubprograms = false;
    if (this.company && this.company.company) this.showHiddens = this.company.company.isHidden;
  },
  methods: {
    async getSubprograms() {
      this.searchHiddens = true;

      try {
        const { data: subprograms } = await api.get('v2/insuranceCompanies/getWithCompany', {
          params: {
            companyId: this.company.company.id,
            getHidden: !this.showHiddens,
          },
        });
        this.searchedSubprograms = subprograms;

        this.showHiddens = !this.showHiddens;
      } catch (e) {
        console.error(e);
      } finally {
        this.searchHiddens = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.company {
  background: #fff;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  &_hidden {
    opacity: 0.5;
  }
}

.company-detail {
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-shrink: 1;
  min-width: 206px;
}

.company-image {
  height: 80px;
  width: 80px;
  margin-right: 15px;
  flex-shrink: 0;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.company-name {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  width: 190px;
  overflow-wrap: break-word;
}

.company-control {
  margin-top: 15px;
}

.company-control {
  cursor: pointer;

  & + & {
    margin-left: 15px;
  }
}

.company-phone {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  white-space: normal;
}

.company-subprograms {
  box-sizing: border-box;
  flex: 1;
  flex-shrink: 2;
  border-left: 1px solid #E0E0E0;
  padding-left: 10px;
  margin-left: 40px;

  @media screen and (max-width: 991px) {
    border-left: unset;
    padding-left: 0;
    margin-left: 0;
    border-top: 1px solid #E0E0E0;
    padding-top: 10px;
    margin-top: 10px;
  }
}

.company-subprograms-title {
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 5px;
}

::v-deep.company-subprogram {
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6E88F3;
  text-decoration: none;
  display: block;

  &_deactivated {
    color: #C7C7C7;
  }
}

::v-deep.company-subprogram + .company-subprogram {
  margin-top: 10px;
}

.subprogram-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #4B62C1;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-top: 10px;

  .crm-icon-cross {
    flex-shrink: 0;
    path {
      fill: #4B62C1;
    }
    margin-right: 10px;
  }

  .crm-icon-arrow-down {
    flex-shrink: 0;
    margin-left: 5px;
    path {
      fill: #4B62C1;
    }

    &.reverse{
      transform: rotate(180deg);
    }
  }
}
</style>
