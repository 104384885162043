export const APP_HOST = process.env.VUE_APP_HOST;
export const APP_DOCTIS = process.env.VUE_APP_DOCTIS;
export const APP_DOCTIS_API_KEY = process.env.VUE_APP_DOCTIS_API_KEY;
export const APP_DOCTIS_FILE_SERVER_URL = process.env.VUE_APP_DOCTIS_FILE_SERVER_URL;
export const APP_PATIENT_URL = process.env.VUE_APP_PATIENT_URL;
export const APP_DOCTOR_URL = process.env.VUE_APP_DOCTOR_URL;
export const APP_ANALYSIS_API_URL = process.env.VUE_APP_ANALYSIS_API_URL;
export const APP_ANALYSIS_API_TOKEN = process.env.VUE_APP_ANALYSIS_API_TOKEN;
export const APP_SETTINGS = process.env.VUE_APP_SETTINGS;
export const APP_SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;

export default {
  APP_HOST,
  APP_DOCTIS,
  APP_DOCTIS_API_KEY,
  APP_PATIENT_URL,
  APP_DOCTOR_URL,
  APP_ANALYSIS_API_URL,
  APP_ANALYSIS_API_TOKEN,
  APP_SETTINGS,
  APP_SENTRY_DSN,
};
