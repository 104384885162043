<template>
  <loading v-if="isLoading">
    Загрузка данных
  </loading>
  <div
    v-else
    class="appeals-export"
  >
    <div class="crm-form-row">
      <div class="crm-form-field">
        <div class="crm-form-block">
          <div class="crm-label">
            Дата создания
          </div>
          <div class="crm-form-field-wrapper">
            <base-date-picker
              v-model="exportData.dateFrom"
              placeholder=""
              :max-date="new Date()"
              class="crm-form-date-picker"
              @keyup.native.enter="$emit('search')"
            />
            <div class="crm-form-divider" />
            <base-date-picker
              v-model="exportData.dateTo"
              placeholder=""
              :max-date="new Date()"
              class="crm-form-date-picker"
              @keyup.native.enter="$emit('search')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="crm-form-row">
      <div class="crm-form-field">
        <div class="crm-form-block">
          <p class="crm-label">
            Статус обращения
          </p>
          <v-select
            v-model="exportData.status"
            :reduce="status => status.index"
            :options="statuses"
            label="title"
            placeholder="Выберите статус"
          />
        </div>
      </div>
    </div>
    <div class="crm-form-row">
      <div class="crm-form-field">
        <div class="crm-form-block">
          <p class="crm-label">
            Ответственный
          </p>
          <v-select
            v-model="exportData.responsibleId"
            :reduce="responsible => responsible.id"
            :options="responsibles"
            label="displayName"
            placeholder="Выберите ответственного"
          />
        </div>
      </div>
    </div>
    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.PRIMARY_BUTTON"
        class="crm-button"
        :loading="isSaving"
        @click="onExport"
      >
        Экспортировать
      </base-button>
    </div>
  </div>
</template>

<script>
import {
  formatISO,
} from 'date-fns';

import {
  BaseButton,
  BaseDatePicker,
} from '@/components/base';
import Loading from '@/components/Loading';

import { APPEAL_STATUSES } from '@/helpers/consts';

export default {
  name: 'AppealsExportModal',
  components: {
    BaseButton,
    BaseDatePicker,
    Loading,
  },
  props: {},
  data() {
    return {
      isSaving: false,
      isLoading: false,
      exportData: {
        dateFrom: null,
        dateTo: null,
        status: null,
        responsibleId: null,
      },
      statuses: APPEAL_STATUSES,
      responsibles: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.responsibles = await this.$store.dispatch(this.$types.ACCOUNT_USERS_FETCH, { take: 10000 });
    this.isLoading = false;
  },
  methods: {
    async onExport() {
      try {
        this.isSaving = true;
        const {
          dateFrom,
          dateTo,
          responsibleId,
          status,
        } = this.exportData;

        const requestObject = {
          responsible: responsibleId || undefined,
          status: status ?? undefined,
          dateFrom: dateFrom ? formatISO(dateFrom) : undefined,
          dateTo: dateTo ? formatISO(dateTo.setHours(23, 59, 0, 0)) : undefined,
        };

        await this.$store.dispatch(this.$types.APPEALS_EXPORT, requestObject);
      } catch (error) {
        console.dir(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-form-field-wrapper {
  display: flex;
  align-items: center;

  .crm-form-divider {
    width: 30px;
    height: 1px;
    background-color: #323232;
    margin: 0 10px;
  }
}

.crm-form-row + .crm-form-row {
  margin-top: 20px;
}
</style>
