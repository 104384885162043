import { render, staticRenderFns } from "./IndemnityNewTemplateTable.vue?vue&type=template&id=a3b87d9c&scoped=true"
import script from "./IndemnityNewTemplateTable.vue?vue&type=script&lang=js"
export * from "./IndemnityNewTemplateTable.vue?vue&type=script&lang=js"
import style0 from "./IndemnityNewTemplateTable.vue?vue&type=style&index=0&id=a3b87d9c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3b87d9c",
  null
  
)

export default component.exports