<template>
  <div class="w-100 pt-4">
    <b-container fluid="xl">
      <div class="mb-3">
        <div class="h3 mb-0">
          Расписание
        </div>
      </div>

      <base-async-select
        v-model="doctor"
        :fetch-function="fetchDoctors"
        placeholder="Выберите врача"
        loading-title="Загрузка докторов"
        no-options-title="Доктора не найдены"
        server-paginated
        fluid
        class="bg-white mb-3"
        @change="scheduleFetch"
      />

      <div
        v-if="doctor"
        class="d-flex justify-content-end my-2"
      >
        <b-button
          variant="primary"
          size="sm"
          @click="openScheduleModal"
        >
          Редактировать расписание врача
        </b-button>
      </div>

      <div class="calendar-container">
        <b-col
          v-if="scheduleLoading"
          cols="12"
          class="p-0 rounded my-skeleton"
        >
          <b-skeleton-img
            no-aspect
            :height="calendarHeight"
          />
        </b-col>
        <ScheduleCalendar
          :schedule-events="availableScheduleEvents"
          :height="calendarHeight"
          @onEventClick="onEventClick"
          @viewChange="viewChange"
        />
      </div>
    </b-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { api } from '@/helpers/api';
import * as types from '@/store/types';
import {
  addDays, differenceInMinutes,
  format, fromUnixTime, getUnixTime, set,
} from 'date-fns';

import ScheduleCalendar from '@/components/Schedule/ScheduleCalendar';
import { BaseAsyncSelect } from '@/components/base';

export default {
  name: 'ScheduleNew',
  components: { ScheduleCalendar, BaseAsyncSelect },

  data: () => ({
    doctor: null,
    scheduleLoading: false,
    schedule: null,
    calendarHeight: '77vh',
    view: null,
    consultationStatuses: {
      new: [0],
      work: [4, 1, 3],
      close: [9, 8, 6, 7, 2],
    },
    statuses: {
      0: {
        title: 'Новый',
        value: 'new',
      },
      1: {
        title: 'В работе',
        value: 'work-in-progress',
      },
      2: {
        title: 'Закрытый',
        value: 'closed',
      },
      3: {
        title: 'Консилиум',
        value: 'consilium',
      },
      4: {
        title: 'Ожидание оплаты',
        value: 'waiting-payment',
      },
      6: {
        title: 'Отмена',
        value: 'closed',
      },
      7: {
        title: 'Ожидание оплаты истекло',
        value: 'waiting-payment-timeout',
      },
      8: {
        title: 'Отменено пациентом',
        value: 'closed',
      },
      9: {
        title: 'Отменено доктором',
        value: 'closed',
      },
      11: {
        title: 'Ошибочный статус',
        value: 'closed',
      },
      '-11': {
        title: 'Ошибочный статус',
        value: 'closed',
      },
    },
  }),

  computed: {
    ...mapGetters({
      scheduleTrigger: types.SCHEDULE_TRIGGER,
    }),
    startDate() {
      if (!this.view) return;

      return this.view.startDate;
    },
    endDate() {
      if (!this.view) return;

      return this.view.endDate;
    },
    doctorSpecializations() {
      if (
        this.doctor
        && this.doctor.doctorSpecializations.length
      ) return this.doctor.doctorSpecializations.map((item) => item.specialization?.title);

      return [];
    },
    scheduleEvents() {
      try {
        if (!this.schedule || !this.schedule.slots.length) return;

        return this.schedule.slots.map((item) => {
          const startTime = format(fromUnixTime(item.slot), 'yyyy-MM-dd HH:mm');
          const startTimeDate = new Date(item.slot * 1000);
          const endTimeDate = startTimeDate;
          endTimeDate.setMinutes(startTimeDate.getMinutes() + 15);
          const endTimeFormat = format(endTimeDate, 'yyyy-MM-dd HH:mm');
          const differenceInMinutesTime = differenceInMinutes(startTimeDate, new Date());

          // текст и цвет
          const style = item.isLocked ? 'locked' : 'open';
          let consultationStyle = null;
          if (item.consultation) {
            if (this.consultationStatuses.new.includes(item.consultation.status)) consultationStyle = 'new';
            if (this.consultationStatuses.work.includes(item.consultation.status)) consultationStyle = 'work';
            if (this.consultationStatuses.close.includes(item.consultation.status)) consultationStyle = 'closed';
          }

          let title = item.isLocked ? 'Закрыт' : 'Открыт';
          if (item.consultation) {
            title = item.consultation.statusText || this.statuses[item.consultation.status]?.title;
          }

          if (item.isLiveSlot) {
            consultationStyle = 'live';
            title = 'Дежурный врач';
          }
          let content = null;
          if (item.consultation && item.consultation.patient) {
            const { lastName, firstName, middleName } = item.consultation.patient;
            content = `${lastName} ${firstName} ${middleName}`;
          }

          return {
            ...item,
            title,
            start: startTime,
            end: endTimeFormat,
            class: consultationStyle || style,
            content,
            differenceInMinutesTime, // Разница в минутах
            isAvailableTime: differenceInMinutesTime > 0, // Разница в минутах. Если больше 0 - true, если меньше - дата уже прошла
          };
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    availableScheduleEvents() {
      if (!this.scheduleEvents) return;

      return this.scheduleEvents.filter((item) => item.isAvailableTime);
    },
  },

  watch: {
    async scheduleTrigger() {
      await this.scheduleFetch();
    },
  },

  methods: {
    async fetchDoctors({ query, skip, take }) {
      const doctors = await this.$store.dispatch(this.$types.DOCTORS_FETCH, {
        skip,
        take,
        query,
        save: false,
      });

      return doctors.map((doctor) => ({ ...doctor, value: doctor.id, name: doctor.fullName }));
    },
    async scheduleFetch() {
      if (!this.doctor) {
        this.schedule = null;
        return;
      }
      if (this.scheduleLoading) return;

      this.scheduleLoading = true;
      try {
        const endUnixTime = getUnixTime(set(addDays(this.endDate, 1), { hours: 23, minutes: 59, seconds: 59 }));
        const { data: schedule } = await api.post('v2/schedule/search', {
          offset: 0,
          limit: 10,
          doctorIds: [this.doctor.id],
          startUnixTime: getUnixTime(set(this.startDate, { hours: 0, minutes: 0, seconds: 0 })),
          endUnixTime,
        });

        // eslint-disable-next-line prefer-destructuring
        if (Array.isArray(schedule) && schedule.length) this.schedule = schedule[0];
      } catch (e) {
        console.error(e);
      } finally {
        this.scheduleLoading = false;
      }
    },
    openCreateScheduleModal(time) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleMakeAnAppointmentModal',
        props: {
          time,
          doctorId: this.doctor.id,
        },
      });
      // Bus.$emit('open-modal:schedule-checkup', {
      //   time,
      //   doctorId: this.doctor.id,
      // });
    },
    openСonsultationInfoModal(event) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleInformationModal',
        props: {
          specializations: this.doctorSpecializations,
          consultation: event.consultation,
          cb: this.scheduleFetch,
        },
      });
    },
    onEventClick(event) {
      if (event.isLocked) {
        this.openСonsultationInfoModal(event);
      } else {
        this.openCreateScheduleModal(event.slot);
      }
    },
    openScheduleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
      // Bus.$emit('open-modal:schedule', this.doctor.id);
    },
    viewChange([e, search = false]) {
      this.view = e;

      if (search) this.scheduleFetch();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vuecal__event {
  cursor: pointer;

  &.open {
    background-color: #e5ffe5;
    color: #adadad;
    font-weight: 500;
  }
  &.new {
    background-color: #6d9f6d;
    color: #fff;
    font-weight: 500;
  }
  &.locked {
    background-color: #6d9f6d;
    color: #fff;
    font-weight: 500;
  }
  &.closed {
    background-color: #646464;
    color: #fff;
    font-weight: 500;
  }
  &.work {
    background-color: $marker-schedule-work;
    font-weight: 500;

    &.dragged {
      background-color: $marker-schedule-selected;
    }
  }
  &.live {
    background-color: $blue-dark;
    color: #fff;
    font-weight: 500;
  }
}

::v-deep .vuecal__event-content {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.calendar-container {
  position: relative;
}

.my-skeleton {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
</style>
