<template>
  <div class="document-edit">
    <div class="crm-form-fields">
      <div class="crm-form-block">
        <p class="crm-label required">
          Тип документа
        </p>
        <v-select
          v-model="type"
          :reduce="type => type.id"
          :options="Document.typeArray"
          :clearable="false"
          placeholder="Тип документа"
          label="title"
        />
        <div
          v-if="$v.document.type.$error"
          class="validation-errors"
        >
          <span
            class="validation-error-text"
          >
            {{ errorsValidation.type[0] }}
          </span>
        </div>
      </div>
    </div>

    <div class="crm-form-fields">
      <div class="crm-form-block">
        <p class="crm-label required">
          Подтип документа
        </p>
        <v-select
          v-model="subtype"
          :disabled="!document"
          :reduce="type => type.id"
          :options="subtypeChoices"
          :clearable="false"
          placeholder="Подтип документа"
          label="title"
        />
        <div
          v-if="$v.document.subtype.$error"
          class="validation-errors"
        >
          <span
            class="validation-error-text"
          >
            {{ errorsValidation.subtype[0] }}
          </span>
        </div>
      </div>
    </div>

    <div class="crm-form-fields">
      <div class="crm-form-block">
        <p class="crm-label required">
          Статус документа
        </p>
        <v-select
          v-model="status"
          :disabled="!statusChoices.length"
          :reduce="type => type.id"
          :options="statusChoices"
          :clearable="false"
          placeholder="Статус документа"
          label="title"
        />
        <div
          v-if="$v.document.status.$error"
          class="validation-errors"
        >
          <span
            class="validation-error-text"
          >
            {{ errorsValidation.status[0] }}
          </span>
        </div>
      </div>
    </div>

    <div class="crm-form-fields">
      <base-input
        v-model="$v.document.title.$model"
        :disabled="!document"
        :error="$v.document.title.$error"
        :errors="errorsValidation.title"
        :input-style="{
          padding: '11px 10px',
        }"
        label="Название"
        placeholder="Название документа"
        required
        fluid
      />
    </div>

    <template v-if="document">
      <div
        v-if="file"
        class="crm-form-fields"
      >
        <base-file
          :file="file"
          file-api="File/clinicdocument/"
          edit
          @delete="onClickDeleteFile"
        />
      </div>

      <div
        v-else
        class="crm-form-fields"
      >
        <div class="crm-form-block">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            @click="onClickOpenFileModal"
          >
            Загрузить файл
          </base-button>

          <div
            v-if="$v.document.fileId.$error"
            class="validation-errors"
          >
            <span
              class="validation-error-text"
            >
              {{ errorsValidation.fileId[0] }}
            </span>
          </div>
        </div>
      </div>
    </template>

    <div class="crm-wrapper-buttons crm-wrapper-buttons_clinic-edit">
      <!-- <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickClose"
      >
        Отменить
      </base-button> -->

      <base-button
        :type="$const.PRIMARY_BUTTON"
        :loading="isSaving"
        class="crm-button"
        @click="onClickSave"
      >
        Сохранить
      </base-button>
    </div>
  </div>
</template>

<script>
import { formatISO } from 'date-fns';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { showMessage } from '@/helpers/utils';
import Bus from '@/eventBus';
import { BaseInput, BaseButton, BaseFile } from '@/components/base';
import {
  createDocumentInstance,
  Document,
} from '@/components/Clinics/clinicUtils';

export default {
  name: 'ClinicDocumentEdit',
  components: {
    BaseInput,
    BaseButton,
    BaseFile,
  },
  mixins: [validationMixin],
  props: {
    documentProp: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      document: null,
      Document,

      isSaving: false,
    };
  },
  validations() {
    return {
      document: {
        type: {
          required,
        },
        subtype: {
          required,
        },
        status: {
          required: this.statusChoices.length ? required : () => true,
        },
        title: {
          required,
        },
        fileId: {
          required,
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.type = [];
      if (!this.$v.document.type.required) {
        errors.type.push('Необходимо выбрать тип документа');
      }

      errors.subtype = [];
      if (!this.$v.document.subtype.required) {
        errors.subtype.push('Необходимо выбрать подтип документа');
      }

      errors.status = [];
      if (!this.$v.document.status.required) {
        errors.status.push('Необходимо выбрать статус документа');
      }

      errors.title = [];
      if (!this.$v.document.title.required) {
        errors.title.push('Необходимо ввести название документа');
      }

      errors.fileId = [];
      if (!this.$v.document.fileId.required) {
        errors.fileId.push('Необходимо прикрепить файл');
      }

      return errors;
    },
    type: {
      get() {
        return this.document?.type || null;
      },
      set(id) {
        this.document = createDocumentInstance({
          ...this.document,
          clinicId: this.$store.state.ClinicDocuments.clinicId,
          type: id,
          subtype: null,
          status: null,
        });
      },
    },
    subtype: {
      get() {
        return this.document?.subtype || null;
      },
      set(id) {
        this.$v.document.subtype.$model = id;
      },
    },
    status: {
      get() {
        return this.document?.status || null;
      },
      set(id) {
        this.$v.document.status.$model = id;
      },
    },
    subtypeChoices() {
      return this.document ? Object.getPrototypeOf(this.document).constructor.subtypeArray : [];
    },
    statusChoices() {
      return this.document ? Object.getPrototypeOf(this.document).constructor.statuses : [];
    },
    file() {
      return this.document.fileId
        ? {
          guid: this.document.fileId,
          title: this.document.title,
        }
        : null;
    },
  },
  created() {
    if (this.documentProp) {
      this.$v.document.$model = createDocumentInstance(this.documentProp);
    }
  },
  methods: {
    saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;

      this.$v.document.fileId.$model = newFile.guid;
      this.$v.document.title.$model = newFile.name;
    },
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback, multiple: false, fileApi: 'File/clinicdocument/upload' });
    },
    onClickDeleteFile() {
      this.$v.document.fileId.$model = null;
      this.$v.document.title.$model = null;
    },
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      try {
        this.isSaving = true;

        if (this.documentProp) {
          await this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_UPDATE, {
            ...this.document,
            timeCreated: formatISO(new Date()),
          });
        } else {
          await this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_CREATE, {
            ...this.document,
            timeCreated: formatISO(new Date()),
          });
        }

        this.$emit('vuedals:close');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.crm-form-block {
  width: 100%;
  position: relative;
}
</style>
