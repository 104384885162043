import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage /* appendFormData */ } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.FETCH_STORIES](_, params) {
    NProgress.start();

    try {
      const { data: { data } } = await api.get('v3/story/list', {
        params,
      });

      return data;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При получении историй произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_STORY_INFO](_, storyId) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('storyId', storyId);

      const { data } = await api.get('v3/story/info', {
        params,
      });

      return data;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При получении истории произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_STORY_IMAGE](_, fileName) {
    NProgress.start();

    try {
      const params = new URLSearchParams();

      params.append('fileName', fileName);

      const { data } = await api.get('v3/story/file/download', {
        params,
        responseType: 'blob',
      });

      return URL.createObjectURL(data);
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'При получении обложки произошла ошибка',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_FILE_UPLOAD](_, file) {
    try {
      const formData = new FormData();

      formData.append('file', file);
      const { data: { data } } = await api.post('v3/story/file/upload', formData);

      return data.fileName;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось загрузить файл',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_GET_EVENT_DICTIONARY]() {
    try {
      const { data: { data } } = await api.get('v3/dictionary/event');
      return data;
    } catch (e) {
      console.error(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить словарь событий',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_CREATE](ctx, story) {
    NProgress.start();

    try {
      const result = await api.post('v3/story/create', story);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'История успешно создана!',
      });
      return result;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_UPDATE](ctx, story) {
    NProgress.start();

    try {
      const result = await api.post('v3/story/update', story);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'История успешно обновлена!',
      });
      return result;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_DELETE](ctx, storyId) {
    NProgress.start();

    try {
      await api.post('v3/story/delete', { storyId });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'История успешно удалена!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_ACTIVATE](ctx, storyId) {
    NProgress.start();

    try {
      await api.post('v3/story/activate', { storyId });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'История успешно активирована!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.STORY_DEACTIVATE](ctx, storyId) {
    NProgress.start();

    try {
      await api.post('v3/story/deactivate', { storyId });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'История успешно деактивирована!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
