import Vue from 'vue';
import Vuex from 'vuex';

import Analysis from './modules/Analysis';
import Auth from './modules/Auth';
import BannerManagement from './modules/BannerManagement';
import Account from './modules/Account';
import Clinic from './modules/Clinic';
import Consultation from './modules/Consultation';
import Doctor from './modules/Doctor';
import LegalEntities from './modules/LegalEntities';
import Statistics from './modules/Statistics';
import Schedule from './modules/Schedule';
import SchedulePage from './modules/SchedulePage';
import Sms from './modules/Sms';
import Stories from './modules/Stories';
import Promocodes from './modules/Promocodes';
import Patients from './modules/Patients';
import Indemnity from './modules/Indemnity';
import ClinicDocuments from './modules/ClinicDocuments';
import Specializations from './modules/Specializations';
import Insurance from './modules/Insurance';
import InfoMessages from './modules/InfoMessages';
import Appeal from './modules/Appeal';
import TabPanel from './modules/TabPanel';
import KnowledgeBase from './modules/KnowledgeBase';
import Users from './modules/Users';
import Other from './modules/Other';
import Registry from './modules/Registry';
import TaskTracker from './modules/TaskTracker';
import Modals from './modules/Modals';
import PolicyList from './modules/PolicyList';
import Laboratories from './modules/Laboratories';
import Roles from './modules/Roles';
import Pages from './modules/Pages';
import Queues from './modules/Queues';
import ConsultationFeedback from './modules/ConsultationFeedback';
import UIStore from './modules/ui';
import Nps from './modules/Nps';

import mutations from './mutations';
import getters from './getters';
import actions from './actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters,
  mutations,
  actions,
  modules: {
    Analysis,
    Auth,
    BannerManagement,
    Account,
    Clinic,
    ConsultationFeedback,
    Consultation,
    Doctor,
    LegalEntities,
    Statistics,
    Schedule,
    Sms,
    Stories,
    Promocodes,
    SchedulePage,
    Patients,
    Indemnity,
    ClinicDocuments,
    Specializations,
    Insurance,
    InfoMessages,
    Appeal,
    TabPanel,
    KnowledgeBase,
    Other,
    Registry,
    Users,
    TaskTracker,
    Modals,
    PolicyList,
    Laboratories,
    Roles,
    Pages,
    Queues,
    UIStore,
    Nps,
  },
});
