<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    :title="`Клиники ответственного лица: ${responsible.displayName}`"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="d-flex mb-3">
        <b-form-select
          v-model="selectedClinic"
          value-field="id"
          text-field="name"
          @change="onClinicSelect"
        >
          <b-form-select-option
            :value="null"
          >
            Выберите клинику
          </b-form-select-option>
          <b-form-select-option
            v-for="clinic in clinicList"
            :key="clinic.id"
            :value="clinic.id"
          >
            {{ clinic.name + ', ' + clinic.clinicAddress }}
          </b-form-select-option>
        </b-form-select>

        <b-button
          variant="danger"
          class="ml-2 text-nowrap"
          size="sm"
          :disabled="clinics.length === 0"
          @click="removeAllClinics"
        >
          Удалить все клиники
        </b-button>
      </div>

      <div v-if="clinics.length === 0">
        Клиники не найдены
      </div>
      <b-table
        v-else
        :fields="fields"
        :items="clinics"
        bordered
      >
        <template #cell(actions)="row">
          <div class="d-flex">
            <b-button
              variant="danger"
              class="ml-auto"
              size="sm"
              @click="removeClinic(row.item.id)"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </template>
      </b-table>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        :disabled="!isSaveAvailable"
        @click="onSave"
      >
        Сохранить
      </b-button>

      <b-button
        variant="primary"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'ClinicResponsibleClinicsModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    responsible: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isEdited: false,
      clinics: [],
      selectedClinic: null,
      fields: [
        { key: 'name', label: 'Название' },
        { key: 'clinicAddress', label: 'Адрес' },
        { key: 'actions', label: '' },
      ],
    };
  },
  computed: {
    clinicList() {
      return this.$store.state.Clinic.clinicListNames.filter((c) => c.name);
    },
    isSaveAvailable() {
      const isEmptyArrayNotChanged = this.responsible.responsibilityClinics.length === 0 && this.clinics.length === 0;
      if (isEmptyArrayNotChanged) return false;

      return this.isEdited;
    },
  },
  created() {
    this.clinics = [...this.responsible.responsibilityClinics];
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      // this.$store.dispatch(this.$types.GET_USERS);
    },
    onClinicSelect(clinicId) {
      this.isEdited = true;
      this.selectedClinic = null;

      const isClinicSelected = !!this.clinics.find((c) => c.id === clinicId);
      if (isClinicSelected) return;

      const clinic = this.clinicList.find((c) => c.id === clinicId);
      this.clinics.push(clinic);
    },
    removeClinic(clinicId) {
      this.isEdited = true;
      this.clinics = this.clinics.filter((c) => c.id !== clinicId);
    },
    removeAllClinics() {
      this.isEdited = true;
      this.clinics = [];
    },
    async onSave() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.USER_EDIT, {
          ...this.responsible,
          responsibilityClinics: this.clinics.map((c) => c.id),
        });

        this.onClose();

        await this.$store.dispatch(this.$types.GET_USERS);
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: error,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
