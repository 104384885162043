<template>
  <div class="insurance-subprograms">
    <custom-scrollbar>
      <div class="insurance-subprograms-inner">
        <div class="crm-container crm-container-title">
          <div
            class="crm-title-link"
            @click="() => this.$router.go(-1)"
          >
            <arrow-icon class="arrow-left" />
          </div>
          <span class="crm-title">
            Создание / редактирование подпрограммы ОМС
          </span>
        </div>

        <div class="crm-container">
          <div class="crm-form">
            <base-dropdown
              header="Шаг 1"
              class="crm-form-step"
              :open="steps[1].open"
            >
              <div class="crm-form-fields">
                <div class="crm-form-field">
                  <div class="crm-form-column">
                    <div class="crm-form-label-row">
                      <div class="crm-label required">
                        Регион
                      </div>
                    </div>
                    <v-select
                      v-model="$v.subprogram.regionId.$model"
                      :reduce="type => type.id"
                      :options="regions"
                      :clearable="false"
                      placeholder="Выберите регион"
                      label="name"
                      :disabled="steps[1].disabled"
                    />

                    <div
                      v-if="$v.subprogram.regionId.$error"
                      class="validation-errors"
                    >
                      <span
                        class="validation-error-text"
                      >
                        {{ errorsValidation.regionId[0] }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="crm-form-field  align-self-end">
                  <div class="crm-form-row">
                    <base-checkbox
                      v-model="$v.subprogram.showCheckup.$model"
                      label="test"
                      class="crm-form-checkbox"
                      :disabled="steps[1].disabled"
                    >
                      <span class="crm-form-checkbox-text">
                        Показывать окно чекапа
                      </span>
                    </base-checkbox>
                  </div>
                </div>
              </div>
            </base-dropdown>

            <base-dropdown
              header="Шаг 2"
              class="crm-form-step"
              :open="steps[2].open"
            >
              <div class="crm-form-label-row">
                <div class="crm-label required">
                  Специальности врачей по телемедицине
                  <div
                    v-if="$v.subprogram.specializations.$error"
                    class="validation-errors position-static margin-left"
                  >
                    <span
                      class="validation-error-text"
                    >
                      {{ errorsValidation.specializations[0] }}
                    </span>
                  </div>
                </div>

                <base-button
                  :type="$const.PRIMARY_BUTTON"
                  class="select-all-specs"
                  :disabled="steps[1].disabled"
                  @click="toggleAllSpecs"
                >
                  <span>
                    {{ allSpecsChecked ? 'Отменить все' : 'Выбрать все' }}
                  </span>
                </base-button>
              </div>

              <div class="crm-form-fields">
                <div class="crm-form-column">
                  <div class="crm-form-subtitle">
                    Дежурные врачи
                  </div>

                  <div
                    v-for="spec in dutySpecializations"
                    :key="spec.id"
                    class="crm-form-checkbox-row"
                  >
                    <div class="crm-form-checkbox-inner">
                      <base-checkbox
                        v-model="spec.active"
                        class="crm-form-checkbox"
                        :disabled="steps[2].disabled"
                        @change="(value) => toggleSpecialization(value, spec)"
                      >
                        <span class="crm-form-checkbox-text">
                          {{ spec.name }}
                        </span>
                      </base-checkbox>

                      <base-input
                        v-if="spec.active"
                        v-model="subprogram.specializations[spec.id]"
                        class="checkbox-input"
                        type="number"
                        :input-style="{
                          width: '50px',
                          height: '20px',
                          padding: '1px 7px',
                          textAlign: 'center',
                        }"
                        :disable-arrows="true"
                        :disabled="steps[2].disabled"
                        placeholder="∞"
                      />
                    </div>
                  </div>
                </div>

                <div class="crm-form-column flex-3">
                  <div class="crm-form-subtitle">
                    Врачи с узкой специальностью
                  </div>

                  <div class="crm-form-checkbox-list">
                    <div
                      v-for="spec in specializations"
                      :key="spec.id"
                      class="crm-form-checkbox-item"
                    >
                      <div class="crm-form-checkbox-inner">
                        <base-checkbox
                          v-model="spec.active"
                          class="crm-form-checkbox"
                          :disabled="steps[2].disabled"
                          @change="(value) => toggleSpecialization(value, spec)"
                        >
                          <span class="crm-form-checkbox-text">
                            {{ spec.name }}
                          </span>
                        </base-checkbox>

                        <base-input
                          v-if="spec.active"
                          v-model="subprogram.specializations[spec.id]"
                          class="crm-checkbox-input"
                          type="number"
                          :input-style="{
                            width: '50px',
                            height: '20px',
                            padding: '1px 7px',
                            textAlign: 'center',
                          }"
                          :disable-arrows="true"
                          placeholder="∞"
                          :disabled="steps[2].disabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </base-dropdown>

            <base-dropdown
              header="Шаг 3"
              class="crm-form-step"
              :open="steps[3].open"
            >
              <div class="crm-form-fields">
                <div class="crm-form-block">
                  <p class="crm-label required">
                    Возрастные ограничения
                  </p>
                  <div class="crm-form-field">
                    <div class="crm-form-input-prefix crm-bold">
                      с
                    </div>
                    <base-input
                      v-model="$v.subprogram.ageRestrictions.lowLimit.$model"
                      class="crm-form-input"
                      type="number"
                      placeholder=""
                      :input-style="{ ...inputStyleProps, width: '60px'}"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                    />
                    <div class="crm-form-input-postfix crm-bold">
                      лет
                    </div>

                    <div class="divide-line" />

                    <div class="crm-form-input-prefix crm-bold">
                      до
                    </div>
                    <base-input
                      v-model="$v.subprogram.ageRestrictions.highLimit.$model"
                      class="crm-form-input"
                      type="number"
                      placeholder=""
                      :input-style="{ ...inputStyleProps, width: '60px'}"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                    />
                    <div class="crm-form-input-postfix crm-bold">
                      лет
                    </div>
                  </div>
                  <div
                    v-if="$v.subprogram.ageRestrictions.lowLimit.$error || $v.subprogram.ageRestrictions.highLimit.$error"
                    class="validation-errors position-static"
                  >
                    <span
                      class="validation-error-text"
                    >
                      {{ errorsValidation.lowLimit[0] }}
                      <br>
                      {{ errorsValidation.highLimit[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="crm-form-fields">
                <div class="crm-form-column">
                  <p class="crm-label required">
                    Текст для распознавания подпрограммы
                  </p>
                  <!-- <div
                    v-if="!$v.subprogram.recognitions.required"
                    class="validation-errors position-static"
                  >
                    <span
                      class="validation-error-text"
                    >
                      Необходимо добавить текст для распознавания подпрограммы
                    </span>
                  </div> -->

                  <div
                    v-if="subprogram.recognitions.length"
                    class="crm-form-link-list"
                  >
                    <div
                      v-for="(recognition, index) in $v.subprogram.recognitions.$each.$iter"
                      :key="index"
                      class="crm-form-link"
                    >
                      <base-input
                        v-model="subprogram.recognitions[index]"
                        placeholder="Введите текст"
                        :input-style="inputStyleProps"
                        :error="!$v.subprogram.recognitions.$each[index].required"
                        :errors="['Поле не может быть пустым']"
                        :disabled="steps[3].disabled"
                      />

                      <base-button
                        :type="$const.SECONDARY_BUTTON"
                        class="width-unset without-border"
                        :disabled="steps[3].disabled"
                        @click="deleteRecognition(index)"
                      >
                        Удалить
                      </base-button>
                    </div>
                  </div>
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="width-unset without-border add-link"
                    :disabled="steps[3].disabled"
                    @click="addRecognition"
                  >
                    <span>Добавить текст</span>
                  </base-button>
                </div>
              </div>
            </base-dropdown>

            <base-dropdown
              header="Шаг 4"
              class="crm-form-step"
              :open="steps[4].open"
            >
              <div class="crm-form-fields">
                <div class="crm-form-field">
                  <div class="crm-form-block">
                    <p class="crm-label required">
                      Особенности активации
                    </p>
                    <div class="crm-radio-buttons">
                      <base-radio-button
                        v-model="$v.subprogram.activationType.$model"
                        :name="0"
                        class="crm-radio-button"
                        :disabled="steps[4].disabled"
                      >
                        По номеру полиса
                      </base-radio-button>

                      <base-radio-button
                        v-model="$v.subprogram.activationType.$model"
                        :name="1"
                        class="crm-radio-button"
                        :disabled="steps[4].disabled"
                      >
                        По страхователю
                      </base-radio-button>

                      <base-radio-button
                        v-model="$v.subprogram.activationType.$model"
                        :name="2"
                        class="crm-radio-button"
                        :disabled="steps[4].disabled"
                      >
                        Автоматическая активация
                      </base-radio-button>
                    </div>
                    <div
                      v-if="$v.subprogram.activationType.$error"
                      class="validation-errors"
                    >
                      <span
                        class="validation-error-text"
                      >
                        {{ errorsValidation.activationType[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-3">
                <b-form-checkbox
                  v-model="subprogram.isCountConsultationLimit"
                  :disabled="steps[4].disabled"
                >
                  <div class="pt-1">
                    Считать лимит телемедицинских консультаций.
                  </div>
                </b-form-checkbox>
              </div>
            </base-dropdown>

            <base-dropdown
              header="Шаг 5"
              class="crm-form-step"
              :open="steps[5].open"
            >
              <div class="crm-form-fields">
                <div class="crm-form-column">
                  <p class="crm-label">
                    Документы
                  </p>

                  <div class="crm-file-list">
                    <base-file
                      v-for="document in documents"
                      :key="document.id"
                      :file="document"
                      file-api="File/"
                      edit
                      :disabled="steps[5].disabled"
                      @delete="onClickDeleteFile(document)"
                    />
                  </div>

                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="width-unset"
                    :disabled="steps[5].disabled"
                    @click="onClickOpenFileModal"
                  >
                    <icon-cross
                      class="crm-icon-cross"
                    />
                    <span>Добавить</span>
                  </base-button>
                </div>
              </div>

              <div class="crm-form-fields">
                <div class="crm-form-column">
                  <p class="crm-label">
                    Полезные ссылки
                  </p>
                  <div
                    v-if="subprogram.usefulLinks.length"
                    class="crm-form-link-list"
                  >
                    <div
                      v-for="(link, index) in $v.subprogram.usefulLinks.$each.$iter"
                      :key="index"
                      class="crm-form-link"
                    >
                      <base-input
                        v-model="subprogram.usefulLinks[index].link"
                        placeholder="Вставьте ссылку"
                        :input-style="inputStyleProps"
                        :error="!$v.subprogram.usefulLinks.$each[index].link.required"
                        :errors="['Поле не может быть пустым']"
                        :disabled="steps[5].disabled"
                      />

                      <base-input
                        v-model="subprogram.usefulLinks[index].description"
                        placeholder="Вставьте название гиперссылки"
                        :input-style="inputStyleProps"
                        :disabled="steps[5].disabled"
                      />

                      <base-button
                        :type="$const.SECONDARY_BUTTON"
                        class="width-unset without-border"
                        :disabled="steps[5].disabled"
                        @click="deleteLink(index)"
                      >
                        <span>Удалить</span>
                      </base-button>
                    </div>
                  </div>
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="width-unset without-border add-link"
                    :disabled="steps[5].disabled"
                    @click="addLink"
                  >
                    <span>Добавить ссылку</span>
                  </base-button>
                </div>
              </div>
            </base-dropdown>

            <div class="crm-form-fields bottom-btns-container">
              <!-- !checkRoleAccess___(['Tech.operator']) -->
              <div
                v-if="subprogram.isActive && subprogram.id&& checkFeatureAccess({
                  name: 'Возможность деактивировать подпрограмму',
                  url: '/insurance-companies/:company/subprograms/new',
                })"
              >
                <base-button
                  :type="$const.WARNING_SECONDARY_BUTTON"
                  @click="deactivationHandler"
                >
                  Деактивировать
                </base-button>
              </div>
              <div class="btns-wrapper">
                <!-- <base-button
                  :type="$const.WARNING_SECONDARY_BUTTON"
                  class="width-unset deactivate"
                >
                  <span>Деактивировать</span>
                </base-button> -->
                <base-button
                  :type="$const.SECONDARY_BUTTON"
                  class="width-unset cancel"
                  @click="() => this.$router.go(-1)"
                >
                  <span>Отменить</span>
                </base-button>

                <base-button
                  :type="$const.PRIMARY_BUTTON"
                  class="width-unset"
                  :loading="isSaving"
                  @click="onClickSave"
                >
                  <span>Сохранить</span>
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Bus from '@/eventBus';
import { showMessage } from '@/helpers/utils';

import {
  BaseInput,
  BaseButton,
  BaseCheckbox,
  BaseDropdown,
  BaseFile,
  BaseRadioButton,
} from '@/components/base';

import IconCross from 'assets/images/cross.svg';
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';

export default {
  name: 'InsuranceSubprogramCreatePageOMS',
  page: {
    title: 'CRM Doctis - Создание / редактирование подпрограммы ОМС',
  },
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    BaseDropdown,
    BaseFile,
    BaseRadioButton,
    IconCross,
    ArrowIcon,
  },
  mixins: [mixinRoles, validationMixin],
  props: {
    initialData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      regions: [],
      specializations: [],
      dutySpecializations: [],
      inputStyleProps: {
        padding: '11px 15px 12px',
        resize: 'none',
      },
      isSaving: false,
      allSpecsChecked: false,
      documents: [],
      showCheckup: true,
      subprogram: {
        isActive: true,
        activationType: null,
        ageRestrictions: {
          lowLimit: null,
          highLimit: null,
        },
        isCountConsultationLimit: false,
        regionId: null,
        specializations: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
        },
        recognitions: [],
        usefulLinks: [],
      },
      steps: {
        1: {
          open: true,
          disabled: false,
        },
        2: {
          open: false,
          disabled: false,
        },
        3: {
          open: false,
          disabled: false,
        },
        4: {
          open: false,
          disabled: false,
        },
        5: {
          open: false,
          disabled: false,
        },
      },
    };
  },
  validations() {
    return {
      subprogram: {
        activationType: { required },
        ageRestrictions: {
          lowLimit: {
            isValid: (value) => value !== null && value >= 0,
          },
          highLimit: {
            isValid: (value) => value > this.subprogram.ageRestrictions.lowLimit,
          },
        },
        showCheckup: {},
        regionId: { required },
        specializations: {
          required: (value) => Object.keys(value).length > 0,
        },
        usefulLinks: {
          $each: {
            link: { required },
          },
        },
        recognitions: {
          // required,
          $each: {
            required,
          },
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.regionId = [];
      if (!this.$v.subprogram.regionId.required) {
        errors.regionId.push('Выберите один из вариантов');
      }

      errors.specializations = [];
      if (!this.$v.subprogram.specializations.required) {
        errors.specializations.push('Выберите один из вариантов');
      }

      errors.lowLimit = [];
      if (!this.$v.subprogram.ageRestrictions.lowLimit.isValid) {
        errors.lowLimit.push('Нижняя граница должна быть больше или равна нулю');
      }

      errors.highLimit = [];
      if (!this.$v.subprogram.ageRestrictions.highLimit.isValid) {
        errors.highLimit.push('Верхняя граница должна быть больше, чем нижняя граница');
      }

      errors.activationType = [];
      if (!this.$v.subprogram.activationType.required) {
        errors.activationType.push('Выберите один из вариантов');
      }

      return errors;
    },
  },
  async created() {
    if (this.initialData) {
      const specializations = { ...this.initialData.specializations };
      Object.keys(specializations).map((key) => {
        const value = +specializations[key];
        if (value === 999) specializations[key] = null;
        return null;
      });

      this.subprogram = {
        ...this.initialData,
        specializations,
        usefulLinks: this.initialData.usefulLinks || [],
        recognitions: this.initialData.recognitions || [],
      };

      const documentsName = await Promise.all(this.subprogram.documentIds
        .map((docId) => this.$store.dispatch(this.$types.FILENAME_BY_ID_FETCH, docId)));

      this.documents = documentsName.map((title, i) => ({ title, guid: this.subprogram.documentIds[i] }));
    }

    this.regions = await this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS);

    let specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
    specializations = specializations.map((spec) => ({ ...spec, active: this.subprogram.specializations[spec.id] !== undefined }));

    this.dutySpecializations = specializations.filter((spec) => spec.name.includes('Дежурный'));
    this.specializations = specializations.filter((spec) => !spec.name.includes('Дежурный'));
  },
  methods: {
    deactivationCallback() {
      this.$router.go(-1);
    },
    deactivationHandler() {
      Bus.$emit('open-modal:insurance-sub-program-deactivation', {
        subprogram: this.subprogram,
        deactivationCallback: this.deactivationCallback,
      });
    },

    addLink() {
      this.subprogram.usefulLinks.push({
        id: 0,
        subprogramId: 0,
        link: '',
        description: '',
      });
    },
    deleteLink(index) {
      this.subprogram.usefulLinks.splice(index, 1);
    },
    addRecognition() {
      this.subprogram.recognitions.push('');
    },
    deleteRecognition(index) {
      this.subprogram.recognitions.splice(index, 1);
    },
    toggleSpecialization(value, spec) {
      this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };

      if (value) {
        this.subprogram.specializations[spec.id] = null;
        return;
      }

      this.$delete(this.subprogram.specializations, spec.id);
    },
    toggleAllSpecs() {
      const arraySpecsToggle = (array) => array.map((spec) => {
        if (!this.allSpecsChecked && !this.subprogram.specializations[spec.id]) {
          this.subprogram.specializations[spec.id] = null;
        }

        if (this.allSpecsChecked) {
          this.$delete(this.subprogram.specializations, spec.id);
        }

        const newSpec = { ...spec };
        newSpec.active = !this.allSpecsChecked;
        return newSpec;
      });

      this.dutySpecializations = arraySpecsToggle(this.dutySpecializations);
      this.specializations = arraySpecsToggle(this.specializations);

      this.allSpecsChecked = !this.allSpecsChecked;
      this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };
    },
    onClickDeleteFile(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    saveFileCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.guid,
        });
        return null;
      });
    },
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback });
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      this.isSaving = false;

      const documentIds = this.documents.map((doc) => doc.guid);
      const specializations = { ...this.subprogram.specializations };
      Object.keys(specializations).map((key) => {
        const value = +specializations[key];
        if (value <= 0) specializations[key] = 999;
        return null;
      });

      const subprogram = { ...this.subprogram, documentIds, specializations };
      const companyId = +this.$route.params.company;

      try {
        if (this.subprogram.id) {
          await this.$store.dispatch(this.$types.SUBPROGRAM_UPDATE, { subprogram, companyId });
        } else {
          await this.$store.dispatch(this.$types.SUBPROGRAM_CREATE, { subprogram, companyId });
        }

        this.$router.push('/insurance-companies');
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-subprograms {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.insurance-subprograms-inner {
  padding: 30px 0;
}

.crm-container-title {
  margin-bottom: 40px;
  display: flex;

  .arrow-left {
    margin-right: 15px;
  }
}
.crm-title {
  @extend .page-title;
}

.crm-title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.bottom-btns-container {
  margin-top: 30px;
}

::v-deep.base-dropdown-wrapper {
  margin: 0;
  & + .base-dropdown-wrapper {
    margin-top: 30px;
  }
}
::v-deep.crm-icon-cross {
  margin-right: 15px;
}

::v-deep.crm-button {
  &.width-unset {
    width: unset;
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
  }

  &.secondary {
    .crm-icon-cross {
      path {
        fill: $blue;
      }
    }
  }

  &.add-link {
    margin-top: 10px;
  }

  &.select-all-specs {
    margin-left: auto;
    padding: 10px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &.deactivate {
    margin-right: auto;
  }

  &.cancel {
    margin-right: 20px;
    margin-left: 20px;
  }
}

.btns-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.crm-form {
  background-color: #fff;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
}

::v-deep.crm-form-step {
  .crm-dropdown-text {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
}

.crm-form-fields + .crm-form-fields {
  margin-top: 30px;
}

.crm-form-column {
  flex: 1;
  & + & {
    margin-left: 30px;
  }

  &.flex-3 {
    flex: 3
  }
}

::v-deep.crm-form-field {
  &.align-end {
    align-items: flex-end;
  }

  .crm-label {
    margin-bottom: 15px;
  }

  .divide-line {
    align-self: center;
    height: 1px;
    width: 30px;
    background-color: #323232;
    flex-shrink: 0;
    margin: 0 20px;
  }
}

::v-deep.crm-form-input {
  &-prefix {
    align-self: center;
    margin-right: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  &-postfix {
    align-self: center;
    margin-left: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  & + .crm-form-input {
      margin-left: 20px;
  }
}

.crm-radio-buttons {
  position: relative;
  display: flex;

  .crm-radio-button {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }
}

.crm-form-link {
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  align-items: center;

  &-list {
    display: flex;
    flex-wrap: wrap;
  }

  &-delete {
    margin-left: 15px;
    cursor: pointer;
  }

  .crm-wrap-block {
    flex: 1;
    margin-right: 20px;
  }
}

.crm-form-subtitle {
  min-height: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 15px;
}

.crm-form-checkbox {
  span {
    align-items: unset!important;
  }

  .crm-form-checkbox-text {
    margin-top: 2px;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc((100% - 40px)/3) );
    grid-row-gap: 10px;
    grid-column-gap: 20px;
  }

  &-row + &-row {
    margin-top: 10px;
  }

  &-inner {
    display: flex;
    justify-content: space-between;

    .crm-checkbox-input {
      align-self: center;
      margin-left: 20px;
    }
  }
}

.validation-errors{
  &.margin-left{
    margin-left: 20px;
  }
  &.position-static {
    position: static;
  }
}

.crm-form-label-row {
  display: flex;
}

::v-deep.crm-file-list {
  margin: 0 -10px 30px;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .file {
    margin: 10px;
    max-width: calc(25% - 20px);
    width: auto;
    word-break: break-word;
  }
}

.position-static {
  position: static;
}
</style>
