<template>
  <b-modal
    v-model="isOpen"
    centered
    size="xs"
    :title="'Вы действительно хотите восстановить клинику?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="d-flex justify-content-between">
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="recoveryClinicHandler"
      >
        Восстановить
      </b-button>
      <b-button
        variant="danger"
        @click="onClose"
      >
        Отмена
      </b-button>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'ClinicRecoveryModal',
  components: {
  },
  props: {
    clinicId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: true,
    };
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async recoveryClinicHandler() {
      this.isLoading = true;
      await this.$store.dispatch(this.$types.CLINIC_RECOVERY, this.clinicId);
      this.isLoading = false;
      await this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
