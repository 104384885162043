import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.CONSULTATION_REVIEW_LIST_FETCH](
    { commit },
    {
      offSet,
      limit,
      doctorId,
      patientFio,
      consultationId,
      moderationStatus,
    },
  ) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      if (offSet !== undefined) params.append('offSet', offSet);
      if (limit) params.append('limit', limit);
      if (patientFio) params.append('patientFio', patientFio);
      if (doctorId) params.append('doctorId', doctorId);
      if (consultationId) params.append('consultationId', consultationId);
      if (moderationStatus !== null) params.append('moderationStatus', moderationStatus);

      const {
        data,
        headers,
      } = await api.get('v3/consultation/review/list', {
        params,
      });
      const xTotalCount = headers['x-total-count'] || headers.get('x-total-count');

      NProgress.done();
      commit(localTypes.REVIEW_ITEMS_SET, data);

      return {
        data,
        xTotalCount,
      };
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_CONSULTATION_REVIEW_FOR_ID]({ commit }, params) {
    NProgress.start();

    try {
      const { data } = await api.get(`v3/consultation/review/${params.reviewId}`);

      NProgress.done();
      commit(localTypes.CURRENT_REVIEW_SET, data);

      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REASONS_REVIEW_REJECTION_FETCH]() {
    NProgress.start();
    try {
      const { data: { data } } = await api.get('/v3/consultation/review/reject-reasons');
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REVIEW_MODERATION](_, id) {
    NProgress.start();
    try {
      const { data } = await api.post(`v3/consultation/review/moderate/${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отзыв успешно добавлен',
      });
      return data;
    } catch (e) {
      showMessage({ type: 'error', message: 'Не удалось добавить отзыв' });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REVIEW_REJECT](_, { id, body }) {
    NProgress.start();

    try {
      const { data } = await api.post(`v3/consultation/review/reject/${id}`, body);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Отзыв успешно отклонён',
      });
      return data;
    } catch (e) {
      showMessage({ type: 'error', message: 'Не удалось отклонить отзыв' });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
