var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-date-picker',{class:{ ['crm-fluid']: _vm.fluid },attrs:{"min-date":_vm.minDate,"max-date":_vm.maxDate,"value":_vm.value,"popover":{
    visibility: _vm.disabled ? 'hidden' : 'click',
    placement: _vm.placement,
    positionFixed: _vm.positionFixed,
  }},on:{"input":function($event){return _vm.datePickerEvents($event)}},scopedSlots:_vm._u([{key:"default",fn:function({ inputProps, inputEvents, hidePopover }){return [_c('div',{staticClass:"date-picker",attrs:{"disabled":_vm.disabled}},[_c('input',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskDate),expression:"maskDate"}],staticClass:"crm-input",class:{
          'with-clear-button': _vm.showClearButton,
          'error': _vm.localError,
        },attrs:{"maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":function($event){return _vm.handleDateInput(inputProps.value)},"click":function($event){$event.stopPropagation();return hidePopover.apply(null, arguments)}}},'input',inputProps,false),inputEvents)),(_vm.showClearButton)?_c('icon-close',{staticClass:"crm-close-icon",on:{"click":() => {
          hidePopover();
          _vm.$emit('change', null);
        }}}):_vm._e(),_c('div',{class:['crm-icon-wrapper', {
          'error': _vm.localError,
        }]},[_c('icon-calendar')],1),(_vm.localError && _vm.errors.length)?[_c('div',{class:['errors', {'errors-top': _vm.isErrorsTop}]},[_c('span',{staticClass:"crm-error-text"},[_vm._v(" "+_vm._s(_vm.errors[0])+" ")])])]:_vm._e()],2)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }