import { api } from '@/helpers/api';
import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  // Получение списка опросов
  async [localTypes.FETCH_NPS_SURVEYS]({ commit }, params = null) {
    commit(localTypes.SET_NPS_LOADING, true);
    try {
      const { data } = await api.get('v3/survey', { params });
      const response = data?.data;
      if (response && response.result) {
        commit(localTypes.SET_NPS_SURVEYS, response.result);
      }
    } catch (error) {
      console.error('Error fetching NPS surveys:', error);
    } finally {
      commit(localTypes.SET_NPS_LOADING, false);
    }
  },
  // Обновление опроса
  async [localTypes.UPDATE_NPS_SURVEY]({ commit, state }, { id, changes }) {
    try {
      const survey = state.surveysList.find((item) => item.id === id);
      if (!survey) return false;

      const requestBody = {
        ...survey,
        ...changes,
      };

      const response = await api.put(`v3/survey/${id}`, requestBody);
      if (response.status === 200) {
        commit(localTypes.SET_NPS_SURVEY_STATUS, { id, ...changes });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Ошибка обновления параметров NPS', error);
      throw error;
    }
  },
  // Получение количества вариантов ответа
  async [localTypes.FETCH_NPS_OPTIONS_COUNT]({ commit }) {
    try {
      const { data } = await api.get('v3/survey/option');
      const response = data?.data;
      if (response && response.total) {
        commit(localTypes.SET_NPS_OPTIONS_COUNT, response.total);
      }
    } catch (e) {
      console.error('Ошибка получения количества вариантов ответа:', e);
    }
  },
};
