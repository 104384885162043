<template>
  <div class="policies-form-fields">
    <div class="clinics-form-fields">
      <div class="crm-form-field w-50">
        <div class="crm-form-block">
          <p class="crm-label required">
            Страховая компания
          </p>
          <v-select
            v-model="policy.companyName"
            :reduce="company => company.title"
            :options="insuranceCompanies"
            class="select-specialization"
            placeholder="Выберите страховую компанию"
            label="title"
            @input="changeCompany"
          />
        </div>
      </div>
      <div class="crm-form-field w-50">
        <div class="crm-form-block">
          <p class="crm-label required">
            Страховая программа
          </p>
          <v-select
            v-model="policy.programName"
            :reduce="program => program.name"
            :options="insurancePrograms"
            class="select-specialization"
            placeholder="Выберите страховую программу"
            label="name"
            @input="changeProgram"
          />
        </div>
      </div>
    </div>
    <div class="clinics-form-fields">
      <div class="crm-form-field w-50">
        <div class="crm-form-block">
          <p class="crm-label required">
            Подпрограмма прикрепления
          </p>
          <v-select
            v-model.trim="policy.subProgramV2Id"
            :reduce="subProgram => subProgram.id"
            :options="insuranceSubprograms"
            class="select-specialization"
            placeholder="Выберите страховую подпрограмму"
            label="name"
          />
        </div>
      </div>
      <base-input
        v-model.trim="policy.policyId"
        :input-style="{
          padding: '11px 15px 12px',
          resize: 'none',
          backgroundColor: '#ffffff',
        }"
        class="crm-form-field w-50"
        label="Серийный номер полиса"
        required
      />
    </div>
    <div class="clinics-form-fields">
      <div class="crm-form-field">
        <div class="crm-form-field">
          <div
            class="crm-form-block"
            :style="{
              minWidth: '150px',
            }"
          >
            <p class="crm-label required">
              Дата начала
            </p>
            <base-date-picker
              v-model="policy.startDate"
            />
          </div>
        </div>
        <div class="crm-form-field">
          <div
            class="crm-form-block"
            :style="{
              minWidth: '150px',
            }"
          >
            <p class="crm-label required">
              Дата окончания
            </p>
            <base-date-picker
              v-model="policy.endDate"
              :min-date="minPolicyEditDateTo"
            />
          </div>
        </div>
      </div>
      <div class="crm-form-field">
        <div class="crm-form-field">
          <div
            class="crm-form-block"
            :style="{
              minWidth: '150px',
            }"
          >
            <p class="crm-label">
              Дата покупки
            </p>
            <base-date-picker
              v-model="policy.buyDate"
              :max-date="maxDate"
            />
          </div>
        </div>
        <base-input
          v-model.trim="policy.ensurerName"
          :input-style="{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="Страхователь"
          placeholder="Введите страхователя"
        />
      </div>
    </div>
    <base-button
      :type="$const.SECONDARY_BUTTON"
      class="button_transparent button-remove-policy"
      @click="onDelete"
    >
      Удалить
    </base-button>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */

import {
  BaseInput,
  BaseButton,
  BaseDatePicker,
} from '@/components/base';

export default {
  name: 'PatientAddDetailPolicy',
  components: {
    BaseInput,
    BaseButton,
    BaseDatePicker,
  },
  props: {
    policy: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      maxDate: new Date(),
      insurancePrograms: [],
      insuranceSubprograms: [],
    };
  },
  computed: {
    insuranceCompanies() {
      return this.$store.state.Patients.insuranceCompanies.map(({ company: { id, title } }) => ({ id, title }));
    },
    minPolicyEditDateTo() {
      return this.policy.startDate || null;
    },
    maxPolicyEditData() {
      return this.policy.endDate || new Date();
    },
  },
  methods: {
    onDelete() {
      this.$emit('delete');
    },
    setInsuranceEntities() {
      if (!this.policy.companyName) return;

      const companyObject = this.$store.state.Patients.insuranceCompanies
        .filter(({ company: { title } }) => title === this.policy.companyName)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;

      if (!this.policy.programName) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { name } }) => name === this.policy.programName)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    changeCompany() {
      this.policy.programName = null;
      this.policy.subProgramV2Id = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.policy.subProgramV2Id = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onSubprogramChange(subprogramId) {
      this.subprogramDetailData = null;
      this.isSubprogramDetailOpen = false;
      if (!subprogramId) return;

      const subprogram = this.insuranceSubprograms.filter((subprog) => subprog.id === subprogramId)[0];
      this.policy.description = subprogram.name;
      this.policy.subProgramV2Id = subprogram.id;
    },
  },
};
</script>

<style lang="scss" scoped>

.button-remove-policy {
  width: 170px;
}
.policies-form-fields {
  border-top: 1px solid rgba(110, 136, 243, 0.3);
  padding-top: 30px;
  margin-bottom: 30px;
}

.crm-form-block {
  width: 100%;
}

.crm-form-field{
  &.w-50 {
    width: calc(50% - 15px);
  }
}
</style>
