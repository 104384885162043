<template>
  <div>
    <b-form-group>
      <b-table-simple
        bordered
        small
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th
              class="text-center"
              style="width: 13%;"
            >
              {{ '№' }}
            </b-th>
            <b-th>
              {{ '' }}
            </b-th>
            <b-th
              class="text-center w-25"
            >
              Код услуги
            </b-th>
            <b-th
              class="text-center w-50"
            >
              Наименование услуг
            </b-th>
            <b-th
              class="text-center"
              style="width: 15%"
            >
              К-во услуг
            </b-th>
            <b-th
              class="text-center"
              style="width: 5px"
            >
              Стоимость
            </b-th>
            <b-th
              class="text-center"
            >
              Итоговая стоимость
            </b-th>
            <b-th
              class="text-center"
            >
              {{ ' ' }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-if="rowsLetter.length">
            <b-tr
              v-for="(r, i) in $v.template.rows.$each.$iter"
              :key="i"
            >
              <b-td
                v-if="typeof r.$model !== 'string'"
              >
                <b-form-input
                  v-model="r.number.value.$model"
                  :type="'number'"
                  class="text-center"
                  number
                  @input="templateRowDataInputHandler"
                />
              </b-td>
              <b-td>
                <div
                  class="d-flex"
                >
                  <div>
                    <b-button
                      id="newLine"
                      v-b-tooltip.hover
                      variant="outline"
                      class="addRowButton"
                      title="Новая строка"
                      @click="addTableRow(i + 1)"
                    >
                      <b-icon
                        class="poi"
                        icon="plus-circle"
                        font-scale="0.9"
                      />
                    </b-button>
                    <b-tooltip
                      triggers="hover"
                      target="newLine"
                    />
                  </div>
                  <div>
                    <b-button
                      id="newGroupLine"
                      v-b-tooltip.hover
                      variant="outline"
                      class="addRowButton"
                      title="Новая строка группы услуг"
                      @click="addNewRowOnlyLineJust(i)"
                    >
                      <b-icon
                        icon="plus-circle"
                        font-scale="0.9"
                        variant="info"
                      />
                    </b-button>
                    <b-tooltip
                      triggers="hover"
                      target="newGroupLine"
                    />
                  </div>
                </div>
              </b-td>
              <template
                v-if="typeof r.$model !== 'string'"
              >
                <b-td>
                  <b-form-input
                    v-model="r.code.value.$model"
                    class="text-center"
                    :state="validateState('code', i)"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >
                    Данное поле обязательно*
                  </b-form-invalid-feedback>
                </b-td>
                <b-td>
                  <b-form-textarea
                    v-model="r.name.value.$model"
                    class="text-center"
                    :state="validateState('name', i)"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >
                    Данное поле обязательно*
                  </b-form-invalid-feedback>
                </b-td>
                <b-td
                  class="text-center"
                >
                  <b-form-input
                    v-model="r.count.value.$model"
                    :type="'number'"
                    class="text-center"
                    number
                    :state="validateState('count', i)"
                    @input="templateRowDataInputHandler"
                  />
                  <b-form-invalid-feedback
                    id="input-1-live-feedback"
                  >
                    Должно начинаться с 1*
                  </b-form-invalid-feedback>
                </b-td>
                <b-td>
                  <b-form-input
                    v-model="r.price.value.$model"
                    class="text-center"
                    number
                    @input="templateRowDataInputHandler"
                  />
                </b-td>
                <b-td>
                  <div
                    class="text-center"
                  >
                    <span
                      style="font-size: 15px !important; font-weight: 500;"
                    >
                      {{ r.sum.value.$model }}
                    </span>
                  </div>
                </b-td>
                <b-td>
                  <b-button
                    variant="outline"
                    @click="removeTableRow(i)"
                  >
                    <b-icon
                      icon="trash"
                      variant="danger"
                    />
                  </b-button>
                </b-td>
              </template>
              <template v-else>
                <b-td colspan="6">
                  <b-form-textarea
                    id="textarea-rows"
                    v-model="rowsLetter[i]"
                    class="text-center"
                    placeholder="Введите наименование группы услуг"
                    @input="templateRowStringInputHandler"
                  />
                </b-td>
                <b-td>
                  <b-button
                    variant="outline"
                    @click="removeTableRow(i)"
                  >
                    <b-icon
                      icon="trash"
                      variant="danger"
                    />
                  </b-button>
                </b-td>
              </template>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
    </b-form-group>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue, numeric } from 'vuelidate/lib/validators';

export default {
  name: 'IndemnityNewTemplateTable',
  mixins: [validationMixin],
  props: {},
  data() {
    return {};
  },
  validations() {
    return {
      templateName: {
        required,
      },
      template: {
        rows: {
          $each: {
            code: {
              value: {
                required,
              },
            },
            name: {
              value: {
                required,
              },
            },
            count: {
              value: {
                required,
                numeric,
                minValue: minValue(1),
              },
            },
            sum: {
              value: {},
            },
            price: {
              value: {},
            },
            number: {
              value: {},
            },
          },
        },
      },
    };
  },
  computed: {
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    isCheckValidation() {
      return this.$store.state.Indemnity.isCheckValidation;
    },
    isValidate() {
      return this.$store.state.Indemnity.isCreateNewTemplateValidate;
    },
    rowsLetter() {
      if (this.template.rows.length) {
        return this.template.rows;
      }
      return [];
    },
    colspanValue() {
      const columns = this.letter.tableColumns;
      let value = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in columns) {
        if (columns[key]) value += 1;
      }
      return value;
    },
    template() {
      return this.$store.state.Indemnity.editTemplate;
    },
  },
  watch: {
    isCheckValidation() {
      this.onCheckValidation();
    },
  },
  methods: {
    templateRowDataInputHandler() {
      const array = this.template.rows.map((row) => {
        const newRow = row;
        if (typeof row === 'string') return newRow;

        newRow.sum.value = +newRow.price.value * +newRow.count.value;
        return newRow;
      });

      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
    },
    templateRowStringInputHandler(value, index) {
      const array = this.template.rows.map((row, i) => (index === i ? value : row));

      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
    },
    addNewRowOnlyLineJust(index) {
      const row = '';
      const array = [...this.template.rows.slice(0, index), row, ...this.template.rows.slice(index, this.template.rows.length)];
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    addTableRow(index) {
      const row = {
        number: {
          isVisible: true,
          value: index,
        },
        code: {
          isVisible: true,
          value: '',
        },
        name: {
          isVisible: true,
          value: '',
        },
        count: {
          isVisible: true,
          value: '',
        },
        price: {
          isVisible: true,
          value: 0,
        },
        sum: {
          isVisible: true,
          value: 0,
        },
      };

      const array = [...this.template.rows.slice(0, index), row, ...this.template.rows.slice(index, this.template.rows.length)];
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    removeTableRow(index) {
      const array = [...this.template.rows.slice(0, +index), ...this.template.rows.slice(+index + 1, this.template.rows.length)];
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...this.template, rows: array });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    validateState(name, i) {
      const { $dirty, $error } = this.$v.template.rows.$each[i][name].value;
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      // TODO пофиксить валидацию - не валидируется когда строка группы
      const rowsValid = this.$v.template.rows.$each;
      const validArr = [];
      for (let i = 0; i < this.template.rows.length; i += 1) {
        if (typeof rowsValid[i].$model !== 'string') {
          rowsValid[i].code.$touch();
          rowsValid[i].name.$touch();
          rowsValid[i].count.$touch();
          if (rowsValid[i].count.$anyError
            || rowsValid[i].name.$anyError
            || rowsValid[i].code.$anyError
          ) {
            validArr.push(false);
          }
        }
      }
      console.log(validArr);
      if (validArr.length) {
        this.$store.commit(this.$types.INDEMNITY_NEW_TEMPLATE_VALIDATION, false);
        return false;
      }
      this.$store.commit(this.$types.INDEMNITY_NEW_TEMPLATE_VALIDATION, true);
      return true;
      // return this.isValidate;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  border: none !important;
  font-size: 15px;
  font-weight: 500;
}
.form-control:focus {
  box-shadow: none !important;
  border: none !important;
}
.table th, .table td {
  vertical-align: middle !important;
}
.addRowButton {
  box-shadow: none !important;
}
</style>
