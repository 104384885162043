<template>
  <div
    class="crm-row"
    style="width: 100%"
  >
    <div class="crm-column">
      <div class="crm-input-label">
        Должность
      </div>
      <base-input
        v-model="value.position"
        class="crm-form-field"
        placeholder="Введите название"
      />
    </div>
    <div class="crm-column">
      <div class="crm-input-label">
        ФИО
      </div>
      <base-input
        v-model="value.fio"
        class="crm-form-field"
        placeholder="Введите ФИО"
      />
    </div>
    <div class="crm-column">
      <div class="crm-input-label">
        E-mail
      </div>
      <base-input
        v-model="value.email"
        class="crm-form-field"
        placeholder="Введите почту"
      />
    </div>
    <div
      class="crm-column"
      style="display: flex; align-items: center;"
    >
      <div>
        <div class="crm-input-label">
          Номер телефона
        </div>
        <base-input
          v-model="value.phoneNumber"
          class="crm-form-field"
          :mask="phoneMask"
          placeholder="Введите номер"
        />
      </div>
      <icon-delete
        class="delete-icon"
        @click.stop="() => $emit('delete', index)"
      />
    </div>
  </div>
</template>

<script>
import {
  BaseInput,
} from '@/components/base';

import IconDelete from 'assets/images/delete_icon.svg';

export default {
  name: 'LegalEntityContact',
  components: {
    BaseInput,
    IconDelete,
  },
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    index: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      phoneMask: ['+', /\d/, '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
    };
  },
};
</script>

<style lang="scss" scoped>
.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-form-field {
  input {
    padding: 11px 10px 12px;
    resize: none;
  }
}

.crm-input-label {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.delete-icon {
  margin-left: 10px;
  position: relative;
  top: 12px;
  cursor: pointer;
}
</style>
