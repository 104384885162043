<template>
  <VDialog
    v-model="componentValue"
    v-bind="btnsOptions"
    :title="changeTitle"
  />
</template>

<script>
import { VDialog } from '@doctis.front/doctis.designsystem';

export default {
  components: {
    VDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    serveyStatus: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      btnsOptions: {
        btnPosition: false,
        leftButton: {
          label: 'Отмена',
          light: true,
          closeOnClick: true,
        },
        rightButton: {
          label: this.serveyStatus ? 'Остановить' : 'Опубликовать',
          handler: (onClose) => {
            this.$emit('changeStatusSuccess');
            onClose();
          },
        },
      },
      dialogTitle: 'Подтверждение действия',
    };
  },
  computed: {
    componentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    changeTitle() {
      return this.serveyStatus ? 'Остановить опрос?' : 'Опубликовать опрос?';
    },
  },
};
</script>
