<template>
  <div class="package-wrapper">
    <div class="package-inner">
      <template v-if="isImportingState">
        <div class="crm-info-text">
          Идет импорт файла. Загрузка может занять до 15 мин. Не прерывайте загрузку
        </div>
        <preloader style="margin-top: 20px" />
      </template>

      <template v-if="isSuccessCompleteState">
        <div class="crm-info-text">
          Файл добавлен в очередь импорта.
          <!-- Импортирование успешно завершено. Загружено пользователей: {{ importedData }} из {{ totalData }} -->
        </div>
        <div class="crm-container-buttons">
          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button-import width-170"
            @click="onFinished"
          >
            <span>ОК</span>
          </base-button>
        </div>
      </template>
      <template v-if="isErrorCompleteState">
        <div class="crm-info-text">
          Импорт частично завершен <br>
          <!-- Загружено пользователей: {{ importedData }} из {{ totalData }}<br/> -->
          <!-- <span style="color: #e76666;">Невозможно импортировать: {{ notImportedData }} из {{ totalData }}</span> -->
        </div>
        <div class="crm-container-buttons">
          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="downloadTable"
          >
            <span>Скачать таблицу</span>
          </base-button>
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button-file"
            @click="onClose"
          >
            <span>Отменить</span>
          </base-button>
        </div>
        <div
          class="additional-text"
          style="margin-top: 15px"
        >
          Таблица содержит данные <br>
          неимпортированных пользователей.
        </div>
      </template>

      <template v-if="isSelectImportTypeState">
        <div
          v-if="!hideModeSelector"
          class="crm-wrapper-radio"
        >
          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="0"
          >
            Импортировать новые полисы
          </base-radio-button>

          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="1"
          >
            Автоматически изменять полисы
          </base-radio-button>
          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="2"
          >
            Открепление пациентов от клиники
          </base-radio-button>
          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="3"
          >
            Открепление
          </base-radio-button>
          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="4"
          >
            Изменение обьема услуг
          </base-radio-button>
          <base-radio-button
            v-model="importType"
            class="crm-radio"
            :name="5"
          >
            Для РТ Доктис +смс
          </base-radio-button>
        </div>

        <div v-if="!hideModeSelector && importType === 2">
          <div class="crm-form-field-filter clinics-filter">
            <base-checkbox-select
              :container-class="'clinics-filter'"
              placeholder="Выберите клиники"
              fluid
            >
              <template
                v-slot:default="{ query }"
              >
                <base-checkbox-list
                  v-model="selectedClinicsForDetach"
                  :query="query"
                  :choices="params.allClinicsByGroup"
                />
              </template>
            </base-checkbox-select>
          </div>

          <div
            v-if="selectedClinicsForDetach.length"
            class="crm-form-clinics-wrapper"
          >
            <custom-scrollbar>
              <div
                class="item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(clinic, idx) in selectedClinicsForDetach"
                  :key="idx"
                  :speciality="clinic"
                  edit
                  class="crm-item"
                  @delete="deleteSelectedPackageClinic(clinic)"
                />
              </div>
            </custom-scrollbar>
          </div>
        </div>
        <div class="crm-container-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button-file"
            @click="onClose"
          >
            <span>Отменить</span>
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="startImport"
          >
            <span>Импортировать</span>
          </base-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseRadioButton,
  BaseCheckboxSelect,
  BaseCheckboxList,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { saveFile, showMessage } from '@/helpers/utils';
import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';

export default {
  name: 'InsuranceImportModal',
  components: {
    BaseButton,
    BaseRadioButton,
    Preloader,
    BaseCheckboxSelect,
    BaseCheckboxList,
    DoctorSpecialityItem,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    hideModeSelector: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedClinicsForDetach: [],
      isImportingState: false,
      isSelectImportTypeState: true,
      isSuccessCompleteState: false,
      isErrorCompleteState: false,

      importType: 0,
      importedData: 0,
      notImportedData: 0,
      totalData: 0,
      fileGUIDForDownload: '',
    };
  },
  methods: {
    deleteSelectedPackageClinic(clinic) {
      this.selectedClinicsForDetach = this.selectedClinicsForDetach.filter((item) => item.id !== clinic.id);
    },
    clearState() {
      this.isImportingState = false;
      this.isSelectImportTypeState = false;
      this.isSuccessCompleteState = false;
      this.isErrorCompleteState = false;
    },
    async downloadTable() {
      const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
      const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
    onClose() {
      this.$emit('vuedals:close');
    },
    onFinished() {
      this.$emit('vuedals:close');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async startImport() {
      if (this.importType === 2 && this.selectedClinicsForDetach.length === 0) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Выберите клиники для открепления',
        });
        return;
      }

      try {
        const params = {
          ...this.params,
          mode: this.importType,
        };

        if (params && params.allClinicsByGroup) delete params.allClinicsByGroup;
        if (this.importType === 2) params.clinicIdsForPolicies = this.selectedClinicsForDetach.map((item) => item.id);

        this.clearState();
        this.isImportingState = true;

        const {
          data: {
            allRowsCount, errorsCount, successCount, fileWithErrorsId,
          },
        } = await api.post('v2/policy/import', params);

        this.notImportedData = errorsCount;
        this.totalData = allRowsCount;
        this.importedData = successCount;

        this.clearState();
        if (errorsCount) {
          this.fileGUIDForDownload = fileWithErrorsId;
          this.isErrorCompleteState = true;
        } else {
          this.isSuccessCompleteState = true;
        }
      } catch (e) {
        this.clearState();
        this.isErrorCompleteState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-wrapper-radio {
    margin-left: 90px;
    & .crm-radio {
      margin: 16px 0px;
    }
  }

  .crm-info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }

.item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
::v-deep.clinics-filter {
  position: relative;
  //  .checkbox-list-wrapper {
  //   height: 300px !important;
  // }

  // .popover-inner {
  //   box-shadow: 0 5px 10px rgba(0, 0, 0, .1) !important;
  // }

  // .popover-select {
  //   position: static !important;
  //   transform: none !important;
  //   display: none !important;
  //   &.open {
  //     display: block !important;
  //   }
  // }
}

// .package-wrapper {
//   min-height: 400px;
//   height: calc(100vh - 170px);
// }

.crm-form-clinics-wrapper {
  height: 200px;
}

.width-170 {
  width: 170px !important;
}

// .package-inner {
//   padding: 0px 12px;
// }
</style>
