<template>
  <div class="content p-3">
    <div class="d-flex flex-column">
      <h6 class="title mb-3">
        Обложка истории
      </h6>
      <div class="d-flex">
        <div>
          <div class="mb-1">
            Обложка
          </div>
          <StoryPhotoEditor
            :photo-url-prop="story.fileUrlEdit"
            :preview-url-prop="story.preview"
            :story="story"
            @upload="$emit('uploadAvatar', $event)"
            @setPreview="$emit('setAvatarPreview', $event)"
          />
        </div>
        <div class="ml-4">
          <div class="d-flex align-items-center mb-2">
            <div class="mr-2">
              Заголовок
            </div>
            <span
              style="color: rgb(199, 193, 193)"
            >
              (Максимум символов 30, включая пробелы)
            </span>
          </div>
          <b-form-input
            v-model="story.title"
            size="sm"
            :state="v.$anyError ? !v.story.title.$error : null"
            placeholder="Максимум символов 30"
            style="width: 270px;"
          />

          <p
            v-if="story.title"
            class="mt-2"
            style="color: rgb(199, 193, 193)"
          >
            Количество введённых символов: {{ story.title.length }}
          </p>
        </div>
      </div>
      <div>
        <div class="content-title">
          <h6>
            Контент
          </h6>
          <b-button
            variant="outline-primary"
            :type="$const.PRIMARY_BUTTON"
            size="sm"
            class="mt-1"
            @click="$emit('storyContentAdd')"
          >
            Добавить историю
          </b-button>
        </div>

        <div class="stories-wrapper">
          <div
            v-for="(content, index) in carousel"
            :key="`content-${index}`"
            class="content-stories-carousel"
          >
            <div class="content-stories-carousel_container">
              <div>
                <div class="carousel-title">
                  <div>
                    <b-button
                      v-if="index"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm p-0"
                      class="arrow-left"
                      @click="$emit('replaceWithDown', index)"
                    >
                      <b-icon icon="caret-left-fill" />
                    </b-button>
                    <div v-else />
                  </div>
                  <div class="font-weight-bold">
                    {{ Number(index) + 1 }} история
                  </div>
                  <div>
                    <b-button
                      v-if="index !== carousel.length - 1"
                      variant="primary"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm p-0"
                      class="arrow-right"
                      @click="$emit('replaceWithUp', index)"
                    >
                      <b-icon icon="caret-right-fill" />
                    </b-button>
                    <div v-else />
                  </div>
                </div>
                <div class="fields-container">
                  <StoryPhotoEditor
                    :photo-url-prop="content.fileUrlEdit"
                    :preview-url-prop="content.preview"
                    :is-preview-button="content.showButtons"
                    :button-preview-text="previewButtonText"
                    :button-preview-style="previewButtonStyle"
                    :story="content"
                    :image-height="300"
                    :image-width="150"
                    :for-content="true"
                    @upload="$emit('uploadContentFile', {file: $event, contentIndex: index})"
                    @setPreview="$emit('setContentPreview', {preview: $event, contentIndex: index})"
                  />
                  <div class="d-flex align-items-center mb-2">
                    <div class="d-flex flex-column">
                      <div class="mb-1 text-center">
                        Кнопки
                      </div>

                      <b-form-radio-group
                        v-model="content.showButtons"
                        :options="BUTTON_VALUES"
                        value-field="value"
                        text-field="title"
                        @change="$emit('changeShowButtons', {historyIndex: index, value: $event})"
                      />
                    </div>
                    <b-button
                      v-if="carousel.length > 1 && !storyId"
                      variant="outline-danger"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      class="content-remove-btn"
                      @click="$emit('removeStoryCarousel', index)"
                    >
                      <b-icon icon="trash" />
                    </b-button>
                  </div>
                  <div class="btn-settings-container custom-scrollbar">
                    <StoryButtonSettings
                      v-if="content.showButtons"
                      :buttons="content.buttons"
                      :index="index"
                      :carousel="carousel"
                      :v="v"
                      @addContentButtons="$emit('addContentButtons', {contentIndex: index})"
                      @removeContentButtons="$emit('removeContentButtons', { buttonsIndex: $event, contentIndex: index})"
                      @updateButtonText="updateButtonText"
                      @updateButtonStyle="updateButtonStyle"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StoryPhotoEditor from '../StoryPhotoEditor';
import StoryButtonSettings from './StoryButtonSettings';

export default {
  name: 'StoryContent',
  components: {
    StoryPhotoEditor,
    StoryButtonSettings,
  },
  props: {
    story: {
      type: Object,
      default: () => ({}),
    },
    storyId: {
      type: [Number, String],
      default: null,
    },
    carousel: {
      type: Array,
      default: () => ([]),
    },
    v: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      previewButtonText: '',
      previewButtonStyle: 'primary',
      BUTTON_VALUES: [
        { value: false, title: 'Нет' },
        { value: true, title: 'Да' },
      ],
    };
  },
  methods: {
    updateButtonText(buttonText) {
      this.previewButtonText = buttonText;
    },
    updateButtonStyle(style) {
      this.previewButtonStyle = style.toLowerCase();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  overflow-y: auto;
}

.content-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.stories-wrapper {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  overflow-x: auto;
}
.content-remove-btn{
  width: 25px;
  height: 25px;
  padding: 3px;
  margin-left: 38px;
}

.content-stories-carousel {
  flex: 0 0 auto;
  min-width: 20%;
  display: flex;
  padding: 5px;
  margin: 2px;
  background: rgb(245, 242, 242);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px,
    rgba(0, 0, 0, 0.09) 0px 32px 16px;
  &_container{
    width: 100%;
    .carousel-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 5px;
    }
  }
}

.fields-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-settings-container{
  overflow-y: auto;
}
.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: #e4f3ff;
  }

  &::-webkit-scrollbar-thumb {
    background: #879ba8;
    border-radius: 5px;
  }
}

</style>
