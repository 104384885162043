<template>
  <div class="insurance-company">
    <custom-scrollbar>
      <div class="crm-container">
        <div class="company-edit-wrapper">
          <loading v-if="isCompanyLoading">
            Загрузка программ
          </loading>

          <template v-else>
            <span class="crm-title">
              {{ isEdit ? 'Редактирование' : 'Добавление' }} компании
            </span>

            <div class="crm-form-fields">
              <base-input
                v-model="$v.company.title.$model"
                class="crm-form-field"
                :error="$v.company.title.$error"
                :errors="errorsValidation.title"
                :input-style="{
                  padding: '11px 20px',
                }"
                label="Название страховой компании"
                placeholder="Введите название"
              />

              <base-input
                v-model="$v.company.phoneNumber.$model"
                class="crm-form-field"
                :error="$v.company.phoneNumber.$error"
                :errors="errorsValidation.phoneNumber"
                :input-style="{
                  padding: '11px 20px',
                }"
                label="Телефон горячей линии"
                placeholder="Введите номер"
              />
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <span class="crm-label">
                    Логотип страховой компании
                  </span>

                  <base-button
                    v-if="!company.logo"
                    :type="$const.SECONDARY_BUTTON"
                    @click="openPhotoModal"
                  >
                    Загрузить
                  </base-button>

                  <div
                    v-else
                    class="image-controls"
                  >
                    <img
                      class="img-company"
                      :src="photoUrl"
                    >

                    <base-button
                      :type="$const.SECONDARY_BUTTON"
                      class="button-transparent"
                      @click="openPhotoModal"
                    >
                      Изменить
                    </base-button>
                  </div>
                </div>
              </div>

              <div
                v-if="$v.company.logo.$error"
                class="validation-errors"
              >
                <span
                  class="validation-error-text"
                >
                  {{ errorsValidation.logo[0] }}
                </span>
              </div>
            </div>

            <div class="d-flex w-100 justify-content-between">
              <div class="w-25" />
              <b-button-group>
                <b-button
                  pill
                  variant="outline-primary"
                  :disabled="isSaving"
                  @click="onCancel"
                >
                  Отменить
                </b-button>

                <b-button
                  pill
                  variant="primary"
                  :disabled="isSaving"
                  class="ml-2"
                  @click="onSave"
                >
                  Сохранить
                </b-button>
              </b-button-group>

              <b-button
                v-b-tooltip.hover
                pill
                :title="company.isHidden ? 'Убрать из скрытых' : 'Скрыть страховую компанию'"
                :variant="company.isHidden ? 'outline-primary' : 'outline-danger'"
                @click="openAgreeModal"
              >
                {{ company.isHidden ? 'Убрать из скрытых' : 'Скрыть' }}
              </b-button>
            </div>
          </template>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { showMessage } from '@/helpers/utils';
import Bus from '@/eventBus';
import Loading from '@/components/Loading';

import {
  BaseInput,
  BaseButton,
} from '@/components/base';
import { APP_HOST } from '@/app.config';

export default {
  name: 'InsuranceCompanyEdit',
  components: {
    BaseInput,
    Loading,
    BaseButton,
  },
  mixins: [validationMixin],
  validations() {
    return {
      company: {
        title: {
          required,
        },
        phoneNumber: {
          required,
        },
        logo: {
          required,
        },
      },
    };
  },
  data() {
    return {
      company: {
        id: null,
        title: null,
        phoneNumber: null,
        logo: null,
      },
      isCompanyLoading: false,
      isSaving: false,
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.title = [];
      if (!this.$v.company.title.required) {
        errors.title.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.company.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.logo = [];
      if (!this.$v.company.logo.required) {
        errors.logo.push('Необходимо добавить фото');
      }

      return errors;
    },
    isEdit() {
      return !!this.$route.params.company;
    },
    companyId() {
      return this.$route.params.company;
    },
    photoUrl() {
      if (this.company.logo !== '00000000-0000-0000-0000-000000000000' && this.company.logo !== null) {
        // eslint-disable-next-line max-len
        return `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${this.company.logo}`;
      }

      return null;
    },
  },
  async created() {
    await this.onCreate();
  },
  methods: {
    async onCreate() {
      if (this.companyId) {
        this.isCompanyLoading = true;
        const { company } = await this.$store.dispatch(this.$types.COMPANY_FETCH, this.companyId);
        this.company = company;
        this.isCompanyLoading = false;
      }
    },
    openPhotoModal() {
      Bus.$emit('open-modal:insurance-company-photo', {
        photoUrlProp: this.photoUrl,
        imageHeight: 80,
        imageWidth: 80,
        saveCallback: (guid) => { this.$v.company.logo.$model = guid; },
      });
    },
    onCancel() {
      this.$router.push('/insurance-companies');
    },
    async onSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });

        return;
      }

      try {
        if (this.company.id) {
          await this.$store.dispatch(this.$types.COMPANY_UPDATE, this.company);
        } else {
          await this.$store.dispatch(this.$types.COMPANY_CREATE, this.company);
        }
        this.$router.push('/insurance-companies');
      } finally {
        this.isSaving = false;
      }
    },
    async hideOrShowCompany() {
      await this.$store.dispatch(this.$types.COMPANY_HIDE_OR_SHOW, {
        params: { companyId: this.companyId },
      });

      await this.onCreate();
    },
    openAgreeModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.hideOrShowCompany },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.company-edit-wrapper {
  width: 100%;
  margin-top: 20px;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.crm-title {
  @extend .page-title;

  margin-bottom: 30px;
}
.crm-form-fields {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.img-company {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.image-controls {
  display: flex;
}
.button-transparent {
  background-color: transparent;
  padding: 0;
  border: none;
}
</style>
