<!--  eslint-disable  -->

<template>
  <div
    class="p-[16px] rounded-[8px] bg-v-ui-root-monochrome-5 cursor-pointer my-1"
    @click="onSelect"
  >
    <div class="flex justify-between items-center mb-[16px]">
      <div class="flex items-center">
        <SingleRadio
          name="nsiId"
          v-model="componentModel"
          class="shrink-0"
          :isChecked="organization.nsiId === selectedOrganization.nsiId"
        />

        <span class="title-16-medium-500 ml-[8px] text-v-ui-text-monochrome">
          {{ organization?.name }}
        </span>
      </div>
    </div>

    <div class="pl-[32px] flex flex-col gap-y-[8px]">
      <div class="flex justify-between">
        <div
          class="title-14-medium-500 text-[#343A40] shrink-0 flex items-center gap-x-[12px] flex-1"
        >
         ИНН: <v-attribute :content="organization?.inn" variant="info" size="xs" class="crm-tag"  />
        </div>

        <div
          class="title-14-medium-500 text-[#343A40] shrink-0 flex items-center gap-x-[12px] flex-1"
        >
         ОГРН: <v-attribute :content="organization?.ogrn" variant="info" size="xs" class="crm-tag" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { VAttribute } from '@doctis.front/doctis.designsystem'
import SingleRadio from '@/components/base/SingleRadio.vue'
export default {
  props: {
    organization: {
      type: Object,
      default: () => {},
      required: true
    },
    selectedOrganization: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      componentModel: {}
    }
  },

  components: { VAttribute, SingleRadio },

  methods: {
    onSelect() {
      this.$emit('onSelect', this.organization)
    }
  }
}
</script>

<style lang="scss">
    .crm-tag {
        font-size: 14px;
        font-weight: 500;
        color: #212529;
    }
</style>
