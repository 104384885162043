<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-form-field_align-center">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Наименование организации
              </p>
            </div>
            <div class="crm-form-field crm-form-field_justify_end">
              <span class="program-name">
                {{ letter.insuranceProgramName }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields d-block">
            <div class="crm-label">
              Тип прайса
            </div>
            <div class="crm-radio-buttons d-flex">
              <base-radio-button
                v-for="type in priceTypesFiltered"
                :key="type.id"
                v-model="priceType"
                :name="type.id"
                class="crm-radio-button mr-4 mt-0"
              >
                {{ type.title }}
              </base-radio-button>
            </div>
          </div>

          <div
            v-if="priceType === 2"
            class="crm-form-fields"
          >
            <div class="crm-form-field w-100">
              <div class="crm-form-block w-100">
                <div class="crm-label required">
                  Город
                </div>
                <v-select
                  v-model="$v.city.$model"
                  :options="cities"
                  placeholder="Выберите город"
                  label="name"
                  :clearable="true"
                  class="crm-select"
                />
                <div
                  v-if="$v.city.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ errorsValidation.city[0] }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <template v-if="priceType === 0 || priceType === 3">
            <div class="crm-form-fields">
              <base-async-select
                v-model="$v.clinic.$model"
                :error="$v.clinic.$error"
                :errors="errorsValidation.clinic"
                :fetch-function="fetchClinics"
                :option-title="'title'"
                :clearable="false"
                class="crm-select"
                label="Наименование организации"
                loading-title="Загрузка клиник"
                no-options-title="Клиники не найдены"
                server-paginated
              >
                <template v-slot:selected-option="{item}">
                  <span>{{ `${item.title} (${item.addressString})` }}</span>
                </template>

                <template v-slot:option="{item}">
                  <span>{{ `${item.title} (${item.addressString})` }}</span>
                </template>
              </base-async-select>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <base-input
                  v-model="$v.address.$model"
                  :error="$v.address.$error"
                  :errors="errorsValidation.address"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  :readonly="!clinic"
                  label="Местонахождение организации"
                  fluid
                />
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <base-input
                  v-model="$v.positionOfDirector.$model"
                  :error="$v.positionOfDirector.$error"
                  :errors="errorsValidation.positionOfDirector"
                  label="Должность"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  :readonly="!clinic"
                  fluid
                />
              </div>

              <div class="crm-form-field">
                <base-input
                  v-model="$v.fioOfDirector.$model"
                  :error="$v.fioOfDirector.$error"
                  :errors="errorsValidation.fioOfDirector"
                  label="Имя адресанта"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  :readonly="!clinic"
                  fluid
                />
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <base-input
                  v-model="inn"
                  label="ИНН*"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  :readonly="!clinic"
                  fluid
                />
              </div>

              <div class="crm-form-field">
                <base-input
                  v-model="kpp"
                  label="КПП*"
                  :input-style="{
                    padding: '11px 15px 11px',
                  }"
                  :readonly="!clinic"
                  fluid
                />
              </div>
            </div>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <span class="description">
                  * - не является обязательным пунктом при заполнении
                </span>
              </div>
            </div>
          </template>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        :loading="isLetterSaving"
        @click="onClickCancel"
      >
        Отменить
      </base-button>

      <base-button
        :type="$const.PRIMARY_BUTTON"
        @click="onClickNext"
      >
        Продолжить
      </base-button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';
import {
  BaseAsyncSelect,
  BaseInput,
  BaseButton,
  BaseRadioButton,
} from '@/components/base';
import IndemnityArowLeftButton from './IndemnityArowLeftButton';

export default {
  name: 'IndemnityModalStep2',
  components: {
    IndemnityArowLeftButton,
    BaseAsyncSelect,
    BaseInput,
    BaseButton,
    BaseRadioButton,
  },
  mixins: [validationMixin],
  props: {
    isReferral: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cities: [],
      priceTypes: [
        {
          id: 0,
          title: 'Шаблон',
        },
        // {
        //   id: 1,
        //   title: 'Нозологический',
        // },
        {
          id: 2,
          title: 'Склеенные услуги',
        },
        {
          id: 3,
          title: 'Услуги из Прайса',
        },
      ],
    };
  },
  validations() {
    return {
      city: {
        required: this.priceType === 2 ? required : () => true,
      },
      clinic: {
        required: this.priceType === 2 ? () => true : required,
      },
      address: {
        required: this.priceType === 2 ? () => true : required,
      },
      fioOfDirector: {
        required: this.priceType === 2 ? () => true : required,
      },
      positionOfDirector: {
        required: this.priceType === 2 ? () => true : required,
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.city = [];
      if (!this.$v.city.required) {
        errors.city.push('Поле не может быть пустым');
      }

      errors.clinic = [];
      if (!this.$v.clinic.required) {
        errors.clinic.push('Поле не может быть пустым');
      }

      errors.address = [];
      if (!this.$v.address.required) {
        errors.address.push('Поле не может быть пустым');
      }

      errors.fioOfDirector = [];
      if (!this.$v.fioOfDirector.required) {
        errors.fioOfDirector.push('Поле не может быть пустым');
      }

      errors.positionOfDirector = [];
      if (!this.$v.positionOfDirector.required) {
        errors.positionOfDirector.push('Поле не может быть пустым');
      }

      return errors;
    },
    priceTypesFiltered() {
      return this.letter.isReferral ? [this.priceTypes[0]] : this.priceTypes;
    },
    isLetterSaving() {
      return this.$store.state.Indemnity.isLetterSaving;
    },
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    priceType: {
      get() {
        return this.letter.priceType;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, priceType: newValue });
      },
    },
    clinic: {
      get() {
        return this.letter.clinic?.title ? this.letter.clinic : null;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET,
          {
            ...this.letter,
            clinic: {
              ...newValue,
              network: newValue.legalPerson?.network?.id,
            },
            clinicId: newValue.id,
            recipientName: newValue.fioOfDirector,
            recipientPosition: newValue.positionOfDirector,
          });
      },
    },
    city: {
      get() {
        return this.$store.state.Indemnity.letterIndemnityCity?.name ? this.$store.state.Indemnity.letterIndemnityCity : null;
      },
      set(newValue) {
        this.$store.state.Indemnity.letterIndemnityCity = newValue;
      },
    },
    address: {
      get() {
        return this.letter.clinic?.addressString;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, clinic: { ...this.letter.clinic, addressString: newValue } });
      },
    },
    fioOfDirector: {
      get() {
        return this.letter.clinic?.fioOfDirector;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET,
          {
            ...this.letter,
            clinic: { ...this.letter.clinic, fioOfDirector: newValue },
            recipientName: newValue,
          });
      },
    },
    positionOfDirector: {
      get() {
        return this.letter.clinic?.positionOfDirector;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET,
          {
            ...this.letter,
            clinic: { ...this.letter.clinic, positionOfDirector: newValue },
            recipientPosition: newValue,
          });
      },
    },
    inn: {
      get() {
        return this.letter.clinic?.inn;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, clinic: { ...this.letter.clinic, inn: newValue } });
      },
    },
    kpp: {
      get() {
        return this.letter.clinic?.kpp;
      },
      set(newValue) {
        this.$store.commit(this.$types.INDEMNITY_LETTER_SET, { ...this.letter, clinic: { ...this.letter.clinic, kpp: newValue } });
      },
    },
  },
  async created() {
    this.cities = (await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' }))
      .filter((c) => c.name)
      .sort((a, b) => a.name.localeCompare(b.name));
  },
  methods: {
    clickPrev() {
      this.$emit('change-step', -1);
    },
    async fetchClinics({ query, skip, take }) {
      const { data: clinics } = await api.post('v2/clinic/search', {
        searchString: query,
        skip,
        take,
        clinicAttribute: this.isReferral ? 0 : null,
      });

      return clinics.map((clinic) => ({ ...clinic, name: `${clinic.title}` }));
    },
    checkHasValidateErrors() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
      }

      return this.$v.$error;
    },
    onClickCancel() {
      this.$emit('cancel');
    },
    onClickNext() {
      if (!this.checkHasValidateErrors()) {
        this.$emit('change-step', 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-select {
  width: 100%;
}
.page-title {
  margin: 0;
}
.description {
  font-size: 14px;
  line-height: 18px;
  color: #908F8D;
}
</style>
