import { render, staticRenderFns } from "./DoctorConsultationsInfoItem.vue?vue&type=template&id=e8926934&scoped=true"
import script from "./DoctorConsultationsInfoItem.vue?vue&type=script&lang=js"
export * from "./DoctorConsultationsInfoItem.vue?vue&type=script&lang=js"
import style0 from "./DoctorConsultationsInfoItem.vue?vue&type=style&index=0&id=e8926934&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8926934",
  null
  
)

export default component.exports