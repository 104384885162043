<template>
  <b-modal
    id="IndemnityPriceClinicTemplateAddModal"
    v-model="isOpen"
    scrollable
    centered
    size="xl"
    title="Добавить шаблон"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <div>
      <div class="d-flex justify-content-between">
        <b-input
          v-model="search.name"
          placeholder="Название шаблона"
          class="mr-2"
          size="sm"
          @keyup.native.enter="onPriceSearch"
        />

        <b-button
          variant="primary"
          size="sm"
          :disabled="search.isLoading"
          @click="onPriceSearch"
        >
          <b-icon icon="search" />
        </b-button>

        <b-button
          variant="primary"
          class="ml-2"
          size="sm"
          @click="() => openTemplateEditModal()"
        >
          Новый
        </b-button>
      </div>

      <div
        v-if="search.templates.length && !search.isLoading"
        class="d-flex flex-wrap templates-row mt-3"
      >
        <div
          v-for="template in search.templates"
          :key="template.id"
          class="templates-item rounded border p-3"
        >
          <div class="d-flex mb-1">
            <div class="h5 m-0 mr-2">
              {{ template.name }}
            </div>

            <div class="ml-auto d-flex flex-column">
              <b-button
                variant="outline-primary"
                class="mb-2"
                size="sm"
                @click="() => openTemplateEditModal(template)"
              >
                <b-icon icon="pencil" />
              </b-button>
              <b-button
                variant="outline-danger"
                class="mb-2"
                size="sm"
                @click="() => openTemplateDeleteModal(template)"
              >
                <b-icon icon="trash" />
              </b-button>
            </div>
          </div>

          <template v-if="template.clinicPrices?.length">
            <small
              v-for="(price, i) in template.clinicPrices.slice(0, 4)"
              :key="price.id"
              class="d-block text-muted text-truncate p-1"
              :class="{
                'border-top': i > 0,
              }"
            >
              <span class="mr-2">
                {{ price.code }}
              </span>

              {{ price.name }}
            </small>
            <small class="d-block text-muted text-truncate p-1">
              Количество услуг: {{ template.clinicPrices.length }}
            </small>

            <div class="d-flex mt-auto">
              <b-button
                variant="primary"
                class="mx-auto mt-2"
                size="sm"
                @click="onSaveTemplate(template)"
              >
                Выбрать
              </b-button>
            </div>
          </template>

          <small
            v-else
            class="d-block text-muted text-truncate p-1 text-center"
          >
            Услуги не найдены
          </small>
        </div>
      </div>

      <div
        v-else-if="!search.isLoading"
        class="mt-3"
      >
        <small class="text-muted">
          Шаблоны не найдены
        </small>
      </div>

      <div
        v-else
        class="mt-3 text-center"
      >
        <b-spinner variant="primary" />
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="outline-primary"
        class="float-right ml-2"
        size="sm"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'IndemnityPriceTemplateAddModal',
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    saveTemplateCallback: {
      type: Function,
      default: () => {},
    },
  },

  data: () => ({
    isOpen: true,
    isLoading: false,
    search: {
      name: '',
      isLoading: false,
      templates: [],
    },
  }),

  methods: {
    async onPriceSearch() {
      this.search.isLoading = true;

      const data = await this.$store.dispatch(this.$types.PRICE_CLINIC_TEMPLATES_LIST_FETCH, {
        name: this.search.name,
        clinicId: this.$store.state.Indemnity?.letter?.clinicId,
        take: 9,
      });

      this.search.templates = data?.map((template) => ({
        ...template,
        clinicPrices: template.clinicPrices?.reduce((result, item) => {
          const itemInResult = result.find((i) => i.id === item.id);

          if (itemInResult) {
            itemInResult.count += 1;
          } else {
            result.push({ ...item, count: item.count || 1 });
          }

          return result;
        }, []) || [],
      }));

      this.search.isLoading = false;
    },
    onSaveTemplate(template) {
      if (this.saveTemplateCallback) this.saveTemplateCallback(template.clinicPrices);
      this.onClose();
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openTemplateEditModal(template) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityPriceClinicTemplateEditModal',
        props: {
          template,
          saveTemplateCallback: () => {
            this.search.templates = [];
          },
        },
      });
    },
    openTemplateDeleteModal(template) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'IndemnityPriceClinicTemplateDeleteModal',
        props: {
          template,
          deleteTemplateCallback: () => {
            this.search.templates = [];
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep#IndemnityPriceClinicTemplateAddModal___BV_modal_outer_ {
  z-index: 1050!important;
}

.templates-row {
  gap: 1rem;
}

.templates-item {
  width: calc((100% - 2rem) / 3);
  display: flex;
  flex-direction: column;
}
</style>
