<template>
  <div>
    <div class="d-flex align-items-center">
      <b-form-input
        v-model="query"
        type="search"
        placeholder="Введите данные клиники"
        class="border"
        @keyup.enter="$emit('search')"
      />
      <b-button
        id="popover-filter"
        v-b-tooltip.hover
        :variant="filterSelected ? 'primary' : 'light'"
        class="border border-primary px-2 ml-2"
        title="Дополнительные фильтры"
      >
        <b-icon icon="filter" />
      </b-button>
      <b-button
        v-b-tooltip.hover
        variant="primary"
        class="px-2 ml-2"
        title="Поиск"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>
    <div class="d-flex justify-content-between mt-2 w-100">
      <b-form-input
        v-model="inn"
        placeholder="ИНН"
        size="sm"
        class="mr-2"
        style="width: 200px"
        @keypress="isNumber($event)"
        @keyup.native.enter="$emit('search')"
      />

      <div class="d-flex">
        <b-button
          variant="outline-primary"
          size="sm"
          class="border-0"
          @click="resetFilter"
        >
          Сбросить
        </b-button>
        <!-- v-if="!checkRoleAccess_(['Operator', 'Tech.operator', 'MedicalCurator', 'Dis.monitoring.operator', 'Dis.monitoring.admin'])"
        <b-button
          v-if="checkFeatureAccess({ name: 'Кнопка ответственные', url: '/clinics' })"
          variant="primary"
          size="sm"
          class="ml-2"
          @click="openResponsiblesModal"
        >
          Ответственные
        </b-button> -->
      </div>
    </div>
    <b-popover
      custom-class="mt-4"
      :show.sync="isPopoverOpen"
      target="popover-filter"
      triggers="click"
      placement="bottom"
    >
      <template #title>
        <div class="d-flex justify-content-end">
          <b-button
            variant="outline"
            class="p-0"
            @click="isPopoverOpen = false"
          >
            <b-icon
              icon="x"
              variant="primary"
              aria-hidden="true"
            />
          </b-button>
        </div>
      </template>
      <div class="crm-filter-popover pr-3">
        <custom-scrollbar>
          <div class="crm-filter-popover-inner">
            <div class="crm-label">
              Сеть клиник
            </div>
            <v-select
              v-model="network"
              :options="networks"
              placeholder="Выберите сеть"
              label="title"
              :clearable="true"
              class="crm-select"
            />
            <div class="crm-label">
              Город
            </div>
            <v-select
              v-model="city"
              :options="cities"
              placeholder="Выберите город"
              label="name"
              :clearable="true"
              class="crm-select"
            />

            <div class="crm-label">
              Услуги
            </div>
            <base-checkbox
              v-for="(service, index) in services"
              :key="service"
              :value="servicesArray.includes(index)"
              class="crm-checkbox"
              @change="toggleService(index)"
            >
              {{ service }}
            </base-checkbox>

            <div class="crm-label">
              Признак договора
            </div>
            <base-radio-button
              v-model="filter.clinicAttribute"
              class="crm-radio-button"
              :name="null"
            >
              Не выбрано
            </base-radio-button>
            <base-radio-button
              v-model="filter.clinicAttribute"
              class="crm-radio-button"
              :name="0"
            >
              Агентский договор
            </base-radio-button>
            <base-radio-button
              v-model="filter.clinicAttribute"
              class="crm-radio-button"
              :name="1"
            >
              Телемед
            </base-radio-button>
            <base-radio-button
              v-model="filter.clinicAttribute"
              class="crm-radio-button"
              :name="2"
            >
              Очная помощь
            </base-radio-button>

            <b-button
              variant="primary"
              class="mt-2"
              @click="applyFilter"
            >
              Применить
            </b-button>
          </div>
        </custom-scrollbar>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  BaseCheckbox,
  BaseRadioButton,
} from '@/components/base';

import Bus from '@/eventBus';

import { CLINIC_SERVICES } from '@/helpers/consts';
import { mixinRoles } from '@/mixins';

export default {
  name: 'ClinicSearchPanel',
  components: {
    BaseCheckbox,
    BaseRadioButton,
  },
  mixins: [mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    filterSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      services: CLINIC_SERVICES,
      networks: [],
      cities: [],
      isPopoverOpen: false,
    };
  },
  computed: {
    query: {
      get() {
        return this.filter.query;
      },
      set(newVal) {
        this.$emit('change', { ...this.filter, query: newVal });
      },
    },
    network: {
      get() {
        return this.filter.network;
      },
      set(newVal) {
        this.$emit('change', { ...this.filter, network: newVal });
      },
    },
    city: {
      get() {
        return this.filter.city;
      },
      set(newVal) {
        this.$emit('change', { ...this.filter, city: newVal });
      },
    },
    inn: {
      get() {
        return this.filter.inn;
      },
      set(newVal) {
        this.$emit('change', { ...this.filter, inn: newVal });
      },
    },
    servicesArray() {
      return Array.isArray(this.filter.services) ? this.filter.services : [];
    },
  },
  async created() {
    this.networks = (await this.$store.dispatch(this.$types.NETWORK_LIST_FETCH)).sort((a, b) => a.title.localeCompare(b.title));
    this.cities = (await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' })).sort((a, b) => a.name.localeCompare(b.name));
  },
  methods: {
    isNumber: (e = window.event) => {
      const charCode = (e.which) ? e.which : e.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        e.preventDefault();
      } else {
        return true;
      }
    },
    openResponsiblesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicResponsibleModal',
      });
    },

    resetFilter() {
      Bus.$emit('clinic-search:reset-filter');
    },
    applyFilter() {
      this.$emit('search');
      this.isPopoverOpen = false;
    },
    toggleService(service) {
      // Убедимся, что this.filter.services инициализирован как массив
      if (!Array.isArray(this.filter.services)) {
        this.$set(this.filter, 'services', []);
      }
      const index = this.filter.services.indexOf(service);
      if (index > -1) {
        this.filter.services.splice(index, 1);
      } else {
        this.filter.services.push(service);
      }
    },
    // onPopOverClose
  },
};
</script>

<style lang="scss" scoped>
.crm-select:not(:last-child) {
  margin-bottom: 20px;
}
.crm-filter-popover {
  width: 270px;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin-right: -15px;
}

.crm-filter-popover-inner {
  padding-right: 15px;
}

.search-deleted-wrapper{
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  padding-left: 1.3125rem !important;
}

.button_transparent {
  padding: 0;
  background-color: transparent;
  color: $blue;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  flex-basis: 144px;
}
.without-border {
  border: none;
}

.reset-button {
  width: auto;
  margin-right: 20px;
  font-weight: 500;
}

::v-deep.crm-label {
  margin-bottom: 10px;
}

::v-deep.custom-checkbox {
  font-size: 14px;
  line-height: 18px;
  display: block;

  & + .custom-checkbox {
    margin-top: 5px;
  }
}

.apply-button {
  font-weight: 500;
  margin: 20px auto 0;
}

.disable-outline {
  outline: none;
}
</style>
