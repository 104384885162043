<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="schedule-modal-inner">
      <schedule-wrapper :doctor-id="doctorId">
        <schedule @change-active-tab="activeTimeTab = $event" />
      </schedule-wrapper>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        size="sm"
        @click="onClose"
      >
        Закрыть
      </b-button>

      <b-button
        v-if="!isDoctorLoading && !doctor.settings.useExternalSchedule"
        variant="primary"
        class="float-right"
        size="sm"
        :disabled="isDoctorLoading"
        @click="openTemplatesModal"
      >
        Редактировать
      </b-button>
      <b-button
        v-else-if="!isDoctorLoading && doctor.settings.useExternalSchedule"
        variant="primary"
        class="float-right"
        title="Нет доступа к редактированию, используется расписание МИС"
        size="sm"
        disabled
      >
        Используется расписание МИС
      </b-button>
      <b-button
        v-if="activeTimeTab"
        variant="success"
        class="float-right"
        size="sm"
        :disabled="isDoctorLoading"
        @click="openCheckupModal"
      >
        Записать пациента
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';

import { mapGetters } from 'vuex';
import * as types from '@/store/types';

import ScheduleWrapper from './ScheduleWrapper';
import Schedule from './Schedule';

export default {
  name: 'ScheduleModal',
  components: {
    Schedule,
    ScheduleWrapper,
  },
  mixins: [mixinRoles],
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Расписание',
    },
  },
  data() {
    return {
      isOpen: true,
      isDoctorLoading: true,
      activeTimeTab: null,
      doctor: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedDoctor: types.SCHEDULE_DOCTOR_GET,
    }),
  },
  async created() {
    try {
      this.doctor = await this.$store.dispatch(this.$types.DOCTOR_FETCH, this.doctorId);
      this.$store.commit(this.$types.SCHEDULE_DOCTOR_SET, this.doctor);
      this.isDoctorLoading = false;
    } catch (e) {
      console.log('Редактирование расписания отключено');
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.SCHEDULE_DOCTOR_SET, null);
    this.$store.commit(this.$types.SCHEDULE_DATE_SET, null);
    this.$store.commit(this.$types.SCHEDULE_SET, []);
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openTemplatesModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleTemplatesModal',
        props: {
          doctor: this.doctor,
        },
      });
      // Bus.$emit('open-modal:schedule-templates', {
      //   doctor: this.doctor,
      // });
    },
    openCheckupModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleMakeAnAppointmentModal',
        props: {
          time: this.activeTimeTab,
          doctorId: this.selectedDoctor.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.without-border {
  border: none;
}

.schedule-modal {
  height: 500px;
}

.schedule-modal-inner {
  padding: 0 10px;
  box-sizing: border-box;
}

::v-deep .schedule-block {
  border-bottom: none;
}

::v-deep .schedule-wrapper {
  min-height: unset;
  height: unset;
}
</style>
