<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите заблокировать пациента?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            class="crm-button"
            @click="onClickDelete"
          >
            Заблокировать
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PatientDeleteModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      try {
        this.isDeleting = true;

        await this.$store.dispatch(this.$types.DELETE_USER_DEVICE, this.patientId);
        await this.$store.dispatch(this.$types.PATIENT_DELETE, this.patientId);

        Bus.$emit('patients:update');
        this.$emit('vuedals:close');
        this.callback();
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
