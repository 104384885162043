<template>
  <div
    :class="{ ['crm-fluid']: fluid }"
    class="crm-checkbox-select"
  >
    <template v-if="render">
      <base-input
        :id="'popover-filter' + uniqueKey"
        ref="base-checkbox-select"
        v-model="query"
        :input-style="{
          padding: '11px 15px',
        }"
        :label="label"
        :placeholder="placeholder"
        :class="{ ['crm-fluid']: fluid }"
      />

      <b-popover
        :custom-class="`base-checkbox-select-popover ${containerClass}`"
        :target="'popover-filter' + uniqueKey"
        triggers="focus blur"
        placement="bottom"
        @show="onShow"
      >
        <div class="crm-checkbox-list-wrapper">
          <custom-scrollbar>
            <slot :query="query" />
          </custom-scrollbar>
        </div>
      </b-popover>

      <base-button
        v-if="clearButton"
        :type="$const.SECONDARY_BUTTON"
        class="button_transparent button_cancel"
        @click="$emit('clear')"
      >
        Сбросить фильтры
      </base-button>
    </template>
  </div>
</template>

<script>
import { BaseInput, BaseButton } from '@/components/base';

export default {
  name: 'BaseCheckboxSelect',
  components: {
    BaseInput,
    BaseButton,
  },
  props: {
    clearButton: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      query: '',
      uniqueKey: 0,
      render: false,
    };
  },

  created() {
    // компонент используется в разных местах на одной страничке, из-за этого не открывался поповер.
    this.uniqueKey = Math.floor(Math.random() * 1000) + 1;

    // для надежности использую переменную рендер
    this.render = true;
  },

  methods: {
    onShow() {
      const inputWidth = this.$refs['base-checkbox-select'].$el.offsetWidth;
      document.documentElement.style.cssText = `--base-checkbox-select-popover-max-width: ${inputWidth}px`;
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-checkbox-select {
  display: flex;
  position: relative;

  &.crm-fluid {
    width: 100%;
  }
}

::v-deep.crm-popover {
  width: 100%;

  .trigger {
    width: 100%;
  }
}

.crm-checkbox-list-wrapper {
  height: 150px!important;
}
.button_transparent {
  padding: 0;
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  flex-basis: 144px;
}
.button_cancel {
  font-size: 12px;
  line-height: 15px;
  border: none;
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<style lang="scss">
html {
  --base-checkbox-select-popover-max-width: 100%;
}

.base-checkbox-select-popover {
  max-width: var(--base-checkbox-select-popover-max-width)!important;

  .arrow{
    display: none!important;;
  }
}
</style>
