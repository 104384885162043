<template>
  <div class="page-dm-statistic">
    <div class="page-dm-statistic__container">
      <div class="d-flex align-items-center page-dm-statistic__header">
        <div class="h3 mr-4">Статистика</div>
        <div v-if="$store.state.Auth.user.doctorId" class="align-items-center page-dm-statistic__header-buttons">
          <button type="primary" class="btn ml-auto btn-primary btn-sm" @click="() => openDoctorAuth('/patients')"> Назначить мониторинг </button>
          <button type="primary" class="btn ml-auto btn-primary btn-sm" @click="() => openDoctorAuth('/consultations')"> В ЛК врача </button>
        </div>
    </div>
      <d-remote-monitoring-stats :role="role" class="page-dm-statistic__remove-monitoring-stats"  />
    </div>
  </div>
</template>

<script>
import { APP_DOCTOR_URL } from '@/app.config';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'RemoteMonitoringStats',
  page: {
    title: 'CRM Doctis - Дис. мониторинг',
  },
  computed: {
    role() {
      return this.$store.state.Auth.role;
    },
  },
  methods: {
    async openDoctorAuth(redirect) {
      try {
        if (this.$store.state.Auth.user.doctorId) {
          const id = this.$store.state.Auth.user.doctorId;
          const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, id);
          const doctorUrl = APP_DOCTOR_URL;
          let url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;
          if (redirect) {
            url += `&redirect=${redirect}`;
          }
          window.location.href = url;
        }
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось авторизовать врача',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .page-dm-statistic {
    overflow: auto;
    width: 100%;
    .remote-monitoring-stats__wrapper {
      margin-top: 24px!important;
    }
    &__container {
      max-width: 1322px;
      padding: 0 15px;
      margin-left: auto;
      width: 95%;
      margin-right: auto;
    }
    &__header {
      padding-top: 32px;
      padding-bottom: 0;
      justify-content: space-between;
      width: 95%;
      margin-left: auto;
      margin-right: auto;
      &-buttons {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
    &__remove-monitoring-stats {
      height: fit-content !important;
      overflow: auto!important;
    }
  }
</style>
