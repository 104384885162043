<template>
  <div
    class="story-background"
    :style="{ width: imageWidth + 'px', height: imageHeight + 'px' }"
  >
    <div class="text">
      <span>+</span>
      <span>Загрузить</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'StoryBackgroundForImage',
  props: {
    imageHeight: {
      type: Number,
      default: 100,
    },
    imageWidth: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
  .story-background {
    display: flex;
    justify-content: center;
    background: #fff;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
</style>
