<template>
  <div class="doctor-info-wrapper">
    <custom-scrollbar>
      <Loading
        v-if="isLoading"
        class="crm-loading-wrapper"
      >
        Загрузка доктора
      </Loading>

      <div
        v-else
        class="doctor-info"
      >
        <div class="doctor-info--block">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Логин
              </p>

              <span class="crm-info-text">
                {{ doctor.login || doctor.userInfo.userName || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Email
              </p>

              <span class="crm-info-text">
                {{ doctor.email || 'Не указано' }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Номер телефона
              </p>

              <span class="crm-info-text">
                {{ doctor.phoneNumber || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                ID врача
              </p>

              <span class="crm-info-text">
                {{ doctor.id }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Дата рождения
              </p>

              <span class="crm-info-text">
                {{ birthDate }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Пол
              </p>

              <span class="crm-info-text">
                {{ sexType }}
              </span>
            </div>
          </div>
        </div>

        <div class="doctor-info--block">
          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Название клиники
              </p>

              <span class="crm-info-text">
                {{ doctor.clinicName }}
              </span>
            </div>
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Признак врача
              </p>

              <span class="crm-info-text">
                {{ signType }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Учетная запись врача является активной
              </p>

              <span class="crm-info-text">
                {{ activeStatus }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Тип видимости
              </p>

              <span class="crm-info-text">
                {{ accessType }}
              </span>
            </div>
          </div>

          <div
            v-if="!doctor.settings.isActive"
            class="crm-form-fields"
          >
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Причина отключения учетной записи
              </p>

              <span class="crm-info-text">
                {{ deactivationReason || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Комментарий отключения учетной записи
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.deactivationComment || 'Не указано' }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                СНИЛС
              </p>

              <span class="crm-info-text">
                {{ doctor.snils || 'Не указано' }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Дистанционный мониторинг
              </p>

              <span class="crm-info-text">
                {{ remoteMonitoringStatus }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Стоимость приема
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.consultationPrice }} ₽
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Стоимость депозита
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.depositPrice ? doctor.settings.depositPrice + '₽' : 'Не указано' }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Доверенность
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.powerOfAttorneyNumber || 'Не указано' }}
              </span>
            </div>
            <div v-if="doctor?.settings.crmUserId && doctor.hasLinkedCrmUser" class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Связанный профиль администратора
              </p>

              <span class="crm-info-text">
                {{ doctor?.crmUserInfo.displayName || 'Не указано' }}
              </span>
            </div>
          </div>

          <div
            v-if="doctor.settings.signType === 1"
            class="crm-form-fields"
          >
            <div class="crm-info-block">
              <p class="crm-info-title">
                Идентификатор МиД
                <span class="crm-info-title_subtitle">
                  (только для врачей МиД)
                </span>
              </p>

              <span class="crm-info-text">
                {{ doctor.settings.midServiceId }}
              </span>
            </div>
          </div>
        </div>

        <div class="doctor-info--block">
          <div
            v-for="(specialization, index) in specializations"
            :key="index"
            class="crm-form-fields"
          >
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Специализация
              </p>

              <span class="crm-info-text">
                {{ specializationsNames[specialization.specializationId] }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Опыт работы
                <span class="crm-info-text_subtext">
                  (начиная с...)
                </span>
              </p>

              <span class="crm-info-text">
                {{ specialization.experience }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div
              v-if="acceptsAge"
              class="crm-form-field crm-info-block"
            >
              <p class="crm-info-title">
                Принимает (по возрасту)
              </p>

              <span class="crm-info-text">
                {{ acceptsAge }}
              </span>
            </div>

            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Тип связи
              </p>

              <span class="crm-info-text">
                {{ linkTypes }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Регионы врача
              </p>

              <span class="crm-info-text">
                {{ regions }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Тип прикрепления
              </p>

              <span class="crm-info-text">
                {{ medicalInsuranceTypes }}
              </span>
            </div>
          </div>

          <div class="crm-form-fields">
            <div class="crm-form-field crm-info-block">
              <p class="crm-info-title">
                Прикрепленные очереди
              </p>

              <b-table-simple
                v-if="doctor.queues.length"
                bordered
                small
              >
                <b-tbody>
                  <b-tr
                    v-for="queue in doctor.queues"
                    :key="queue.id"
                  >
                    <b-td>
                      {{ queue.name }}
                    </b-td>
                    <b-td>
                      {{ queue.description || 'Нет описания' }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
              <div
                v-else
                class="text-secondary"
              >
                Очереди не добавлены
              </div>
            </div>
          </div>
        </div>
        <div class="crm-button-wrapper">
          <base-button
            v-if="checkFeatureAccess({ name: '(Модальное окно) Редактирование врача', url: '/doctors' })"
            :type="$const.SECONDARY_BUTTON"
            class="edit-button"
            @click="openEditModal"
          >
            Редактировать
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import { api } from '@/helpers/api';
import {
  DOCTOR_SIGN_TYPE,
  SEX_TYPE, POLICY_TYPE,
  DOCTOR_ACCESS_TYPE,
  DOCTOR_INACTIVE_REASONS,
} from '@/helpers/consts';
import { mixinRoles } from '@/mixins';

import Loading from '@/components/Loading';

import { BaseButton } from '@/components/base';

export default {
  name: 'DoctorInfoModal',
  components: {
    Loading,
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    doctorId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      doctor: {},
      DOCTOR_INACTIVE_REASONS,
    };
  },
  computed: {
    activeStatus() {
      return this.doctor.settings.isActive ? 'Да' : 'Нет';
    },
    deactivationReason() {
      return DOCTOR_INACTIVE_REASONS[this.doctor.settings.deactivationReason]?.title || '';
    },
    acceptsAge() {
      if (!this.doctor.mainDoctorSpecialization) return false;

      const { isForAdults, isForChildren } = this.doctor.mainDoctorSpecialization;

      if (isForAdults && isForChildren) {
        return 'Взрослых и детей';
      }
      if (isForAdults) {
        return 'Взрослых';
      }
      if (isForChildren) {
        return 'Детей';
      }

      return false;
    },
    remoteMonitoringStatus() {
      return this.doctor.settings.remoteMonitoringAllowed ? 'Да' : 'Нет';
    },
    birthDate() {
      return format(parseISO(this.doctor.birthDate), 'dd.MM.yyyy');
    },
    signType() {
      return DOCTOR_SIGN_TYPE[this.doctor.settings.signType];
    },
    sexType() {
      return SEX_TYPE[this.doctor.sex];
    },
    accessType() {
      return DOCTOR_ACCESS_TYPE[this.doctor.settings.accessType];
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((specialization) => ({
        ...specialization,
        experience: format(parseISO(specialization.experience), 'dd.MM.yyyy'),
      }));
    },
    specializationsNames() {
      return this.doctor.specializations.reduce((acc, specialization) => ({ ...acc, [specialization.id]: specialization.title }), {});
    },
    linkTypes() {
      const types = ['Текст'];
      if (this.doctor.settings.isAudioEnabled) types.push('Аудио');
      if (this.doctor.settings.isVideoEnabled) types.push('Видео');

      return types.join(', ');
    },
    regions() {
      return this.doctor.regions.map((region) => region.name).join(', ') || '—';
    },
    medicalInsuranceTypes() {
      const decodedInsuranceType = POLICY_TYPE.decodeType(this.doctor.settings.medicalInsuranceType);
      let insuranceTypeArray = [];

      if (decodedInsuranceType === 'All') {
        insuranceTypeArray = ['ОМС', 'ДМС'];
      } else if (decodedInsuranceType === 'OMS') {
        insuranceTypeArray = ['ОМС'];
      } else if (decodedInsuranceType === 'DMS') {
        insuranceTypeArray = ['ДМС'];
      }

      return insuranceTypeArray.join(', ') || '—';
    },
  },
  async created() {
    try {
      this.isLoading = true;

      const { data: doctor } = await api.get(`v2/doctors/${this.doctorId}`);

      this.doctor = doctor;
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    openEditModal() {
      this.$emit('vuedals:close');
      // Bus.$emit('open-modal:doctor-edit', this.doctorId);
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorEditModal',
        props: {
          doctorId: this.doctorId,
          title: 'Редактирование врача',
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.doctor-info-wrapper {
  height: calc(100vh - 152px);
}
.doctor-info,
.doctor-info--block {
  width: 100%;
}
.doctor-info {
  margin-top: 30px;
  padding-right: 10px;
  box-sizing: border-box;
}
.doctor-info--block {
  padding-bottom: 30px;
  & + & {
    border-top: 1px solid $gray-border;
    padding-top: 30px;
  }

}
.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.crm-info-block {
  flex-direction: column;

  .crm-info-text {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &_subtext {
      font-weight: normal;
      color: $gray-dark;
    }
  }

  .crm-info-title {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 17px;

    &_subtitle {
      color: $gray-border;
    }
  }
}

.crm-button-wrapper {
  display: flex;
  justify-content: center;
}
</style>
