// import NProgress from 'nprogress/nprogress';
// import jwtDecode from 'jwt-decode';
import {
  removeNamespaces,
  showMessage,
} from '@/helpers/utils';
import * as types from '@/store/types';
import { api } from '@/helpers/api';
import NProgress from 'nprogress/nprogress';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.ACCOUNT_USERS_FETCH]({ commit }, {
    save,
    take,
  } = {}) {
    NProgress.start();

    const params = new URLSearchParams();
    if (take) params.append('take', take);

    try {
      const { data: responsibles } = await api.get('v2/account/users', { params });

      if (save) commit(localTypes.ACCOUNT_USERS_SET, responsibles);

      return responsibles;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список ответственных лиц',
      });
    } finally {
      NProgress.done();
    }
  },
};
