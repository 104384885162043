<template>
  <div class="w-100 pt-4">
    <b-container fluid="xl">
      <div class="mb-3">
        <div class="h3 mb-0">
          Промокоды
        </div>
      </div>

      <div class="p-3 bg-white rounded border border-black">
        <LaboratoryPromocodesSearchPanel
          v-model="code"
          @search="promocodesFetch(true)"
        >
          <template
            v-if="checkFeatureAccess({ name: 'Добавление промокода', url: '/laboratory-promocodes' })"
            #search-right
          >
            <b-button
              class="ml-2"
              variant="primary text-nowrap"
              @click="openPromocodeModal(null)"
            >
              <b-icon
                icon="plus"
                aria-hidden="true"
              />
              Новый промокод
            </b-button>
          </template>

          <template #filters>
            <LaboratoryPromocodesSearchFilters
              v-model="filters"
              :legal-partner-list="legalPartnerList"
              @search="promocodesFetch"
            />
          </template>
        </LaboratoryPromocodesSearchPanel>
      </div>
      <div
        v-if="!totalCount"
      >
        Ничего не найдено
      </div>

      <template v-else>
        <div
          v-if="promocodes.length"
          class="mt-4 promocodes-wrapper"
        >
          <LaboratoryPromocodeItem
            v-for="promocode in promocodes"
            :key="promocode.id"
            :promocode-data="promocode"
            class="mt-2"
            @edit="openPromocodeModal(promocode.id)"
            @remove="openDeleteModal(promocode.id)"
          />
        </div>
        <div
          v-if="loading"
          style="transform: translate(0, -15px)"
          class="mt-4"
        >
          <b-card
            v-for="item in 10"
            :key="item"
            class="mt-2 rounded "
          >
            <b-skeleton
              animation="wave"
              width="7%"
            />
            <b-skeleton
              animation="wave"
              width="45%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
            <b-skeleton
              animation="wave"
              width="60%"
            />
            <b-skeleton
              animation="wave"
              width="55%"
            />
            <b-skeleton
              animation="wave"
              width="70%"
            />
          </b-card>
        </div>

        <div class="d-flex align-items-center">
          <b-dropdown
            id="dropdown-1"
            :text="`${limit}`"
            split
            class="m-md-2"
            size="sm"
            split-variant="outline-primary"
            variant="primary"
          >
            <b-dropdown-item @click="tableSizeHandler(10)">
              10
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(25)">
              25
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click="tableSizeHandler(50)">
              50
            </b-dropdown-item>
          </b-dropdown>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="limit"
            class="mt-2"
            @input="() => promocodesFetch()"
          />
        </div>

        <div
          v-if="accident"
          class="mt-3 p-3 bg-white border-dark rounded"
        >
          {{ accident }}
        </div>
      </template>
    </b-container>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import LaboratoryPromocodesSearchPanel from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodesSearchPanel';
import LaboratoryPromocodesSearchFilters from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodesSearchFilters';
import LaboratoryPromocodeItem from '@/components/Laboratories/Modals/Promocodes/LaboratoryPromocodeItem';

export default {
  name: 'Promocodes',
  components: {
    LaboratoryPromocodesSearchPanel,
    LaboratoryPromocodesSearchFilters,
    LaboratoryPromocodeItem,
  },
  mixins: [mixinRoles],

  data: () => ({
    loading: false,
    code: '',
    currentPage: 1,
    limit: 10,
    filters: {
      code: '',
      isActual: true,
      legalPartnerId: null,
    },
    legalPartnerList: [],
    error: false,
  }),
  computed: {
    promocodes() {
      return this.$store.getters[this.$types.LABORATORY_PROMOCODES_GET];
    },
    totalCount() {
      return this.$store.getters[this.$types.LABORATORY_PROMOCODES_COUNT_GET];
    },
    totalPageCount() {
      return Math.ceil(this.totalCount / this.limit);
    },
    accident() {
      if (this.error) {
        return 'Произошла ошибка';
      }
      if (this.isAllItemsLoaded && !this.promocodes.length) {
        return 'Ничего не найдено';
      }

      return false;
    },
    offset() {
      return this.limit * (this.currentPage - 1);
    },
  },
  async created() {
    await this.fetchPartners();

    this.promocodesFetch(true);
  },
  methods: {
    async tableSizeHandler(mount) {
      this.limit = mount;
      await this.promocodesFetch();
    },
    async fetchPartners() {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
      this.legalPartnerList = data;
    },
    async promocodesFetch(clear) {
      if (this.loading) {
        return;
      }

      if (clear) {
        this.currentPage = 1;
      }

      this.loading = true;
      try {
        await this.$store.dispatch(this.$types.LABORATORY_PROMOCODES_FETCH,
          {
            code: this.code,
            isActual: this.filters.isActual,
            limit: this.limit,
            offset: this.offset,
            legalPartnerId: this.filters.legalPartnerId,
          });
      } catch (e) {
        console.error(e);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    openPromocodeModal(id) {
      const props = {};

      if (id) {
        props.promocodeId = id;
        props.title = 'Редактирование промокода';
      }
      props.legalPartnerList = this.legalPartnerList;
      props.limit = this.limit;
      props.offset = this.offset;
      props.legalPartnerId = this.filters.legalPartnerId;
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'LaboratoryPromocodeEditModal',
        props,
      });
    },
    async deletePromocode(id) {
      try {
        await this.$store.dispatch(this.$types.LABORATORY_PROMOCODE_DELETE, id);

        this.$store.commit(this.$types.LABORATORY_PROMOCODE_REMOVE_BY_ID, id);
        await this.$store.dispatch(this.$types.LABORATORY_PROMOCODES_FETCH, {
          code: this.code,
          isActual: this.filters.isActual,
          limit: this.limit,
          offset: this.offset,
          legalPartnerId: this.filters.legalPartnerId,
        });
      } catch (e) {
        console.error(e);
      }
    },
    openDeleteModal(id) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: {
            title: 'Удаление промокода',
            cb: this.deletePromocode,
            cbArgs: [id],
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.promocodes-wrapper{
  overflow: auto;
  height: calc(100vh - 360px);
}
</style>
