import mutations from './mutations';
// import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    patient: null,
    patientFullData: null,
    medtests: [],
    clinics: [],
    clinicQuery: '',
    selectedClinic: null,
  },
  actions,
  // getters,
  mutations,
};
