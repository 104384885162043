<template>
  <div class="appeal-item">
    <div class="crm-row">
      <div class="crm-date">
        {{ date }}
      </div>
    </div>
    <div class="crm-title">
      {{ purposes[appeal.purpose] }}
    </div>
    <div
      class="crm-status"
    >
      <span
        class="crm-status-text"
      >
        Статус:
      </span>
      <span
        class="crm-status-text"
        :class="statusColor"
      >
        {{ statusText }}
      </span>
    </div>
  </div>
</template>

<script>
import { parseISO, format } from 'date-fns';

export default {
  name: 'PatientDetailAppealsItem',
  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      statuses: {
        0: 'В работе',
        1: 'Отложено',
        2: 'Закрыто',
      },
      purposes: {
        0: 'Информационное',
        1: 'Чекап',
        2: 'Лечение / плановая госпитализация',
        3: 'Экстренная госпитализация',
      },
    };
  },
  computed: {
    date() {
      return format(parseISO(this.appeal.dateTime), 'dd.MM.yyyy');
    },
    statusColor() {
      if (this.appeal.status === 0) return 'green';
      if (this.appeal.status === 1) return 'yellow';
      if (this.appeal.status === 2) return 'red';

      return '';
    },
    statusText() {
      return this.statuses[this.appeal.status];
    },
  },
};
</script>

<style lang="scss" scoped>
.appeal-item {
  cursor: pointer;
  padding: 20px 10px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;

  &.active {
    background: #6E88F3;
    color: #ffffff;

    .date, .cmr-status, .crm-status-text {
      color: #ffffff!important;
    }
  }

  .crm-row {
    display: flex;
    justify-content: space-between;
  }

  .crm-date {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .crm-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }

  .crm-status {
    margin-top: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #908F8D;
  }

  .crm-status-text {
    &.green {
      color: $green;
    }
    &.yellow {
      color: #F2994A;
    }
    &.red {
      color: $red;
    }
  }
}
</style>
