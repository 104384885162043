<template>
  <div class="consultations-info">
    <div
      v-if="!totalCount && isAllItemsLoaded"
      class="no-consultations"
    >
      <span>Нет консультаций</span>
    </div>
    <async-list
      v-else
      :is-all-items-loaded="isAllItemsLoaded"
      :disable-toggle-header="true"
      class="consultations-list"
      @load-more-items="loadMoreItems"
    >
      <template>
        <div
          v-for="consultation in consultations"
          :key="consultation.id"
          class="crm-container container-consultations-info"
        >
          <doctor-consultations-info-item
            :consultation="consultation"
            class="consultations-info"
          />
        </div>
      </template>
    </async-list>
  </div>
</template>

<script>
import AsyncList from '@/components/AsyncList';

import DoctorConsultationsInfoItem from './DoctorConsultationsInfoItem';

const LIMIT = 15;

export default {
  name: 'DoctorConsultationsInfoModal',
  components: {
    AsyncList,
    DoctorConsultationsInfoItem,
  },
  props: {
    doctorId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isAllItemsLoaded: false,
      isLoading: false,
      limit: LIMIT,
    };
  },
  computed: {
    consultations() {
      return this.$store.state.Doctor.consultations;
    },
    totalCount() {
      return this.consultations.length;
    },
  },
  beforeDestroy() {
    this.$store.commit(this.$types.DOCTORS_CONSULTATIONS_SET, []);
  },
  methods: {
    async consultationsFetch() {
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = this.totalCount;

      try {
        await this.$store.dispatch(this.$types.DOCTORS_CONSULTATIONS_FETCH, {
          skip: prevTotal,
          take: this.limit,
          doctorId: this.doctorId,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.consultationsFetch();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.consultations-info {
  width: 100%;
}
.consultations-list {
  height: 589px;
}
.crm-container.container-consultations-info {
  margin-bottom: 10px;
}
.no-consultations {
  display: flex;
  justify-content: center;
}
</style>
