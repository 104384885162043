<template>
  <v-modal
    v-model="componentValue"
    size="l"
    title="Добавление ответа"
    @input="onClose"
    class="nps-options-modal"
  >
    <div class="d-flex flex-column mt-4 gap-6">
      <VInput
        v-model="title"
        :label="$v.title.$error ? 'Это поле обязательно для заполнения' : 'Ответ для опроса'"
        :error="$v.title.$error"
        content-width
        placeholder="Введите ответ"
      />
      <VTextArea
        v-model="description"
        :invalid="$v.description.$error"
        :error-text="$v.description.$error ? 'Это поле обязательно для заполнения' : ''"
        label="Описание ответа"
        placeholder="Придумайте описание ответа"
      />
    </div>
    <div class="d-flex pt-6 justify-content-end">
      <VButton label="Сохранить" size="m" @click="addAnswer" />
    </div>
  </v-modal>
</template>

<script>
import {
  VModal,
  VInput,
  VTextArea,
  VButton,
} from '@doctis.front/doctis.designsystem';
import { required } from 'vuelidate/lib/validators';

export default {
  components: {
    VModal,
    VInput,
    VTextArea,
    VButton,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: '',
      description: '',
    };
  },
  validations() {
    return {
      title: {
        required,
      },
      description: {
        required,
      },
    };
  },
  computed: {
    componentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onClose() {
      this.componentValue = false;
    },
    addAnswer() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.warn('Некорректные данные!');
        return;
      }
      this.$emit('addAnswer', { title: this.title, description: this.description });
      this.onClose();
    },
  },
};
</script>

<style lang="scss">
  .nps-options-modal {
    p {
      margin-bottom: 0;
    }
    .panel-header-base {
      border-bottom: 1px solid #E8E8EA;
    }
  }
</style>
