v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Импорт отделений'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Партнер"
          >
            <b-form-select
              v-model="legalPartnerId"
              value-field="id"
              text-field="legalName"
              label="legalName"
              trim
              :options="optionsLegalPartner"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Шаблон"
          >
            <b-form-select
              v-model="importType"
              value-field="item"
              text-field="name"
              :options="optionsType"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="importFile"
            class="mt-3"
            plain
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :disabled="!importFile || !legalPartnerId"
          @click="onClickImport"
        >
          Импорт
        </b-button>
        <b-button
          variant="danger"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  name: 'ImportBranches',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
      importType: null,
      optionsLegalPartner: [],
      optionsType: [
        { item: '0', name: 'По умолчанию' },
        { item: '1', name: 'Шаблон Гемотеста' },
      ],
      legalPartnerId: null,
    };
  },
  computed: {
    legalPartnerListStore() {
      return this.$store.state.Laboratories.legalPartnerList;
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
    this.optionsLegalPartner = this.legalPartnerListStore.data;
    this.isLoading = false;
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickImport() {
      const params = {
        legalPartnerId: this.legalPartnerId,
        importFile: this.importFile,
        importType: this.importType,
      };
      await this.$store.dispatch(this.$types.LABORATORY_BRANCH_IMPORT, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_BRANCHES, this.params);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
