<template>
  <div class="w-100 pt-4 pb-4 overflow-auto">
    <b-container fluid="xl">
      <NpsTitle />
      <TabsNavigation
        :selected-tab="selectedTab"
        :badge-number="optionsCount"
      />
      <Preloader v-if="isLoading" />
      <QuestCard
        v-else
        :surveys="surveys"
        @editModal="editModal"
        @changeStatus="changeStatus"
      />
    </b-container>
    <NpsSurveysModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      :surveys-edit-object="surveysEditObject"
      title="Редактирование опроса"
      @updateServeyCard="updateServeyCard"
    />
    <NpsSurveysDialog
      v-if="isPublicModel"
      v-model="isPublicModel"
      :servey-status="serveyStatus"
      @changeStatusSuccess="changeStatusSuccess"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  FETCH_NPS_OPTIONS_COUNT,
  GET_NPS_OPTIONS_COUNT,
  FETCH_NPS_SURVEYS,
  GET_NPS_SURVEYS,
  GET_NPS_LOADING,
  UPDATE_NPS_SURVEY,
} from '@/store/types';
import NpsTitle from '@/components/Statistics/Nps/NpsTitle.vue';
import Preloader from '@/components/Preloader';
import TabsNavigation from '@/components/Statistics/Nps/TabsNavigation.vue';
import QuestCard from '@/components/Statistics/Nps/QuestCard.vue';
import NpsSurveysModal from '@/components/Statistics/Nps/Surveys/SurveysModal.vue';
import NpsSurveysDialog from '@/components/Statistics/Nps/Surveys/SurveysDialog.vue';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'NpsSurveysPage',
  page: {
    title: 'NPS МП Пациента - Опросы',
  },
  components: {
    NpsTitle,
    TabsNavigation,
    QuestCard,
    Preloader,
    NpsSurveysModal,
    NpsSurveysDialog,
  },
  data() {
    return {
      isModalOpen: false,
      isPublicModel: false,
      selectedTab: { label: 'Опросы', value: '/statistics/nps/surveys' },
      surveysEditObject: {},
      surveyStatus: false,
      surveyId: null,
    };
  },
  computed: {
    ...mapGetters({
      optionsCount: GET_NPS_OPTIONS_COUNT,
      surveys: GET_NPS_SURVEYS,
      isLoading: GET_NPS_LOADING,
    }),
  },
  async mounted() {
    await Promise.all([
      this.fetchSurveys(),
      this.fetchOptionsCount(),
    ]);
  },
  methods: {
    ...mapActions({
      fetchOptionsCount: FETCH_NPS_OPTIONS_COUNT,
      fetchSurveys: FETCH_NPS_SURVEYS,
      updateSurvey: UPDATE_NPS_SURVEY,
    }),
    editModal(survey) {
      this.surveysEditObject = survey;
      this.isModalOpen = true;
    },
    changeStatus(status, id) {
      this.serveyStatus = status;
      this.serveyId = id;
      this.isPublicModel = true;
    },
    async updateServeyCard(item) {
      try {
        const success = await this.updateSurvey({
          id: item.id,
          changes: item,
        });
        if (success) {
          this.showMessageStatus('edit');
        }
      } catch (error) {
        console.error('Ошибка обновления опроса NPS', error);
      }
    },
    async changeStatusSuccess() {
      try {
        const newStatus = !this.serveyStatus;
        const success = await this.updateSurvey({
          id: this.serveyId,
          changes: { active: newStatus },
        });
        if (success) {
          this.showMessageStatus(newStatus);
        }
      } catch (error) {
        console.error('Ошибка обновления статуса NPS', error);
      }
    },
    showMessageStatus(status) {
      const messages = {
        true: { text: 'Опрос опубликован', type: 'success' },
        false: { text: 'Опрос остановлен', type: 'warning' },
        edit: { text: 'Опрос отредактирован', type: 'success' },
      };
      const { text, type } = messages[status];
      showMessage({ type, message: text });
    },
  },
};
</script>
