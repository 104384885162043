import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.GET_DOCTORS_PINNED](state) {
    return state.pinnedDoctors;
  },
  [localTypes.GET_IS_CHANGED_DOCTORS_INFO](state) {
    return state.isChangedClinicDoctorsInfo;
  },
  [localTypes.GET_IS_CHANGED_INFO](state) {
    return state.isChangedClinicInfo;
  },
  [localTypes.PRICES_LIST](state) {
    return state.pricesList;
  },
  [localTypes.PRICES_LIST_PAGINATION_COUNT](state) {
    return state.pricesListPaginationCount;
  },
  [localTypes.PRICES_LIST_LOADING](state) {
    return state.pricesListLoading;
  },
};
