import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CONSULTATIONS_SET](state, payload) {
    state.consultations = payload;
  },
  [localTypes.CONSULTATIONS_ADD](state, payload) {
    state.consultations = [...state.consultations, ...payload];
  },
  [localTypes.CONSULTATIONS_CONTROL_SET](state, payload) {
    state.control.consultations = payload;
  },
  [localTypes.CONSULTATIONS_CONTROL_LOADING_SET](state, payload) {
    state.control.isLoading = payload;
  },
  [localTypes.TOGGLE_CONSULTATIONS_PROBLEMS_TRIGGER](state) {
    state.consultationsProblemsTrigger = !state.consultationsProblemsTrigger;
  },
  [localTypes.UPDATE_CURRENT_CONSULTATION](state, payload) {
    state.control.consultations = state.control.consultations.map((item) => {
      if (item.id === payload.consultationId) {
        const problemInfo = { ...item.problemInfo };
        problemInfo.status = payload.status;
        problemInfo.doctorComment = payload.proccessingComment;
        problemInfo.patientComment = payload.proccessingPatientComment;
        item.problemInfo = problemInfo;
      }
      return item;
    });
  },
};
