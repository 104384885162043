<template>
  <div class="patient-block-wrapper">
    <div class="crm-form-title">
      <span class="crm-form-title-inner">
        Данные пациента
      </span>
    </div>

    <div class="crm-form-row">
      <div class="crm-form-field w-25">
        <div class="crm-form-block">
          <p class="crm-label">
            Страховая компания
          </p>
          <v-select
            v-model="searchData.companyName"
            :reduce="company => company.title"
            :options="insuranceCompanies"
            placeholder="Выберите компанию"
            label="title"
            @input="changeCompany"
          />
        </div>
      </div>
      <div class="crm-form-field w-25">
        <div class="crm-form-block">
          <p class="crm-label">
            Страховая программа
          </p>
          <v-select
            v-model="searchData.programName"
            :reduce="program => program.name"
            :options="insurancePrograms"
            placeholder="Выберите программу"
            label="name"
            @input="changeProgram"
          />
        </div>
      </div>
      <div class="crm-form-field w-25">
        <div class="crm-form-block">
          <p class="crm-label">
            Страховая подпрограмма
          </p>
          <v-select
            v-model.trim="searchData.subprogramId"
            :reduce="subprogram => subprogram.id"
            :options="insuranceSubprograms"
            placeholder="Выберите подпрограмму"
            label="name"
            @input="changeSubrogram"
          />
        </div>
      </div>
      <div class="crm-form-field w-25">
        <base-input
          v-model.trim="searchData.policyCode"
          :input-style="{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="Номер полиса"
          placeholder="Введите номер"
          @keyup.native.enter="searchPatient"
          @input="changePolicy"
        />
        <base-button
          :type="$const.PRIMARY_BUTTON"
          class="crm-search-button"
          @click="searchPatient"
        >
          <icon-search
            class="crm-icon-search"
          />
        </base-button>
      </div>
    </div>

    <div
      v-if="patients && patients.length"
      class="crm-form-row"
    >
      <custom-scrollbar>
        <div class="patient-list">
          <div
            v-for="{ person: patient, policies } in patients"
            :key="patient.id"
            class="patient-item"
            :class="{ active: selectedPatient && patient.id === selectedPatient.id }"
            @click="setPatient({ patient, policies })"
          >
            <!-- :class="{ active: patient.id === checkup.patientId }" -->
            <div
              v-if="patient.isVip || patient.isTop || patient.isGosuslugi || patient.isMango"
              class="patient-statuses"
            >
              <icon-vip
                v-if="patient.isVip"
                class="is-vip"
              />

              <icon-top
                v-if="patient.isTop"
                class="is-top"
              />

              <icon-gosuslugi
                v-if="patient.isGosuslugi"
                class="is-gosuslugi"
              />

              <icon-mango
                v-if="patient.isMango"
                class="is-mango"
              />
            </div>
            <div class="patient-info">
              <div class="patient-name">
                {{ patient.lastName }}
                {{ patient.firstName }}
                {{ patient.middleName }}
              </div>
              <div class="patient-row">
                <div class="patient-phone">
                  {{ patient.phoneNumber || '-' }}
                </div>
                <div class="patient-type">
                  {{ patient.isDMS ? 'ДМС' : '' }}
                  {{ patient.isDMS && patient.isOMS ? ', ' : '' }}
                  {{ patient.isOMS ? 'ОМС' : '' }}
                </div>
              </div>

              <div class="patient-birth">
                <!-- {{ parseDate(patient.birthDate) || '-' }} -->
              </div>
            </div>
          </div>
        </div>
      </custom-scrollbar>
    </div>

    <template v-if="selectedPatient">
      <div class="crm-form-row">
        <base-input
          v-model="selectedPatient.lastName"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
          }"
          class="crm-form-field"
          :disabled="disabledData.lastName"
          label="Фамилия"
          placeholder="Введите фамилию"
        />
        <base-input
          v-model="selectedPatient.firstName"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
          }"
          class="crm-form-field"
          :disabled="disabledData.firstName"
          label="Имя"
          placeholder="Введите имя"
        />
        <base-input
          v-model="selectedPatient.middleName"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
          }"
          class="crm-form-field"
          :disabled="disabledData.middleName"
          label="Отчество (при наличии)"
          placeholder="Введите отчество"
        />
        <div
          class="crm-form-field"
        >
          <div
            class="crm-form-block"
            :style="{
              width: '180px',
            }"
          >
            <p class="crm-label">
              Дата рождения
            </p>
            <base-date-picker
              v-model="selectedPatient.birthDate"
              :disabled="disabledData.birthDate"
              :clearable="false"
            />
          </div>
        </div>
      </div>

      <div class="crm-form-row">
        <base-input
          v-model="selectedPatient.city"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="Город"
          placeholder="Введите название"
          @input="setClinicQueryToCity"
        />
        <base-input
          v-model="selectedPatient.phoneNumber"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="Номер телефона"
          placeholder="Введите номер телефона"
        />
        <base-input
          v-model="selectedPatient.email"
          :input-style="{
            padding: '11px 10px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          }"
          class="crm-form-field"
          label="E-mail"
          placeholder="Введите email"
          :mask="emailMask"
          :guide="false"
          :error="emailInvalid"
          :errors="['Недопустимое значение']"
        />
        <div class="crm-form-field" />
      </div>
    </template>
  </div>
</template>

<script>
import emailMask from 'text-mask-addons/dist/emailMask';
import { emailValidate } from '@/helpers/utils';

import {
  parseISO,
  // formatISO,
  format,
} from 'date-fns';

import {
  BaseInput,
  BaseButton,
  BaseDatePicker,
} from '@/components/base';

import IconSearch from 'assets/images/search.svg';
import IconMango from 'assets/images/icon_mango.svg';
import IconGosuslugi from 'assets/images/icon_gosuslugi.svg';
import IconVip from 'assets/images/icon_vip.svg';
import IconTop from 'assets/images/icon_top.svg';

export default {
  name: 'PatientData',
  components: {
    IconSearch,
    IconMango,
    IconGosuslugi,
    IconVip,
    IconTop,
    BaseInput,
    BaseButton,
    BaseDatePicker,
  },
  data() {
    return {
      searchData: {
        companyName: null,
        programName: null,
        subprogramId: null,
        policyCode: null,
      },
      disabledData: {
        lastName: false,
        firstName: false,
        middleName: false,
        birthDate: false,
      },
      isFamilySubprogram: false,
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      patients: [],
      emailMask,
    };
  },
  computed: {
    selectedPatient() {
      return this.$store.state.Analysis.patient;
    },
    emailInvalid() {
      return !!this.selectedPatient.email && !emailValidate(this.selectedPatient.email);
    },
  },
  async created() {
    const data = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceData = data;
    this.insuranceCompanies = data.map(({ company: { id, title } }) => ({ id, title }));
  },
  methods: {
    setInsuranceEntities() {
      if (!this.searchData.companyName) return;

      const companyObject = this.insuranceData
        .filter(({ company: { title } }) => title === this.searchData.companyName)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;

      if (!this.searchData.programName) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { name } }) => name === this.searchData.programName)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    changeCompany() {
      this.searchData.programName = null;
      this.searchData.subprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
      this.patients = [];
      this.isFamilySubprogram = false;
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.searchData.subprogramId = null;
      this.insuranceSubprograms = [];
      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
      this.patients = [];
      this.isFamilySubprogram = false;
      this.setInsuranceEntities();
    },
    async changeSubrogram(id) {
      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
      this.patients = [];
      this.isFamilySubprogram = false;

      if (id) {
        const subprogram = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH, id);
        this.isFamilySubprogram = subprogram.subProgramType === 1;
      }
    },
    changePolicy() {
      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
      this.patients = [];
    },
    parseDate(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    setClinicQueryToCity(query) {
      this.$store.commit(this.$types.ANALYSIS_CLINIC_QUERY_SET, query);
    },
    async searchPatient() {
      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
      this.patients = [];

      const { subprogramId, policyCode } = this.searchData;

      const filter = {
        subProgramId: subprogramId,
        policyCode,
      };

      // filter deleted persons
      const patients = (await this.$store.dispatch(this.$types.PATIENTS_FETCH, { take: 100, filter }))
        .filter((patient) => !patient.person.wasDeleted);

      this.patients = patients;

      // // filter non main persons if non family subprogram
      // this.patients = this.isFamilySubprogram ? patients : patients.filter((patient) => !patient.person.parentId);
    },
    setPatient(patientFullData) {
      if (this.selectedPatient?.id === patientFullData.patient.id) {
        this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, null);
        return;
      }

      const {
        patient: {
          id,
          email,
          phoneNumber,
          birthDate,
          firstName,
          lastName,
          middleName,
          city,
          // policies,
        },
        policies,
      } = patientFullData;

      const policyId = policies.filter((p) => p.policyId.includes(this.searchData.policyCode))[0].id;

      const patientData = {
        id,
        email,
        phoneNumber,
        birthDate: parseISO(birthDate),
        firstName,
        lastName,
        middleName,
        city,
        policyId,
      };

      this.$store.commit(this.$types.ANALYSIS_PATIENT_SET, patientData);
      this.$store.commit(this.$types.ANALYSIS_PATIENT_FULL_DATA_SET, patientFullData);
      this.$store.commit(this.$types.ANALYSIS_CLINIC_QUERY_SET, city || '');

      this.disabledData = {
        lastName: !!lastName,
        firstName: !!firstName,
        middleName: !!middleName,
        birthDate: !!birthDate,
      };
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-form-title {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #979797;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
  }
}

.crm-form-row {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

::v-deep.crm-form-field {
  display: flex;
  flex: 1;

  &:not(:first-child) {
    margin-left: 20px;
  }

  &.w-25 {
    width: 25%;
    max-width: calc((100% - 60px)/ 4);
  }

  &.w-33 {
    width: 33.3%;
    max-width: calc((100% - 40px)/ 3);
  }

  &.w-50 {
    width: 25%;
    max-width: calc((100% - 20px)/ 2);
  }

  .crm-form-block {
    width: 100%;
  }

  .crm-label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    font-weight: 400;
  }
  .crm-base-input {
    height: 40px;
  }
}

.crm-search-button {
  padding: 0;
  height: 40px;
  width: 60px;
  align-self: flex-end;
  border-radius: 10px;
  margin-left: 20px;

  .crm-icon-search {
    path {
      fill: #ffffff;
    }
  }
}

.patient-list {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  grid-auto-columns: calc(33% - 5px);
  overflow-x: auto;
  padding-bottom: 10px;
}

::v-deep.patient-item {
  cursor: pointer;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  display: flex;

  &.active {
    border: 1px solid #6E88F3;
  }

  &:hover {
    border: 1px solid #6E88F3;
  }
}

.patient-name {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.patient-row {
  display: flex;
  margin-top: 10px;
}

.patient-phone {
  font-size: 14px;
  line-height: 18px;
  color: #908F8D;
  margin-right: auto;
}

.patient-type {
  font-size: 14px;
  line-height: 18px;
  color: #908F8D;
  margin-left: 10px;
}

.patient-birth {
  font-size: 14px;
  line-height: 18px;
  color: #908F8D;
  margin-top: 5px;
}

.patient-statuses {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 15px;

  .is-gosuslugi, .is-mango, .is-vip, .is-top {
    margin-top: 10px;
    margin-bottom: auto;
  }
}
</style>
