<template>
  <div class="consultation-detail">
    <custom-scrollbar>
      <div class="crm-inner">
        <div class="crm-title">
          Информация о консультации
        </div>
        <div class="crm-subtitle">
          ФИО пациента
        </div>
        <div class="full-name">
          {{ patientFullname }}
        </div>
        <div class="crm-subtitle">
          Текст консультации
        </div>
        <div class="text-body">
          {{ consultation.body }}
        </div>

        <div class="crm-line" />
        <div class="crm-row crm-mt crm-align-center">
          <div class="crm-column">
            <div
              class="crm-status"
              :class="statusColor"
            >
              {{ statusText }}
            </div>
          </div>
          <div class="crm-column width-stretch d-flex align-items-center">
            <div class="font-weight-bold">
              <span v-if="consultation.doctorSpecialization.doctor.settings.medicalInsuranceType === 0">
                ДМС
              </span>

              <span v-if="consultation.doctorSpecialization.doctor.settings.medicalInsuranceType === 1">
                ОМС
              </span>

              <div v-if="consultation.doctorSpecialization.doctor.settings.medicalInsuranceType === 2">
                <div>
                  ДМС
                </div>
                <div class="mt-1">
                  ОМС
                </div>
              </div>
            </div>

            <div class="ml-3">
              <div class="specialization">
                {{ consultation.doctorSpecialization.specialization.title }}
              </div>
              <div class="doctor">
                {{ doctorFullname }}
              </div>
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Номер телефона
            </div>
            <div class="value crm-bold crm-nowrap">
              {{ consultation.doctorSpecialization.doctor.phoneNumber || '-' }}
            </div>
          </div>
        </div>

        <b-table
          v-if="consultation.doctorClinics.length"
          class="bg-white mb-4 mt-4"
          bordered
          :fields="fields"
          :items="consultation.doctorClinics"
        >
          <template #cell(responsibles)="row">
            <div v-if="row.value.length">
              <div
                v-for="responsible in row.value"
                :key="responsible"
                class="mb-1"
              >
                {{ responsible }}
              </div>
            </div>
            <div v-else>
              Ответственные лица не найдены
            </div>
          </template>
        </b-table>

        <base-dropdown
          v-if="consultation.protocol"
          header="Протокол"
          :open="false"
          :show-line="false"
        >
          <div
            class="protocol-info"
          >
            <div class="crm-subtitle">
              Рекомендации
            </div>
            <div class="text-body">
              {{ consultation.protocol.recomendation || '-' }}
            </div>
            <div class="crm-subtitle">
              Жалобы
            </div>
            <div class="text-body">
              {{ consultation.protocol.anamnesis || '-' }}
            </div>
            <div class="crm-subtitle">
              МКБ
            </div>
            <div class="text-body">
              <template v-if="consultation.protocol.mkB10s.length">
                <div
                  v-for="(mkb, index) in consultation.protocol.mkB10s"
                  :key="index"
                >
                  {{ mkb.name }} - {{ mkb.code }}
                  ;<br>
                </div>
              </template>
              <template v-else>
                -
              </template>
            </div>
          </div>
          <div class="crm-line" />
        </base-dropdown>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Время создания
            </div>
            <div class="crm-value">
              {{ createDate }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Взято в работу
            </div>
            <div class="crm-value">
              {{ takeOutDate }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Запись на
            </div>
            <div class="crm-value">
              {{ actionDate }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Закрыто
            </div>
            <div class="crm-value">
              {{ closeDate }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Часовой пояс врача
            </div>
            <div class="crm-value">
              {{ timeZoneInfo }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              ID консультации
            </div>
            <div class="crm-value">
              {{ consultation.id }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Стоимость консультации
            </div>
            <div class="crm-value">
              {{ consultation.cost ? consultation.cost + ' ₽' : '-' }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Применённый промокод
            </div>
            <div class="crm-value">
              {{ consultation.promocode || '-' }}
            </div>
          </div>
        </div>
        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Платформа
            </div>
            <div class="crm-value">
              {{ clientTypeText }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Способ связи
            </div>
            <div class="crm-value">
              {{ communicationMethods[consultation.communicationMethod] }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Основание
            </div>
            <div class="crm-value">
              {{ parentDocumentType }}
            </div>
          </div>
        </div>
        <div class="crm-row">
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий доктора
            </div>
            <div class="crm-value">
              {{ consultation.doctorComment || '-' }}
            </div>
          </div>
          <div class="crm-column">
            <div class="crm-subtitle">
              Комментарий пациента
            </div>
            <div class="crm-value">
              {{ consultation.patientComment || '-' }}
            </div>
          </div>
        </div>
        <div
          v-if="consultation.status === 0"
          class="crm-buttons-wrapper"
        >
          <base-button
            v-if="consultation.status === 0 || consultation.status === 4"
            :type="$const.WARNING_SECONDARY_BUTTON"
            class="crm-button crm-cancel-button"
            @click="openCancelModal"
          >
            Отменить консультацию
          </base-button>
          <base-button
            v-if="consultation.status === 0"
            :type="$const.PRIMARY_BUTTON"
            class="crm-button transfer-button"
            @click="openTransferModal"
          >
            Перенести консультацию
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { formatTimeZone } from '@/helpers/utils';
import { parseISO, format } from 'date-fns';
import { mixinRoles } from '@/mixins';

import { BaseButton, BaseDropdown } from '@/components/base';

export default {
  name: 'PatientDetailConsultation',
  components: {
    BaseButton,
    BaseDropdown,
  },
  mixins: [mixinRoles],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      consultationStatuses: {
        success: [0],
        pending: [4, 1, 3],
        close: [9, 8, 6, 7, 2],
      },
      statuses: {
        0: {
          title: 'Новые',
          value: 'new',
        },
        1: {
          title: 'В работе',
          value: 'work-in-progress',
        },
        2: {
          title: 'Закрытые',
          value: 'closed',
        },
        3: {
          title: 'Консилиум',
          value: 'consilium',
        },
        4: {
          title: 'Ожидание оплаты',
          value: 'waiting-payment',
        },
        6: {
          title: 'Отмена',
          value: 'closed',
        },
        7: {
          title: 'Ожидание оплаты истекло',
          value: 'waiting-payment-timeout',
        },
        8: {
          title: 'Отменено пациентом',
          value: 'closed',
        },
        9: {
          title: 'Отменено доктором',
          value: 'closed',
        },
      },
      clientTypes: {
        0: 'Неизвестно',
        1: 'Android приложение',
        2: 'iOS приложение',
        3: 'Веб-приложение',
        4: 'CRM',
        5: 'Запись врачом',
        11: 'Android приложение FoodFessional Generation',
        12: 'iOS приложение FoodFessional Generation',
        101: 'Внешний сервис',
        102: 'СМЭВ',
        104: 'Дистанционный мониторинг',
        111: 'МИС',
      },
      communicationMethods: {
        0: 'Текст',
        1: 'Аудио',
        2: 'Видео',
      },
      consultationDocumentTypes: {
        0: 'Консультация',
        1: 'Направление',
        2: 'Заявка на консультацию',
      },
      fields: [
        {
          key: 'title',
          label: 'Название клиники',
        },
        {
          key: 'responsibles',
          label: 'Ответственные',
        },
      ],
    };
  },
  computed: {
    timeZoneInfo() {
      return this.consultation.timeZone ? formatTimeZone(this.consultation.timeZone) : '-';
    },
    createDate() {
      return this.consultation.createDate ? `${format(parseISO(this.consultation.createDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    takeOutDate() {
      return this.consultation.takeOutDate ? `${format(parseISO(this.consultation.takeOutDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    actionDate() {
      return this.consultation.slotDate ? `${format(parseISO(this.consultation.slotDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    closeDate() {
      return this.consultation.closeDate ? `${format(parseISO(this.consultation.closeDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    statusColor() {
      if (this.consultationStatuses.success.includes(this.consultation.status)) return 'green';
      if (this.consultationStatuses.pending.includes(this.consultation.status)) return 'yellow';
      if (this.consultationStatuses.close.includes(this.consultation.status)) return 'red';

      return '';
    },
    statusText() {
      return this.statuses[this.consultation.status].title;
    },
    patientFullname() {
      const patient = this.patientId === this.consultation.patient.id
        ? this.consultation.patient
        : this.consultation.targetPatient;
      const { middleName, firstName, lastName } = patient;
      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    doctorFullname() {
      const { middleName, firstName, lastName } = this.consultation.doctorSpecialization.doctor;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    clientTypeText() {
      if (this.consultation.clientType === 0 && this.consultation.authorPerson) {
        const doctor = this.consultation.authorPerson;
        const doctorFullname = `${doctor.lastName} ${doctor.firstName} ${doctor.middleName}`;
        return `Запись врачом: ${doctorFullname}`;
      }

      return this.clientTypes[this.consultation.clientType];
    },
    parentDocumentType() {
      return this.consultation.parentDocumentType ? this.consultationDocumentTypes[this.consultation.parentDocumentType] : '-';
    },
  },
  methods: {
    openTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationTransferModal',
        props: {
          consultationProp: this.consultation,
        },
      });
      // Bus.$emit('open-modal:consultation-transfer', this.consultation);
    },
    openCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationCancelModal',
        props: {
          consultation: this.consultation,
        },
      });
      // Bus.$emit('open-modal:consultation-cancel', this.consultation);
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-detail {
  box-sizing: border-box;
  height: 100%;
  flex: 1;
}

.crm-inner {
  height: 100%;
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
}
.crm-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 30px;
}
.crm-subtitle {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #707070;
}

.full-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 30px;
}

.text-body {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 20px;
}

.crm-line {
  height: 1px;
  background-color: #E0E0E0;
  width: 100%;
}

.crm-row {
  display: flex;

  & + & {
    margin-top: 20px;
  }

  &.crm-align-center {
    align-items: center;
  }

  &.crm-mt {
    margin-top: 30px;
  }
}

.crm-column {
  flex: 1;

  & + & {
    margin-left: 20px;
  }

}

.crm-status {
  text-align: center;
  border-radius: 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  padding: 0 10px;

  &.green {
    background: #e6ffd1;
    color: $green;
  }
  &.yellow {
    background: #FFE6D1;
    color: #F2994A;
  }
  &.red {
    background: #ffdede;
    color: $red;
  }
}

.crm-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 20px;
}

.crm-button {
  width: unset;
  padding-right: 30px;
  padding-left: 30px;
  margin-top: 10px;
  margin-left: 20px;
  font-weight: normal;
}

.specialization {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 5px;
  color: #4B62C1;
}

.doctor {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.crm-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &.crm-bold {
    font-weight: 600;
  }

  &.crm-nowrap {
    white-space: nowrap;
  }
}

::v-deep.base-dropdown-wrapper {
  margin-top: 30px;

  .crm-dropdown-row {
    height: 1px!important;
  }
}

.protocol-info {
  margin-top: 20px;
}
</style>
