import NProgress from 'nprogress/nprogress';
// import axios from 'axios';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.GET_USERS]({ commit }, {
    onlyEsiaAuthorized = false,
  }) {
    NProgress.start();
    commit(localTypes.IS_LOADING_TRUE);
    try {
      const params = {
        onlyEsiaAuthorized,
      };
      const { data } = await api.get('v2/account/users?take=2147483647', {
        params,
      });
      // console.log(data);
      commit(localTypes.USERS_SET, data);
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
      commit(localTypes.IS_LOADING_FALSE);
    }
  },
  async [localTypes.GET_USER_BY_ID](_, id) {
    NProgress.start();
    try {
      const { data } = await api.get(`v2/account?id=${id}`);
      return data;
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_USER_DEVICE_ID](_, id) {
    NProgress.start();
    try {
      const { data } = await api.get(`v2/person/devices?personId=${id}`);
      return data;
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.DELETE_USER_DEVICE](_, id) {
    NProgress.start();
    try {
      const { data } = await api.delete(`v2/person/devices?personId=${id}`);

      showMessage({
        type: 'success',
        message: 'Устройства пользователя успешно удалены',
      });
      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить устройства пользователя',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.USER_CREATE](_, params) {
    NProgress.start();
    try {
      await api.post('/v2/account/create', params);

      showMessage({
        type: 'success',
        message: 'Пользователь успешно создан',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать пользователя',
      });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.USER_CREATE_FOR_ESIA](_, params) {
    NProgress.start();
    try {
      await api.post('/v2/account/create-for-esia', params);

      showMessage({
        type: 'success',
        message: 'Пользователь успешно создан',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать пользователя',
      });
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.USER_DELETE](_, id) {
    NProgress.start();
    try {
      await api.delete(`v2/account?id=${id}`);
    } catch (e) {
      console.warn(e);
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.USER_EDIT](_, params) {
    NProgress.start();

    try {
      const data = await api.put('v2/account', params);

      showMessage({
        type: 'success',
        message: 'Данные о пользователе успешно изменены',
      });

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось изменить данные',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
