import NProgress from 'nprogress/nprogress';
// import axios from 'axios';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.QUEUE_FETCH](_, { id } = {}) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/DutyQueue/?id=${id}`);

      return data;
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить очередь',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUES_FETCH]({ commit }, { save } = {}) {
    NProgress.start();

    if (save) {
      commit(localTypes.QUEUES_SET, []);
    }

    try {
      const { data } = await api.get('v2/DutyQueue/list');

      if (save) {
        commit(localTypes.QUEUES_SET, data);
      }

      return data;
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список очередей',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUE_CREATE](_, params) {
    NProgress.start();

    try {
      await api.post('v2/DutyQueue', { ...params });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Очередь добавлена',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось добавить очередь',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUE_UPDATE](_, params) {
    NProgress.start();

    try {
      await api.put('v2/DutyQueue', { ...params });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Очередь обновлена',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить очередь',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUE_DELETE](_, queueId) {
    NProgress.start();

    try {
      await api.delete('v2/DutyQueue', { params: { id: queueId } });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Очередь удалена',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить очередь',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUES_TAGS_FETCH]({ commit }, { save } = {}) {
    NProgress.start();

    if (save) {
      commit(localTypes.QUEUES_TAGS_SET, []);
    }

    try {
      const { data } = await api.get('v2/DutyQueue/tag/list');

      if (save) {
        commit(localTypes.QUEUES_TAGS_SET, data);
      }

      return data;
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список очередей',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUES_TAG_CREATE](_, params) {
    NProgress.start();

    try {
      await api.post('v2/DutyQueue/tag', { ...params });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Тэг добавлен',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось добавить тэг',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUES_TAG_UPDATE](_, params) {
    NProgress.start();

    try {
      await api.put('v2/DutyQueue/tag', { ...params });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Тэг обновлен',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить тэг',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.QUEUES_TAG_DELETE](_, tagId) {
    NProgress.start();

    try {
      await api.delete(`v2/DutyQueue/tag/${tagId}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Тэг удален',
      });
    } catch (err) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить тэг',
      });
    } finally {
      NProgress.done();
    }
  },
};
