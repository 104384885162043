<template>
  <div class="promocode-edit">
    <custom-scrollbar>
      <div
        class="crm-container promocode-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить промокод <br>
          «<span>{{ promocode.code }}</span>»?
        </div>
        <div class="crm-wrapper-buttons">
          <!-- <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button> -->

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            @click="onClickDelete"
          >
            Удалить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PromocodeDeleteModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    promocode: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.PROMOCODE_DELETE, this.promocode.id);

        this.$store.commit(this.$types.PROMOCODE_REMOVE_BY_ID, this.promocode.id);
        this.$store.dispatch(this.$types.PROMOCODES_FETCH, true);
        this.$emit('vuedals:close');
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.promocode-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  span {
    color: $blue-dark;
    font-weight: 500;
  }
}
</style>
