<template>
  <div class="bg-white rounded">
    <div class="border border-bottom-0 p-3">
      <div class="appeal-block">
        <div class="appeal-column">
          <div class="appeal-row appeal-row--6">
            <div class="appeal-field">
              <div class="crm-input-label">
                ФИО пациента
              </div>
              <div
                v-if="appeal.person"
                class="appeal-text appeal-info-name"
              >
                {{ `${appeal.person.lastName || ''} ${appeal.person.firstName || ''} ${appeal.person.middleName || ''}` }}
              </div>
            </div>
            <div
              class="appeal-field"
            >
              <div>
                <div class="crm-input-label">
                  Номер телефона
                </div>
                <div
                  class="appeal-text"
                >
                  {{ appeal.person.phoneNumber || '—' }}
                  <icon-vip
                    v-if="appeal.person.isVip"
                    class="is-vip"
                  />
                  <icon-top
                    v-if="appeal.person.isTop"
                    class="is-top"
                  />
                </div>
              </div>
            </div>
            <div class="appeal-field">
              <div class="crm-input-label">
                Статус
              </div>
              <div
                class="appeal-text"
                :class="getStatusColor(appeal.status)"
              >
                {{ appealStatus }}
              </div>

              <template v-if="appeal.urgency || appeal.purpose === 2">
                <div class="crm-input-label mt-3">
                  Срочность
                </div>
                <div
                  class="appeal-text"
                  :class="appeal.urgency && 'red'"
                >
                  {{ appeal.urgency ? 'Срочно' : 'Не срочно' }}
                </div>
              </template>
            </div>
            <div class="appeal-field">
              <div class="crm-input-label">
                Цель обращения
              </div>
              <div class="appeal-text">
                {{ appealPurpose }}
              </div>
            </div>
            <div class="appeal-field">
              <div class="crm-input-label">
                Дата и время
              </div>
              <div class="appeal-text">
                {{ appealDate }}
              </div>
            </div>
            <div class="appeal-field">
              <div class="crm-input-label">
                ID обращения
              </div>
              <div class="appeal-text">
                {{ appeal.id }}
              </div>
            </div>
          </div>
        </div>
        <div class="appeal-buttons">
          <b-button
            v-if="checkFeatureAccess({ name: 'Обращения - Редактировать', url: '/appeals' })"
            v-b-tooltip.hover.left
            variant="primary"
            size="sm"
            class="p-1"
            title="Редактировать"
            @click="openAppealEditorModal"
          >
            <b-icon icon="pencil-square" />
          </b-button>

          <b-button
            v-b-tooltip.hover.left
            variant="success"
            size="sm"
            class="mt-2 p-1"
            title="История изменений"
            @click="openAppealHistoryModal"
          >
            <b-icon icon="book" />
          </b-button>
        </div>
      </div>
      <template v-if="isInfoShow">
        <div class="hline" />
        <template v-if="appeal.person && (!appeal.type || (appeal.type && appeal.type.id !== 22))">
          <div class="appeal-block">
            <div class="appeal-column">
              <div class="appeal-row appeal-row--6">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Дата рождения
                  </div>
                  <div class="appeal-text">
                    {{ birthDateFormated }}
                  </div>
                </div>
                <div class="appeal-field appeal-field--age">
                  <div class="crm-input-label">
                    Возраст
                  </div>
                  <div class="appeal-text">
                    {{ personAge }}
                  </div>
                </div>
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Город
                  </div>
                  <div class="appeal-text">
                    {{ appeal.person.city }}
                  </div>
                </div>
                <div class="appeal-field">
                  <div
                    v-if="appeal.type"
                    class="type"
                  >
                    <div class="crm-input-label">
                      Тип обращения
                    </div>
                    <div
                      v-if="appeal.type.title"
                      class="appeal-text"
                    >
                      {{ appeal.type.title }}
                    </div>
                  </div>
                </div>
                <div class="appeal-field">
                  <template>
                    <div class="crm-input-label">
                      Другой тип обращения
                    </div>
                    <div
                      v-if="isTreatmentCard && appeal.type.id === 42"
                      class="appeal-text"
                    >
                      {{ appeal.typeAnotherText }}
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <div class="patient-detail-btn">
              <b-button
                v-b-tooltip.hover.left
                variant="primary"
                size="sm"
                class="p-1"
                title="Детальная информация о пациенте"
                @click="openPatientDetail"
              >
                <b-icon icon="person-lines-fill" />
              </b-button>
            </div>
          </div>
          <div
            v-if="appeal.selectedPolicy"
            class="patient-data"
          >
            <div class="hline" />
            <div class="patient-data--main">
              <div
                v-if="appeal.purpose !== 3"
                class="policy"
              >
                <div class="crm-input-label">
                  Полис
                </div>
                <policy-item
                  :policy="appeal.selectedPolicy"
                />
              </div>
              <div
                v-if="appeal.hasIndemnityLetters"
                class="gp"
              >
                <div
                  class="crm-input-label"
                  style="margin-top: 10px"
                >
                  Гарантийные письма
                </div>
                <div
                  v-if="!checkRoleAccess(['polis.operator.dop', 'Dis.monitoring.admin', 'admin.Dis.monitoring'])"
                  class="create-gp-btn crm-button letter-history-btn without-border transparent"
                  @click="openIndemnityBtnHandler"
                >
                  История ГП
                </div>
              </div>
            </div>
          </div>
          <div class="hline" />
        </template>
        <div class="appeal-detail">
          <template
            v-if="isInformationCard && appeal.type && (appeal.type.id === 21 || appeal.type.id === 22)"
            class="information-card"
          >
            <div class="crm-row">
              <div class="information-card--appeal-city">
                <div class="crm-input-label">
                  Город
                </div>
                <div class="appeal-text">
                  {{ appeal.city.name }}
                </div>
              </div>
              <div class="appeal-clinic">
                <div class="crm-input-label">
                  Клиника
                </div>
                <div
                  v-if="appeal.clinic && appeal.clinic.title"
                  class="appeal-text"
                >
                  {{ appeal.clinic.title }}
                </div>
              </div>
            </div>
          </template>

          <template v-if="isCheckupCard">
            <div class="checkup-card crm-row">
              <div class="checkup-card--profile">
                <div class="crm-input-label">
                  Профиль чекапа
                </div>
                <div
                  v-if="appeal.checkupProfile.title"
                  class="appeal-text"
                >
                  {{ appeal.checkupProfile.title }}
                </div>
              </div>
              <div class="checkup-card--date">
                <div class="crm-input-label">
                  Дата чекапа
                </div>
                <div class="appeal-text">
                  {{ checkupDate }}
                </div>
              </div>
              <div class="checkup-card--clinic">
                <div class="crm-input-label">
                  Клиника
                </div>
                <div
                  v-if="appeal.clinic && appeal.clinic.title"
                  class="appeal-text"
                >
                  {{ appeal.clinic.title }}
                </div>
              </div>
            </div>
            <div
              v-if="!isClinicNetworkCheck"
              class="checkup-card--rejection-reason"
            >
              <div class="crm-input-label">
                Причина отказа от записи в МиД
              </div>
              <div class="appeal-text">
                {{ appeal.rejectionReason }}
              </div>
            </div>
          </template>

          <template v-if="isTreatmentCard">
            <div class="treatment-card crm-row">
              <div class="treatment-card--provisional-diagnosis">
                <div class="crm-input-label">
                  Предварительный диагноз
                </div>
                <div class="appeal-text">
                  {{ appeal.provisionalDiagnosis }}
                </div>
              </div>
              <div class="treatment-card--clinic">
                <div class="crm-input-label">
                  Клиника
                </div>
                <div
                  v-if="appeal.clinic && appeal.clinic.title"
                  class="appeal-text"
                >
                  {{ appeal.clinic.title }}
                  <br>
                  {{ appeal.clinic.address }}
                </div>
              </div>
            </div>
            <div class="treatment-card crm-row">
              <div class="treatment-card--checkup">
                <div class="crm-input-label">
                  Чекап
                </div>
                <div class="appeal-text">
                  {{ checkupById }}
                </div>
              </div>
              <div class="treatment-card--mkb-code">
                <div class="crm-input-label">
                  Код по МКБ
                </div>
                <div class="appeal-text">
                  {{ appeal.mkbCode }}
                </div>
              </div>
              <div class="treatment-card--options">
                <div class="crm-input-label">
                  Дополнительные опции
                </div>
                <div
                  v-if="appeal.urgency"
                  class="appeal-text"
                >
                  Срочность
                </div>
              </div>
            </div>
          </template>

          <template v-if="isHospitalizationCard">
            <div class="hospitalization-card crm-row">
              <div class="hospitalization-card--provisional-diagnosis">
                <div class="crm-input-label">
                  Предварительный диагноз
                </div>
                <div class="appeal-text">
                  {{ appeal.provisionalDiagnosis }}
                </div>
              </div>
              <div class="hospitalization-card--curators-phone">
                <div class="crm-input-label">
                  Телефон куратора
                </div>
                <div class="appeal-text">
                  {{ appeal.curatorsPhone }}
                </div>
              </div>
            </div>
            <div class="hospitalization-card crm-row">
              <div class="hospitalization-card--clinic">
                <div class="crm-input-label">
                  Клиника
                </div>
                <div
                  v-if="appeal.clinic && appeal.clinic.title"
                  class="appeal-text"
                >
                  {{ appeal.clinic.title }}
                </div>
              </div>
              <div class="hospitalization-card--hospitalized-from">
                <div class="crm-input-label">
                  Откуда госпитализирован
                </div>
                <div class="appeal-text">
                  {{ appeal.hospitalizedFrom }}
                </div>
              </div>
            </div>
            <div
              v-if="!isClinicNetworkCheck"
              class="hospitalization-card--rejection-reason"
            >
              <div class="crm-input-label">
                Причина отказа от записи в МиД
              </div>
              <div class="appeal-text">
                {{ appeal.rejectionReason }}
              </div>
            </div>
          </template>
        </div>
        <div class="crm-comment">
          <div class="crm-input-label">
            Комментарий
          </div>
          <div class="appeal-text">
            {{ appeal.comment }}
          </div>
        </div>
        <div class="footer">
          <div class="responsible">
            <div class="crm-input-label">
              Ответственный
            </div>
            <div class="appeal-text">
              {{ appeal.responsible ? appeal.responsible.displayName : null }}
            </div>
          </div>
          <div class="files">
            <div class="crm-input-label">
              Файлы
            </div>
            <div v-if="appeal.fileIds.length > 0">
              <div
                v-for="(file, index) in appeal.fileIds"
                :key="index"
                class="crm-file-item"
                @click="downloadFile(file)"
              >
                <document-icon class="document-icon" />
                {{ file.name }}
              </div>
            </div>
            <div v-else>
              —
            </div>
          </div>
        </div>
        <div
          v-if="!isClinicNetworkCheck"
          class="hospitalization-card--rejection-reason"
        >
          <div class="crm-input-label">
            Причина отказа от записи в МиД
          </div>
          <div>{{ appeal.rejectionReason }}</div>
        </div>
        <template v-if="appeal.reconciliations.length">
          <div
            v-for="match in appeal.reconciliations"
            :key="match.id"
          >
            <div class="hline" />
            <div class="appeal-block-title">
              <span>Согласование врача-куратора</span>
            </div>
            <div class="appeal-block">
              <div class="appeal-column">
                <div class="appeal-row appeal-row--5">
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      ID согласования
                    </div>
                    <div class="appeal-text">
                      {{ match.id }}
                    </div>
                  </div>
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Дата и время
                    </div>
                    <div class="appeal-text">
                      {{ reconciliationsDateFormatted(match) }}
                    </div>
                  </div>
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Статус
                    </div>
                    <div class="status-text">
                      {{ appealReconciliationsStatusCheck(match) }}
                    </div>
                  </div>
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Инициатор согласования
                    </div>
                    <div class="appeal-text">
                      {{ appealReconciliationsInitiatorCheck(match) }}
                    </div>
                  </div>
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Название клиники
                    </div>
                    <div class="appeal-text">
                      {{ match.clinic ? match.clinic.name : '' }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Согласованные услуги
                  </div>
                  <div class="appeal-text">
                    {{ match.agreedServices }}
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Несогласованные услуги
                  </div>
                  <div class="appeal-text">
                    {{ match.notAgreedServices }}
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Причина отказа
                  </div>
                  <div class="appeal-text">
                    {{ match.regectionReason }}
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Комментарий врача-куратора
                  </div>
                  <div class="appeal-text">
                    {{ match.doctorComment }}
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-field">
                  <div class="crm-input-label">
                    Комментарий для оператора
                  </div>
                  <div class="appeal-text">
                    {{ match.commentForOperator }}
                  </div>
                </div>
              </div>
            </div>
            <div class="reconciliationsColumnWrapper">
              <div class="appeal-column">
                <div class="appeal-row appeal-row--2">
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Ответственный за согласование
                    </div>
                    <div class="appeal-text">
                      {{ match.responsible.displayName }}
                    </div>
                  </div>
                  <div class="appeal-field">
                    <div class="crm-input-label">
                      Файлы
                    </div>
                    <div class="appeal-text">
                      <div v-if="match.files.length > 0">
                        <div
                          v-for="(file, index) in match.files"
                          :key="index"
                          class="crm-file-item"
                          @click="downloadFile(file)"
                        >
                          <document-icon class="document-icon" />
                          {{ file.name }}
                        </div>
                      </div>
                      <div v-else>
                        —
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>

    <div
      style="cursor: pointer;"
      class="bg-primary rounded-bottom d-flex justify-content-center p-2"
      @click="isInfoShow = !isInfoShow"
    >
      <b-icon
        icon="caret-down-fill"
        variant="light"
        :rotate="isInfoShow ? 180 : 0"
      />
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { mixinRoles } from '@/mixins';

import { find } from 'lodash';
import {
  format,
  parseISO,
} from 'date-fns';

import { saveFile } from '@/helpers/utils';
import { api } from '@/helpers/api';

import PolicyItem from '@/components/Appeals/PolicyItem';

import IconVip from 'assets/images/icon_vip.svg';
import IconTop from 'assets/images/icon_top.svg';
import DocumentIcon from 'assets/images/document_icon.svg';

export default {
  name: 'AppealInfo',
  components: {
    IconVip,
    IconTop,
    PolicyItem,
    DocumentIcon,
  },
  mixins: [mixinRoles],
  props: {
    appeal: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      appealStatusOptions: [
        { id: 0, title: 'Передано' },
        { id: 1, title: 'Отложено' },
        { id: 2, title: 'Закрыто' },
      ],
      appealReconciliationsStatusOptions: [
        { id: 0, title: 'Не выбрано' },
        { id: 1, title: 'В работе' },
        { id: 2, title: 'Готов' },
        { id: 3, title: 'Передано' },
        { id: 4, title: 'Отложено' },
      ],
      appealReconciliationsInitiatorOptions: [
        { id: 0, title: 'Клиника' },
        { id: 1, title: 'Пациент' },
        { id: 2, title: 'Другое' },
      ],
      purposeList: [
        { id: 0, title: 'Информационное' },
        { id: 1, title: 'Чекап' },
        { id: 2, title: 'Лечение / плановая госпитализация' },
        { id: 3, title: 'Экстренная госпитализация' },
      ],

      sexList: [
        { id: true, title: 'Мужской' },
        { id: false, title: 'Женский' },
      ],

      checkupsList: [
        { id: 0, title: 'Пройден' },
        { id: 1, title: 'Не пройден' },
        { id: 2, title: 'Пройден частично' },
      ],

      isInfoShow: false,
    };
  },
  computed: {
    checkupById() {
      return this.appeal.checkup ? find(this.checkupsList, { id: this.appeal.checkup }).title : '';
    },
    appealStatus() {
      return find(this.appealStatusOptions, { id: this.appeal.status }).title;
    },
    appealPurpose() {
      return find(this.purposeList, { id: this.appeal.purpose }).title;
    },
    appealDate() {
      return format(parseISO(this.appeal.dateTime), 'dd.MM.yyyy HH:mm');
    },

    birthDateFormated() {
      return this.appeal.person.birthDate ? format(parseISO(this.appeal.person.birthDate), 'dd.MM.yyyy') : '–';
    },

    checkupDate() {
      return format(parseISO(this.appeal.checkupDate), 'dd.MM.yyyy');
    },

    personSex() {
      return this.appeal.person.sex ? 'Мужской' : 'Женский';
    },

    isInformationCard() {
      return this.appeal.purpose === 0;
    },

    isCheckupCard() {
      return this.appeal.purpose === 1;
    },

    isTreatmentCard() {
      return this.appeal.purpose === 2;
    },

    isHospitalizationCard() {
      return this.appeal.purpose === 3;
    },

    personAge() {
      if (!this.appeal.person.birthDate) return '—';
      const date = new Date(this.appeal.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age < 0 ? 0 : age;
    },
    isClinicNetworkCheck() {
      return this.appeal.clinic?.clinicNetwork ? this.appeal.clinic.clinicNetwork.title === 'Мать и дитя' : '–';
    },
  },

  methods: {
    getStatusColor(status) {
      if (status === 0) return 'green';
      if (status === 1) return 'yellow';
      if (status === 2) return 'red';

      return '';
    },
    openAppealHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'AppealHistoryModal',
        props: {
          appealId: this.appeal.id,
        },
      });
      // Bus.$emit('open-modal:appeal-history', this.appeal.id);
    },
    async downloadFile(fileToDownload) {
      const { data: file } = await api.get(`File/${fileToDownload.id || fileToDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileToDownload.name });
    },
    appealReconciliationsStatusCheck(item) {
      return find(this.appealReconciliationsStatusOptions, { id: item.status }).title;
    },
    appealReconciliationsInitiatorCheck(item) {
      return find(this.appealReconciliationsInitiatorOptions, { id: item.initiator })?.title || '–';
    },

    reconciliationsDateFormatted(item) {
      return format(parseISO(item.createDate), 'dd.MM.yyyy HH:mm');
    },
    openAppealEditorModal() {
      Bus.$emit('open-modal:appeal-editor', {
        initAppealData: this.appeal,
        initPatientData: {
          person: this.appeal.person,
          policies: this.appeal.person.policies,
        },
      });
    },
    openPatientDetail() {
      Bus.$emit('open-modal:patient-detail', this.appeal.person.id);
    },
    openIndemnityBtnHandler() {
      Bus.$emit('open-modal:indemnity-history-modal', {
        personId: this.appeal.person.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.edit-icon {
  cursor: pointer;
  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: #6E88F3;
    }
  }
}

::v-deep.history-icon {
  cursor: pointer;

  &:hover {
    rect {
      stroke: #6E88F3;
    }
  }
}

::v-deep.policy{
  width: 100%;
  .active-policy{
    width: 100%;
    margin: 0px;
    background: #E7F6EC;
  }
}

.crm-file-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
}

.document-icon {
  transform: scale(0.5);
  path {
    fill: $blue;
  }
}

.crm-control-icon {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}

.appeal-block-title{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
}

.hline {
  height: 1px;
  width: 100%;
  background-color: #E0E0E0;
  margin: 28px 0px;
}

.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

.appeal-block {
  width: 100%;
  display: flex;

  & + & {
    margin-top: 30px;
    padding-top: 30px;
    border-top: 1px solid #E0E0E0;
  }
}

.appeal-column {
  flex: 1;

  &--patient {
    flex: 2;
  }

  &--doctor {
    flex: 1;
  }

  &--date {
    flex: 3;
  }

  &--controls {
    flex: 0;
  }

  & + & {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 1px solid #E0E0E0;
  }
}

.appeal-row {
  display: grid;
  grid-auto-flow: column;
  column-gap: 30px;

  & + & {

    .consultation-field {
      padding-top: 20px;
    }
  }

  &--2 {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 0.9fr;
  }
  &--3 {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &--4 {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr 1fr 3fr;
  }
  &--4 :last-child {
    width: 100%;
  }
  &--5 {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr 1fr 1.1fr 1.9fr
  }
  &--6 {
    grid-auto-flow: unset;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}

.appeal-text {
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;

  &.green {
    color: $green;
  }
  &.yellow {
    color: #F2994A;
  }
  &.red {
    color: $red;
  }
}

.appeal-info-name{
  width: 100px;
}

.phone{
  margin-right: 76px;
}

.footer {
  margin-top: 28px;
  display: flex;

  .tasks {
    width: 200px;
  }
}

.appeal-detail {
  margin-bottom: 28px;
}

.information-card {
  margin-bottom: 28px;

  &--appeal-city {
    width: 550px;
  }
  .appeal-clinic {
    width: 300px;
  }
}

.checkup-card {
  &--profile {
    min-width: 375px;
    width: 375px;
  }

  &--date {
    min-width: 380px;
    width: 380px;
  }

  &--rejection-reason {
    margin-top: 28px;
  }
}

.treatment-card {
  &--provisional-diagnosis{
    width: 550px;
  }
  &--checkup {
    width: 200px;
  }
  &--mkb-code {
    width: 350px;
  }
}

.hospitalization-card {
  &--provisional-diagnosis, &--clinic{
    width: 550px;
  }
  &--rejection-reason {
    margin-top: 28px;
  }
}

.responsible {
  width: 350px;
}

.crm-input-label {
  color: #979797;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.status-text {
  color: $green;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.person-status {
  display: flex;
}

.create-gp-btn {
  display: flex;
  align-items: center;
  color: $blue;
  font-weight: 600;
  cursor: pointer;
}

.appeal-info {
  width: 100%;
  background: white;
  box-sizing: border-box;
  box-shadow: 0px 2px 24px rgba(50, 50, 50, 0.08), 0px 0px 2px rgba(50, 50, 50, 0.08);
  border-radius: 6px;
  color: #323232;
  margin-bottom: 10px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  .appeal-info-wrapper{
    padding: 30px;
  }
}
.status, .fio{
  width: 180px;
}
.purpose {
  width: 250px;
}
.appealID{
  width: 130px;
}
.date {
  width: 200px;
}

.phoneAndCity {
  width: 175px;
}

.birthDateAndAge {
  width: 180px;
}
.sexAndLimit {
  width: 250px;
}

.info-toggle-btn {
  background: rgba(110, 136, 243, 0.1);
  border-radius: 0;
  //position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100% !important;
  border: none;
}

.crm-icon-arrow-down {
  path {
    fill: #6E88F3;
  }

  &.reverse{
    transform: rotate(180deg);
  }
}

.reconciliationsColumnWrapper{
  margin-top: 28px;
}

.appeal-buttons {
  display: flex;
  flex-direction: column;

  .crm-icon + .crm-icon {
    margin-top: 10px;
  }
}
</style>
