<template>
  <div class="legal-person-page">
    <custom-scrollbar>
      <div class="legal-person-page-inner">
        <loading v-if="isLoading" />
        <template
          v-else
        >
          <div
            class="crm-container crm-container-title"
          >
            <router-link
              :to="{ path: '/legal-entities' }"
              class="arrow-left"
            >
              <indemnity-arrow-icon />
            </router-link>
            <span class="crm-title">
              Список юридических лиц {{ !isCreate ? '/ ' + legalPerson.name : '' }}
            </span>
          </div>
          <div class="crm-container page-wrapper">
            <legal-entity-details
              v-if="!isCreate && !isEdit"
              :legal-person="legalPerson"
              @edit="editBtnHandler"
            />
            <legal-entity-editor
              v-else
              :initial-data="legalPerson"
              :networks="networks"
              @edit="editBtnHandler"
            />
          </div>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import IndemnityArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import Loading from '@/components/Loading';
import LegalEntityDetails from '@/components/LegalEntities/LegalEntityDetails';
import LegalEntityEditor from '@/components/LegalEntities/LegalEntityEditor';

export default {
  name: 'LegalEntity',
  page: {
    title: 'CRM Doctis - Юридические лица клиник',
  },
  components: {
    IndemnityArrowIcon,
    Loading,
    LegalEntityDetails,
    LegalEntityEditor,
  },
  mixins: [mixinRoles],
  props: {
    isCreate: {
      type: Boolean,
      default: false,
    },
    legalPersonId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      legalPerson: null,
      isLoading: false,
      isEdit: false,
      networks: [],
    };
  },
  async created() {
    this.isLoading = true;
    if (!this.isCreate) {
      this.legalPerson = await this.$store.dispatch(this.$types.LEGAL_ENTITY_FETCH, { id: this.legalPersonId });
    }
    this.networks = await this.$store.dispatch(this.$types.NETWORK_LIST_FETCH);
    this.isLoading = false;
  },
  methods: {
    editBtnHandler() {
      this.isEdit = !this.isEdit;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-container-title {
  margin-bottom: 30px;
  min-height: 42px;
  display: flex;

  .arrow-left {
    margin-right: 15px;

    path {
      fill: #6E88F3,
    }
  }
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

.page-wrapper {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  width: 100%;
}

.legal-person-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100vh;
}

.legal-person-page-inner {
  padding: 30px 0;
}
</style>
