<template>
  <div class="package-wrapper">
    <div class="package-inner">
      <template v-if="isLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <template v-else>
        <div>
          <div class="crm-label">
            Название пакета клиник
          </div>
          <base-input
            v-model="packageName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
              marginBottom: '15px',
            }"
            class="crm-form-field"
            placeholder="Введите название"
          />

          <div class="crm-label">
            Клиники
          </div>
          <div class="crm-form-field-filter clinics-filter">
            <base-checkbox-select
              :container-class="'clinics-filter'"
              placeholder="Выберите клиники"
              fluid
            >
              <template
                v-slot:default="{ query }"
              >
                <base-checkbox-list
                  v-model="selectedClinics"
                  :query="query"
                  :choices="params.allClinicsByGroup"
                />
              </template>
            </base-checkbox-select>
          </div>
          <div
            v-if="selectedClinics && selectedClinics.length"
            class="crm-item-scrollbar-wrapper"
          >
            <custom-scrollbar>
              <div
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(clinic, idx) in selectedClinics"
                  :key="idx"
                  :speciality="clinic"
                  edit
                  class="crm-item"
                  @delete="deleteSelectedPackageClinic(clinic)"
                />
              </div>
            </custom-scrollbar>
          </div>
        </div>
        <div class="crm-container-buttons">
          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="createPackage"
          >
            <span>Сохранить</span>
          </base-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseCheckboxSelect,
  BaseCheckboxList,
  BaseInput,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';
import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';
// import { ca } from 'date-fns/locale';

export default {
  name: 'ClinicPackageModal',
  components: {
    BaseButton,
    Preloader,
    BaseCheckboxSelect,
    BaseCheckboxList,
    DoctorSpecialityItem,
    BaseInput,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedClinics: [],
      packageName: '',
      isLoading: false,
    };
  },
  methods: {
    deleteSelectedPackageClinic(clinic) {
      this.selectedClinics = this.selectedClinics.filter((item) => item.id !== clinic.id);
    },

    async createPackage() {
      if (!this.packageName || this.selectedClinics.length === 0) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Укажите название пакета и выберите клиники',
        });
        return;
      }

      try {
        this.isLoading = true;
        const params = {
          id: 0,
          name: this.packageName,
          clinicIds: this.selectedClinics.map((item) => item.id),
        };
        await api.post('v2/clinicPackages', params);

        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Пакет создан',
        });
        this.params.callback();
        this.$emit('vuedals:close');
      } catch (e) {
        showMessage({
          type: 'warning',
          title: 'Ошибка при создании пакета',
          message: 'Не удалось создать пакет',
        });
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

.crm-item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
// ::v-deep.clinics-filter {
//   .checkbox-list-wrapper {
//     height: 300px !important;
//   }

//   .popover-inner {
//     box-shadow: 0 5px 10px rgba(0, 0, 0, .1) !important;
//   }

//   .popover-select {
//     position: static !important;
//     transform: none !important;
//     display: none !important;
//     &.open {
//       display: block !important;
//     }
//   }
// }

.crm-label {
  color: $gray-dark;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 8px;
  font-weight: 400;
}

.crm-item-scrollbar-wrapper {
  height: 200px;
}
</style>
