<template>
  <div class="clinic-page">
    <custom-scrollbar>
      <div class="clinic-page-inner">
        <b-container fluid="xl">
          <div class="crm-container-title">
            <router-link
              :to="{ path: '/clinics' }"
              class="arrow-left"
            >
              <indemnity-arrow-icon />
            </router-link>
            <span class="crm-title">
              Клиники {{ clinicInfo ? '/ ' + clinicInfo.title : '' }}
            </span>
          </div>

          <div class="crm-container-clinic">
            <div class="crm-tabs-wrapper">
              <base-tabs
                v-model="activeTab"
                :items="filteredTabs"
                class="crm-tabs"
              />

              <div class="clinics-tab-content">
                <Loading
                  v-if="isLoading"
                  class="crm-loading-wrapper"
                >
                  Загрузка клиники
                </Loading>
                <component
                  :is="activeTab"
                  v-else
                  @updateClinicInfo="fetchClinicInfo"
                />
              </div>
            </div>
          </div>
        </b-container>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import IndemnityArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import { BaseTabs } from '@/components/base';
import Loading from '@/components/Loading';
import ClinicInfo from './ClinicInfo';
import ClinicDoctors from './ClinicDoctors';
import ClinicPhotoEdit from './ClinicPhotoEdit';
import ClinicPrice from './ClinicPrice';

export default {
  name: 'ClinicPage',
  page: {
    title: 'CRM Doctis - Клиника',
  },
  components: {
    IndemnityArrowIcon,
    BaseTabs,
    ClinicInfo,
    ClinicDoctors,
    ClinicPrice,
    ClinicPhotoEdit,
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    clinicId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      tabs: [
        {
          title: 'Данные клиники',
          value: 'ClinicInfo',
        },
        {
          title: 'Врачи клиники',
          value: 'ClinicDoctors',
        },
        {
          title: 'Фото клиники',
          value: 'ClinicPhotoEdit',
        },
        {
          title: 'Прайс',
          value: 'ClinicPrice',
        },
      ],
      activeTab: 'ClinicInfo',
    };
  },
  computed: {
    clinicInfo() {
      return this.$store.state.Clinic.clinic.info;
    },
    isLoading: {
      get() {
        return this.$store.state.Clinic.isClinicLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.CLINIC_IS_LOADING_SET, newVal);
      },
    },
    filteredTabs() {
      return this.tabs;
    },
  },
  watch: {
    activeTab() {
      this.$store.commit(this.$types.CLINIC_DOCTORS_IS_EDIT_SET, false);
      this.$store.commit(this.$types.CLINIC_INFO_IS_EDIT_SET, false);
    },
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CLINIC_SET, {});
  },
  async created() {
    try {
      this.isLoading = true;

      const clinicInfo = await this.fetchClinicInfo();
      this.$store.commit(this.$types.CLINICS_PINNED_DOCTORS_SET, [...clinicInfo.doctors]);
    } catch (e) {
      console.warn(e);
      this.$router.push('/clinics/');
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async fetchClinicInfo() {
      try {
        this.$store.commit(this.$types.CLINIC_IS_LOADING_SET, true);
        const clinicInfo = await this.$store.dispatch(this.$types.CLINIC_FETCH, this.clinicId);
        const modifiedСlinicInfo = {
          ...clinicInfo,
          clinicAttributes: clinicInfo.clinicAttributes || [],
          contactsForIL: clinicInfo.contactsForIL || [],
          services: clinicInfo.services || [],
        };
        this.$store.commit(this.$types.CLINIC_SET, {
          ...modifiedСlinicInfo,
          info: modifiedСlinicInfo,
        });

        return clinicInfo;
      } catch (e) {
        console.error(e);
        return e;
      } finally {
        this.$store.commit(this.$types.CLINIC_IS_LOADING_SET, false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.clinic-page-inner {
  padding: 20px 0;
}

::v-deep.crm-container-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  .arrow-left {
    margin-right: 15px;

    path {
      fill: #6E88F3;
    }
  }
}
.crm-title {
  @extend .page-title;
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

::v-deep.crm-container-clinic {
  margin-top: 30px;
}

::v-deep.crm-tabs {
  @extend .clinics-tabs;

  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  .crm-tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 12px 10px 10px;
    box-sizing: border-box;
    border-bottom: 0;

    &.active {
      background: #6E88F3;
      color: #fff;
    }
  }
}

.crm-tabs-wrapper {
  padding: 30px;
  background-color: #fff;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  width: 100%;
}

::v-deep.clinics-tab-content {
  height: 100%;
  min-height: calc(100vh - 450px);
}
</style>
