<template>
  <div style="width: 100%;">
    <div
      v-if="isEdit"
      style="display: flex; justify-content: flex-end;"
    >
      <icon-edit
        class="crm-icon"
        @click="() => $emit('edit')"
      />
    </div>
    <div class="line-title">
      <div class="line-title-inner">
        Информация о юридическом лице
      </div>
    </div>

    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Название Юр лица <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.name.$model"
          :error="$v.legalPersonData.name.$error"
          :errors="errorsValidation.legalPersonName"
          class="crm-form-field"
          placeholder="Введите название юр. лица"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Сеть клиник <span class="crm-error-text">*</span>
        </div>
        <div class="crm-radio-buttons crm-radio-buttons_vertical">
          <base-radio-button
            v-model="networkType"
            class="crm-radio-button"
            name="none"
            @change="checkNonNetwork"
          >
            Не выбрана
          </base-radio-button>

          <base-radio-button
            v-model="networkType"
            class="crm-radio-button"
            name="new"
            @change="checkNewNetwork"
          >
            Создать новую
          </base-radio-button>

          <base-radio-button
            v-model="networkType"
            class="crm-radio-button"
            name="exist"
            @change="checkCurrentNetwork"
          >
            Выбрать существующую
          </base-radio-button>
        </div>
        <base-input
          v-if="networkType === 'new'"
          v-model="$v.legalPersonData.network.title.$model"
          :error="$v.legalPersonData.network.title.$error"
          :errors="errorsValidation.network"
          class="crm-form-field"
          placeholder="Введите название сети"
        />
        <template v-if="networkType === 'exist'">
          <v-select
            v-model="$v.legalPersonData.network.$model"
            :options="networks"
            placeholder="Выберите сеть"
            label="title"
            class="crm-form-field"
          />
          <div
            v-if="errorsValidation.network"
            class="crm-error-text"
          >
            {{ errorsValidation.network[0] }}
          </div>
        </template>
      </div>
    </div>

    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          ФИО руководителя <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.leaderFio.$model"
          :error="$v.legalPersonData.leaderFio.$error"
          :errors="errorsValidation.leaderFio"
          required
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Должность руководителя <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.leaderPosition.$model"
          :error="$v.legalPersonData.leaderPosition.$error"
          :errors="errorsValidation.leaderPosition"
          required
          class="crm-form-field"
        />
      </div>
    </div>
    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Расчетный счет
        </div>

        <b-input
          v-model="$v.legalPersonData.checkingAccount.$model"
          :state="checkValidation ? !$v.legalPersonData.checkingAccount.$error : null"
          placeholder="Расчетный счет"
        />

        <div
          v-if="errorsValidation.checkingAccount"
          class="crm-error-text"
        >
          {{ errorsValidation.checkingAccount[0] }}
        </div>
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          Юридический адрес
        </div>

        <b-input
          v-model="legalPersonData.legalAddress"
          placeholder="Юридический адрес"
        />
      </div>
    </div>
    <div class="crm-row">
      <div class="crm-column">
        <div class="crm-input-label">
          Веб-сайт
        </div>
        <base-input
          v-model="$v.legalPersonData.site.$model"
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          ОГРН <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.ogrn.$model"
          :error="$v.legalPersonData.ogrn.$error"
          :errors="errorsValidation.ogrn"
          required
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          ИНН <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.inn.$model"
          :error="$v.legalPersonData.inn.$error"
          :errors="errorsValidation.inn"
          required
          class="crm-form-field"
        />
      </div>
      <div class="crm-column">
        <div class="crm-input-label">
          КПП <span class="crm-error-text">*</span>
        </div>
        <base-input
          v-model="$v.legalPersonData.kpp.$model"
          :error="$v.legalPersonData.kpp.$error"
          :errors="errorsValidation.kpp"
          required
          class="crm-form-field"
        />
      </div>
    </div>

    <legal-entity-contact
      v-for="(contact, contactIndex) in legalPersonData.contacts"
      :key="`contact-${contactIndex}`"
      v-model="legalPersonData.contacts[contactIndex]"
      :index="contactIndex"
      @delete="deleteContactHandler"
    />

    <div class="crm-row">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="width-unset without-border add-contact"
        @click="addContact"
      >
        <span>Добавить контакт</span>
      </base-button>
    </div>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Прикрепленные клиники
      </div>
    </div>
    <legal-entity-clinic
      v-for="(contact, clinicIndex) in legalPersonData.clinics"
      :key="`clinic-${clinicIndex}`"
      v-model="legalPersonData.clinics"
      :index="clinicIndex"
      @delete="deleteClinicHandler"
    />

    <div class="crm-row">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="width-unset without-border add-contact"
        @click="addClinic"
      >
        <span>Добавить клинику</span>
      </base-button>
    </div>

    <div
      class="line-title"
      style="margin-top: 15px;"
    >
      <div class="line-title-inner">
        Учередительные документы
      </div>
    </div>

    <legal-entity-documents
      edit
      :documents="legalPersonData.documents"
      @removeDocument="removeDocument"
    />

    <div class="crm-row">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickOpenFileModal"
      >
        <icon-cross
          class="crm-icon-cross"
        />
        Добавить файл
      </base-button>
    </div>

    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.PRIMARY_BUTTON"
        class="crm-button"
        @click="onClickSave"
      >
        Сохранить
      </base-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import IconEdit from 'assets/images/edit_icon.svg';
import IconCross from 'assets/images/cross.svg';
import { required, numeric } from 'vuelidate/lib/validators';
import {
  validateInn,
  showMessage,
  validateOgrn, validateKpp,
} from '@/helpers/utils';

import LegalEntityContact from '@/components/LegalEntities/LegalEntityContact';
import LegalEntityClinic from '@/components/LegalEntities/LegalEntityClinic';
import LegalEntityDocuments from '@/components/LegalEntities/LegalEntityDocuments';

import {
  BaseButton,
  BaseInput,
  BaseRadioButton,
} from '@/components/base';

export default {
  name: 'LegalEntityEditor',
  components: {
    IconEdit,
    IconCross,
    BaseInput,
    BaseButton,
    BaseRadioButton,
    LegalEntityContact,
    LegalEntityClinic,
    LegalEntityDocuments,
  },
  mixins: [mixinRoles],
  props: {
    initialData: {
      type: Object,
      default: () => null,
    },
    networks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkValidation: false,
      legalPersonData: {
        clinics: [],
        contacts: [],
        documents: [],
        id: 0,
        inn: null,
        kpp: null,
        leaderFio: null,
        leaderPosition: null,
        name: null,
        network: null,
        ogrn: null,
        site: null,
        checkingAccount: null,
        legalAddress: null,
      },
      networkType: 'none',
    };
  },
  validations() {
    return {
      legalPersonData: {
        name: { required },
        leaderFio: { required },
        leaderPosition: { required },
        inn: { required, validateInn },
        kpp: { required, validateKpp },
        ogrn: { required, validateOgrn },
        checkingAccount: { numeric },
        site: {},
        network: {
          id: { required: this.networkType === 'exist' ? required : () => true },
          title: { required: this.networkType === 'new' ? required : () => true },
        },
      },
    };
  },
  computed: {
    isEdit() {
      return !!this.initialData;
    },
    errorsValidation() {
      const errors = {};

      errors.legalPersonName = [];
      if (!this.$v.legalPersonData.name.required) {
        errors.legalPersonName.push('Поле не может быть пустым');
      }

      errors.network = [];
      if (this.networkType === 'new') {
        if (!this.$v.legalPersonData.network.title.required) {
          errors.network.push('Поле не может быть пустым');
        }
      } else if (this.networkType === 'exist' && !this.$v.legalPersonData.network.id.required) {
        errors.network.push('Поле не может быть пустым');
      }

      errors.leaderFio = [];
      if (!this.$v.legalPersonData.leaderFio.required) {
        errors.leaderFio.push('Поле не может быть пустым');
      }

      errors.leaderPosition = [];
      if (!this.$v.legalPersonData.leaderPosition.required) {
        errors.leaderPosition.push('Поле не может быть пустым');
      }

      errors.inn = [];
      if (!this.$v.legalPersonData.inn.required) {
        errors.inn.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.inn.validateInn) {
        errors.inn.push('Проверьте правильность инн');
      }

      errors.checkingAccount = [];
      if (!this.$v.legalPersonData.checkingAccount.numeric) {
        errors.checkingAccount.push('Допускаются только числа');
      }

      errors.ogrn = [];
      if (!this.$v.legalPersonData.ogrn.required) {
        errors.ogrn.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.ogrn.validateOgrn) {
        errors.ogrn.push('Проверьте правильность огрн');
      }

      errors.kpp = [];
      if (!this.$v.legalPersonData.kpp.required) {
        errors.kpp.push('Поле не может быть пустым');
      }
      if (!this.$v.legalPersonData.kpp.validateKpp) {
        errors.kpp.push('Проверьте правильность кпп');
      }

      return errors;
    },
  },
  async created() {
    if (this.isEdit) {
      this.legalPersonData = {
        ...this.initialData,
        // network: this.initialData.network ? this.initialData.network : { id: 0, title: '' },
      };
      this.networkType = this.initialData.network ? 'exist' : 'none';
    }
  },
  methods: {
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback, multiple: true });
    },
    saveFileCallback(newFilesArray) {
      const files = [];
      newFilesArray.map((fileObject) => {
        files.push({
          name: fileObject.name,
          fileId: fileObject.guid,
        });
        return null;
      });
      this.legalPersonData.documents = [...this.legalPersonData.documents, ...files];
    },
    removeDocument(index) {
      this.legalPersonData.documents.splice(index, 1);
    },
    checkNonNetwork() {
      this.legalPersonData.network = null;
    },
    checkNewNetwork() {
      this.legalPersonData.network = { id: 0, title: '' };
    },
    checkCurrentNetwork() {
      this.legalPersonData.network = null;
    },
    addContact() {
      this.legalPersonData.contacts.push({
        position: '',
        fio: '',
        email: '',
        phoneNumber: '',
      });
    },
    addClinic() {
      this.legalPersonData.clinics.push(null);
    },
    deleteContactHandler(delIndex) {
      this.legalPersonData.contacts = this.legalPersonData.contacts.filter((item, index) => index !== delIndex);
    },
    deleteClinicHandler(delIndex) {
      this.legalPersonData.clinics = this.legalPersonData.clinics.filter((item, index) => index !== delIndex);
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidation = true;

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      const action = !this.isEdit ? this.$types.LEGAL_ENTITIES_CREATE : this.$types.LEGAL_ENTITIES_UPDATE;
      const legalPersonToSave = {
        ...this.legalPersonData,
        clinics: this.legalPersonData.clinics.filter((item) => item !== null),
        contacts: this.legalPersonData.contacts.filter((item) => item.position || item.phoneNumber || item.fio || item.email),
      };

      try {
        await this.$store.dispatch(action, legalPersonToSave);
        this.$router.push('/legal-entities');
      } catch (e) {
        showMessage({
          type: 'error',
          message: this.isEdit ? 'Не удалось обновить данные о Юр лице' : 'Не удалось создать Юр лицо',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }
  cursor: pointer;
  rect {
    stroke: #6E88F3;
  }
  path {
    fill: $blue;
  }
}
.line-title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 30px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
    display: inline-block;
  }
}
.crm-row {
  display: flex;
  margin-top: 30px;
}
.crm-column {
  flex: 1;
  max-width: 100%;

  & + & {
    margin-left: 30px;
  }
}

::v-deep.crm-form-field {
  input {
    padding: 11px 10px 12px;
    resize: none;
  }
}

.crm-input-label {
  color: #707070;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 8px;
}

.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-radio-buttons_vertical {
  flex-direction: column;
  margin-bottom: 15px;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.crm-icon-cross {
  margin-right: 5px;
  path {
    fill: $blue;
  }
}
</style>
