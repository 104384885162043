<template>
  <div>
    <custom-scrollbar>
      <div class="crm-info-wrapper">
        <slot />

        <div
          v-if="!isAllItemsLoaded"
          ref="load"
          style="height: 50px; display: block;"
        />

        <div
          v-if="!isAllItemsLoaded && !withoutLoader"
          class="crm-preloader-wrapper"
        >
          <preloader />
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'AsyncList',
  components: {
    Preloader,
  },
  props: {
    isAllItemsLoaded: {
      type: Boolean,
      default: false,
    },
    withoutLoader: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      observer: null,
    };
  },
  watch: {
    isAllItemsLoaded: {
      async handler(newValue) {
        if (!newValue) {
          await this.$nextTick();
          this.observer.observe(this.$refs.load);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  beforeDestroy() {
    this.observer.disconnect();
  },
  methods: {
    infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        this.$emit('load-more-items');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-info-wrapper {
  flex-direction: column;
}
</style>
