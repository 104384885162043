<template>
  <div class="d-flex justify-content-between rounded p-3 bg-white border border-black">
    <div>
      <div
        v-for="infoItem in promocodeInfo"
        :key="infoItem.id"
        style="font-size: 16px;"
        class="d-flex mb-1"
      >
        <div class="font-weight-bold">
          {{ infoItem.key }}
        </div>

        <div
          :style="{color: infoItem.color}"
          class="ml-2 text-break"
        >
          {{ infoItem.value }}
        </div>
      </div>
    </div>

    <div class="d-flex flex-column ml-2">
      <b-button
        v-if="checkFeatureAccess({ name: 'Редактирование промокода', url: '/laboratory-promocodes' })"
        v-b-tooltip.hover.left
        variant="light"
        size="sm"
        class="border border-primary p-1"
        title="Редактировать"
        @click="$emit('edit')"
      >
        <b-icon icon="pencil-square" />
      </b-button>
      <b-button
        v-if="checkFeatureAccess({ name: 'Удаление промокода', url: '/laboratory-promocodes' })"
        v-b-tooltip.hover.left
        variant="light"
        size="sm"
        class="border border-danger mt-2 p-1"
        title="Удалить"
        @click="$emit('remove')"
      >
        <b-icon icon="trash" />
      </b-button>

      <!-- <b-button
        v-b-tooltip.hover.left
        :disabled="isExporting"
        variant="light"
        size="sm"
        class="border border-success mt-2 p-1"
        title="Скачать"
        @click="exportPromocode"
      >
        <b-icon icon="cloud-download" />
      </b-button> -->
    </div>
  </div>
</template>
<script>
import { format, parseISO } from 'date-fns';
import { mixinRoles } from '@/mixins';

const EXPIRED_TEXT = 'Просрочен';

export default {
  name: 'PromocodeItem',
  mixins: [mixinRoles],

  props: {
    promocodeData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isExporting: false,
  }),
  computed: {
    promocodeInfo() {
      return [
        { id: 1, key: 'Код:', value: this.promocodeData.code },
        {
          id: 2,
          key: 'Срок действия:',
          value: this.validityPeriod,
          color: this.validityPeriod === EXPIRED_TEXT ? 'red' : '',
        },
        {
          id: 3,
          key: 'Количество применений:',
          value: this.promocodeData.availableUsesTotalCount ? this.promocodeData.availableUsesTotalCount : '∞',
        },
        { id: 4, key: 'Юр. лица:', value: this.getLegalPartners },
        { id: 5, key: 'Комментарий:', value: this.promocodeData.comment },
      ];
    },
    getLegalPartners() {
      const legalPartners = this.promocodeData.availableLegalPartners.map((item) => item.legalName).join(', ');
      return legalPartners || 'Не выбрано';
    },
    endDateExpired() {
      if (!this.promocodeData.endDate) return;

      return new Date() >= new Date(this.promocodeData.endDate);
    },
    validityPeriod() {
      const promocode = this.promocodeData;

      if (promocode.startDate) {
        if (promocode.endDate) {
          if (this.endDateExpired) {
            return EXPIRED_TEXT;
          }
          return `С ${this.parseData(promocode.startDate)} по ${this.parseData(promocode.endDate)}`;
        }

        return 'Бессрочный';
      }

      return 'Не указан';
    },
  },
  methods: {
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    async exportPromocode() {
      this.isExporting = true;
      await this.$store.dispatch(this.$types.LABORATORY_PROMOCODE_EXPORT, { promocodeId: this.promocodeData.id, code: this.promocodeData.code });
      this.isExporting = false;
    },
  },
};
</script>

<style scoped>

</style>
