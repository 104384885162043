<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'История изменений прайса'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <custom-scrollbar>
      <div class="pr-4 pb-2">
        <b-table
          :bordered="true"
          :hover="true"
          :fields="fields"
          class="text-center mb-0"
          small
          show-empty
          :items="items"
        >
          <template #empty="">
            <div class="d-flex justify-content-center align-items-center">
              <h5 class="p-2">
                Не найдено <b-icon
                  icon="exclamation-triangle-fill"
                  variant="danger"
                />
              </h5>
            </div>
          </template>

          <template v-slot:cell(createdAt)="row">
            <span>{{ parseData(row.item.createdAt) }}</span>
          </template>

          <template v-slot:cell(startDate)="row">
            <span>{{ parseData(row.item.startDate) }}</span>
          </template>
        </b-table>
      </div>
    </custom-scrollbar>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  addHours,
} from 'date-fns';

export default {
  name: 'PricesHistoryModal',
  components: {},
  props: {
    costHistory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      fields: [
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Дата загрузки',
          sortable: true,
        },
        {
          key: 'startDate',
          label: 'Дата начала',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    items() {
      return this.costHistory.map((item) => ({
        ...item,
        createdAt: item.createdAt ? `${item.createdAt}z` : item.createdAt,
        startDate: item.startDate ? `${item.startDate}z` : item.startDate,
      }));
    },
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    parseData(ISODate) {
      return format(new Date(ISODate), 'dd.MM.yyyy');
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.fileIcon{
  cursor: pointer;
}
//.table th, .table td {
//  vertical-align: middle !important;
//}
</style>
