<template>
  <div>
    <template v-if="isCheckingState">
      <div class="crm-info-text">
        Ведется проверка, не закрывайте окно, <br>
        может занять несколько минут
      </div>
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else-if="isImportLoading">
      <div class="crm-info-text">
        Данные сохраняются, не закрывайте окно, <br>
        может занять несколько минут
      </div>
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else-if="isSuccessState">
      <div class="crm-info-text success">
        Расхождения не найдены. <br>
      </div>
    </template>

    <template v-else-if="isErrorState">
      <div class="crm-info-text error">
        Расхождения найдены. <br> Таблицу с типами ошибок можно скачать.
      </div>
      <div class="crm-container-buttons">
        <base-button
          :type="$const.PRIMARY_BUTTON"
          class="crm-button crm-button-file"
          @click="downloadTable"
        >
          <span>Скачать таблицу</span>
        </base-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';

export default {
  name: 'InsuranceProgramReestrImportModal',
  components: {
    BaseButton,
    Preloader,
  },
  props: {
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isCheckingState: false,
      isSuccessState: false,
      isErrorState: false,
      isImportLoading: false,
      successfullyChecked: [],
    };
  },
  async created() {
    try {
      this.isCheckingState = true;
      const { data: { fileWithErrorsId, successfullyChecked } } = await api.post('v2/policy/check', this.params);
      this.isCheckingState = false;
      this.successfullyChecked = successfullyChecked;

      if (fileWithErrorsId) {
        this.isErrorState = true;
        this.fileGUIDForDownload = fileWithErrorsId;
      } else {
        this.isSuccessState = true;
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async downloadTable() {
      const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
      const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
    async startImport() {
      try {
        this.isImportLoading = true;
        const params = {
          legalPersonId: this.params.legalPerson.id,
          services: this.successfullyChecked,
          actNumber: this.params.actNumber,
          month: this.params.month,
          year: this.params.year,
        };

        await api.post('v2/registry/save', params);
        this.$emit('vuedals:close');
      } catch (e) {
        console.log(e);
        this.isImportLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    .crm-button {
      width: unset;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .crm-info-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;

    &.success {
      color: #219653;
    }

    &.error {
      color: #EB5757;
    }
  }
</style>
