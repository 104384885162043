<template>
  <div class="appeals-info-wrapper">
    <div class="d-flex flex-wrap align-items-center justify-content-between my-2">
      <div class="d-flex flex-wrap">
        <div
          v-for="filter in appealFilters"
          :key="filter.index"
          class="appeals-filter-item"
          :class="{active: selectedFilterIndex === filter.index}"
          @click="selectFilter(filter)"
        >
          {{ filter.title }}
        </div>
      </div>

      <b-button
        id="popover-filter"
        v-b-tooltip.hover
        :variant="filterSelected ? 'primary' : 'light'"
        size="sm"
        class="border border-primary p-1 ml-2"
        title="Дополнительные фильтры"
      >
        <b-icon icon="filter" />
      </b-button>
    </div>

    <div class="appeals-info">
      <div
        v-if="!totalCount && isAllItemsLoaded"
        class="no-appeals"
      >
        <span>Нет обращений</span>
      </div>
      <template
        v-else
      >
        <async-list
          :is-all-items-loaded="isAllItemsLoaded"
          :disable-toggle-header="true"
          class="appeals-list"
        >
          <template v-if="!isLoading">
            <template v-if="sorting.sortType === 1">
              <div
                v-for="appealListItem in appealsSortedByPolicyAndNotPolicy"
                :key="appealListItem.id"
                class="appeals-list-item"
              >
                <div
                  class="appeal-policy-id d-flex justify-content-between align-items-center w-100 p-3"
                  @click="toggleShowPolicyList(appealListItem.id)"
                >
                  <div>
                    <div>
                      {{ appealListItem.selectedPolicy.policyId }}
                    </div>
                    <div
                      v-if="appealListItem.selectedPolicy.startDate|| appealListItem.selectedPolicy.endDate"
                      class="small text-secondary mt-1"
                    >
                      <template v-if="appealListItem.selectedPolicy.startDate">
                        с {{ parseDate(appealListItem.selectedPolicy.startDate) }}
                      </template>
                      <template v-if="appealListItem.selectedPolicy.endDate">
                        по {{ parseDate(appealListItem.selectedPolicy.endDate) }}
                      </template>
                    </div>
                  </div>

                  <b-icon
                    icon="chevron-down"
                    :rotate="selectedList(appealListItem.id) ? '180' : null"
                  />
                </div>

                <template v-if="selectedList(appealListItem.id)">
                  <PatientDetailAppealsItem
                    v-for="appealItem in appealListItem.appeals"
                    :key="appealItem.id"
                    :appeal="appealItem"
                    :class="{ active: activeAppeal && appealItem.id === activeAppeal.id }"
                    @click.native="setActiveAppeal(appealItem)"
                  />
                </template>
              </div>
            </template>
            <template v-else>
              <div
                v-for="appeal in appeals"
                :key="appeal.id"
                class="crm-container"
              >
                <PatientDetailAppealsItem
                  :appeal="appeal"
                  :class="{ active: activeAppeal && appeal.id === activeAppeal.id }"
                  @click.native="setActiveAppeal(appeal)"
                />
              </div>
            </template>
          </template>
        </async-list>

        <PatientDetailAppeal
          v-if="activeAppeal"
          :key="activeAppeal.id"
          :appeal="activeAppeal"
          :patient="patient"
        />
        <div
          v-if="!activeAppeal"
          class="no-active-appeal"
        >
          Выберите обращение из списка слева, чтобы посмотреть полную информацию о нём
        </div>
      </template>
    </div>

    <b-popover
      :show.sync="isPopoverOpen"
      target="popover-filter"
      triggers="click focus blur"
    >
      <div class="d-flex justify-content-between align-items-center mb-2">
        <div class="h6 font-weight-bold">
          Фильтры
        </div>

        <b-button
          variant="light"
          size="sm"
          class="border border-danger p-0 ml-2"
          @click="isPopoverOpen = false"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
      <div class="popover-inner">
        <div>
          <div class="my-3">
            <div class="mb-2">
              Сортировать по
            </div>
            <b-form-radio-group
              v-model="sorting.sortType"
              :options="sortTypeOptions"
              value-field="id"
              text-field="title"
              size="sm"
            />
          </div>

          <div class="d-flex justify-content-end align-items-center">
            <!--            <b-button-->
            <!--              variant="primary"-->
            <!--              size="sm"-->
            <!--              @click="applyFilter"-->
            <!--            >-->
            <!--              Применить-->
            <!--            </b-button>-->
            <b-button
              variant="danger"
              size="sm"
              @click="resetPopoverFilters"
            >
              Сбросить
            </b-button>
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { format, parseISO } from 'date-fns';
import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';
import PatientDetailAppealsItem from '@/components/Patients/PatientDetailAppealsItem';
import PatientDetailAppeal from '@/components/Patients/PatientDetailAppeal';

const LIMIT = 15;

export default {
  name: 'PatientDetailAppeals',
  components: {
    AsyncList,
    PatientDetailAppealsItem,
    PatientDetailAppeal,
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      limit: LIMIT,
      selectedFilterIndex: null,
      isPopoverOpen: false,
      filterSelected: false,
      appealFilters: [
        {
          index: 0,
          id: 0,
          title: 'Информационное',
          type: 'purpose',
        },
        {
          index: 1,
          id: 1,
          title: 'Чекап',
          type: 'purpose',
        },
        // {
        //   index: 2,
        //   id: 2,
        //   title: 'Лечение',
        //   type: 'purpose',
        // },
        {
          index: 3,
          id: 3,
          title: 'Госпитализация',
          type: 'purpose',
        },
        {
          index: 4,
          id: 36,
          title: 'Согласование услуг',
          type: 'type',
        },
      ],
      sorting: {
        sortType: 0,
      },
      sortTypeOptions: [
        {
          id: 0,
          title: 'Ничего не выбрано',
        },
        {
          id: 1,
          title: 'По полисам',
        },
      ],
      showAppealsLists: [],
    };
  },
  computed: {
    activeAppeal: {
      get() {
        return this.$store.state.Patients.detailAppeals.activeAppeal;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_ACTIVE_APPEAL_SET, newVal);
      },
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Patients.detailAppeals.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_APPEALS_IS_ALL_ITEMS_LOADED_SET, newVal);
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Patients.detailAppeals.isLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_DETAIL_APPEALS_IS_LOADING_SET, newVal);
      },
    },
    appeals: {
      get() {
        return this.$store.state.Patients.detailAppeals.appeals.filter((appeal) => {
          if (this.selectedFilterIndex === null) return true;
          const filter = this.appealFilters.find((f) => f.index === this.selectedFilterIndex);

          if (filter.type === 'type') return appeal.type && appeal.type.id === filter.id;

          return appeal.purpose === filter.id;
        });
      },
      set(newVal) {
        this.$store.commit(this.$types.PATIENT_APPEALS_SET, newVal);
      },
    },
    appealsSortedByPolicy() {
      return this.appeals.reduce((acc, item) => {
        if (item.selectedPolicy) {
          const actualItemIndex = acc.findIndex((accItem) => item.selectedPolicy.id === accItem.selectedPolicy.id);

          if (actualItemIndex !== -1) {
            if (acc[actualItemIndex].appeals) {
              acc[actualItemIndex].appeals.push(item);
            } else {
              acc[actualItemIndex].appeals = [item];
            }
          } else {
            acc.push({
              id: Math.floor(Math.random() * 10000),
              selectedPolicy: item.selectedPolicy,
              appeals: [item],
            });
          }
        }

        return acc;
      }, []);
    },
    appealsByNotSelectedPolicy() {
      return this.appeals.filter((item) => !item.selectedPolicy);
    },
    appealsSortedByPolicyAndNotPolicy() {
      return [
        ...this.appealsSortedByPolicy,
        {
          id: 'not-policy',
          selectedPolicy: {
            policyId: 'Без выбранного полиса',
          },
          appeals: this.appealsByNotSelectedPolicy,
        },
      ];
    },
    totalCount() {
      return this.appeals.length;
    },
  },
  async created() {
    this.getAppeals();

    Bus.$on('patient-detail-appeals:get-appeals', this.getAppeals);
  },
  beforeDestroy() {
    this.appeals = [];
    this.isAllItemsLoaded = false;
    this.activeAppeal = null;
    Bus.$off('patient-detail-appeals:get-appeals', this.getAppeals);
  },
  methods: {
    async getAppeals() {
      this.appeals = [];
      this.activeAppeal = null;

      this.isAllItemsLoaded = false;
      await this.$store.dispatch(this.$types.PATIENT_APPEALS_FETCH, {
        patientId: this.patientId,
      });
      this.isAllItemsLoaded = true;

      if (this.totalCount) {
        this.setActiveAppeal(this.appeals[0]);
      }
    },
    parseDate(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    setActiveAppeal(appeal) {
      if (this.activeAppeal && this.activeAppeal.id === appeal.id) {
        this.activeAppeal = null;
        return;
      }

      this.activeAppeal = appeal;
    },
    applyFilter() {
      // this.$emit('search');
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    resetPopoverFilters() {
      Object.keys(this.sorting).forEach((item) => {
        this.sorting[item] = 0;
      });
      this.isPopoverOpen = false;
    },
    selectFilter(filter) {
      if (this.selectedFilterIndex === filter.index) {
        this.selectedFilterIndex = null;
      } else {
        this.selectedFilterIndex = filter.index;
        this.activeAppeal = null;
      }

      if (this.appeals.length) {
        this.setActiveAppeal(this.appeals[0]);
      }
    },
    toggleShowPolicyList(id) {
      const index = this.showAppealsLists.findIndex((item) => item === id);

      if (index !== -1) {
        this.showAppealsLists.splice(index, 1);
      } else {
        this.showAppealsLists.push(id);
      }
    },
    selectedList(id) {
      return this.showAppealsLists.findIndex((item) => item === id) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.appeals-info-wrapper {
  height: 100%;
}

.appeals-filter {
  display: flex;
  margin-bottom: 10px;
}

::v-deep.appeals-filter-item {
  cursor: pointer;
  user-select: none;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 20px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;

  & + .appeals-filter-item {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }

  &.active {
    color: #6E88F3;
    border: 1px solid #6E88F3;
  }

}

.appeals-info {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  overflow: auto;
  box-sizing: border-box;
  height: calc(100% - 50px);
}

.appeals-list {
  height: 100%;
  overflow: auto;
  width: 280px;
  border-right: 1px solid #E0E0E0;
  box-sizing: border-box;
  flex-shrink: 0;
}

.no-appeals {
  padding: 30px 15px 30px 30px;
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

.no-active-appeal {
  box-sizing: border-box;
  padding: 20px;
  flex: 1;
  display: flex;
  height: 100%;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
}

.crm-container{
  padding: 0px;
}

.appeals-list-item {
  position: relative;
}

.appeal-policy-id {
  position: sticky;
  top: 0;
  left: 0;
  cursor: pointer;
  background-color: #f8fcff;
  border-bottom: 1px solid #E0E0E0;
}
</style>
