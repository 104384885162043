<template>
  <div class="bg-white border rounded">
    <div class="p-3">
      <div class="d-flex">
        <div class="w-50 pr-2">
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ID
            </div>
            <div class="ml-2 text-break">
              {{ consultation.id || '—' }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div
              v-if="consultation.type === 0"
              class="font-weight-bold"
            >
              {{ consultation.targetPatient ? 'ФИО представителя' : 'ФИО пациента' }}
            </div>
            <div
              v-if="consultation.type === 1"
              class="font-weight-bold"
            >
              Консультируемый врач
            </div>
            <div class="ml-2 text-break">
              {{ patientFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Статус
            </div>
            <div
              :style="{'color': statusColor}"
              class="ml-2 text-break"
            >
              {{ status }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div
              v-if="consultation.type === 0"
              class="font-weight-bold"
            >
              {{ consultation.targetPatient ? 'Телефон представителя' : 'Телефон пациента' }}
            </div>
            <div
              v-if="consultation.type === 1"
              class="font-weight-bold"
            >
              Телефон врача
            </div>
            <div class="ml-2 text-break">
              {{ consultation.patient.phoneNumber || '—' }}
            </div>
          </div>
        </div>
        <div class="w-75">
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              ФИО врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorFullname }}
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Телефон врача
            </div>
            <a
              v-if="doctorPhoneNumber"
              :href="`tel:${doctorPhoneNumber}`"
              class="ml-2 text-break"
            >
              {{ doctorPhoneNumber }}
            </a>
            <div
              v-else
              class="ml-2 text-break"
            >
              -
            </div>
          </div>
          <div class="d-flex mb-1">
            <div class="font-weight-bold">
              Специальность врача
            </div>
            <div class="ml-2 text-break">
              {{ doctorSpecialization }}
            </div>
          </div>
          <div class="my-divider" />
          <div class="d-flex">
            <div class="w-50">
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Время создания
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(consultation.createDate) }}
                </div>
              </div>
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Запись на
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(consultation.slotDate) }}
                </div>
              </div>
              <div
                v-if="consultation.differenceSlotTakeOut"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Запись — Взятие
                </div>
                <div class="ml-2 text-break">
                  {{ differenceSlotTakeOut }}
                </div>
              </div>
            </div>
            <div class="my-divider my-divider_vertical" />
            <div class="w-50">
              <div class="d-flex mb-1">
                <div class="font-weight-bold">
                  Взято в работу
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(consultation.takeOutDate) }}
                </div>
              </div>
              <div
                v-if="consultation.closeDate"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Закрыто
                </div>
                <div class="ml-2 text-break">
                  {{ parseData(consultation.closeDate) }}
                </div>
              </div>
              <div
                v-if="consultation.differenceTakeOutClose"
                class="d-flex mb-1"
              >
                <div class="font-weight-bold">
                  Взятие — Закрытие
                </div>
                <div class="ml-2 text-break">
                  {{ differenceTakeOutClose }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ml-3 d-flex flex-column">
          <!-- <icon-chat class="consultation-control" /> -->
          <b-button
            v-b-tooltip.hover.left
            variant="success"
            size="sm"
            class="mt-2 p-1"
            title="История изменений"
            @click="openConsultationHistoryModal"
          >
            <b-icon icon="book" />
          </b-button>
          <b-button
            v-if="!isShowConsultationControls && status === 'Закрытые'"
            v-b-tooltip.hover.left
            variant="primary"
            size="sm"
            class="mt-2 p-1"
            title="Выгрузить консультацию"
            @click="getConsultationZip"
          >
            <b-icon icon="download" />
          </b-button>
          <template v-if="isShowConsultationControls">
            <b-button
              v-if="checkFeatureAccess({ name: 'Перенос консультации', url: '/consultations' })"
              v-b-tooltip.hover.left
              variant="primary"
              size="sm"
              class="mt-2 p-1"
              title="Перенос консультации"
              @click="openTransferModal"
            >
              <b-icon icon="arrow-left-right" />
            </b-button>
            <b-button
              v-if="checkFeatureAccess({ name: 'Удаление консультации', url: '/consultations' })"
              v-b-tooltip.hover.left
              variant="danger"
              size="sm"
              class="mt-2 p-1"
              title="Удаление консультации"
              @click="openCancelModal"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="isInfoShow"
      class="p-3"
    >
      <div class="d-flex flex-wrap">
        <div
          v-if="consultation.targetPatient"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            ФИО пациента
          </div>
          <div class="mt-2 text-break">
            {{ targetPatientFullname }}
          </div>
        </div>
        <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Способ связи
          </div>
          <div class="mt-2 text-break">
            {{ communicationMethods[consultation.communicationMethod] }}
          </div>
        </div>
        <div
          v-if="checkFeatureAccess({ name: 'Видеть режим консультации', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Режим кон-ции
          </div>
          <div class="mt-2 text-break">
            {{ consultationTypes[consultation.type] }}
          </div>
        </div>
        <div class="mr-4 mb-3">
          <!-- !checkRoleAccess_(['ClinicAdministrator']) -->
          <div
            v-if="checkFeatureAccess({ name: 'Видеть поле Начальная стоимость', url: '/consultations' })"
            class="font-weight-bold"
          >
            Начальная стоимость
          </div>
          <div class="mt-2 text-break">
            {{ startPrice }}
          </div>
        </div>
        <!-- v-if="!checkRoleAccess_(['ClinicAdministrator'])" -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Конечная стоимость', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Конечная стоимость
          </div>
          <div class="mt-2 text-break">
            {{ consultation.cost }} ₽
          </div>
        </div>
        <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Часовой пояс врача
          </div>
          <div class="mt-2 text-break">
            {{ timeZone }}
          </div>
        </div>
        <!-- v-if="!checkRoleAccess_(['ClinicAdministrator'])" -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Промокод', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Промокод
          </div>
          <div class="mt-2 text-break">
            {{ consultation.promocode || '—' }}
          </div>
        </div>
        <!--          <div class="mr-4 mb-3">-->
        <!--            <div class="font-weight-bold">-->
        <!--              Оценка кон-ции-->
        <!--            </div>-->
        <!--            <div class="mt-2 text-break">-->
        <!--              {{ consultation.rating || '—' }}-->
        <!--            </div>-->
        <!--          </div>-->
        <div class="mr-4 mb-3">
          <div class="font-weight-bold">
            Режим кон-ции
          </div>
          <div class="mt-2 text-break">
            {{ consultationTypes[consultation.type] }}
          </div>
        </div>
        <!-- v-if="!checkRoleAccess_(['ClinicAdministrator'])" -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Платформа', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Платформа
          </div>
          <div class="mt-2 text-break">
            {{ clientTypes[consultation.clientType] }}
          </div>
        </div>
        <!-- v-if="!checkRoleAccess_(['ClinicAdministrator'])" -->
        <div
          v-if="checkFeatureAccess({ name: 'Видеть поле Код оплаты', url: '/consultations' })"
          class="mr-4 mb-3"
        >
          <div class="font-weight-bold">
            Код оплаты
          </div>
          <div class="mt-2 text-break">
            {{ consultation.paymentOrderId || '—' }}
          </div>
        </div>
      </div>

      <div class="my-divider" />
      <div class="d-flex mb-1">
        <div class="font-weight-bold">
          Текст обращения
        </div>
        <div class="ml-2 text-break">
          {{ consultation.body }}
        </div>
      </div>
      <template v-if="consultation.protocol">
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Рекомендации
          </div>
          <div class="ml-2 text-break">
            {{ consultation.protocol.recomendation || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Жалобы
          </div>
          <div class="ml-2 text-break">
            {{ consultation.protocol.anamnesis || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            МКБ
          </div>
          <div
            v-if="!consultation.protocol.mkB10s.length"
            class="ml-2 text-break"
          >
            —
          </div>
          <template v-else>
            <div
              v-for="(mkB10, index) in consultation.protocol.mkB10s"
              :key="index"
              class="ml-2 text-break"
            >
              {{ `${mkB10.code} — ${mkB10.name}` }}
            </div>
          </template>
        </div>
      </template>
      <template v-if="telemedRating">
        <div class="my-divider" />
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Качество связи
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.connectionQuality || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Работа доктора
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.doctorsWork || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Доктор был пунктуален
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isPunctual ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Доктор был вежлив
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isPolite ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Доктор был профессионален
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isProfessional ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Доктор был внимателен
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isAttentive ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Доктор был сфокусирован
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isFocused ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Консултация была полезна
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isHelpfull ? 'Да' : 'Нет' }}
          </div>
        </div>
        <div class="my-divider" />
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Комментарий пациента
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.comment || '—' }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Рекомендуете данного врача
          </div>
          <div class="ml-2 text-break">
            {{ telemedRating.isRecommendation ? 'Да' : 'Нет' }}
          </div>
        </div>
      </template>
    </div>

    <div
      style="cursor: pointer;"
      class="bg-primary rounded-bottom d-flex justify-content-center p-2"
      @click="isInfoShow = !isInfoShow"
    >
      <b-icon
        icon="caret-down-fill"
        variant="light"
        :rotate="isInfoShow ? 180 : 0"
      />
    </div>
  </div>
</template>

<!-- Рефакторинг не производился. Перевёрстан под бутстрап -->
<script>
import { formatTimeZone } from '@/helpers/utils';
import {
  parseISO,
  format,
} from 'date-fns';

import { mixinRoles } from '@/mixins';

export default {
  name: 'Consultationinfo',

  mixins: [mixinRoles],
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isInfoShow: false,
      telemedRating: null,
      consultationTypes: {
        0: 'Пациент-Врач',
        1: 'Врач-Врач',
      },
      statuses: {
        0: {
          title: 'Новая',
          value: 'green',
        },
        1: {
          title: 'В работе',
          value: 'blue',
        },
        2: {
          title: 'Закрытые',
          value: 'red',
        },
        3: {
          title: 'Консилиум',
          value: 'blue',
        },
        4: {
          title: 'Ожидание оплаты',
          value: 'blue',
        },
        6: {
          title: 'Отмена',
          value: 'red',
        },
        7: {
          title: 'Ожидание оплаты истекло',
          value: 'orange',
        },
        8: {
          title: 'Отменено пациентом',
          value: 'red',
        },
        9: {
          title: 'Отменено доктором',
          value: 'red',
        },
        11: {
          title: 'Ошибочный статус',
          value: 'red',
        },
      },
      clientTypes: {
        0: 'Неизвестно',
        1: 'Android приложение',
        2: 'iOS приложение',
        3: 'Веб-приложение',
        4: 'CRM',
        5: 'Запись врачом',
        11: 'Android приложение FoodFessional Generation',
        12: 'iOS приложение FoodFessional Generation',
        101: 'Внешний сервис',
        102: 'СМЭВ',
        104: 'Дистанционный мониторинг',
        111: 'МИС',
      },
      communicationMethods: {
        0: 'Текст',
        1: 'Аудио',
        2: 'Видео',
      },
    };
  },
  computed: {
    isShowConsultationControls() {
      return ['В работе', 'Новая', 'Ожидание оплаты'].includes(this.status);
    },
    status() {
      return this.statuses[this.consultation.status].title;
    },
    statusColor() {
      return this.statuses[this.consultation.status].value;
    },
    rating() {
      return this.consultation.rating || 'Отсутствует';
    },
    doctorSpecialization() {
      return this.consultation.doctorSpecialization.specialization.title;
    },
    patientFullname() {
      const { middleName, firstName, lastName } = this.consultation.patient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    targetPatientFullname() {
      const { middleName, firstName, lastName } = this.consultation.targetPatient;

      return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
    },
    doctorFullname() {
      try {
        const { middleName, firstName, lastName } = this.consultation.doctorSpecialization.doctor;

        return `${lastName || ''} ${firstName || ''} ${middleName || ''}`;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    doctorPhoneNumber() {
      try {
        return this.consultation.doctorSpecialization.doctor.phoneNumber;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    timeZone() {
      return this.consultation.timeZone ? formatTimeZone(this.consultation.timeZone) : null;
    },
    differenceSlotTakeOut() {
      const difference = this.consultation.differenceSlotTakeOut.replace('-', '');
      const hasDays = difference.includes('.');
      const days = difference.split('.')[0];
      const daysString = hasDays ? `${+days}д` : '';

      const differenceArray = hasDays ? difference.split('.')[1].split(':') : difference.split(':');

      const hours = differenceArray[0];
      const hasHours = !!+hours;
      const hoursString = hasHours ? `${hours}ч` : '';

      const minutes = differenceArray[1];
      const hasMinutes = !!+minutes;
      const minutesString = hasMinutes ? `${minutes}м ` : '';

      const seconds = differenceArray[2];
      const secondsString = `${seconds}с `;

      return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
    },
    differenceTakeOutClose() {
      const difference = this.consultation.differenceTakeOutClose.replace('-', '');
      const hasDays = difference.includes('.');
      const days = difference.split('.')[0];
      const daysString = hasDays ? `${+days}д` : '';

      const differenceArray = hasDays ? difference.split('.')[1].split(':') : difference.split(':');

      const hours = differenceArray[0];
      const hasHours = !!+hours;
      const hoursString = hasHours ? `${+hours}ч` : '';

      const minutes = differenceArray[1];
      const hasMinutes = !!+minutes;
      const minutesString = hasMinutes ? `${minutes}м ` : '';

      const seconds = differenceArray[2];
      const secondsString = `${seconds}с `;

      return `${daysString} ${hoursString} ${minutesString} ${secondsString}`;
    },
    startPrice() {
      const price = this.consultation.doctorSpecialization.doctor.settings.consultationPrice;
      return price ? `${price} ₽` : '—';
    },
  },
  methods: {
    openConsultationHistoryModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationHistoryModal',
        props: {
          consultationId: this.consultation.id,
        },
      });
    },
    parseData(ISODate) {
      if (!ISODate) {
        return null;
      }

      return format(parseISO(ISODate), 'dd.MM.yyyy HH:mm');
    },
    openTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationTransferModal',
        props: {
          consultationProp: this.consultation,
        },
      });
    },
    openCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationDeletingModal',
        props: {
          consultation: this.consultation,
        },
      });
    },
    async getConsultationZip() {
      try {
        await this.$store.dispatch(this.$types.CONSULTATION_GET_ZIP, this.consultation.id);
      } catch (error) {
        console.log('getConsultationZip', error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.5);
  margin: 10px 0;

  &_vertical {
    width: 1px;
    height: 50px;
    margin: 0 10px;
  }
}
</style>
