<template>
  <custom-scrollbar>
    <div class="w-100 p-3 insuranceImoportReestr">
      <b-container>
        <div class="w-100 p-3 bg-white rounded header-container">
          <div>
            <div>
              <h4>
                Проверка реестров от СК
              </h4>
            </div>
            <div class="d-block flex-column">
              <div
                v-for="(insurance, i) in $v.insuranceRows.$each.$iter"
                :key="i"
                class="mt-3 w-100 mb-4"
              >
                <div class="d-flex w-100 mb-4">
                  <div class="w-100">
                    <v-select
                      v-model="insurance.selectedCompanyId.$model"
                      :reduce="company => company.id"
                      :options="insuranceCompanies"
                      placeholder="Выберите компанию"
                      label="title"
                      class="w-100"
                      @input="changeCompany(i)"
                    />
                    <div
                      v-if="insurance.selectedCompanyId.$error"
                      class="error-text"
                    >
                      <span>
                        Необходимо выбрать один из вариантов
                      </span>
                    </div>
                  </div>

                  <div class="w-100 ml-3">
                    <v-select
                      v-model="insuranceRows[i].selectedProgramId"
                      :reduce="program => program.id"
                      :options="insuranceRows[i].insurancePrograms"
                      placeholder="Выберите программу"
                      label="name"
                      class="w-100"
                      @input="changeProgram(i)"
                    />
                    <div
                      v-if="insurance.selectedProgramId.$error"
                      class="error-text"
                    >
                      <span>
                        Необходимо выбрать один из вариантов
                      </span>
                    </div>
                  </div>
                </div>

                <div class="w-100">
                  <v-select
                    v-model="insuranceRows[i].selectedSubprogramId"
                    :reduce="subprogram => subprogram.id"
                    :options="insuranceRows[i].insuranceSubprograms"
                    placeholder="Выберите подпрограмму"
                    label="name"
                    class="w-100"
                  />
                  <div
                    v-if="insurance.selectedSubprogramId.$error"
                    class="error-text"
                  >
                    <span>
                      Необходимо выбрать один из вариантов
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-end mt-3">
              <div class="d-flex">
                <b-button
                  :type="$const.SECONDARY_BUTTON"
                  variant="primary"
                  class="mr-2 text-nowrap"
                  @click="onClickOpenFileModal"
                >
                  <b-icon icon="plus" />
                  <span>Добавить файл</span>
                </b-button>
                <b-button
                  :type="$const.PRIMARY_BUTTON"
                  class="button-import"
                  variant="info"
                  @click="onClickOpenInsuranceModal"
                >
                  <span>Проверить</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 p-3 mt-4 bg-white rounded table-container">
          <div
            v-if=" !isLoadingFromFile"
            class="w-100"
          >
            <div class="policies-table-wrapper">
              <div
                ref="table-header"
                class="policies-table-header"
              >
                <div
                  v-for="(title, i) in selectedColumnNames"
                  :key="i"
                  class="policies-table-header-cell"
                >
                  <v-select
                    :value="availableColumnNames.filter((name) => name.id === selectedColumnNames[i])[0]"
                    :append-to-body="true"
                    :reduce="program => program.id"
                    :searchable="false"
                    :clearable="true"
                    :options="filteredAvailableColumnNames"
                    :calculate-position="setMaxHeight"
                    class="select-col-name"
                    label="name"
                    placeholder="Игнорировать"
                    @input="selectColumnName($event, i)"
                  />
                </div>
              </div>
              <div class="policies-table-body">
                <vuescroll
                  :ops="ops"
                  class="vuescroll-custom"
                  @handle-scroll="handleScroll"
                >
                  <div class="policies-table-inner">
                    <div
                      v-for="(row, rowIndex) in tableRows"
                      :key="rowIndex"
                      class="policies-table-row"
                    >
                      <input
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        readonly="readonly"
                        class="policies-table-cell"
                        type="text"
                        :value="cell"
                      >
                    </div>
                  </div>
                </vuescroll>
              </div>
            </div>
            <div
              v-if="tableRows.length < totalRowsInFile"
              class="show-more-btn"
              @click="showAllRows"
            >
              Показать полностью
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </custom-scrollbar>
</template>

<script>
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import vuescroll from 'vuescroll';
import { showMessage } from '@/helpers/utils';
import { api } from '@/helpers/api';

export default {
  name: 'InsuranceProgramReestrImport',
  components: {
    vuescroll,
  },
  mixins: [validationMixin],
  data() {
    return {
      isLoadingFromFile: false,
      filteredAvailableColumnNames: [],
      totalRowsInFile: 0,
      availableColumnNames: [
        { id: 0, name: 'Номер полиса' },
        { id: 2, name: 'Фамилия' },
        { id: 1, name: 'Имя' },
        { id: 3, name: 'Отчество' },
        { id: 4, name: 'Дата рождения' },
        { id: 7, name: 'Номер телефона' },
        { id: 5, name: 'Дата начала полиса' },
        { id: 6, name: 'Дата окончания полиса' },
        { id: 8, name: 'Город' },
        { id: 9, name: 'Дата покупки' },
        { id: 10, name: 'Страхователь' },
        { id: 11, name: 'Стоимость прикрепления' },
        { id: 12, name: 'Стоимость открепления' },
        { id: 13, name: 'СНИЛС' },
        { id: 14, name: 'Новый объем услуг' },
        { id: 15, name: 'Старый объем услуг' },
      ],
      selectedColumnNames: [
        null, null, null, null, null,
      ],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#E0E0E0',
          minSize: 0.15,
          size: '5px',
        },
      },
      insuranceCompanies: [],
      insuranceRows: [
        {
          selectedCompanyId: null,
          selectedProgramId: null,
          selectedSubprogramId: null,
          insurancePrograms: [],
          insuranceSubprograms: [],
        },
      ],
      tableRows: [
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
      ],
    };
  },
  validations() {
    return {
      insuranceRows: {
        $each: {
          selectedCompanyId: {
            required,
          },
          selectedProgramId: {
            required,
          },
          selectedSubprogramId: {
            required,
          },
        },
      },
    };
  },
  computed: {
    formatedSelectedRowsForBackend() {
      return this.availableColumnNames.map((colType) => {
        let colIndexForThisColName = this.selectedColumnNames.indexOf(colType.id);
        if (colIndexForThisColName === -1) {
          colIndexForThisColName = null;
        }
        return { propertyId: colType.id, columnNumber: colIndexForThisColName };
      });
    },
  },
  async created() {
    this.isLoading = true;

    this.filteredAvailableColumnNames = this.availableColumnNames;

    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));

    this.setInsuranceEntities();

    this.isLoading = false;
  },
  methods: {
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload-modern', { saveFileCallback: this.saveFileCallback, multiple: false });
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid;
      await this.getTableRows(10, true);
    },
    onClickOpenInsuranceModal() {
      this.$v.$touch();
      const hasValidationError = this.$v.$invalid;

      if (hasValidationError) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Укажите недостающие данные',
        });
        return;
      }

      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoadingFromFile;

      if (!hasFile) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо добавить файл',
        });
        return;
      }

      // const companyIds = this.insuranceRows.filter((row) => row.selectedCompanyId).map((row) => row.selectedCompanyId);
      // const programIds = this.insuranceRows.filter((row) => row.selectedProgramId).map((row) => row.selectedProgramId);
      const subprogramIdCheck = this.insuranceRows.filter((row) => row.selectedSubprogramId).map((row) => row.selectedSubprogramId);
      const subprogramId = subprogramIdCheck[0];

      const params = {
        fileId: this.fileGUID,
        // companyIds,
        // programIds,
        subprogramId,
        allRowsCount: this.totalRowsInFile,
        columns: this.formatedSelectedRowsForBackend,
        legalPerson: this.legalPerson,
        actNumber: this.actNumber,
        month: this.month,
        year: this.year,
      };

      Bus.$emit('open-modal:insurance-program-reestr-import', {
        params,
      });
    },
    setMaxHeight(dropdownList, component, { width, top, left }) {
      /* eslint-disable no-param-reassign */
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      // dropdownList.style.maxHeight = 'calc(100vh - 100px)';
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },
    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoadingFromFile = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows?guid=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoadingFromFile = false;
    },
    changeCompany(i) {
      this.insuranceRows[i].selectedProgramId = null;
      this.insuranceRows[i].selectedSubprogramId = null;
      this.insuranceRows[i].insurancePrograms = [];
      this.insuranceRows[i].insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram(i) {
      this.insuranceRows[i].selectedSubprogramId = null;
      this.insuranceRows[i].insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    selectColumnName(e, i) {
      this.selectedColumnNames[i] = e;
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.id));
    },
    handleScroll(vertical, horizontal) {
      // Table header horizontal scroll
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
    },
    setInsuranceEntities() {
      this.insuranceRows.forEach((row, index) => {
        const { selectedCompanyId, selectedProgramId } = row;

        if (!selectedCompanyId) return;

        const companyObject = this.insuranceData
          .filter(({ company: { id } }) => id === selectedCompanyId)[0];

        const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
        this.insuranceRows[index].insurancePrograms = programs;

        if (!selectedProgramId) return;

        const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === selectedProgramId)[0];
        this.insuranceRows[index].insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.table-container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.insuranceImoportReestr{
  height: calc(100vh - 30px);
}

.reestr-import {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 30px);
  margin-top: 30px;
}

.crm-container {
  flex-direction: column;
}

.crm-header-block {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.crm-form-row {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-column-gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.crm-form-row--buttons {
  grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
  grid-column-gap: 40px;
}

.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

::v-deep.title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-container-buttons {
  display: flex;
  margin-left: auto;
}

::v-deep.crm-button-file {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-left: auto;
  background-color: transparent;

  .crm-icon-cross {
    margin-right: 15px;
    path {
      fill: $blue;
    }
  }
}

.button-import {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-left: auto;
}

.policies-table {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px  55px 30px;

  * {
    box-sizing: border-box;
  }
}

.policies-table-wrapper {
  width: 100%;
  // overflow: hidden;
}

.policies-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.policies-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.policies-table-body {
  height: 350px;
  position: relative;
}

::v-deep.policies-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
}

::v-deep.policies-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .policies-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.width-unset {
  width: auto;
}

.add-insurance-row {
  margin-right: auto;
}

.crm-form-field--selects-row {
  display: flex;
}

.crm-form-field-months {
  width: 180px;
  margin-right: 20px;
}

.crm-form-field-months {
  width: 180px;
  margin-right: 20px;
}

.crm-form-field-years {
  width: 120px;
}

.crm-form-field {
  position: relative;
}

.error-text {
  position: absolute;
  padding: 3px 0 0 0;
  font-size: 13px;
  color: red;
}
</style>
