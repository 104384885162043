<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить клинику?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            @click="onClickDelete"
          >
            Удалить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'IndemnityLetterDeleteModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    clinicId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.CLINIC_REMOVE, this.clinicId);
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
        this.$emit('vuedals:close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
