<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="bg-white p-4 rounded border my-4">
        <div class="h5 font-weight-bold mb-4">
          Запись пациента на консультацию
        </div>
        <div class="crm-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block w-100">
              <div class="font-weight-bold mb-2">
                Куда записываем пациента
              </div>
              <v-select
                v-model="$v.queue.$model"
                :options="queueList"
                :reduce="queue => queue.id"
                label="name"
                placeholder="Куда записываем пациента"
              />

              <div
                v-if="$v.queue.$error"
                class="validation-errors search-error"
              >
                <span
                  class="validation-error-text"
                >
                  {{ errorsValidation.queue[0] }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              Учетная запись
            </div>

            <b-form-radio-group
              v-model="accountType"
              :options="ACCOUNT_TYPES"
              value-field="id"
              text-field="title"
              class="mb-2"
            />

            <template v-if="accountType === 0">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-if="search.field === 'phone'"
                  v-model="search.filter.PhoneNumber"
                  type="search"
                  size="sm"
                  :disabled="search.isLoading"
                  placeholder="Введите номер телефона"
                  class="border"
                  @keyup.native.enter="onClickSearch"
                />
                <b-form-input
                  v-if="search.field === 'fio'"
                  v-model="search.filter.SearchFIO"
                  type="search"
                  size="sm"
                  :disabled="search.isLoading"
                  placeholder="Введите ФИО"
                  class="border"
                  @keyup.native.enter="onClickSearch"
                />
                <b-form-input
                  v-if="search.field === 'policy'"
                  v-model="search.filter.PolicyNumber"
                  type="search"
                  size="sm"
                  :disabled="search.isLoading"
                  placeholder="Введите номер полиса"
                  class="border"
                  @keyup.native.enter="onClickSearch"
                />
                <b-button
                  id="popover-filter"
                  v-b-tooltip.hover
                  variant="light"
                  size="sm"
                  :disabled="search.isLoading"
                  class="border border-primary p-1 ml-2"
                  title="Дополнительные фильтры"
                >
                  <b-icon icon="filter" />
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  variant="light"
                  size="sm"
                  class="border border-success p-1 ml-2"
                  title="Поиск"
                  :disabled="search.isLoading"
                  @click="onClickSearch"
                >
                  <b-spinner
                    v-if="search.isLoading"
                    variant="primary"
                    small
                  />
                  <b-icon
                    v-else
                    icon="search"
                  />
                </b-button>
              </div>

              <div
                v-if="search.error"
                class="text-danger mb-2 small"
              >
                {{ search.error }}
              </div>

              <div
                v-if="$v.checkup.patientId.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.patientId[0] }}
              </div>

              <div
                v-if="search.patients && search.patients.length"
                class="mt-3"
              >
                <div class="patients d-flex">
                  <PatientInfo
                    v-for="patient in search.patients"
                    :key="patient.id"
                    :patient="patient"
                    :active="patient.id === checkup.patientId"
                    class="mr-2"
                    @click.native="setPatient(patient)"
                  />
                </div>
              </div>
            </template>

            <div
              v-if="accountType === 1"
              class="border p-2 my-2"
            >
              <div class="mb-3 d-flex">
                <div class="w-50">
                  <div class="font-weight-bold mb-2">
                    Фамилия
                  </div>

                  <b-form-input
                    v-model="$v.checkup.lastName.$model"
                    :state="checkValidations ? !$v.checkup.lastName.$error : null"
                    size="sm"
                  />

                  <div
                    v-if="$v.checkup.lastName.$error"
                    class="text-danger mt-2 small"
                  >
                    {{ errorsValidation.lastName[0] }}
                  </div>
                </div>
                <div class="w-50 ml-2">
                  <div class="font-weight-bold mb-2">
                    Имя
                  </div>

                  <b-form-input
                    v-model="$v.checkup.firstName.$model"
                    :state="checkValidations ? !$v.checkup.firstName.$error : null"
                    size="sm"
                  />

                  <div
                    v-if="$v.checkup.firstName.$error"
                    class="text-danger mt-2 small"
                  >
                    {{ errorsValidation.firstName[0] }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="font-weight-bold mb-2">
                  Отчество
                </div>

                <b-form-input
                  v-model="checkup.middleName"
                  size="sm"
                />
              </div>
              <div class="mb-3 d-flex justify-content-between">
                <div>
                  <div class="font-weight-bold mb-2">
                    Дата рождения
                  </div>

                  <base-date-picker
                    v-model="$v.checkup.birthDate.$model"
                    :max-date="maxDate"
                    :error="$v.checkup.birthDate.$error"
                  />

                  <div
                    v-if="$v.checkup.birthDate.$error"
                    class="text-danger mt-2 small"
                  >
                    {{ errorsValidation.birthDate[0] }}
                  </div>
                </div>
                <div>
                  <div class="font-weight-bold mb-2">
                    Пол
                  </div>

                  <b-form-radio-group
                    v-model="$v.checkup.sex.$model"
                    :options="GENDERS"
                    :state="checkValidations ? !$v.checkup.sex.$error : null"
                    value-field="value"
                    text-field="title"
                    class="mb-2"
                  />

                  <div
                    v-if="$v.checkup.sex.$error"
                    class="text-danger mt-2 small"
                  >
                    {{ errorsValidation.sex[0] }}
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <div class="font-weight-bold mb-2">
                  Телефон
                </div>

                <div class="d-flex">
                  <b-form-input
                    v-model="$v.checkup.phoneNumber.$model"
                    v-mask="'+7 (###) ### ##-##'"
                    :state="checkValidations ? !$v.checkup.phoneNumber.$error : null"
                    size="sm"
                    @input="changePhoneNumber"
                  />

                  <b-button
                    variant="primary"
                    :disabled="confirmButtonDisabled"
                    size="sm"
                    class="ml-3 text-nowrap"
                    @click="onPhoneConfirm"
                  >
                    {{ isPhoneConfirmed ? 'Подтвержден' : 'Отправить код' }}
                  </b-button>
                </div>

                <div
                  v-if="$v.checkup.phoneNumber.$error"
                  class="text-danger mt-2 small"
                >
                  {{ errorsValidation.phoneNumber[0] }}
                </div>
              </div>
              <div
                v-if="isPhoneConfirmCodeSended && !isPhoneConfirmed"
                class="d-flex mb-3"
              >
                <b-input
                  v-model="phoneConfirmCode"
                  :state="!(phoneConfirmCode.length < 4)"
                  placeholder="Код из смс"
                />

                <b-button
                  variant="primary"
                  :disabled="phoneConfirmCode.length < 4 || isPhoneConfirmLoading"
                  class="ml-4 text-nowrap"
                  @click="onPhoneConfirmCodeCheck"
                >
                  Проверить код
                </b-button>
              </div>
            </div>
          </div>

          <!-- <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Тип консультации
              </p>
              <div class="crm-radio-buttons">
                <base-radio-button
                  v-model="communicationType"
                  class="crm-radio-button"
                  :name="0"
                >
                  Аудио
                </base-radio-button>
                <base-radio-button
                  v-model="communicationType"
                  class="crm-radio-button"
                  :name="1"
                >
                  Видео
                </base-radio-button>
                <base-radio-button
                  v-model="communicationType"
                  class="crm-radio-button"
                  :name="2"
                >
                  Чат
                </base-radio-button>
              </div>
            </div>
          </div> -->
        </div>

        <!-- <div class="mb-3">
          <div class="font-weight-bold mb-2">
            Файлы
          </div>

          <div class="mb-2">
            <div
              v-for="document in documents"
              :key="document.guid"
              class="d-flex mb-1"
            >
              <div class="overflow-hidden">
                {{ document.title }}
              </div>
              <b-button
                variant="light"
                size="sm"
                class="border ml-2 p-1"
                @click="onClickDeleteFile(document)"
              >
                <b-icon icon="trash" />
              </b-button>
            </div>
          </div>

          <b-button
            variant="primary"
            size="sm"
            @click="onClickOpenFileModal"
          >
            Добавить файлы
          </b-button>
        </div> -->

        <div class="mb-3">
          <div class="font-weight-bold mb-2">
            Текст обращения
            <div class="small">
              (опционально)
            </div>
          </div>

          <b-form-textarea
            v-model="checkup.body"
            rows="3"
            max-rows="6"
          />
        </div>

        <div class="crm-wrapper-buttons">
          <b-button
            v-if="accountType === 0"
            variant="primary"
            @click="onClickSave"
          >
            Записать
          </b-button>
          <b-button
            v-if="accountType === 1"
            variant="primary"
            :disabled="saveButtonDisabled"
            @click="onClickSave"
          >
            Записать
          </b-button>
        </div>
      </div>
    </b-container>
    <b-popover
      target="popover-filter"
      triggers="click focus blur"
    >
      <div class="popover-inner p-2">
        <div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Поиск по
            </div>

            <b-form-radio-group
              v-model="search.field"
              :options="SEARCH_FIELDS"
              value-field="alias"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Тип полиса
            </div>

            <b-form-radio-group
              v-model="search.filter.PatientType"
              :options="POLICY_TYPES"
              value-field="id"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import {
  format,
  parseISO,
  formatISO,
} from 'date-fns';

import PatientInfo from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/PatientInfo';
import { BaseDatePicker } from '@/components/base';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { phoneMixins } from '@/mixins/phoneMixins';
import { showMessage, dateWithoutTime } from '@/helpers/utils';
import { ACCOUNT_TYPES } from '@/helpers/consts';

const GENDERS = [
  {
    value: true,
    title: 'Мужской',
  },
  {
    value: false,
    title: 'Женский',
  },
];

const SEARCH_FIELDS = [
  {
    alias: 'phone',
    title: 'Номеру телефона',
  },
  {
    alias: 'fio',
    title: 'ФИО',
  },
  {
    alias: 'policy',
    title: 'По номеру полиса',
  },
];

const POLICY_TYPES = [
  {
    id: 2,
    title: 'Все',
  },
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'ОМС',
  },
];

export default {
  name: 'PatientAppointment',
  components: {
    PatientInfo,
    BaseDatePicker,
  },
  mixins: [validationMixin, phoneMixins],
  props: {
    // time: {
    //   type: Number,
    //   default: 0,
    // },
    // doctorId: {
    //   type: Number,
    //   default: 0,
    // },
  },
  data() {
    return {
      phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
      maxDate: new Date(),
      isSaving: false,
      checkValidations: false,
      isPhoneConfirmed: false,
      isPhoneConfirmLoading: false,
      isPhoneConfirmCodeSended: false,
      isSendSms: false,
      ACCOUNT_TYPES,
      GENDERS,
      SEARCH_FIELDS,
      POLICY_TYPES,
      phoneConfirmCode: '',
      accountType: 0,
      communicationType: 0,
      documents: [],
      checkup: {
        middleName: '',
        firstName: '',
        lastName: '',
        birthDate: null,
        sex: null,
        phoneNumber: '+7 (',
        body: '',
        patientId: null,
        parentId: null,
      },
      search: {
        isLoading: false,
        filter: {
          PhoneNumber: null,
          SearchFIO: null,
          PolicyNumber: null,
          PatientType: 2,
        },
        field: 'phone',
        patients: [],
        error: null,
      },
      queue: null,
      queueList: [],
      selectedPerson: null,
    };
  },
  validations() {
    return this.accountType === 0
      ? {
        checkup: {
          patientId: {
            required,
          },
        },
        queue: {
          required,
        },
      } : {
        checkup: {
          lastName: {
            required,
          },
          firstName: {
            required,
          },
          birthDate: {
            required,
          },
          sex: {
            required,
          },
          phoneNumber: {
            required,
            isValid: (value) => /^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/i.test(value),
          },
        },
        isPhoneConfirmed: {
          required,
        },
        queue: {
          required,
        },
      };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.queue = [];

      if (!this.$v.queue.required) {
        errors.queue.push('Необходимо выбрать очередь');
      }

      if (this.accountType === 0) {
        errors.patientId = [];

        if (!this.$v.checkup.patientId.required) {
          errors.patientId.push('Выберите существующего пользователя или создайте нового');
        }

        return errors;
      }

      errors.lastName = [];
      if (!this.$v.checkup.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.checkup.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.checkup.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.checkup.sex.required) {
        errors.sex.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.checkup.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.checkup.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение поля');
      }

      if (!this.isPhoneConfirmed) {
        errors.phoneNumber.push('Телефон не подтвержден');
      }

      return errors;
    },
    confirmButtonDisabled() {
      return this.$v.checkup.phoneNumber.$error
      || !this.$v.checkup.lastName.$model
      || !this.$v.checkup.firstName.$model
      || !this.$v.checkup.birthDate.$model
      || this.$v.checkup.sex.$model == null
      || this.isPhoneConfirmed
      || this.isPhoneConfirmLoading
      || this.isPhoneConfirmCodeSended;
    },
    saveButtonDisabled() {
      return !this.isSendSms || !this.isPhoneConfirmed;
    },
  },
  watch: {
    accountType() {
      if (this.accountType === 1) this.$v.$touch();
      if (this.accountType === 0) this.$v.$reset();
    },
  },
  async mounted() {
    this.$v.$reset();
    this.queueList = await this.$store.dispatch(this.$types.DUTY_QUEUE_LIST_FETCH);
  },
  methods: {
    parseDate(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    async changePhoneNumber(newValue) {
      const newPhoneNumber = await this.formatingPhoneNumber(newValue);
      if (newPhoneNumber) {
        this.$v.checkup.phoneNumber.$model = newPhoneNumber;
      }
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      try {
        this.isSaving = true;

        const personObject = this.accountType === 0
          ? this.selectedPerson : {
            phoneNumber: this.checkup.phoneNumber,
            lastName: this.checkup.lastName,
            firstName: this.checkup.firstName,
            middleName: this.checkup.middleName,
            birthDate: this.checkup.birthDate ? dateWithoutTime(formatISO(this.checkup.birthDate)) : null,
            sex: this.checkup.sex,
          };

        const params = {
          queue: this.queue,
          communicationType: this.checkup.communicationType,
          person: personObject,
          consultationBody: this.checkup.body,
        };

        const consultationId = await this.$store.dispatch(this.$types.DUTY_APPOINTMENT_CREATE, params);

        if (consultationId) {
          const fileToken = await this.$store.dispatch(this.$types.SCHEDULE_FILETOKEN_FETCH, consultationId);

          const documentsId = this.documents.map((doc) => doc.guid);

          await this.$store.dispatch(this.$types.SCHEDULE_CHECKUP_ADD_DOCUMENTS, {
            consultationId,
            documentsId,
            fileToken,
          });
        }
      } finally {
        this.isSaving = false;
      }
      window.location.reload();
    },
    async onPhoneConfirm() {
      this.$v.checkup.phoneNumber.$touch();

      if (this.$v.checkup.phoneNumber.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность номера телефона',
        });
        return;
      }

      try {
        this.isPhoneConfirmLoading = true;
        const phoneNumber = this.phoneFormatToSave(this.checkup.phoneNumber);
        await this.$store.dispatch(this.$types.PATIENT_PHONE_EXIST_CHECK, phoneNumber);
        await this.$store.dispatch(this.$types.SEND_CHECK_CODE, { phoneNumber });
        this.isSendSms = true;
        this.isPhoneConfirmCodeSended = true;
      } finally {
        this.isPhoneConfirmLoading = false;
      }
    },
    async onPhoneConfirmCodeCheck() {
      try {
        this.isPhoneConfirmLoading = true;
        const phoneNumber = this.phoneFormatToSave(this.checkup.phoneNumber);
        await this.$store.dispatch(this.$types.SENDED_CODE_CHECK, { code: this.phoneConfirmCode, phoneNumber });
        this.isPhoneConfirmed = true;
      } catch (err) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось подтвердить проверочный код',
        });
        this.isPhoneConfirmed = false;
      } finally {
        this.isPhoneConfirmLoading = false;
      }
    },
    onPhoneNumberChange() {
      this.isPhoneConfirmCodeSended = false;
      this.isSendSms = false;
      this.phoneConfirmCode = '';
      this.isPhoneConfirmed = false;
    },
    changeSearchField() {
      this.search.filter.PhoneNumber = null;
      this.search.filter.SearchFIO = null;
      this.search.filter.PolicyNumber = null;
    },
    async onClickSearch() {
      this.checkup.patientId = null;

      try {
        this.search.isLoading = true;
        this.search.error = null;
        this.search.patients = [];

        const patients = await this.$store.dispatch(this.$types.SCHEDULE_PATIENTS_SEARCH, this.search.filter);
        this.search.patients = patients;
      } catch (error) {
        console.log(error);
      } finally {
        this.search.isLoading = false;

        if (this.search.patients.length === 0) {
          if (this.search.field === 'phone') {
            this.search.error = 'Пользователь с таким номером телефона не найден.';
          }

          if (this.search.field === 'fio') {
            this.search.error = 'Пользователь с таким ФИО не найден.';
          }

          if (this.search.field === 'policy') {
            this.search.error = 'Пользователь с таким номером полиса не найден.';
          }
        }
      }
    },
    setPatient(patient) {
      this.selectedPerson = patient;

      if (this.checkup.patientId === patient.id) {
        this.checkup.patientId = null;
        this.checkup.parentId = null;
        return;
      }
      this.checkup.parentId = patient.parentId;
      this.checkup.patientId = patient.id;
    },
    onClickDeleteFile(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    saveFileCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        console.log(fileObject);
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData,
        });
        return null;
      });
    },
    onClickOpenFileModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка документов',
          saveFileCallback: this.saveFileCallback,
          fileApi: 'File/fileServer',
        },
      });
      // Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback, fileApi: 'File/fileServer' });
    },
    phoneFormatToSave(phone) {
      if (!phone) return '';

      return phone
        .split('+')
        .join('')
        .split(' ')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('-')
        .join('');
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-form-field{
  &.mg-tp {
    margin-top: 20px;
  }
  &-search {
    margin-right: 0!important;
  }
}

::v-deep.crm-form-fields {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.crm-form-block {
  &.w-100{
    width: 100%;
  }
}

::v-deep.crm-radio-buttons {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
      margin-right: 35px;
    }
  }

  .crm-radio-input:checked + .crm-radio-text {
    color: $blue;
  }

  &.vertical {
    flex-direction: column;

    .crm-radio-button {
      &:not(:last-child) {
        margin-bottom: 10px;
        margin-right: 0;
      }
    }
  }
}

::v-deep.crm-button-search {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  border-radius: 10px;

  .crm-icon-search path {
    fill: #fff;
  }
}

.search-error {
  margin-top: 10px;
  position: initial;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

::v-deep.patient-item {
  cursor: pointer;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px;
  display: flex;

  &.active {
    border: 1px solid #6E88F3;
  }

  &:hover {
    border: 1px solid #6E88F3;
  }
}

::v-deep.crm-filter-popover {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  .trigger {
    height: 100%;
  }

  .crm-icon-wrapper {
    cursor: pointer;
    height: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;

    &:hover {
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
  &.open {
    .crm-icon-wrapper {
      svg {
        path {
          fill: $blue;
        }
      }
    }
  }
}

::v-deep.crm-filter-popover-tooltip {
  background: #FFFFFF;
  box-shadow: 0px 1px 5px #C4C4C4;
  border-radius: 10px;
}

::v-deep.crm-button {
  &.width-unset {
    width: unset;
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
  }
}
::v-deep.crm-icon-cross {
  margin-right: 15px;
  path {
    fill: $blue;
  }
}

::v-deep.crm-file-list {
  margin-bottom: 20px;

  .file {
    margin-top: 20px;
  }
}

.patients {
  overflow: auto;
  max-width: 100%;
  padding-bottom: 20px;
}
</style>
