<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'История изменений'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <custom-scrollbar>
        <div
          v-if="rows.length > 0"
          class="crm-table"
        >
          <div class="crm-table-row crm-table-row--header">
            <div class="crm-table-cell crm-table-cell--date">
              Дата и время
            </div>
            <div class="crm-table-cell ">
              Действие
            </div>
          </div>
          <div
            v-for="(row, i) in rows"
            :key="i"
            class="crm-table-row"
          >
            <div class="crm-table-cell crm-table-cell--date">
              {{ formatDate(row.date) }}
            </div>
            <div class="crm-table-cell">
              {{ row.action }}
            </div>
          </div>
        </div>

        <div v-else>
          Нет данных
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  addHours,
} from 'date-fns';
import Loading from '@/components/Loading';

export default {
  name: 'ClinicHistoryModal',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    clinicId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
    };
  },
  async created() {
    this.isLoading = true;
    this.rows = await this.$store.dispatch(this.$types.CLINIC_HISTORY_FETCH, this.clinicId);
    this.isLoading = false;
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
