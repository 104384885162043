<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Ответственные лица"
    :hide-footer="true"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-input
        v-model="fio"
        placeholder="Введите фио ответственного лица"
        class="mb-3"
        debounce="300"
      />

      <div v-if="usersFiltered.length === 0">
        Не найдено
      </div>

      <b-table
        v-else
        :fields="fields"
        :items="usersFiltered"
        bordered
      >
        <template #cell(actions)="row">
          <div class="d-flex">
            <b-button
              variant="primary"
              size="sm"
              class="ml-auto"
              @click="openResponsibleClinicsModal(row.item)"
            >
              Клиники
            </b-button>
          </div>
        </template>
      </b-table>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'ClinicResponsibleModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      fio: '',
      isOpen: true,
      fields: [
        { key: 'displayName', label: 'ФИО' },
        { key: 'actions', label: '' },
      ],
    };
  },
  computed: {
    users() {
      return this.$store.state.Users.users.filter((u) => u.displayName);
    },
    usersFiltered() {
      if (!this.fio) return this.users;

      return this.users.filter((user) => user.displayName.toLowerCase().includes(this.fio.toLowerCase()));
    },
    isLoading() {
      return this.$store.state.Users.isLoading;
    },

  },
  async created() {
    await this.$store.dispatch(this.$types.GET_USERS);
    await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH, { save: true });
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openResponsibleClinicsModal(responsible) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'ClinicResponsibleClinicsModal', props: { responsible } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
