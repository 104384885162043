<template>
  <b-modal
    id="shedule"
    visible
    scrollable
    header-class="p-2 border-bottom-0"
    body-class="p-0"
    centered
    :hide-footer="true"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>
    <template v-else>
      <div class="schedule">
        <div class="schedule-header">
          <p class="schedule-header-text">
            {{ formatData(date) }}
          </p>
        </div>
        <div class="schedule-body">
          <table class="w-100">
            <tbody>
              <tr
                v-for="(row) in timeRows"
                :key="row.time"
                class="table"
              >
                <div
                  class="item-container"
                  :class="{'border-tr': row.time === selectedTime.slice(0,5)}"
                >
                  <td class="item-td-time">
                    {{ row.time }}
                  </td>
                  <td
                    v-for="(slot, i) in row.slots"
                    :key="i"
                    class="item-td mr-1"
                    :class="getColorSlot(row)"
                  >
                    <span>{{ slot?.slotInfo?.doctor?.lastName }}</span>
                  </td>
                </div>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { format, parseISO } from 'date-fns';
import { ru } from 'date-fns/locale';

export default {
  name: 'DoctorScheduleInfoModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    slots: {
      type: Array,
      default: () => ([]),
    },
    selectedTime: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    timeRows() {
      const rows = [];

      this.slots.forEach((item) => {
        const slotTime = this.formatTime(item.startDate);

        const row = rows.find((r) => r.time === slotTime);

        if (!row) {
          rows.push({
            time: slotTime,
            slots: [item],
          });
        } else {
          row.slots.push(item);
        }
      });

      return rows;
    },
    date() {
      return this.slots[0].startDate;
    },
  },
  methods: {
    formatData(date) {
      return format(new Date(date), 'dd MMMM, iiii', { locale: ru });
    },
    formatTime(date) {
      return format(parseISO(date), 'HH:mm');
    },
    getColorSlot(row) {
      switch (row.slots.length) {
        case 0:
        case 1:
          return 'yellow';
        case 2:
          return 'light-green';
        case 3:
          return 'green';
        case 4:
          return 'light-red';
        case 5:
          return 'red';
        default:
          return 'dark-red';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule {
  padding: 10px ;
  &-header {
    background: rgb(241, 237, 237);
    border-radius: 4px;
    &-text {
      text-align: center;
    }
  }
  &-body {
    width: 100%;
    display: flex;
  }
}

.table {
  display: flex;
  flex-flow: row no-wrap;
  justify-content: start;
  gap:5px;
    th, td {
      padding: 8px;
      vertical-align: top;
      border-top: none;
    }
}
.item-container {
  display: flex;
}
.item-td-time {
  text-align: center;
  width: 70px;
}

.item-td{
  background: rgb(125, 206, 141);
  border-radius: 5px;
  color: white;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
}

.border-tr {
  outline: 1px solid rgb(81, 81, 240);
  outline-offset: 5px;
  border-radius: 4px;
}

.yellow {
  background-color: rgb(235, 247, 168);
}
.light-green {
  background-color: rgb(182, 233, 140);
}
.green {
  background-color: rgb(104, 224, 110);
}
.light-red {
  background-color: rgb(238, 164, 134);
}
.red {
  background-color: rgb(228, 123, 81);
}
.dark-red {
  background-color: rgb(150, 23, 23);
}

</style>
