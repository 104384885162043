<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить {{ isReferral ? 'направление': 'гарантийное письмо' }}?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            @click="onClickDelete"
          >
            Удалить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'IndemnityLetterDeleteModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    indemnityLetterId: {
      type: String,
      default: null,
    },
    policyId: {
      type: Number,
      default: null,
    },
    clinicId: {
      type: String,
      default: '',
    },
    isReferral: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      this.isDeleting = true;

      try {
        await this.$store.dispatch(this.$types.INDEMNITY_LETTER_DELETE, { id: this.indemnityLetterId, isReferral: this.isReferral });
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
        this.$emit('vuedals:close');

        if (this.$store.state.Indemnity.letters.length) Bus.$emit('indemnity-history-modal:fetch-letters');

        if (this.clinicId) {
          await this.$store.dispatch(this.$types.INDEMNITY_LETTERS_GET_BY_CLINIC, this.clinicId);
        } else {
          await this.$store.dispatch(this.$types.INDEMNITY_LIST_NAMES_FETCH, {
            personId: this.$store.state.Indemnity.personId,
            policyId: this.policyId,
            isReferral: this.isReferral,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
