<!--  eslint-disable  -->

<template>
  <b-modal
    v-model="modalState"
    scrollable
    centered
    size="lg"
    title="Выбор из справочника"
    @hidden="onClose"
    @ok="onClose"
  >
    <template>
      <div class="flex flex-col gap-y-[16px] select-from-reestr-modal">
        <div class="grid grid-cols-3 gap-x-[8px]">
          <div class="flex flex-col gap-y-[8px]">
            <base-input label="Название" v-model="filters.name" />
          </div>

          <div class="flex flex-col gap-y-[8px]">
            <base-input label="ИНН" v-model="filters.inn" />
          </div>

          <div class="flex flex-col gap-y-[8px]">
            <base-input label="ОГРН" v-model="filters.ogrn" />
          </div>
        </div>

        <div class="flex items-center justify-end gap-x-[8px]">
          <b-button
            variant="secondary"
            @click="resetFilter"
            :type="$remoteMonitoringConst.PRIMARY_BUTTON"
          >
            Сбросить фильтры
          </b-button>

          <b-button
            variant="primary"
            :disabled="filtersAreEmpty || isLoading"
            :type="$remoteMonitoringConst.PRIMARY_BUTTON"
            @click="fetchOrganizations(true)"
          >
            <b-spinner v-if="isLoading" small />
            <span v-else>
              Показать
            </span>
          </b-button>
        </div>

        <div v-if="organizations" class="content">
          <div class="crm-label">
            Результаты поиска
          </div>

          <div class="content-empty" v-if="!organizations.length">
            <IconEmpty />
            <p>По вашему запросу ничего не найдено</p>
          </div>

          <AsyncList
            v-else
            class="async-list"
            :is-all-items-loaded="isAllItemsLoaded"
            :disable-toggle-header="true"
            @load-more-items="loadMoreItems"
          >
            <ReestrOrganizationCard
              v-for="organization in organizations"
              :key="organization.nsiId"
              :organization="organization"
              :selected-organization="selectedOrganization"
              @onSelect="onSelectOrganization"
            />
          </AsyncList>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        :type="$remoteMonitoringConst.PRIMARY_BUTTON"
        variant="primary"
        :disabled="isLoading || !selectedOrganization?.nameFull"
        @click="onConfirm"
      >
        <b-spinner v-if="isLoading" small />
        <span v-else>
          Сохранить
        </span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { BaseInput } from '@/components/base'
import IconEmpty from '@/assets/images/icon-empty.svg'
import ReestrOrganizationCard from '../ReestrOrganizationCard.vue'
import AsyncList from '@/components/AsyncList'

import medicalOrganizationService from '../../../services/medical-organization/medical-organization.service'

export default {
  name: 'SelectFromReestrModal',
  components: { BaseInput, IconEmpty, ReestrOrganizationCard, AsyncList },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      organizations: null,
      isAllItemsLoaded: false,
      selectedOrganization: {},
      filters: {
        name: null,
        inn: null,
        ogrn: null,
        limit: 50,
        skip: 0
      }
    }
  },
  computed: {
    modalState: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    filtersAreEmpty() {
      return !!(!this.filters.name && !this.filters.inn && !this.filters.ogrn)
    }
  },
  mounted() {},
  methods: {
    onClose() {
      this.modalState = false
    },
    resetFilter() {
      this.filters = {
        name: null,
        inn: null,
        ogrn: null
      }
      this.organizations = null
    },
    async fetchOrganizations(clear=false) {
      this.isLoading = true
      this.isAllItemsLoaded = false

      if(clear) {
        this.organizations = null;
      }

      this.filters.skip = this.organizations ? this.organizations.length : 0

      if (!this.isAllItemsLoaded) {
        const response = await medicalOrganizationService.getNSIList(
          this.filters
        )

        this.organizations = this.organizations
          ? [...this.organizations, ...response]
          : response

        if (this.organizations.length < this.filters.limit) {
          this.isAllItemsLoaded = true
        }
      }

      this.isLoading = false
    },
    async loadMoreItems() {
      await this.fetchOrganizations()
    },
    onConfirm() {
      this.$emit('onSelect', this.selectedOrganization);
      this.onClose();
    },
    onSelectOrganization(organization) {
        this.selectedOrganization = organization;
    }
  }
}
</script>

<style lang="scss">
.select-from-reestr-modal {
  .crm-label {
    margin-bottom: 8px;
    color: #6c757d;
    font-size: 14px;
    font-weight: 400;
  }

  .crm-base-input {
    height: 38px;
    border: 1px solid #ced4da;
    padding: 0px 12px;
    border-radius: 4px;
  }

  .async-list {
    height: 50vh;
  }

  .__panel {
    padding: 0;
  }

  .content {
    padding: 16px;
    min-height: 300px;

    &-empty {
      height: 300px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        color: #adb5bd;
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
</style>
