/* eslint-disable import/prefer-default-export */export const mixinRoles = {
  methods: {
    checkRoleAccess(roles) {
      const { role } = this.$store.state.Auth;
      return roles.includes(role);
    },
    checkFeatureAccess({ name, url } = {}) {
      const checkFeature = (nameUrl, pages = []) => pages.some((p) => {
        const foundFeature = `${p.name}${p.url}` === nameUrl;
        return foundFeature || (p.pages?.length && checkFeature(nameUrl, p.pages));
      });

      const rolePages = this.$store.state.Auth.user.roles[0]?.pages || [];
      const featureNameUrl = `${name}${url}`;

      return checkFeature(featureNameUrl, rolePages);
    },
  },
};
