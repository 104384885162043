<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить файл?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            class="crm-button"
            @click="onClickDelete"
          >
            Удалить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
// import Bus from '@/eventBus';
import { api } from '@/helpers/api';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'AppealDeleteFileModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    deleteFileCallback: {
      type: Function,
      default: () => {},
    },
    saveAppealCallback: {
      type: Function,
      default: () => {},
    },
    fileId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      try {
        this.isDeleting = true;
        await api.delete('File', { data: [this.fileId] });

        this.$emit('vuedals:close');
        this.deleteFileCallback(this.fileId);
        this.saveAppealCallback(true);
      } catch (e) {
        console.log(e);
      } finally {
        this.isDeleting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
