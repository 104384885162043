<template>
  <div class="page-dm-import">
    <d-remote-monitoring-import
      :is-gov-user="isGovUser"
      :has-doctor-id="hasDoctorId"
      class="page-dm-import__monitoring"
      @openDoctorAuth="openDoctorAuth"
    />
  </div>
</template>

<script>
import { APP_DOCTOR_URL } from '@/app.config';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'RemoteMonitoringImport',
  page: {
    title: 'CRM Doctis - Импорт дистанционный мониторинг',
  },
  computed: {
    isGovUser() {
      const role = this.$store.state.Auth.user.roles[0]?.name;
      return role ? role.startsWith('gov.') : false;
    },
    hasDoctorId() {
      return this.$store.state.Auth.user.doctorId;
    },
  },
  methods: {
    async openDoctorAuth() {
      try {
        if (this.$store.state.Auth.user.doctorId) {
          const id = this.$store.state.Auth.user.doctorId;
          const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, id);
          const doctorUrl = APP_DOCTOR_URL;
          const url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;
          window.location.href = url;
        }
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось авторизовать врача',
        });
      }
    },
  },
};
</script>

<style lang="scss">
  .page-dm-import {
    width: 100%;
    overflow: auto;
  }
</style>
