<template>
  <div class="clinics">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      class="clinic-list"
      @load-more-items="loadMoreItems"
    >
      <b-container size="xl">
        <div class="d-flex py-4 justify-content-between">
          <div class="h3 mb-0">
            Список клиник
          </div>

          <div class="d-flex">
            <b-button
              v-if="checkRoleAccess(['Administrator', 'polis.operator', 'Operator.gostelemed', 'Dis.monitoring.operator', 'Tech.operator'])"
              :type="$const.PRIMARY_BUTTON"
              variant="primary"
              class="ml-2"
              @click="openAddModal"
            >
              <b-icon icon="plus" />
              <span>
                Новая клиника
              </span>
            </b-button>
            <b-button
              v-if="checkFeatureAccess({ name: 'Кнопка добавления города', url: '/clinics' })"
              class="ml-2"
              variant="primary"
              @click="openAddCityModal"
            >
              <b-icon icon="plus" />
              Добавить город
            </b-button>
          </div>
        </div>

        <clinic-search-panel
          v-if="checkFeatureAccess({ name: 'Поисковое поле', url: '/clinics' })"
          v-model="filter"
          :filter-selected="filterSelected"
          class=" mb-4"
          @search="clinicsFetch(true)"
        />

        <div class=" crm-title">
          <!--          <template-->
          <!--            v-if="totalCount"-->
          <!--          >-->
          <!--            <div class="crm-info page-subtitle">-->
          <!--              <span>Данные клиники</span>-->
          <!--            </div>-->
          <!--            <div class="extra-crm-info page-subtitle">-->
          <!--              <span>Дополнительная информация</span>-->
          <!--            </div>-->
          <!--          </template>-->

          <span
            v-if="!totalCount && isAllItemsLoaded && isSearched"
            class="crm-info"
          >
            По выбранному фильтру ничего не найдено
          </span>
        </div>

        <div
          v-if="actualClinics.length"
        >
          <div class="h5 mb-3">
            Актуальные клиники
          </div>

          <div
            v-for="clinic in actualClinics"
            :key="clinic.id"
          >
            <clinic-info
              :clinic="clinic"
              class="clinic-info"
            />
          </div>
        </div>

        <div
          v-if="clinicsDeleted.length"
          class="mt-4"
        >
          <div class="h5 mb-3">
            Удаленные клиники
          </div>

          <div
            v-for="clinic in clinicsDeleted"
            :key="clinic.id"
          >
            <clinic-info
              :clinic="clinic"
              class="clinic-info"
            />
          </div>
        </div>
      </b-container>
    </async-list>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

import ClinicSearchPanel from '@/components/Clinics/ClinicSearchPanel';
import AsyncList from '@/components/AsyncList';
import ClinicInfo from '@/components/Clinics/ClinicInfo';

const LIMIT = 15;
const FETCH_DELAY = 700;

export default {
  name: 'Clinics',
  page: {
    title: 'CRM Doctis - Клиники',
  },
  components: {
    ClinicSearchPanel,
    AsyncList,
    ClinicInfo,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isAllItemsLoaded: true,
      isSearched: false,
      isLoading: false,
      isCheckDeleted: false,
      limit: LIMIT,
      filter: {
        query: '',
        network: null,
        city: null,
        inn: null,
        services: null,
        clinicAttribute: null,
        isPopoverOpen: false,
      },
      filterKeys: [
        'network',
        'city',
        'services',
        'clinicAttribute',
      ],
    };
  },
  computed: {
    clinics() {
      return this.$store.state.Clinic.clinics;
    },
    actualClinics() {
      return this.clinics.filter((item) => !item.isDeleted);
    },
    clinicsDeleted() {
      return this.clinics.filter((item) => item.isDeleted);
    },
    totalCount() {
      return this.clinics.length;
    },
    totalCountDeleted() {
      return this.clinicsDeleted.length;
    },
    filterSelected() {
      const filters = this.filterKeys.map((item) => this.filter[item]);
      return Object.values(filters).some((item) => {
        if (Array.isArray(item)) return item.length;
        return item || item === 0;
      });
    },
  },
  watch: {
    filter: {
      handler(filter) {
        localStorage.setItem('clinic-filter', JSON.stringify(filter));
        // this.clinicsFetchDebounced(true);
      },
      deep: true,
    },
  },
  created() {
    Bus.$on('clinic-search:reset-filter', this.resetFilter);
    if (localStorage.getItem('clinic-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('clinic-filter'));
        this.filter = { ...filter };
      } catch (e) {
        localStorage.removeItem('clinic-filter');
      }
    }

    this.clinicsFetchDebounced = debounce(this.clinicsFetch, FETCH_DELAY);
    if (!this.checkFeatureAccess({ name: 'Поисковое поле', url: '/clinics' })) this.clinicsFetch();
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CLINICS_SET, []);
    Bus.$off('clinic-search:reset-filter', this.resetFilter);
  },
  methods: {
    resetFilter() {
      localStorage.removeItem('clinic-filter');
      this.filter = {
        query: '',
        network: null,
        city: null,
        inn: null,
        clinicAttribute: null,
        services: [],
      };
      this.$store.commit(this.$types.CLINICS_SET, []);
      this.isAllItemsLoaded = true;
      this.isSearched = false;
    },
    async clinicsFetch(clear) {
      if (this.isLoading) return;
      if (clear) this.isCheckDeleted = false;

      this.isSearched = true;
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevTotalDeleted = clear ? 0 : this.totalCountDeleted;

      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        filter: this.filter,
        clear: !!clear,
        save: true,
      };

      const requestObjectDeleted = {
        ...requestObject,
        skip: prevTotalDeleted,
        isDeleted: true,
        save: true,
        clear: false,
      };

      try {
        if (!this.isCheckDeleted) await this.$store.dispatch(this.$types.CLINICS_FETCH, requestObject);

        if (prevTotal + this.limit > this.totalCount) {
          this.isCheckDeleted = true;
        }

        if (this.isCheckDeleted) {
          await this.$store.dispatch(this.$types.CLINICS_FETCH, requestObjectDeleted);

          if (prevTotalDeleted + this.limit > this.totalCountDeleted) {
            this.isAllItemsLoaded = true;
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    openAddModal() {
      Bus.$emit('open-modal:clinic-edit');
    },
    openAddCityModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'CityCreateModal',
      });
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.clinicsFetch(false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clinics {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.crm-title {
  display: flex;
  margin-bottom: 20px;
}
.crm-info,
.extra-crm-info {
  display: flex;
  padding: 0 30px;
  box-sizing: border-box;
}
.crm-info {
  flex-basis: 486px;
}
.extra-crm-info {
  flex-basis: 724px;
}
.clinic-info {

  &:not(last-child) {
    margin-bottom: 10px;
  }
}
.clinic-list {
  height: 100%;
}
.clinic-list-inner {
  min-height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
}
</style>
