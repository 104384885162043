import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.COMPANIES_SET](state, payload) {
    state.companies = payload;
  },
  [localTypes.COMPANIES_LOADING_SET](state, payload) {
    state.isCompaniesLoading = payload;
  },
  [localTypes.COMPANY_SET](state, payload) {
    state.company = payload;
  },
  [localTypes.COMPANY_LOADING_SET](state, payload) {
    state.isCompanyLoading = payload;
  },

  [localTypes.PROGRAMS_SET](state, payload) {
    state.programs = payload;
  },
  [localTypes.PROGRAMS_LOADING_SET](state, payload) {
    state.isProgramsLoading = payload;
  },
  [localTypes.PROGRAM_SET](state, payload) {
    state.program = payload;
  },
  [localTypes.PROGRAM_LOADING_SET](state, payload) {
    state.isProgramLoading = payload;
  },

  [localTypes.SUBPROGRAMS_SET](state, payload) {
    state.subprograms = payload;
  },
  [localTypes.SUBPROGRAMS_LOADING_SET](state, payload) {
    state.isSubprogramsLoading = payload;
  },
  [localTypes.SUBPROGRAM_SET](state, payload) {
    state.subprogram = payload;
  },
  [localTypes.SUBPROGRAM_LOADING_SET](state, payload) {
    state.isSubprogramLoading = payload;
  },
  [localTypes.SECTION_TYPE_SET](state, payload) {
    state.sectionInfo = payload;
  },
  [localTypes.SECTION_TYPE_SET](state, payload) {
    state.sectionInfo = payload;
  },
  [localTypes.KNOWLEDGE_DOCUMENTS_SET](state, payload) {
    state.knowledgeDocuments = payload;
  },
  [localTypes.KNOWLEDGE_DOCUMENTS_CLEAR](state) {
    state.knowledgeDocuments = [];
  },
};
