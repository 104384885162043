<template>
  <div class="contact-info">
    <p class="crm-title">
      {{ title }}
    </p>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContactsInfo',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-info {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  background: $white;
  border: 1px solid $gray;
  box-sizing: border-box;
  /* Тень для плашек 1 */

  box-shadow: 0px 1px 2px $gray-shadow;
  border-radius: 5px;
}
.crm-title {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #908F8D;
  margin: 0 0 20px 0;
}
</style>
