<template>
  <div class="list-wrapper">
    <div v-if="!activePolicies.length > 0">
      Нет полисов
    </div>
    <div
      v-for="(policy, index) in activePolicies"
      v-else
      :key="policy.policyId + index"
      :class="['person-policy', 'active-policy', { 'selected-policy': policy.id === selectedPolicyId }]"
      @click="selectPolicyHandler(policy)"
    >
      <div class="policy-info policy-info-main">
        <div class="policy-name">
          <template v-if="policy.isDMS">
            <template v-if="policy.companyName">
              {{ policy.companyName }}. <br>
            </template>
            <template v-if="policy.programName">
              {{ policy.programName }}. <br>
            </template>
            {{ policy.insuranceName }}
          </template>
          <template v-else>
            {{ policy.insuranceName }}
          </template>
        </div>
        <div class="policy-fullname">
          {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
        </div>

        <div class="policy-ensurerName">
          {{ policy.ensurerName }}
        </div>
        <div class="policy-id">
          № {{ policy.policyId }}
        </div>
        <div
          v-if="policy.startDate && policy.endDate"
          class="policy-date"
        >
          {{ parseData(policy.startDate) }} - {{ parseData(policy.endDate) }}
        </div>
        <div
          v-if="!policy.startDate && policy.endDate"
          class="policy-date"
        >
          до {{ parseData(policy.endDate) }}
        </div>
      </div>

      <div
        class="policy-controls"
      >
        <!-- checkFeatureAccess({ name: 'Создать ГП', url: '/appeals' }) -->
        <b-button
          v-if="checkIndemnityCondition(policy) && !checkRoleAccess(['polis.operator', 'polis.operator.dop'])"
          variant="light"
          size="sm"
          class="border p-0"
          title="Создать ГП"
          @click="openIndemnityModal(policy, $event)"
        >
          <icon-worksheet />
        </b-button>
        <!-- checkFeatureAccess({ name: 'Создать направление', url: '/appeals' }) -->
        <b-button
          v-if="checkIndemnityCondition(policy) && !checkRoleAccess(['polis.operator', 'polis.operator.dop'])"
          variant="light"
          size="sm"
          class="border mt-2 p-1"
          title="Создать направление"
          @click="openIndemnityModal(policy, $event, true)"
        >
          <b-icon
            icon="file-earmark-medical"
            class="text-info"
          />
        </b-button>
        <b-button
          variant="light"
          size="sm"
          class="border mt-2 p-0"
          title="Детальная информация"
          @click="openPolicyDetail(policy, $event)"
        >
          <icon-info />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  format,
  // formatISO,
  parseISO,
} from 'date-fns';
import IconWorksheet from 'assets/images/worksheet.svg';
import IconInfo from 'assets/images/info.svg';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

export default {
  name: 'PolicyList',
  components: {
    IconWorksheet,
    IconInfo,
  },
  mixins: [mixinRoles],
  props: {
    personData: {
      type: Object,
      default: () => ({}),
    },
    activePolicies: {
      type: Array,
      default: () => [],
    },

    selectedPolicyId: {
      type: Number,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentDay: new Date(),
    };
  },
  computed: {},

  methods: {
    selectPolicyHandler(policy) {
      if (!this.disabled) {
        this.$emit('selectPolicy', policy);
      }
    },
    checkIndemnityCondition(policy) {
      const dateArray = [];
      dateArray.push(this.currentDay >= new Date(policy.startDate), this.currentDay <= new Date(policy.endDate));

      if (!dateArray.includes(false)) {
        if (policy.policyStatus === 3 && policy.isActivated) {
          return true;
        }
        if (policy.policyStatus === 2 && !policy.isActivated) {
          return true;
        }
        return true;
      }
      return false;
    },
    isSelectedPolicy(policyId) {
      return this.selectedPolicyId === policyId;
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openIndemnityModal(policy, event, isReferral) {
      if (this.isSelectedPolicy(policy.id)) {
        event.stopPropagation();
        Bus.$emit('open-modal:indemnity-modal', {
          personId: policy.personId, policyId: policy.id, subProgramId: policy.subProgramV2Id, isReferral,
        });
      }
    },
    openPatientDetail(personId) {
      Bus.$emit('open-modal:patient-detail', personId);
    },
    openPolicyDetail(policy, event) {
      if (this.isSelectedPolicy(policy.id)) {
        event.stopPropagation();
        this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, policy.id);
        this.openPatientDetail(this.personData.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.selected-policy {
  opacity: 1 !important;
}
.person-policy {
  cursor: pointer;
  opacity: .3;
  display: flex;
  align-items: flex-start;
  margin: 5px;
  width: 260px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;

  &.active-policy {
    border: 1px solid #219653;
  }
  &.pending-policy {
    border: 1px solid #F2994A;
  }
  &.inactive-policy {
    border: 1px solid #EB5757;
  }

  &:hover {
    opacity: .5;
  }
}

.policy-info {
  word-break: break-word;
  margin-right: auto;
}

.policy-info-main {
  font-size: 16px;
  line-height: 20px;
}

.policy-name {
  font-weight: 600;
}

.policy-controls {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.crm-control-icon {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}
</style>
