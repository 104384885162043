<template>
  <div>
    <div class="d-flex">
      <b-input-group class="bg-white border rounded">
        <b-form-input
          v-model="query"
          type="search"
          placeholder="Введите название промокода"
          class="border-0"
          @input="deferredRequest"
          @keyup.enter="$emit('search')"
        />
        <b-button
          variant="outline-primary"
          class="rounded-0 border-0"
          @click="$emit('search')"
        >
          <b-icon icon="search" />
        </b-button>
      </b-input-group>

      <slot name="search-right" />
    </div>

    <div
      v-if="$slots.filters"
      style="z-index: 2"
      class="mt-4 position-relative"
    >
      <slot name="filters" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LaboratoryPromocodesSearchPanel',

  data: () => ({
    deferred: null,
  }),
  computed: {
    query: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },

  methods: {
    deferredRequest() {
      clearTimeout(this.deferred);

      this.deferred = setTimeout(() => {
        this.$emit('search');
      }, 1200);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
