<template>
  <div class="scrollbar-wrapper">
    <custom-scrollbar>
      <Preloader v-if="isLoading" />
      <div
        v-else
        class="inner-wrapper"
      >
        <div>
          <div
            v-for="(date, index) in $v.registerInfo.dates.$each.$iter"
            :key="index"
            class="crm-row"
          >
            <div class="crm-row-item">
              <div class="crm-input-label">
                Месяц
              </div>
              <div class="crm-date">
                <v-select
                  v-model="date.month.$model"
                  :reduce="month => month.id"
                  :options="monthsList"
                  placeholder="Выберите месяц"
                  label="title"
                />
              </div>
            </div>
            <div class="crm-row-item">
              <div class="crm-input-label">
                Год
              </div>
              <div class="crm-date">
                <v-select
                  v-model="date.year.$model"
                  :options="availableYears"
                  placeholder="Выберите год"
                />
              </div>
              <div
                v-if="date.year.$error"
                class="error-text"
              >
                Укажите корректный год
              </div>
            </div>
            <div
              class="crm-row-item"
              style="width: 30px"
            >
              <IconDelete
                v-if="index != 0"
                class="crm-icon-delete"
                @click="() => deleteDateItem(+index)"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            class="text-btn"
            @click="addDateItem"
          >
            Добавить дату
          </div>
        </div>

        <div
          class="crm-row"
          style="display: block"
        >
          <div class="input-label">
            Период (из загруженного столбца реестров)
          </div>
          <div class="messages-datepickers">
            <base-date-picker
              v-model="$v.registerInfo.dateFromRegistryPeriod.from.$model"
              class="datepicker"
            />
            <div class="divider">
              <div class="divider-row" />
            </div>
            <base-date-picker
              v-model="$v.registerInfo.dateFromRegistryPeriod.to.$model"
              class="datepicker datepicker-right"
            />
          </div>
          <div
            v-if="$v.registerInfo.dateFromRegistryPeriod.$error"
            class="error-text"
          >
            {{ printError(errorsValidation.dateFromRegistryPeriod) }}
          </div>
        </div>

        <div
          class="crm-row"
          style="display: block"
        >
          <div class="input-label">
            Период (по дате загрузки реестра)
          </div>
          <div class="messages-datepickers">
            <base-date-picker
              v-model="$v.registerInfo.registryImportDatePeriod.from.$model"
              class="datepicker"
            />
            <div class="divider">
              <div class="divider-row" />
            </div>
            <base-date-picker
              v-model="$v.registerInfo.registryImportDatePeriod.to.$model"
              class="datepicker datepicker-right"
            />
          </div>
          <div
            v-if="$v.registerInfo.registryImportDatePeriod.$error"
            class="error-text"
          >
            {{ printError(errorsValidation.registryImportDatePeriod) }}
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="input-label">
              Юридическое лицо
            </div>
            <v-select
              v-model="$v.registerInfo.legalPersons.$model"
              :options="legalPersonsList"
              label="name"
              placeholder="Выберите юридическое лицо"
            />
            <div
              v-if="$v.registerInfo.legalPersons.$error"
              class="error-text"
            >
              {{ printError(errorsValidation.legalPersons) }}
            </div>
          </div>
        </div>
        <!-- <div>
          <div class="text-btn" @click="addLegalPersonItem">Добавить юридическое лицо</div>
        </div> -->

        <div class="crm-row">
          <div class="crm-column">
            <div class="input-label">
              Страховая компания
            </div>
            <v-select
              v-model="$v.registerInfo.insuranceCompany.$model"
              :options="insuranceCompaniesList"
              label="title"
              placeholder="Выберите компанию"
              @input="changeInsuranceCompany"
            />
            <div
              v-if="$v.registerInfo.insuranceCompany.$error"
              class="error-text"
            >
              {{ printError(errorsValidation.insuranceCompany) }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="input-label">
              Страховая программа
            </div>
            <v-select
              v-model="$v.registerInfo.insuranceProgram.$model"
              :options="insuranceProgramList"
              label="title"
              placeholder="Выберите программу"
              @input="changeInsuranceProgram"
            />
            <div
              v-if="$v.registerInfo.insuranceProgram.$error"
              class="error-text"
            >
              {{ printError(errorsValidation.insuranceProgram) }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="input-label">
              Страховая подпрограмма
            </div>
            <v-select
              v-model="$v.registerInfo.insuranceSubProgram.$model"
              :options="insuranceSubProgramList"
              label="title"
              placeholder="Выберите подпрограмму"
            />
            <div
              v-if="$v.registerInfo.insuranceSubProgram.$error"
              class="error-text"
            >
              {{ printError(errorsValidation.insuranceSubProgram) }}
            </div>
          </div>
        </div>

        <div class="crm-row">
          <div class="crm-column">
            <div class="input-label">
              Номер акта
            </div>
            <base-input
              v-model="$v.registerInfo.actNumber.$model"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              placeholder="Введите номер"
              fluid
            />
            <div
              v-if="$v.registerInfo.actNumber.$error"
              class="error-text"
            >
              {{ printError(errorsValidation.actNumber) }}
            </div>
          </div>
        </div>

        <div class="wrapper-buttons">
          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button"
            @click="onClickSave"
          >
            Экспортировать
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseDatePicker,
  BaseInput,
} from '@/components/base';
import { showMessage, saveFile } from '@/helpers/utils';
import { required } from 'vuelidate/lib/validators';

import Preloader from '@/components/Preloader';
import formatISO from 'date-fns/formatISO';
import IconDelete from 'assets/images/delete_icon.svg';

export default {
  name: 'LegalEntityReestrExportModal',
  components: {
    BaseButton,
    BaseDatePicker,
    Preloader,
    BaseInput,
    IconDelete,
  },
  props: {
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      registerInfo: {
        dates: [
          { month: null, year: null },
        ],
        dateFromRegistryPeriod: { from: null, to: null },
        registryImportDatePeriod: { from: null, to: null },
        legalPersons: null,
        insuranceCompany: null,
        insuranceProgram: null,
        insuranceSubProgram: null,
        actNumber: null,
      },
      isLoading: false,
      legalPersonsList: [],
      insuranceCompaniesRawData: [],
      monthsList: [
        { id: 1, title: 'Январь' },
        { id: 2, title: 'Февраль' },
        { id: 3, title: 'Март' },
        { id: 4, title: 'Апрель' },
        { id: 5, title: 'Май' },
        { id: 6, title: 'Июнь' },
        { id: 7, title: 'Июль' },
        { id: 8, title: 'Август' },
        { id: 9, title: 'Сентябрь' },
        { id: 10, title: 'Октябрь' },
        { id: 11, title: 'Ноябрь' },
        { id: 12, title: 'Декабрь' },
      ],
    };
  },
  validations() {
    return {
      registerInfo: {
        dates: {
          $each: {
            year: {
              required: this.yearErrorShow ? required : () => true,
            },
            month: {},
          },
        },
        dateFromRegistryPeriod: {
          from: {
            // required,
          },
          to: {
            // required,
          },
        },
        registryImportDatePeriod: {
          from: {
            // required,
          },
          to: {
            // required,
          },
        },
        legalPersons: {
          // required,
        },
        insuranceCompany: {
          // required,
        },
        insuranceProgram: {
          // required,
        },
        insuranceSubProgram: {
          // required,
        },
        actNumber: {
          // required,
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.dateFromRegistryPeriod = [];
      if (!this.$v.registerInfo.dateFromRegistryPeriod.from.required || !this.$v.registerInfo.dateFromRegistryPeriod.to.required) {
        errors.dateFromRegistryPeriod.push('Укажите корректный период');
      }

      errors.registryImportDatePeriod = [];
      if (!this.$v.registerInfo.registryImportDatePeriod.from.required || !this.$v.registerInfo.registryImportDatePeriod.to.required) {
        errors.registryImportDatePeriod.push('Укажите корректный период');
      }

      errors.legalPersons = [];
      if (!this.$v.registerInfo.legalPersons.required) {
        errors.legalPersons.push('Укажите юридическое лицо');
      }

      errors.insuranceCompany = [];
      if (!this.$v.registerInfo.insuranceCompany.required) {
        errors.insuranceCompany.push('Укажите страховую компанию');
      }

      errors.insuranceProgram = [];
      if (!this.$v.registerInfo.insuranceProgram.required) {
        errors.insuranceProgram.push('Укажите страховую программу');
      }

      errors.insuranceSubProgram = [];
      if (!this.$v.registerInfo.insuranceSubProgram.required) {
        errors.insuranceSubProgram.push('Укажите страховую подпрограмму');
      }

      errors.actNumber = [];
      if (!this.$v.registerInfo.actNumber.required) {
        errors.actNumber.push('Укажите номер акта');
      }

      return errors;
    },
    insuranceCompaniesList() {
      return this.insuranceCompaniesRawData.map((item) => ({
        id: item.company.id,
        title: item.company.title,
        programs: item.programsWithSubprograms,
      }));
    },

    insuranceProgramList() {
      if (!this.registerInfo.insuranceCompany || !this.registerInfo.insuranceCompany.programs) return [];

      return this.registerInfo.insuranceCompany.programs.map((item) => ({
        id: item.program.id,
        title: item.program.name,
        subPrograms: item.subPrograms,
      }));
    },

    insuranceSubProgramList() {
      if (!this.registerInfo.insuranceProgram || !this.registerInfo.insuranceProgram.subPrograms) return [];

      return this.registerInfo.insuranceProgram.subPrograms.map((item) => ({
        id: item.subprogramId,
        title: item.name,
      }));
    },

    availableYears() {
      const endYear = new Date().getFullYear();
      const startYear = endYear - 10;
      const yearsList = [];
      for (let i = startYear; i <= endYear; i += 1) {
        yearsList.push(i);
      }
      return yearsList.reverse();
    },
    yearErrorShow() {
      return !this.registerInfo.legalPersons
      && !this.registerInfo.insuranceCompany
      && !this.registerInfo.dateFromRegistryPeriod.from
      && !this.registerInfo.dateFromRegistryPeriod.to
      && !this.registerInfo.registryImportDatePeriod.from
      && !this.registerInfo.registryImportDatePeriod.to
      && !this.registerInfo.actNumber
      && !this.registerInfo.dates[0].year;
    },
  },
  async created() {
    this.isLoading = true;
    this.legalPersonsList = await this.fetchLegalPersons();
    this.insuranceCompaniesRawData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.isLoading = false;
  },
  methods: {
    changeInsuranceCompany() {
      this.registerInfo.insuranceProgram = null;
      this.changeInsuranceProgram();
    },

    changeInsuranceProgram() {
      this.registerInfo.insuranceSubProgram = null;
    },

    printError(errArray) {
      return errArray ? errArray.toString() : null;
    },
    deleteDateItem(index) {
      this.registerInfo.dates = this.registerInfo.dates.filter((item, itemIndex) => itemIndex !== index);
    },
    addDateItem() {
      this.registerInfo.dates.push({ month: null, year: null });
    },
    addLegalPersonItem() {
      this.registerInfo.legalPersons.push({ id: null });
    },

    deleteLegalPersonItem(index) {
      this.registerInfo.legalPersons = this.registerInfo.legalPersons.filter((item, itemIndex) => itemIndex !== index);
    },

    async fetchLegalPersons() {
      const clinics = await this.$store.dispatch(this.$types.LEGAL_PERSONS_FETCH, { skip: 0 });
      return clinics.map((item) => ({ id: item.id, name: item.name }));
    },

    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return false;
      }

      try {
        const dateFromRegistryPeriod = {
          from: this.registerInfo.dateFromRegistryPeriod.from ? formatISO(this.registerInfo.dateFromRegistryPeriod.from) : null,
          to: this.registerInfo.dateFromRegistryPeriod.to ? formatISO(this.registerInfo.dateFromRegistryPeriod.to.setHours(23, 59, 0, 0)) : null,
        };
        const registryImportDatePeriod = {
          from: this.registerInfo.registryImportDatePeriod.from ? formatISO(this.registerInfo.registryImportDatePeriod.from) : null,
          to: this.registerInfo.registryImportDatePeriod.to ? formatISO(this.registerInfo.registryImportDatePeriod.to.setHours(23, 59, 0, 0)) : null,
        };

        const registrToSave = {
          dates: this.registerInfo.dates.filter((date) => date.month || date.year),
          // dateFromRegistryPeriod: this.registerInfo.dateFromRegistryPeriod,
          dateFromRegistryPeriod,
          // registryImportDatePeriod: this.registerInfo.registryImportDatePeriod,
          registryImportDatePeriod,
          actNumber: this.registerInfo.actNumber,
          legalPersons: this.registerInfo.legalPersons?.id ? [{ id: this.registerInfo.legalPersons?.id }] : [],
          insuranceCompanyIds: this.registerInfo.insuranceCompany?.id ? [this.registerInfo.insuranceCompany.id] : [],
          insuranceProgramIds: this.registerInfo.insuranceProgram?.id ? [this.registerInfo.insuranceProgram?.id] : [],
          insuranceSubprogramIds: this.registerInfo.insuranceSubProgram?.id ? [this.registerInfo.insuranceSubProgram?.id] : [],
        };

        const { data: file } = await this.$store.dispatch(this.$types.REGISTRY_EXPORT, registrToSave);
        saveFile(file, { name: 'registry' });
      } catch (e) {
        console.log(e);

        showMessage({
          type: 'error',
          message: 'Не удалось экспортировать реестр',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .scrollbar-wrapper {
    height: calc(100vh - 202px);
    // margin-right: 10px;
    // margin: 0 -10px 10px;
  }

  .inner-wrapper {
    padding: 0px 10px;
  }

  .crm-row {
    display: flex;
    margin-top: 30px;
  }
  .crm-column {
    flex: 1;
    max-width: 100%;

    & + & {
      margin-left: 30px;
    }
  }

  .input-label {
    color: #707070;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .text-btn {
    width: 500px;
    font-size: 16px;
    line-height: 20px;
    color: #6E88F3;
    cursor: pointer;
    margin-top: 12px;
  }

.wrapper-buttons {
  margin-top: 30px;
}

.messages-datepickers {
  position: relative;
  flex-basis: 486px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
::v-deep.datepicker-right {
  .vc-popover-content-wrapper {
    left: -15px !important;
  }
}
.error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}
.crm-row-item {
    & + & {
      margin-left: 20px;
    }
    .crm-date {
      width: 250px;
    }

    .crm-icon-delete {
      margin-top: 30px;
      cursor: pointer;
    }
  }

  .crm-input-label {
    color: #707070;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 8px;
  }
</style>
