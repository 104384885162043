<template>
    <d-remote-monitoring-indicator-trends-view :id="id" @on-create="onConfirmHandler" />
</template>

<script>

export default {
  name: 'RemoteMonitoringIndicatorTrendsView',
  data() {
    return {
      id: null,
    };
  },

  mounted() {
    this.id = this.$route.params?.id;
  },
  methods: {
    onConfirmHandler() {
      this.$router.push('/remote-monitoring/indicator-trends/');
    },
  },
};
</script>
