<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="container policy-container"
      >
        <div class="clinics-form-fields">
          <base-input
            v-model="personIdTo"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Куда переносим данные "
            placeholder="Введите id учетной записи"
          />

          <base-input
            v-model="personIdFrom"
            :input-style="{
              padding: '11px 15px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Откуда переносим данные"
            placeholder="Введите id учетной записи"
          />
        </div>

        <div class="crm-description">
          В основную учетную запись перенесутся все данные <br> из дочерней учетной записи
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            :loading="isLoading"
            class="crm-button"
            @click="onClickTransfer"
          >
            Объединить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { showMessage } from '@/helpers/utils';
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
  BaseInput,
} from '@/components/base';

export default {
  name: 'PatientTransferModal',
  components: {
    BaseButton,
    BaseInput,
  },
  mixins: [mixinRoles],
  props: {},
  data() {
    return {
      personIdTo: null,
      personIdFrom: null,
      isLoading: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickTransfer() {
      if (!this.personIdTo || !this.personIdFrom) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо заполнить поля',
        });

        return;
      }

      try {
        this.isLoading = true;

        await this.$store.dispatch(this.$types.PATIENT_TRANSFER, {
          personIdTo: this.personIdTo,
          personIdFrom: this.personIdFrom,
        });

        Bus.$emit('patients:update');
        this.$emit('vuedals:close');
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.crm-description {
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin-top: -10px;
}
.clinics-form-fields {
  margin-top: 20px;
}

::v-deep.form-field {
  .label {
    margin-bottom: 10px;
  }
}

</style>
