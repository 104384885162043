<template>
  <div
    class="relative border-solid border rounded-full transition-colors cursor-pointer w-6 h-6"
    :class="[
      disabled
        ? 'pointer-events-none !border-transparent !bg-v-ui-root-monochrome-10'
        : '',
      isChecked
        ? 'border-v-ui-root-main bg-v-ui-root-main'
        : 'border-v-ui-root-main bg-transparent'
    ]"
  >
    <label
      class="absolute w-full h-full flex justify-center items-center cursor-pointer"
      :for="radioId"
    >
      <span
        v-if="isChecked"
        :class="[
          disabled ? '!bg-v-ui-icon-monochrome-20' : '',
          isChecked ? 'bg-v-ui-background-monochrome-0' : ''
        ]"
        class="absolute w-2 h-2 rounded-full m-auto inset-0 transition-colors"
      ></span>
    </label>
    <input
      :id="radioId"
      :name="name"
      :value="componentValue"
      :checked="isChecked"
      hidden
      type="radio"
      @change="onSelect"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'SingleRadio',
  props: {
    size: {
      type: String,
      default: 'm',
    },
    value: {
      type: Object,
      default: () => null,
    },
    variant: {
      type: String,
      default: 'gray',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentValue: null,
    };
  },
  computed: {
    radioId() {
      return uuidv4();
    },
  },
  methods: {
    onSelect() {
      this.$emit('input', this.componentValue);
    },
  },
};
</script>
