<template>
  <div class="doctors-pin">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      class="doctor-list"
      @load-more-items="loadMoreItems"
    >
      <doctor-search
        :search-trigger="searchTrigger"
        disable-toggle-header
        class="doctors-pin__doctor-search"
        only-active-doctors
        modal-mode
      >
        <template v-slot:doctors="{ doctors }">
          <DoctorsList
            v-if="activeDoctors(doctors).length"
            :doctors="activeDoctors(doctors)"
            mode-pin
            :pinned-doctors="pinnedDoctors"
            @toggle-pin="togglePin"
          >
            <template #label>
              Активные доктора
            </template>
          </DoctorsList>
        </template>
      </doctor-search>
    </async-list>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';

import AsyncList from '@/components/AsyncList';
import DoctorSearch from '@/components/Doctors/DoctorSearch';
import DoctorsList from '@/components/Doctors/DoctorsList';

export default {
  name: 'DoctorsPinModal',
  components: {
    AsyncList,
    DoctorSearch,
    DoctorsList,
  },
  props: {
    pinFunc: {
      type: Function,
      default: null,
    },
  },

  data: () => ({
    searchTrigger: false,
  }),
  computed: {
    ...mapGetters({
      pinnedDoctors: types.GET_DOCTORS_PINNED,
    }),
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Doctor.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.state.Doctor.isAllItemsLoaded = newVal;
      },
    },
  },
  methods: {
    togglePin(doctor) {
      if (this.pinFunc) this.pinFunc(doctor);
      this.$store.dispatch(this.$types.CLINICS_PINNED_DOCTORS_TOGGLE, doctor);
    },
    loadMoreItems() {
      this.searchTrigger = !this.searchTrigger;
    },
    activeDoctors(doctors) {
      return doctors.filter((item) => !item.isDeleted);
    },
    deletedDoctors(doctors) {
      return doctors.filter((item) => item.isDeleted);
    },
  },
};
</script>

<style lang="scss" scoped>
.doctors-pin {
  background-color: #F3F3F3;

  &__doctor-search {
    min-height: 420px;
  }
}
.container-info {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.doctor-list {
  height: calc(100vh - 250px);
}
</style>
