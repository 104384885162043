<template>
  <div class="clinic-edit-wrapper">
    <div class="clinics-scroll-content">
      <custom-scrollbar>
        <div class="clinic-edit">
          <Loading v-if="isLoading" class="crm-loading-wrapper">
            Загрузка клиники
          </Loading>

          <template v-else>
            <div class="line-title">
              <div class="line-title-inner">
                Информация о юридическом лице
              </div>
            </div>

            <div class="clinics-form-fields">
              <div class="crm-form-field" style="width: 45%">
                <div class="crm-form-block">
                  <p class="crm-label">
                    Юр. лицо
                  </p>
                  <div class="crm-radio-buttons crm-radio-buttons_vertical">
                    <base-radio-button
                      v-model="legalPersonSelectMode"
                      class="crm-radio-button"
                      :name="SELECT_LEGAL_PERSON_MODE.NEW"
                      @change="setNewLegalPerson"
                    >
                      Создать новое
                    </base-radio-button>

                    <base-radio-button
                      v-model="legalPersonSelectMode"
                      class="crm-radio-button"
                      :name="SELECT_LEGAL_PERSON_MODE.SELECT_FROM_BASE"
                    >
                      Выбрать существующее
                    </base-radio-button>

                    <base-radio-button
                      v-model="legalPersonSelectMode"
                      class="crm-radio-button"
                      :name="SELECT_LEGAL_PERSON_MODE.SELECT_FROM_REESTR"
                    >
                      Выбрать из справочника
                    </base-radio-button>
                  </div>
                  <base-input
                    v-if="isNewLegalModeSelected"
                    v-model="$v.clinicInfo.legalPerson.name.$model"
                    :error="$v.clinicInfo.legalPerson.name.$error"
                    :errors="errorsValidation.legalPersonName"
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    placeholder="Введите название юр. лица"
                  />
                  <div
                    v-else-if="isSelectFromBaseModeSelected"
                    class="crm-form-field"
                  >
                    <base-async-select
                      v-model="$v.clinicInfo.legalPerson.$model"
                      class="crm-select"
                      :fetch-function="fetchLegalPersons"
                      server-paginated
                      fluid
                      option-title="name"
                      loading-title="Загрузка Юр. лиц"
                      no-options-title="Юр. лица не найдены"
                    />
                    <div
                      v-if="$v.clinicInfo.legalPerson.$error"
                      class="validation-errors"
                    >
                      <span class="validation-error-text">
                        {{ errorsValidation.legalPerson[0] }}
                      </span>
                    </div>
                  </div>

                  <div v-else-if="isSelectFromReestrModeSelected">
                    <div class="flex items-center">
                      <base-input
                        :value="selectedDataFromReestr?.nameFull"
                        :input-style="inputStyleProps"
                        class="crm-form-field mr-2"
                        disabled
                        placeholder="Необходимо выбрать клинику"
                      />

                      <b-button
                        size="m"
                        variant="primary"
                        style="height: 41px;"
                        @click="openSelectFromReestrModal"
                      >
                        Выбрать
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isNewLegalModeSelected" class="crm-form-field">
                <div v-if="isCanNetworkChange" class="crm-form-block">
                  <p class="crm-label">
                    Сеть клиник
                  </p>
                  <div class="crm-radio-buttons crm-radio-buttons_vertical">
                    <base-radio-button
                      v-model="newNetwork"
                      class="crm-radio-button"
                      :name="true"
                    >
                      Создать новую
                    </base-radio-button>

                    <base-radio-button
                      v-model="newNetwork"
                      class="crm-radio-button"
                      :name="false"
                    >
                      Выбрать существующую
                    </base-radio-button>
                  </div>
                  <base-input
                    v-if="newNetwork"
                    v-model="newNetworkName"
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    placeholder="Введите название сети"
                  />
                  <v-select
                    v-else
                    v-model="$v.clinicInfo.clinicNetwork.$model"
                    :options="networks"
                    placeholder="Выберите сеть"
                    label="title"
                    class="crm-form-field person-select"
                  />
                </div>
              </div>
            </div>

            <base-input
              v-if="isSelectFromReestrModeSelected"
              v-model="clinicInfo.legalPerson.name"
              :input-style="inputStyleProps"
              class="crm-form-field mb-4"
              disabled
              label="Полное наименование организации"
            />

            <template
              v-if="
                clinicInfo.legalPerson &&
                  (isNewLegalModeSelected || isSelectFromReestrModeSelected)
              "
            >
              <div class="clinics-form-fields">
                <base-input
                  v-model="$v.clinicInfo.legalPerson.leaderFio.$model"
                  :error="$v.clinicInfo.legalPerson.leaderFio.$error"
                  :errors="errorsValidation.leaderFio"
                  required
                  :input-style="inputStyleProps"
                  class="crm-form-field"
                  label="ФИО руководителя"
                />
                <div class="crm-form-field">
                  <base-input
                    v-model="$v.clinicInfo.legalPerson.leaderPosition.$model"
                    :error="$v.clinicInfo.legalPerson.leaderPosition.$error"
                    :errors="errorsValidation.leaderPosition"
                    required
                    class="crm-form-field"
                    :input-style="inputStyleProps"
                    label="Должность руководителя"
                  />

                  <base-input
                    v-model="clinicInfo.legalPerson.site"
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    label="Веб-сайт"
                  />
                </div>
              </div>

              <div class="clinics-form-fields">
                <div class="crm-form-field">
                  <base-input
                    v-model="$v.clinicInfo.legalPerson.kpp.$model"
                    :error="$v.clinicInfo.legalPerson.kpp.$error"
                    :errors="errorsValidation.kpp"
                    required
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    label="КПП"
                    :disabled="isSelectFromReestrModeSelected && clinicInfo.legalPerson.kpp"
                  />
                  <base-input
                    v-model="$v.clinicInfo.legalPerson.ogrn.$model"
                    :error="$v.clinicInfo.legalPerson.ogrn.$error"
                    :errors="errorsValidation.ogrn"
                    required
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    label="ОГРН"
                    :disabled="isSelectFromReestrModeSelected && clinicInfo.legalPerson.ogrn"
                  />
                </div>

                <div class="crm-form-field">
                  <base-input
                    v-model="$v.clinicInfo.legalPerson.inn.$model"
                    :error="$v.clinicInfo.legalPerson.inn.$error"
                    :errors="errorsValidation.inn"
                    :input-style="inputStyleProps"
                    class="crm-form-field"
                    label="ИНН"
                    required
                    :disabled="isSelectFromReestrModeSelected && clinicInfo.legalPerson.inn"
                  />

                  <div class="crm-form-field" />
                </div>
              </div>

              <div
                v-for="(contact, index) in $v.clinicInfo.legalPerson.contacts
                  .$each.$iter"
                :key="`${index}key`"
                class="clinics-form-fields"
              >
                <base-input
                  v-model="contact.position.$model"
                  :error="contact.position.$error"
                  :errors="['Поле не может быть пустым']"
                  :input-style="inputStyleProps"
                  class="crm-form-field"
                  label="Должность"
                  required
                />

                <base-input
                  v-model="contact.fio.$model"
                  :error="contact.fio.$error"
                  :errors="['Поле не может быть пустым']"
                  :input-style="inputStyleProps"
                  class="crm-form-field"
                  label="ФИО"
                  required
                />

                <base-input
                  v-model="contact.email.$model"
                  :input-style="inputStyleProps"
                  :mask="emailMask"
                  :guide="false"
                  class="crm-form-field"
                  label="E-mail"
                />

                <div class="crm-form-field">
                  <base-input
                    v-model="contact.phoneNumber.$model"
                    :error="contact.phoneNumber.$error"
                    :errors="['Поле не может быть пустым']"
                    :input-style="inputStyleProps"
                    :guide="false"
                    class="crm-form-field"
                    label="Телефон"
                    required
                  />
                  <icon-delete
                    class="crm-icon-delete contact-legal-delete"
                    @click="deleteLegalPersonContact(index)"
                  />
                </div>
              </div>
              <div class="clinics-form-fields">
                <base-button
                  :type="$const.SECONDARY_BUTTON"
                  class="width-unset without-border add-contact"
                  @click="addLegalPersonContact"
                >
                  <span>Добавить контакт</span>
                </base-button>
              </div>
            </template>
            <template v-else>
              <div class="mb-4">
                <div class="clinics-form-fields">
                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        Сеть клиник
                      </p>
                      <span class="crm-info-text">
                        {{
                          clinicInfo.clinicNetwork
                            ? clinicInfo.clinicNetwork.title
                            : '—'
                        }}
                      </span>
                    </div>
                  </div>

                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        Юр. лицо
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.name || '—' }}
                      </span>
                    </div>
                  </div>

                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title flex">
                        ФИО руководителя
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.leaderFio || '—' }}
                      </span>
                    </div>
                  </div>

                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title flex">
                        Должность руководителя
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.leaderPosition || '—' }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="clinics-form-fields">
                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        Веб-сайт
                      </p>
                      <span class="crm-info-text">
                        <a
                          v-if="clinicInfo.legalPerson.site"
                          :href="clinicInfo.legalPerson.site"
                          target="_blank"
                        >
                          {{ clinicInfo.legalPerson.site }}
                        </a>
                        <template v-else>
                          —
                        </template>
                      </span>
                    </div>
                  </div>

                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        ОГРН
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.ogrn || '—' }}
                      </span>
                    </div>
                  </div>

                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        ИНН
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.inn || '—' }}
                      </span>
                    </div>
                  </div>
                  <div class="crm-form-field">
                    <div class="crm-form-block">
                      <p class="crm-info-title">
                        КПП
                      </p>
                      <span class="crm-info-text">
                        {{ clinicInfo.legalPerson.kpp || '—' }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="line-title">
              <div class="line-title-inner">
                Информация о клинике
              </div>
            </div>

            <div class="clinics-form-fields">
              <base-input
                v-model="$v.clinicInfo.title.$model"
                :error="$v.clinicInfo.title.$error"
                :errors="errorsValidation.title"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="Название клиники для приложения"
                placeholder="Введите название"
                required
                :disabled="isSelectFromReestrModeSelected && clinicInfo.title"
              />

              <base-input
                v-model="clinicInfo.medicalOrganizationOID"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="OID"
                placeholder="Введите номер"
                :disabled="isSelectFromReestrModeSelected && clinicInfo.medicalOrganizationOID"
              />
            </div>

            <div class="clinics-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <div class="crm-label required">
                    Город
                  </div>
                  <v-select
                    v-model="$v.clinicInfo.city.$model"
                    :options="cities"
                    placeholder="Выберите город"
                    label="name"
                    :clearable="true"
                    class="crm-select"
                    :disabled="isSelectFromReestrModeSelected && clinicInfo.city"
                  />
                  <div
                    v-if="$v.clinicInfo.city.$error"
                    class="validation-errors"
                  >
                    <span class="validation-error-text">
                      {{ errorsValidation.city[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <base-input
                v-model="$v.clinicInfo.address.$model"
                :error="$v.clinicInfo.address.$error"
                :errors="errorsValidation.address"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="Адрес"
                required
                :disabled="isSelectFromReestrModeSelected && clinicInfo.address"
              />

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-label">
                    Режим работы
                  </p>
                  <div class="crm-form-field">
                    <div class="crm-form-input-prefix crm-bold">
                      с
                    </div>
                    <base-input
                      v-model="clinicInfo.operatingHoursFrom"
                      class="crm-form-input"
                      type="text"
                      placeholder="8:00"
                      :input-style="{ ...inputStyleProps, width: '70px' }"
                      :disable-arrows="true"
                    />

                    <div class="divide-line" />

                    <div class="crm-form-input-prefix crm-bold">
                      до
                    </div>
                    <base-input
                      v-model="clinicInfo.operatingHoursTo"
                      class="crm-form-input"
                      type="text"
                      placeholder="20:00"
                      :input-style="{ ...inputStyleProps, width: '70px' }"
                      :disable-arrows="true"
                    />
                  </div>
                </div>
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <p class="crm-label">
                    Географические координаты
                  </p>

                  <div class="crm-form-fields">
                    <base-input
                      v-model="$v.clinicInfo.latitude.$model"
                      :error="$v.clinicInfo.latitude.$error"
                      :errors="errorsValidation.latitude"
                      :input-style="inputStyleProps"
                      :mask="maskLatitude"
                      class="crm-form-field coord-input"
                    >
                      <template v-slot:prefix>
                        <span class="coord-prefix">Ш.</span>
                      </template>
                    </base-input>

                    <base-input
                      v-model="$v.clinicInfo.longitude.$model"
                      :error="$v.clinicInfo.longitude.$error"
                      :errors="errorsValidation.longitude"
                      :input-style="inputStyleProps"
                      :mask="maskLongitude"
                      class="crm-form-field coord-input"
                    >
                      <template v-slot:prefix>
                        <span class="coord-prefix">Д.</span>
                      </template>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>

            <div class="clinics-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-field">
                  <div class="crm-form-block">
                    <p class="crm-label">
                      E-mail для гарантийных писем
                    </p>

                    <div
                      v-for="(email, index) in clinicInfo.contactsForIL"
                      :key="index"
                      class="crm-form-field-email"
                    >
                      <base-input
                        v-model="clinicInfo.contactsForIL[index]"
                        :input-style="inputStyleProps"
                        :style="{ flex: '1' }"
                        :mask="emailMask"
                        placeholder="example@example.com"
                      />

                      <icon-delete
                        class="crm-icon-delete crm-form-email-delete"
                        @click="deleteEmail(index)"
                      />
                    </div>

                    <base-button
                      :type="$const.SECONDARY_BUTTON"
                      class="width-unset without-border add-email"
                      @click="addEmail"
                    >
                      <span>Добавить e-mail</span>
                    </base-button>
                  </div>
                </div>
                <div class="crm-form-field">
                  <div class="crm-form-block">
                    <p class="crm-label required">
                      Приоритет клиники
                    </p>
                    <v-select
                      v-model="$v.clinicInfo.priority.$model"
                      :reduce="(priority) => priority.index"
                      :options="clinicPriorities"
                      :clearable="false"
                      class="crm-form-field person-select"
                      placeholder="Выберите приоритет"
                      label="title"
                    />
                    <div
                      v-if="$v.clinicInfo.priority.$error"
                      class="validation-errors"
                    >
                      <span class="validation-error-text">
                        {{ errorsValidation.priority[0] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <base-input
                v-model="clinicInfo.operatingHoursComment"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="Комментарий к режиму работы:"
                placeholder="Введите текст"
              />
            </div>

            <div class="clinics-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <div class="crm-label">
                    Услуги
                  </div>
                  <div class="crm-form-checkbox-list">
                    <base-checkbox
                      v-for="(service, index) in clinicServices"
                      :key="service"
                      v-model="clinicInfo.services"
                      :label="index"
                      class="crm-form-checkbox"
                    >
                      <span class="crm-form-checkbox-text">
                        {{ service }}
                      </span>
                    </base-checkbox>
                  </div>
                </div>
              </div>
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <base-input
                    v-model="clinicInfo.specialComment"
                    :input-style="{
                      ...inputStyleProps,
                      height: '100px',
                      marginBottom: '15px'
                    }"
                    class="crm-form-field"
                    type="textarea"
                    label="Особенности клиники"
                  />
                  <base-checkbox
                    v-model="clinicInfo.viewInDoctis"
                    class="crm-form-checkbox"
                  >
                    <span class="crm-form-checkbox-text">
                      Выводить для doctis.ru
                    </span>
                  </base-checkbox>
                </div>
              </div>
            </div>

            <div class="clinics-form-fields">
              <div class="crm-form-field">
                <div class="crm-form-block">
                  <div class="crm-label">
                    Виды услуг
                  </div>
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      id="checkbox-1"
                      v-model="clinicInfo.isOms"
                      :disabled="
                        !checkFeatureAccess({
                          name: 'Редактирование. Виды услуг. Можно выбрать ОМС',
                          url: '/clinics/:clinicId'
                        })
                      "
                      name="checkbox-1"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span class="crm-form-checkbox-text">
                        ОМС
                      </span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-2"
                      v-model="clinicInfo.isDms"
                      :disabled="
                        !checkFeatureAccess({
                          name: 'Редактирование. Виды услуг. Можно выбрать ДМС',
                          url: '/clinics/:clinicId'
                        })
                      "
                      name="checkbox-2"
                      :value="true"
                      class="mr-4 ml-4"
                      :unchecked-value="false"
                    >
                      <span class="crm-form-checkbox-text">
                        ДМС
                      </span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-3"
                      v-model="clinicInfo.isTelemed"
                      :disabled="
                        !checkFeatureAccess({
                          name:
                            'Редактирование. Виды услуг. Можно выбрать Телемед',
                          url: '/clinics/:clinicId'
                        })
                      "
                      name="checkbox-3"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span class="crm-form-checkbox-text">
                        Телемед
                      </span>
                    </b-form-checkbox>
                  </div>

                  <div
                    v-if="typesOfServicesErrors && checkErrors"
                    class="validation-errors"
                  >
                    <span class="validation-error-text">
                      {{ typesOfServicesErrors[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <div class="crm-label">
                    Привязка клиники
                  </div>
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      id="checkbox-12"
                      v-model="clinicInfo.isOmsPay"
                      :disabled="
                        !checkFeatureAccess({
                          name:
                            'Редактирование. Привязка клиники. Можно выбрать ОМС',
                          url: '/clinics/:clinicId'
                        })
                      "
                      name="checkbox-12"
                      :value="true"
                      :unchecked-value="false"
                    >
                      <span class="crm-form-checkbox-text">
                        ОМС
                      </span>
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-22"
                      v-model="clinicInfo.isDmsPay"
                      :disabled="
                        !checkFeatureAccess({
                          name:
                            'Редактирование. Привязка клиники. Можно выбрать ДМС',
                          url: '/clinics/:clinicId'
                        })
                      "
                      name="checkbox-22"
                      :value="true"
                      class="mr-4 ml-4"
                      :unchecked-value="false"
                    >
                      <span class="crm-form-checkbox-text">
                        ДМС
                      </span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>

              <div class="crm-form-field">
                <div class="crm-form-block">
                  <div class="crm-label">
                    Признак договора
                  </div>

                  <b-form-group v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group
                      id="checkbox-group-1"
                      v-model="clinicInfo.clinicAttributes"
                      :options="SIGNS_AGENCY_AGREEMENT"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-1"
                    />
                  </b-form-group>

                  <div
                    v-if="signContractErrors && checkErrors"
                    class="validation-errors"
                  >
                    <span class="validation-error-text">
                      {{ signContractErrors[0] }}
                    </span>
                  </div>
                </div>
              </div>

              <div class="crm-form-field">
                <div
                  :title="id || id === 0 ? 'Недоступно при редактировании' : ''"
                  class="crm-form-block"
                >
                  <div class="crm-label font-weight-bold">
                    Дистанционный мониторинг
                  </div>

                  <b-form-checkbox
                    v-model="disMonitoring"
                    :value="true"
                    :disabled="id || id === 0"
                    :unchecked-value="false"
                    class="font-weight-bold"
                  >
                    Клиника привязана
                  </b-form-checkbox>
                </div>
              </div>

              <div v-if="selectedAgencyAgreement" class="crm-form-field">
                <div class="crm-form-block">
                  <div
                    class="crm-label"
                    :class="{ required: selectedAgencyAgreement }"
                  >
                    Номер агентского договора
                  </div>

                  <b-form-group>
                    <b-form-input
                      v-model="$v.clinicInfo.contractNumber.$model"
                      :state="!$v.clinicInfo.contractNumber.$error"
                      :errors="errorsValidation.contractNumber"
                      :input-style="inputStyleProps"
                      :required="selectedAgencyAgreement"
                    />
                    <b-form-invalid-feedback>
                      Данное поле обязательно*
                    </b-form-invalid-feedback>
                  </b-form-group>
                </div>
              </div>
            </div>

            <div v-if="clinicInfo.clinicAttributes.includes(1)" class="pb-3">
              <div class="line-title">
                <div class="line-title-inner">
                  Оферта
                </div>
              </div>

              <div class="border rounded">
                <div v-if="offersShow" class="p-3 mb-2">
                  <div
                    v-for="offer in clinicInfo.clinicOffers"
                    :key="offer.type"
                    class="editor-item"
                  >
                    <div class="mb-2 font-weight-bold">
                      {{ offer.title }}
                    </div>

                    <div class="editor-container">
                      <div class="editor-hashtags d-flex">
                        <div
                          v-for="hashtag in HASHTAGS"
                          :key="hashtag.key"
                          class="editor-hashtags__item"
                          @click="copyHashtag(hashtag)"
                        >
                          {{ hashtag.title }}
                        </div>
                      </div>
                      <TextEditor
                        v-model="offer.text"
                        :custom-toolbar="CLINIC_EDITOR_TOOLBAR"
                      />

                      <p
                        v-if="checkErrors && !offer.text"
                        class="mt-1 text-danger"
                      >
                        Поле обязательно к заполнению
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  style="cursor: pointer;"
                  class="bg-primary rounded-bottom d-flex justify-content-center p-2 "
                  @click="offersShow = !offersShow"
                >
                  <b-icon
                    icon="caret-down-fill"
                    variant="light"
                    :rotate="offersShow ? 180 : 0"
                  />
                </div>
              </div>
            </div>

            <div class="line-title">
              <div class="line-title-inner">
                Контакты клиники
              </div>
            </div>

            <div
              v-for="(contact, index) in $v.clinicInfo.contacts.$each.$iter"
              :key="index"
              class="clinics-form-fields"
            >
              <base-input
                v-model="contact.position.$model"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="Должность"
              />

              <base-input
                v-model="contact.fio.$model"
                :input-style="inputStyleProps"
                class="crm-form-field"
                label="ФИО"
              />

              <base-input
                v-model="contact.email.$model"
                :input-style="inputStyleProps"
                :mask="emailMask"
                :guide="false"
                class="crm-form-field"
                label="E-mail"
              />

              <div class="crm-form-field">
                <base-input
                  v-model="contact.phoneNumber.$model"
                  :error="contact.phoneNumber.$error"
                  :errors="['Поле не может быть пустым']"
                  :input-style="{ ...inputStyleProps, width: '150px' }"
                  :guide="false"
                  class="crm-form-field"
                  label="Телефон"
                  required
                />

                <div class="crm-form-field">
                  <div class="crm-form-block">
                    <p class="crm-label">
                      Осн. контакт
                    </p>
                    <span class="crm-info-text contact">
                      <base-checkbox
                        v-model="contact.isMain.$model"
                        class="crm-checkbox"
                      />
                      <icon-delete
                        class="crm-icon-delete contact-delete"
                        @click="deleteContact(index)"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="clinics-form-fields">
              <base-button
                :type="$const.SECONDARY_BUTTON"
                class="width-unset without-border add-contact"
                @click="addContact"
              >
                <span>Добавить контакт</span>
              </base-button>
            </div>

            <div class="line-title">
              <div class="line-title-inner">
                Документы клиники
              </div>
            </div>

            <b-row v-if="clinicDocuments.length" class="mb-4">
              <b-col>
                <div class="d-flex">
                  <div class="d-flex flex-column">
                    <template v-for="(doc, index) in clinicDocuments">
                      <div
                        v-if="doc.type === 1 || doc.type === 3"
                        :key="index"
                        class="p-1"
                      >
                        <b-button
                          v-b-tooltip.hover
                          size="sm"
                          class="w-100 d-flex align-items-center justify-content-start"
                          :title="doc.title"
                          variant="primary"
                          @click="saveFile(doc)"
                        >
                          <b-icon icon="file-earmark-fill" />
                          <span class="ml-2">{{ documentType(doc) }}</span>
                        </b-button>
                      </div>
                    </template>
                  </div>
                  <div class="d-flex flex-column">
                    <template v-for="(doc, index) in clinicDocuments">
                      <div
                        v-if="doc.type === 4 || doc.type === 5"
                        :key="index"
                        class="p-1"
                      >
                        <b-button
                          v-b-tooltip.hover
                          size="sm"
                          class="w-100 d-flex align-items-center justify-content-start"
                          :title="doc.title"
                          variant="primary"
                          @click="saveFile(doc)"
                        >
                          <b-icon icon="file-earmark-fill" />
                          <span class="ml-2">{{ documentType(doc) }}</span>
                        </b-button>
                      </div>
                    </template>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row class="mb-4">
              <b-col>
                <b-button
                  size="sm"
                  variant="primary"
                  @click="openDownloadFileModal"
                >
                  Добавить файлы
                </b-button>
              </b-col>
            </b-row>

            <template v-if="disMonitoring || clinicInfo.isTelemed">
              <div class="line-title">
                <div class="line-title-inner">
                  {{
                    disMonitoring
                      ? 'Профиль администратора дис.мониторинга'
                      : 'Профиль администратора'
                  }}
                </div>
              </div>

              <div class="clinics-form-fields">
                <div class="crm-form-field">
                  <base-input
                    v-model="$v.clinicInfo.authorization.login.$model"
                    :error="$v.clinicInfo.authorization.login.$error"
                    :errors="errorsValidation.authorization.login"
                    :input-style="inputStyleProps"
                    :required="
                      !!clinicInfo.authorization.password &&
                        (clinicInfo.isTelemed || disMonitoring)
                    "
                    class="crm-form-field"
                    :label="
                      disMonitoring
                        ? 'Логин администратора дис.мониторинга'
                        : 'Логин администратора'
                    "
                  />

                  <base-input
                    ref="password"
                    v-model="$v.clinicInfo.authorization.password.$model"
                    :error="$v.clinicInfo.authorization.password.$error"
                    :errors="errorsValidation.authorization.password"
                    :type="showPassword ? 'text' : 'password'"
                    :input-style="inputStyleProps"
                    :required="
                      !!clinicInfo.authorization.login &&
                        (clinicInfo.isTelemed || disMonitoring)
                    "
                    class="crm-form-field"
                    :label="
                      disMonitoring
                        ? 'Пароль администратора дис.мониторинга'
                        : 'Пароль администратора'
                    "
                    autocomplete="new-password"
                  >
                    <template v-slot:suffix>
                      <icon-eye
                        :class="['icon-eye', { active: showPassword }]"
                        @click="toggleShowPassword"
                      />
                    </template>
                  </base-input>
                </div>
                <div class="crm-form-field" />
              </div>
            </template>
          </template>
        </div>
      </custom-scrollbar>
    </div>

    <div class="crm-wrapper-buttons crm-wrapper-buttons_clinic-edit">
      <!-- <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickClose"
      >
        Отменить
      </base-button> -->

      <base-button
        :type="$const.PRIMARY_BUTTON"
        :loading="isSaving"
        class="crm-button"
        @click="onClickSave"
      >
        Сохранить
      </base-button>
    </div>

    <SelectFromReestrModal
      v-if="isSelectFromReestrModalOpen"
      v-model="isSelectFromReestrModalOpen"
      @onSelect="fillDataFromReestr"
    />
  </div>
</template>

<script>
/* eslint-disable */

import IconEye from 'assets/images/eye_1.svg'
import { api } from '@/helpers/api'
import { mapGetters } from 'vuex'
import * as types from '@/store/types'
import {
  CONTACT_TYPE,
  CLINIC_PRIORITIES,
  CLINIC_SERVICES,
  SIGNS_AGENCY_AGREEMENT,
  OFFERS,
  HASHTAGS,
  CLINIC_EDITOR_TOOLBAR,
  SELECT_LEGAL_PERSON_MODE
} from '@/helpers/consts'

import Loading from '@/components/Loading'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import emailMask from 'text-mask-addons/dist/emailMask'

import {
  showMessage,
  createCoordMask,
  saveFile,
  validateInn,
  validateKpp,
  validateOgrn
} from '@/helpers/utils'

import IconDelete from 'assets/images/delete_icon.svg'
import {
  BaseInput,
  BaseCheckbox,
  BaseButton,
  BaseRadioButton,
  BaseAsyncSelect
} from '@/components/base'
import TextEditor from '@/components/common/TextEditor'
import Bus from '@/eventBus'
import { mixinRoles } from '@/mixins'
import SelectFromReestrModal from './SelectFromReestrModal.vue'

export default {
  name: 'ClinicEdit',
  components: {
    BaseInput,
    BaseCheckbox,
    BaseRadioButton,
    BaseButton,
    Loading,
    IconEye,
    IconDelete,
    BaseAsyncSelect,
    TextEditor,
    SelectFromReestrModal
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      selectedDataFromReestr: null,
      isSelectFromReestrModalOpen: false,
      legalPersonSelectMode: 1,
      disMonitoring: false,
      networks: [],
      cities: [],
      clinicPriorities: CLINIC_PRIORITIES,
      clinicServices: CLINIC_SERVICES,
      newNetwork: false,
      newNetworkName: null,
      checkErrors: false,
      emailMask,
      mask: [
        '+',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ],
      inputStyleProps: {
        padding: '11px 15px 12px',
        resize: 'none'
      },
      offersShow: false,
      OFFERS,
      HASHTAGS,
      CLINIC_EDITOR_TOOLBAR,
      hadAuthorization: false,
      showPasswordInput: false,
      showPassword: false,
      passwordSelection: {
        start: 0,
        end: 0
      },
      avv: 'test',

      isSaving: false,
      isLoading: false,

      maskLatitude: createCoordMask('latitude'),
      maskLongitude: createCoordMask('longitude'),
      unwatch: null,

      emptyCityTemplate: {
        id: null,
        name: null
      },
      emptyContactTemplate: {
        phoneNumber: null,
        email: null,
        comment: null
      },

      contactTypes: CONTACT_TYPE,
      isAdminEdit: false,
      SIGNS_AGENCY_AGREEMENT,
      clinicInfo: {
        id: null,
        title: '',
        appTitle: '',
        clinicNetwork: null,
        city: null,
        services: [],
        viewInDoctis: false,
        isOms: false,
        isDms: false,
        isTelemed: false,
        priority: 0,
        latitude: null,
        longitude: null,
        clinicAttributes: [],
        contractNumber: '',
        clinicOffers: [],

        contactsForIL: [],
        address: null,
        isOmsPay: false,
        isDmsPay: false,
        legalPerson: {
          contacts: [
            {
              id: 0,
              legalPersonId: 0,
              email: null,
              fio: null,
              phoneNumber: null,
              position: null
            }
          ],
          id: 0,
          inn: null,
          kpp: null,
          leaderFio: null,
          leaderPosition: null,
          name: null,
          ogrn: null,
          site: null,
          network: null
        },
        operatingHoursFrom: null,
        operatingHoursTo: null,
        operatingHoursComment: null,
        authorization: {
          login: null,
          password: null
        },
        description: null,
        specialComment: null,
        // isTelemed: false,
        // specializations: [],
        doctors: [],
        photoFile: [],
        agreementFiles: {},
        contacts: [
          {
            email: null,
            fio: null,
            comment: null,
            isMain: false,
            phoneNumber: null,
            position: null
          }
        ]
      }
    }
  },
  validations() {
    return {
      clinicInfo: {
        clinicNetwork: {
          // required
        },
        legalPerson: {
          required,
          contacts: {
            $each: {
              position: {
                required
              },
              phoneNumber: {
                required
              },
              fio: {
                required
              },
              email: {
                // required,
              }
            }
          },
          inn: {
            required,
            validateInn
          },
          kpp: {
            required,
            validateKpp
          },
          ogrn: {
            required,
            validateOgrn
          },
          name: {
            required
          },
          leaderFio: {
            required
          },
          leaderPosition: {
            required
          },
          site: {
            // required
          }
        },
        title: { required },
        priority: { required },
        // appTitle: { required },
        // clinicNetwork: { required },
        city: { required },
        longitude: {
          // required: this.clinicInfo.hasTelemed ? required : () => true,
          // isValid: this.clinicInfo.hasTelemed ? (val) => /^-?\d{2,3}.\d+$/.test(val) : () => true,
        },
        latitude: {
          // required: this.clinicInfo.hasTelemed ? required : () => true,
          // isValid: this.clinicInfo.hasTelemed ? (val) => /^-?\d{2,3}.\d+$/.test(val) : () => true,
        },
        address: { required },
        // legalInformation: {
        //   inn: { required },
        //   kpp: { required },
        //   ogrn: { required },
        // },
        authorization: {
          login: {
            required:
              this.clinicInfo.isTelemed || this.disMonitoring
                ? required
                : () => true
          },
          password: {
            required:
              this.clinicInfo.isTelemed || this.disMonitoring
                ? required
                : () => true,
            minLength: (value) => {
              if (this.hadAuthorization) {
                return !value || !!(value && value.length >= 6)
              }

              if (!this.clinicInfo.authorization.login) return true

              return !!(value && value.length >= 6)
            }
          }
        },
        contacts: {
          $each: {
            phoneNumber: {
              required
            },
            email: {
              // required
            },
            position: {
              // required,
            },
            fio: {
              // required,
            },
            comment: {
              // required,
            },
            isMain: {
              // required,
            }
          }
        },
        contractNumber: {
          required: this.selectedAgencyAgreement ? required : () => true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isChangedInfo: types.GET_IS_CHANGED_INFO
    }),

    SELECT_LEGAL_PERSON_MODE() {
      return SELECT_LEGAL_PERSON_MODE
    },

    isNewLegalModeSelected() {
      return this.legalPersonSelectMode === SELECT_LEGAL_PERSON_MODE.NEW
    },

    isSelectFromBaseModeSelected() {
      return (
        this.legalPersonSelectMode === SELECT_LEGAL_PERSON_MODE.SELECT_FROM_BASE
      )
    },

    isSelectFromReestrModeSelected() {
      return (
        this.legalPersonSelectMode ===
        SELECT_LEGAL_PERSON_MODE.SELECT_FROM_REESTR
      )
    },

    isCanNetworkChange() {
      return (
        this.isNewLegalModeSelected ||
        (!this.isNewLegalModeSelected &&
          this.clinicInfo.legalPerson &&
          this.clinicInfo.legalPerson.network === null)
      )
    },
    getActualNetworkForLegalPerson() {
      if (this.newNetwork)
        return this.newNetworkName
          ? { id: 0, title: this.newNetworkName }
          : null
      return this.clinicInfo.clinicNetwork
        ? { id: this.clinicInfo.clinicNetwork.id }
        : null
    },
    clinicDocuments() {
      return this.$store.state.ClinicDocuments.documents
    },
    offersError() {
      return this.clinicInfo.clinicOffers.some((item) => !item.text)
    },
    isNeedUpdateLegarPerson() {
      if (!this.isCanNetworkChange) return false

      const initNetwork = this.clinicInfo.legalPerson.network
        ? this.clinicInfo.legalPerson.network.id
        : null
      const actualNetwork = this.getActualNetworkForLegalPerson
        ? this.getActualNetworkForLegalPerson.id
        : null
      if (initNetwork === actualNetwork) return false
      return true
    },
    errorsValidation() {
      const errors = {}

      errors.legalPerson = []
      if (!this.$v.clinicInfo.legalPerson.required) {
        errors.legalPerson.push('Поле не может быть пустым')
      }

      errors.legalPersonName = []
      if (!this.$v.clinicInfo.legalPerson.name.required) {
        errors.legalPersonName.push('Поле не может быть пустым')
      }

      errors.leaderPosition = []
      if (!this.$v.clinicInfo.legalPerson.leaderPosition.required) {
        errors.leaderPosition.push('Поле не может быть пустым')
      }

      errors.leaderFio = []
      if (!this.$v.clinicInfo.legalPerson.leaderFio.required) {
        errors.leaderFio.push('Поле не может быть пустым')
      }

      errors.ogrn = []
      if (!this.$v.clinicInfo.legalPerson.ogrn.required) {
        errors.ogrn.push('Поле не может быть пустым')
      }
      if (!this.$v.clinicInfo.legalPerson.ogrn.validateOgrn) {
        errors.ogrn.push('Проверьте правильность огрн')
      }

      errors.kpp = []
      if (!this.$v.clinicInfo.legalPerson.kpp.required) {
        errors.kpp.push('Поле не может быть пустым')
      }
      if (!this.$v.clinicInfo.legalPerson.kpp.validateKpp) {
        errors.kpp.push('Проверьте правильность кпп')
      }

      errors.inn = []
      if (!this.$v.clinicInfo.legalPerson.inn.required) {
        errors.inn.push('Поле не может быть пустым')
      }
      if (!this.$v.clinicInfo.legalPerson.inn.validateInn) {
        errors.inn.push('Проверьте правильность инн')
      }

      errors.title = []
      if (!this.$v.clinicInfo.title.required) {
        errors.title.push('Поле не может быть пустым')
      }

      errors.priority = []
      if (!this.$v.clinicInfo.priority.required) {
        errors.priority.push('Поле не может быть пустым')
      }

      errors.contractNumber = []
      if (!this.$v.clinicInfo.contractNumber.required) {
        errors.contractNumber.push('Поле не может быть пустым')
      }

      // errors.appTitle = [];
      // if (!this.$v.clinicInfo.appTitle.required) {
      //   errors.appTitle.push('Поле не может быть пустым');
      // }

      // errors.network = [];
      // if (!this.$v.clinicInfo.clinicNetwork.required) {
      //   errors.network.push('Поле не может быть пустым');
      // }

      errors.city = []
      if (!this.$v.clinicInfo.city.required) {
        errors.city.push('Поле не может быть пустым')
      }

      errors.latitude = []
      if (!this.$v.clinicInfo.latitude.required) {
        errors.latitude.push('Поле не может быть пустым')
      }
      if (!this.$v.clinicInfo.latitude.isValid) {
        errors.latitude.push('Некорректное значение')
      }

      errors.longitude = []
      if (!this.$v.clinicInfo.longitude.required) {
        errors.longitude.push('Поле не может быть пустым')
      }
      if (!this.$v.clinicInfo.longitude.isValid) {
        errors.longitude.push('Некорректное значение')
      }

      errors.address = []
      if (!this.$v.clinicInfo.address.required) {
        errors.address.push('Поле не может быть пустым')
      }

      errors.authorization = { login: [], password: [] }

      if (!this.$v.clinicInfo.authorization.login.required) {
        errors.authorization.login.push('Поле не может быть пустым')
      }

      if (!this.$v.clinicInfo.authorization.password.required) {
        errors.authorization.password.push('Поле обязательно для заполнения')
      }
      if (!this.$v.clinicInfo.authorization.password.minLength) {
        errors.authorization.password.push('Длина не менее 6 знаков')
      }

      return errors
    },
    typesOfServicesErrors() {
      const errors = []
      if (
        !this.clinicInfo.isOms &&
        !this.clinicInfo.isDms &&
        !this.clinicInfo.isTelemed
      ) {
        errors.push('Выберите хотя-бы одну')
      }

      if (errors.length) return errors
      return false
    },
    signContractErrors() {
      const errors = []
      if (
        !this.clinicInfo.clinicAttributes ||
        !this.clinicInfo.clinicAttributes.length
      ) {
        errors.push('Выберите хотя-бы одну')
      }

      if (errors.length) return errors
      return false
    },
    currentClinicData() {
      return this.$store.state.Clinic.clinicInfo
    },
    selectedAgencyAgreement() {
      if (!this.clinicInfo.clinicAttributes) return

      return this.clinicInfo.clinicAttributes.some((item) => item === 0)
    }
  },
  async created() {
    this.isLoading = true

    this.clinicInfo.clinicOffers = this.OFFERS.map((item) => ({
      type: item.id,
      title: item.title,
      text: ''
    }))

    this.networks = (
      await this.$store.dispatch(this.$types.NETWORK_LIST_FETCH)
    ).sort((a, b) => a.title.localeCompare(b.title))
    this.cities = (
      await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' })
    ).sort((a, b) => a.name.localeCompare(b.name)).filter(c=>c.name && c.id)

    // this.checkRoleAccess_(['Dis.monitoring.operator'])
    if (
      this.checkFeatureAccess({
        name:
          'Создание клиники. При открытии странички выбрать ОМС в виде услуг и привязке клиники',
        url: '/clinics/:clinicId'
      })
    ) {
      this.clinicInfo.isOmsPay = true
      this.clinicInfo.isOms = true
    }

    if (this.id) {
      try {
        const clinicInfo = await this.$store.dispatch(
          this.$types.CLINIC_FETCH,
          this.id
        )

        this.clinicInfo = {
          ...clinicInfo,
          clinicAttributes: clinicInfo.clinicAttributes
            ? clinicInfo.clinicAttributes
            : []
        }

        if (this.clinicInfo?.authorization) {
          this.hadAuthorization = true
        } else {
          this.$set(this.clinicInfo, 'authorization', {
            login: null,
            password: null
          })
          this.showPasswordInput = true
        }

        // this.clinicInfo.contacts.forEach((contact) => {
        //   const { type } = this.contactTypes.find((contactType) => contactType.index === contact.type);

        //   if (this.contacts[type][0].phoneNumber === null) {
        //     this.contacts[type] = [contact];
        //   } else {
        //     this.contacts[type] = [...this.contacts[type], contact];
        //   }
        // });
      } finally {
        this.isLoading = false
      }
    } else {
      this.$set(this.clinicInfo, 'authorization', {
        login: null,
        password: null
      })
      this.showPasswordInput = true
      this.isLoading = false
    }

    this.setWatcher()
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CLINIC_DOCUMENTS_SET, [])
  },
  methods: {
    fillDataFromReestr(payload) {
      this.selectedDataFromReestr = payload;
      this.clinicInfo.title = payload?.name;
      this.clinicInfo.legalPerson.name = payload?.nameFull;
      this.clinicInfo.legalPerson.kpp = payload?.kpp
      this.clinicInfo.legalPerson.ogrn = payload?.ogrn
      this.clinicInfo.legalPerson.inn = payload?.inn
      this.clinicInfo.medicalOrganizationOID = payload?.oid
      this.clinicInfo.city = this.cities.find(c=>c.name === payload?.areaName);
      this.clinicInfo.address = this.generateAdressString(payload)
    },

    generateAdressString(payload) {
      return  payload?.addrRegionName ?  (
        payload?.addrRegionName +
        ', ' +
        (payload.prefixArea ? payload.prefixArea + ' ' : '') +
        payload?.areaName +
        (payload.streetName
          ? ', ' + payload.prefixStreet + ' ' + payload.streetName
          : '') +
        (payload.house ? ', д. ' + payload.house : '') +
        (payload.building ? ', стр. ' + payload.building : '') +
        (payload.struct ? ', корп. ' + payload.struct : '')
      ) : null;
    },

    openSelectFromReestrModal() {
      this.isSelectFromReestrModalOpen = true
    },
    async saveFile(row) {
      const { data: file } = await api.get(
        `File/clinicdocument/${row.fileId}`,
        { responseType: 'blob' }
      )

      saveFile(file, { name: row.title })
    },
    documentType(row) {
      if (row.type === 1) {
        return 'Договор'
      }
      if (row.type === 3) {
        return 'Лицензия'
      }
      if (row.type === 4) {
        return 'Прайс'
      }
      if (row.type === 5) {
        return 'Скан'
      }
      return 'Учредительные документ'
    },

    async fetchLegalPersons({ skip, take, query }) {
      const clinics = await this.$store.dispatch(
        this.$types.LEGAL_PERSONS_FETCH,
        {
          skip,
          take,
          query
        }
      )

      return clinics
    },

    saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray

      this.$v.document.fileId.$model = newFile.guid
      this.$v.document.title.$model = newFile.name
    },
    openDownloadFileModal() {
      Bus.$emit('open-modal:file-upload-clinic', {
        saveFileCallback: this.saveFileCallback,
        multiple: false,
        fileApi: 'File/clinicdocument/upload'
      })
    },
    setNewLegalPerson() {
      this.clinicInfo.legalPerson = {
        contacts: [
          {
            id: 0,
            legalPersonId: 0,
            email: null,
            fio: null,
            phoneNumber: null,
            position: null
          }
        ],
        id: 0,
        inn: null,
        kpp: null,
        leaderFio: null,
        leaderPosition: null,
        name: null,
        ogrn: null,
        site: null
      }
    },
    addEmail() {
      this.clinicInfo.contactsForIL.push('')
    },
    deleteEmail(index) {
      this.clinicInfo.contactsForIL.splice(index, 1)
    },
    cancelEditAdministation() {
      this.clinicInfo.authorization.login = null
      this.clinicInfo.authorization.password = null
      this.isAdminEdit = false
    },
    setWatcher() {
      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_INFO, false)
      this.unwatch = this.$watch(
        'clinicInfo',
        () => {
          if (this.unwatch) {
            this.unwatch()
          }
          this.$store.commit(
            this.$types.CLINICS_SET_IS_CHANGED_CLINIC_INFO,
            true
          )
        },
        {
          deep: true
        }
      )
    },
    async copyHashtag(hashtag) {
      try {
        await navigator.clipboard.writeText(hashtag.key)

        showMessage({
          type: 'success',
          title: 'Хештег скопирован в буфер обмена',
          message: 'Вставьте хештег, куда вам удобно (ctrl+v)'
        })
      } catch (e) {
        console.error(e)
        showMessage({
          type: 'danger',
          title: 'Что-то пошло не так',
          message: e
        })
      }
    },
    addContact() {
      const newContact = {
        email: null,
        fio: null,
        comment: null,
        isMain: false,
        phoneNumber: null,
        position: null
      }

      this.clinicInfo.contacts = [...this.clinicInfo.contacts, newContact]
    },
    addLegalPersonContact() {
      const newContact = {
        id: 0,
        legalPersonId: this.clinicInfo.legalPerson.id,
        email: null,
        fio: null,
        phoneNumber: null,
        position: null
      }

      this.clinicInfo.legalPerson.contacts = [
        ...this.clinicInfo.legalPerson.contacts,
        newContact
      ]
    },
    deleteContact(index) {
      this.clinicInfo.contacts.splice(index, 1)
    },
    onClickClose() {
      if (this.isChangedInfo) {
        if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
          return
        }
      }

      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_INFO, false)
      this.$emit('vuedals:close')
    },
    async onClickSave() {
      this.clinicInfo.documents = this.clinicDocuments
      this.checkErrors = true
      this.setWatcher()
      this.$v.$touch()

      if (
        this.$v.clinicInfo.legalPerson.ogrn.$error ||
        this.$v.clinicInfo.legalPerson.inn.$error ||
        this.$v.clinicInfo.legalPerson.kpp.$error
      ) {
        showMessage({
          type: 'error',
          title: 'Ошибка валидации юр.лица',
          message: 'Проверьте правильность заполнения огрн, инн, кпп'
        })
      }
      if (
        this.$v.$error ||
        this.typesOfServicesErrors ||
        this.signContractErrors
      ) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей'
        })
        return
      }
      if (this.clinicInfo.clinicAttributes.includes(1) && this.offersError) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации "Оферта"',
          message: 'Проверьте правильность заполнения полей'
        })
        return
      }
      if (
        !(
          this.clinicInfo.authorization.login &&
          this.clinicInfo.authorization.password
        )
      ) {
        this.clinicInfo.authorization = {
          login: null,
          password: null
        }
      }

      const preformattedClinicInfo = {
        ...this.clinicInfo,
        appTitle: this.clinicInfo.title,
        isCreateAdminClinic: !this.disMonitoring
      }
      delete preformattedClinicInfo.clinicNetwork
      delete preformattedClinicInfo.legalPerson

      try {
        this.isSaving = true
        let legalPersonId = null

        if (this.isNewLegalModeSelected) {
          legalPersonId = await this.createOrUpdateLegalPerson()
        } else {
          legalPersonId = this.clinicInfo.legalPerson.id
          // if (this.isNeedUpdateLegarPerson) await this.createOrUpdateLegalPerson();
        }
        preformattedClinicInfo.legalPerson = { id: legalPersonId }

        if (this.id) {
          await this.$store.dispatch(
            this.$types.CLINIC_UPDATE,
            preformattedClinicInfo
          )
        } else {
          const { id } = await this.$store.dispatch(
            this.$types.CLINIC_CREATE,
            preformattedClinicInfo
          )
          this.$emit('create-clinic', id)
          this.$store.commit(this.$types.CLINIC_DOCUMENTS_SET, [])
        }
      } catch (e) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: e.response.data
        })
      } finally {
        this.isSaving = false
      }
    },
    async createOrUpdateLegalPerson() {
      const legalPErsonParam = {
        ...this.clinicInfo.legalPerson,
        network: this.getActualNetworkForLegalPerson
      }

      const action = this.isNewLegalModeSelected
        ? this.$types.LEGAL_ENTITIES_CREATE
        : this.$types.LEGAL_ENTITIES_UPDATE

      // if (this.newNetwork) {
      //   legalPErsonParam.network = this.newNetworkName ? { id: 0, title: this.newNetworkName } : null;
      // } else {
      //   legalPErsonParam.network = this.clinicInfo.clinicNetwork ? { id: this.clinicInfo.clinicNetwork.id } : null;
      // }

      try {
        const { data } = await this.$store.dispatch(action, legalPErsonParam)
        return data.id
      } catch {
        return null
      }
    },
    async focusPassword() {
      this.$refs.password.$refs.input.focus()
      await this.$nextTick()
      this.$refs.password.$refs.input.setSelectionRange(
        this.passwordSelection.start,
        this.passwordSelection.end
      )
    },
    async toggleShowPassword() {
      this.showPassword = !this.showPassword
      await this.$nextTick()
      this.focusPassword()
    },
    onblurPassword(event) {
      this.passwordSelection.start = event.target.selectionStart
      this.passwordSelection.end = event.target.selectionEnd
    },
    deleteLegalPersonContact(index) {
      this.clinicInfo.legalPerson.contacts.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.clinic-edit {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 1px;
}
.clinics-scroll-content {
  height: calc(100vh - 300px);
  margin: 0 -10px;
}
.crm-form-block {
  width: 100%;
}
.crm-checkbox {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-label {
    color: rgba(144, 143, 141, 0.9);
  }
}
.crm-checkbox-wrapper {
  margin-bottom: 15px;
}
.contact-form {
  &-input {
    margin-bottom: 35px;
  }
}
::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
.button_transparent {
  padding: 0;
  background-color: transparent;
  color: $blue;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  flex-basis: 144px;
}
.without-border {
  border: none;
}
.coord-prefix {
  color: #908f8d;
  font-size: 16px;
  line-height: 20px;
}
.file-button {
  font-weight: 300;
  width: auto;
  padding: 7px 20px;
}
::v-deep.crm-icon-cross {
  margin-right: 15px;

  path {
    fill: $blue;
  }
}
.clinics-form-fields_authorization {
  align-items: flex-end;
}
.password-button {
  flex-basis: 370px;
  margin-right: 30px;
}
.password-description {
  margin: 40px 0 0 0;
  color: #908f8d;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
::v-deep.coord-input {
  .crm-wrap-input.with-prefix {
    .crm-base-input {
      padding-left: 40px !important;
    }
  }
}
::v-deep.crm-icon-eye {
  cursor: pointer;

  &:hover,
  &.active {
    path {
      fill: $blue;
    }
  }
}
.button_transparent {
  padding: 0;
  background-color: transparent;
  color: $blue;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  flex-basis: 144px;
  border: none;
}

.button_password_edit {
  height: 40px;
  padding: 10px;
  flex-basis: unset;
  width: unset;
  align-self: flex-end;
}

.line-title {
  position: relative;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #979797;
  margin-bottom: 30px;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #e0e0e0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
    display: inline-block;
  }
}

::v-deep.crm-form-field {
  .divide-line {
    align-self: center;
    height: 1px;
    width: 30px;
    background-color: #323232;
    flex-shrink: 0;
    margin: 0 20px;
  }

  .crm-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 10px 0;
    color: #707070;
  }
}

::v-deep.crm-radio-input:checked + .crm-radio-text {
  color: $blue;
}

.crm-radio-buttons_vertical {
  flex-direction: column;
  margin-bottom: 15px;

  .crm-radio-button {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

::v-deep.person-select {
  .vs__dropdown-toggle {
    width: 100%;
  }
}

::v-deep.crm-form-input {
  &-prefix {
    align-self: center;
    margin-right: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  &-postfix {
    align-self: center;
    margin-left: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  & + .crm-form-input {
    margin-left: 20px;
  }
}

.crm-form-field-email {
  display: flex;
  align-items: center;

  .crm-form-email-delete {
    margin-left: 10px;
  }
}

::v-deep.crm-form-field-email + .crm-form-field-email {
  margin-top: 10px;
}

.crm-form-checkbox-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px 0;

  .crm-form-checkbox {
    margin: 10px 15px;
  }
}

.crm-form-checkbox-text {
  font-size: 14px;
  line-height: 18px;
}

.crm-info-text.contact {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.contact-legal-delete {
  align-self: flex-end;
  margin-left: -10px;
  margin-bottom: 5px;
}

.crm-icon-delete {
  cursor: pointer;
}

.editor-item {
  margin-bottom: 50px;
}

.editor-container {
  position: relative;
  transform: translate(0, 39px);
}

.editor-hashtags {
  position: absolute;
  width: 100%;
  padding: 6px;
  height: 40px;
  top: 8px;
  left: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #ccc;

  &__item {
    padding: 3px 6px;
    font-size: 13px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
      border: 1px solid #133bd3;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}

::v-deep .ql-toolbar {
  transform: translate(0, -39px);
}
</style>
