<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Список прайсов'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <custom-scrollbar>
        <div class="p-4">
          <!--          <b-input-group class="bg-white rounded">-->
          <!--            <b-form-input-->
          <!--              v-model="filter"-->
          <!--              type="search"-->
          <!--              placeholder="Поиск"-->
          <!--              @keyup.enter="pricesGet"-->
          <!--            />-->
          <!--            <b-input-group-prepend>-->
          <!--              <b-button-->
          <!--                variant="outline-primary"-->
          <!--                class="rounded-0 border-secondary"-->
          <!--                @click="pricesGet"-->
          <!--              >-->
          <!--                <b-icon icon="search" />-->
          <!--              </b-button>-->
          <!--            </b-input-group-prepend>-->
          <!--          </b-input-group>-->
          <b-table
            :bordered="true"
            :hover="true"
            :fields="fields"
            class="text-center mt-4"
            small
            show-empty
            :items="rows"
          >
            <template #empty="">
              <div class="d-flex justify-content-center align-items-center">
                <h4 class="p-2">
                  Не найдено <b-icon
                    icon="exclamation-triangle-fill"
                    variant="danger"
                  />
                </h4>
              </div>
            </template>
          </b-table>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  addHours,
} from 'date-fns';
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';

export default {
  name: 'PricesListViewModal',
  components: {},
  props: {
    clinicId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      filter: '',
      rows: [],
      fields: [
        {
          key: 'code',
          label: 'Код услуги',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Название',
          tdClass: 'align-middle',
          sortable: true,
        },
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
        },
      ],
    };
  },
  // async created() {
  //   this.isLoading = true;
  //   const params = {
  //     clinicId: this.clinicId,
  //   };
  //   this.rows = await this.$store.dispatch(this.$types.CLINIC_PRICES_LIST_FETCH, params);
  //   this.isLoading = false;
  // },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
    // async pricesGet() {
    //   this.isLoading = true;
    // const params = {
    //   clinicId: this.clinicId,
    //   search: this.filter,
    // };
    // this.rows = await this.$store.dispatch(this.$types.CLINIC_PRICES_LIST_FETCH, params);
    //   this.isLoading = false;
    // },
    async downloadTable(id) {
      const { data: fileName } = await api.get(`File/name/${id}`);
      const { data: file } = await api.get(`File/${id}`, { responseType: 'blob' });
      saveFile(file, { name: fileName });
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.fileIcon{
  cursor: pointer;
}
.crm-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
