<template>
  <div class="nps-options-list">
    <div v-for="option in optionsList" :key="option.id" class="nps-options-list_item">
      <p class="title">{{ option.title }}</p>
      <p>{{ option.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OptionsList',
  props: {
    optionsList: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .nps-options-list {
    background-color: #FFFFFF;
    border-radius: 8px;
    &_item {
      padding: 8px 16px;
      color: #7F838D;
      font-size: 12px;
      line-height: 1;
      &:not(:last-child) {
        border-bottom: 1px solid #E8E8EA;
      }
      p {
        margin-bottom: 0;
        &.title {
          font-weight: 500;
          color: #161E2F;
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
    }
  }
</style>
