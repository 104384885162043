<template>
  <div :class="['multi-select', {'multi-select--disabled': isDisabled }]">
    <div
      class="dropdown w-full flex items-start justify-between bg-white rounded cursor-pointer"
      @click="toggleDropdown"
    >
      <div class="flex items-center">
        <div
          v-if="!selectedItems.length"
          class="w-full"
        >
          Выберите МКБ
        </div>
        <div class="flex flex-wrap gap-1">
          <div
            v-for="(item, index) in selectedItems"
            :key="index"
            class="tag"
          >
            <div>{{ item }}</div>
            <b-icon
              icon="x"
              class="ml-2"
              @click.stop="removeItem(item)"
            />
          </div>
        </div>
      </div>
      <b-icon
        icon="chevron-down"
        class="ml-2"
      />
    </div>
    <div
      v-if="isView"
      class="list"
    >
      <div class="search">
        <b-icon icon="search" />
        <input
          v-model="search"
          type="text"
          placeholder="Поиск по МКБ"
          @input="onSearch"
        >
      </div>
      <div
        v-if="groupedData.length"
        class="list-mkb custom-scrollbar"
      >
        <div
          v-for="(category, index) in groupedData"
          :key="index"
        >
          <label class="checkbox">
            <input
              type="checkbox"
              :checked="isCategorySelected(category.category)"
              @change="toggleCategory(category.category)"
            >
            <span class="font-medium">
              {{ category.category }}
            </span>
          </label>
          <div
            v-for="item in category.diagnoses"
            :key="item.diagnosis"
          >
            <label class="checkbox ml-2">
              <input
                type="checkbox"
                :checked="isSelected(item.diagnosis)"
                @change="toggleItem(item.diagnosis)"
              >
              <div>
                <span class="font-medium">
                  {{ item.diagnosis }}:
                </span>
                {{ item.name }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div
        v-else
        class="empty-mkb"
      >
        МКБ не найдены
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';
import storiesService from '@/services/';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isView: false,
      fullItems: [],
      items: [],
      selectedItems: [],
      search: '',
    };
  },
  computed: {
    groupedData() {
      const grouped = this.items.reduce((acc, item) => {
        if (!acc[item.category]) {
          acc[item.category] = [];
        }
        acc[item.category].push({
          diagnosis: item.diagnosis,
          name: item.name,
        });
        return acc;
      }, {});
      return Object.keys(grouped).map((key) => ({
        category: key,
        diagnoses: grouped[key],
      }));
    },
  },
  watch: {
    selectedItems() {
      if (this.selectedItems.length === 0) {
        this.$emit('input', null);
      } else {
        this.$emit('input', JSON.stringify(this.selectedItems));
      }
    },
  },
  mounted() {
    this.init();
    if (this.value) {
      this.selectedItems = JSON.parse(this.value);
    }
  },
  methods: {
    toggleDropdown() {
      this.isView = !this.isView;

      if (this.isView) {
        document.addEventListener('click', this.handleClickOutside);
      } else {
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isView = false;
        document.removeEventListener('click', this.handleClickOutside);
      }
    },
    isSelected(item) {
      return this.selectedItems.includes(item);
    },
    toggleItem(item) {
      if (this.isSelected(item)) {
        this.selectedItems = this.selectedItems.filter((i) => i !== item);
      } else {
        this.selectedItems.push(item);
      }
    },
    isCategorySelected(category) {
      return this.groupedData.find((group) => group.category === category)
        .diagnoses.every((item) => this.isSelected(item.diagnosis));
    },
    toggleCategory(category) {
      const group = this.groupedData.find((i) => i.category === category)
        .diagnoses.map((i) => i.diagnosis);

      if (this.isCategorySelected(category)) {
        this.selectedItems = this.selectedItems.filter(
          (item) => !group.includes(item),
        );
      } else {
        this.selectedItems.push(...group.filter((item) => !this.isSelected(item)));
      }
    },
    removeItem(item) {
      this.selectedItems = this.selectedItems.filter((i) => i !== item);
    },
    onSearch: debounce(async function onSearch() { await this.init(); }, 600),
    async init() {
      try {
        let list = await storiesService.getMkbList({
          skip: 0,
          take: 300,
          pattern: this.search,
        });

        const seenCodes = new Set();

        list = list.flatMap((i) => {
          const categoryName = `${i.categoryCode} ${i.name}`;
          if (i.diagnoses && i.diagnoses.length > 0) {
            return i.diagnoses
              .filter((diagnosis) => !seenCodes.has(diagnosis.code))
              .map((diagnosis) => {
                seenCodes.add(diagnosis.code);
                return {
                  category: categoryName,
                  diagnosis: diagnosis.code,
                  name: diagnosis.name,
                };
              });
          }

          if (!seenCodes.has(i.code)) {
            seenCodes.add(i.code);
            return [{
              category: categoryName,
              diagnosis: i.code,
              name: i.name,
            }];
          }

          return [];
        });

        this.items = list;
      } catch (error) {
        console.error('Ошибка при получении списка МКБ:', error);
      }
    },
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss">
.multi-select {
  position: relative;
  width: 100%;
  &.error {
    .dropdown {
      border-color: #dc3545;
    }
  }
  &--disabled {
    cursor: no-drop;
    .dropdown {
      background-color: #e9ecef !important;
      pointer-events: none;
    }
  }
  .dropdown {
    position: relative;
    min-height: 38px;
    max-height: 100px;
    overflow-y: auto;
    padding: 10px 12px;
    border: 1px solid #CED4DA;
    border-radius: 4px;
    z-index: 100;
  }

  .tag {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 14px;
    padding: 0 8px;
    border-radius: 4px;
  }

  .list {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    z-index: 100;
    background: #fff;
    border: 1px solid #CED4DA;
    border-radius: 4px;

    .checkbox {
      display: flex;
      margin-bottom: 12px;
    }

    input[type='checkbox'] {
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin-right: 10px;
      border-radius: 4px;
    }

    .list-mkb {
      padding: 12px;
      max-height: 300px;
      overflow: auto;
    }
  }

  .search {
    display: flex;
    align-items: center;
    background: #fff;
    width: 100%;
    padding: 0 12px;
    border-bottom: 1px solid #CED4DA;

    input {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      padding: 0 10px;
      outline: none;
    }
  }

  .custom-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 100%;
    }

    &::-webkit-scrollbar-track {
      background-color: #e8e8e8;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #aeaeae;
    }
  }

  .empty-mkb {
    padding: 12px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
