import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.TASK_TRACKER_FETCH]({ commit }, {
    skip,
    take,
    clear,
    save,
    filter,
  }) {
    NProgress.start();
    if (save && clear) {
      commit(localTypes.TASK_TRACKER_TASKS_SET, []);
    }

    try {
      const params = {};

      if (filter.searchString != null) params.searchString = filter.searchString;
      if (filter.createDate != null) params.createDate = filter.createDate;
      if (filter.status != null) params.status = filter.status;

      params.skip = skip;
      params.take = take;

      const { data: tasks } = await api.post('v2/taskTracker/search', params);

      if (save) {
        commit(localTypes.TASK_TRACKER_TASKS_ADD, tasks);
      }
      return tasks;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.TASK_TRACKER_TASK_FETCH](ctx, { id }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const { data: task } = await api.get('v2/taskTracker', { params });

      return task;
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить задачу',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.TASK_TRACKER_TASK_CREATE](ctx, params) {
    NProgress.start();

    try {
      const { data: task } = await api.post('v2/taskTracker', params);
      console.log(task);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Задача успешно создана!',
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать задачу',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.TASK_TRACKER_TASK_UPDATE](ctx, params) {
    NProgress.start();

    try {
      const { data: task } = await api.put('v2/taskTracker', params);
      console.log(task);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Задача успешно обновлена!',
      });
    } catch (e) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить задачу',
      });
    } finally {
      NProgress.done();
    }
  },
};
