<template>
  <div class="w-100">
    <b-container
      fluid="xl"
    >
      <div class="d-flex align-items-center py-4">
        <div class="h3 mb-0 mr-auto">
          Регионы
        </div>

        <!-- v-if="checkFeatureAccess({ name: 'Создание пациента', url: '/patients' })" -->
        <b-button
          variant="primary"
          class="ml-2"
          size="sm"
          @click="openRegionEditModal"
        >
          Добавить регион
        </b-button>
      </div>

      <div class="d-flex">
        <b-form-input
          v-model="query"
          type="text"
          placeholder="Введите название региона"
          @keyup.native.enter="regionsFetch"
        />

        <b-button
          variant="primary"
          class="ml-2"
          @click="regionsFetch"
        >
          <b-icon icon="search" />
        </b-button>
      </div>

      <div class="d-flex mt-2 mb-2">
        <b-form-checkbox
          v-model="showDeleted"
          size="sm"
          class="ml-auto"
        >
          <span class="text-secondary">
            Показывать удаленные
          </span>
        </b-form-checkbox>
      </div>

      <div
        v-if="!regions?.length && !isLoading"
      >
        Ничего не найдено
      </div>

      <div
        v-else-if="isLoading"
        class="d-flex justify-content-center mt-5"
      >
        <b-spinner
          label="Loading..."
          variant="primary"
          style="width: 5rem; height: 5rem;"
        />
      </div>

      <div
        v-else
        class="regions-list pr-2"
      >
        <RegionItem
          v-for="region in regions"
          :key="region.id"
          :region="region"
          class="regions-item"
        />
      </div>
    </b-container>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import RegionItem from './RegionItem';

export default {
  name: 'RegionsPage',
  page: {
    title: 'CRM Doctis - Регионы',
  },
  components: {
    RegionItem,
  },
  data() {
    return {
      query: '',
      showDeleted: false,
      isLoading: false,
      limit: 15,
      regions: [],
    };
  },
  computed: {},
  created() {
    Bus.$on('regions:update', this.regionsFetch);
  },
  beforeDestroy() {
    Bus.$off('regions:update', this.regionsFetch);
  },
  methods: {
    openRegionEditModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'RegionEditModal',
      });
    },
    async regionsFetch() {
      if (this.isLoading) return;

      this.isLoading = true;

      try {
        const regions = await this.$store.dispatch(this.$types.REGIONS_FETCH, { isActive: !this.showDeleted });
        this.regions = regions.filter((r) => {
          const isValidRegion = this.showDeleted ? true : r.isActive;
          const isValidRegionName = r.name.toLowerCase().includes(this.query.trim().toLocaleLowerCase());
          return isValidRegion && isValidRegionName;
        });
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.regions-list {
  height: calc(100vh - 200px);
  overflow: auto;
}
</style>
