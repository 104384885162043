<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Уведомление"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    trigger="ok"
    @ok="onClose"
  >
    <!-- @hidden="onClose" -->
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      {{ text }} <br> <br>
      ID полиса <b>{{ policyId }}</b>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="disabledBtn"
        @click="onClose"
      >
        Закрыть
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'PolicyHasIndemnityModal',
  components: {
    Preloader,
  },
  props: {
    policyId: {
      type: [String, Number],
      default: null,
    },
    text: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      disabledBtn: true,
    };
  },
  created() {
    setTimeout(() => {
      this.disabledBtn = false;
    }, 3000);
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
