<template>
  <div
    v-if="patient"
    class="patient-info"
  >
    <custom-scrollbar>
      <div class="patient-info-inner">
        <template v-if="isEdit">
          <div class="person-form-fields">
            <base-input
              v-model.trim="$v.patient.lastName.$model"
              :error="$v.patient.lastName.$error"
              :errors="errorsValidation.lastName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Фамилия"
              placeholder="Введите фамилию"
              required
            />

            <base-input
              v-model.trim="$v.patient.firstName.$model"
              :error="$v.patient.firstName.$error"
              :errors="errorsValidation.firstName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Имя"
              placeholder="Введите имя"
              required
            />

            <base-input
              v-model="patient.middleName"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Отчество"
              placeholder="Введите отчество"
            />
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  ID пациента
                </p>
                <div class="crm-input-value">
                  {{ person.id }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <base-input
              v-model="$v.patient.phoneNumber.$model"
              :error="$v.patient.phoneNumber.$error"
              :errors="errorsValidation.phoneNumber"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Номер тел. куда будут звонить"
              placeholder="+"
              :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            />
            <!-- required -->
            <base-input
              v-model="$v.patient.email.$model"
              :error="$v.patient.email.$error"
              :errors="errorsValidation.email"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              :mask="emailMask"
              class="crm-form-field"
              label="E-mail"
            />
            <base-input
              v-model="patient.snils"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Снилс"
              :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]"
              placeholder="XXX-XXX-XXX XX"
            />

            <base-input
              v-model="$v.patient.userName.$model"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="UserName (Телемед)"
              placeholder="+"
              :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /./, /./, /./, /./, /./, /./, /./, /./]"
            />
          </div>

          <div class="person-form-fields">
            <base-input
              v-model="patient.userPhoneNumber"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Номер телефона пользователя"
              placeholder="+"
              :mask="['+', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]"
            />
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label required">
                  Пол
                </p>
                <div class="crm-radio-buttons">
                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="true"
                  >
                    Мужской
                  </base-radio-button>

                  <base-radio-button
                    v-model="patient.sex"
                    class="crm-radio-button"
                    :name="false"
                  >
                    Женский
                  </base-radio-button>

                  <div
                    v-if="$v.patient.sex.$error"
                    class="validation-errors"
                  >
                    <span
                      class="validation-error-text"
                    >
                      {{ errorsValidation.sex[0] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label required">
                  Дата рождения
                </p>
                <base-date-picker
                  v-model="$v.patient.birthDate.$model"
                  :error="$v.patient.birthDate.$error"
                  :max-date="maxDate"
                />
              </div>
            </div>
            <div class="crm-form-field" />
          </div>

          <div class="person-form-fields">
            <!-- <base-async-select
              v-model="citySelect"
              :fetch-function="fetchCities"
              label="Город"
              class="crm-form-field"
              placeholder="Поиск города"
            /> -->
            <div class="crm-form-field">
              <div class="crm-form-block">
                <div class="crm-label">
                  Город
                </div>
                <v-select
                  v-model="patient.city"
                  :options="cities"
                  placeholder="Выберите город"
                  label="name"
                  :clearable="true"
                  class="crm-select"
                />
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Статус
                </p>
                <div class="crm-input-value crm-input-value--checkbox">
                  <base-checkbox
                    v-model="patient.isVip"
                    class="crm-checkbox"
                    @change="patient.isTop = null"
                  >
                    VIP
                  </base-checkbox>
                  <base-checkbox
                    v-model="patient.isTop"
                    class="crm-checkbox"
                    @change="patient.isVip = null"
                  >
                    ТОП
                  </base-checkbox>
                </div>
              </div>
            </div>
          </div>

          <div class="person-form-fields">
            <base-input
              v-model="$v.patient.comment.$model"
              :input-style="{
                padding: '11px 10px 12px',
                width: '100%',
                backgroundColor: '#ffffff',
              }"
              type="textarea"
              class="crm-form-field w-100"
              label="Комментарий"
            />
          </div>

          <div class="person-form-fields">
            <base-input
              v-model="patient.patientAddress"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Адрес фактического проживания"
              placeholder="Введите адрес"
            />

            <base-input
              v-model.trim="patient.passport.registrationAddress"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Адрес регистрации"
              placeholder="Введите адрес регистрации"
            />
          </div>

          <!-- Паспорт -->
          <div class="person-form-fields">
            <base-input
              v-model.trim="patient.passport.series"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Серия паспорта"
              placeholder="Введите серию паспорта"
            />
            <base-input
              v-model.trim="patient.passport.number"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Номер паспорта"
              placeholder="Введите номер паспорта"
            />
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Дата выдачи паспорта
                </p>
                <base-date-picker
                  v-model="patient.passport.issueDate"
                  :max-date="maxDate"
                />
              </div>
            </div>
            <base-input
              v-model.trim="patient.passport.issuedBy"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Кем выдан"
              placeholder="Кем выдан"
            />
            <base-input
              v-model.trim="patient.passport.issueId"
              :input-style="{
                padding: '11px 10px 12px',
                resize: 'none',
                backgroundColor: '#ffffff',
              }"
              class="crm-form-field"
              label="Код подразделения"
              placeholder="Код подразделения"
            />
          </div>
          <!-- Паспорт -->

          <div class="crm-wrapper-buttons">
            <!-- v-if="!checkRoleAccess_([
              'Operator',
              'MedicalCurator',
              'Dis.monitoring.operator',
              'polis.operator',
              'polis.operator.dop',
              'Dis.monitoring.team'
            ])" -->
            <base-button
              v-if="checkFeatureAccess({
                name: 'Возможность заблокировать пациента',
                url: '/patients',
              })"
              :type="$const.WARNING_BUTTON"
              class="crm-button width-unset mr-2"
              @click="openDeleteModal"
            >
              Заблокировать
            </base-button>
            <!-- v-if="!checkRoleAccess_([
              'Operator',
              'MedicalCurator',
              'Dis.monitoring.operator',
              'polis.operator',
              'polis.operator.dop',
              'Dis.monitoring.team'
            ])" -->
            <base-button
              v-if="checkFeatureAccess({
                name: 'Возможность деперсонализировать пациента',
                url: '/patients',
              })"
              :type="$const.WARNING_BUTTON"
              class="crm-button width-unset mr-auto"
              @click="openDepersonalizateModal"
            >
              Деперсонализировать
            </base-button>

            <base-button
              :type="$const.SECONDARY_BUTTON"
              class="crm-button button_transparent ml-auto"
              @click="toggleEditMode"
            >
              Отменить
            </base-button>

            <base-button
              :type="$const.PRIMARY_BUTTON"
              :loading="isSaving"
              class="crm-button width-unset"
              @click="onClickSave"
            >
              Сохранить изменения
            </base-button>
          </div>
        </template>
        <template v-else>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Фамилия
                </p>
                <div class="crm-input-value">
                  {{ person ? person.lastName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Имя
                </p>
                <div class="crm-input-value">
                  {{ person ? person.firstName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Отчество
                </p>
                <div class="crm-input-value">
                  {{ person ? person.middleName: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  ID пациента
                </p>
                <div class="crm-input-value">
                  {{ person.id }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Номер тел. куда будут звонить
                </p>
                <div class="crm-input-value">
                  {{ person ? person.phoneNumber: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  E-mail
                </p>
                <div class="crm-input-value">
                  {{ person ? person.email: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Снилс
                </p>
                <div class="crm-input-value">
                  {{ person ? person.snils: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  UserID
                </p>
                <div class="crm-input-value">
                  {{ person.userId }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Пол
                </p>
                <div class="crm-input-value">
                  {{ personSex }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Дата рождения
                </p>
                <div class="crm-input-value">
                  {{ personBirthDate }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Возраст
                </p>
                <div class="crm-input-value">
                  {{ personAge }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  UserName (Телемед)
                </p>
                <div class="crm-input-value">
                  {{ patient.userName }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Номер телефона пользователя
                </p>
                <div class="crm-input-value">
                  {{ person ? person.userPhoneNumber: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Город
                </p>
                <div class="crm-input-value">
                  {{ person.city || null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Статус
                </p>
                <div class="crm-input-value">
                  {{ patient.isVip ? 'VIP' : '' }}
                  {{ patient.isTop ? 'ТОП' : '' }}
                  {{ !patient.isTop && !patient.isVip ? 'Не указан' : '' }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Регион
                </p>
                <div class="crm-input-value">
                  {{ regionName }}
                </div>
              </div>
            </div>
          </div>
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Адрес фактического проживания
                </p>
                <div class="crm-input-value">
                  {{ person ? person.patientAddress: null }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Адрес регистрации
                </p>
                <div class="crm-input-value">
                  {{ patient.passport.registrationAddress || null }}
                </div>
              </div>
            </div>
          </div>
          <!-- Паспорт -->
          <div class="person-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Серия паспорта
                </p>
                <div class="crm-input-value">
                  {{ person.passport.series }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Номер паспорта
                </p>
                <div class="crm-input-value">
                  {{ person.passport.number }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Дата выдачи паспорта
                </p>
                <div class="crm-input-value">
                  {{ personIssueDate }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Кем выдан
                </p>
                <div class="crm-input-value">
                  {{ person.passport.issuedBy }}
                </div>
              </div>
            </div>
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-label">
                  Код подразделения
                </p>
                <div class="crm-input-value">
                  {{ person.passport.issueId }}
                </div>
              </div>
            </div>
          </div>
          <!-- Паспорт -->
          <div class="person-form-fields">
            <div class="crm-form-field w-100">
              <div class="crm-form-block">
                <p class="crm-label">
                  Комментарий
                </p>
                <div class="crm-input-value text-break">
                  {{ patient.comment }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="person"
            class="crm-wrapper-buttons"
          >
            <!-- v-if="!checkRoleAccess_([
              'Operator',
              'MedicalCurator',
              'Dis.monitoring.operator',
              'polis.operator',
              'polis.operator.dop',
              'Dis.monitoring.team',
              'Dis.monitoring.admin',
            ])" -->
            <base-button
              v-if="checkFeatureAccess({
                name: 'Возможность заблокировать пациента',
                url: '/patients',
              })"
              :type="$const.WARNING_BUTTON"
              class="crm-button width-unset mr-2"
              @click="openDeleteModal"
            >
              Заблокировать
            </base-button>
            <!-- v-if="!checkRoleAccess_([
              'Operator',
              'MedicalCurator',
              'Dis.monitoring.operator',
              'polis.operator',
              'polis.operator.dop',
              'Dis.monitoring.team',
              'Dis.monitoring.admin',
            ])" -->
            <base-button
              v-if="checkFeatureAccess({
                name: 'Возможность деперсонализировать пациента',
                url: '/patients',
              }) && notVisibleForDm"
              :type="$const.WARNING_BUTTON"
              class="crm-button width-unset mr-auto"
              @click="openDepersonalizateModal"
            >
              Деперсонализировать
            </base-button>

            <!-- !checkRoleAccess_(['Dis.monitoring.admin']) -->
            <div
              v-if="notVisibleForDm"
              class="bottom-buttons"
            >
              <base-button
                :type="$const.SECONDARY_BUTTON"
                class="crm-button width-unset ml-auto"
                @click="syncWithEcp"
              >
                Обновить данные
              </base-button>
              <base-button
                v-if="checkFeatureAccess({
                  name: 'Возможность редактировать пациента',
                  url: '/patients',
                })"
                :type="$const.SECONDARY_BUTTON"
                class="crm-button width-unset ml-auto"
                @click="toggleEditMode"
              >
                Редактировать
              </base-button>
            </div>
          </div>
          <b-row
            v-if="historyPatientData?.length"
            class="mt-4"
          >
            <b-col>
              <b-form-group>
                <span
                  class="mt-2"
                  style="font-weight: 500"
                  role="button"
                  @click="isHistoryVisible = !isHistoryVisible"
                >
                  История изменений пациента
                  <b-icon
                    v-if="!isHistoryVisible"
                    icon="arrow-down-short"
                    aria-hidden="true"
                  />
                  <b-icon
                    v-else
                    icon="arrow-up-short"
                    aria-hidden="true"
                  />
                </span>
                <b-collapse
                  id="collapse-3"
                  v-model="isHistoryVisible"
                  class="mt-4"
                >
                  <b-table
                    :bordered="true"
                    :hover="true"
                    :fields="fields"
                    small
                    :items="historyPatientData"
                  >
                    <template v-slot:cell(date)="row">
                      <span>{{ parseData(row.item.date) }}</span>
                    </template>
                  </b-table>
                </b-collapse>
              </b-form-group>
            </b-col>
          </b-row>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import {
  // isValid,
  parseISO,
  format,
  formatISO,
} from 'date-fns';
import Bus from '@/eventBus';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showMessage, dateWithoutTime } from '@/helpers/utils';
import emailMask from 'text-mask-addons/dist/emailMask';
import { mixinRoles } from '@/mixins';

import {
  BaseInput,
  // BaseAsyncSelect,
  BaseCheckbox,
  BaseButton,
  BaseRadioButton,
  BaseDatePicker,
} from '@/components/base';
import { ROLE } from '@/helpers/consts';

export default {
  name: 'PatientDetailPerson',
  components: {
    BaseInput,
    // BaseAsyncSelect,
    BaseButton,
    BaseCheckbox,
    BaseRadioButton,
    BaseDatePicker,
  },
  mixins: [validationMixin, mixinRoles],
  props: {
    person: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      regions: [],
      cities: [],
      emailMask,
      isSaving: false,
      isEdit: false,
      maxDate: new Date(),
      patient: {},
      isHistoryVisible: false,
      historyPatientData: null,
      fields: [
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Изменения',
          sortable: true,
        },
      ],
    };
  },
  validations() {
    return {
      patient: {
        lastName: { required },
        firstName: { required },
        birthDate: { required },
        sex: { required },
        phoneNumber: {
          required,
        },
        comment: {
        },
        email: {
          // required,
        },
        userName: {
          // required,
        },
      },
    };
  },
  computed: {
    notVisibleForDm() {
      return !this.$store.state.Auth.role.includes(ROLE.DIS_MONITORING);
    },
    errorsValidation() {
      const errors = {};

      errors.lastName = [];
      if (!this.$v.patient.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.patient.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.patient.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.email = [];
      if (!this.$v.patient.email.required) {
        errors.email.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.patient.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.patient.sex.required) {
        errors.sex.push('Выберите один из вариантов');
      }

      return errors;
    },
    personSex() {
      if (!this.person || this.person.sex === null) return null;
      return this.person.sex === true ? 'Мужской' : 'Женский';
    },
    personBirthDate() {
      if (!this.person.birthDate) return '-';
      return format(parseISO(this.person.birthDate), 'dd.MM.yyyy');
    },
    personIssueDate() {
      if (!this.person.passport.issueDate) return '-';
      return format(this.person.passport.issueDate, 'dd.MM.yyyy');
    },
    personAge() {
      if (!this.person || this.person.birthDate === null) return null;
      const date = new Date(this.person.birthDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const dateNow = new Date();
      const dayNow = dateNow.getDate();
      const monthNow = dateNow.getMonth() + 1;
      const yearNow = dateNow.getFullYear();
      let age = yearNow - year;

      if ((monthNow < month) || (monthNow === month && dayNow < day)) {
        age -= 1;
      }

      return age;
    },
    regionName() {
      const region = this.regions.find((id) => id === this.patient.regionId);
      if (!region) return '';
      return region.name;
    },
  },
  async created() {
    await this.historyLoad();
    this.cities = (await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' })).sort((a, b) => a.name.localeCompare(b.name));
    this.regions = await this.$store.dispatch(this.$types.REGIONS_FETCH);
    this.setPerson();
  },
  methods: {
    fetchCities(query) {
      return this.$store.dispatch(this.$types.CITIES_FETCH, query);
    },
    async syncWithEcp() {
      const updatedPatient = await this.$store.dispatch(this.$types.PATIENT_SYNC, this.person.id);
      this.$emit('update-patient', updatedPatient);
    },
    onClickClose() {
      if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
        return;
      }
      this.$emit('vuedals:close');
    },
    async historyLoad() {
      const personId = this.person.id;
      this.historyPatientData = await this.$store.dispatch(this.$types.PATIENT_HISTORY_FETCH, personId);
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      const formattedPatientData = {
        ...this.patient,
        userName: this.patient.userName.replace('+', ''),
        birthDate: dateWithoutTime(formatISO(this.patient.birthDate)),
        snils: this.patient.snils || null,
      };

      if (typeof formattedPatientData.city === 'object' && formattedPatientData.city !== null) {
        formattedPatientData.city = formattedPatientData.city ? formattedPatientData.city.name : null;
      }

      formattedPatientData.phoneNumber = formattedPatientData.phoneNumber
        .split(' ')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('-')
        .join('');
      formattedPatientData.userPhoneNumber = formattedPatientData.userPhoneNumber
        .split(' ')
        .join('')
        .split('(')
        .join('')
        .split(')')
        .join('')
        .split('-')
        .join('');

      try {
        this.isSaving = true;

        await this.$store.dispatch(this.$types.PATIENT_UPDATE, formattedPatientData);
      } catch (e) {
        console.error(e);
      } finally {
        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
        this.toggleEditMode();
        this.isSaving = false;
      }
    },
    setPerson() {
      const { patientAddress, passport, birthDate } = this.person;

      if (this.person) {
        const passportData = passport || {
          series: '',
          number: '',
          issueDate: null,
          issuedBy: '',
          issueId: '',
          registrationAddress: '',
        };

        if (passportData.issueDate) passportData.issueDate = new Date(passportData.issueDate);

        this.patient = {
          ...this.person,
          patientAddress: patientAddress || null,
          passport: passportData,
        };

        this.person.passport = passportData;

        if (birthDate) this.patient.birthDate = new Date(dateWithoutTime(birthDate));
      }
    },
    toggleEditMode() {
      this.setPerson();
      this.isEdit = !this.isEdit;
    },
    openDeleteModal() {
      Bus.$emit('open-modal:patient-delete', {
        patientId: this.person.id,
        callback: () => {
          this.$emit('vuedals:close');
        },
      });
    },
    openDepersonalizateModal() {
      Bus.$emit('open-modal:patient-depersonalizate', {
        patientId: this.person.id,
        callback: () => {
          this.$emit('vuedals:close');
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-info {
  background-color: #fff;
  padding: 30px 20px 30px 30px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  height: 100%;
  box-sizing: border-box;
}
.bottom-buttons {
  display: flex;
}
.patient-info-inner {
  padding-right: 10px;
}

.crm-form-block {
  width: 100%;
  display: block;
}

::v-deep.crm-radio-buttons {
  position: relative;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;

  .crm-radio-button{
    flex: 1;
  }

  .crm-radio-button + .crm-radio-button {
    border-left: 1px solid #E0E0E0;
  }
  .crm-radio-text {
    padding: 0 10px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    text-align: center;

    &::before, &::after {
      display: none;
    }
  }
  .crm-radio-input:checked + .crm-radio-text {
    background: #6E88F3;
    color: #fff;
  }
}

.person-form-fields {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

::v-deep.crm-form-field {
  width: 270px;
  &:not(:first-child) {
    margin-left: 10px;
  }

  .crm-label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
  }

  .base-input {
    height: 50px;
  }
}

.button_transparent {
  border: none;
}
.crm-button.width-unset {
  width: unset;
  padding-left: 40px;
  padding-right: 40px;
}

::v-deep.crm-input-value {
  background: #F9F9F9;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 14px;
  line-height: 14px;

  &--checkbox {
    background: #fff;
    padding: 0;

    .custom-checkbox {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;

      & + .custom-checkbox {
        margin-left: 20px;
      }
    }
  }
}

::v-deep label {
  margin-bottom: 0;
}
</style>
