import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
  saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

// function mockApi(object, time) {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(object), time);
//   });
// }

export default {
  async [localTypes.COMPANIES_FETCH]({ commit }) {
    NProgress.start();

    commit(localTypes.COMPANIES_LOADING_SET, true);
    try {
      const { data: companies } = await api.get('v2/insuranceCompanies');

      commit(localTypes.COMPANIES_SET, companies);

      return companies;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить страховые компании',
      });
    } finally {
      commit(localTypes.COMPANIES_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.COMPANY_FETCH]({ commit }, id) {
    NProgress.start();

    commit(localTypes.COMPANY_LOADING_SET, true);
    try {
      const { data: company } = await api.get(`v2/insuranceCompanies/${id}`);

      commit(localTypes.COMPANY_SET, company);

      return company;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить страховую компанию',
      });
    } finally {
      commit(localTypes.COMPANY_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.COMPANY_CREATE]({ commit }, company) {
    NProgress.start();

    commit(localTypes.COMPANY_LOADING_SET, true);
    try {
      await api.post('v2/insuranceCompanies', company);

      showMessage({
        type: 'success',
        message: 'Страховая компания создана',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать страховую компанию',
      });

      throw (e);
    } finally {
      commit(localTypes.COMPANY_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.COMPANY_UPDATE]({ commit }, company) {
    NProgress.start();

    commit(localTypes.COMPANY_LOADING_SET, true);
    try {
      await api.put('v2/insuranceCompanies', company);

      showMessage({
        type: 'success',
        message: 'Страховая компания обновлена',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить страховую компанию',
      });

      throw (e);
    } finally {
      commit(localTypes.COMPANY_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.PROGRAMS_FETCH]({ commit }, idCompany) {
    NProgress.start();

    commit(localTypes.PROGRAMS_LOADING_SET, true);
    try {
      const { data: programs } = await api.get(`v2/insuranceCompanies/${idCompany}/programs`);

      commit(localTypes.PROGRAMS_SET, programs);

      return programs;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить страховые программы',
      });
    } finally {
      commit(localTypes.PROGRAMS_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.PROGRAM_FETCH]({ commit }, id) {
    NProgress.start();

    commit(localTypes.PROGRAM_LOADING_SET, true);
    try {
      const { data: program } = await api.get(`v2/insuranceCompanies/programs/${id}`);

      commit(localTypes.PROGRAM_SET, program);

      return program;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить программу',
      });
    } finally {
      commit(localTypes.PROGRAM_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.SUBPROGRAMS_FETCH]({ commit }, idProgram) {
    NProgress.start();

    commit(localTypes.SUBPROGRAMS_LOADING_SET, true);
    try {
      const { data: subprograms } = await api.get(`v2/insuranceCompanies/programs/${idProgram}/subprograms`);

      commit(localTypes.SUBPROGRAMS_SET, subprograms);

      return subprograms;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить страховые подпрограммы',
      });
    } finally {
      commit(localTypes.SUBPROGRAMS_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.SUBPROGRAM_FETCH]({ commit }, id) {
    NProgress.start();

    commit(localTypes.SUBPROGRAM_LOADING_SET, true);
    try {
      const { data: subprogram } = await api.get(`v2/insuranceCompanies/programs/subprogram/${id}`);

      return subprogram;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить программу',
      });
    } finally {
      commit(localTypes.SUBPROGRAM_LOADING_SET, false);
      NProgress.done();
    }
  },
  async [localTypes.PROGRAM_CREATE](_, { companyId, program }) {
    NProgress.start();

    try {
      const { data: programId } = await api.post(`v2/insuranceCompanies/${companyId}/program`, program);

      showMessage({
        type: 'success',
        message: 'Страховая программа создана',
      });

      return programId;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать страховую программу',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.PROGRAM_UPDATE](_, params) {
    NProgress.start();

    try {
      const data = await api.put('v2/insuranceCompanies/program', params);

      showMessage({
        type: 'success',
        message: 'Страховая программа обновлена',
      });

      return data;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить страховую программу',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SUBPROGRAM_CREATE](_, { subprogram, companyId, programId }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      if (companyId) params.append('companyId', companyId);
      if (programId) params.append('programId', programId);

      await api.post('v2/insuranceCompanies/programs/subprogram', subprogram, { params });

      showMessage({
        type: 'success',
        message: 'Страховая подпрограмма создана',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать страховую подпрограмму',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SUBPROGRAM_UPDATE](_, { subprogram, companyId, programId }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      if (companyId) params.append('companyId', companyId);
      if (programId) params.append('programId', programId);

      await api.put('v2/insuranceCompanies/programs/subprogram', subprogram, { params });

      showMessage({
        type: 'success',
        message: 'Страховая подпрограмма обновлена',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось обновить страховую подпрограмму',
      });

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.FILENAME_BY_ID_FETCH](_, id) {
    NProgress.start();

    try {
      const { data: fileName } = await api.get(`File/name/${id}`);

      return fileName;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить название файла',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.INSURANCE_POLICY_EXPORT](_, params) {
    NProgress.start();

    try {
      const { data } = await api.post('v2/policy/export', params, { responseType: 'blob' });
      // console.log(data);
      saveFile(data, { name: 'export.xlsx' });
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SET_SECTION_TYPE]({ commit }, type) {
    NProgress.start();
    console.log(type);
    commit(localTypes.SECTION_TYPE_SET, type);
  },
  async [localTypes.KNOWLEDGE_DOCUMENT_CREATE](_, params) {
    NProgress.start();
    try {
      const { data } = await api.post('/v2/knowledge', params, { responseType: 'blob' });
      console.log(data);
      // saveFile(data, { name: 'export.xlsx' });
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.GET_KNOWLEDGE_DOCUMENTS]({ commit }, type) {
    NProgress.start();
    try {
      const { data } = await api.get(`v2/knowledge/list?type=${type}`);
      console.log(data);
      commit(localTypes.KNOWLEDGE_DOCUMENTS_SET, data);
      // saveFile(data, { name: 'export.xlsx' });
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.KNOWLEDGE_DOCUMENT_UPDATE](_, params) {
    NProgress.start();
    try {
      await api.put('v2/knowledge', {
        id: params.id,
        type: params.type,
        fileIds: params.fileIds,
        name: params.name,
        description: params.description,
      });
    } catch (e) {
      console.warn(e);
      throw (e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.KNOWLEDGE_DOCUMENT_DELETE](_, id) {
    NProgress.start();
    try {
      await api.delete(`v2/knowledge?id=${id}`);
    } catch (e) {
      console.warn(e);
      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
