<template>
  <div
    ref="wrapper"
    :class="{ clickable: isOverflow }"
    class="crm-info-item"
    @click="toggleView"
  >
    <div
      :class="{ overflow: isOverflow, fullview: isFullView }"
      class="clinic-info--wrapper"
    >
      <div
        class="crm-info--wrapper"
      >
        <p class="crm-info-block">
          <span class="crm-info-block--name">
            {{ clinic.appTitle || clinic.title }}
          </span>
        </p>

        <!-- <div class="crm-info-block">
          <p class="crm-info-block--label">
            Юр. лицо:
          </p>

          <p class="crm-info-text">
            {{ clinic.title }}
          </p>
        </div> -->

        <div class="crm-info-block">
          <p class="crm-info-block--label">
            Адрес:
          </p>

          <p class="crm-info-text crm-info-text--address">
            {{ clinic.addressString }}
          </p>
        </div>

        <div
          class="crm-info-block"
        >
          <p class="crm-info-block--label">
            Телефон:
          </p>

          <div class="crm-info-text">
            <div
              v-for="(contact, idx) in clinic.contacts.filter(c => c.isMain)"
              :key="idx"
            >
              {{ contact.phoneNumber }}
              <!-- <span
                v-if="contact.comment"
                class="phone-comment"
              >
                {{ contact.comment }}
              </span> -->
            </div>
          </div>
        </div>

        <div
          class="crm-info-block"
        >
          <p class="crm-info-block--label">
            Почта для ГП:
          </p>

          <div class="crm-info-text">
            <div
              v-for="(email, idx) in clinic.contactsForIL"
              :key="idx"
            >
              {{ email }}
            </div>
          </div>
        </div>

        <b-row>
          <b-col
            v-if="clinic.documents.length"
            class="mb-1"
          >
            <span class="font-weight-bold"> Документы клиники </span>
            <div
              class="d-flex mt-2"
            >
              <div class="d-flex flex-column">
                <template
                  v-for="(doc, index) in clinic.documents"
                >
                  <div
                    v-if="doc.type === 1 || doc.type === 3"
                    :key="index"
                    class="p-1"
                  >
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      class="w-100 d-flex align-items-center justify-content-start"
                      :title="doc.title"
                      variant="primary"
                      @click="saveClinicDocument(doc.fileId, doc.title)"
                    >
                      <b-icon icon="file-earmark-fill" />
                      <span class="ml-2">{{ documentType(doc) }}</span>
                    </b-button>
                  </div>
                </template>
              </div>
              <div class="d-flex flex-column">
                <template
                  v-for="(doc, index) in clinic.documents"
                >
                  <div
                    v-if="doc.type === 4 || doc.type === 5"
                    :key="index"
                    class="p-1"
                  >
                    <b-button
                      v-b-tooltip.hover
                      size="sm"
                      class="w-100 d-flex align-items-center justify-content-start"
                      :title="doc.title"
                      variant="primary"
                      @click="saveClinicDocument(doc.fileId, doc.title)"
                    >
                      <b-icon icon="file-earmark-fill" />
                      <span class="ml-2">{{ documentType(doc) }}</span>
                    </b-button>
                  </div>
                </template>
              </div>
            </div>
          </b-col>
          <b-col v-if="clinic.legalPerson && clinic.legalPerson.documents && clinic.legalPerson.documents.length">
            <span class="font-weight-bold"> Документы юр.лица </span>
            <div class="d-flex mt-2">
              <div class="d-flex flex-column">
                <div
                  v-for="doc in clinic.legalPerson.documents"
                  :key="doc.fileId"
                  class="p-1"
                >
                  <b-button
                    v-b-tooltip.hover
                    size="sm"
                    class="w-100 d-flex align-items-center justify-content-start"
                    :title="doc.title"
                    variant="primary"
                    @click="saveFile(doc.fileId, doc.name)"
                  >
                    <b-icon icon="file-earmark-fill" />
                    <span class="ml-2 doc-file-name">{{ doc.name }}</span>
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-button
        v-if="clinic.isDeleted"
        variant="primary"
        class="button-return-clinic"
        @click="openClinicRecoveryModal(clinic.id)"
      >
        Восстановить
      </b-button>

      <div class="extra-crm-info">
        <div class="extra-crm-info--wrapper">
          <div
            v-if="!(clinic.specialComment || clinic.services.length || clinic.priority )"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label">
              Нет дополнительной информации
            </p>
          </div>

          <div
            v-if="false"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label">
              Специализации
            </p>
            <p class="crm-info-text">
              <!-- TODO: специализации -->
            </p>
          </div>

          <div
            v-if="clinic.services.length"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label">
              Услуги
            </p>
            <div class="service-list">
              <div
                v-for="service in clinic.services"
                :key="service"
                class="service-item"
              >
                {{ clinicServices[service] }}
              </div>
            </div>
          </div>

          <div
            v-if="clinic.priority"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label">
              Приоритет клиники:
            </p>
            <p class="crm-info-text">
              {{ clinicPriority }}
            </p>
          </div>

          <div
            v-if="clinic.specialComment"
            class="extra-crm-info--block"
          >
            <p class="extra-crm-info--label">
              Особенности клиники
            </p>
            <p class="crm-info-text crm-info-text--special">
              {{ clinic.specialComment }}
            </p>
          </div>
        </div>
        <div class="extra-crm-info--controls">
          <icon-watch
            v-if="!checkRoleAccess(['ClinicAdministrator'])"
            class="crm-icon"
            @click="onClickEditIcon"
          />
          <!-- <icon-watch
            class="crm-icon"
            @click="onClickWatchIcon"
          /> -->

          <!--          <icon-edit
            v-if="!checkRoleAccess(['ClinicAdministrator'])"
            class="crm-icon"
            @click="onClickEditIcon"
          />-->
          <!-- v-if="!checkRoleAccess_(['ClinicAdministrator'])" -->
          <icon-history
            v-if="checkFeatureAccess({ name: 'История клиники', url: '/clinics' })"
            class="crm-icon"
            @click="openClinicHistoryModal"
          />
          <!-- <icon-guarantee-letter
            class="crm-icon"
          /> -->
          <!-- v-if="!checkRoleAccess_(['ClinicAdministrator', 'Operator', 'Tech.operator'])" -->
          <icon-delete
            v-if="checkFeatureAccess({ name: 'Удаление клиники', url: '/clinics' })"
            class="crm-icon-delete"
            @click="openClinicCardDeleteModal(clinic.id)"
          />
        </div>
      </div>
    </div>
    <div
      v-if="clinic.isDeleted"
      class="deleted-overlay"
    />
  </div>
</template>

<script>
// import { pick, pickBy } from 'lodash';

import Bus from '@/eventBus';
import { api } from '@/helpers/api';
import { mixinRoles } from '@/mixins';

import { CLINIC_SERVICES, CLINIC_PRIORITIES } from '@/helpers/consts';
import { saveFile } from '@/helpers/utils';

import IconWatch from 'assets/images/watch_icon.svg';
import IconHistory from 'assets/images/icon_history.svg';

// import IconGuaranteeLetter from 'assets/images/guarantee_letter.svg';
import IconDelete from 'assets/images/delete_icon.svg';

export default {
  name: 'ClinicInfo',
  components: {
    IconWatch,
    // IconGuaranteeLetter,
    IconDelete,
    IconHistory,
  },
  mixins: [mixinRoles],
  props: {
    clinic: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      maxHeight: 520,
      fields: [
        { key: 'id', label: 'Документ', thClass: 'text-center' },
      ],
      isOverflow: false,
      isFullView: false,
      clinicServices: CLINIC_SERVICES,
      clinicPriorities: CLINIC_PRIORITIES,
    };
  },
  computed: {
    // contacts() {
    //   const tempContacts = pick(this.clinic, [
    //     'appointmentContact',
    //     'clientServiceContact',
    //     'indemnityContact',
    //     'legalContact',
    //   ]);

    //   return pickBy(tempContacts, (contact) => contact && !!contact.phoneNumber);
    // },
    clinicPriority() {
      const index = this.clinic.priority;
      const priority = this.clinicPriorities.find((p) => p.index === index);
      return priority ? priority.title : null;
    },
  },
  mounted() {
    if (this.$refs.wrapper.offsetHeight > this.maxHeight) {
      this.isOverflow = true;
    }
  },
  methods: {
    documentType(row) {
      if (row.type === 1) {
        return 'Договор';
      }
      if (row.type === 3) {
        return 'Лицензия';
      }
      if (row.type === 4) {
        return 'Прайс';
      }
      if (row.type === 5) {
        return 'Скан';
      }
      return 'Учредительные документы';
    },
    async saveClinicDocument(fileId, name) {
      try {
        const { data: file } = await api.get(`File/clinicdocument/${fileId}`, { responseType: 'blob' });

        saveFile(file, { name });
      } catch (e) {
        console.error(e);
      }
    },
    async saveFile(fileId, name) {
      try {
        const { data: file } = await api.get(`File/${fileId}`, { responseType: 'blob' });

        saveFile(file, { name });
      } catch (e) {
        console.error(e);
      }
    },
    toggleView(event) {
      if (!this.isOverflow
        || event.target.classList.contains('crm-icon-delete')
        || event.target.classList.contains('icon')
        || event.target.tagName === 'path') {
        return;
      }
      this.isFullView = !this.isFullView;
    },
    openClinicRecoveryModal(id) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicRecoveryModal',
        props: {
          clinicId: id,
        },
      });
    },
    onClickEditIcon() {
      this.$store.commit(this.$types.CLINIC_INFO_IS_EDIT_SET, true);
      this.$router.push(`/clinics/${this.clinic.id}/`);
      // Bus.$emit('open-modal:clinic-edit', this.clinic.id);
    },
    onClickWatchIcon() {
      Bus.$emit('open-modal:clinic-info', this.clinic.id);
      // Bus.$emit('open-modal:clinic-edit', this.clinic.id);
    },
    openClinicHistoryModal() {
      // Bus.$emit('open-modal:clinic-history', this.clinic.id);
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ClinicHistoryModal',
        props: {
          clinicId: this.clinic.id,
        },
      });
    },
    openClinicCardDeleteModal(id) {
      Bus.$emit('open-modal:clinic-card-delete', {
        clinicId: id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-info--wrapper {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
  position: relative;

  &.overflow:not(.fullview) {
    max-height: 235px;
    overflow: hidden;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      box-shadow: 0px 0px 10px 4px $white;
      bottom: 0;
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 75px;
      background-image: url('~assets/images/select_arrow.svg?inline');
      width: 12px;
      height: 6px;
    }
  }
}
.clickable {
  cursor: pointer;
}
.crm-info--wrapper,
.extra-crm-info--wrapper{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.crm-info--wrapper {
  flex-basis: 456px;
  padding-right: 30px;
}
.extra-crm-info {
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-basis: 724px;
}
.crm-info-block {
  display: flex;

  &:not(:last-child) {
    margin: 0 0 20px 0;
  }

  .crm-info-block--name {
    font-weight: bold;
    // color: $blue-magenta;
  }
  .crm-info-block--label {
    color: $blue;
    margin: 0 10px 0 0;
    min-width: 85px;
  }
  .phone-comment {
    font-weight: normal;
  }
}
.crm-info-text {
  font-weight: 500;
  margin: 0;
  color: $black-light;
  white-space: pre-line;
  word-break: break-word;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.extra-crm-info--wrapper {
  flex-grow: 1;
  border-right: 1px solid rgba(218, 218, 218, 0.7);
  border-left: 1px solid rgba(218, 218, 218, 0.7);
  padding-left: 30px;
  padding-right: 46px;

  .extra-crm-info--block {

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .extra-crm-info--label {
    color: $blue;
    margin: 0 0 15px 0;
  }
}
.extra-crm-info--controls {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
}
::v-deep.crm-icon {

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.service-list {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  .service-item {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    line-height: 20px;
    background: #F3F3F3;
    border-radius: 20px;
  }
}

.crm-info-text--special {
  white-space: pre-line;
}

.crm-icon-delete {
  cursor: pointer;
  &:hover {
    cursor: pointer;

    rect {
      stroke: #6E88F3;
    }
  }
}

.button-return-clinic {
  width: unset;
  padding: 8px 20px 9px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  margin-top: 3rem;
  position: relative;
  z-index: 2;
}
.deleted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 4px;
  z-index: 1;
}
.headerTableClass{
  margin-top: 20rem !important;
}

.doc-file-name {
  max-width: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
