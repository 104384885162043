<template>
  <div
    class="analysis-content"
  >
    <custom-scrollbar>
      <div class="analysis-content-inner">
        <div class="crm-container">
          <div class="crm-title-row">
            <span class="crm-title">
              Анализы
            </span>

            <gemotest-logo
              width="142"
              viewBox="0 0 883 164"
              class="title-logo"
            />
          </div>

          <div class="crm-form-wrapper">
            <patient-data />
            <analysis-cart />
            <analysis-clinics />

            <base-button
              class="send-button"
              :loading="sendingOrder"
              @click="sendOrder"
            >
              Отправить ГП
            </base-button>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import {
  showMessage,
  emailValidate,
} from '@/helpers/utils';
import {
  // parseISO,
  formatISO,
  format,
} from 'date-fns';

import {
  BaseButton,
} from '@/components/base';

import GemotestLogo from '@/assets/images/gemotest_logo.svg';

import PatientData from '../components/Analysis/PatientData';
import AnalysisCart from '../components/Analysis/AnalysisCart';
import AnalysisClinics from '../components/Analysis/AnalysisClinics';
// import IconSearch from 'assets/images/search.svg';

export default {
  name: 'AnalysisPage',
  page: {
    title: 'CRM Doctis - Анализы',
  },
  components: {
    BaseButton,
    GemotestLogo,
    PatientData,
    AnalysisCart,
    AnalysisClinics,
  },
  data() {
    return {
      sendingOrder: false,
    };
  },
  computed: {
    patient() {
      return this.$store.state.Analysis.patient;
    },
    patientFullData() {
      return this.$store.state.Analysis.patientFullData;
    },
    clinic() {
      return this.$store.state.Analysis.selectedClinic;
    },
    medtests() {
      return this.$store.state.Analysis.medtests;
    },
  },
  created() {},
  beforeDestroy() {
    this.$store.commit(this.$types.ANALYSIS_STATE_DEFAULT_SET);
  },
  methods: {
    async sendOrder() {
      const errors = {
        patient: !this.patient,
        // eslint-disable-next-line max-len
        email: !!this.patient?.email && !emailValidate(this.patient?.email),
        medtests: !this.medtests.length,
        clinic: !this.clinic,
      };

      if (errors.patient) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Заполните данные пациента',
        });
        return;
      }

      if (errors.email) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Недопустимое значение для e—mail',
        });
        return;
      }

      if (errors.medtests) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Укажите выбранный пакет анализов',
        });
        return;
      }

      if (errors.clinic) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Выберите отделение',
        });
        return;
      }

      try {
        const formattedPatientData = {
          ...this.patientFullData.patient,
          policies: this.patientFullData.policies,
          email: this.patient.email,
          phoneNumber: this.patient.phoneNumber,
          birthDate: formatISO(this.patient.birthDate),
          firstName: this.patient.firstName,
          lastName: this.patient.lastName,
          middleName: this.patient.middleName,
          city: this.patient.city,
        };

        const orderData = {
          first_name: this.patient.firstName,
          last_name: this.patient.lastName,
          middle_name: this.patient.middleName,
          birthday: format(this.patient.birthDate, 'yyyy-MM-dd'),
          email: this.patient.email,
          phone: this.patient.phoneNumber,
          policy_id: this.patient.policyId,
          med_test: this.medtests.map((test) => test.id),
          clinic: this.clinic.id,
        };

        this.sendingOrder = true;

        await this.$store.dispatch(this.$types.PATIENT_UPDATE, formattedPatientData);
        await this.$store.dispatch(this.$types.ANALYSIS_INSURANCE_ORDER, orderData);
        this.$store.commit(this.$types.ANALYSIS_STATE_DEFAULT_SET);

        setTimeout(() => {
          window.location.reload();
          this.sendingOrder = false;
        }, 3000);
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: error,
        });
        this.sendingOrder = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-content {
  height: 100vh;
  width: 100%;
  transition: height 0.3s linear;
}
.analysis-content-inner {
  padding: 30px 0;
}
.crm-container {
  width: 100%;
  flex-direction: column;
}
.crm-title-row {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
}
.crm-title {
  @extend .page-title;
  margin-right: 10px;
}

.crm-form-wrapper {
  box-sizing: border-box;
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C7C7C7;
  border-radius: 10px;
  width: 100%;
}

.send-button {
  margin: 20px auto 0;
}
</style>
