<template>
  <div class="w-100 pt-4">
    <AsyncList
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container
        fluid="xl"
      >
        <div class="d-flex align-items-center mb-4 mt-4">
          <div class="h3">
            Очередь импорта
          </div>

          <!-- <b-button
            variant="primary"
            class="ml-auto"
            size="sm"
            @click="() => openRoleEditModal()"
          >
            Добавить новую роль
          </b-button> -->
        </div>

        <div
          v-if="!totalCount && !isLoading"
          class="px-3"
        >
          Ничего не найдено
        </div>

        <b-table
          v-if="totalCount"
          class="bg-white m-0"
          bordered
          :fields="fields"
          :items="items"
        >
          <template #cell(createdAt)="row">
            {{ formatDate(row.item.createdAt) }}
          </template>

          <template #cell(companyName)="row">
            <div :class="getImportClass(row.item)">
              {{ types[row.item.type] + ' - ' + row.item.companyName + ' / ' + row.item.programName }} <br>
              {{ row.item.originalName }}
            </div>
          </template>

          <template #cell(downloadsCount)="row">
            {{ row.item.successCount ? row.item.successCount : '0' }} / {{ row.item.allRowsCount }}
          </template>

          <template #cell(cloudKey)="row">
            <b-button
              size="sm"
              variant="primary"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.cloudKey, row.item.originalName)"
            >
              Скачать
            </b-button>
          </template>
          <template #cell(fileErrorCloudKey)="row">
            <b-button
              v-if="row.item.fileErrorCloudKey"
              size="sm"
              variant="warning"
              :disabled="isFileLoading"
              @click="saveImportFile(row.item.fileErrorCloudKey, row.item.originalFileErrorName)"
            >
              Скачать
            </b-button>

            <template v-else>
              —
            </template>
          </template>
        </b-table>
        <b-skeleton-table
          v-if="isLoading"
          :rows="25"
          :columns="fields.length"
          :table-props="{ bordered: true, striped: true }"
        />
      </b-container>
    </AsyncList>
  </div>
</template>

<script>
import {
  parseISO,
  format,
} from 'date-fns';

import AsyncList from '@/components/AsyncList';
import { dateWithoutTimeZone } from '@/helpers/utils';

export default {
  name: 'InsuranceImportList',
  page: {
    title: 'CRM Doctis - Очередь импорта',
  },
  components: {
    AsyncList,
  },
  data() {
    return {
      isLoading: false,
      isFileLoading: false,
      fields: [
        {
          key: 'createdAt',
          label: 'Дата',
        },
        {
          key: 'companyName',
          label: 'Информация',
        },
        {
          key: 'downloadsCount',
          label: 'Количество',
        },
        {
          key: 'cloudKey',
          label: 'Файл',
          class: 'td-button',
        },
        {
          key: 'fileErrorCloudKey',
          label: 'Ошибка',
          class: 'td-button',
        },
      ],
      items: [],
      allCount: 0,
      take: 25,
      page: 1,
      types: {
        5: 'Для РТ Доктис +смс',
        0: 'Добавление новых',
        1: 'С заменой существующих',
        2: 'Открепление клиник от полисов',
        3: 'Открепление',
        4: 'Изменение объема услуг',
      },
    };
  },
  computed: {
    totalCount() {
      return this.items.length;
    },
    isAllItemsLoaded() {
      return this.totalCount >= this.allCount;
    },
  },
  async mounted() {
    await this.itemsFetch();
  },
  methods: {
    async loadMoreItems() {
      if (this.isLoading) return;

      this.page += 1;
      await this.itemsFetch();
    },
    async itemsFetch() {
      if (this.isLoading) return;

      this.isLoading = true;
      try {
        const { originalCount, paginatedCollection } = (await this.$store.dispatch(this.$types.IMPORT_LIST_FETCH, {
          take: this.take,
          skip: (this.page - 1) * this.take,
        }));

        const newItems = paginatedCollection.map((item) => ({ ...item, createdAt: `${item.createdAt}z` }));
        this.items = this.items.concat(newItems);
        this.allCount = originalCount;
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    formatDate(date) {
      const newDate = dateWithoutTimeZone(date);
      return format(parseISO(new Date(newDate).toISOString()), 'dd.MM.yyyy HH:mm');
    },
    async saveImportFile(id, name) {
      this.isFileLoading = true;
      await this.$store.dispatch(this.$types.IMPORT_FILE_FETCH, { id, name });
      this.isFileLoading = false;
    },
    getImportClass({ isError, isProcessed }) {
      if (isProcessed && !isError) return 'text-success';
      if (!isProcessed && !isError) return 'text-warning';
      if (isError) return 'text-danger';

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.bg-white .td-button {
    width: 100px;
    text-align: center;
  }
</style>
