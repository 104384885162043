<template>
  <div class="rule-module">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <div class="font-bold mr-2">
          {{ rule.title }}
        </div>
        <b-badge
          v-if="rule.selected.length"
          href="#"
          variant="primary"
        >
          {{ rule.selected.length }}
        </b-badge>
      </div>

      <b-icon
        icon="pencil-fill"
        class="cursor-pointer"
        @click="editRule"
      />
    </div>

    <div
      v-if="selected.length"
      class="list"
    >
      <div
        v-for="(item, index) of selected"
        :key="index"
        class="item"
      >
        <div class="index">
          {{ index + 1 }}.
        </div>
        <div class="rule-item-wrapper">
          <template v-if="rule.id === 'specializations'">
            <div>{{ item.name }}</div>
          </template>

          <template v-if="rule.id === 'clinic'">
            <div>{{ item.title }}</div>
            <div class="address">
              {{ item.addressString }}
            </div>
          </template>

          <template v-if="rule.id === 'patients'">
            <div>{{ item.fullName }}</div>
            <div class="birthdate">
              {{ item.birthDate }}
            </div>
          </template>

          <template v-if="rule.id === 'doctors'">
            <div>{{ item.fullName }}</div>
          </template>

          <template v-if="rule.id === 'diases'">
            <div>{{ item.code }}: {{ item.name }}</div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-else
      class="empty"
    >
      {{ rule.placeholder }}
    </div>

    <RuleModuleFull
      v-if="isEdit"
      :rule="rule"
      @close="close"
      @save="save"
    />
  </div>
</template>

<script>
import RuleModuleFull from '@/components/Rules/RuleModuleFull';

export default {
  name: 'RuleModule',
  components: {
    RuleModuleFull,
  },
  props: {
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isEdit: false,
    };
  },
  computed: {
    selected() {
      return this.rule?.selected.slice(0, 6);
    },
  },
  methods: {
    close() {
      this.isEdit = !this.isEdit;
    },
    save() {
      this.$emit('save');
    },
    editRule() {
      this.rule.functionReset();
      this.isEdit = true;
    },
  },
};
</script>

<style lang="scss">
.rule-module {
  width: 100%;
  height: 400px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > .list {
    width: 100%;

    .item {
      width: 100%;
      padding: 12px;
      background: #f6f6f6;
      margin-bottom: 8px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .3s;

      .index {
        margin-right: 8px;
      }

      .rule-item-wrapper {
        .address {
          margin-top: 3px;
          font-size: 12px;
          opacity: 0.7;
        }

        .birthdate {
          margin-top: 3px;
          font-size: 12px;
          opacity: 0.7;
        }
      }
    }
  }
}
</style>
