import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.TASK_TRACKER_FILTER_SET](state, payload) {
    state.filter = { ...payload };
  },
  [localTypes.TASK_TRACKER_TASKS_SET](state, payload) {
    state.tasks = payload;
  },
  [localTypes.TASK_TRACKER_TASKS_ADD](state, payload) {
    state.tasks = [...state.tasks, ...payload];
  },
  [localTypes.TASK_TRACKER_IS_LOADING_SET](state, payload) {
    state.isLoading = payload;
  },
  [localTypes.TASK_TRACKER_IS_ALL_ITEMS_LOADED_SET](state, payload) {
    state.isAllItemsLoaded = payload;
  },
};
