<template>
  <div
    class="knowledge-base-documents"
  >
    <div>
      <icon-document
        v-if="documentData.fileIds.length"
        class="document-icon"
        @click="downloadDocument"
      />
    </div>
    <base-input
      v-if="isEditMode"
      v-model="nameEditor"
      type="textarea"
      :input-style="{
        border: 'none',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#323232',
        outline: 'none',
        resize: 'none',
        maxHeight: '100px',
      }"
      class="edit-input-name"
    />
    <span
      v-else
      class="document-name-text"
    >
      {{ documentData.name }}
    </span>
    <base-input
      v-if="isEditMode"
      v-model="descEditor"
      :input-style="{
        border: 'none',
        color: '#323232',
        fontSize: '16px',
        outline: 'none',
        resize: 'none',
      }"
      type="textarea"
      class="edit-input-desc"
    />
    <span
      v-else
      class="document-description-text"
    >
      {{ documentData.description }}
    </span>
    <div
      v-if="isEditMode"
      class="crm-icon-delete"
      @click="removeDocument"
    >
      <icon-delete
        @click="removeDocument"
      />
    </div>
    <div class="edit-btn">
      <icon-edit
        class="edit-icon crm-icon"
        :class="{'edit-document-active': isEditMode}"
        @click="openKnowledgeBaseEditor"
      />
    </div>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { saveFile } from '@/helpers/utils';

import IconEdit from 'assets/images/edit_icon.svg';
import IconDocument from 'assets/images/document_icon.svg';
import IconDelete from 'assets/images/delete_icon.svg';
import BaseInput from '../base/BaseInput';

export default {
  name: 'KnowledgeBaseDocumentItem',
  components: {
    BaseInput,
    IconEdit,
    IconDocument,
    IconDelete,
  },
  props: {
    documentData: {
      type: Object,
    },
  },
  data() {
    return {
      isEditMode: false,
      nameEditor: this.documentData.name,
      descEditor: this.documentData.description,
    };
  },
  methods: {
    openKnowledgeBaseEditor() {
      if (this.isEditMode) {
        this.$store.dispatch(this.$types.KNOWLEDGE_DOCUMENT_UPDATE, {
          id: this.documentData.id,
          type: this.documentData.type,
          fileIds: this.documentData.fileIds,
          name: this.nameEditor,
          description: this.descEditor,
        }).then(() => {
          this.$store.dispatch(this.$types.GET_KNOWLEDGE_DOCUMENTS, this.documentData.type);
          setTimeout(() => {
            this.isEditMode = false;
          }, 1000);
        });
      } else {
        this.isEditMode = true;
      }
    },
    removeDocument() {
      this.$store.dispatch(this.$types.KNOWLEDGE_DOCUMENT_DELETE, this.documentData.id).then(() => {
        this.$store.dispatch(this.$types.GET_KNOWLEDGE_DOCUMENTS, this.documentData.type);
        setTimeout(() => {
          this.isEditMode = false;
        }, 1000);
      });
    },
    async downloadDocument() {
      const { data: file } = await api.get(`File/getKnowledgeDocument/${this.documentData.fileIds[0]}`, { responseType: 'blob' });
      saveFile(file, { name: this.documentData.name });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.edit-icon {
  cursor: pointer;
  rect {
    stroke: #6E88F3;
  }
  path {
    fill: $blue;
  }
}
::v-deep.edit-document-active {
  cursor: pointer;
  rect {
    stroke: #6E88F3;
  }
  path {
    fill: white;
  }
  background: #6E88F3;
  border-radius: 4px;
}
.document-icon {
  cursor: pointer;
  transform: scale(0.672);
  path {
    fill: $blue;
  }
}

// ::v-deep .edit-input-desc{
//   .crm-base-input{
//     background: #6E88F3;
//   }
// }
.knowledge-base-documents {
  display: grid;
  grid-template-columns: 100px 400px 590px 1fr;
  align-items: center;
  border-bottom: 0.5px solid #E0E0E0;
  padding: 18px 0 17px 0;
  .document-name-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #323232;
  }
  .document-description-text {
    font-size: 16px;
    color: #323232;
  }
  .edit-btn {
    grid-column-end: none;
  }
  .edit-input-name {
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #323232;
    outline: none;
    resize: none;
  }
  .edit-input-desc {
    border: none;
    font-size: 16px;
    color: #323232;
    outline: none;
    height: 100%;
    resize: none;
  }
  .crm-icon-delete {
    cursor: pointer;
  }
}
</style>
