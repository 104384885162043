import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.DOCTORS_SET](state, payload) {
    state.doctors = payload;
  },
  [localTypes.DOCTORS_ADD](state, payload) {
    state.doctors = [...state.doctors, ...payload];
  },
  [localTypes.DOCTOR_REMOVE_BY_ID](state, payload) {
    state.doctors = state.doctors.filter((doctor) => doctor.id !== payload);
  },
  [localTypes.DOCTORS_CONSULTATIONS_SET](state, payload) {
    state.consultations = payload;
  },
  [localTypes.DOCTORS_CONSULTATIONS_ADD](state, payload) {
    state.consultations = [...state.consultations, ...payload];
  },
};
