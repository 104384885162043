import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
  saveFile,
  getCookie,
} from '@/helpers/utils';
import analysisApi from '@/helpers/analysis-api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.ANALYSIS_INSURANCE_ORDER](_, orderData) {
    NProgress.start();
    try {
      const csrftoken = getCookie('csrftoken');
      console.log(csrftoken);
      await analysisApi.post('insurance_order/', orderData, {
        headers: {
          // 'X-CSRFToken': csrftoken,
        },
      });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Гарантийное письмо успешно отправлено!',
      });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ANALYSIS_TEMPLATES_FETCH](_, {
    subprogramId,
    query,
    take,
    skip,
  }) {
    NProgress.start();
    try {
      const params = {};

      if (subprogramId) params.sub_program_id = subprogramId;
      params.search = query;
      params.limit = take;
      params.offset = skip;
      params.ordering = 'name';

      const { data: { results } } = await analysisApi.get('insurance_complex_med_test/', { params });
      return results;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ANALYSIS_MEDTEST_FETCH](_, {
    query,
    take,
    skip,
  }) {
    NProgress.start();
    try {
      const params = {};
      params.name = query;
      params.limit = take;
      params.offset = skip;
      params.ordering = 'name';

      const { data: { results } } = await analysisApi.get('med_test/', { params });
      return results;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ANALYSIS_CLINICS_FETCH](_, {
    query,
    take,
  }) {
    NProgress.start();
    try {
      const params = {};
      params.name = query;
      params.limit = take;
      params.ordering = 'address';

      const { data: { results } } = await analysisApi.get('clinic/', { params });
      return results;
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.ANALYSIS_INDEMNITY_PDF_DOWNLOAD](_, id) {
    NProgress.start();
    try {
      const { data: file } = await analysisApi.get(`insurance_order/${id}/print/`, { responseType: 'blob' });
      saveFile(file, { name: 'order', open: true });
    } catch (err) {
      console.error(err);
      throw err;
    } finally {
      NProgress.done();
    }
  },
};
