import { render, staticRenderFns } from "./ClinicInfoCardDeleteModal.vue?vue&type=template&id=52ceb24c&scoped=true"
import script from "./ClinicInfoCardDeleteModal.vue?vue&type=script&lang=js"
export * from "./ClinicInfoCardDeleteModal.vue?vue&type=script&lang=js"
import style0 from "./ClinicInfoCardDeleteModal.vue?vue&type=style&index=0&id=52ceb24c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52ceb24c",
  null
  
)

export default component.exports