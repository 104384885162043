<template>
  <div
    v-if="show"
    class="wrapper"
    @click="hidePreviewCover"
  >
    <div
      class="preview"
      :style="{ width: isImageWidth + 'px', height: isImageHeight + 'px' }"
    >
      <span
        class="close-button"
        @click="hidePreviewCover"
      >
        ×
      </span>
      <VStoryViewerCover
        :source="story.source"
        :source-type="story.sourceType"
        :story="story"
        :is-mobile="false"
        :active="true"
      />
      <b-button
        v-if="isPreviewButton"
        :variant="buttonPreviewStyle"
        size="md"
        class="preview__preview-btn"
      >
        {{ buttonPreviewText }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { VStoryViewerCover } from '@doctis.front/doctis.designsystem';

export default {
  name: 'StoryFilePreview',
  components: {
    VStoryViewerCover,
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    isPreviewButton: {
      type: Boolean,
      default: false,
    },
    buttonPreviewText: {
      type: String,
      default: '',
    },
    buttonPreviewStyle: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {

    };
  },
  computed: {
    isImageWidth() {
      return this.isMobile ? '250' : '350';
    },
    isImageHeight() {
      return this.isMobile ? '500' : '700';
    },
  },
  methods: {
    hidePreviewCover(event) {
      if (event.target.classList.contains('close-button')) {
        this.$emit('hide-preview', false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.preview {
  margin: auto;
  position: relative;
  z-index: 1000;
  &__preview-btn {
    position: absolute;
    left: 57%;
    zoom: 1.5;
    transform: translateX(-50%);
    bottom: 30px;
    pointer-events: none;
  }
}
.close-button {
  position: absolute;
  top: -15px;
  right: -75px;
  font-size: 50px;
  color: #fff;
  cursor: pointer;
}
</style>
