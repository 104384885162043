<template>
  <div class="knowledge-base-document-container">
    <div class="knowledge-base-document-header-wrapper">
      <span
        v-for="t in headerTitle"
        :key="t"
        class="document-header-text"
      >
        {{ t }}
      </span>
    </div>
    <div
      v-for="item in documentList"
      :key="item.id"
    >
      <knowledge-base-document-item
        :document-data="item"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import KnowledgeBaseDocumentItem from './KnowledgeBaseDocumentItem';

export default {
  name: 'KnowledgeBaseDocuments',
  components: {
    KnowledgeBaseDocumentItem,
  },
  data() {
    return {
      headerTitle: ['Файл', 'Наименование файла', 'Описание'],
      isEditMode: false,
    };
  },
  computed: {
    ...mapGetters({
      documentList: types.KNOWLEDGE_DOCUMENTS_GET,
    }),
  },
};
</script>

<style lang="scss" scoped>
.knowledge-base-document-container {
  .knowledge-base-document-header-wrapper {
    display: grid;
    grid-template-columns: 100px 400px 1fr;
    padding-bottom: 17px;
    border-bottom: 0.5px solid #C7C7C7;
    .document-header-text {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #979797;
    }
  }
}
</style>
