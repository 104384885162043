<template>
  <div>
    <template v-if="isDeactivationState">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-if="isSelectDeactivationReasonState">
      <span class="section-title">Причина деактивации</span>
      <div class="crm-wrapper-radio">
        <base-radio-button
          v-model="deactivationReason"
          :name="0"
        >
          Программа не продается<br>
          (обследование продолжается)
        </base-radio-button>

        <base-radio-button
          v-model="deactivationReason"
          :name="1"
        >
          Программа не активна<br>
          (все полисы деактивированы)
        </base-radio-button>
      </div>
      <div class="comment-container">
        <span class="section-title">Комментарий к деактивации</span>
        <base-input
          v-model="deactivationComment"
          placeholder="Ввведите текст"
          :input-style="{ resize: 'none', height: '151px', padding: '10px', marginTop: '10px'}"
          type="textarea"
        />
      </div>
      <div class="crm-container-buttons">
        <base-button
          :type="$const.PRIMARY_BUTTON"
          class="button-import"
          @click="startDeactivation"
        >
          <span>Деактивировать</span>
        </base-button>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseRadioButton,
  BaseInput,
} from '@/components/base';
import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';

export default {
  name: 'InsuranceSubProgramDeactivateModal',
  components: {
    BaseButton,
    BaseRadioButton,
    Preloader,
    BaseInput,
  },
  props: {
    subprogram: {
      type: Object,
      default: () => {},
    },
    deactivationCallback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isDeactivationState: false,
      isSelectDeactivationReasonState: true,
      deactivationReason: 0,
      deactivationComment: '',
    };
  },
  methods: {
    clearState() {
      this.isDeactivationState = false;
      this.isSelectDeactivationReasonState = false;
    },
    async startDeactivation() {
      try {
        this.clearState();
        this.isDeactivationState = true;
        const q = `subProgramId=${this.subprogram.id}&reasonDeactivation=${this.deactivationReason}&commentDeactivation=${this.deactivationComment}`;
        await api.put(`v2/insuranceCompanies/deactivationSubProgram?${q}`);
        this.deactivationCallback();
        this.$emit('vuedals:close');
      } catch (e) {
        this.clearState();
        this.isSelectDeactivationReasonState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-wrapper-radio {
    & label {
      margin: 16px 0px;
    }
  }
  .section-title {
    font-weight: 500;
    font-size: 16px;
    color: $black-light;
    line-height: 20px;
  }
  .info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }
</style>
