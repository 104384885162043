<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Изменение услуги'"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group label="Название услуги">
            <b-form-input
              v-model="$v.serviceData.name.$model"
              :disabled="!isChange"
              type="text"
              :state="validateState('name')"
              :placeholder="'Введите название услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Описание услуги">
            <b-form-textarea
              v-model="$v.serviceData.description.$model"
              :disabled="!isChange"
              type="text"
              :state="validateState('description')"
              :placeholder="'Введите описание услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Срок изготовления">
            <b-form-input
              v-model="$v.serviceData.productionTime.$model"
              type="text"
              :disabled="!isChange"
              :state="validateState('productionTime')"
              :placeholder="'Введите срок изготовления услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Подготовка к анализу">
            <b-form-textarea
              v-model="$v.serviceData.preparation.$model"
              type="text"
              :disabled="!isChange"
              :state="validateState('preparation')"
              :placeholder="'Введите описание подготовки к анализу'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Противопоказания">
            <b-form-input
              v-model="$v.serviceData.contraindications.$model"
              :disabled="!isChange"
              type="text"
              :state="validateState('contraindications')"
              :placeholder="'Введите противопоказания к услуге'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Расшифровка">
            <b-form-textarea
              v-model="$v.serviceData.decoding.$model"
              type="text"
              :disabled="!isChange"
              :state="validateState('decoding')"
              :placeholder="'Введите расшифровку услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Код услуги">
            <b-form-input
              v-model="$v.serviceData.code.$model"
              type="text"
              :disabled="!isChange"
              :state="validateState('code')"
              :placeholder="'Введите код услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-form-group label="Стоимость услуги">
            <b-form-input
              v-model="serviceData.cost"
              :placeholder="'Введите стоимость услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="serviceData.isActive"
              :disabled="!isChange"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            v-model="isCheckup"
            size="sm"
            :disabled="!isChange"
            @change="onCheckUp"
          >
            <span class="text-secondary font-weight-bold"> Чекап </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="isCheckup">
          <b-col>
            <b-form-group label="Дочерние услуги">
              <multiselect
                v-model="serviceData.childs"
                :multiple="true"
                placeholder="Выберите дочернюю услугу"
                :disabled="!isChange"
                :close-on-select="false"
                label="name"
                track-by="id"
                :searchable="true"
                :options="serviceList"
                @search-change="fetchServices"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row
        v-if="$v.serviceData.childs.$error"
        class="mb-5"
      >
        <b-col>
          <div
            class="validation-errors"
          >
            <span class="validation-error-text">
              Данное поле обязательно*
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Категории">
            <multiselect
              v-model="serviceData.tags"
              :multiple="true"
              placeholder="Выберите категорию"
              :close-on-select="false"
              :disabled="!isChange"
              label="name"
              track-by="id"
              :options="tags"
              @search-change="fetchTags"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          :disabled="!isChange"
          @click="onClickUpdateService"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          :disabled="!isChange"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { api } from '@/helpers/api';
import debounce from 'lodash.debounce';
import { mixinRoles } from '@/mixins';

const FETCH_DELAY = 700;

export default {
  name: 'EditLaboratoryServiceModal',
  components: { },
  mixins: [validationMixin, mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    serviceId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    cities: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isCheckup: false,
      rows: [],
      serviceData: {
        childs: [],
        name: '',
        description: '',
        productionTime: '',
        preparation: '',
        contraindications: '',
        decoding: '',
        code: '',
        cost: null,
        id: null,
        isActive: null,
        tags: [],
      },
      optionsActivate: [
        { value: null, text: 'Выберите состояние' },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      serviceList: [],
      tagsId: [],
      tags: [],
    };
  },
  validations() {
    return {
      serviceData: {
        name: { required },
        code: { required },
        description: { required },
        productionTime: { required },
        preparation: { required },
        contraindications: { required },
        decoding: { required },
        childs: {
          required: this.isCheckup ? required : () => true,
        },
      },
    };
  },
  computed: {
    isChange() {
      return this.checkFeatureAccess({ name: 'Изменение услуги', url: '/laboratory-service' });
    },
  },
  async created() {
    await this.fetchServiceInfo();
    await this.fetchServices();
    await this.fetchTags();
    if (this.serviceData.childs.length) this.isCheckup = true;
  },
  methods: {
    onCheckUp() {
      this.serviceData.childs = [];
    },
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchServiceInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(
        this.$types.GET_LABORATORY_SERVICE_FOR_ID,
        this.serviceId,
      );
      this.serviceData = data;
      this.isLoading = false;
    },
    fetchTags: debounce(async function fetchTags(query) {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, { isActive: true, Name: query });

      this.tags = data;
    }, FETCH_DELAY),
    fetchServices: debounce(async function fetchServices(query) {
      const params = new URLSearchParams();

      params.append('isMerged', true);
      if (query) params.append('Name', query);

      const { data: { data } } = await api.get('v3/laboratory/service/list', {
        params,
      });
      this.serviceList = data;
    }, FETCH_DELAY),
    async onClickUpdateService() {
      if (!this.onCheckValidation()) return;

      const tagsIds = this.serviceData.tags.map((item) => item.id);
      const childIds = this.serviceData.childs.map((item) => item.id);

      const params = {
        name: this.serviceData.name,
        code: this.serviceData.code,
        cost: +this.serviceData.cost,
        id: this.serviceData.id,
        description: this.serviceData.description,
        productionTime: this.serviceData.productionTime,
        preparation: this.serviceData.preparation,
        contraindications: this.serviceData.contraindications,
        decoding: this.serviceData.decoding,
        childs: childIds,
        tags: tagsIds,
        isActive: this.serviceData.isActive ?? false,
      };

      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_UPDATE, params);
      await this.$store.dispatch(
        this.$types.GET_LABORATORY_SERVICES,
        this.params,
      );

      this.onClose();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.serviceData[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.serviceData.name.$touch();
      this.$v.serviceData.code.$touch();
      this.$v.serviceData.description.$touch();
      this.$v.serviceData.productionTime.$touch();
      this.$v.serviceData.preparation.$touch();
      this.$v.serviceData.contraindications.$touch();
      this.$v.serviceData.decoding.$touch();
      this.$v.serviceData.childs.$touch();
      if (this.$v.serviceData.name.$anyError
        || this.$v.serviceData.code.$anyError
        || this.$v.serviceData.description.$anyError
        || this.$v.serviceData.productionTime.$anyError
        || this.$v.serviceData.preparation.$anyError
        || this.$v.serviceData.contraindications.$anyError
        || this.$v.serviceData.decoding.$anyError
        || this.$v.serviceData.childs.$anyError
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-table {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #f3f3f3;
      color: #6e88f3;
    }

    & + & {
      border-top: 1px solid #e0e0e0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #e0e0e0;
    }
  }
}
.error-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
