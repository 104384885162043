<template>
  <div
    class="unloading-wrapper"
    :class="{clinicAdmin: checkRoleAccess(['ClinicAdministrator'])}"
  >
    <custom-scrollbar>
      <div class="crm-container unloading-modal">
        <loading
          v-if="isLoading"
          class="crm-loading"
        >
          Загрузка файла
        </loading>
        <template v-else>
          <div class="crm-info">
            <p class="crm-title">
              Выбор по периоду:
            </p>

            <div class="crm-wrapper-radio">
              <base-radio-button
                v-model="reportDateType"
                :name="1"
                class="mr-3"
              >
                по дате создания
              </base-radio-button>

              <base-radio-button
                v-model="reportDateType"
                :name="2"
                class="mr-3"
              >
                по дате записи
              </base-radio-button>

              <base-radio-button
                v-model="reportDateType"
                :name="3"
              >
                по дате закрытия
              </base-radio-button>
            </div>
          </div>

          <template v-if="checkRoleAccess(['Administrator'])">
            <!-- <div class="crm-info">
              <p class="crm-title">
                Выгрузить информацию
              </p>

              <div class="crm-wrapper-checkbox">
                <base-checkbox
                  v-model="reportPartTypes"
                  class="crm-checkbox"
                  label="1"
                >
                  информация по врачу (фио, специализации);
                </base-checkbox>

                <base-checkbox
                  v-if="checkRoleAccess_(['Operator', 'Tech.operator', 'MedicalCurator', 'Administrator', 'ClinicAdministrator']) && isMoniki)"
                  v-model="reportPartTypes"
                  class="crm-checkbox"
                  label="3"
                >
                  информация по пользователю (полис);
                </base-checkbox>

                <base-checkbox
                  v-if="checkRoleAccess_(['Operator', 'Tech.operator', 'Administrator', 'MedicalCurator'])"
                  v-model="reportPartTypes"
                  class="crm-checkbox"
                  label="2"
                >
                  информация по оплате (стоимость консультации, депозит, промокод);
                </base-checkbox>

                <base-checkbox
                  v-model="reportPartTypes"
                  class="crm-checkbox"
                  label="4"
                >
                  дата и время закрытия;
                </base-checkbox>

                <base-checkbox
                  v-model="reportPartTypes"
                  class="crm-checkbox"
                  label="5"
                >
                  дата и время записи;
                </base-checkbox>
              </div>
            </div> -->

            <div class="crm-info">
              <p class="crm-title">
                Страхование:
              </p>

              <div class="crm-wrapper-radio">
                <base-radio-button
                  v-model="medicalInsuranceType"
                  :name="0"
                  class="mr-3"
                  :disabled="disabledFields.medicalInsuranceType"
                >
                  ДМС
                </base-radio-button>

                <base-radio-button
                  v-model="medicalInsuranceType"
                  :name="1"
                  class="mr-3"
                  :disabled="disabledFields.medicalInsuranceType"
                >
                  ОМС
                </base-radio-button>

                <base-radio-button
                  v-model="medicalInsuranceType"
                  :name="2"
                  :disabled="disabledFields.medicalInsuranceType"
                  class="mr-3"
                >
                  Все
                </base-radio-button>
              </div>
            </div>

            <div
              v-if="medicalInsuranceType === 1"
              class="crm-info crm-info-region-container"
            >
              <base-checkbox-select
                :container-class="'crm-info-region-container'"
                label="Регион и тип прикрепления"
                class="crm-checkbox-select"
                placeholder="Выберите регион"
                fluid
              >
                <template
                  v-slot:default="{ query }"
                >
                  <base-checkbox-list
                    v-model="regions"
                    :query="query"
                    :choices="regionChoices"
                  />
                </template>
              </base-checkbox-select>
              <!--  ОМС -->

              <div
                v-if="regions.length"
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(region, idx) in regions"
                  :key="idx"
                  :speciality="region"
                  edit
                  class="crm-item"
                  @delete="deleteRegion(region)"
                />
              </div>
            </div>

            <div
              v-if="medicalInsuranceType === 0"
              class="crm-info crm-info-insurance-container"
            >
              <base-checkbox-select
                :container-class="'crm-info-insurance-container'"
                label="Страховая подпрограмма"
                class="crm-checkbox-select"
                placeholder="Выберите страховую подпрограмму"
                fluid
              >
                <template
                  v-slot:default="{ query }"
                >
                  <base-checkbox-list
                    v-model="insuranceProgramms"
                    :query="query"
                    :choices="insuranceProgrammsChoices"
                  />
                </template>
              </base-checkbox-select>
              <!--  ОМС -->

              <div
                v-if="insuranceProgramms.length"
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(programm, idx) in insuranceProgramms"
                  :key="idx"
                  :speciality="programm"
                  edit
                  class="crm-item"
                  @delete="deleteInsuranceProgramm(programm)"
                />
              </div>
            </div>

            <div class="crm-info">
              <base-async-select
                :value="defaultValue"
                class="crm-select"
                :fetch-function="fetchNetworks"
                option-title="title"
                label="Сети"
                loading-title="Загрузка сетей"
                no-options-title="Сети не найдены"
                server-paginated
                @change="selectNetwork"
              />

              <div
                v-if="clinicNetworks.length"
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(clinicNetwork, idx) in clinicNetworks"
                  :key="idx"
                  :speciality="clinicNetwork"
                  edit
                  class="crm-item"
                  @delete="deleteClinicNetwork(clinicNetwork)"
                />
              </div>
            </div>

            <div class="crm-info">
              <base-async-select
                :value="defaultValue"
                :fetch-function="fetchClinic"
                label="Название клиники"
                class="crm-select"
                server-paginated
                @change="selectClinic"
              />

              <div
                v-if="clinics.length"
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(clinic, idx) in clinics"
                  :key="idx"
                  :speciality="clinic"
                  edit
                  class="crm-item"
                  @delete="deleteClinic(clinic)"
                />
              </div>
            </div>
          </template>

          <div class="crm-wrapper-buttons">
            <!-- <base-button
              :type="$const.SECONDARY_BUTTON"
              class="button"
              @click="onCancel"
            >
              Отменить
            </base-button> -->

            <base-button
              :type="$const.PRIMARY_BUTTON"
              class="crm-button"
              @click="onUnload"
            >
              Скачать
            </base-button>
          </div>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import {
  BaseRadioButton,
  // BaseCheckbox,
  BaseButton,
  BaseCheckboxSelect,
  BaseCheckboxList,
  BaseAsyncSelect,
} from '@/components/base';

import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';

import Loading from '@/components/Loading';

import { mixinRoles } from '@/mixins';

export default {
  name: 'UnloadingModal',
  components: {
    BaseRadioButton,
    // BaseCheckbox,
    BaseButton,
    BaseAsyncSelect,
    Loading,
    BaseCheckboxSelect,
    BaseCheckboxList,
    DoctorSpecialityItem,
  },
  mixins: [mixinRoles],
  props: {
    dateFrom: {
      type: String,
      default: null,
    },
    dateTo: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      reportDateType: 2,
      reportPartTypes: [
        '1',
        '2',
        '3',
        '4',
        '5',
      ],
      medicalInsuranceType: null,
      regions: [],
      insuranceProgramms: [],
      clinicNetworks: [],
      clinics: [],

      defaultValue: null,

      regionChoices: {
        title: 'Выбрать все',
        choices: [],
      },
      insuranceProgrammsChoices: {
        title: 'Выбрать все',
        choices: [],
      },
    };
  },
  computed: {
    isMoniki() {
      return this.$store.state.Auth.login === 'moniki';
    },
    disabledFields() {
      return {
        medicalInsuranceType: this.checkRoleAccess(['Operator.gostelemed']),
      };
    },
  },
  async created() {
    if (this.checkRoleAccess(['ClinicAdministrator'])) return;
    if (this.checkRoleAccess(['Operator.gostelemed'])) this.medicalInsuranceType = 1;

    this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS).then((regions) => {
      this.regionChoices.choices = regions.map((region) => ({ ...region, title: region.name }));
    });

    this.$store.dispatch(this.$types.INSURANCE_SUBPROGRAMS_FETCH).then((programms) => {
      this.insuranceProgrammsChoices.choices = programms.map((programm) => ({ ...programm, title: programm.name }));
    });
  },
  methods: {
    onCancel() {
      this.$emit('vuedals:close');
    },
    async onUnload() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.STATISTICS_UNLOAD_FETCH, {
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
          reportPartTypes: this.reportPartTypes,
          reportDateType: this.reportDateType,
          medicalInsuranceType: this.medicalInsuranceType,
          regions: this.medicalInsuranceType === 1 || this.medicalInsuranceType === 2 ? this.regions.map((region) => region.id) : null,
          insuranceProgramms: this.medicalInsuranceType === 0 || this.medicalInsuranceType === 2
            ? this.insuranceProgramms.map((programm) => programm.id) : null,
          clinicNetworks: this.clinicNetworks.map((network) => network.id),
          clinics: this.clinics.map((clinic) => clinic.id),
        });
        this.$emit('vuedals:close');
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    deleteRegion(region) {
      this.regions = this.regions.filter((item) => item.id !== region.id);
    },
    deleteInsuranceProgramm(programm) {
      this.insuranceProgramms = this.insuranceProgramms.filter((item) => item.id !== programm.id);
    },
    deleteClinicNetwork(clinicNetwork) {
      this.clinicNetworks = this.clinicNetworks.filter((item) => item.id !== clinicNetwork.id);
    },
    deleteClinic(clinic) {
      this.clinics = this.clinics.filter((item) => item.id !== clinic.id);
    },
    fetchNetworks(params) {
      return this.$store.dispatch(this.$types.NETWORK_V2_FETCH, params);
    },
    selectNetwork(value) {
      this.clinicNetworks = [...this.clinicNetworks, { ...value }];
    },
    async fetchClinic({ query, skip, take }) {
      const clinics = await this.$store.dispatch(this.$types.CLINICS_FETCH, {
        skip,
        take,
        filter: {
          query,
          network: this.clinicNetworks[0],
          city: null,
        },
      });
      return clinics.map((clinic) => ({ value: clinic.id, name: clinic.title }));
    },
    selectClinic(event) {
      this.clinics = [...this.clinics, {
        id: event.value,
        title: event.name,
      }];
    },
  },
};
</script>

<style lang="scss" scoped>
.unloading-wrapper {
  height: calc(100vh - 200px);

  &.clinicAdmin {
    height: auto;
  }
}
.unloading-modal {
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}
.crm-title {
  margin: 0 0 20px 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.crm-info {
  width: 100%;
  margin-bottom: 30px;
}
::v-deep.crm-wrapper-radio {
  display: flex;

  .custom-radio:not(:last-child) {
    margin-right: 35px;
  }
}
.crm-wrapper-checkbox {
  display: flex;
  flex-direction: column;
}
.crm-checkbox {
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}
.crm-item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
</style>
