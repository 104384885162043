import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  // Установка списка опросов
  [localTypes.SET_NPS_SURVEYS](state, surveys) {
    state.surveysList = surveys;
  },
  // Установка состояния загрузки
  [localTypes.SET_NPS_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  // Обновление полей опроса
  [localTypes.SET_NPS_SURVEY_STATUS](state, { id, ...changes }) {
    const survey = state.surveysList.find((item) => item.id === id);
    if (survey) {
      Object.assign(survey, changes);
    }
  },
  // Изменение количества опций
  [localTypes.SET_NPS_OPTIONS_COUNT](state, count) {
    state.optionsCount = count;
  },
};
