import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.KNOWLEDGE_DOCUMENTS_GET](state) {
    return state.knowledgeDocuments;
  },
};
