<template>
  <div class="w-100 pt-4">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container fluid="xl">
        <div class="mb-3">
          <div class="h3 mb-0">
            Обращения
          </div>
        </div>

        <appeals-search-panel
          v-model="filter"
          class="mb-4"
          @search="appealsFetch(true)"
        />

        <div
          v-if="!totalCount && isAllItemsLoaded"
          class="bg-white rounded border p-3"
        >
          Ничего не найдено
        </div>

        <div v-if="totalCount">
          <div class="d-flex">
            <div class="h6">
              Результаты поиска
            </div>

            <!-- <span class="result-count">
              Найдено: {{ totalCount }}
            </span> -->
          </div>
        </div>

        <div
          v-for="(appeal, index) in appeals"
          :key="index"
          class="mt-3"
        >
          <appeal-info :appeal="appeal" />
        </div>

        <div v-if="isLoading">
          <b-col
            v-for="item in 20"
            :key="item"
            cols="12"
            class="mt-3 p-0 rounded"
          >
            <b-skeleton-img
              no-aspect
              height="183px"
            />
          </b-col>
        </div>
      </b-container>
    </async-list>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import {
  formatISO,
} from 'date-fns';
import { mixinRoles } from '@/mixins';
import { dateWithoutTime } from '@/helpers/utils';
import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';
import AppealInfo from '@/components/Appeals/AppealInfo';
import AppealsSearchPanel from '@/components/Appeals/AppealsSearchPanel';

const LIMIT = 15;
const FETCH_DELAY = 700;
export default {
  name: 'Appeals',
  page: {
    title: 'CRM Doctis - Обращения',
  },
  components: {
    AsyncList,
    AppealInfo,
    AppealsSearchPanel,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isSearched: false,
      isAllItemsLoaded: false,
      isLoading: false,
      limit: LIMIT,
      filter: {
        patientFio: '',
        phoneNumber: null,
        creationDateFrom: null,
        creationDateTo: null,
        type: null,
        purpose: null,
        // type: {
        //   id: null,
        //   title: '',
        //   type: null,
        // },
        status: null,
        responsibleId: null,
        clinicId: null,
        policyNumber: null,
        insuranceCompanyId: null,
        insuranceProgramId: null,
        insuranceSubprogramId: null,
      },
    };
  },
  computed: {
    appeals() {
      return this.$store.state.Appeal.appeals;
    },
    totalCount() {
      return this.appeals.length;
    },
  },
  watch: {
    filter: {
      handler(filter) {
        localStorage.setItem('appeals-filter', JSON.stringify(filter));
      },
      deep: true,
    },
  },
  created() {
    this.appealsFetchDebounced = debounce(this.appealsFetch, FETCH_DELAY);
    Bus.$on('appeals:reset-filter', this.resetFilter);
    Bus.$on('appeals:fetch', this.appealsFetch);

    if (localStorage.getItem('appeals-filter')) {
      try {
        const filter = JSON.parse(localStorage.getItem('appeals-filter'));
        const { creationDateTo, creationDateFrom } = filter;
        this.filter = {
          ...filter,
          creationDateTo: creationDateTo ? new Date(filter.creationDateTo) : null,
          creationDateFrom: creationDateFrom ? new Date(filter.creationDateFrom) : null,
        };
      } catch (e) {
        localStorage.removeItem('appeals-filter');
      }
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.APPEALS_SET, []);
    Bus.$off('appeals:reset-filter', this.resetFilter);
    Bus.$off('appeals:fetch', this.appealsFetch);
  },
  methods: {
    openAppealEditor() {
      Bus.$emit('open-modal:appeal-editor', {});
    },
    openEditModal() {
      // Bus.$emit('open-modal:patient-edit', null);
    },

    async appealsFetch(clear) {
      this.isSearched = true;
      if (this.isLoading && !clear) return;

      this.isAllItemsLoaded = false;
      this.isLoading = true;
      const prevTotal = clear ? 0 : this.totalCount;
      const prevQuery = this.filter.patientFio;

      const { creationDateFrom, creationDateTo } = this.filter;
      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        clear: !!clear,
        filter: {
          ...this.filter,
          creationDateFrom: creationDateFrom ? dateWithoutTime(formatISO(creationDateFrom)) : null,
          creationDateTo: creationDateTo ? formatISO(new Date(creationDateTo).setHours(23, 59, 0, 0)) : null,
        },
      };
      try {
        await this.$store.dispatch(this.$types.APPEALS_FETCH, requestObject);
        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.filter.patientFio !== prevQuery) this.appealsFetch(true);
      }
    },
    loadMoreItems() {
      if (this.isLoading) return;

      this.appealsFetch(false);
    },
    openExportappeal() {
      // Bus.$emit('open-modal:patient-add');
    },
    resetFilter() {
      localStorage.removeItem('appeals-filter');
      this.filter = {
        patientFio: '',
        phoneNumber: null,
        creationDateFrom: null,
        creationDateTo: null,
        type: null,
        status: null,
        responsibleId: null,
        clinicId: null,
        insuranceCompanyId: null,
        insuranceProgramId: null,
        insuranceSubprogramId: null,
      };

      this.$store.commit(this.$types.APPEALS_SET, []);
      this.isSearched = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.appeals {
  display: flex;
  flex-direction: column;
  width: 100%;
}

::v-deep.icon-user {
  margin-right: 15px;
  path {
    fill: $blue;
  }
}

::v-deep.crm-info-wrapper {
  padding-top: 30px;
  padding-bottom: 30px;
}

.appeal-list {
  height: 100vh;
  transition: height 0.3s linear;
  box-sizing: border-box;
}

.result-row {
  flex: 1;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.appeals-search-panel {
  margin-top: 20px;
}
</style>
