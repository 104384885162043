<template>
  <div class="rule-page w-100 h-100 overflow-auto">
    <b-container
      v-if="!isLoader"
      fluid="xl"
    >
      <div class="d-flex align-items-center justify-content-between mt-4 mb-3">
        <div class="d-flex align-items-center">
          <div
            class="back mr-3"
            @click="back"
          >
            <IconChevronLeft />
          </div>
          <div class="h3 mb-0">
            {{ title }}
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="rule-tabs-status mr-3">
            <div
              v-for="({ title, value }) of statusTabs"
              :key="value"
              class="tab"
              :class="{ active: rule.isActive === value }"
              @click="rule.isActive = value"
            >
              {{ title }}
            </div>
          </div>
          <b-button
            variant="primary"
            class="ml-2"
            size="sm"
            @click="save(true)"
          >
            Сохранить
          </b-button>
        </div>
      </div>

      <div class="rule-section d-flex justify-content-between align-items-center mb-3">
        <div class="rule-form mr-3">
          <div class="mr-3 w-100">
            <div class="mb-3">
              <base-input
                v-model="rule.name"
                class="rule-form-input"
                label="Название правила"
                placeholder="Введите название"
              />
            </div>
            <div>
              <div class="mb-2">
                Медицинская система
              </div>
              <div>
                <v-select
                  v-model="rule.medicalSystemRouteId"
                  :options="medicalSystemRoutes"
                  :clearable="false"
                  placeholder="Выберите медицинскую систему"
                  label="title"
                />
              </div>
            </div>
          </div>
          <div class="w-100">
            <div class="mb-2">
              Описание правила
            </div>
            <b-form-textarea
              v-model="rule.description"
              placeholder="Описание правила"
              rows="5"
              size="sm"
            />
          </div>
        </div>
      </div>

      <div class="rule-modules pb-5">
        <RuleModule
          v-for="(module, index) of rules"
          :key="index"
          :rule="module"
          class="rule-section"
          @save="save"
        />
      </div>
    </b-container>
    <Preloader v-else />
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { BaseInput } from '@/components/base';
import { parseISO, format } from 'date-fns';
import IconChevronLeft from 'assets/images/arrow-button-left.svg';
import Preloader from '@/components/Preloader';
import RuleModule from '@/components/Rules/RuleModule';

export default {
  name: 'RulePage',
  components: {
    IconChevronLeft,
    Preloader,
    RuleModule,
    BaseInput,
  },
  data() {
    return {
      isLoader: true,
      medicalSystemRoutes: [
        { id: 'a1d2aee3-a477-49b7-9409-1d5cd01c143e', title: 'Для теста' },
      ],
      statusTabs: [
        { title: 'Активно', value: true },
        { title: 'Неактивно', value: false },
      ],
      rule: {
        name: '',
        description: '',
        medicalSystemRouteId: '',
        isActive: true,
        start: null,
        end: null,
      },
      rules: {
        clinic: {
          id: 'clinic',
          field: 'clinicIds',
          title: 'Список медицинских организаций',
          placeholder: 'Не выбрано ни одной медицинской организаций',
          placeholderSearch: 'Поиск по медицинским организациям',
          list: [],
          selected: [],
          functionSearch: async (rule, params) => {
            const { data: clinics } = await api.post('v2/clinic/search', {
              searchString: params.text,
              skip: 0,
              take: 150,
            });

            rule.list = clinics;
          },
          functionReset: () => {
            this.rules.clinic.list = [];
          },
        },
        specializations: {
          id: 'specializations',
          field: 'specializationIds',
          title: 'Список специальностей',
          placeholder: 'Не выбрано ни одной специальности',
          placeholderSearch: 'Поиск по специальностям',
          list: [],
          selected: [],
          fullList: [],
          functionSearch: (rule, params) => {
            rule.list = rule.fullList.filter((i) => i.name.toLowerCase().includes(params.text.toLowerCase()));
          },
          functionReset: () => {
            this.rules.specializations.list = this.rules.specializations.fullList;
          },
        },
        patients: {
          id: 'patients',
          field: 'patientIds',
          title: 'Список пациентов',
          placeholder: 'Не выбрано ни одного пациента',
          placeholderSearch: 'Поиск по пациентам',
          list: [],
          selected: [],
          functionSearch: async (rule, params) => {
            const { data: { result: patients } } = await api.post('v2/person/find', {
              searchString: params.text,
              skip: 0,
              take: 150,
            });

            rule.list = patients.map((patient) => ({
              ...patient.person,
              birthDate: patient.person?.birthDate ? format(parseISO(patient.person.birthDate), 'dd.MM.yyyy') : '',
            }));
          },
          functionReset: () => {
            this.rules.patients.list = [];
          },
        },
        diases: {
          id: 'diases',
          field: 'diagnoses',
          title: 'Список диагнозов',
          placeholder: 'Диагнозы для правил не заданы',
          placeholderSearch: 'Поиск по диагнозам',
          list: [],
          fullList: [],
          selected: [],
          functionSearch: async (rule, params) => {
            rule.list = rule.fullList.filter((i) => i.name.toLowerCase().includes(params.text.toLowerCase()));
          },
          functionReset: () => {
            this.rules.diases.list = this.rules.diases.fullList;
          },
        },
        doctors: {
          id: 'doctors',
          field: 'doctorIds',
          title: 'Список врачей',
          placeholder: 'Не выбрано ни одного врача',
          placeholderSearch: 'Поиск по врачам',
          list: [],
          selected: [],
          functionSearch: async (rule, params) => {
            const { data: doctors } = await api.get('v2/doctors/find', {
              params: {
                search: params.text,
                take: 150,
              },
            });

            console.log(doctors);
            rule.list = doctors.map((item) => ({
              ...item,
              specialization: item.doctorSpecializations.map((i) => i.specializationTitle).join(', '),
            }));

            console.log('rule.list', rule.list);
          },
          functionReset: () => {
            this.rules.doctors.list = [];
          },
        },
      },
    };
  },
  computed: {
    isNew() {
      return this.$route.params.id === 'new';
    },
    title() {
      return this.isNew ? 'Новое правило' : this.rule.name || 'Без названия';
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.isLoader = true;

      await this.getRule();
      /** LIST SPECIALIZATIONS */
      await this.getSpecializations();
      await this.getDiagnoses();

      this.isLoader = false;
    },
    back() {
      this.$router.push('/rules');
    },
    async save(isReload) {
      const { medicalSystemRouteId } = this.rule;
      const rules = this.formirationRules();

      const params = {
        ...this.rule,
        medicalSystemRouteId: medicalSystemRouteId?.id || medicalSystemRouteId,
        ...rules,
      };

      try {
        if (this.isNew) {
          const { data } = await api.post('v3/medical-system-consultation-rule', params);
          this.$router.push('/rules');
          console.log('create', data);
        } else {
          const { data } = await api.put('v3/medical-system-consultation-rule', params);
          console.log('update', data);
        }

        if (isReload) this.$router.push('/rules');
      } catch (error) {
        console.log('Ошибка при создании правила', error);
      }
    },
    formirationRules() {
      return Object.keys(this.rules).reduce((res, key) => {
        const { selected, field } = this.rules[key];

        if (selected.length) res[field] = selected.map((item) => item.id || item.code);
        else res[field] = null;

        return res;
      }, {});
    },
    async getRule() {
      try {
        if (!this.isNew) {
          const { data } = await api.get(`v3/medical-system-consultation-rule/${this.$route.params.id}`);
          if (data?.data) {
            const {
              id,
              medicalSystemRouteId,
              name,
              description,
              start,
              end,
              isActive,
              clinics,
              specializations,
              patients,
              doctors,
              diagnoses,
            } = data?.data;

            this.rule = {
              consultationRuleId: id,
              medicalSystemRouteId,
              name,
              description,
              start,
              end,
              isActive,
            };

            console.log('patients', patients);

            // Set Rules Modules
            this.rules.clinic.selected = clinics?.map((item) => ({ ...item, addressString: item.address })) || [];
            this.rules.specializations.selected = specializations || [];
            this.rules.patients.selected = patients?.map((patient) => ({
              ...patient,
              birthDate: patient.birthDate ? format(parseISO(patient.birthDate), 'dd.MM.yyyy') : '',
            })) || [];
            this.rules.doctors.selected = doctors || [];
            this.rules.diases.selected = diagnoses || [];
          }
        }
      } catch (error) {
        console.log('Ошибка при создании правила', error);
      }
    },

    // Get data modules
    async getSpecializations() {
      const { data } = await api.get('/v3/medical-system-specialization', {
        params: { MedicalSystemRouteId: 'a1d2aee3-a477-49b7-9409-1d5cd01c143e' },
      });

      if (data?.data) {
        this.rules.specializations.list = data?.data;
        this.rules.specializations.fullList = data?.data;
      }
    },
    async getDiagnoses(params = null) {
      try {
        const { data } = await api.get('v2/monitoring/mkb', { params });
        if (data) {
          this.rules.diases.list = data;
          this.rules.diases.fullList = data;
        }
      } catch (e) {
        console.warn(e);
      }
    },
  },
};
</script>

<style lang="scss">
.rule-page {
  width: 100%;
  position: relative;

  .rule-section {
    display: flex;
    border-radius: 1rem;
    background: #fff;
    padding: 1rem;

    .rule-form {
      display: flex;
      width: 100%;

      .rule-form-input {
        .crm-label {
          margin-bottom: 0.5rem !important;
          font-weight: 400;
          font-size: 14px;
        }

        input {
          height: 40px;
          padding: 0 7px;
        }
      }
    }
  }

  .rule-modules {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  .back {
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
  }

  .rule-tabs-status {
    display: flex;
    padding: 2px;
    border-radius: 999px;
    background: #fff;
    height: 36px;
    cursor: pointer;

    .tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 120px;
      height: 100%;
      padding: 0 25px;
      border-radius: 999px;

      &.active {
        background: #007BFF;
        color: #ffffff;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}
</style>
