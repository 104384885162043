<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <template #modal-header>
      <div class="d-flex justify-content-between align-items-start w-100">
        <div class="h5 font-weight-bold">
          {{ title }}
          <span class="small h6m ml-2">
            {{ formattedDate }}
          </span>
        </div>

        <b-button
          size="sm"
          title="Закрыть"
          variant="outline-danger"
          class="p-0"
          @click="onClose"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
    </template>

    <div>
      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Учетная запись
        </div>

        <b-form-radio-group
          v-model="accountType"
          :options="ACCOUNT_TYPES"
          value-field="id"
          text-field="title"
          class="mb-2"
        />

        <template v-if="accountType === 0">
          <div class="d-flex align-items-center">
            <b-form-input
              v-if="search.field === 'phone'"
              v-model="search.filter.PhoneNumber"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите номер телефона"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-form-input
              v-if="search.field === 'fio'"
              v-model="search.filter.SearchFIO"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите ФИО"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-form-input
              v-if="search.field === 'policy'"
              v-model="search.filter.PolicyNumber"
              type="search"
              size="sm"
              :disabled="search.isLoading"
              placeholder="Введите номер полиса"
              class="border"
              @keyup.native.enter="onClickSearch"
            />
            <b-button
              id="popover-filter"
              v-b-tooltip.hover
              variant="light"
              size="sm"
              :disabled="search.isLoading"
              class="border border-primary p-1 ml-2"
              title="Дополнительные фильтры"
            >
              <b-icon icon="filter" />
            </b-button>
            <b-button
              v-b-tooltip.hover
              variant="light"
              size="sm"
              class="border border-success p-1 ml-2"
              title="Поиск"
              :disabled="search.isLoading"
              @click="onClickSearch"
            >
              <b-spinner
                v-if="search.isLoading"
                variant="primary"
                small
              />
              <b-icon
                v-else
                icon="search"
              />
            </b-button>
          </div>

          <div
            v-if="search.error"
            class="text-danger mb-2 font-weight-bold small"
          >
            {{ search.error }}
          </div>

          <div
            v-if="$v.checkup.patientId.$error"
            class="text-danger mt-2 small"
          >
            {{ errorsValidation.patientId[0] }}
          </div>

          <div
            v-if="search.patients && search.patients.length"
            class="mt-3"
          >
            <div class="patients d-flex">
              <PatientInfo
                v-for="patient in search.patients"
                :key="patient.id"
                :patient="patient"
                :active="patient.id === checkup.patientId"
                class="mr-2"
                @click.native="setPatient(patient)"
              />
            </div>
          </div>
        </template>

        <div
          v-if="accountType === 1"
          class="border p-2 my-2"
        >
          <div class="mb-3 d-flex">
            <div class="w-50">
              <div class="font-weight-bold mb-2">
                Фамилия
              </div>

              <b-form-input
                v-model="$v.checkup.lastName.$model"
                :state="checkValidations ? !$v.checkup.lastName.$error : null"
                size="sm"
              />

              <div
                v-if="$v.checkup.lastName.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.lastName[0] }}
              </div>
            </div>
            <div class="w-50 ml-2">
              <div class="font-weight-bold mb-2">
                Имя
              </div>

              <b-form-input
                v-model="$v.checkup.firstName.$model"
                :state="checkValidations ? !$v.checkup.firstName.$error : null"
                size="sm"
              />

              <div
                v-if="$v.checkup.firstName.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.firstName[0] }}
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              Отчество
            </div>

            <b-form-input
              v-model="checkup.middleName"
              size="sm"
            />
          </div>
          <div class="mb-3 d-flex justify-content-between">
            <div>
              <div class="font-weight-bold mb-2">
                Дата рождения
              </div>

              <base-date-picker
                v-model="$v.checkup.birthDate.$model"
                :error="$v.checkup.birthDate.$error"
                :clearable="false"
              />

              <div
                v-if="$v.checkup.birthDate.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.birthDate[0] }}
              </div>
            </div>
            <div>
              <div class="font-weight-bold mb-2">
                Пол
              </div>

              <b-form-radio-group
                v-model="$v.checkup.sex.$model"
                :options="GENDERS"
                :state="checkValidations ? !$v.checkup.sex.$error : null"
                value-field="value"
                text-field="title"
                class="mb-2"
              />

              <div
                v-if="$v.checkup.sex.$error"
                class="text-danger mt-2 small"
              >
                {{ errorsValidation.sex[0] }}
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="font-weight-bold mb-2">
              Телефон
            </div>

            <b-form-input
              v-model="$v.checkup.phoneNumber.$model"
              v-mask="'+7 (###) ### ##-##'"
              :state="checkValidations ? !$v.checkup.phoneNumber.$error : null"
              size="sm"
              @input="changePhoneNumber"
            />

            <div
              v-if="$v.checkup.phoneNumber.$error"
              class="text-danger mt-2 small"
            >
              {{ errorsValidation.phoneNumber[0] }}
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Способ связи
        </div>

        <b-form-radio-group
          v-model="$v.checkup.communicationMethod.$model"
          :state="checkValidations ? !$v.checkup.communicationMethod.$error : null"
          :options="COMMUNICATION_METHODS"
          value-field="id"
          text-field="title"
        />
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Тип
        </div>

        <b-form-radio-group
          v-model="checkup.availabilityOfPayment"
          :options="PAYMENT_VARIANTS"
          value-field="value"
          text-field="title"
        />
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Файлы
        </div>

        <div class="mb-2">
          <div
            v-for="document in documents"
            :key="document.guid"
            class="d-flex mb-1"
          >
            <div class="overflow-hidden">
              {{ document.title }}
            </div>
            <b-button
              variant="light"
              size="sm"
              class="border ml-2 p-1"
              @click="onClickDeleteFile(document)"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </div>

        <b-button
          variant="primary"
          size="sm"
          @click="onClickOpenFileModal"
        >
          Добавить файлы
        </b-button>
      </div>

      <div class="mb-3">
        <div class="font-weight-bold mb-2">
          Текст обращения
          <div class="small">
            (опционально)
          </div>
        </div>

        <b-form-textarea
          v-model="checkup.body"
          rows="3"
          max-rows="6"
        />
      </div>
    </div>

    <b-popover
      :show.sync="isPopoverOpen"
      target="popover-filter"
      triggers="click"
    >
      <div class="d-flex justify-content-end mb-2">
        <b-button
          variant="light"
          size="sm"
          class="border border-danger p-0 ml-2"
          @click="isPopoverOpen = false"
        >
          <b-icon icon="x" />
        </b-button>
      </div>
      <div class="popover-inner p-2">
        <div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Поиск по
            </div>

            <b-form-radio-group
              v-model="search.field"
              :options="SEARCH_FIELDS"
              value-field="alias"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
          <div class="mb-3">
            <div class="mb-2 font-weight-bold">
              Тип полиса
            </div>

            <b-form-radio-group
              v-model="search.filter.PatientType"
              :options="POLICY_TYPES"
              value-field="id"
              text-field="title"
              class="mb-2"
              @change="changeSearchField"
            />
          </div>
        </div>
      </div>
    </b-popover>

    <template #modal-footer>
      <b-button
        variant="danger"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { format, formatISO } from 'date-fns';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { phoneMixins } from '@/mixins/phoneMixins';

import { showMessage, dateWithoutTime } from '@/helpers/utils';
import { ACCOUNT_TYPES } from '@/helpers/consts';

import { BaseDatePicker } from '@/components/base';

import PatientInfo from '@/components/Schedule/Modals/ScheduleMakeAnAppointmentModal/PatientInfo';

const SEARCH_FIELDS = [
  {
    alias: 'phone',
    title: 'Номеру телефона',
  },
  {
    alias: 'fio',
    title: 'ФИО',
  },
  {
    alias: 'policy',
    title: 'По номеру полиса',
  },
];

const POLICY_TYPES = [
  {
    id: 2,
    title: 'Все',
  },
  {
    id: 0,
    title: 'ДМС',
  },
  {
    id: 1,
    title: 'ОМС',
  },
];

const COMMUNICATION_METHODS = [
  {
    id: 0,
    title: 'Текст',
  },
  {
    id: 1,
    title: 'Аудио',
  },
  {
    id: 2,
    title: 'Видео',
  },
];

const PAYMENT_VARIANTS = [
  {
    value: true,
    title: 'С оплатой',
  },
  {
    value: false,
    title: 'Без оплаты',
  },
];

const GENDERS = [
  {
    value: true,
    title: 'Мужской',
  },
  {
    value: false,
    title: 'Женский',
  },
];

export default {
  name: 'ScheduleMakeAnAppointmentModal',
  components: {
    PatientInfo,
    BaseDatePicker,
  },
  mixins: [validationMixin, phoneMixins],

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Записать на прием',
    },
    time: {
      type: Number,
      default: 0,
    },
    doctorId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isOpen: true,
      isPopoverOpen: false,
      checkValidations: false,
      ACCOUNT_TYPES,
      SEARCH_FIELDS,
      POLICY_TYPES,
      COMMUNICATION_METHODS,
      PAYMENT_VARIANTS,
      GENDERS,
      isLoading: false,
      birthDateType: 'date',
      phoneMask: ['+', /\d/, ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, '-', /\d/, /\d/],
      maxDate: new Date().toISOString().split('T')[0],
      isSaving: false,
      accountType: 0,
      documents: [],
      checkup: {
        middleName: '',
        firstName: '',
        lastName: '',
        birthDate: null,
        sex: null,
        phoneNumber: '+7 (',
        body: '',
        patientId: null,
        parentId: null,
        communicationMethod: null,
        availabilityOfPayment: null,
      },
      search: {
        isLoading: false,
        isFilterOpen: false,
        filter: {
          PhoneNumber: null,
          SearchFIO: null,
          PolicyNumber: null,
          PatientType: 2,
        },
        field: 'phone',
        patients: [],
        error: null,
      },
    };
  },
  validations() {
    return this.accountType === 0
      ? {
        checkup: {
          patientId: {
            required,
          },
          communicationMethod: {
            required,
          },
        },
      } : {
        checkup: {
          lastName: {
            required,
          },
          firstName: {
            required,
          },
          birthDate: {
            required,
          },
          sex: {
            required,
          },
          phoneNumber: {
            required,
            isValid: (value) => /^\+\d \(\d{3}\) \d{3} \d{2}-\d{2}$/i.test(value),
          },
          communicationMethod: {
            required,
          },
        },
      };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.communicationMethod = [];
      if (this.accountType === 0) {
        errors.patientId = [];

        if (!this.$v.checkup.patientId.required) {
          errors.patientId.push('Выберите существующего пользователя или создайте нового');
        }

        if (!this.$v.checkup.communicationMethod.required) {
          errors.communicationMethod.push('Способ связи обязателен к заполнению');
        }

        return errors;
      }

      if (!this.$v.checkup.communicationMethod.required) {
        errors.communicationMethod.push('Способ связи обязателен к заполнению');
      }

      errors.lastName = [];
      if (!this.$v.checkup.lastName.required) {
        errors.lastName.push('Поле не может быть пустым');
      }

      errors.firstName = [];
      if (!this.$v.checkup.firstName.required) {
        errors.firstName.push('Поле не может быть пустым');
      }

      errors.birthDate = [];
      if (!this.$v.checkup.birthDate.required) {
        errors.birthDate.push('Поле не может быть пустым');
      }

      errors.sex = [];
      if (!this.$v.checkup.sex.required) {
        errors.sex.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.checkup.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }
      if (!this.$v.checkup.phoneNumber.isValid) {
        errors.phoneNumber.push('Некорректное значение поля');
      }

      return errors;
    },
    formattedDate() {
      return format(new Date(this.time * 1000), 'dd.MM.yyyy HH:mm');
    },
  },
  mounted() {
    this.$v.$reset();
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async changePhoneNumber(newValue) {
      const newPhoneNumber = await this.formatingPhoneNumber(newValue);
      if (newPhoneNumber) {
        this.$v.checkup.phoneNumber.$model = newPhoneNumber;
      }
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      try {
        this.isSaving = true;

        const checkupObj = this.accountType === 0
          ? {
            patientId: this.checkup.parentId ? this.checkup.parentId : this.checkup.patientId,
          } : {
            patient: {
              phoneNumber: this.checkup.phoneNumber,
              lastName: this.checkup.lastName,
              firstName: this.checkup.firstName,
              middleName: this.checkup.middleName,
              birthDate: dateWithoutTime(formatISO(this.checkup.birthDate)),
              sex: this.checkup.sex,
            },
          };

        const params = {
          consultation: {
            doctorId: this.doctorId,
            unixTime: this.time,
            body: this.checkup.body,
            communicationMethod: this.checkup.communicationMethod,
            availabilityOfPayment: this.checkup.availabilityOfPayment,
            fileNames: this.documents.map((doc) => doc.title),
          },
          ...checkupObj,
        };

        if (this.checkup.parentId) params.consultation.targetPatientId = this.checkup.patientId;

        const consultationId = await this.$store.dispatch(this.$types.SCHEDULE_CREATE_CHECKUP, params);

        const fileToken = await this.$store.dispatch(this.$types.SCHEDULE_FILETOKEN_FETCH, consultationId);

        const documentsId = this.documents.map((doc) => doc.guid);

        await this.$store.dispatch(this.$types.SCHEDULE_CHECKUP_ADD_DOCUMENTS, {
          consultationId,
          documentsId,
          fileToken,
        });
        this.$store.commit(this.$types.TOGGLE_SCHEDULE_TRIGGER); // сделает тогл триггера и обновит расписание
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    changeSearchField() {
      this.search.filter.PhoneNumber = null;
      this.search.filter.SearchFIO = null;
      this.search.filter.PolicyNumber = null;
    },
    async onClickSearch() {
      this.checkup.patientId = null;
      this.search.isLoading = true;

      try {
        this.search.error = null;
        this.search.patients = [];

        const patients = await this.$store.dispatch(this.$types.SCHEDULE_PATIENTS_SEARCH, this.search.filter);
        this.search.patients = patients;
      } catch (error) {
        console.log(error);
      } finally {
        this.search.isLoading = false;

        if (this.search.patients.length === 0) {
          if (this.search.field === 'phone') {
            this.search.error = 'Пользователь с таким номером телефона не найден.';
          }

          if (this.search.field === 'fio') {
            this.search.error = 'Пользователь с таким ФИО не найден.';
          }

          if (this.search.field === 'policy') {
            this.search.error = 'Пользователь с таким номером полиса не найден.';
          }
        }
      }
    },
    setPatient(patient) {
      console.log(patient);
      if (this.checkup.patientId === patient.id) {
        this.checkup.patientId = null;
        this.checkup.parentId = null;
        return;
      }
      this.checkup.parentId = patient.parentId;
      this.checkup.patientId = patient.id;
    },
    onClickDeleteFile(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    saveFileCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        console.log(fileObject);
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData,
        });
        return null;
      });
    },
    onClickOpenFileModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка документов',
          saveFileCallback: this.saveFileCallback,
          fileApi: 'File/fileServer',
        },
      });
      // Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFileCallback, fileApi: 'File/fileServer' });
    },
  },
};
</script>

<style lang="scss" scoped>
.patients {
  overflow: auto;
  max-width: 100%;
  padding-bottom: 20px;
}
</style>
