import { render, staticRenderFns } from "./ServiceItem.vue?vue&type=template&id=a757908e&scoped=true"
import script from "./ServiceItem.vue?vue&type=script&lang=js"
export * from "./ServiceItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a757908e",
  null
  
)

export default component.exports