<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="taskId ? 'Задача ' + taskId: 'Новая задача'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Дата и время создания"
          >
            <b-form-input
              :value="getFormatDate(taskData.createDate, 'yyyy-MM-dd')"
              type="date"
              disabled
            />
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Дедлайн реализации"
          >
            <base-date-picker
              v-model="taskData.deadline"
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Статус задачи"
          >
            <b-form-select
              v-model="taskData.status"
              :options="statusList"
              :disabled="!isEdit"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите статус
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Тип проблемы"
          >
            <b-form-select
              v-model="taskData.type"
              :options="typeList"
              :disabled="!isEdit"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите проблему
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Исполнитель"
          >
            <!-- valid-feedback="Thank you!"
            :state="false" -->
            <!-- description="Let us know your name." -->
            <!-- :invalid-feedback="invalidFeedback" -->
            <b-form-select
              v-model="taskData.performer"
              :options="performerList"
              :disabled="!isEdit"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите исполнителя
              </b-form-select-option>
            </b-form-select>
            <!-- v-model="selected" -->
            <!-- :options="options"  -->
            <!-- :state="state" -->
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Текст задачи"
          >
            <b-form-textarea
              v-model="taskData.body"
              placeholder="Введите текст"
              rows="3"
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Ссылка на задачу в Kaiten"
          >
            <b-form-input
              v-model="taskData.kaitenLink"
              type="text"
              placeholder="Вставьте ссылку"
              trim
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Операционная система"
          >
            <b-form-select
              v-model="taskData.operatingSystem"
              :options="operatingSystemList"
              :disabled="!isEdit"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите систему
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>

        <b-col>
          <b-form-group
            label="Версия ПО"
          >
            <b-form-input
              v-model="taskData.softwareVersion"
              type="text"
              placeholder="Введите номер"
              trim
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="false">
        <b-col>
          <b-form-group
            label="Комментарий при закрытии"
          >
            <b-form-textarea
              placeholder="Введите текст"
              rows="3"
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Ответственный"
          >
            <b-form-select
              v-model="taskData.responsibleId"
              :options="responsibleList"
              :disabled="!isEdit"
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите ответственного
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="taskData.author">
        <b-col>
          <b-form-group
            label="Постановщик"
          >
            <b-form-select
              :value="taskData.author.id"
              :options="responsibleList"
              disabled
            >
              <b-form-select-option
                :value="null"
                disabled
              >
                Выберите постановщика
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="false">
        <b-col>
          <b-form-group
            label="Id обращения"
          >
            <b-form-input
              v-model="taskData.appealId"
              type="text"
              placeholder="Введите id"
              trim
              :disabled="!isEdit"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        v-if="taskId"
        class="float-right"
        :variant="isEdit ? 'danger' : 'primary'"
        :disabled="isLoading"
        @click="onEditToggle"
      >
        {{ isEdit ? 'Отменить' : 'Редактировать' }}
      </b-button>

      <b-button
        v-if="isEdit"
        variant="primary"
        class="float-right"
        :disabled="isLoading"
        @click="onSave"
      >
        Сохранить задачу
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';
import { BaseDatePicker } from '@/components/base';
import { onBInputDatePaste, dateWithoutTime } from '@/helpers/utils';
import {
  format,
  formatISO,
  // parseISO,
} from 'date-fns';

export default {
  name: 'TaskViewModal',
  components: {
    Preloader,
    BaseDatePicker,
  },
  props: {
    taskId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isEdit: true,
      taskDefaultData: null,
      taskData: {
        body: null,
        type: null,
        status: null,
        responsibleId: null,
        priority: 0,
        kaitenLink: null,
        createDate: null,
        deadline: null,
        performer: null,
        operatingSystem: null,
        softwareVersion: null,
        appealId: null,
        // id: null,
        // parentId: null,
        // members: [
        //   '3fa85f64-5717-4562-b3fc-2c963f66afa6'
        // ],
        // comments: [
        //   {
        //     "comment": "string",
        //     "author": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        //   }
        // ],
        // tags: [
        //   0,
        // ],
        // checkList: [
        //   {
        //     "title": "string",
        //     "isChecked": true,
        //     "dateChecked": "2021-10-05T18:41:57.747Z",
        //     "authorChecked": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        //   }
        // ],
        // attachments: [
        //   "3fa85f64-5717-4562-b3fc-2c963f66afa6"
        // ],
        // childIds: [
        //   0
        // ],
      },
      statusList: [
        {
          value: 0,
          text: 'Новое',
        },
        {
          value: 1,
          text: 'В работе',
        },
        {
          value: 2,
          text: 'Возвращена',
        },
        {
          value: 3,
          text: 'Передано',
        },
        {
          value: 4,
          text: 'В ожидании',
        },
        {
          value: 5,
          text: 'Выполнено',
        },
        {
          value: 6,
          text: 'В архиве',
        },
      ],
      typeList: [
        {
          value: 0,
          text: 'Отмена',
        },
        {
          value: 1,
          text: 'Перенос',
        },
        {
          value: 2,
          text: 'Сделать слот',
        },
        {
          value: 3,
          text: 'Корректировка данных',
        },
        {
          value: 4,
          text: 'Раздвоение учеток',
        },
        {
          value: 5,
          text: 'Платные врачи по полису',
        },
        {
          value: 6,
          text: 'Активация полиса',
        },
        {
          value: 7,
          text: 'Не может записаться',
        },
        {
          value: 8,
          text: 'Расписание врача не синхронизировано',
        },
        {
          value: 9,
          text: 'Ошибка входа',
        },
        {
          value: 10,
          text: 'Проблемы с регистрацией',
        },
        {
          value: 11,
          text: 'Ошибка загрузки файлов',
        },
        {
          value: 12,
          text: 'Уведомления',
        },
        {
          value: 13,
          text: 'Плохая связь',
        },
        {
          value: 14,
          text: 'Работа приложения',
        },
        {
          value: 15,
          text: 'Другое',
        },
      ],
      performerList: [
        {
          value: 0,
          text: 'Договорной отдел',
        },
        {
          value: 1,
          text: 'Техподдержка',
        },
        {
          value: 2,
          text: 'Врач-куратор',
        },
        {
          value: 3,
          text: 'Колл-центр',
        },
      ],
      operatingSystemList: [
        {
          value: 0,
          text: 'IOS',
        },
        {
          value: 1,
          text: 'Android',
        },
        {
          value: 2,
          text: 'Windows',
        },
      ],
    };
  },
  computed: {
    responsibleList() {
      return this.$store.state.Account.users.map((user) => ({ value: user.id, text: user.displayName }));
    },
  },
  async created() {
    if (this.taskId) {
      this.isEdit = false;
      try {
        this.isLoading = true;
        const data = await this.$store.dispatch(this.$types.TASK_TRACKER_TASK_FETCH, { id: this.taskId });
        this.taskData = {
          ...data,
          responsibleId: data.responsible.id,
          deadline: new Date(data.deadline),
        };

        this.taskDefaultData = JSON.parse(JSON.stringify(this.taskData));
      } catch (error) {
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    onPaste(e) {
      onBInputDatePaste(e);
    },
    getFormatDate(date, dateFormat) {
      return date && dateFormat ? format(new Date(date), dateFormat) : null;
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onEditToggle() {
      this.isEdit = !this.isEdit;
      this.taskData = JSON.parse(JSON.stringify(this.taskDefaultData));
    },
    async onSave() {
      try {
        const data = {
          ...this.taskData,
          deadline: dateWithoutTime(formatISO(this.taskData.deadline)),
        };

        // if (data.id) {
        //   const responsibleObject = this.responsibleList
        //     .filter((r) => r.text)
        //     .find((r) => r.value === this.taskData.responsibleId);

        //   if (responsibleObject) {
        //     const { text, value } = responsibleObject;
        //     data.responsible = { id: value, displayName: text };
        //   }
        // }

        const apiMethod = this.taskId ? 'TASK_TRACKER_TASK_UPDATE' : 'TASK_TRACKER_TASK_CREATE';
        await this.$store.dispatch(this.$types[apiMethod], data);

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.$store.commit(this.$types.TASK_TRACKER_TASKS_SET, []);
        this.$store.commit(this.$types.TASK_TRACKER_IS_ALL_ITEMS_LOADED_SET, false);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
