<template>
  <div class="patient-policies">
    <custom-scrollbar>
      <div class="policies-inner">
        <div class="policies-header-buttons">
          <!-- v-if="!checkRoleAccess_(['polis.operator', 'polis.operator.dop', 'Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Полисы. История ГП без полиса', url: '/patients' })"
            :type="$const.SECONDARY_BUTTON"
            class="crm-button letter-history-btn without-border transparent ml-auto"
            @click="openIndemnityHistoryWithoutPolicyModal"
          >
            История ГП без полиса
          </base-button>
          <!-- v-if="!checkRoleAccess_(['polis.operator', 'polis.operator.dop', 'Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Полисы. Создать ГП без полиса', url: '/patients' })"
            :type="$const.SECONDARY_BUTTON"
            class="crm-button letter-history-btn without-border transparent ml-3"
            @click="openIndemnityModal"
          >
            Создать ГП без полиса
          </base-button>
          <!-- v-if="!checkRoleAccess_(['polis.operator', 'polis.operator.dop', 'Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Полисы. Создать направление без полиса', url: '/patients' })"
            :type="$const.SECONDARY_BUTTON"
            class="crm-button letter-history-btn without-border transparent ml-3"
            @click="() => openIndemnityModal({isReferral: true })"
          >
            Создать направление без полиса
          </base-button>
          <!-- v-if="!checkRoleAccess_(['polis.operator', 'polis.operator.dop', 'Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Полисы. История направления без полиса', url: '/patients' })"
            :type="$const.SECONDARY_BUTTON"
            class="crm-button letter-history-btn without-border transparent ml-3"
            @click="() => openIndemnityHistoryWithoutPolicyModal({isReferral: true })"
          >
            История направления без полиса
          </base-button>
          <!-- Денис  -->
        </div>
        <div class="active-policies">
          <PatientDetailPolicy
            v-for="(policy, index) in newPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in activePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
          />

          <PatientDetailPolicy
            v-for="(policy, index) in pendingPolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
          />
          <div
            v-if="activePolicies.length === 0 && pendingPolicies.length === 0 && newPolicies.length === 0"
            class="not-found"
          >
            Нет полисов
          </div>
        </div>
        <div
          v-if="inactivePolicies.length"
          class="inactive-policies"
        >
          <div class="redline" />
          <div class="redline-text">
            Неактивные полисы
          </div>
          <PatientDetailPolicy
            v-for="(policy, index) in inactivePolicies"
            :key="policy.id + index"
            :policy="policy"
            :person-id="personId"
          />
        </div>
        <base-button
          v-if="false && newPolicies.length === 0"
          :type="$const.PRIMARY_BUTTON"
          class="add-policy-button"
          @click="addNewPolicy"
        >
          <icon-cross class="crm-icon-cross" />
          Добавить полис
        </base-button>

        <!-- !checkRoleAccess_(['Dis.monitoring.admin']) -->
        <base-button
          v-if="checkFeatureAccess({
            name: 'Возможность прикрепить полис',
            url: '/patients',
          })"
          :type="$const.PRIMARY_BUTTON"
          class="add-policy-button"
          @click="openAttachPolicyModal"
        >
          Прикрепить полис
        </base-button>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { mixinRoles } from '@/mixins';

import PatientDetailPolicy from '@/components/Patients/PatientDetailPolicy';
import IconCross from 'assets/images/cross.svg';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PatientDetailPolicies',
  components: {
    PatientDetailPolicy,
    BaseButton,
    IconCross,
  },
  mixins: [mixinRoles],
  props: {
    policies: {
      type: Array,
      default: null,
    },
    personId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      policyStatuses: {
        inactive: [0, 1, 4, 5],
        pending: [2],
        active: [3],
      },
      newPolicies: [],
    };
  },
  computed: {
    activePolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.active.includes(policy.policyStatus));
      return array;
    },
    pendingPolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.pending.includes(policy.policyStatus));
      return array;
    },
    inactivePolicies() {
      const array = this.policies.filter((policy) => this.policyStatuses.inactive.includes(policy.policyStatus));
      return array;
    },
  },
  methods: {
    addNewPolicy() {
      const newPolicy = {
        id: 0,
        subProgramV2Id: null,
        buyDate: null,
        startDate: null,
        endDate: null,
        personId: this.personId,
      };
      this.newPolicies.push(newPolicy);
    },
    openIndemnityModal({ isReferral }) {
      Bus.$emit('open-modal:indemnity-modal', {
        personId: this.personId,
        clinicId: null, // Денис, вместо null необходимо вставить id выбранной клиники (если Id нет, от можно оставить null)
        isReferral,
      });
    },
    openIndemnityHistoryWithoutPolicyModal({ isReferral }) {
      this.$store.commit(this.$types.INDEMNITY_HISTORY_WITHOUT_POLICY_SET, true);
      this.$store.commit(this.$types.INDEMNITY_PERSON_ID_SET, this.personId);
      Bus.$emit('open-modal:indemnity-history-modal', { personId: this.personId, isReferral });
    },
    openAttachPolicyModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'AttachPolicyModal', props: { personId: this.personId } });
    },
  },
};
</script>

<style lang="scss" scoped>

.patient-policies {
  background-color: #fff;
  padding: 30px 15px 30px 30px;
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  height: calc(100vh - 250px);
  overflow: auto;
  box-sizing: border-box;
}
.policies-inner {
  padding-right: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.not-found {
  font-size: 16px;
  line-height: 20px;
  color: #908F8D;
}

.inactive-policies {
  position: relative;
  margin-top: 20px;
  padding-top: 40px;
}

.redline {
  position: absolute;
  top: 10px;
  right: 0;
  left: 0;
  background-color: #EB5757;
  height: 0.5px;

  &-text {
    position: absolute;
    top: 10px;
    left: 0;
    font-size: 16px;
    line-height: 20px;
    transform: translateY(-50%);
    color: #EB5757;
    background-color: #ffffff;
    padding-right: 30px;
  }
}

.add-policy-button {
  margin-top: 30px;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  width: unset;
  z-index: 10;

  .crm-icon-cross {
    margin-right: 15px;
  }
}

.letter-history-btn {
  width: unset;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  & + & {
    margin-left: 0;
  }
}

.policies-header-buttons {
  display: flex;
}
</style>
