<template>
  <b-container
    fluid="xl"
  >
    <div class="py-4 d-flex align-items-center">
      <div class="h3 mr-4">
        Результат работы анализатора КТГ
      </div>
    </div>

    <Preloader
      v-if="loading"
      :dots="true"
    />
  </b-container>
</template>

<script>
import Preloader from '@/components/Preloader';
import { convertBlobToBase64 } from '@/helpers/utils';

export default {
  components: {
    Preloader,
  },
  data() {
    return {
      analizatorData: {},
      loading: true,
    };
  },
  async mounted() {
    const measId = this.$route.query.id;
    if (measId) {
      await this.getPDFdocument(measId);
      await this.$router.push('/');
      this.loading = false;
    }
  },

  methods: {

    async getPDFdocument(id) {
      const file = await this.$store.dispatch(this.$types.REMOTE_MONITORING_GET_CTG_PDF, {
        measurementId: id,
      });

      const base64 = await convertBlobToBase64(file);

      const URL = window.URL || window.webkitURL;
      const byteChars = atob(base64.split('base64,')[1]);
      const bytes = [];
      let i = 0;

      // eslint-disable-next-line no-plusplus
      for (; i < byteChars.length; i++) bytes[i] = byteChars.charCodeAt(i);

      const blob = new Blob([new Uint8Array(bytes)], {
        type: 'application/pdf',
      });
        // создаём object URL из Blob
      const downloadUrl = URL.createObjectURL(blob);

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { window.navigator.msSaveOrOpenBlob(blob); } else {
        const newWin = window.open(downloadUrl, '_blank');
        newWin.focus();
        URL.revokeObjectURL(downloadUrl);
      }
    },
  },
};
</script>
