<template>
  <div>
    <div class="d-flex align-items-center">
      <b-input
        v-model="filter.patientFio"
        placeholder="Введите ФИО пациента"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        id="popover-filter"
        v-b-tooltip.hover
        :variant="filterSelected ? 'primary' : 'light'"
        class="border border-primary px-1 mr-2"
        title="Дополнительные фильтры"
        @click="togglePopover"
      >
        <b-icon icon="filter" />
      </b-button>

      <b-popover
        custom-class="appeals-popover"
        :show.sync="isPopoverOpen"
        target="popover-filter"
        triggers="manual"
        placement="bottom"
      >
        <div class="d-flex justify-content-end mb-2">
          <b-button
            variant="danger"
            size="sm"
            class="border border-danger p-0 ml-2"
            @click="isPopoverOpen = false"
          >
            <b-icon icon="x" />
          </b-button>
        </div>
        <div class="popover-inner p-2">
          <div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Дата создания
              </div>
              <div>
                <div class="mb-1">
                  <div class="small font-weight-bold mb-1">
                    От
                  </div>
                  <base-date-picker
                    v-model="filter.creationDateFrom"
                    :max-date="maxDate"
                    class="mb-1"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
                <div>
                  <div class="small font-weight-bold mb-1">
                    До
                  </div>
                  <base-date-picker
                    v-model="filter.creationDateTo"
                    :max-date="maxDate"
                    @keyup.native.enter="$emit('search')"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <div class="mb-2 font-weight-bold">
                Цель обращения
              </div>
              <b-select
                v-model="filter.purpose"
                value-field="id"
                text-field="title"
                :options="purposes"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Выберите цель
                  </b-form-select-option>
                </template>
              </b-select>
            </div>
            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Тип обращения
                </div>
                <b-select
                  v-model="typeId"
                  value-field="id"
                  text-field="title"
                  :options="types"
                  @change="changeTypeId"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите тип
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>
            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Статус обращения
                </div>
                <b-select
                  v-model="filter.status"
                  value-field="index"
                  text-field="title"
                  :options="statuses"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите статус
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>
            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Ответственный
                </div>
                <b-select
                  v-model="filter.responsibleId"
                  value-field="id"
                  text-field="displayName"
                  :options="filteredResponsibles"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите ответственного
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>
            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Клиника
                </div>
                <b-select
                  v-model="filter.clinicId"
                  value-field="id"
                  text-field="name"
                  :options="clinics"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите клинику
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>
            <!-- <base-async-select
              v-model="selectedClinic"
              :fetch-function="fetchClinics"
              class="crm-form-field crm-form-field-base-select"
              placeholder="Выберите клинику"
              label="Клиника"
              loading-title="Загрузка клиник"
              no-options-title="Клиники не найдены"
              @change="changeClinic"
            /> -->

            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Страховая компания
                </div>
                <b-select
                  v-model="filter.insuranceCompanyId"
                  value-field="id"
                  text-field="title"
                  :options="insuranceCompanies"
                  @change="changeCompany"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите компанию
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>

            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Страховая программа
                </div>
                <b-select
                  v-model="filter.insuranceProgramId"
                  value-field="id"
                  text-field="name"
                  :options="insurancePrograms"
                  @change="changeProgram"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите программу
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>

            <div class="mb-3">
              <div class="crm-form-block">
                <div class="mb-2 font-weight-bold">
                  Страховая подпрограмма
                </div>
                <b-select
                  v-model="filter.insuranceSubprogramId"
                  value-field="id"
                  text-field="name"
                  :options="insuranceSubprograms"
                  @change="changeSubrogram"
                >
                  <template #first>
                    <b-form-select-option :value="null">
                      Выберите подпрограмму
                    </b-form-select-option>
                  </template>
                </b-select>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                size="sm"
                @click="applyFilter"
              >
                Применить
              </b-button>
            </div>
          </div>
        </div>
      </b-popover>

      <b-button
        variant="primary"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>
    <div class="d-flex justify-content-between w-100 mt-2">
      <b-input
        v-model="filter.phoneNumber"
        size="sm"
        placeholder="Номер телефона"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />

      <b-select
        v-model="filter.status"
        size="sm"
        value-field="index"
        text-field="title"
        :options="statuses"
        class="mr-2"
      >
        <template #first>
          <b-form-select-option :value="null">
            Выберите статус
          </b-form-select-option>
        </template>
      </b-select>

      <b-input
        v-model="filter.policyNumber"
        size="sm"
        placeholder="Номер полиса"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />
      <b-input
        v-model="filter.id"
        size="sm"
        placeholder="ID обращения"
        class="mr-2"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        variant="outline-primary"
        size="sm"
        @click="openExportModal"
      >
        Экспорт
      </b-button>
    </div>
    <div class="d-flex justify-content-between mt-2">
      <b-form-checkbox
        v-model="filter.hasReconciliation"
        switch
        size="sm"
        @change="$emit('search')"
      >
        Показывать только обращения с согласованиями
      </b-form-checkbox>
      <b-button
        variant="outline-primary"
        size="sm"
        class="border-0"
        @click="resetFilter"
      >
        Сбросить фильтры
      </b-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { APPEAL_STATUSES } from '@/helpers/consts';
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'AppealsSearchPanel',
  components: {
    BaseDatePicker,
  },

  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    closeFilterPopoverTrigger: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPopoverOpen: false,
      statuses: APPEAL_STATUSES,
      types: [],
      typeId: null,
      maxDate: new Date(),
      purposes: [
        { id: 0, title: 'Информационное' },
        { id: 1, title: 'Чекап' },
        { id: 2, title: 'Лечение / плановая госпитализация' },
        { id: 3, title: 'Экстренная госпитализация' },
      ],
      filterKeys: [
        'creationDateFrom',
        'creationDateTo',
        'purpose',
        'type',
        'status',
        'responsibleId',
        'clinicId',
        'insuranceCompanyId',
        'insuranceProgramId',
        'insuranceSubprogramId',
      ],
      responsibles: [],
      insuranceData: [],
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      clinics: [],
    };
  },
  computed: {
    filterSelected() {
      const filters = this.filterKeys.map((item) => this.filter[item]);
      return Object.values(filters).some((item) => item || item === 0);
    },
    filteredResponsibles() {
      const validateRoles = [
        'Operator',
        'Tech.operator',
        'MedicalCurator',
        'Dis.monitoring.operator',
      ];
      return this.responsibles.filter((item) => validateRoles.includes(item.roleName));
    },
  },
  async created() {
    if (this.filter.type) this.typeId = this.filter.type.id;

    this.responsibles = await this.fetchResponsibles();
    this.types = await this.fetchTypes();
    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title } }) => ({ id, title }));
    this.clinics = await this.fetchClinics();
    this.setInsuranceEntities();
  },
  mounted() {
    // Находится в некоем скролл-компоненте. Использую класс __panel для нахождения данного блока.
    const scrollElements = document.getElementsByClassName('__panel');

    if (scrollElements.length) {
      scrollElements[0].addEventListener('scroll', this.onScrollPage);
    }
  },
  methods: {
    applyFilter() {
      this.$emit('search');
      this.isPopoverOpen = false;
    },
    onScrollPage() {
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    handleScroll() {
      this.isPopoverOpen = false;
    },
    resetFilter() {
      Bus.$emit('appeals:reset-filter');
    },
    async fetchResponsibles() {
      const data = await this.$store.dispatch(this.$types.ACCOUNT_USERS_FETCH, { take: 99999 });
      return data;
    },
    changeTypeId(id) {
      console.log(id);
      this.filter.type = this.types.find((item) => item.id === id);
    },
    async fetchTypes() {
      const data = await this.$store.dispatch(this.$types.APPEAL_TYPES_FETCH);
      return data;
    },
    async fetchClinics() {
      const data = await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH);
      return data;
    },
    setInsuranceEntities() {
      if (!this.filter.insuranceCompanyId) return;

      const companyObject = this.insuranceData
        .filter(({ company: { id } }) => id === this.filter.insuranceCompanyId)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;

      if (!this.filter.insuranceProgramId) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.filter.insuranceProgramId)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    changeCompany() {
      this.filter.insuranceProgramId = null;
      this.filter.insuranceSubprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.filter.insuranceSubprogramId = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeSubrogram() {},
    openExportModal() {
      Bus.$emit('open-modal:appeals-export');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-icon-search {
  path {
    fill: #ffffff;
  }
}

::v-deep.crm-filter-button {
  &.active {
    background: $blue;

    .crm-icon-filter path{
      fill: #fff;
    }
  }

  .crm-icon-filter path{
    fill: $blue,
  }

}

::v-deep.crm-filter-field {
  &.crm-align-center {
    display: flex;
    align-items: center;
  }

  &--small {
    width: 155px;
  }

  &--reset {
    margin-left: auto!important;
    .reset_button {
      margin-left: auto;
      padding: 0;
      font-size: 14px;
      width: unset;
    }
  }

  &.field-gray-bg {
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    padding: 0 20px;
  }
}

::v-deep.crm-wrap-block.main-field {
  width: calc(100% - 174px);
  flex-grow: unset;
}

.crm-form-field-wrapper {
  display: flex;
  align-items: center;

  .crm-form-divider {
    width: 30px;
    height: 1px;
    background-color: #323232;
    margin: 0 10px;
  }
}

.crm-form-date-picker {
  width: 160px;
}

::v-deep.crm-form-field {
    &.crm-form-field-base-select {
      max-width: 370px;
    }

    & + .crm-form-field {
      margin-top: 20px;
    }

  .vs__selected {
    max-width: 300px;
  }
}

.apply-button {
  margin: 20px auto 0;
}

.popover-inner {
  max-height: 400px;
  overflow: auto;
}

.popover {
  width: 350px !important;
  max-width: unset !important;
}
</style>
