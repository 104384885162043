<template>
  <div
    class="crm-info-item"
  >
    <div class="d-flex">
      <div class="mr-3">
        <img
          :src="doctorImageUrl"
          alt="avatar"
          class="avatar"
        >

        <div class="d-flex mt-2 w-100">
          <b-button
            variant="outline-primary"
            size="sm"
            class="avatar-button"
            @click="openDoctorAvatarAddModal"
          >
            Аватарка
          </b-button>

          <icon-delete
            v-if="doctor.avatarCloudKey"
            class="crm-icon icon-delete mb-0 ml-1"
            @click="openDoctorAvatarDeleteModal"
          />
        </div>
      </div>

      <div class="name-wrapper mr-3">
        <p class="font-weight-bold">
          {{ fullname }}
        </p>

        <!-- null тоже не подходит, поэтому - строгое равенство -->
        <p
          v-if="doctor.settings?.isActive === false"
          class="text-danger small font-weigt-bold"
        >
          Деактивирован
        </p>

        <p class="small">
          {{ specializations }}
        </p>
      </div>

      <div class="divider-doctor mr-3" />

      <div class="d-flex">
        <div class="w-25 d-flex flex-column justify-content-between mr-3">
          <div>
            <p class="crm-crm-info-title">
              Средний рейтинг
            </p>

            <p class="crm-crm-info-text">
              {{ doctor.averageRating || '—' }}
            </p>
          </div>

          <p class="crm-info-title">
            Часовой пояс
          </p>

          <div class="crm-info-text">
            {{ timeZone }}
          </div>
        </div>

        <div class="w-25 mr-3">
          <p class="crm-crm-info-title">
            К-во неотвеченных консультаций
          </p>

          <div class="crm-info-text">
            {{ doctor.notAnsweredConsultationCount }}
          </div>
        </div>

        <div class="w-25 d-flex flex-column justify-content-between mr-3">
          <div>
            <p class="crm-info-title">
              Опыт работы
            </p>

            <div class="crm-info-text">
              {{ experience }}
            </div>
          </div>

          <div>
            <p class="crm-info-title">
              Среднее время
            </p>

            <div>
              <span class="crm-info-title crm-info-title_text">ч:</span>
              <span
                class="crm-info-text"
                style="margin-right: 5px"
              >{{ averageTime.h || '—' }}</span>

              <span class="crm-info-title crm-info-title_text">м:</span>
              <span
                class="crm-info-text"
                style="margin-right: 5px"
              >{{ averageTime.m || '—' }}</span>
            </div>
          </div>
        </div>

        <div class="w-25 crm-buttons-wrapper mr-3">
          <base-button
            v-if="modePin"
            class="crm-button"
            :type="$const.SECONDARY_BUTTON"
            @click="$emit('toggle-pin')"
          >
            {{ isPinned ? 'Открепить' : 'Прикрепить' }}
          </base-button>

          <template v-else>
            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Консультации', url: '/doctors' })"
              variant="outline-primary"
              size="sm"
              @click="openConsultationsModal"
            >
              Консультации
            </b-button>

            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Расписание', url: '/doctors' })"
              variant="outline-primary"
              size="sm"
              class="mt-2"
              @click="openScheduleModal"
            >
              Расписание
            </b-button>
            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Авторизоваться', url: '/doctors' })"
              variant="outline-primary"
              size="sm"
              class="mt-2"
              @click="openDoctorAuth"
            >
              Авторизоваться
            </b-button>
            <!-- v-if="!checkRoleAccess_(['Dis.monitoring.admin'])" -->
            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Устройства', url: '/doctors' })"
              variant="outline-primary"
              size="sm"
              class="mt-2"
              @click="openDoctorDeviceModal"
            >
              Устройства
            </b-button>
            <b-button
              v-if="checkFeatureAccess({ name: 'Видеть кнопку Восстановить', url: '/doctors' }) && doctor.isDeleted"
              variant="primary"
              size="sm"
              class="mt-2 recovery-button"
              @click="openDoctorReestablishModal"
            >
              Восстановить
            </b-button>
          </template>
        </div>
      </div>

      <div
        v-if="!modePin"
        class="divider-doctor-2 mr-3"
      />

      <div
        v-if="!modePin"
        class="controls"
      >
        <icon-watch
          v-if="checkFeatureAccess({ name: 'Кнопка детальной информации о враче (глазик)', url: '/doctors' })"
          class="crm-icon"
          @click="openWatchModal"
        />
        <!-- <icon-edit
          v-if="checkRoleAccess_(['Administrator', 'ClinicAdministrator'])"
          class="crm-icon"
          @click="openEditModal"
        /> -->
        <icon-clouds
          v-if="checkFeatureAccess({ name: 'Кнопка обращения к врачу', url: '/doctors' })"
          class="crm-icon clouds-icon crm-border"
          @click="openAppealEditor"
        />
        <icon-delete
          v-if="checkFeatureAccess({ name: 'Кнопка удаления врача', url: '/doctors' })"
          :class="{ 'progress': isDoctorDeleting }"
          class="crm-icon icon-delete"
          @click="deleteDoctor"
        />
      </div>
    </div>
    <div
      v-if="doctor.isDeleted"
      class="deleted-overlay"
    />
  </div>
</template>

<script>
import { formatTimeZone, showMessage } from '@/helpers/utils';
import { parseISO, format } from 'date-fns';

import { mixinRoles } from '@/mixins';

import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

import IconWatch from 'assets/images/watch_icon.svg';
// import IconEdit from 'assets/images/edit_icon.svg';
import IconDelete from 'assets/images/delete_icon.svg';
import IconClouds from 'assets/images/clouds-icon.svg';
import doctorAvatar from 'assets/images/empty-avatar.svg?inline';

import { APP_DOCTIS_FILE_SERVER_URL, APP_DOCTOR_URL } from '@/app.config';

export default {
  name: 'DoctorInfo',
  components: {
    IconWatch,
    // IconEdit,
    BaseButton,
    IconDelete,
    IconClouds,
  },
  mixins: [mixinRoles],
  props: {
    doctor: {
      type: Object,
      default: () => ({}),
    },
    modePin: {
      type: Boolean,
      default: false,
    },
    isPinned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarUrl: null,
      isDoctorDeleting: false,
    };
  },
  computed: {
    doctorImageUrl() {
      return this.doctor.avatarCloudKey
        ? `${APP_DOCTIS_FILE_SERVER_URL}/${this.doctor.avatarCloudKey}`
        : doctorAvatar;
    },
    fullname() {
      return this.doctor.fullName.split(' ').join('\n');
    },
    specializations() {
      return this.doctor.doctorSpecializations.map((doctorSpecialization) => doctorSpecialization.specialization.title).join(', ');
    },
    averageTime() {
      if (!this.doctor.averageSeveralTime) return '-';

      const array = this.doctor.averageSeveralTime.match(/[0-9]+/gmi);

      return {
        h: array[0],
        m: array[1],
        s: array[2],
      };
    },
    experience() {
      const mainSpec = this.doctor.mainDoctorSpecialization;

      return mainSpec ? this.parseData(mainSpec.experience) : '—';
    },
    timeZone() {
      const { timeZone } = this.doctor;

      return timeZone ? formatTimeZone(timeZone) : '—';
    },
  },
  async created() {
    // if (this.doctor.avatarCloudKey) {
    //   try {
    //     const data = await this.$store.dispatch(this.$types.DOCTOR_AVATAR_GET, { avatarCloudKey: this.doctor.avatarCloudKey });
    //     this.avatarUrl = URL.createObjectURL(data);

    //     console.log(this.avatarUrl);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  },
  methods: {
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openWatchModal() {
      Bus.$emit('open-modal:doctor-watch', { title: this.doctor.fullName, id: this.doctor.id });
    },
    openEditModal() {
      // Bus.$emit('open-modal:doctor-edit', this.doctor.id);
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorEditModal',
        props: {
          doctorId: this.doctor.id,
          title: 'Редактирование врача',
        },
      });
    },
    openConsultationsModal() {
      Bus.$emit('open-modal:doctor-consultations', this.doctor.id);
    },
    openScheduleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ScheduleModal',
        props: {
          doctorId: this.doctor.id,
        },
      });
      // Bus.$emit('open-modal:schedule', this.doctor.id);
    },
    openAppealEditor() {
      Bus.$emit('open-modal:appeal-editor', {
        // initPatientData: this.doctor,
        initDoctorData: {
          firstName: this.doctor.firstName,
          lastName: this.doctor.lastName,
          middleName: this.doctor.middleName,
          phoneNumber: this.doctor.phoneNumber,
          birthDate: this.doctor.birthDate,
        },
      });
    },
    async deleteDoctor() {
      /* eslint-disable no-restricted-globals */
      /* eslint-disable-next-line no-alert */
      if (!confirm(`Вы уверены, что хотите удалить врача ${this.doctor.fullName}`)) return;

      this.isDoctorDeleting = true;

      try {
        await this.$store.dispatch(this.$types.DOCTOR_DELETE, { id: this.doctor.id });

        this.$store.commit(this.$types.DOCTOR_REMOVE_BY_ID, this.doctor.id);
      } finally {
        this.isDoctorDeleting = false;
      }
    },
    openDoctorReestablishModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'DoctorReestablishModal', props: { doctorId: this.doctor.id } });
    },
    async openDoctorAuth() {
      try {
        const { token, refreshToken } = await this.$store.dispatch(this.$types.DOCTOR_AUTH_TOKEN_FETCH, this.doctor.id);
        const doctorUrl = APP_DOCTOR_URL;
        const url = `${doctorUrl}auth?token=${token}&refreshToken=${refreshToken}`;

        window.open(url, '_blank');
      } catch (error) {
        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: 'Не удалось авторизовать врача',
        });
      }
    },
    openDoctorDeviceModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorDeviceModal',
        props: {
          personId: this.doctor.id,
          fio: `${this.doctor.lastName} ${this.doctor.firstName} ${this.doctor.middleName}`,
        },
      });
    },
    openDoctorAvatarAddModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorAvatarAddModal',
        props: {
          doctor: this.doctor,
          // afterSave: this.onClose,
          afterSave: () => Bus.$emit('doctors:fetch'),
        },
      });
    },
    openDoctorAvatarDeleteModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DoctorAvatarDeleteModal',
        props: {
          doctorId: this.doctor.id,
          // personId: this.personId,
          afterDelete: () => Bus.$emit('doctors:fetch'),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-info-item {
  padding: 20px !important;
  border-radius: 0.25rem !important;
  box-shadow: rgba(84, 89, 94, 0.2) 0px 8px 24px !important;
  margin-top: 10px;
  position: relative;
}
.avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
}
.name-wrapper {
  width: 230px;
  max-width: 230px;
  min-width: 230px;
  white-space: pre-line;

  @media (max-width: 1400px) {
    width: 150px;
    max-width: 150px;
    min-width: 150px;
  }
}

.divider-doctor,
.divider-doctor-2 {
  height: 100%;
  border-right: 1px solid #E0E0E0;
}

.crm-buttons-wrapper {
  display: flex;
  flex-direction: column;

  .crm-button {
    width: auto;
    padding: 10px;
    height: 35px;
    //flex-basis: 130px;
    font-size: 14px;
    line-height: 17px;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}
.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.crm-info-title {
  font-size: 14px;
  line-height: 17px;
  color: #908F8D;
  margin: 0 0 5px 0;

  &_text {
    margin: 0 5px 0 0 ;
  }
}
.crm-info-text {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}
::v-deep.crm-icon {
  cursor: pointer;

  &:not(last-child) {
    margin-bottom: 15px;
  }

  &.progress {
    cursor: wait;
  }

  &.icon-delete:hover {
    rect {
      stroke: $red;
    }
  }

  &.crm-border {
    border: 1px solid #c7c7c7;
    border-radius: 5px;
    &:hover {
      border: 1px solid $blue;
    }
  }

  &.clouds-icon:hover {
    path {
      fill: $blue-dark;
    }
  }

  &:not(.icon-delete):hover {
    rect {
      stroke: $blue;
    }
    path {
      fill: $blue;
    }
  }
}
.clouds-icon {
  padding: 6px 5px 6px 5px;
  path {
    fill: #868686;
  }
}

.deleted-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFFFFF;
  opacity: 0.7;
  border-radius: 4px;
  z-index: 1;
}

.recovery-button {
  position: relative;
  z-index: 10;
}

.avatar-button {
  flex-grow: 1;
}
</style>
