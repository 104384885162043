<template>
  <div class="analysis-block-wrapper">
    <div class="crm-form-title">
      <span class="crm-form-title-inner">
        Отделение
      </span>
    </div>

    <div class="crm-form-row">
      <base-input
        v-model="query"
        :input-style="{
          padding: '11px 10px 12px',
          resize: 'none',
          backgroundColor: '#ffffff',
        }"
        class="crm-form-field"
        placeholder="Введите адрес"
      >
        <template #suffix>
          <icon-search />
        </template>
      </base-input>
      <div class="crm-form-field" />
    </div>

    <div class="clinic-map">
      <div class="clinic-list">
        <custom-scrollbar ref="clinic-container">
          <div class="clinic-list-inner">
            <div
              v-for="clinic in clinicList"
              :key="clinic.id"
              :ref="clinic.id"
              class="clinic-item"
              :class="{selected: clinic.id === selectedClinic.id}"
              @click="onClinicClick(clinic)"
            >
              <div class="crm-title">
                {{ clinic.company.name }}
              </div>
              <div class="address">
                {{ clinic.address }}
              </div>
            </div>
          </div>
        </custom-scrollbar>
      </div>
      <div class="clinic-map-container">
        <yandex-map
          :coords="coords"
          :zoom="zoom"
          :scroll-zoom="false"
          :style="{ width: '100%', height: '100%' }"
          :controls="['zoomControl']"
          :options="options"
          @map-was-initialized="setMap"
        >
          <ymap-marker
            v-for="clinic in clinics"
            :key="clinic.id"
            :marker-id="clinic.id"
            :coords="[clinic.location.latitude, clinic.location.longitude]"
            :hint-content="`${clinic.company.name} <br> ${clinic.address}`"
            :cluster-name="1"
            :icon="clinic.id === selectedClinic.id ? markerIcon : undefined"
            style="{ background-color: black; }"
            @click="onClinicMarkerClick(clinic)"
          />
        </yandex-map>

        <div
          v-if="selectedClinic.id"
          class="map-panel"
        >
          <div class="map-panel-header">
            Выбранное отделение:
          </div>
          <div class="map-panel-title">
            {{ selectedClinic.company.name }}
          </div>
          <div class="map-panel-address">
            {{ selectedClinic.address }}
          </div>
          <div
            class="close-btn"
            @click="clearSelectedClinic"
          >
            <icon-cross
              height="10"
              width="10"
              viewBox="0 0 15 15"
              class="crm-icon-cross"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseInput,
} from '@/components/base';

import IconSearch from 'assets/images/search.svg';
import IconCross from 'assets/images/cross.svg';
// import IconMarker from 'assets/images/map-placeholder.svg';

export default {
  name: 'AnalysisClinics',
  components: {
    BaseInput,
    IconSearch,
    IconCross,
  },
  data() {
    return {
      coords: [55.760959, 37.625251], // moscow
      zoom: 9,
      options: {
        margin: 10,
        // minZoom: 3,
        maxAnimationZoomDifference: Infinity,
      },
      markerIcon: {
        layout: 'default#imageWithContent',
        // eslint-disable-next-line global-require
        imageHref: require('../../assets/images/map-placeholder.png'),
        imageSize: [60, 60],
        imageOffset: [-28, -48],
      },
      map: null,
    };
  },
  computed: {
    query: {
      get() {
        return this.$store.state.Analysis.clinicQuery;
      },
      set(newVal) {
        this.$store.commit(this.$types.ANALYSIS_CLINIC_QUERY_SET, newVal);
      },
    },
    selectedClinic() {
      return this.$store.state.Analysis.selectedClinic || {};
    },
    clinics() {
      return this.$store.state.Analysis.clinics;
    },
    clinicList() {
      return this.$store.state.Analysis.clinics.filter((clinic) => {
        const query = this.query.toLowerCase();
        const address = clinic.address.toLowerCase();
        const name = clinic.company.name.toLowerCase();

        return address.includes(query) || name.includes(query);
      });
    },
  },
  watch: {
    clinicList(clinics) {
      if (this.query.trim().length === 0 && this.selectedClinic && this.selectedClinic.location) {
        this.showClinicOnMap(this.selectedClinic);
        this.showClinicInList(this.selectedClinic);
        return;
      }

      if (clinics.length === 0 || this.query.trim().length === 0 || !this.map) return;

      if (clinics.length === 1) {
        this.showClinicOnMap(clinics[0]);
        return;
      }

      const position = clinics[0].city && clinics[0].city.location
        ? [clinics[0].city.location.latitude, clinics[0].city.location.longitude]
        : [clinics[0].location.latitude, clinics[0].location.longitude];

      this.map.setCenter(position, 10, {
        duration: 500,
        checkZoomRange: true,
      });
    },
  },
  async created() {
    await this.fetchAllClinics();
  },
  methods: {
    setMap(map) {
      this.map = map;
      this.map.margin.setDefaultMargin(50);
    },
    async fetchAllClinics() {
      const clinics = await this.$store.dispatch(this.$types.ANALYSIS_CLINICS_FETCH, {
        take: 1000,
        query: this.query,
      });

      const filteredClinics = clinics.filter((clinic) => clinic.location);

      this.$store.commit(this.$types.ANALYSIS_CLINICS_SET, [...filteredClinics]);
    },
    onClinicMarkerClick(clinic) {
      this.query = '';
      this.setClinic(clinic);
    },
    onClinicClick(clinic) {
      this.setClinic(clinic);
    },
    setClinic(clinic) {
      if (clinic.id === this.selectedClinic.id) return this.$store.commit(this.$types.ANALYSIS_SELECTED_CLINIC_SET, null);

      this.$store.commit(this.$types.ANALYSIS_SELECTED_CLINIC_SET, clinic);
      this.showClinicOnMap(clinic);
      this.showClinicInList(clinic);
    },
    showClinicOnMap(clinic) {
      const position = [clinic.location.latitude, clinic.location.longitude];

      this.map.setCenter(position, 16, {
        duration: 500,
        checkZoomRange: true,
      });
    },
    showClinicInList(clinic) {
      setTimeout(() => {
        const { offsetTop } = this.$refs[clinic.id][0];
        const clinicContainer = this.$refs['clinic-container'];
        clinicContainer.scrollTo({ y: offsetTop }, 500);
      }, 300);
    },
    clearSelectedClinic() {
      this.$store.commit(this.$types.ANALYSIS_SELECTED_CLINIC_SET, null);
    },
  },
};
</script>

<style lang="scss" scoped>
.analysis-block-wrapper {
  margin-top: 40px;
}
.crm-form-row {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

::v-deep.crm-form-field {
  display: flex;
  flex: 1;

  &:not(:first-child) {
    margin-left: 20px;
  }
  .crm-label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    font-weight: 400;
  }
  .crm-base-input {
    height: 40px;
  }
}

.clinic-map {
  margin-top: 20px;
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #DADADA;
  display: flex;
}

.clinic-list {
  width: 250px;
  flex-shrink: 0;
  background-color: #F3F3F3;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.clinic-item {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  transition: all .3s;

  &:hover {
    opacity: 0.7;
  }

  &:not(:first-child) {
    margin-top: 5px;
  }

  .crm-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
  .address {
    font-size: 14px;
    line-height: 18px;
    color: #B8B8B8;
    margin-top: 10px;
  }

  &.selected {
    background: #6E88F3;

    .crm-title, .address {
      color: #fff;
    }
  }

}

.clinic-map-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #fff;

  &-header {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6E88F3;
  }
  &-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-top: 10px;
  }
  &-address {
    font-size: 14px;
    line-height: 18px;
    color: #B8B8B8;
    margin-top: 10px;
  }

  .close-btn {
    cursor: pointer;
    padding: 5px 15px;
    transition: .3s all;
    position: absolute;
    top: 10px;
    right: 0;

    &:hover {
      opacity: 0.5;
    }

    .crm-icon-cross {
      transform: rotate(45deg);
      path {
        fill: #373429;
      }
    }
  }
}
.crm-form-title {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #979797;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
  }
}
</style>
