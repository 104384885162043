<template>
  <div class="w-100 h-100 p-4 overflow-auto">
    <b-container
      fluid="xl"
      class="py-4"
    >
      <div class="crm-container-title">
        <div
          class="crm-title-link"
          @click="$router.go(-1)"
        >
          <arrow-icon class="arrow-left" />
        </div>
        <span class="crm-title">
          Создание / редактирование подпрограммы ДМС
        </span>
      </div>

      <div class="">
        <div class="rounded p-4 bg-white w-100">
          <base-dropdown
            header="Шаг 1"
            class="crm-form-step"
            :open="steps[1].open"
            @toggleShowStatus="toggleStepShowStatus(1)"
          >
            <template v-if="steps[1].open">
              <b-row class="my-3">
                <b-col>
                  <div class="font-weight-bold mb-2">
                    Страховая компания
                  </div>

                  <v-select
                    v-model="$v.subprogram.companyId.$model"
                    :reduce="company => company.id"
                    :options="companies"
                    :clearable="false"
                    placeholder="Выберите компанию"
                    label="title"
                    :disabled="steps[1].disabled"
                    @input="changeCompany"
                  />

                  <div
                    v-if="$v.subprogram.companyId.$error"
                    class="mt-2 text-danger"
                  >
                    {{ errorsValidation.companyId[0] }}
                  </div>
                </b-col>
                <b-col>
                  <div class="font-weight-bold mb-2">
                    Название подпрограммы
                  </div>

                  <b-input
                    v-model="$v.subprogram.name.$model"
                    :disabled="steps[1].disabled"
                    :state="checkValidations ? !$v.subprogram.name.$error: null"
                    placeholder="Введите название подпрограммы"
                  />

                  <div
                    v-if="$v.subprogram.name.$error"
                    class="mt-2 text-danger"
                  >
                    Поле не может быть пустым
                  </div>
                </b-col>
              </b-row>
              <b-row class="my-3">
                <b-col>
                  <div class="font-weight-bold mb-2">
                    Программа
                  </div>

                  <b-form-radio-group
                    v-model="$v.programType.$model"
                    :options="PROGRAMS"
                    value-field="id"
                    text-field="title"
                    :disabled="steps[1].disabled"
                  />

                  <div
                    v-if="$v.programType.$error"
                    class="mt-2 text-danger"
                  >
                    {{ errorsValidation.programType[0] }}
                  </div>
                </b-col>
                <b-col>
                  <template v-if="programType === 0">
                    <div class="font-weight-bold mb-2">
                      Выберите программу
                    </div>

                    <b-select
                      v-model="$v.subprogram.programId.$model"
                      :options="programs"
                      value-field="id"
                      :state="checkValidations ? !$v.subprogram.programId.$error : null"
                      placeholder="Выберите программу"
                      text-field="name"
                      :disabled="steps[1].disabled"
                      @change="onProgramChange"
                    />

                    <div
                      v-if="$v.subprogram.programId.$error"
                      class="mt-2 text-danger"
                    >
                      {{ errorsValidation.programId[0] }}
                    </div>
                  </template>

                  <template v-if="programType === 1">
                    <div class="font-weight-bold mb-2">
                      Название программы
                    </div>

                    <b-input
                      v-model="$v.newProgramName.$model"
                      :state="checkValidations ? !$v.newProgramName.$error: null"
                      placeholder="Введите название программы"
                      :disabled="steps[1].disabled"
                    />
                  </template>
                </b-col>
              </b-row>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Наименование подпрограммы для гарантийных писем для МИД
                </div>

                <b-input
                  v-model="$v.subprogram.indemnityLetterName.$model"
                  :disabled="steps[1].disabled"
                  :state="checkValidations ? !$v.subprogram.indemnityLetterName.$error : null"
                  placeholder="Введите наименование"
                />
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Назначение полиса
                </div>

                <b-form-checkbox-group
                  v-model="$v.subprogram.policyPurposes.$model"
                  :options="POLICY_PUROISES"
                  value-field="id"
                  text-field="title"
                  :state="checkValidations ? !$v.subprogram.policyPurposes.$error : null"
                  :disabled="steps[1].disabled"
                />

                <div
                  v-if="$v.subprogram.policyPurposes.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.policyPurposes[0] }}
                </div>
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Группа продукта
                </div>

                <b-form-checkbox-group
                  v-model="$v.subprogram.productGroup.$model"
                  :options="PRODUCT_GROUP"
                  value-field="id"
                  text-field="title"
                  :state="checkValidations ? !$v.subprogram.productGroup.$error : null"
                  :disabled="steps[1].disabled"
                />

                <div
                  v-if="$v.subprogram.productGroup.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.productGroup[0] }}
                </div>
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Особенности активации
                </div>

                <b-form-radio-group
                  v-model="$v.subprogram.activationType.$model"
                  :options="ACTIVATION_TYPES"
                  value-field="id"
                  text-field="title"
                  :state="checkValidations ? !$v.subprogram.activationType.$error : null"
                  :disabled="steps[1].disabled"
                />

                <div
                  v-if="$v.subprogram.activationType.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.activationType[0] }}
                </div>
              </div>

              <div class="my-3">
                <b-form-checkbox
                  v-model="subprogram.isCountConsultationLimit"
                  :disabled="steps[1].disabled"
                >
                  <div class="pt-1">
                    Считать лимит телемедицинских консультаций.
                  </div>
                </b-form-checkbox>
              </div>

              <div class="my-3">
                <b-form-checkbox
                  v-model="subprogram.isOrOr"
                  :disabled="steps[1].disabled"
                >
                  <div class="pt-1">
                    Или Или (привязка подпрограмм)
                  </div>
                </b-form-checkbox>
              </div>

              <template v-if="subprogram.isOrOr">
                <b-table
                  v-if="relatedSubprogramSearch.items.length"
                  :fields="relatedSubprogramSearch.fields"
                  :items="relatedSubprogramSearch.items"
                  sticky-header
                  small
                  bordered
                  class="related-subprograms-table"
                >
                  <template #cell(id)="row">
                    <b-form-checkbox
                      v-model="subprogram.relatedSubprogramIds"
                      :value="row.item.id"
                      class="ml-2"
                    />
                  </template>
                </b-table>

                <div v-else>
                  Подпрограммы для привязки не найдены
                </div>
              </template>
            </template>
          </base-dropdown>

          <base-dropdown
            header="Шаг 2"
            class="crm-form-step"
            :open="steps[2].open"
            @toggleShowStatus="toggleStepShowStatus(2)"
          >
            <template v-if="steps[2].open">
              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Лимит стоимости консультации
                </div>

                <b-input
                  v-model="subprogram.limitingConsultationCost"
                  type="number"
                  :disabled="steps[2].disabled"
                />

                <div
                  v-if="$v.subprogram.limitingConsultationCost.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.limitingConsultationCost[0] }}
                </div>
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Специальности врачей по телемедицине
                </div>

                <div
                  v-if="$v.subprogram.specializations.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.specializations[0] }}
                </div>
              </div>

              <div class="my-3 d-flex">
                <b-form-checkbox
                  v-model="subprogram.isLimitation"
                  :disabled="steps[2].disabled"
                  @change="toggleConsultationLimit"
                >
                  Ограничение общего числа консультаций к выбранным специалистам
                </b-form-checkbox>

                <b-input
                  v-if="subprogram.isLimitation"
                  v-model="subprogram.limitationCount"
                  type="number"
                  size="sm"
                  style="width: 120px;"
                  class="ml-4"
                  :state="checkValidations ? !$v.subprogram.limitationCount.$error : null"
                  :disabled="steps[2].disabled"
                />
              </div>

              <div class="d-flex justify-content-end my-3">
                <b-button
                  variant="primary"
                  :disabled="steps[2].disabled"
                  @click="toggleAllSpecs"
                >
                  {{ allSpecsChecked ? 'Отменить все' : 'Выбрать все' }}
                </b-button>
              </div>

              <div class="crm-form-fields">
                <div class="crm-form-column">
                  <div class="crm-form-subtitle">
                    Дежурные врачи (<b>неограниченно</b>)
                  </div>

                  <div
                    v-for="spec in dutySpecializations"
                    :key="spec.id"
                    class="crm-form-checkbox-row"
                  >
                    <div class="crm-form-checkbox-inner">
                      <!-- <base-checkbox
                        v-model="spec.active"
                        class="crm-form-checkbox"
                        @change="(value) => toggleSpecialization(value, spec)"
                      >
                        <span class="crm-form-checkbox-text">
                          {{ spec.name }}
                        </span>
                      </base-checkbox> -->

                      <div
                        class="crm-form-checkbox-text"
                        style="padding: 7px 0px"
                      >
                        {{ spec.name }}
                      </div>

                      <!-- <base-input
                        v-if="spec.active"
                        v-model="subprogram.specializations[spec.id]"
                        class="crm-checkbox-input"
                        type="number"
                        :input-style="{
                          width: '50px',
                          height: '20px',
                          padding: '1px 7px',
                          textAlign: 'center',
                        }"
                        :disable-arrows="true"
                        placeholder="∞"
                      /> -->
                    </div>
                  </div>
                </div>

                <div class="crm-form-column flex-3">
                  <div class="crm-form-subtitle">
                    Врачи с узкой специальностью
                  </div>

                  <div class="crm-form-checkbox-list">
                    <div
                      v-for="spec in specializations"
                      :key="spec.id"
                      class="crm-form-checkbox-item"
                    >
                      <div class="crm-form-checkbox-inner">
                        <base-checkbox
                          v-model="spec.active"
                          class="crm-form-checkbox"
                          :disabled="steps[2].disabled"
                          @change="(value) => toggleSpecialization(value, spec)"
                        >
                          <span class="crm-form-checkbox-text">
                            {{ spec.name }}
                          </span>
                        </base-checkbox>

                        <base-input
                          v-if="spec.active"
                          v-model="subprogram.specializations[spec.id]"
                          class="crm-checkbox-input"
                          type="number"
                          :input-style="{
                            width: '50px',
                            height: '20px',
                            padding: '1px 7px',
                            textAlign: 'center',
                          }"
                          :disable-arrows="true"
                          placeholder="∞"
                          :disabled="steps[2].disabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Узкопрофильные блоки
                </div>

                <div class="d-flex flex-wrap">
                  <b-form-checkbox
                    v-for="spec in narrowSpecializations"
                    :key="spec"
                    v-model="subprogram.narrowSpecializations"
                    :value="spec"
                    :disabled="steps[2].disabled"
                    class="mr-2 mb-2"
                  >
                    {{ spec }}
                  </b-form-checkbox>
                </div>
              </div>
            </template>
          </base-dropdown>

          <base-dropdown
            header="Шаг 3"
            class="crm-form-step"
            :open="steps[3].open"
            @toggleShowStatus="toggleStepShowStatus(3)"
          >
            <template v-if="steps[3].open">
              <b-row class="my-3 d-flex">
                <b-col cols="4">
                  <div class="font-weight-bold mb-2">
                    Стоимость прикрепления
                  </div>

                  <div class="d-flex align-items-center">
                    <b-input
                      v-model="$v.subprogram.attachmentCost.$model"
                      type="number"
                      style="width: 120px;"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                      :state="checkValidations ? !$v.subprogram.attachmentCost.$error : null"
                    />
                    <div class="ml-2">
                      ₽
                    </div>
                  </div>

                  <div
                    v-if="$v.subprogram.attachmentCost.$error"
                    class="mt-2 text-danger"
                  >
                    {{ errorsValidation.attachmentCost[0] }}
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="font-weight-bold mb-2">
                    Возрастные ограничения от-до (Лет)
                  </div>

                  <div class="d-flex align-items-center">
                    <b-input
                      v-model="$v.subprogram.ageRestrictions.lowLimit.$model"
                      type="number"
                      style="width: 120px;"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                      :state="checkValidations ? !$v.subprogram.ageRestrictions.lowLimit.$error : null"
                    />
                    <div class="mx-4">
                      -
                    </div>
                    <b-input
                      v-model="$v.subprogram.ageRestrictions.highLimit.$model"
                      type="number"
                      style="width: 120px;"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                      :state="checkValidations ? !$v.subprogram.ageRestrictions.highLimit.$error : null"
                    />
                  </div>

                  <div
                    v-if="$v.subprogram.ageRestrictions.lowLimit.$error || $v.subprogram.ageRestrictions.highLimit.$error"
                    class="mt-2 text-danger"
                  >
                    <div>
                      {{ errorsValidation.lowLimit[0] }}
                    </div>
                    <div class="mt-1">
                      {{ errorsValidation.highLimit[0] }}
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="font-weight-bold mb-2">
                    Срок франшизы
                  </div>

                  <div class="d-flex align-items-center">
                    <b-input
                      v-model="subprogram.franchiseTerm"
                      style="width: 120px;"
                      type="number"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                    />

                    <div class="ml-3">
                      дней
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-row class="my-3">
                <b-col cols="3">
                  <div class="font-weight-bold mb-2">
                    Вид программы
                  </div>

                  <b-form-radio
                    v-model="$v.subprogram.subProgramType.$model"
                    name="subProgramType1"
                    :value="0"
                    :disabled="steps[3].disabled"
                    @change="subprogram.familyMembersCount = 1"
                  >
                    Индивидуальная
                  </b-form-radio>
                  <b-form-radio
                    v-model="$v.subprogram.subProgramType.$model"
                    name="subProgramType1"
                    :value="1"
                    :disabled="steps[3].disabled"
                  >
                    Семейная
                  </b-form-radio>

                  <div
                    v-if="$v.subprogram.subProgramType.$error"
                    class="mt-2 text-danger"
                  >
                    {{ errorsValidation.subProgramType[0] }}
                  </div>
                </b-col>
                <template v-if="subprogram.subProgramType === 1">
                  <b-col cols="3">
                    <div class="font-weight-bold mb-2">
                      Кол-во членов семьи
                    </div>

                    <div class="d-flex align-items-center">
                      <b-input
                        v-model="$v.subprogram.familyMembersCount.$model"
                        type="number"
                        placeholder="∞"
                        style="width: 120px;"
                        :disable-arrows="true"
                        :disabled="steps[3].disabled"
                      />
                      <div class="ml-3">
                        чел.
                      </div>
                    </div>

                    <div
                      v-if="$v.subprogram.familyMembersCount.$error"
                      class="mt-2 text-danger"
                    >
                      {{ errorsValidation.familyMembersCount[0] }}
                    </div>
                  </b-col>
                  <b-col cols="3">
                    <div class="font-weight-bold mb-2">
                      Вид лимита
                    </div>

                    <b-form-radio-group
                      v-model="$v.subprogram.limitType.$model"
                      :options="LIMIT_TYPES"
                      value-field="id"
                      text-field="title"
                      :disabled="steps[3].disabled"
                    />
                  </b-col>
                  <b-col
                    v-if="subprogram.limitType === 0"
                    cols="3"
                  >
                    <div class="font-weight-bold mb-2">
                      Суммы лимитов
                    </div>

                    <div
                      v-for="(inputItem, index) in Number(subprogram.familyMembersCount)"
                      :key="inputItem"
                      :class="{ 'mt-3': index }"
                    >
                      <div>
                        {{ index ? 'Доп '+ index : 'Основной' }}
                      </div>
                      <div class="d-flex mt-2">
                        <b-input
                          v-model="subprogram.individualLimits[index]"
                          type="number"
                          min="0"
                          style="width: 120px;"
                          :disable-arrows="true"
                          @input="individualLimitsTrim = true"
                        />
                        <div class="ml-3">
                          ₽
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="individualLimitsError && individualLimitsTrim"
                      class="mt-2"
                    >
                      <div class="text-danger font-weight-bold">
                        Проверьте правильность введенных данных
                      </div>

                      <div
                        v-for="(errorItem, index) in individualLimitsError"
                        :key="index"
                        class="mt-1 text-danger"
                      >
                        {{ errorItem.text }}
                      </div>
                    </div>
                  </b-col>
                </template>
              </b-row>

              <div class="d-flex align-items-center mt-4">
                <b-form-checkbox
                  v-model="isDiscountStatus"
                  name="checkbox-1"
                  switch
                  :disabled="steps[1].disabled"
                >
                  Скидки
                </b-form-checkbox>
              </div>

              <div class="my-3">
                <transition name="slide-fade">
                  <b-row v-if="isDiscountStatus">
                    <b-col cols="4">
                      <b-form-group
                        :invalid-feedback="invalidFeedback"
                        :state="statePercentageDiscount"
                        :disabled="steps[1].disabled"
                      >
                        <b-form-radio
                          v-model="$v.selectedDiscount.$model"
                          name="some-radios"
                          value="A"
                        >
                          Процентная скидка %
                          <b-form-input
                            v-model="subprogram.percentageDiscount"
                            type="number"
                            number
                            class="mt-2"
                            size="sm"
                            @focus="onFocusSelectDiscount('A')"
                          />
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group
                        :invalid-feedback="invalidFeedback"
                        :state="stateFixedDiscount"
                        :disabled="steps[1].disabled"
                      >
                        <b-form-radio
                          v-model="$v.selectedDiscount.$model"
                          name="some-radios"
                          value="B"
                        >
                          Фиксированная скидка
                          <b-form-input
                            v-model="subprogram.fixedDiscount"
                            type="number"
                            number
                            class="mt-2"
                            size="sm"
                            @focus="onFocusSelectDiscount('B')"
                          />
                        </b-form-radio>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      class="mt-2 mb-2"
                    >
                      <transition name="slide-fade">
                        <div
                          v-if="$v.selectedDiscount.$error"
                          class="validation-errors"
                        >
                          <span
                            class="validation-error-text"
                          >
                            {{ errorsValidation.selectedDiscount[0] }}
                          </span>
                        </div>
                      </transition>
                    </b-col>
                  </b-row>
                </transition>
              </div>

              <b-row class="my-3">
                <b-col cols="4">
                  <div class="font-weight-bold mb-2">
                    Сумма страхового покрытия
                  </div>

                  <div class="d-flex align-items-center">
                    <b-input
                      v-model="subprogram.insuranceCoverSum"
                      type="number"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                    />
                    <div class="ml-3">
                      ₽
                    </div>
                  </div>
                </b-col>
                <b-col cols="4">
                  <div class="font-weight-bold mb-2">
                    Страховая выплата
                  </div>

                  <div class="d-flex align-items-center">
                    <b-input
                      v-model="subprogram.insurancePayment"
                      type="number"
                      :disable-arrows="true"
                      :disabled="steps[3].disabled"
                    />
                    <div class="ml-3">
                      ₽
                    </div>
                  </div>
                </b-col>
              </b-row>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Текст для распознавания подпрограммы
                </div>

                <div v-if="subprogram.recognitions.length">
                  <div
                    v-for="(recognition, index) in $v.subprogram.recognitions.$each.$iter"
                    :key="index"
                  >
                    <div class="d-flex align-items-center mb-2">
                      <b-input
                        v-model="subprogram.recognitions[index]"
                        placeholder="Введите текст"
                        :state="checkValidations ? $v.subprogram.recognitions.$each[index].required : null"
                        :disabled="steps[3].disabled"
                      />

                      <b-button
                        variant="danger"
                        :disabled="steps[3].disabled"
                        class="p-1 ml-3"
                        @click="deleteRecognition(index)"
                      >
                        <b-icon icon="trash" />
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-button
                  variant="primary"
                  size="sm"
                  :disabled="steps[3].disabled"
                  class="mt-3"
                  @click="addRecognition"
                >
                  Добавить текст
                </b-button>
              </div>
            </template>
          </base-dropdown>

          <base-dropdown
            header="Шаг 4"
            class="crm-form-step"
            :open="steps[4].open"
            @toggleShowStatus="toggleStepShowStatus(4)"
          >
            <template v-if="steps[4].open">
              <div class="mt-3">
                <div class="font-weight-bold mb-2">
                  Покрытие лабораторных и инструментальных исследований
                </div>

                <TextEditor
                  v-model="subprogram.researchCover"
                  placeholder="Введите текст"
                  :disabled="steps[4].disabled"
                  class="crm-form-field"
                />
              </div>

              <div class="mt-3">
                <div class="font-weight-bold mb-2">
                  Лечение
                </div>

                <TextEditor
                  v-model="subprogram.therapy"
                  placeholder="Введите текст"
                  :disabled="steps[4].disabled"
                  class="crm-form-field"
                />
              </div>

              <div class="mt-3">
                <div class="font-weight-bold mb-2">
                  Гарантийные письма
                </div>

                <TextEditor
                  v-model="subprogram.indemnityLetters"
                  placeholder="Введите текст"
                  :disabled="steps[4].disabled"
                  class="crm-form-field"
                />
              </div>

              <div class="d-flex justify-content-between mt-3">
                <div class="w-50 mr-1">
                  <div class="font-weight-bold mb-2">
                    Транспортные расходы
                  </div>

                  <TextEditor
                    v-model="subprogram.fares"
                    placeholder="Введите текст"
                    :disabled="steps[4].disabled"
                  />
                </div>

                <div class="w-50 ml-1">
                  <div class="font-weight-bold mb-2">
                    Канал реализации
                  </div>

                  <TextEditor
                    v-model="subprogram.channel"
                    placeholder="Введите текст"
                    :disabled="steps[4].disabled"
                  />
                </div>
              </div>

              <div class="mt-3">
                <div class="font-weight-bold mb-2">
                  Описание программы для клиентов
                </div>

                <TextEditor
                  v-model="subprogram.description"
                  placeholder="Введите текст"
                  :disabled="steps[4].disabled"
                  class="crm-form-field"
                />
              </div>

              <div class="my-3">
                <div class="font-weight-bold mb-2">
                  Документы
                </div>

                <div
                  v-if="documents.length"
                  class="mb-2"
                >
                  <base-file
                    v-for="document in documents"
                    :key="document.id"
                    :file="document"
                    file-api="File/"
                    edit
                    :disabled="steps[4].disabled"
                    @delete="onClickDeleteFiles(document)"
                  />
                </div>

                <b-button
                  variant="primary"
                  size="sm"
                  :disabled="steps[4].disabled"
                  @click="onClickOpenFilesModal"
                >
                  Добавить
                </b-button>
              </div>

              <div
                v-if="subprogram.documentForClients"
                class="my-3"
              >
                <div class="font-weight-bold mb-2">
                  Документы для клиента
                </div>
                <div class="d-flex">
                  <div class="w-50 my-4 insurance-subprograms__files-group">
                    <div class="font-weight-bold mb-2">
                      Документ для клиентов
                    </div>

                    <div
                      v-if="subprogram.documentForClients.value"
                      class="w-100 mb-4"
                    >
                      <base-file
                        :file="{
                          title: subprogram.documentForClients.value,
                          guid: subprogram.documentForClients.key,
                        }"
                        file-api="File/"
                        edit
                        :is-show="false"
                        :disabled="steps[4].disabled"
                        download-permission
                        @delete="onClickDeleteFile(subprogram.documentForClients)"
                        @downloadFile="downloadFile('documentForClients', $event)"
                      />
                    </div>

                    <b-button
                      variant="primary"
                      size="sm"
                      :disabled="steps[4].disabled"
                      @click="openUploadFileModal(0)"
                    >
                      {{ subprogram.documentForClients.value ? 'Заменить' : 'Добавить' }}
                    </b-button>
                  </div>
                  <div class="ml-2 w-50 my-4 insurance-subprograms__files-group">
                    <div class="font-weight-bold mb-2">
                      Подробное описание программы
                    </div>

                    <div
                      v-if="subprogram.detailedDescription.value"
                      class="w-100 mb-4"
                    >
                      <base-file
                        :file="{
                          title: subprogram.detailedDescription.value,
                          guid: subprogram.detailedDescription.key,
                        }"
                        file-api="File/"
                        edit
                        :is-show="false"
                        :disabled="steps[4].disabled"
                        download-permission
                        @delete="onClickDeleteFile(subprogram.detailedDescription)"
                        @downloadFile="downloadFile('detailedDescription', $event)"
                      />
                    </div>

                    <b-button
                      variant="primary"
                      size="sm"
                      :disabled="steps[4].disabled"
                      @click="openUploadFileModal(1)"
                    >
                      {{ subprogram.detailedDescription.value ? 'Заменить' : 'Добавить' }}
                    </b-button>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="w-50 my-4 insurance-subprograms__files-group">
                    <div class="font-weight-bold mb-2">
                      Шаблон полиса
                    </div>

                    <div
                      v-if="subprogram.policyTemplate.value"
                      class="w-100 mb-4"
                    >
                      <base-file
                        :file="{
                          title: subprogram.policyTemplate.value,
                          guid: subprogram.policyTemplate.key,
                        }"
                        file-api="File/"
                        edit
                        :is-show="false"
                        :disabled="steps[4].disabled"
                        download-permission
                        @delete="onClickDeleteFile(subprogram.policyTemplate)"
                        @downloadFile="downloadFile('policyTemplate', $event)"
                      />
                    </div>

                    <b-button
                      variant="primary"
                      size="sm"
                      :disabled="steps[4].disabled"
                      @click="openUploadFileModal(2)"
                    >
                      {{ subprogram.policyTemplate.value ? 'Заменить' : 'Добавить' }}
                    </b-button>
                  </div>
                  <div class="ml-2 w-50 my-4 insurance-subprograms__files-group">
                    <div class="font-weight-bold mb-2">
                      Дополнительная информация для клиента
                    </div>

                    <div
                      v-if="subprogram.additionalInformationForClients.value"
                      class="w-100 mb-4"
                    >
                      <base-file
                        :file="{
                          title: subprogram.additionalInformationForClients.value,
                          guid: subprogram.additionalInformationForClients.key,
                        }"
                        file-api="File/"
                        edit
                        :is-show="false"
                        :disabled="steps[4].disabled"
                        download-permission
                        @delete="onClickDeleteFile(subprogram.additionalInformationForClients)"
                        @downloadFile="downloadFile('additionalInformationForClients', $event)"
                      />
                    </div>

                    <b-button
                      variant="primary"
                      size="sm"
                      :disabled="steps[4].disabled"
                      @click="openUploadFileModal(3)"
                    >
                      {{ subprogram.additionalInformationForClients.value ? 'Заменить' : 'Добавить' }}
                    </b-button>
                  </div>
                </div>
              </div>

              <div
                class="crm-form-fields"
              >
                <div class="crm-form-column">
                  <div class="font-weight-bold mb-2">
                    Полезные ссылки
                  </div>
                  <div
                    v-if="subprogram.usefulLinks.length"
                    class="crm-form-link-list"
                  >
                    <div
                      v-for="(link, index) in $v.subprogram.usefulLinks.$each.$iter"
                      :key="index"
                      class="crm-form-link"
                    >
                      <b-input
                        v-model="subprogram.usefulLinks[index].link"
                        placeholder="Вставьте ссылку"
                        :state="checkValidations ? $v.subprogram.usefulLinks.$each[index].link.required : null"
                        :disabled="steps[4].disabled"
                      />

                      <b-input
                        v-model="subprogram.usefulLinks[index].description"
                        placeholder="Вставьте название гиперссылки"
                        :disabled="steps[4].disabled"
                      />

                      <b-button
                        variant="danger"
                        size="sm"
                        :disabled="steps[4].disabled"
                        class="ml-2"
                        @click="deleteLink(index)"
                      >
                        Удалить
                      </b-button>
                    </div>
                  </div>
                  <b-button
                    variant="primary"
                    size="sm"
                    :disabled="steps[4].disabled"
                    class="mt-2"
                    @click="addLink"
                  >
                    Добавить ссылку
                  </b-button>
                </div>
              </div>
            </template>
          </base-dropdown>

          <div class="d-flex align-items-center justify-content-between mt-4">
            <div class="">
              <div v-if="subprogram.isActive && subprogram.id">
                <!-- !checkRoleAccess___(['Tech.operator']) -->
                <b-button
                  v-if="checkFeatureAccess({
                    name: 'Возможность деактивировать подпрограмму',
                    url: '/insurance-companies/:company/subprograms/new',
                  })"
                  variant="danger"
                  :disabled="isSaving"
                  @click="deactivationHandler"
                >
                  Деактивировать
                </b-button>
              </div>
            </div>

            <div class="d-flex">
              <b-button
                variant="outline-primary"
                @click="$router.go(-1)"
              >
                Отменить
              </b-button>
              <b-button
                variant="primary"
                :disabled="isSaving"
                class="ml-2"
                @click="onClickSave"
              >
                Сохранить
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { find } from 'lodash';
import { mixinRoles } from '@/mixins';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Bus from '@/eventBus';
import { showMessage } from '@/helpers/utils';
import {
  BaseDropdown,
  BaseCheckbox,
  BaseFile,
  BaseInput,
} from '@/components/base';

import TextEditor from '@/components/common/TextEditor';

import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';

const DOC_TYPES = [
  'documentForClients',
  'detailedDescription',
  'policyTemplate',
  'additionalInformationForClients',
];

const PROGRAMS = [
  {
    id: 0,
    title: 'Выбрать существующую программу',
  },
  {
    id: 1,
    title: 'Создать новую программу',
  },
];

const POLICY_PUROISES = [
  {
    id: 1,
    title: 'Телемедицина',
  },
  {
    id: 2,
    title: 'Диагностика',
  },
  {
    id: 3,
    title: 'Госпитализация',
  },
  {
    id: 4,
    title: 'Лечение',
  },
  {
    id: 5,
    title: 'Роды',
  },
  {
    id: 6,
    title: 'Ведение беременности',
  },
];

const PRODUCT_GROUP = [
  {
    id: 1,
    title: 'ДМС',
  },
  {
    id: 2,
    title: 'Коробочная версия',
  },
  {
    id: 3,
    title: 'Телемедицина',
  },
];

const ACTIVATION_TYPES = [
  {
    id: 0,
    title: 'По номеру полиса',
  },
  {
    id: 1,
    title: 'По страхователю',
  },
  {
    id: 2,
    title: 'Автоматическая активация',
  },
];

const LIMIT_TYPES = [
  {
    id: 1,
    title: 'Общий лимит',
  },
  {
    id: 0,
    title: 'Индивидуальный',
  },
  {
    id: 2,
    title: 'Общий + индивидуальный',
  },
];

export default {
  name: 'InsuranceSubprogramCreatePageDMS',
  page: {
    title: 'Doctis - Создание / редактирование подпрограммы ДМС',
  },
  components: {
    BaseFile,
    BaseDropdown,
    BaseCheckbox,
    BaseInput,
    TextEditor,
    ArrowIcon,
  },
  mixins: [mixinRoles, validationMixin],
  props: {
    initialData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      DOC_TYPES,
      PROGRAMS,
      POLICY_PUROISES,
      PRODUCT_GROUP,
      ACTIVATION_TYPES,
      LIMIT_TYPES,
      name: '',
      isDiscountStatus: false,
      checkValidations: false,
      selectedDiscount: '',
      isDutySpecializationsLoaded: false,
      relatedSubprogramSearch: {
        name: '',
        fields: [
          {
            key: 'id',
            label: '',
            tdClass: 'td--id',
            thClass: 'td--id',
          },
          { key: 'name', label: 'Название' },
        ],
        items: [],
      },
      inputStyleProps: {
        padding: '11px 15px',
        resize: 'none',
      },
      individualLimitsTrim: false,
      isSaving: false,
      companies: [],
      allSpecsChecked: false,
      specializations: [],
      dutySpecializations: [],
      documents: [],
      programs: [],
      programType: 0,
      newProgramName: null,
      productGroup: {
        1: 'ДМС',
        2: 'Коробочная версия',
        3: 'Телемедицина',
      },
      periodTypes: [
        {
          type: 'day',
          text: 'День',
        },
        {
          type: 'week',
          text: 'Неделя',
        },
        {
          type: 'month',
          text: 'Месяц',
        },
        {
          type: 'year',
          text: 'Год',
        },
      ],
      narrowSpecializations: [
        'Аллергический',
        'Гастроэнтерологий',
        'Гинекологический',
        'Кардиологический',
        'Неврологический',
        'Офтальмологический',
        'Терапевтический',
        'Урологический',
        'Эндокринологический',
      ],
      subprogram: {
        isLimitation: false,
        limitationCount: null,
        isActive: true,
        attachmentCost: 0,
        name: null,
        activationType: null,
        ageRestrictions: {
          lowLimit: null,
          highLimit: null,
        },
        percentageDiscount: null,
        fixedDiscount: null,
        isCountConsultationLimit: false,
        companyId: null,
        familyMembersCount: 1,
        indemnityLetterName: null,
        programId: null,
        subProgramType: null,
        limitType: 1,
        individualLimits: [],
        specializations: {
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
        },
        productGroup: [],
        usefulLinks: [],
        recognitions: [],
        narrowSpecializations: [],
        policyPurposes: [],
        limitingConsultationCost: 4500,
        fileName: null,
        fileKey: null,
        isOrOr: false,
        relatedSubprogramIds: [],
        subrelatedSubprogramIds: [],
      },
      steps: {
        1: {
          open: true,
          disabled: false,
        },
        2: {
          open: false,
          disabled: false,
        },
        3: {
          open: false,
          disabled: false,
        },
        4: {
          open: false,
          disabled: false,
        },
        5: {
          open: false,
          disabled: false,
        },
      },
    };
  },
  computed: {
    // isValidLimitsBySpecializations() {
    //   return this.selectedSpecializationsConsultationCount() === this.subprogram.limitationCount;
    // },
    documentData() {
      return this.$store.state.Insurance.documentData;
    },
    statePercentageDiscount() {
      if (this.selectedDiscount === 'A') {
        return this.isDiscountStatus && this.subprogram.percentageDiscount > 0;
      }
      return true;
    },
    stateFixedDiscount() {
      if (this.selectedDiscount === 'B') {
        return this.isDiscountStatus && this.subprogram.fixedDiscount > 0;
      }
      return true;
    },
    invalidFeedback() {
      return 'Пожалуйста введите скидку';
    },
    errorsValidation() {
      const errors = {};

      errors.companyId = [];
      if (!this.$v.subprogram.companyId.required) {
        errors.companyId.push('Выберите один из вариантов');
      }

      errors.programType = [];
      if (!this.$v.programType.required) {
        errors.programType.push('Выберите один из вариантов');
      }

      errors.specializations = [];
      if (!this.$v.subprogram.specializations.required) {
        errors.specializations.push('Выберите один из вариантов');
      }

      errors.programId = [];
      if (!this.$v.subprogram.programId.required) {
        errors.programId.push('Выберите один из вариантов');
      }

      errors.newProgramName = [];
      if (!this.$v.newProgramName.required) {
        errors.newProgramName.push('Поле не может быть пустым');
      }

      errors.name = [];
      if (!this.$v.subprogram.name.required) {
        errors.name.push('Поле не может быть пустым');
      }

      errors.attachmentCost = [];
      if (!this.$v.subprogram.attachmentCost.isValid) {
        errors.attachmentCost.push('Значение должно быть больше или равно нулю');
      }

      errors.limitingConsultationCost = [];
      if (!this.$v.subprogram.limitingConsultationCost.isValid) {
        errors.limitingConsultationCost.push('Значение должно быть больше нуля');
      }

      errors.policyPurposes = [];
      if (!this.$v.subprogram.policyPurposes.required) {
        errors.policyPurposes.push('Выберите один из вариантов');
      }

      errors.productGroup = [];
      if (!this.$v.subprogram.productGroup.required) {
        errors.productGroup.push('Выберите один из вариантов');
      }

      errors.lowLimit = [];
      if (!this.$v.subprogram.ageRestrictions.lowLimit.isValid) {
        errors.lowLimit.push('Нижняя граница должна быть больше или равно нулю');
      }

      errors.highLimit = [];
      if (!this.$v.subprogram.ageRestrictions.highLimit.isValid) {
        errors.highLimit.push('Верхняя граница должна быть больше, чем нижняя граница');
      }

      errors.familyMembersCount = [];
      if (!this.$v.subprogram.familyMembersCount.isValid) {
        errors.familyMembersCount.push('Значение не должно быть меньше 0');
      }

      errors.activationType = [];
      if (!this.$v.subprogram.activationType.required) {
        errors.activationType.push('Выберите один из вариантов');
      }

      errors.limitationCount = [];
      if (!this.$v.subprogram.limitationCount.isValid) {
        errors.limitationCount.push('Укажите значение больше 0');
      }

      errors.subProgramType = [];
      if (!this.$v.subprogram.subProgramType.required) {
        errors.subProgramType.push('Выберите один из вариантов');
      }

      errors.selectedDiscount = [];
      if (!this.$v.selectedDiscount.required) {
        errors.selectedDiscount.push('Выберите один из вариантов');
      }

      // errors.indemnityLetterName = [];
      // if (!this.$v.indemnityLetterName.required) {
      //   errors.indemnityLetterName.push('Поле не может быть пустым');
      // }

      return errors;
    },
    individualLimitsList() {
      const limits = {};

      for (let i = 0; i < Number(this.subprogram.familyMembersCount); i += 1) {
        if (this.subprogram.individualLimits[i]) {
          limits[`${i + 1}`] = this.subprogram.individualLimits[i];
        } else {
          limits[`${i + 1}`] = 0;
        }
      }

      if (Object.keys(limits).length) return limits;

      return false;
    },
    individualLimitsError() {
      if (
        !this.individualLimitsList
        || this.subprogram.subProgramType !== 1
      ) return;

      const isRequired = {
        text: 'Лимиты не могут быть пустыми или равными нулю',
        cb: (arr) => !(arr.some((item) => !item)),
      };

      const notBelowZero = {
        text: 'Лимиты не могут быть ниже нуля',
        cb: (arr) => !(arr.some((item) => item < 0)),
      };

      const sumIsEqual = {
        text: 'Сумма всех лимитов должна быть равна значению, введенному в поле "Сумма страхового покрытия" (Шаг 3)',
        cb: (arr, value) => {
          const sum = arr.reduce((acc, item) => {
            acc += Number(item);
            return acc;
          }, 0);

          return Number(value) === Number(sum);
        },
      };

      const validations = [isRequired, notBelowZero, sumIsEqual];

      const errors = [];
      validations.forEach((item) => {
        if (!(item.cb(Object.values(this.individualLimitsList), this.subprogram.insuranceCoverSum))) errors.push(item);
      });

      if (errors.length) return errors;

      return false;
    },
  },
  watch: {
    // эта функция запускается при любом изменении вопроса
    isDiscountStatus() {
      if (this.isDiscountStatus === false) {
        this.subprogram.fixedDiscount = null;
        this.subprogram.percentageDiscount = null;
        this.selectedDiscount = '';
        this.$v.selectedDiscount.$reset();
      }
    },
    documentData() {
      this.setDocumentForClients();
    },
    selectedDiscount() {
      if (this.selectedDiscount === 'A') this.subprogram.fixedDiscount = null;
      if (this.selectedDiscount === 'B') this.subprogram.percentageDiscount = null;
    },
  },
  validations() {
    return {
      programType: { required },
      selectedDiscount: { required: this.isDiscountStatus ? required : () => true },
      newProgramName: {
        required: this.programType === 1 ? required : () => true,
      },
      subprogram: {
        indemnityLetterName: { },
        activationType: { required },
        limitationCount: {
          isValid: !this.subprogram.isLimitation ? () => true : (value) => value !== null && Number(value) > 0,
        },
        limitingConsultationCost: {
          isValid: (value) => Number(value) > 0,
        },
        attachmentCost: {
          isValid: (value) => Number(value) >= 0,
        },
        ageRestrictions: {
          lowLimit: {
            isValid: (value) => value !== null && Number(value) >= 0,
          },
          highLimit: {
            isValid: (value) => Number(value) > Number(this.subprogram.ageRestrictions.lowLimit),
          },
        },
        companyId: { required },
        familyMembersCount: {
          isValid: this.subprogram.subProgramType === 0 ? () => true : (value) => Number(value) >= 0,
        },
        programId: {
          required: this.programType === 0 ? required : () => true,
        },
        subProgramType: { required },
        limitType: {
          required: this.subprogram.subProgramType === 1 ? () => required : () => true,
        },
        name: { required },
        specializations: {
          required: (value) => Object.keys(value).length > 0,
        },
        policyPurposes: {
          required: (value) => value.length > 0,
        },
        productGroup: {
          required: (value) => value.length > 0,
        },
        usefulLinks: {
          $each: {
            link: { required },
          },
        },
        recognitions: {
          // required,
          $each: {
            required,
          },
        },
      },
    };
  },
  async created() {
    if (this.initialData) {
      this.initSteps();

      const specializations = { ...this.initialData.specializations };
      Object.keys(specializations).map((key) => {
        const value = +specializations[key];
        if (value === 999) specializations[key] = null;
        return null;
      });

      this.subprogram = {
        ...this.initialData,
        usefulLinks: this.initialData.usefulLinks || [],
        recognitions: this.initialData.recognitions || [],
        narrowSpecializations: this.initialData.narrowSpecializations || [],
        specializations,
        relatedSubprogramIds: this.initialData.relatedSubprograms?.map((s) => s.subprogramId) || [],
        subrelatedSubprogramIds: this.initialData.subrelatedSubprograms?.map((s) => s.subprogramId) || [],
        isOrOr: !!(this.initialData.relatedSubprograms?.length || this.initialData.subrelatedSubprograms?.length),
        companyId: +this.$route.params.company,
      };

      if (this.initialData.relatedSubprograms) {
        this.initialData.relatedSubprograms.forEach((s) => {
          this.relatedSubprogramSearch.items.push({ id: s.subprogramId, name: s.name });
        });
      }

      if (
        this.subprogram.individualLimits
        && Object.values(this.subprogram.individualLimits).length
      ) {
        this.subprogram.individualLimits = Object.values(this.subprogram.individualLimits);
      }

      if (this.subprogram.percentageDiscount !== null || this.subprogram.fixedDiscount !== null) {
        this.isDiscountStatus = true;
        this.selectedDiscount = this.subprogram.percentageDiscount !== null ? 'A' : 'B';
      }
      const documentsName = await Promise.all(this.subprogram.documentIds
        .map((docId) => this.$store.dispatch(this.$types.FILENAME_BY_ID_FETCH, docId)));

      this.documents = documentsName.map((title, i) => ({ title, guid: this.subprogram.documentIds[i] }));

      this.fetchSubprogramsForRelate();
    }
    this.subprogram.companyId = +this.$route.params.company;
    this.updatePrograms();

    const companies = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.companies = companies.map((comp) => comp.company);

    let specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
    specializations = specializations.map((spec) => ({ ...spec, active: this.subprogram.specializations[spec.id] !== undefined }));

    this.dutySpecializations = specializations.filter((spec) => spec.name.includes('Дежурный'));
    this.specializations = specializations.filter((spec) => !spec.name.includes('Дежурный'));

    // Добавление всех дежурных врачей
    Object.keys(this.dutySpecializations).forEach((key) => {
      const doctorId = this.dutySpecializations[key].id;
      this.subprogram.specializations[doctorId] = null;
    });
    this.isDutySpecializationsLoaded = true;
  },
  beforeDestroy() {
    this.$store.commit(this.$types.SUBPROGRAM_DOC_DATA_SET, {});
  },
  methods: {
    initSteps() {
      // if (this.checkRoleAccess_(['Tech.operator'])) {
      //   this.steps = {
      //     1: {
      //       open: false,
      //       disabled: true,
      //     },
      //     2: {
      //       open: false,
      //       disabled: true,
      //     },
      //     3: {
      //       open: false,
      //       disabled: true,
      //     },
      //     4: {
      //       open: true,
      //       disabled: false,
      //     },
      //   };
      // }
      if (this.checkFeatureAccess({
        name: 'Возможность редактировать шаг 1',
        url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
      })) {
        this.steps['1'] = {
          open: true,
          disabled: false,
        };
      } else {
        this.steps['1'] = {
          open: false,
          disabled: true,
        };
      }
      if (this.checkFeatureAccess({
        name: 'Возможность редактировать шаг 2',
        url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
      })) {
        this.steps['2'] = {
          open: false,
          disabled: false,
        };
      } else {
        this.steps['2'] = {
          open: false,
          disabled: true,
        };
      }
      if (this.checkFeatureAccess({
        name: 'Возможность редактировать шаг 3',
        url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
      })) {
        this.steps['3'] = {
          open: false,
          disabled: false,
        };
      } else {
        this.steps['3'] = {
          open: false,
          disabled: true,
        };
      }
      if (this.checkFeatureAccess({
        name: 'Возможность редактировать шаг 4',
        url: '/insurance-companies/:company/programs/:program/subprograms/:subprogram/edit',
      })) {
        this.steps['4'] = {
          open: true,
          disabled: false,
        };
      } else {
        this.steps['4'] = {
          open: false,
          disabled: true,
        };
      }
    },
    toggleStepShowStatus(index) {
      this.steps[index].open = !this.steps[index].open;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.subprogram[name];
      return $dirty ? !$error : null;
    },
    setDocumentForClients() {
      this.subprogram[this.DOC_TYPES[this.documentData.docType]].value = this.documentData.fileName;
      this.subprogram[this.DOC_TYPES[this.documentData.docType]].key = this.documentData.fileKey;
    },
    isSpecLimitOverflow() {
      const maxLimit = this.subprogram.limitationCount && this.selectedSpecializationsConsultationMaxLimit();
      return maxLimit > this.subprogram.limitationCount;
    },
    async fetchSubprogramsForRelate() {
      const subprograms = await this.$store.dispatch(this.$types.SUBPROGRAMS_FOR_RELATE_FETCH, this.subprogram.programId);
      subprograms
        .filter((s) => !s.isHidden && s.id !== this.subprogram.id)
        .forEach((s) => this.relatedSubprogramSearch.items.push(s));
    },
    onProgramChange() {
      this.relatedSubprogramSearch.items = [];
      this.subprogram.relatedSubprogramIds = [];
      this.subprogram.subrelatedSubprogramIds = [];

      if (this.initialData.programId === this.subprogram.programId && this.initialData.relatedSubprograms?.length) {
        this.initialData.relatedSubprograms.forEach((s) => {
          this.relatedSubprogramSearch.items.push({ id: s.subprogramId, name: s.name });
        });

        this.subprogram.relatedSubprogramIds = this.initialData.relatedSubprograms?.map((s) => s.subprogramId) || [];
        this.subprogram.subrelatedSubprogramIds = this.initialData.subrelatedSubprograms?.map((s) => s.subprogramId) || [];
      }

      this.fetchSubprogramsForRelate();
    },
    // isSpecLimitEqualOrOverflow() {
    //   return this.subprogram.limitationCount && this.selectedSpecializationsConsultationCount() >= this.subprogram.limitationCount;
    // },
    // checkSpecLimit() {
    //   if (this.dutySpecializations
    //     && this.subprogram.isLimitation
    //     // && this.subprogram.limitationCount
    //     && this.isSpecLimitEqualOrOverflow()) {
    //     this.uncheckSpecializationsWithoutLimit();
    //   }
    // },
    selectedSpecializationsConsultationMaxLimit() {
      const onlySpec = this.getSelectedSpecializationsWithoutDuty();
      let maxLimit = 0;
      onlySpec.forEach((item) => {
        const currentSpecLimit = this.subprogram.specializations[item];
        if (this.subprogram.specializations[item] && currentSpecLimit > maxLimit) {
          maxLimit = currentSpecLimit;
        }
      });
      return maxLimit;
    },
    // selectedSpecializationsConsultationCount() {
    //   const onlySpec = this.getSelectedSpecializationsWithoutDuty();
    //   let computedLimits = 0;
    //   onlySpec.forEach((item) => {
    //     const currentSpecLimit = this.subprogram.specializations[item];
    //     if (this.subprogram.specializations[item]) {
    //       computedLimits += currentSpecLimit;
    //     }
    //   });
    //   return computedLimits;
    // },
    uncheckSpecializationsWithoutLimit() {
      // this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };

      // const specToUncheck = this.getSelectedSpecializationsWithoutDuty().filter((specId) => (!this.subprogram.specializations[specId]));
      // specToUncheck.forEach((specId) => {
      //   this.$delete(this.subprogram.specializations, specId);
      // });
      // this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };
      // console.log(specToUncheck);
      this.specializations = this.arraySpecsUncheckedOnlyNotLimitedToggle(this.specializations);
      this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };
    },
    getSelectedSpecializationsWithoutDuty() {
      return Object.keys(this.subprogram.specializations).filter((item) => (!find(this.dutySpecializations, { id: parseInt(item, 10) })));
    },
    onFocusSelectDiscount(item) {
      if (item === 'B') {
        this.subprogram.percentageDiscount = null;
        this.selectedDiscount = 'B';
      }
      if (item === 'A') {
        this.subprogram.fixedDiscount = null;
        this.selectedDiscount = 'A';
      }
    },
    deactivationCallback() {
      this.$router.go(-1);
    },
    deactivationHandler() {
      Bus.$emit('open-modal:insurance-sub-program-deactivation', {
        subprogram: this.subprogram,
        deactivationCallback: this.deactivationCallback,
      });
    },
    // changeProgramType(val) {
    //   if (val === 1) {
    //     this.indemnityLetterName = null;
    //   } else {
    //     this.setIndemnityLetterNameFromSelectedProgram();
    //   }
    // },
    getSelectedSubprogram() {
      return find(this.programs, { id: this.subprogram.programId });
    },
    // setIndemnityLetterNameFromSelectedProgram() {
    //   if (this.subprogram.programId) {
    //     const selectedSubprogram = this.getSelectedSubprogram();
    //     // console.log(selectedSubprogram);
    //     if (selectedSubprogram) {
    //       this.indemnityLetterName = selectedSubprogram.indemnityLetterName;
    //     }
    //   }
    // },
    addLink() {
      this.subprogram.usefulLinks.push({
        id: 0,
        subprogramId: 0,
        link: '',
        description: '',
      });
    },
    deleteLink(index) {
      this.subprogram.usefulLinks.splice(index, 1);
    },
    addRecognition() {
      this.subprogram.recognitions.push('');
    },
    deleteRecognition(index) {
      this.subprogram.recognitions.splice(index, 1);
    },
    toggleConsultationLimit(val) {
      if (!val) {
        this.subprogram.limitationCount = null;
      } else {
        // this.checkSpecLimit(true);
      }
    },
    toggleSpecialization(value, spec) {
      this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };

      if (value) {
        this.subprogram.specializations[spec.id] = null;
        return;
      }

      this.$delete(this.subprogram.specializations, spec.id);
    },
    arraySpecsUncheckedOnlyNotLimitedToggle(array) {
      return array.map((spec) => {
        const newSpec = { ...spec };
        if (!this.subprogram.specializations[spec.id]) {
          this.$delete(this.subprogram.specializations, spec.id);
          newSpec.active = false;
        }
        return newSpec;
      });
    },
    toggleAllSpecs() {
      const arraySpecsToggle = (array) => array.map((spec) => {
        if (!this.allSpecsChecked && !this.subprogram.specializations[spec.id]) {
          this.subprogram.specializations[spec.id] = null;
        }

        if (this.allSpecsChecked) {
          this.$delete(this.subprogram.specializations, spec.id);
        }

        const newSpec = { ...spec };
        newSpec.active = !this.allSpecsChecked;
        return newSpec;
      });

      // this.dutySpecializations = arraySpecsToggle(this.dutySpecializations);
      this.specializations = arraySpecsToggle(this.specializations);

      this.allSpecsChecked = !this.allSpecsChecked;
      this.$v.subprogram.specializations.$model = { ...this.subprogram.specializations };
    },
    onClickDeleteFiles(file) {
      this.documents = this.documents.filter((doc) => doc.guid !== file.guid);
    },
    saveFilesCallback(newFilesArray) {
      newFilesArray.map((fileObject) => {
        this.documents.push({
          title: fileObject.name,
          guid: fileObject.responseData?.guid,
        });
        return null;
      });
    },
    onClickOpenFilesModal() {
      // Bus.$emit('open-modal:file-upload', { saveFileCallback: this.saveFilesCallback });
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'FilesUploadModal',
        props: {
          multiple: true,
          title: 'Загрузка документов',
          accept: '.pdf',
          saveFileCallback: this.saveFilesCallback,
        },
      });
    },
    saveFileCallback(data) {
      console.log(data);
      this.subprogram.fileName = data[0].name;
      // this.subprogram.fileKey = data[0].guid;
    },
    onClickOpenFileModal(docType) {
      Bus.$emit('open-modal:file-upload', {
        fileApi: `v2/insuranceCompanies/programs/subprogram/uploadFile?subProgramId=${this.subprogram.id}&type=${docType}`,
        saveFileCallback: this.saveFileCallback,
        multiple: false,
        type: '.pdf',
      });
    },
    openUploadFileModal(docType) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InsuranceSubprogramUploadFile',
        props: {
          subProgramId: this.subprogram.id,
          docType,
        },
      });
    },
    onClickDeleteFile(item) {
      item.value = null;
      item.key = null;
    },
    changeCompany() {
      this.subprogram.programId = null;
      // this.indemnityLetterName = null;
      this.updatePrograms();
    },
    async updatePrograms() {
      const programs = await this.$store.dispatch(this.$types.PROGRAMS_FETCH, {
        idCompany: this.subprogram.companyId,
      });
      this.programs = programs.map((program) => program.program);
      // this.setIndemnityLetterNameFromSelectedProgram();
    },
    async onClickSave() {
      this.$v.$touch();
      this.checkValidations = true;
      this.individualLimitsTrim = true;
      if (this.$v.$error || (!this.stateFixedDiscount || !this.statePercentageDiscount)) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      if (this.subprogram.isLimitation
        && this.isSpecLimitOverflow()
      ) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Превышен общий лимит консультаций в одном из врачей',
        });
        return;
      }

      if (
        this.subprogram.subProgramType === 1
        && this.subprogram.limitType === 0
        && this.individualLimitsError
        && this.individualLimitsError.length
      ) {
        this.individualLimitsError.forEach((item) => {
          showMessage({
            type: 'warning',
            title: 'Ошибка валидации. Шаг 3. Суммы лимитов',
            message: item.text,
          });
        });

        return;
      }

      this.isSaving = false;
      try {
        if (this.programType === 1) {
          const program = {
            name: this.newProgramName,
            description: '',
            isActivated: true,
            indemnityLetterName: this.subprogram.indemnityLetterName,
          };
          const programId = await this.$store.dispatch(this.$types.PROGRAM_CREATE, { companyId: this.subprogram.companyId, program });

          this.subprogram.programId = programId;
        } else {
          const selectedSubprogramm = this.getSelectedSubprogram();

          if (selectedSubprogramm.indemnityLetterName !== this.subprogram.indemnityLetterName) {
            const params = {
              ...selectedSubprogramm,
              indemnityLetterName: this.subprogram.indemnityLetterName,
            };
            await this.$store.dispatch(this.$types.PROGRAM_UPDATE, params);
          }
        }

        const specializations = { ...this.subprogram.specializations };
        Object.keys(specializations).map((key) => {
          const value = +specializations[key];
          if (value <= 0) specializations[key] = 999;
          return null;
        });
        // const familyMembersCount = this.subprogram.familyMembersCount > 0 ? this.subprogram.familyMembersCount : 999;
        const documentIds = this.documents.map((doc) => doc.guid);

        const subprogramData = {
          ...this.subprogram,
          limitationCount: this.subprogram.limitationCount <= 0 ? null : this.subprogram.limitationCount,
          specializations,
          documentIds,
        };

        // переопределяем индивидуальные лимиты
        delete subprogramData.individualLimits;
        if (this.subprogram.subProgramType === 1 && this.individualLimitsList) {
          if (this.subprogram.limitType === 0) {
            // индивидуальный
            subprogramData.individualLimits = this.individualLimitsList;
          }
        }

        if (!subprogramData.isOrOr) {
          subprogramData.relatedSubprogramIds = [];
          subprogramData.subrelatedSubprogramIds = [];
        }

        // удаляем поля которые не должны быть в запросе
        delete subprogramData.generalWithIndividualLimits;
        delete subprogramData.generalLimitForPerson;

        if (this.subprogram.id) {
          await this.$store.dispatch(this.$types.SUBPROGRAM_UPDATE, {
            subprogram: subprogramData,
            companyId: subprogramData.companyId,
            programId: subprogramData.programId,
          });
          this.$router.go(-1);
        } else {
          await this.$store.dispatch(this.$types.SUBPROGRAM_CREATE, {
            subprogram: subprogramData,
            companyId: subprogramData.companyId,
            programId: subprogramData.programId,
          });
        }
        this.$router.push('/insurance-companies');
      } finally {
        this.isSaving = false;
      }
    },
    async downloadFile(fileKey, fileName) {
      try {
        const blob = await this.$store.dispatch(this.$types.DOWNLOAD_SUBPROGRAM_DOCUMENTS, {
          subProgramId: this.subprogram.id,
          type: DOC_TYPES.findIndex((item) => item === fileKey),
        });
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', fileName);
        // eslint-disable-next-line no-restricted-globals
        onload = link.click();
        link.remove();
      } catch (e) {
        console.error(e);
      }
    },
  },
  // watch: {
  //   'subprogram.programId': {
  //     handler() {
  //       this.setIndemnityLetterNameFromSelectedProgram();
  //     },
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.insurance-subprograms {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;

  &__files-group {
    padding-bottom: 15px;
    border-bottom: 1px solid #ececec;
  }
}

.file {
  max-width: 600px;
}

.insurance-subprograms-inner {
  padding: 30px 0;
}
.crm-container-title {
  margin-bottom: 40px;
  display: flex;

  .arrow-left {
    margin-right: 15px;
  }
}
.crm-title {
  @extend .page-title;
}

::v-deep.crm-title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}
.bottom-btns-container {
  margin-top: 30px;
}
::v-deep.base-dropdown-wrapper {
  margin: 0;
  & + .base-dropdown-wrapper {
    margin-top: 30px;
  }
}
::v-deep.crm-icon-cross {
  margin-right: 15px;
}

::v-deep.crm-button {
  &.width-unset {
    width: unset;
    padding-left: 15px;
    padding-right: 15px;
    flex-shrink: 0;
  }

  &.secondary {
    .crm-icon-cross {
      path {
        fill: $blue;
      }
    }
  }

  &.select-all-specs {
    margin-left: auto;
    padding: 10px 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &.deactivate {
    margin-right: auto;
  }

  &.cancel {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.red-text {
  color: $red;
}
.selected-limits {
  margin-top: 5px;
}
.btns-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.crm-form {
  background-color: #fff;
  width: 100%;
  padding: 30px;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;

  &-row {
    display: flex;
    margin: 0 -25px;
    flex-wrap: wrap;

    .crm-form-checkbox {
      margin: 0 25px 10px;

      &--narrow-specializations {
        flex-basis: calc(25% - 50px);
      }
    }
  }
}

::v-deep.crm-form-step {
  .crm-dropdown-text {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
  }
}

.crm-form-fields + .crm-form-fields {
  margin-top: 30px;
}

.crm-form-column {
  flex: 1;
  & + & {
    margin-left: 30px;
  }

  &.flex-3 {
    flex: 3
  }
}

::v-deep.crm-form-field {
  &.align-end {
    align-items: flex-end;
  }

  &.align-self-end{
    align-self: flex-end;
  }

  .crm-label {
    margin-bottom: 15px;
  }

  .divide-line {
    align-self: center;
    height: 1px;
    width: 30px;
    background-color: #323232;
    flex-shrink: 0;
    margin: 0 20px;
  }
}

::v-deep.crm-form-input {
  &-prefix {
    align-self: center;
    margin-right: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  &-postfix {
    align-self: center;
    margin-left: 10px;

    &.crm-bold {
      font-weight: 500;
    }
  }

  & + .crm-form-input {
    margin-left: 20px;
  }
}

.crm-radio-buttons {
  position: relative;
  display: flex;

  .crm-radio-button {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }

  &.vertical {
    flex-direction: column;

    .crm-radio-button {
      &:not(:first-child) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }
}

.crm-form-link {
  margin: 0 0 30px;
  width: 100%;
  display: flex;
  align-items: center;

  &-list {
    display: flex;
    flex-wrap: wrap;
  }

  &-delete {
    margin-left: 15px;
    cursor: pointer;
  }

  .crm-wrap-block {
    flex: 1;
    margin-right: 20px;
  }
}

.crm-form-subtitle {
  min-height: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 15px;
}

.crm-form-input-select-period {
  width: 140px;
}

.consultation-coast {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

::v-deep.crm-form-checkbox {
  span {
    align-items: unset!important;
  }

  .crm-form-checkbox-text {
    margin-top: 2px;
    font-size: 14px;
    line-height: 18px;
    word-break: break-word;
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc((100% - 40px)/3) );
    grid-row-gap: 10px;
    grid-column-gap: 20px;
  }

  &-row + &-row {
    margin-top: 10px;
  }

  &-inner, &-consultation-limit {
    display: flex;
    justify-content: space-between;

    .crm-checkbox-input {
      align-self: center;
      margin-left: 20px;
    }
  }

  &-consultation-limit {
    justify-content: start;

    .crm-checkbox-input {
      width: 200px;
    }
    input {
      width: 100px !important;
    }
  }
}

.validation-errors{
  &.margin-left{
    margin-left: 20px;
  }
  &.position-static {
    position: static;
  }
}

.crm-form-label-row {
  display: flex;
}

.crm-form-label {
  font-size: 16px;
  font-weight: 500;
}

::v-deep.crm-file-list {
  margin: 0 -10px 30px;
  flex: 1;

  .file {
    margin: 20px 10px;
    width: auto;
    word-break: break-word;
  }
}

.position-static {
  position: static;
}

.discountLabel{
  font-size: 16px;
  font-weight: 500;
}
.discountRequired {
  font-size: 12px;
  color: #EB5757;
  margin-left: 5px;
}

::v-deep.related-subprograms-table {
  .td--id {
    width: 10px;
  }
}
</style>
