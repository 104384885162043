import NProgress from 'nprogress/nprogress';
// import axios from 'axios';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.SPECIALIZATIONS_FETCH]({ commit }, query) {
    try {
      const { data: specializations } = await api.get(`v2/specializations/find${query ? `?search=${query}` : ''}`);
      commit(localTypes.SPECIALIZATIONS_SET, specializations);
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить список специализаций',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATION_FETCH_BY_ID](ctx, specializationId) {
    try {
      const { data: specialization } = await api.get(`v2/specializations?specializationId=${specializationId}`);
      return specialization;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось загрузить специализацию',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATION_CREATE](ctx, specialization) {
    NProgress.start();

    try {
      await api.post('v2/specializations', specialization);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Специализация успешно добавлена!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATION_UPDATE](ctx, specialization) {
    NProgress.start();

    try {
      await api.put('v2/specializations', specialization);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Специализация успешно изменена!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.SPECIALIZATION_DELETE](ctx, id) {
    NProgress.start();

    try {
      await api.delete(`v2/specializations?specializationId=${id}`);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Специализация успешно удалена!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
};
