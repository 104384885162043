<template>
  <div class="task-tracker-page">
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      class="h-100"
      @load-more-items="loadMoreItems"
    >
      <b-container
        fluid="xl"
      >
        <div class="h3 mb-4 mt-4">
          Задачи
        </div>

        <b-row class="mb-4">
          <b-col class="w-100">
            <b-input-group class="bg-white border border-primary rounded">
              <b-form-input
                v-model="filter.searchString"
                type="text"
                debounce="500"
                placeholder="Введите текст задачи"
                class="border-0"
              />

              <b-dropdown
                id="dropdown-form"
                ref="dropdown"
                variant="outline-primary"
                no-caret
                right
                toggle-class="rounded-0 border-0"
                offset="25,5"
              >
                <template #button-content>
                  <b-icon icon="filter" />
                </template>

                <div
                  class="px-4 py-2"
                  :style="{ width: '300px' }"
                >
                  <b-form-group>
                    <div class="fs-5 mb-3">
                      Статус
                    </div>
                    <b-form-radio
                      v-for="status in statusList"
                      :key="status.value"
                      v-model="filter.status"
                      name="status"
                      :value="status.value"
                      class="mt-2"
                    >
                      <div class="ms-1 pt-1">
                        {{ status.text }}
                      </div>
                    </b-form-radio>
                  </b-form-group>

                  <div class="fs-5 mt-4 mb-3">
                    Дата
                  </div>
                  <base-date-picker v-model="filter.createDate" />
                </div>
              </b-dropdown>
              <b-button
                variant="outline-primary"
                class="rounded-0 border-0"
                @click="tasksFetch(true)"
              >
                <b-icon icon="search" />
              </b-button>
            </b-input-group>
          </b-col>

          <b-col sm="auto">
            <b-button
              variant="primary"
              @click="openNewTaskModal"
            >
              <b-icon icon="plus" />
              Новая задача
            </b-button>
          </b-col>
        </b-row>

        <div
          v-if="!totalCount && isAllItemsLoaded"
          class="px-3"
        >
          Ничего не найдено
        </div>

        <b-table
          v-if="totalCount"
          ref="selectableTable"
          class="bg-white"
          bordered
          hover
          select-mode="single"
          selectable
          :fields="fields"
          :items="tasks"
          @row-selected="onRowSelected"
        />
      </b-container>
    </async-list>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import {
  format,
  formatISO,
  // parseISO,
  // isEqual,
} from 'date-fns';
import { onBInputDatePaste, dateWithoutTime } from '@/helpers/utils';

import { mixinRoles } from '@/mixins';

import AsyncList from '@/components/AsyncList';
import { BaseDatePicker } from '@/components/base';

export default {
  name: 'Tasks',
  page: {
    title: 'CRM Doctis - Задачи',
  },
  components: {
    AsyncList,
    BaseDatePicker,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isCloseble: false,
      selectedRows: [],
      fields: [
        { key: 'id', label: 'ID' },
        {
          key: 'status',
          label: 'Статус',
          formatter: (value) => value != null && this.statusList.find((s) => s.value === value)?.text,
        },
        {
          key: 'createDate',
          label: 'Дата создания',
          formatter: (value) => value != null && format(new Date(value), 'dd.MM.yyyy'),
        },
        { key: 'body', label: 'Текст задачи' },
        {
          key: 'responsibleId',
          label: 'Ответственный',
          formatter: (id) => (id != null && this.responsibleList.find((r) => r.id === id)?.displayName) || '',
        },
        {
          key: 'performer',
          label: 'Исполнитель',
          formatter: (value) => (value != null && this.performerList.find((p) => p.value === value)?.text) || '',
        },
      ],
      statusList: [
        {
          value: null,
          text: 'Не выбран',
        },
        {
          value: 0,
          text: 'Новое',
        },
        {
          value: 1,
          text: 'В работе',
        },
        {
          value: 2,
          text: 'Возвращена',
        },
        {
          value: 3,
          text: 'Передано',
        },
        {
          value: 4,
          text: 'Выполнено',
        },
        {
          value: 5,
          text: 'Заархивировано',
        },
        {
          value: 6,
          text: 'В ожидании',
        },
      ],
      performerList: [
        {
          value: 0,
          text: 'Договорной отдел',
        },
        {
          value: 1,
          text: 'Техподдержка',
        },
        {
          value: 2,
          text: 'Врач-куратор',
        },
        {
          value: 3,
          text: 'Колл-центр',
        },
      ],
      limit: 15,
      fetchDelay: 700,
      // responsibleList: [],
    };
  },
  computed: {
    tasks() {
      return this.$store.state.TaskTracker.tasks;
    },
    responsibleList() {
      return this.$store.state.Account.users;
    },
    totalCount() {
      return this.tasks.length;
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.TaskTracker.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.commit(this.$types.TASK_TRACKER_IS_ALL_ITEMS_LOADED_SET, newVal);
      },
    },
    isLoading: {
      get() {
        return this.$store.state.TaskTracker.isLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.TASK_TRACKER_IS_LOADING_SET, newVal);
      },
    },
    filter: {
      get() {
        return this.$store.state.TaskTracker.filter;
      },
      set(newVal) {
        this.$store.commit(this.$types.TASK_TRACKER_FILTER_SET, newVal);
      },
    },
  },
  watch: {
    filter: {
      handler() {
        this.tasksFetchDebounced(true);
      },
      deep: true,
    },
  },
  async created() {
    await this.$store.dispatch(this.$types.ACCOUNT_USERS_FETCH, { take: 999, save: true });
    this.tasksFetchDebounced = debounce(this.tasksFetch, this.fetchDelay);
  },
  beforeDestroy() {
    this.$store.commit(this.$types.TASK_TRACKER_TASKS_SET, []);
    this.isAllItemsLoaded = false;
  },
  methods: {
    onPaste(e) {
      onBInputDatePaste(e);
    },
    async tasksFetch(clear) {
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const filter = { ...this.filter };
      if (filter.createDate) filter.createDate = dateWithoutTime(formatISO(filter.createDate));

      try {
        await this.$store.dispatch(this.$types.TASK_TRACKER_FETCH, {
          skip: prevTotal,
          take: this.limit,
          filter,
          clear: !!clear,
          save: true,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
        this.isAllItemsLoaded = true;
      } finally {
        this.isLoading = false;
      }
    },
    loadMoreItems() {
      if (!this.isLoading) {
        this.tasksFetch(false);
      }
    },
    openNewTaskModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'TaskViewModal' });
    },
    openTaskModal(id) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'TaskViewModal', props: { taskId: id } });
    },
    onRowSelected(rows) {
      const taskId = rows[0]?.id;
      if (taskId) this.openTaskModal(taskId);
      this.$refs.selectableTable.clearSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
.task-tracker-page {
  height: 100vh;
  width: 100%;
}

</style>
