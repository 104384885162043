<template>
  <div>
    <base-tabs
      v-model="activeTab"
      :items="filteredTabs"
      :disabled="tabsDisableCond"
      class="crm-tabs"
    />
    <!-- <div class="clinics-tab-content"> -->
    <!-- <custom-scrollbar ref="scrollbar"> -->
    <component
      :is="activeTab"
      :id="id"
      @create-clinic="onCreateClinic"
    />
    <!-- </custom-scrollbar> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { mixinRoles } from '@/mixins';

/* eslint-disable vue/no-unused-components */
import { BaseTabs } from '@/components/base';
import ClinicEdit from './ClinicEdit';
import ClinicDoctorsEdit from './ClinicDoctorsEdit';
import ClinicPhotoEdit from './ClinicPhotoEdit';

export default {
  name: 'ClinicEditModal',
  components: {
    BaseTabs,
    ClinicEdit,
    ClinicDoctorsEdit,
    ClinicPhotoEdit,
  },
  mixins: [mixinRoles],
  props: {
    clinicId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      id: this.clinicId,
      tabs: [
        {
          title: 'Данные клиники',
          value: 'ClinicEdit',
        },
        {
          title: 'Врачи клиники',
          value: 'ClinicDoctorsEdit',
        },
        {
          title: 'Фото клиники',
          value: 'ClinicPhotoEdit',
        },
      ],
      activeTab: 'ClinicEdit',
    };
  },
  computed: {
    ...mapGetters({
      isChangedDoctorsInfo: types.GET_IS_CHANGED_DOCTORS_INFO,
      isChangedInfo: types.GET_IS_CHANGED_INFO,
    }),
    tabsDisableCond() {
      return !this.id || this.isChangedDoctorsInfo || this.isChangedInfo;
    },
    filteredTabs() {
      return this.tabs;
    },
  },
  watch: {
    activeTab() {
      this.scrollTo({ y: 0 }, 300);
    },
  },
  methods: {
    onCreateClinic(id) {
      this.id = id;
      this.activeTab = 'ClinicDoctorsEdit';
    },
    scrollTo(position, duration = 0) {
      const { scrollbar } = this.$refs;

      if (scrollbar) scrollbar.scrollTo(position, duration);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-tabs {
  @extend .clinics-tabs;
}
</style>
