<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
      class="py-4"
    >
      <div class="h3 mb-4 mr-auto">
        Склейка услуг
      </div>

      <!--      <b-input-group class="rounded p-2 bg-white">-->
      <!--        <b-input-->
      <!--          v-model="name"-->
      <!--          placeholder="Название"-->
      <!--          class="w-75"-->
      <!--          @keydown.enter="searchServices"-->
      <!--        />-->
      <!--        <b-input-->
      <!--          v-model="code"-->
      <!--          placeholder="Код"-->
      <!--          @keydown.enter="searchServices"-->
      <!--        />-->
      <!--        <b-button-->
      <!--          variant="primary"-->
      <!--          class="rounded-0 border-0"-->
      <!--          :disabled="searchServicesProcess"-->
      <!--          @click="searchServices"-->
      <!--        >-->
      <!--          <b-icon icon="search" />-->
      <!--        </b-button>-->
      <!--      </b-input-group>-->

      <div class="d-flex justify-content-between my-3">
        <b-button
          :variant="isActive ? 'outline-primary' : 'primary'"
          size="sm"
          :class="{'border-0': isActive}"
          @click="toggleActive"
        >
          {{ isActive ? 'Показать удаленные' : 'Показать активные' }}
        </b-button>

        <b-button
          variant="primary"
          size="sm"
          @click="editService(false)"
        >
          Создать услугу
        </b-button>
      </div>

      <template v-if="searchHasMade && !isLoading">
        <template v-if="prices.length">
          <b-table
            :fields="fields"
            :items="prices"
            bordered
            hover
            :small="true"
            class="bg-white crm-table"
          >
            <template #cell(type)="row">
              <div>
                {{ priceType(row.item.type) }}
              </div>
            </template>

            <template v-slot:cell(isActive)="row">
              <b-badge
                v-if="row.item.isActive"
                class="p-2"
                pill
                variant="success"
              >
                Активирована
              </b-badge>
              <b-badge
                v-else
                class="p-2"
                pill
                variant="danger"
              >
                Не активирована
              </b-badge>
            </template>

            <template #cell(actions)="row">
              <b-button
                v-b-tooltip.hover
                size="sm"
                title="Редактировать склейку"
                variant="primary"
                @click="editService(row.item)"
              >
                <b-icon icon="pencil-square" />
              </b-button>
            </template>
          </b-table>

          <!--          <b-pagination-->
          <!--            v-model="currentPage"-->
          <!--            pills-->
          <!--            :total-rows="+pricesPaginationCount"-->
          <!--            :per-page="perPage"-->
          <!--            style="padding-top: 16px"-->
          <!--            @input="searchServices"-->
          <!--          />-->
        </template>

        <template v-else>
          <div class="font-weight-bold">
            Ничего не найдено
          </div>
        </template>
      </template>

      <b-skeleton-table
        v-if="isLoading"
        :rows="limit"
        :columns="fields.length"
        :table-props="{ bordered: true, striped: true }"
      />
    </b-container>
  </div>
</template>

<script>
import { PRICE_TYPES } from '@/helpers/consts';

export default {
  name: 'GluingServices',

  data: () => ({
    PRICE_TYPES,
    fields: [
      {
        key: 'code',
        label: 'Код',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'name',
        label: 'Название',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      // {
      //   key: 'cost',
      //   label: 'Стоимость',
      //   sortable: true,
      //   tdClass: 'align-middle',
      //   class: 'text-center',
      // },
      // {
      //   key: 'agentsCommission',
      //   label: 'Комиссия',
      //   sortable: true,
      //   tdClass: 'align-middle',
      //   class: 'text-center',
      // },
      {
        key: 'type',
        label: 'Тип',
        sortable: true,
        tdClass: 'align-middle',
        class: 'text-center',
      },
      {
        key: 'isActive',
        label: 'Статус услуги',
        tdClass: ['align-middle'],
        class: 'text-center',
      },
      {
        key: 'actions',
        label: '',
        tdClass: 'align-middle',
        class: ['actionsClass', 'text-center'],
      },
    ],
    currentPage: 1,
    perPage: 20,
    limit: 20,
    isActive: true,
    searchServicesProcess: false,
    code: '',
    name: '',
    searchHasMade: false,
  }),
  computed: {
    prices() {
      return this.$store.getters[this.$types.PRICES_LIST];
    },
    pricesPaginationCount() {
      return this.$store.getters[this.$types.PRICES_LIST_PAGINATION_COUNT];
    },
    isLoading() {
      return this.$store.getters[this.$types.PRICES_LIST_LOADING];
    },
  },

  // async created() {
  //   await this.searchServices();
  // },

  methods: {
    editService(data) {
      let props = {
        searchIsActive: this.isActive,
      };

      if (data) {
        props = {
          ...props,
          title: `Редактирование "${data.name}"`,
          searchData: data,
          type: data.type,
        };
      }
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditingServicesModal',
        props,
      });
    },
    priceType(typeId) {
      const type = this.PRICE_TYPES.find((item) => item.id === typeId);
      if (type) return type.title;

      return '-';
    },
    async toggleActive() {
      this.isActive = !this.isActive;

      this.currentPage = 1;
      // await this.searchServices();
    },
    // async searchServices() {
    //   try {
    //     await this.$store.dispatch(this.$types.FETCH_PRICES_LIST, {
    //       code: this.code,
    //       name: this.name,
    //       skip: (this.currentPage - 1) * this.limit,
    //       take: this.limit,
    //       isActive: this.isActive,
    //     });
    //   } catch (e) {
    //     console.error(e);
    //   } finally {
    //     this.searchHasMade = true;
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>

</style>
