import NProgress from 'nprogress/nprogress';
// import { format, parseISO } from 'date-fns';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
  // convertObjToQueryParams, saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  // async [localTypes.DOCTOR_FETCH](ctx, id) {
  //   NProgress.start();

  //   try {
  //     const { data: { data: doctor } } = await api.get(`v2/doctors/${id}`);

  //     return doctor;
  //   } catch (e) {
  //     console.warn(e);

  //     showMessage({
  //       type: 'error',
  //       title: 'Ошибка',
  //       message: 'Не удалось получить информацию о враче',
  //     });
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  async [localTypes.LEGAL_ENTITIES_CREATE](ctx, person) {
    NProgress.start();

    try {
      const res = await api.post('v2/clinic/createLegalPerson', person);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр. лицо успешно добавлено!',
      });
      return res;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LEGAL_ENTITIES_UPDATE](ctx, person) {
    NProgress.start();

    try {
      const res = await api.put('v2/clinic/updateLegalPerson', person);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юр. лицо успешно обновлено!',
      });
      return res;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LEGAL_DELETE]({ commit }, id) {
    NProgress.start();

    try {
      await api.delete(`v2/clinic/deleteLegalPerson?id=${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Юрлицо успешно удалено!',
      });
      commit(localTypes.LEGAL_ENTITIES_REMOVE_BY_ID, id);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить юрлицо',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LEGAL_ENTITIES_FETCH]({ commit }, {
    skip,
    query,
    take,
    clear,
    save,
    inn,
  }) {
    NProgress.start();

    if (save && clear) {
      commit(localTypes.LEGAL_ENTITIES_SET, []);
    }

    try {
      const params = new URLSearchParams();

      if (query) params.append('name', query);
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      if (inn) params.append('inn', inn);

      const { data: entities } = await api.get('v2/clinic/listLegalPerson', {
        params,
      });

      if (save) {
        commit(localTypes.LEGAL_ENTITIES_ADD, entities);
      }

      return entities;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.LEGAL_ENTITY_FETCH](_, { id }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      params.append('id', id);

      const { data: legalPerson } = await api.get('v2/clinic/getLegalPerson', {
        params,
      });
      return legalPerson;
    } finally {
      NProgress.done();
    }
  },
  // async [localTypes.DOCTORS_CONSULTATIONS_FETCH]({ commit }, {
  //   skip,
  //   take,
  //   doctorId,
  // }) {
  //   NProgress.start();

  //   try {
  //     const params = new URLSearchParams();

  //     params.append('skip', skip);
  //     params.append('take', take);
  //     const { data: { data: consultations } } = await api.get(`v2/doctors/${doctorId}/consultations`, {
  //       params,
  //     });

  //     commit(localTypes.DOCTORS_CONSULTATIONS_ADD, consultations);
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  // async [localTypes.SPECIALIZATIONS_SEARCH](ctx, query) {
  //   NProgress.start();

  //   try {
  //     const params = new URLSearchParams();

  //     params.append('search', query);
  //     const { data: specializations } = await api.get('v2/specializations/find', {
  //       params,
  //     });

  //     return specializations.map((specialization) => ({ id: specialization.id, name: specialization.title }));
  //   } catch (e) {
  //     console.warn(e);
  //     return [];
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  // async [localTypes.DOCTOR_UNLOAD_FETCH](ctx, filter) {
  //   NProgress.start();
  //   const params = convertObjToQueryParams(filter);

  //   try {
  //     const { data } = await api.get('v2/doctors/report', {
  //       params,
  //       responseType: 'blob',
  //     });

  //     let title = '';

  //     if (filter.clinicName) {
  //       title = filter.clinicName;
  //     } else {
  //       title = `${format(parseISO(filter.startDate), 'dd/MM/yyyy')} - ${format(parseISO(filter.endDate), 'dd/MM/yyyy')}`;
  //     }

  //     saveFile(data, { name: `Выгрузка по врачам ${title}.xlsx` });
  //   } catch (e) {
  //     console.warn(e);
  //     showMessage({ type: 'error', message: 'Не удалось выгрузить врачей' });
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  // async [localTypes.DOCTOR_FETCH_REGIONS]() {
  //   NProgress.start();

  //   try {
  //     const { data: regions } = await api.get('v2/regions/list');

  //     return regions;
  //   } catch (e) {
  //     console.warn(e);

  //     showMessage({
  //       type: 'error',
  //       title: 'Ошибка',
  //       message: 'Не удалось получить информацию о регионах',
  //     });
  //   } finally {
  //     NProgress.done();
  //   }
  // },
};
