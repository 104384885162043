<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Добавление документа'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group label="Тип документа">
            <b-form-select
              v-model="selectedType"
              :options="options"
              size="sm"
              class="mt-3"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-file
            v-model="updateFile"
            class="mt-3"
            plain
            @input="sendFile(updateFile)"
          />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <transition name="slide-fade">
          <b-button
            v-if="updateFile && selectedType"
            variant="primary"
            @click="onClickAdd"
          >
            Добавить
          </b-button>
        </transition>
        <b-button
          variant="danger"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Loading from '@/components/Loading';
import { formatISO } from 'date-fns';
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';

export default {
  name: 'AddClinicDocument',
  components: {
    Loading,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    clinicId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      selectedType: null,
      options: [
        { value: 1, text: 'Договор' },
        { value: 3, text: 'Лицензия' },
        { value: 4, text: 'Прайс' },
        { value: 5, text: 'Скан' },
      ],
      isOpen: true,
      isLoading: false,
      updateFile: null,
      newDocument: {},
      document: {},
      fileApi: 'File/clinicdocument/upload',
    };
  },
  watch: {
    selectedType() {
      this.newDocument.type = this.selectedType;
    },
  },
  methods: {
    async onClose() {
      await this.deleteDocument();

      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async deleteDocument() {
      if (this.document.id) {
        this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_DELETE, {
          document: this.document,
          isFetch: false,
        });
      }
    },
    async onClickAdd() {
      await this.$store.dispatch(this.$types.CLINIC_DOCUMENTS_CREATE, this.newDocument);
      const clinicData = await this.$store.dispatch(this.$types.CLINIC_FETCH, this.clinicId);
      this.$store.commit(this.$types.CLINIC_SET, {
        ...clinicData,
        info: clinicData,
      });
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async sendFile(fileObject) {
      const extension = getFileExtension(fileObject.name);

      if (this.document.id) {
        await this.deleteDocument();
      }

      try {
        const fd = new FormData();

        if (this.fileApi) {
          fd.append('file', fileObject);
        }

        const { data: { guid } } = await api.post(this.fileApi || `File/${extension}`, this.fileApi ? fd : fileObject, {
          headers: {
            'Content-Type': fileObject.type,
          },
        });

        this.newDocument.fileId = guid;
        this.newDocument.timeCreated = formatISO(new Date());
        this.newDocument.title = fileObject.name;
        this.newDocument.clinicId = this.clinicId;
        this.document.id = guid;
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
