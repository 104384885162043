<template>
  <div class="rule-module-full">
    <b-container
      fluid="xl"
    >
      <!-- header -->
      <div class="d-flex align-items-center justify-content-between mt-4 mb-3">
        <div class="d-flex align-items-center">
          <div
            class="back mr-3"
            @click="close"
          >
            <IconChevronLeft />
          </div>
          <div class="h3 mb-0">
            {{ rule.title }}
          </div>
        </div>

        <b-button
          variant="primary"
          class="ml-2"
          size="sm"
          @click="save()"
        >
          Сохранить
        </b-button>
      </div>

      <!-- search -->
      <div class="w-100 mt-3 mb-4">
        <b-input-group class="bg-white border border-primary rounded">
          <b-form-input
            v-model="params.text"
            type="text"
            debounce="500"
            :placeholder="rule.placeholderSearch"
            class="border-0"
            @keyup.enter.native="search(rule, params)"
          />

          <b-button
            variant="outline-primary"
            class="rounded-0 border-0"
            @click="search(rule, params)"
          >
            <b-icon icon="search" />
          </b-button>
        </b-input-group>
      </div>

      <!-- list -->
      <div class="rule-list-el">
        <div class="w-full mr-4">
          <div class="rule-list-title">
            {{ rule.title }}
          </div>

          <div
            v-if="!isListLoader"
            class="list"
          >
            <template v-if="rule.list.length">
              <div
                v-for="(item, index) of rule.list"
                :key="index"
                class="item"
                @click="chose(item, index, 'list')"
              >
                <div class="index">
                  {{ index + 1 }}
                </div>

                <div class="rule-item-wrapper">
                  <template v-if="rule.id === 'specializations'">
                    <div>{{ item.name }}</div>
                  </template>
                  <template v-if="rule.id === 'clinic'">
                    <div>{{ item.title }}</div>
                    <div class="address">
                      {{ item.addressString }}
                    </div>
                  </template>
                  <template v-if="rule.id === 'patients'">
                    <div>{{ item.fullName }}</div>
                    <div class="birthdate">
                      {{ item.birthdate }}
                    </div>
                  </template>
                  <template v-if="rule.id === 'doctors'">
                    <div>{{ item.fullName }}</div>
                  </template>
                  <template v-if="rule.id === 'diases'">
                    <div>{{ item.code }}: {{ item.name }}</div>
                  </template>
                </div>
              </div>
            </template>
            <div
              v-else
              class="empty"
            >
              Ничего не найдено. Воспользуйтесь поиском
            </div>
          </div>
          <Preloader v-else />
        </div>
        <div class="w-full">
          <div class="rule-list-title">
            Выбранные
            <b-badge
              v-if="rule.selected.length"
              href="#"
              variant="primary"
            >
              {{ rule.selected.length }}
            </b-badge>
          </div>

          <div class="list result">
            <template v-if="rule.selected.length">
              <div
                v-for="(item, index) of rule.selected"
                :key="index"
                class="item"
                @click="chose(item, index, 'selected')"
              >
                <div class="index">
                  {{ index + 1 }}
                </div>
                <div class="rule-item-wrapper">
                  <template v-if="rule.id === 'specializations'">
                    <div>{{ item.name }}</div>
                  </template>
                  <template v-if="rule.id === 'clinic'">
                    <div>{{ item.title }}</div>
                    <div class="address">
                      {{ item.addressString }}
                    </div>
                  </template>
                  <template v-if="rule.id === 'patients'">
                    <div>{{ item.fullName }}</div>
                    <div class="birthdate">
                      {{ item.birthDate }}
                    </div>
                  </template>
                  <template v-if="rule.id === 'doctors'">
                    <div>{{ item.fullName }}</div>
                  </template>
                  <template v-if="rule.id === 'diases'">
                    <div>{{ item.code }}: {{ item.name }}</div>
                  </template>
                </div>
              </div>
            </template>
            <div
              v-else
              class="empty"
            >
              {{ rule.placeholder }}
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';
import IconChevronLeft from 'assets/images/arrow-button-left.svg';

export default {
  name: 'RuleModuleFull',
  components: { IconChevronLeft, Preloader },
  props: {
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isListLoader: false,
      params: {
        text: '',
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
      this.$emit('close');
    },
    async search(rule, params) {
      this.isListLoader = true;
      await this.rule.functionSearch(rule, params);
      this.isListLoader = false;
    },
    chose(item, index, field) {
      const oppositeField = field === 'selected' ? 'list' : 'selected';

      this.rule[field].splice(index, 1);
      this.rule[oppositeField].unshift(item);
    },
  },
};
</script>

<style lang="scss">
.rule-module-full {
  position: fixed;
  top: 0;
  left: 250px;
  width: calc(100% - 250px);
  height: 100%;
  background: #f3f3f3;
  z-index: 999999;

  .rule-list-el {
    width: 100%;
    display: flex;

    .rule-list-title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .list {
      width: 100%;
      height: 650px;
      overflow-y: auto;

      .item {
        width: 100%;
        padding: 12px;
        background: #fff;
        margin-bottom: 8px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all .3s;

        .index {
          margin-right: 8px;
        }

        .rule-item-wrapper {
          .address,
          .specialization,
          .birthdate {
            margin-top: 3px;
            font-size: 12px;
            opacity: 0.7;
          }
        }

        &:hover {
          background: #fbfdff;
          transform: translateY(4px);
        }
      }

      &.result {
        .item {
          background: var(--blue);
          color: var(--white);
        }
      }

      .empty {
        color: #000;
      }
    }
  }
}
</style>
