<template>
  <div>
    <div class="d-flex">
      <b-input-group
        v-if="filter.isActive"
      >
        <b-form-input
          v-model="filter.lastName"
          placeholder="Фамилия"
          @keyup.native.enter="$emit('search')"
        />
        <b-form-input
          v-model="filter.firstName"
          placeholder="Имя"
          @keyup.native.enter="$emit('search')"
        />
        <b-form-input
          v-model="filter.middleName"
          placeholder="Отчество"
          @keyup.native.enter="$emit('search')"
        />
      </b-input-group>

      <b-form-input
        v-else
        v-model="filter.query"
        type="text"
        placeholder="Введите ФИО пациента"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        :variant="filter.isActive ? 'primary': 'outline-primary'"
        class="ml-2"
        @click="toggleFilter"
      >
        <b-icon icon="three-dots" />
      </b-button>

      <b-button
        variant="primary"
        class="ml-2"
        @click="$emit('search')"
      >
        <b-icon icon="search" />
      </b-button>
    </div>

    <div class="d-flex mt-2 align-items-center">
      <b-form-input
        v-model="filter.phone"
        size="sm"
        placeholder="Номер телефона"
        :style="{ width: '160px' }"
        @keyup.native.enter="$emit('search')"
      />
      <b-form-input
        v-model="filter.birthDate"
        class="ml-2 birthDay"
        size="sm"
        placeholder="Дата рождения"
        :style="{ width: '150px' }"
        @focus="(e) => e.target.type ='date'"
        @blur="(e) => filter.birthDate ? null : e.target.type ='text'"
        @keyup.native.enter="$emit('search')"
        @paste="onPaste"
      />

      <b-form-input
        v-model="filter.policyCode"
        class="ml-2"
        size="sm"
        placeholder="Номер полиса"
        @keyup.native.enter="$emit('search')"
      />

      <b-form-input
        v-model="filter.id"
        class="ml-2 mr-2"
        :style="{ width: '150px' }"
        size="sm"
        placeholder="ID пациента"
        @keyup.native.enter="$emit('search')"
      />

      <b-button
        variant="outline-primary"
        class="ml-auto border-0"
        size="sm"
        :style="{ flexShrink: '0' }"
        @click="resetFilter"
      >
        Сбросить фильтры
      </b-button>
    </div>
    <div class="d-flex mt-2 align-items-center justify-content-end">
      <b-form-checkbox
        v-model="filter.isRegistered"
        size="sm"
      >
        <span class="text-secondary">
          Зарегистрирован в Доктис
        </span>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import { isValid } from 'date-fns';

import { validationMixin } from 'vuelidate';

import { mixinRoles } from '@/mixins';
import { onBInputDatePaste } from '@/helpers/utils';

export default {
  name: 'PatientsSearchPanel',
  components: {},
  mixins: [validationMixin, mixinRoles],
  model: {
    prop: 'filter',
    event: 'change',
  },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      insuranceSubPrograms: [],
      type: 'text',
    };
  },
  validations() {
    return {
      filter: {
        phone: {
          isValid: (value) => {
            if (value === null || !value.length) return true;
            // return /^\+7\(\d{3}\) \d{3}-\d{2}-\d{2}$/i.test(value);
            return true;
          },
        },
        birthDate: {
          isValid: (value) => {
            if (value === null) return true;
            return isValid(value);
          },
        },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.birthDate = [];
      if (!this.$v.filter.birthDate.isValid) {
        errors.birthDate.push('Некорректное значение поля');
      }

      return errors;
    },
  },
  async created() {
    this.insuranceSubPrograms = await this.$store.dispatch(this.$types.INSURANCE_SUBPROGRAMS_FETCH);
  },
  methods: {
    onPaste(e) {
      onBInputDatePaste(e);
    },
    openAddModal() {
      Bus.$emit('open-modal:patient-edit');
    },
    checkBirthdayValue(val) {
      return val === '' ? 'text' : 'date';
    },
    toggleFilter() {
      this.filter.isActive = !this.filter.isActive;
    },
    resetFilter() {
      Bus.$emit('patient-search:reset-filter');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
