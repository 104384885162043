<template>
  <div>
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      :class="{ 'entities-list_modal': modalMode }"
      without-loader
      class="entities-list"
      @load-more-items="loadMoreItems"
    >
      <b-container fluid="xl">
        <div class="entities-list-inner">
          <slot />

          <div class=" mb-5">
            <div class="d-flex w-100">
              <b-input-group class="bg-white rounded shadow-light">
                <b-form-input
                  v-model="form.query"
                  type="search"
                  placeholder="Поиск юридического лица"
                  class="border-0"
                  @keyup.enter="entitiesFetch(true)"
                  @input="deferredRequest"
                />
                <b-button
                  variant="outline-primary"
                  class="rounded-0 border-0"
                  @click="entitiesFetch(true)"
                >
                  <b-icon icon="search" />
                </b-button>
              </b-input-group>

              <div class="ml-2">
                <slot name="button" />
              </div>
            </div>

            <div class="d-flex mt-2">
              <b-form-input
                v-model="form.inn"
                placeholder="ИНН"
                class="w-25"
                @keyup.enter="entitiesFetch(true)"
                @input="deferredRequest"
              />

              <b-button
                variant="outline-primary"
                class="ml-1 border-0"
                size="sm"
                @click="resetFilter"
              >
                Сбросить фильтры
              </b-button>
            </div>
          </div>

          <div v-if="!totalCount && isAllItemsLoaded">
            <div v-if="!form.query">
              Введите строку для поиска
            </div>

            <div
              v-else
              class="bg-white shadow-lg p-3 rounded font-weight-bold"
            >
              Ничего не найдено
            </div>
          </div>

          <slot
            name="legalEntities"
            :legalEntities="legalEntities"
          />

          <div v-if="isLoading">
            <b-col
              v-for="item in 15"
              :key="item"
              cols="12"
              class="mt-3 p-0 rounded"
            >
              <b-skeleton-img
                no-aspect
                height="145px"
              />
            </b-col>
          </div>
        </div>
      </b-container>
    </async-list>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import Bus from '@/eventBus';

import AsyncList from '@/components/AsyncList';

const LIMIT = 15;
const FETCH_DELAY = 700;

export default {
  name: 'LegalEntitySearch',
  components: {
    AsyncList,
  },
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      deferred: null,
      form: {
        query: '',
        inn: '',
      },
      isAllItemsLoaded: false,
      isLoading: false,
      limit: LIMIT,
    };
  },
  computed: {
    legalEntities() {
      return this.$store.state.LegalEntities.legalEntities;
    },
    totalCount() {
      return this.legalEntities.length;
    },
  },
  created() {
    Bus.$on('doctors:update', this.updateDoctors);
    this.entitiesFetchDebounced = debounce(this.entitiesFetch, FETCH_DELAY);
  },
  beforeDestroy() {
    Bus.$off('doctors:update', this.updateDoctors);
    this.$store.commit(this.$types.DOCTORS_SET, []);
  },
  methods: {
    async entitiesFetch(clear) {
      if (this.isLoading) return;
      clearTimeout(this.deferred);

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;

      try {
        await this.$store.dispatch(this.$types.LEGAL_ENTITIES_FETCH, {
          skip: prevTotal,
          take: this.limit,
          query: this.form.query,
          inn: this.form.inn,
          clear: !!clear,
          save: true,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    deferredRequest() {
      clearTimeout(this.deferred);

      this.deferred = setTimeout(() => {
        this.entitiesFetch(true);
      }, 1200);
    },
    async resetFilter() {
      Object.keys(this.form).forEach((item) => {
        this.form[item] = '';
      });

      await this.entitiesFetch(true);
    },
    loadMoreItems() {
      this.entitiesFetch(false);
    },
    updateDoctors(id) {
      if (this.legalEntities.find((item) => item.id === id)) {
        this.entitiesFetch(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}
.entities-list {
  height: 100vh;

  &_modal {
    height: calc(100vh - 150px);
  }
}

.entities-list-inner {
  padding: 30px 0;
}
</style>
