<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Удаление очереди"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      Вы уверены, что хотите удалить очередь?
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        @click="onDelete"
      >
        Удалить
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'QueueDeleteModal',
  components: {
    Preloader,
  },
  props: {
    queueId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterDelete: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onDelete() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.QUEUE_DELETE, this.queueId);
        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.afterDelete();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
