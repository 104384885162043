var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-form"},[_c('img',{staticClass:"logo",attrs:{"src":require(`assets/images/${_vm.logo}.svg?inline`)}}),_c('div',{staticClass:"crm-input-block"},[_c('base-input',{staticClass:"login-form__input",attrs:{"error":_vm.$v.login.$error,"errors":_vm.loginErrors,"input-style":{
          padding: '21px 20px',
        },"placeholder":"Логин","fluid":""},model:{value:(_vm.$v.login.$model),callback:function ($$v) {_vm.$set(_vm.$v.login, "$model", $$v)},expression:"$v.login.$model"}}),_c('base-input',_vm._g({ref:"password",staticClass:"login-form__input",attrs:{"error":_vm.$v.password.$error,"errors":_vm.passwordErrors,"input-style":{
          padding: '21px 20px',
        },"type":_vm.showPassword ? 'text' : 'password',"placeholder":"Пароль","fluid":""},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_c('icon-eye',{class:[ 'icon-eye', { active: _vm.showPassword }],on:{"click":_vm.toggleShowPassword}})]},proxy:true}]),model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}},{
          blur: _vm.onblurPassword,
        }))],1),_c('base-checkbox',{staticClass:"login-form__checkbox",attrs:{"align-top":""},model:{value:(_vm.saveAuth),callback:function ($$v) {_vm.saveAuth=$$v},expression:"saveAuth"}},[_vm._v(" Сохранить логин и пароль для входа в профиль ")]),_c('base-button',{staticClass:"login-form__button",attrs:{"type":_vm.$const.SECONDARY_BUTTON},on:{"click":_vm.signIn}},[_vm._v(" ВОЙТИ ")]),_c('hr'),_c('div',{staticClass:"gosuslugi"},[_c('p',{staticClass:"gosuslugi-text"},[_vm._v(" или авторизоваться через личный кабинет портала «Госуслуги»: ")]),_c('div',{staticClass:"gosuslugi-button",on:{"click":_vm.openEsiaAuth}},[_c('div',[_vm._v("войти через портал")]),_c('IconEsia')],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }