<template>
  <div class="consultation-info">
    <div class="crm-info-wrapper crm-info-wrapper_head">
      <p class="crm-title">
        Номер консультации: {{ consultation.id }}
      </p>

      <div class="crm-row">
        <div class="crm-column specialization">
          <span class="specialization__caption">
            Специализация врача
          </span>
          <span class="specialization-name">
            {{ consultation.specializationName }}
          </span>
        </div>
        <div class="crm-column specialization">
          <span class="specialization__caption">
            Часовой пояс врача
          </span>
          <span class="specialization-name">
            {{ timeZoneInfo }}
          </span>
        </div>
      </div>
    </div>
    <div class="crm-line" />

    <div class="crm-row crm-info-wrapper patient-info">
      <div class="crm-column">
        <div>ФИО пациента</div>
        <div class="patient-fio">
          {{ consultation.patientName }}
        </div>
      </div>
      <div class="crm-column">
        <div>ID пациента</div>
        <div class="patient-id">
          {{ consultation.patientId }}
        </div>
      </div>
    </div>

    <div class="crm-info-wrapper">
      <div class="crm-info-block crm-info-block--create">
        <div class="create-time-title">
          Время создания
        </div>
        <span class="crm-info-text create-time-value">
          {{ createDate }}
        </span>
      </div>

      <div class="crm-info-block crm-info-block--take">
        <div class="taking-time-title">
          Время взятия
        </div>
        <span class="crm-info-text taking-time-value">
          {{ takeOutDate }}
        </span>
      </div>

      <div
        v-if="rating"
        class="crm-info-block crm-info-block--rate"
      >
        <p class="crm-info-title">
          Оценка
        </p>
        <span class="crm-info-text">
          {{ rating }}
        </span>
      </div>
    </div>

    <div class="crm-info-wrapper">
      <div class="crm-info-block">
        <div>
          Дата и время консультации
        </div>
        <span class="crm-info-text taking-time-value">
          {{ slotDate }}
        </span>
      </div>
    </div>

    <base-dropdown
      v-if="consultation.body"
      header="Текст обращения"
      :open="true"
      :show-line="false"
    >
      <span class="crm-info-text message-text">
        {{ consultation.body }}
      </span>
    </base-dropdown>

    <base-dropdown
      v-if="consultation.protocol"
      header="Протокол"
      :open="false"
      :show-line="false"
    >
      <div class="protocol-content">
        <div class="protocol-content__section-title">
          Рекомендации
        </div>
        <span>{{ recommendations }}</span>
        <div class="protocol-content__section-title">
          Жалобы
        </div>
        <span>{{ anamnesis }}</span>
        <div class="protocol-content__section-title">
          МКБ
        </div>
        <template v-if="consultation.protocol.mkB10s.length">
          <span
            v-for="(mkb, index) in consultation.protocol.mkB10s"
            :key="index"
          >
            {{ mkb.name }} - {{ mkb.code }};
          </span>
        </template>
        <template v-else>
          <span>—</span>
        </template>
      </div>
    </base-dropdown>
  </div>
</template>

<script>
import { BaseDropdown } from '@/components/base';
import { formatTimeZone } from '@/helpers/utils';
import { parseISO, format } from 'date-fns';

export default {
  name: 'DoctorConsultationsInfoItem',
  components: {
    BaseDropdown,
  },
  props: {
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    rating() {
      return this.consultation.score || undefined;
    },

    timeZoneInfo() {
      return this.consultation.timeZone ? formatTimeZone(this.consultation.timeZone) : '-';
    },
    createDate() {
      return this.consultation.creationDateTime
        ? `${format(parseISO(this.consultation.creationDateTime), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    takeOutDate() {
      return this.consultation.takeoutDateTime
        ? `${format(parseISO(this.consultation.takeoutDateTime), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    slotDate() {
      return this.consultation.slotDate
        ? `${format(parseISO(this.consultation.slotDate), 'dd.MM.yyyy HH:mm')}` : '-';
    },
    recommendations() {
      return this.consultation.protocol.recomendation || '—';
    },
    anamnesis() {
      return this.consultation.protocol.anamnesis || '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px dashed $blue;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 30px;
}
.specialization {
  margin-top: 30px;

  &__caption {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    color: $black-light;
    display: block;
  }
  &-name {
    display: block;
  }
}

.create-time-title {
  color: $green !important;
  font-weight: 400 !important;
}
.taking-time-title {
  color: $orange !important;
  font-weight: 400 !important;
}

.specialization-name, .patient-fio, .patient-id, .create-time-value, .taking-time-value{
  margin-top: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: $black-light;
}

.crm-line {
  height: 1px;
  background-color: #E0E0E0;
  width: 100%;
  margin-bottom: 30px;
}
.crm-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: $black;
  margin: 0;
}
.crm-info-wrapper {
  width: 100%;
  display: flex;

  &_head {
    flex-direction: column;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.crm-info-block {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &:not(:last-child) {
    padding-right: 30px;
  }

  &--create {
    flex-basis: 257px;
  }

  &--take {
    flex-basis: 267px;
  }

  &--rate {
    flex-basis: 66px;
  }
}
.crm-info-title {
  font-weight: 600;
  color: $gray-dark;
  margin: 0 0 10px 0;
}

.message-text {
  font-size: 16px;
  line-height: 20px;
}

.protocol-content {
  &__section-title {
    font-size: 14px;
    line-height: 18px;
    color: $gray-dark;
    margin-top: 20px;
  }
  span {
    margin-top: 5px;
    display: block;
    font-size: 16px;
    line-height: 20px;
  }
}

.crm-row {
  display: flex;

  & + & {
    margin-top: 20px;
  }

  &.align-center {
    align-items: center;
  }
}

.crm-column {
  flex: 1;

  & + & {
    margin-left: 20px;
  }
}

.base-dropdown-wrapper {
  margin: 10px 0px;
}
</style>
