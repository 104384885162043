<template>
  <div
    v-if="policy"
    :class="['person-policy', 'active-policy']"
  >
    <div class="policy-info policy-info-main">
      <div class="policy-name">
        <template v-if="policy.isDMS">
          <template v-if="policy.companyName">
            {{ policy.companyName }}. <br>
          </template>
          <template v-if="policy.programName">
            {{ policy.programName }}. <br>
          </template>
          {{ policy.insuranceName }}
        </template>
        <template v-else>
          {{ policy.insuranceName }}
        </template>
      </div>
      <div
        v-if="!policy.isDMS"
        class="font-weight-bolder"
      >
        {{ policy.companyName }}
      </div>
      <div v-if="policy.programName">
        Программа - <span class="font-weight-bolder">{{ policy.programName }}</span>
      </div>
      <div v-if="policy.subProgramV2Id">
        Подпрограмма - <span
          v-if="policyСonnections"
          class="font-weight-bolder"
        >{{ policyСonnections.subProgram?.name }}</span>
      </div>
      <div class="policy-fullname">
        {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
      </div>
      <div class="policy-ensurerName">
        {{ policy.ensurerName }}
      </div>
      <div class="policy-id mt-2 mb-2">
        № {{ policy.policyId }}
      </div>
      <div
        v-if="policy.startDate && policy.endDate"
        class="policy-date"
      >
        {{ parseData(policy.startDate) }} - {{ parseData(policy.endDate) }}
      </div>
      <div
        v-if="!policy.startDate && policy.endDate"
        class="policy-date"
      >
        до {{ parseData(policy.endDate) }}
      </div>
    </div>

    <div class="policy-controls">
      <!-- v-if="checkFeatureAccess({ name: 'Создать ГП', url: '/appeals' })" -->
      <b-button
        v-if="!checkRoleAccess(['polis.operator', 'polis.operator.dop'])"
        variant="light"
        size="sm"
        class="p-0 border"
        title="Создать ГП"
        @click="openIndemnityModal(policy)"
      >
        <icon-worksheet />
      </b-button>
      <!-- checkFeatureAccess({ name: 'Создать направление', url: '/appeals' }) -->
      <b-button
        v-if="!checkRoleAccess(['polis.operator', 'polis.operator.dop'])"
        variant="light"
        size="sm"
        class="mt-1 p-1 border"
        title="Создать направление"
        @click="openIndemnityModal(policy, true)"
      >
        <b-icon
          icon="file-earmark-medical"
          class="text-info"
        />
      </b-button>
      <b-button
        variant="light"
        size="sm"
        class="mt-1 p-0 border"
        title="Детальная информация"
        @click="openPolicyDetail(policy)"
      >
        <icon-info />
      </b-button>
    </div>
  </div>
  <div v-else>
    —
  </div>
</template>

<script>
import {
  format,
  // formatISO,
  parseISO,
} from 'date-fns';
import IconWorksheet from 'assets/images/worksheet.svg';
import IconInfo from 'assets/images/info.svg';
import Bus from '@/eventBus';
import { api } from '@/helpers/api';

import { mixinRoles } from '@/mixins';

export default {
  name: 'PolicyItem',
  components: {
    IconWorksheet,
    IconInfo,
  },
  mixins: [mixinRoles],
  props: {
    policy: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      policyСonnections: null,
    };
  },
  computed: {
  },
  async mounted() {
    try {
      if (this.policy.subProgramV2Id) {
        const { data } = await api.get(`v2/insuranceCompanies/programs/subprogram/${this.policy.subProgramV2Id}`);
        this.policyСonnections = data;
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    isSelectedPolicy(policyId) {
      return this.selectedPolicyId === policyId;
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    openIndemnityModal(policy, isReferral) {
      Bus.$emit('open-modal:indemnity-modal', {
        personId: policy.personId, policyId: policy.id, subProgramId: policy.subProgramV2Id, isReferral,
      });
    },
    openPatientDetail(personId) {
      Bus.$emit('open-modal:patient-detail', personId);
    },
    openPolicyDetail(policy) {
      this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, policy.id);
      this.openPatientDetail(policy.personId);
    },
  },
};
</script>

<style lang="scss" scoped>
.person-policy {
  display: flex;
  align-items: flex-start;
  margin: 5px;
  width: 250px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  min-height: 100px;

  &.active-policy {
    border: 1px solid #219653;
  }
  &.pending-policy {
    border: 1px solid #F2994A;
  }
  &.inactive-policy {
    border: 1px solid #EB5757;
  }
}

.policy-info {
  word-break: break-word;
  margin-right: auto;
}

.policy-info-main {
  font-size: 16px;
  line-height: 20px;
}

.policy-name {
  font-weight: 600;
}

.policy-controls {
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.crm-control-icon {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}
</style>
