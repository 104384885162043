<template>
  <div>
    <div />
    <div class="services-search-wrapper">
      <div class="search-instruments-wrap">
        <v-select
          v-model="currentLegal"
          class="services-clinic-select"
          :options="legalList"
          label="name"
          placeholder="Выберите юрлицо"
        />
        <div class="d-flex mx-2">
          <base-date-picker
            v-model="startDate"
            style="width: 170px;"
            class="mr-1"
          />

          <base-date-picker
            v-model="endDate"
            style="width: 170px;"
          />
        </div>
        <span
          class="general-text"
        >Всего: {{ servicesData.sum }}₽</span>
      </div>
      <base-button
        :type="$const.PRIMARY_BUTTON"
        class="search-button"
        tabindex="-1"
        @click="tableFilter"
      >
        <icon-search
          class="icon-search"
        />
      </base-button>
    </div>
    <person-services-table />
  </div>
</template>

<script>
import { formatISO } from 'date-fns';
import {
  BaseButton,
  BaseDatePicker,
} from '@/components/base';
import IconSearch from 'assets/images/search.svg';
import * as types from '@/store/types';
import { mapGetters } from 'vuex';
import { dateWithoutTime } from '@/helpers/utils';
import PersonServicesTable from '../PersonServicesTable/PersonServicesTable';

export default {
  name: 'PatientServicesModal',
  components: {
    PersonServicesTable,
    BaseButton,
    BaseDatePicker,
    IconSearch,
  },

  props: {
    subprogramId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      legalList: [],
      currentLegal: '',
    };
  },
  computed: {
    ...mapGetters({
      servicesData: types.GET_PATIENT_SERVICES,
      policyId: types.GET_PATIENT_POLICY_ID,
    }),
  },
  async created() {
    this.legalList = (await this.$store.dispatch(this.$types.LEGAL_LIST_FETCH));
  },
  methods: {
    async tableFilter() {
      try {
        await this.$store.dispatch(this.$types.PATIENT_SERVICES_FETCH,
          {
            policyId: this.policyId,
            startDate: this.startDate ? dateWithoutTime(formatISO(this.startDate)) : null,
            endDate: this.endDate ? dateWithoutTime(formatISO(this.endDate)) : null,
            legalPersonId: this.currentLegal ?? '',
            subprogramId: this.subprogramId,
          });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.icon-search {
  path {
    fill: #ffffff;
  }
}

.search-button {
  width: 76px;
  height: 40px;
  border-radius: 10px;
}

.services-search-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .search-instruments-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.services-clinic-select {
  width: 350px;
}

.general-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6E88F3;
}

.input-container {
  position: relative;

  &__clear-icon {
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 4px;
    transition: .15s;
    &:hover {
      background: rgb(238, 238, 238);
    }
  }
}
input[type="date"] {
  padding-right: 19px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
}
</style>
