<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    title="Удаление информационного сообщения"
    :hide-footer="true"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>
    <template>
      <b-button
        variant="danger"
        class="float-right ml-4"
        @click="onClose('reset')"
        v-text="'Отмена'"
      />
      <b-button
        variant="success"
        class="float-right"
        @click="onClickDelete"
        v-text="'Удалить сообщение'"
      />
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import Preloader from '@/components/Preloader';

export default {
  name: 'InfoMessageDeleteModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    messageItem: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
  },
  data() {
    return {
      isOpen: true,
      isSaving: false,
    };
  },
  methods: {
    onClose(modalName = 'InfoMessageDeleteModal') {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName });
    },
    async onClickDelete() {
      this.isSaving = true;

      try {
        const isSuccess = await this.$store.dispatch(this.$types.INFO_MESSAGES_DELETE, { id: this.messageItem.id });

        if (isSuccess) {
          await this.$store.dispatch(this.$types.INFO_MESSAGES_FETCH);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaving = false;
        this.onClose('reset');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-overflow {
  max-width: 1px;
}
</style>
