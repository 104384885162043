/* eslint-disable no-param-reassign */
<template>
  <div class="insurance-company">
    <custom-scrollbar>
      <div class="crm-container">
        <loading
          v-if="isLoading"
          class="mt-4"
        >
          Загрузка
        </loading>

        <template v-else>
          <div class="crm-header-block">
            <template v-if="checkFeatureAccess({ name: 'Прикрепление пациентов', url: '/insurance-import' })">
              <span class="title">
                Прикрепление пациентов
              </span>

              <div class="crm-form-row">
                <v-select
                  v-model="selectedClinic"
                  :options="clinics"
                  placeholder="Выберите клинику"
                  label="title"
                  class="crm-form-field"
                  @input="onClinicSelect"
                />
              </div>

              <div class="crm-form-row">
                <base-async-select
                  v-model="selectedConfigurationTemplate"
                  :fetch-function="fetchConfigurationTemplates"
                  class="crm-form-field crm-form-field-base-select"
                  placeholder="Выберите шаблон настройки клиники"
                  loading-title="Загрузка шаблонов"
                  no-options-title="Шаблоны не найдены"
                  server-paginated
                />
                <!-- <v-select
                  v-model="selectedProgramId"
                  :reduce="program => program.id"
                  :options="insurancePrograms"
                  placeholder="Выберите ОМС программу"
                  label="name"
                  class="crm-form-field"
                  @input="changeOmsProgram"
                /> -->
              </div>

              <div class="crm-form-row">
                <div class="crm-form-field">
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="crm-button button_transparent without-border width-unset"
                    @click="openConfigurationTemplateAddModal"
                  >
                    Создать новый шаблон
                  </base-button>
                </div>
                <div class="crm-container-buttons">
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="crm-button-file"
                    @click="onClickOpenFileModal"
                  >
                    <icon-cross
                      class="crm-icon-cross"
                    />
                    <span>Добавить файл</span>
                  </base-button>

                  <base-button
                    :type="$const.PRIMARY_BUTTON"
                    class="button-import"
                    @click="onClickOpenImportModal"
                  >
                    <span>Загрузить</span>
                  </base-button>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="crm-header-group">
                <div class="crm-title">
                  Импорт полисов
                </div>
                <div>
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="crm-button-file"
                    @click="onClickOpenPackageCreateModal"
                  >
                    <span>Создать пакет клиник</span>
                  </base-button>
                </div>
              </div>

              <div class="crm-form-row">
                <v-select
                  v-model="selectedCompanyId"
                  :reduce="company => company.id"
                  :options="availableInsuranceCompanies"
                  placeholder="Выберите компанию"
                  label="title"
                  class="crm-form-field"
                  @input="changeCompany"
                />
                <v-select
                  v-model="selectedProgramId"
                  :reduce="program => program.id"
                  :options="availableInsurancePrograms"
                  placeholder="Выберите программу"
                  label="name"
                  class="crm-form-field"
                  @input="changeProgram"
                />
                <!-- <v-select
                  v-model="selectedSubprogramId"
                  :reduce="subprogram => subprogram.id"
                  :options="insuranceSubprograms"
                  placeholder="Выберите подпрограмму"
                  label="name"
                  class="crm-form-field"
                /> -->
              </div>

              <div
                v-if="selectedCompanyId !== 15"
                class="crm-form-row"
              >
                <div class="crm-form-field-filter specialization-filter">
                  <base-checkbox-select
                    :container-class="'specialization-filter'"
                    placeholder="Выберите клиники"
                    fluid
                  >
                    <template
                      v-slot:default="{ query }"
                    >
                      <base-checkbox-list
                        v-model="selectedClinicsForOthers"
                        :query="query"
                        :choices="allClinicsByGroup"
                      />
                    </template>
                  </base-checkbox-select>
                </div>
              </div>

              <div
                v-if="selectedClinicsForOthers.length && selectedCompanyId !== 15"
                class="crm-item-wrapper"
              >
                <doctor-speciality-item
                  v-for="(clinic, idx) in selectedClinicsForOthers"
                  :key="idx"
                  :speciality="clinic"
                  edit
                  class="crm-item"
                  @delete="deleteSelectedPackageClinic(clinic)"
                />
              </div>
              <div
                v-if="selectedCompanyId === 15"
                class="crm-form-row"
              >
                <v-select
                  v-model="selectedClinic"
                  :options="allClinics"
                  label="name"
                  placeholder="Выберите клинику"
                  class="crm-form-field"
                  @input="onClinicSelect"
                />
              </div>
              <div class="crm-form-row">
                <v-select
                  v-if="selectedCompanyId === 15"
                  v-model="selectedConfigurationTemplate"
                  :options="templates"
                  placeholder="Выберите шаблон настройки клиники"
                  label="name"
                  class="crm-form-field"
                />
                <div class="crm-container-buttons">
                  <base-button
                    :type="$const.SECONDARY_BUTTON"
                    class="crm-button-file"
                    @click="onClickOpenFileModal"
                  >
                    <icon-cross
                      class="crm-icon-cross"
                    />
                    <span>Добавить файл</span>
                  </base-button>

                  <base-button
                    :type="$const.PRIMARY_BUTTON"
                    class="button-import"
                    @click="onClickOpenImportModal"
                  >
                    <span>Загрузить</span>
                  </base-button>
                </div>
              </div>
              <base-button
                v-if="selectedCompanyId === 15"
                :type="$const.SECONDARY_BUTTON"
                class="crm-button button_transparent without-border width-unset"
                @click="openConfigurationTemplateAddModal"
              >
                Создать новый шаблон
              </base-button>
            </template>
          </div>

          <preloader v-if="isLoadingFromFile" />

          <div
            v-if=" !isLoadingFromFile"
            class="policies-table"
          >
            <div class="policies-table-wrapper">
              <div
                ref="table-header"
                class="policies-table-header"
              >
                <div
                  v-for="(title, i) in selectedColumnNames"
                  :key="i"
                  class="policies-table-header-cell"
                >
                  <v-select
                    :value="availableColumnNames.filter((name) => name.id === selectedColumnNames[i])[0]"
                    :append-to-body="true"
                    :reduce="program => program.id"
                    :searchable="false"
                    :clearable="true"
                    :options="filteredAvailableColumnNames"
                    :calculate-position="setMaxHeight"
                    class="select-col-name"
                    label="name"
                    placeholder="Игнорировать"
                    @input="selectColumnName($event, i)"
                  />
                </div>
              </div>
              <div class="policies-table-body">
                <vuescroll
                  :ops="ops"
                  class="vuescroll-custom"
                  @handle-scroll="handleScroll"
                >
                  <div class="policies-table-inner">
                    <div
                      v-for="(row, rowIndex) in tableRows"
                      :key="rowIndex"
                      class="policies-table-row"
                    >
                      <input
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        readonly="readonly"
                        class="policies-table-cell"
                        type="text"
                        :value="cell"
                      >
                    </div>
                  </div>
                </vuescroll>
              </div>
            </div>
            <div
              v-if="tableRows.length < totalRowsInFile"
              class="show-more-btn"
              @click="showAllRows"
            >
              Показать полностью
            </div>
          </div>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import Bus from '@/eventBus';
import vuescroll from 'vuescroll';

import { mixinRoles } from '@/mixins';
import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';

import Loading from '@/components/Loading';
import Preloader from '@/components/Preloader';
import {
  BaseButton,
  BaseAsyncSelect,
  BaseCheckboxSelect,
  BaseCheckboxList,
} from '@/components/base';

import IconCross from 'assets/images/cross.svg';
import DoctorSpecialityItem from '@/components/Doctors/DoctorSpecialityItem';

export default {
  name: 'InsuranceImport',
  page: {
    title: 'CRM Doctis - Импорт страховых полисов ',
  },
  components: {
    Loading,
    IconCross,
    BaseButton,
    BaseAsyncSelect,
    vuescroll,
    Preloader,
    BaseCheckboxSelect,
    BaseCheckboxList,
    DoctorSpecialityItem,
  },
  mixins: [mixinRoles],
  data() {
    return {
      isLoading: false,
      isLoadingFromFile: false,
      fileGUID: undefined,
      fileName: undefined,
      selectedClinic: null,
      selectedClinicsForOthers: [],
      clinics: [],
      allClinics: [],
      allClinicsByGroup: {
        choices: [],
      },
      templates: [],
      insuranceData: null,
      insuranceCompanies: [],
      insurancePrograms: [],
      insuranceSubprograms: [],
      selectedCompanyId: null,
      selectedProgramId: null,
      selectedSubprogramId: null,
      selectedConfigurationTemplate: null,
      totalRowsInFile: 0,
      availableColumnNames: [
        { id: 0, name: 'Номер полиса' },
        { id: 2, name: 'Фамилия' },
        { id: 1, name: 'Имя' },
        { id: 3, name: 'Отчество' },
        { id: 4, name: 'Дата рождения' },
        { id: 7, name: 'Номер телефона' },
        { id: 5, name: 'Дата начала полиса' },
        { id: 6, name: 'Дата окончания полиса' },
        { id: 8, name: 'Город' },
        { id: 9, name: 'Дата покупки' },
        { id: 10, name: 'Страхователь' },
        { id: 11, name: 'Стоимость прикрепления' },
        { id: 12, name: 'Стоимость открепления' },
        { id: 13, name: 'СНИЛС' },
        { id: 14, name: 'Новый объем услуг' },
        { id: 15, name: 'Старый объем услуг' },
        { id: 16, name: 'Паспорт Серия' },
        { id: 17, name: 'Паспорт Номер' },
        { id: 18, name: 'Паспорт Дата выдачи' },
        { id: 19, name: 'Паспорт Кем выдан' },
        { id: 20, name: 'Паспорт Код подразделения' },
      ],
      filteredAvailableColumnNames: [],
      miniDisplayedRows: 10,
      selectedColumnNames: [
        null, null, null, null, null,
      ],
      tableRows: [
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
        ['', '', '', '', ''],
      ],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
        //   background: '#D5D9E5',
          // gutterOfSide: '-5px',
          // gutterOfEnds: '100px',
          opacity: 0,
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#E0E0E0',
          minSize: 0.15,
          size: '5px',
        },
      },
    };
  },
  computed: {
    availableInsuranceCompanies() {
      if (this.insuranceCompanies.length) {
        return this.insuranceCompanies.filter((item) => !item.isHidden);
      }

      return [];
    },
    availableInsurancePrograms() {
      if (this.insurancePrograms.length) {
        return this.insurancePrograms.filter((item) => !item.isHidden);
      }

      return [];
    },
  },
  async created() {
    this.isLoading = true;
    await this.getClinicPackeges();
    this.filteredAvailableColumnNames = this.availableColumnNames;

    this.insuranceData = await this.$store.dispatch(this.$types.COMPANIES_FETCH);
    this.insuranceCompanies = this.insuranceData.map(({ company: { id, title, isHidden } }) => ({ id, title, isHidden }));
    this.clinics = await this.$store.dispatch(this.$types.ADMIN_CLINICS_FETCH);
    this.allClinics = await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH);
    this.templates = await this.fetchConfigurationTemplates();

    if (this.checkFeatureAccess({
      name: 'Показывать по умолчанию Территориальный фонд',
      url: '/insurance-import',
    })) this.selectedCompanyId = 15;

    this.setInsuranceEntities();

    this.isLoading = false;
    Bus.$on('update-insurance-configuration-template', this.updateConfigurationTemplates);
  },
  beforeDestroy() {
    Bus.$off('update-insurance-configuration-template', this.updateConfigurationTemplates);
  },
  methods: {
    getFormatedSelectedRowsForBackend() {
      return this.availableColumnNames.map((colType) => {
        let colIndexForThisColName = this.selectedColumnNames.indexOf(colType.id);
        if (colIndexForThisColName === -1) {
          colIndexForThisColName = null;
        }
        return { propertyId: colType.id, columnNumber: colIndexForThisColName };
      });
    },
    deleteSelectedPackageClinic(clinic) {
      this.selectedClinicsForOthers = this.selectedClinicsForOthers.filter((item) => item.id !== clinic.id);
    },
    async getClinicPackeges() {
      const responseData = await this.$store.dispatch(this.$types.CLINICS_PACKAGES_FETCH);
      const nestedLegalPersons = responseData.legalPersons.filter((item) => item.clinics.length > 0).map((item) => ({
        title: `Юр лицо ${item.name}`,
        choices: item.clinics.map((clinicItem) => ({ id: clinicItem.id, name: clinicItem.name, title: clinicItem.name })),
      }));

      const nestedPackages = responseData.packages.filter((item) => item.clinics.length > 0).map((item) => ({
        title: `Пакет ${item.name}`,
        choices: item.clinics.map((clinicItem) => ({ id: clinicItem.id, name: clinicItem.name, title: clinicItem.name })),
      }));

      const otherClinics = [{
        title: 'Клиники',
        choices: responseData.clinics.filter((item) => !!item.name)
          .map((clinicItem) => ({ id: clinicItem.id, name: clinicItem.name, title: clinicItem.name })),
      }];

      this.allClinicsByGroup = {
        title: 'Выбрать все',
        choices: [],
        nested: [
          ...nestedLegalPersons,
          ...nestedPackages,
          ...otherClinics,
        ],
      };
    },
    async createPackageCallback() {
      this.isLoading = true;
      await this.getClinicPackeges();
      this.isLoading = false;
    },
    onClickOpenPackageCreateModal() {
      const params = {
        allClinicsByGroup: { ...this.allClinicsByGroup },
        callback: this.createPackageCallback,
      };
      Bus.$emit('open-modal:clinic-package', { params });
    },
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload-modern', {
        saveFileCallback: this.saveFileCallback,
        multiple: false,
        fileApi: 'v2/policy/add-file',
        type: '.xlsx',
      });
    },

    onClickOpenImportModal() {
      const isOms = this.selectedCompanyId === 15;
      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoadingFromFile;
      const hasCompany = this.selectedCompanyId;
      // const hasProgram = this.selectedProgramId;
      // const hasSubprogram = this.selectedSubprogramId;

      if (!hasCompany) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо выбрать компанию',
        });
        return;
      }

      // if (!isOms && !hasProgram) {
      //   showMessage({
      //     type: 'warning',
      //     title: 'Ошибка',
      //     message: 'Необходимо выбрать программу',
      //   });
      //   return;
      // }

      // if (!hasSubprogram) {
      //   showMessage({
      //     type: 'warning',
      //     title: 'Ошибка',
      //     message: 'Необходимо выбрать подпрограмму',
      //   });
      //   return;
      // }

      if (isOms && !this.selectedClinic) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо выбрать клинику',
        });
        return;
      }

      if (isOms && !this.selectedConfigurationTemplate) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо выбрать шаблон',
        });
        return;
      }

      if (!hasFile) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо добавить файл',
        });
        return;
      }

      const params = {
        fileId: this.fileGUID,
        fileName: this.fileName,
        // SubprogramId: this.selectedSubprogramId,
        companyId: this.selectedCompanyId || undefined,
        programId: this.selectedProgramId || undefined,
        AllRowsCount: this.totalRowsInFile,
        Columns: this.getFormatedSelectedRowsForBackend(),
        templateId: this.selectedConfigurationTemplate?.id,
        IsAdminClinic: this.selectedCompanyId === 15 || undefined,
        clinicIdsForPolicies: this.selectedCompanyId !== 15 ? this.selectedClinicsForOthers.map((item) => item.id) : [],
        allClinicsByGroup: { ...this.allClinicsByGroup },
      };

      Bus.$emit('open-modal:insurance-import', {
        params,
      });
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },

    generateEmptyRows(rowsCount, colsCount) {
      const rows = new Array(rowsCount).fill([]);

      for (let i = 0; i < rowsCount; i += 1) {
        rows[i] = new Array(colsCount).fill('');
      }

      return rows;
    },

    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoadingFromFile = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows-new?key=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoadingFromFile = false;
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid;
      this.fileName = newFile.name;
      await this.getTableRows(10, true);
    },

    handleScroll(vertical, horizontal) { // vertical, horizontal, nativeEvent
      // console.log(vertical, horizontal);

      // Table header horizontal scroll
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
      // header.style.left = `-${value}px`;

      // Table row delete button list vertical scroll
      // const deleteList = this.$refs['row-delete-list'];
      // const deleteListScrollValue = Math.round(vertical.process * (deleteList.scrollHeight - deleteList.clientHeight));
      // deleteList.scrollTop = deleteListScrollValue;

      // console.log(deleteListScrollValue);
      // console.dir(deleteList);
    },
    selectColumnName(e, i) {
      this.selectedColumnNames[i] = e;
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.id));
    },
    async fetchConfigurationTemplates() {
      const clinicId = this.selectedClinic?.id;
      const templates = await this.$store.dispatch(this.$types.CONFIGURATION_TEMPLATE_BY_CLINICS_FETCH, {
        clinicIds: clinicId ? [clinicId] : null,
      });

      return templates.map((template) => ({ id: template.id, name: template.title }));
    },
    setInsuranceEntities() {
      if (!this.selectedCompanyId) return;

      const companyObject = this.insuranceData
        .filter(({ company: { id } }) => id === this.selectedCompanyId)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program }) => (program));
      this.insurancePrograms = programs;

      if (!this.selectedProgramId) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.selectedProgramId)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    changeCompany() {
      this.selectedProgramId = null;
      this.selectedSubprogramId = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.selectedClinic = null;
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.selectedSubprogramId = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeOmsProgram(value) {
      if (!value) {
        this.selectedSubprogramId = null;
        return;
      }

      const companyObject = this.insuranceData.filter(({ company: { id } }) => id === this.selectedCompanyId)[0];
      const programObject = companyObject.programsWithSubprograms.filter(({ program: { id } }) => id === this.selectedProgramId)[0];
      this.selectedSubprogramId = programObject.subPrograms[0].subprogramId;
    },
    setMaxHeight(dropdownList, component, { width, top, left }) {
      /* eslint-disable no-param-reassign */
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      // dropdownList.style.maxHeight = 'calc(100vh - 100px)';
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    openConfigurationTemplateAddModal() {
      Bus.$emit('open-modal:add-configuration-template');
    },
    onClinicSelect() {
      this.selectedConfigurationTemplate = null;
      this.updateConfigurationTemplates();
    },
    async updateConfigurationTemplates() {
      if (this.selectedClinic) {
        this.templates = await this.fetchConfigurationTemplates();
      } else {
        this.templates = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.crm-container {
  flex-direction: column;
}

.crm-header-block {
  margin-top: 20px;
  background: #FFFFFF;
  width: 100%;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.crm-form-row {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.crm-title {
  @extend .page-title;
  display: flex;
  align-items: center;

  span {
    margin-left: 15px;
  }
}

::v-deep.title-link {
  line-height: 0;
  cursor: pointer;

  .arrow-left {
    rect {
      stroke: #6E88F3;
    }
    path {
      fill: $blue;
    }
  }
}

.crm-container-buttons {
  display: flex;
  margin-left: auto;
}

.crm-button-file {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-right: auto;
  background-color: transparent;

  .crm-icon-cross {
    margin-right: 15px;
    path {
      fill: $blue;
    }
  }
}

.button-import {
  display: flex;
  align-items: center;
  padding: 9px 30px;
  width: unset;
  margin-left: 50px;
}

.policies-table {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px 30px  55px 30px;

  * {
    box-sizing: border-box;
  }
}

.policies-table-wrapper {
  width: 100%;
  // overflow: hidden;
}

.policies-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.policies-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.policies-table-body {
  height: 350px;
  position: relative;
}

::v-deep.policies-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
  //& + .policies-table-row {
  //  border-bottom: 1px solid #E0E0E0;
  //}
}

//.policies-table-row-delete-list {
//  position: absolute;
//  left: -50px;
//  top: 0;
//  bottom: 0;
//  z-index: 1;
//  overflow: hidden;
//}

.policies-table-button-row-delete {
  height: 40px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep.policies-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .policies-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.width-unset {
  width: auto;
}

::v-deep.specialization-filter {
  .crm-checkbox-list-wrapper {
    height: 350px !important;
  }
}

.crm-item-wrapper {
  margin-top: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .crm-item {
    margin-right: 15px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: auto;
    }
  }
}
.crm-header-group {
  display: flex;
  justify-content: space-between;
}

.specialization-filter {
  position: relative;
}
</style>
