<template>
  <div class="modal-step">
    <div class="crm-scrollbar-wrapper">
      <custom-scrollbar>
        <div class="crm-container indemtity-wrapper">
          <div class="crm-form-fields">
            <div class="crm-form-field">
              <indemnity-arow-left-button @click="clickPrev" />
              <p class="page-title">
                Добавление шаблона
              </p>
            </div>
          </div>
          <div class="crm-header-block">
            <div class="crm-search-row">
              <base-input
                v-model="query"
                :input-style="{
                  padding: '18px 30px',
                  boxShadow: '0px 1px 2px #C4C4C4',
                  borderRadius: '50px',
                }"
                :guide="false"
                placeholder="Введите название шаблона"
                fluid
                @keyup.native.enter="templateFetch"
              >
                <template #suffix>
                  <icon-search
                    class="crm-icon-search"
                    @click="templateFetch"
                  />
                </template>
              </base-input>

              <base-button
                :type="$const.PRIMARY_BUTTON"
                class="add-button"
                @click="addNewTemplate"
              >
                <icon-cross
                  class="crm-icon-cross"
                />
                Новый шаблон
              </base-button>
            </div>
          </div>
          <div class="template-wrapper">
            <div
              v-if="!isSearched"
              class="page-subtitle"
            >
              Здесь будут отображены результаты поиска
            </div>

            <div
              v-if="isSearched && !isLoading && templates.length === 0"
              class="page-subtitle"
            >
              Ничего не найдено
            </div>

            <Loading
              v-if="isLoading"
              class="crm-loading-wrapper"
            />

            <div class="template-list">
              <div
                v-for="(template, index) in templates"
                :key="index"
                class="template-item"
                :class="{
                  active: activeTemplateId === template.id
                }"
              >
                <div class="template-name">
                  {{ template.name }}

                  <div class="control-icon-wrapper">
                    <icon-edit
                      class="crm-icon-edit"
                      @click="editTemplate(template)"
                    />
                    <icon-delete
                      class="crm-icon-delete"
                      @click="openTemplateDeleteModal(template.id)"
                    />
                  </div>
                </div>
                <div class="template-service-list">
                  <div
                    v-for="(row, i) in template.rows.filter((r) => (typeof r) != 'string').slice(0, 4)"
                    :key="i"
                    class="template-service-item"
                  >
                    <div class="template-service-code">
                      {{ row.code.value }}
                    </div>

                    <div class="template-service-name">
                      {{ row.name.value }}
                    </div>

                    <div class="template-service-sum">
                      {{ row.sum.value }}
                    </div>
                  </div>
                </div>
                <div class="template-comment">
                  <span>Комментарий</span>
                  <span>
                    + {{ servicesLength(template.rows) }} услуг
                  </span>
                </div>

                <base-button
                  v-if="activeTemplateId === template.id"
                  :type="$const.PRIMARY_BUTTON"
                  class="template-apply-btn disabled"
                >
                  Выбрано
                </base-button>
                <base-button
                  v-else
                  :type="$const.SECONDARY_BUTTON"
                  class="template-apply-btn button_transparent"
                  @click="applyTemplate(template)"
                >
                  Выбрать
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

// import { api } from '@/helpers/api';
import IconEdit from 'assets/images/edit_icon.svg';

import {
  BaseInput,
  BaseButton,
} from '@/components/base';

import Loading from '@/components/Loading';
import IconSearch from 'assets/images/search.svg';
import IconCross from 'assets/images/cross.svg';
import IconDelete from 'assets/images/delete_icon.svg';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { preformatTableRows } from '../indemnityUtils';
import IndemnityArowLeftButton from './IndemnityArowLeftButton';

export default {
  name: 'IndemnityModalAddTemplateStep',
  components: {
    BaseButton,
    IndemnityArowLeftButton,
    IconSearch,
    BaseInput,
    IconEdit,
    IconDelete,
    Loading,
    IconCross,
  },
  data() {
    return {
      isLoading: false,
      isSearched: false,
      query: '', // still empty
      activeTemplateId: null,
    };
  },
  validations() {
    return {
    };
  },
  computed: {
    letter() {
      return this.$store.state.Indemnity.letter;
    },
    totalCount() {
      return this.templates.length;
    },
    ...mapGetters({
      templates: types.GET_TEMPLATES,
    }),
  },
  beforeDestroy() {
    this.$store.commit(this.$types.INDEMNITY_TEMPLATE_SET, []);
  },
  methods: {
    clickPrev() {
      this.$emit('change-step', -28);
    },
    servicesLength(row) {
      return row.filter((item) => typeof item !== 'string').length;
    },
    openTemplateDeleteModal(id) {
      Bus.$emit('open-modal:template-indemnity-delete', {
        templateId: id,
      });
    },
    async templateFetch() {
      if (this.query.length === 0) {
        this.isSearched = false;
        this.$store.commit(this.$types.INDEMNITY_TEMPLATE_SET, []);
        return;
      }
      if (this.isLoading) return;

      this.isSearched = true;
      this.isLoading = true;
      this.$store.commit(this.$types.INDEMNITY_TEMPLATE_SET, []);
      await this.$store.dispatch(this.$types.INDEMNITY_TEMPLATES_FETCH, this.query);
      this.isLoading = false;
    },
    applyTemplate(template) {
      this.activeTemplateId = template.id;
      const letter = { ...this.$store.state.Indemnity.letter };
      let { tableColumns, tableRows } = preformatTableRows(template.rows);

      const updatedTableRows = tableRows.map((row) => {
        if ((typeof row) !== 'string') {
          const newRow = { ...row };
          Object.keys(newRow).forEach((key) => {
            if (key === 'code' || key === 'name') {
              newRow[key].isVisible = true;
              return;
            }
            newRow[key].isVisible = false;
          });
          return newRow;
        }
        return row;
      });
      const updateTableColumns = { ...tableColumns };
      Object.keys(tableColumns).forEach((key) => {
        if (key === 'Код услуги' || key === 'Наименование услуги') {
          updateTableColumns[key] = true;
          return;
        }
        updateTableColumns[key] = false;
      });
      tableColumns = updateTableColumns;
      tableRows = updatedTableRows;

      const array = tableRows.map((row) => {
        const newRow = row;
        if (typeof row === 'string') return newRow;

        newRow.sum.value = +newRow.price.value * +newRow.count.value;
        return newRow;
      });
      const sumStringValue = array.reduce((sum, row) => (typeof row === 'string' ? +sum : +sum + +row.sum.value), 0);

      this.$store.commit(this.$types.INDEMNITY_LETTER_SET, {
        ...letter,
        tableRows,
        tableColumns,
        sumString: sumStringValue,
        templateId: template.id,
      });
      this.$store.dispatch(this.$types.INDEMNITY_LETTER_NUMBERS_UPDATE);
      this.clickPrev();
    },
    addNewTemplate() {
      this.$emit('change-step', 1);

      const row = {
        number: {
          isVisible: true,
          value: 0,
        },
        code: {
          isVisible: true,
          value: '',
        },
        name: {
          isVisible: true,
          value: '',
        },
        count: {
          isVisible: true,
          value: 0,
        },
        price: {
          isVisible: true,
          value: 0,
        },
        sum: {
          isVisible: true,
          value: 0,
        },
      };
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, {
        // id: null,
        name: '',
        columns: null,
        program: null,
        rows: [row],
        previewImage: null,
      });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
    },
    editTemplate(template) {
      this.$store.commit(this.$types.INDEMNITY_EDIT_TEMPLATE_SET, { ...template });
      this.$store.dispatch(this.$types.INDEMNITY_EDIT_TEMPLATE_NUMBERS_UPDATE);
      this.$emit('change-step', 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-search-row {
  display: flex;
}

.crm-header-block {
  width: 100%;
}

.template-wrapper {
  width: 100%;
  margin-top: 30px;
}

.add-button {
  border-radius: 50px;
  margin-left: 30px;
  height: 54px;
}

::v-deep.crm-icon-search {
  path {
    fill: #ffffff;
  }
}

.page-subtitle {
  text-align: center;
  color: #908F8D;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
}

.template-list {
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px -7.5px 0;
}

.template-item {
  margin: 7.5px;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 15px 15px 10px;
  width: calc(33% - 14px);
  display: flex;
  flex-direction: column;
}

.template-item.active {
  border: 2px solid $blue;
}

.template-name {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
  min-height: 60px;
  display: flex;
  justify-content: space-between;
}

.template-service-list {
  margin-top: 10px;
  font-size: 12px;
  line-height: 16px;
  color: #908F8D;
  margin-bottom: auto;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.template-service-item {
  display: flex;
  border-bottom: 1px solid #F3F3F3;
  padding-bottom: 5px;

  & + & {
    margin-top: 5px;
  }
}

.template-service-code {
  margin-right: 5px;
}

.template-service-name {
  margin-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.template-service-sum {
  margin-left: auto;
}

.template-comment {
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  justify-content: space-between;
}

.template-apply-btn {
  margin: 10px auto 0;
}

.template-apply-btn.disabled {
  pointer-events: none;
}

.button_transparent {
  background-color: transparent;
  color: $blue;
  font-weight: 600;
  border: 1px solid transparent;
}

.crm-icon-cross {
  margin-right: 15px;
}

::v-deep.crm-icon-search {
  cursor: pointer;
  path {
    fill: $gray;
  }

  &:hover {
    path {
      fill: $blue;
    }
  }
}

::v-deep.crm-icon-edit {
  cursor: pointer;
  margin-left: 15px;
  flex-shrink: 0;

  &:hover {
    rect {
      stroke: $blue;
    }
    path {
      fill: $blue;
    }
  }
}
.control-icon-wrapper{
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}
.crm-icon-delete {
  cursor: pointer;
  margin: 0.80rem 0 0.55rem 0;
  &:hover {
    cursor: pointer;

    // eslint-disable-next-line vue-scoped-css/no-unused-selector
    rect {
      stroke: #6E88F3;
    }
  }
}
</style>
