var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"policy-delete"},[_c('custom-scrollbar',[_c('div',{staticClass:"container policy-container"},[_c('div',{staticClass:"clinics-form-fields"},[_c('base-input',{staticClass:"crm-form-field",attrs:{"input-style":{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          },"label":"Куда переносим данные ","placeholder":"Введите id учетной записи"},model:{value:(_vm.personIdTo),callback:function ($$v) {_vm.personIdTo=$$v},expression:"personIdTo"}}),_c('base-input',{staticClass:"crm-form-field",attrs:{"input-style":{
            padding: '11px 15px 12px',
            resize: 'none',
            backgroundColor: '#ffffff',
          },"label":"Откуда переносим данные","placeholder":"Введите id учетной записи"},model:{value:(_vm.personIdFrom),callback:function ($$v) {_vm.personIdFrom=$$v},expression:"personIdFrom"}})],1),_c('div',{staticClass:"crm-description"},[_vm._v(" В основную учетную запись перенесутся все данные "),_c('br'),_vm._v(" из дочерней учетной записи ")]),_c('div',{staticClass:"crm-wrapper-buttons"},[_c('base-button',{staticClass:"crm-button",attrs:{"type":_vm.$const.SECONDARY_BUTTON},on:{"click":_vm.onClickClose}},[_vm._v(" Отменить ")]),_c('base-button',{staticClass:"crm-button",attrs:{"type":_vm.$const.PRIMARY_BUTTON,"loading":_vm.isLoading},on:{"click":_vm.onClickTransfer}},[_vm._v(" Объединить ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }