<template>
  <div class="mb-3">
    <div class="h3 mb-0">
      NPS МП пациента
    </div>
  </div>
</template>

<script>
export default {
  name: 'NpsTitle',
};
</script>

<style lang="scss" scoped>
</style>
