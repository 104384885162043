<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'Nps',
  page: {
    title: 'NPS МП Пациента',
  },
  created() {
    if (this.$route.path === '/statistics/nps') {
      this.$router.push('/statistics/nps/surveys');
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
