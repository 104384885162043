<template>
  <v-modal
    v-model="componentValue"
    size="l"
    :title="title"
    @input="onClose"
    class="nps-surveys-modal"
  >
    <div class="d-flex flex-column mt-4 gap-6">
      <VInput
        v-model="editableSurvey.title"
        :label="$v.editableSurvey.title.$error ? 'Это поле обязательно для заполнения' : 'Название опроса'"
        :error="$v.editableSurvey.title.$error"
        content-width
        placeholder="Введите название опроса"
      />
      <VTextArea
        v-model="editableSurvey.description"
        label="Описание опроса (необязательно)"
        placeholder="Придумайте описание опроса"
      />
      <div class="d-flex gap-2 justify-between">
        <div class="nps-surveys-modal_switch-item">
          <BaseSwitcher
            v-model="editableSurvey.stars"
            label="Звёзды"
          />
        </div>
        <div class="nps-surveys-modal_switch-item">
          <BaseSwitcher
            v-model="editableSurvey.option"
            label="Чек-боксы"
          />
        </div>
        <div class="nps-surveys-modal_switch-item">
          <BaseSwitcher
            v-model="editableSurvey.comment"
            label="Комментарий"
          />
        </div>
      </div>
      <div class="d-flex flex-column gap-4">
        <template v-if="editableSurvey.options">
          <div v-for="(option, index) in editableSurvey.options" :key="option.id">
            <ServeysOptionCard
              :index="index"
              :option="option"
              @error="optionError"
              @addNewOption="addNewOption"
            />
          </div>
        </template>
        <VButton
          label="Добавить ответ"
          size="m"
          :left-icon="{name: 'plus', size: 'm'}"
          type="light"
          class="w-fit"
          @click="addOption"
        />
      </div>
    </div>
    <div class="d-flex pt-6 justify-content-end">
      <VButton
        label="Сохранить"
        size="m"
        @click="saveServeyEdit"
      />
    </div>
  </v-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import {
  VModal,
  VInput,
  VTextArea,
  VButton,
} from '@doctis.front/doctis.designsystem';
import BaseSwitcher from '@/components/base/BaseSwitcher.vue';
import ServeysOptionCard from './components/ServeysOptionCard.vue';

export default {
  name: 'NpsSurveysModal',
  components: {
    VModal,
    VInput,
    VTextArea,
    BaseSwitcher,
    VButton,
    ServeysOptionCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    surveysEditObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      editableSurvey: {
        ...this.surveysEditObject,
        options: this.surveysEditObject.options
          ? this.surveysEditObject.options.map((option) => ({ ...option }))
          : [],
      },
      optionsErrors: {},
    };
  },
  validations() {
    return {
      editableSurvey: {
        title: {
          required,
        },
      },
    };
  },
  computed: {
    componentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onClose() {
      this.componentValue = false;
    },
    addOption() {
      this.editableSurvey.options.push({
        active: true,
      });
    },
    addNewOption(option, index) {
      if (index !== undefined) {
        // Обновляем опцию по индексу, объединяя старые и новые свойства
        this.$set(this.editableSurvey.options, index, {
          ...this.editableSurvey.options[index],
          ...option,
        });
      } else {
        console.error('Индекс не передан для обновления опции');
      }
    },
    optionError(error, index) {
      // Обновляем ошибку для конкретной опции
      this.$set(this.optionsErrors, index, error);
    },
    saveServeyEdit() {
      this.$v.$touch();
      const hasOptionsError = Object.values(this.optionsErrors).some((error) => error === true);
      if (hasOptionsError) {
        return;
      }
      this.$emit('updateServeyCard', this.editableSurvey);
      this.onClose();
    },
  },
};
</script>

<style lang="scss">
  .nps-surveys-modal {
    p {
      margin-bottom: 0;
    }
    &_switch-item {
      padding: 8px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F3F4F5;
      border-radius: 8px;
      color: #161E2F;
      width: 33%;
      height: 40px;
      .base-switcher {
        width: 100%;
        .crm-label {
          flex: 1;
          line-height: 1.2;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
</style>
