<template>
  <base-photo-edit
    :photo-url-prop="photoUrlProp"
    :image-height="imageHeight"
    :image-width="imageWidth"
    :save-callback="saveCallback"
    @cancel="onFinished"
    @save="onFinished"
  />
</template>

<script>
import { BasePhotoEdit } from '@/components/base';

export default {
  name: 'InsuranceCompanyPhotoModal',
  components: {
    BasePhotoEdit,
  },
  props: {
    photoUrlProp: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: Number,
      default: 200,
    },
    imageWidth: {
      type: Number,
      default: 200,
    },
    saveCallback: {
      type: Function,
      default: async () => {},
    },
  },
  methods: {
    onFinished() {
      this.$emit('vuedals:close');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
