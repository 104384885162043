<template>
  <div class="flex flex-column gap-4">
    <div
      v-for="survey in surveys"
      :key="survey.id"
      class="quest-card"
    >
      <div class="quest-card_head">
        <span class="title">{{ survey.title }}</span>
        <VAttribute
          :variant="survey.active ? 'success' : 'warning'"
          :content="survey.active ? 'Опубликован' : 'Остановлен'"
          :left-icon="{name: survey.active ? 'check' : 'pause', size: 'm'}"
        />
      </div>
      <div class="quest-card_body">
        <div v-if="survey.stars" class="quest-card_body-stars">
          <VIcon
            v-for="index in 5"
            :key="index"
            disabled
            name="star"
            size="s"
          />
        </div>

        <div class="quest-card_body-check-list">
          <p class="title">
            Что можно улучшить?
          </p>
          <ul>
            <li v-for="option in survey.options" :key="option.id" >
              <BaseCheckbox
                :value="isChecked"
                class="base-checkbox"
                disabled
              />
              <span>{{ option.title }}</span>
            </li>
          </ul>
        </div>
        <p v-if="survey.description">
          {{ survey.description }}
        </p>
        <VAttribute
          v-if="survey.comment"
          class="quest-card_body-attribute"
          content="Поле для комментария"
          :left-icon="{name: 'check', size: 'm'}"
        />
      </div>
      <div class="quest-card_footer">
        <VButton
          label="Редактировать"
          size="m"
          :left-icon="{name: 'edit', size: 'm'}"
          @click="editModal(survey)"
        />
        <VButton
          :label="!survey.active ? 'Опубликовать' : 'Остановить'"
          size="m"
          :left-icon="{name: !survey.active ? 'play' : 'pause', size: 'm'}"
          @click="changeStatus(survey.active, survey.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  VAttribute,
  VIcon,
  VButton,
} from '@doctis.front/doctis.designsystem';
import { BaseCheckbox } from '@/components/base';

export default {
  name: 'QuestCard',
  components: {
    VAttribute,
    VIcon,
    VButton,
    BaseCheckbox,
  },
  props: {
    surveys: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    editModal(survey) {
      this.$emit('editModal', survey);
    },
    changeStatus(status, id) {
      this.$emit('changeStatus', status, id);
    },
  },
};
</script>

<style lang="scss">
.quest-card {
  background: #fff;
  border-radius: 16px;
  &_head {
    padding: 24px 16px 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E8E8EA;
    .title {
      font-weight: 500;
      font-size: 20px;
      color: #161E2F;
    }
  }
  &_body {
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-stars {
      display: flex;
      align-items: center;
      gap: 8px;
      svg {
        path {
          fill: #D0D2D5;
          stroke: #D0D2D5;
        }
      }
    }
    &-check-list {
      .title {
        font-weight: 500;
        color: #161E2F;
      }
      ul {
        li {
          padding: 12px 16px;
          display: flex;
          align-items: center;
          color: #161E2F;
          font-size: 14px;
          font-weight: 500;
          .base-checkbox {
            display: flex;
          }
          &:not(:last-child) {
            border-bottom: 1px solid #E8E8EA;
          }
          label {
            margin-bottom: 0;
          }
        }
      }
    }
    &-attribute {
      justify-content: flex-start;
    }
  }
  &_footer {
    display: flex;
    align-items: center;
    padding: 0 16px 16px 16px;
    gap: 8px;
  }
}
</style>
