<template>
  <div
    :class="{ disabled }"
    class="crm-tabs"
  >
    <div
      v-for="tab in items"
      :key="tab.value"
      class="crm-tab-wrapper"
    >
      <span
        v-if="tab.description"
        class="crm-tab-title"
      >
        {{ tab.description }}
      </span>

      <div
        :class="{ active: tab.value === value, highlight: tab.highlight }"
        class="crm-tab"
        @click="onClickTab(tab.value)"
      >
        {{ tab.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTabs',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickTab(newValue) {
      if (this.disabled) return;

      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-tabs {
  width: 100%;
  display: flex;
}
.crm-tab-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.crm-tab-title {
  color: $gray-dark;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.crm-tab {
  display: flex;
  padding: 15px 0;
  border-bottom: 5px solid $blue-light;
  color: $gray-dark;
  font-size: 18px;
  line-height: 22px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;

  &.active {
    border-bottom: 5px solid $blue;
    color: $blue;
  }
}
</style>
