<template>
  <div class="pt-4 w-100 containerLabsPage">
    <custom-scrollbar>
      <b-container
        fluid
      >
        <b-overlay
          :show="isLoading"
          variant="transparent"
          no-wrap
        />
        <transition
          name="component-fade"
          mode="out-in"
        >
          <div
            v-if="!isLoading"
            class="pt-4"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Наименование партнерской услуги"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-input
                    v-model="currentPartnerServiceData.name"
                    class="mb-2"
                    disabled
                    placeholder="Партнерская услуга"
                  />
                  <transition name="slide-fade">
                    <span
                      v-if="currentPartnerServiceData.description"
                      class="partnerServiceDescription"
                    >
                      Состав: {{ currentPartnerServiceData.description }}
                    </span>
                  </transition>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Юридическое лицо"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-input
                    v-model="legalPartnerData.legalName"
                    disabled
                    placeholder="Юридическое лицо"
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="legalPartnerData.partner">
              <b-col>
                <b-form-group
                  label="Партнер"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-input
                    v-model="legalPartnerData.partner.name"
                    disabled
                    placeholder="Партнер"
                    class="mb-2"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <div
                    class="d-flex justify-content-between align-items-center mb-4"
                  >
                    <span class="font-weight-bold">Наш перечень услуг</span>
                    <b-input-group class="w-25">
                      <template #append>
                        <b-button
                          class="border-secondary bg-white"
                          variant="outline-primary"
                          @click="searchSimilarServices"
                        >
                          <b-icon
                            variant="primary"
                            icon="search"
                          />
                        </b-button>
                      </template>
                      <b-form-input
                        v-model="serviceNameSearch"
                        class="border-secondary"
                        placeholder="Поиск"
                        type="text"
                        @keyup.native.enter="searchSimilarServices"
                      />
                    </b-input-group>
                  </div>
                  <transition-group name="fade">
                    <div
                      v-if="optionsSimilarServices.length"
                      key="1"
                      class="swiper"
                    >
                      <swiper
                        :id="id"
                        :options="swiperOption"
                      >
                        <swiper-slide
                          v-for="(service, index) in optionsSimilarServices"
                          :key="index"
                        >
                          <b-card-group
                            deck
                          >
                            <template>
                              <b-card
                                border-variant="secondary"
                                header-border-variant="secondary"
                                footer-border-variant="secondary"
                                header-tag="header"
                                footer-tag="footer"
                                style="width: 18.8rem; min-height: 20rem"
                              >
                                <template #header>
                                  <b-button
                                    class="w-100"
                                    variant="primary"
                                    @click="onClickMap(service.id)"
                                  >
                                    Выбрать
                                  </b-button>
                                </template>
                                <b-card-text class="serviceName">
                                  {{ service.name }}
                                </b-card-text>
                                <template #footer>
                                  <span class="serviceDescription">
                                    {{ service.description }}
                                  </span>
                                </template>
                              </b-card>
                            </template>
                          </b-card-group>
                        </swiper-slide>
                        <div
                          slot="pagination"
                          class="swiper-pagination"
                        />
                      </swiper>
                      <div
                        class="swiper-button-prev"
                        :class="'prev-' + id "
                      />
                      <div
                        class="swiper-button-next"
                        :class="'next-' + id "
                      />
                    </div>
                    <div
                      v-else
                      key="2"
                      class="pl-2 mb-2"
                    >
                      <span>Ничего не найдено <b-icon
                        icon="exclamation-triangle-fill"
                        variant="danger"
                      /></span>
                    </div>
                  </transition-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Добавить новую услугу"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-group
                    label="Код услуги"
                  >
                    <b-form-input
                      id="nested-street"
                      v-model="$v.serviceCode.$model"
                      class="w-25"
                      type="text"
                      :state="validateState('serviceCode')"
                      :placeholder="'Введите код услуги'"
                      trim
                    />
                    <b-form-invalid-feedback
                      id="input-1-live-feedback"
                    >
                      Данное поле обязательно*
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label="Название услуги"
                  >
                    <b-form-input
                      id="nested-street"
                      v-model="$v.serviceName.$model"
                      class="w-75"
                      type="text"
                      :state="validateState('serviceName')"
                      :placeholder="'Введите название услуги'"
                      trim
                    />
                    <b-form-invalid-feedback
                      id="input-1-live-feedback"
                    >
                      Данное поле обязательно*
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    label="Описание услуги"
                  >
                    <b-form-input
                      id="nested-street"
                      v-model="$v.serviceDescription.$model"
                      type="text"
                      :state="validateState('serviceDescription')"
                      :placeholder="'Введите описание услуги'"
                      class="w-100"
                      trim
                    />
                    <b-form-invalid-feedback
                      id="input-1-live-feedback"
                    >
                      Данное поле обязательно*
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-button
                    variant="primary"
                    @click="onClickCreateService"
                  >
                    Сохранить услугу
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'PartnerServiceBind',
  components: {
    Swiper,
    SwiperSlide,
  },
  mixins: [validationMixin],
  props: {
    partnerServiceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      importFile: null,
      optionsServices: [],
      optionsSimilarServices: [],
      serviceId: null,
      currentPartnerServiceData: [],
      serviceNameSearch: '',
      legalPartnerData: [],
      serviceName: '',
      serviceCode: '',
      serviceCost: null,
      serviceDescription: '',
      isActive: null,
      id: 'swiperCards',
      nextPartnerServiceId: '',
      swiperOption: {
        // pagination: {
        //   el: '.swiper-pagination',
        // },
        slidesPerView: Number(3),
        spaceBetween: Number(5),
        autoplay: false,
        navigation: {
          nextEl: '.next-swiperCards',
          prevEl: '.prev-swiperCards',
        },
      },
    };
  },
  validations: {
    serviceName: { required },
    serviceCode: { required },
    serviceDescription: { required },
  },
  computed: {
    serviceListStore() {
      return this.$store.state.Laboratories.serviceList;
    },
  },
  async created() {
    this.isLoading = true;

    await this.currentPartnerServiceDataFetch();
    this.serviceId = this.currentPartnerServiceData.serviceId;
    await this.fetchSimilarServices();
    this.serviceName = this.currentPartnerServiceData.name;
    this.serviceDescription = this.currentPartnerServiceData.description
      ? this.currentPartnerServiceData.description : this.currentPartnerServiceData.name;

    const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNER_FOR_ID, this.currentPartnerServiceData.legalPartnerId);
    this.legalPartnerData = data;

    this.isLoading = false;
  },
  methods: {
    async onClickMap(serviceId) {
      const params = {
        partnerServiceGuid: this.currentPartnerServiceData.id,
        serviceGuid: serviceId,
      };

      await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_MAP, params);
      await this.currentPartnerServiceDataFetch();
    },
    async currentPartnerServiceDataFetch() {
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_PARTNER_SERVICES_FOR_ID, this.partnerServiceId);
      this.currentPartnerServiceData = data;
    },
    async fetchServices() {
      await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, { Name: this.serviceNameSearch });
      this.optionsServices = this.serviceListStore.data;
    },
    async fetchSimilarServices() {
      const { data: { data } } = await this.$store.dispatch(this.$types.LABORATORY_PARTNER_SERVICE_GET_SIMILAR_SERVICE, this.partnerServiceId);
      this.optionsSimilarServices = data;
    },
    async onClickCreateService() {
      if (!this.onCheckValidation()) return;
      const params = {
        name: this.serviceName,
        code: this.serviceCode,
        description: this.serviceDescription,
      };

      const { data: { data } } = await this.$store.dispatch(this.$types.LABORATORY_SERVICE_CREATE, params);
      await this.onClickMap(data.id);

      await this.fetchSimilarServices();
      this.serviceCode = '';
      this.serviceDescription = '';
      this.serviceName = '';
      this.$v.$reset();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    async searchSimilarServices() {
      if (this.serviceNameSearch) {
        await this.fetchServices();
        this.optionsSimilarServices = this.serviceListStore.data;
      } else {
        await this.fetchSimilarServices();
      }
    },
    onCheckValidation() {
      this.$v.serviceName.$touch();
      this.$v.serviceCode.$touch();
      this.$v.serviceDescription.$touch();
      if (this.$v.serviceName.$anyError
        || this.$v.serviceCode.$anyError
        || this.$v.serviceDescription.$anyError
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss">
.partnerServiceDescription{
  color: #6C757D;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.154px;
}
.serviceDescription{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #212529;
}
.serviceName{
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 120%;
  color: #212529;
}
.swiper-slide {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.swiper-pagination-bullet-active {
  background: #fff;
}
.swiper {
  position: relative;
}
</style>
