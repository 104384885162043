<template>
  <div class="package-wrapper">
    <div class="package-inner">
      <template v-if="!isImported">
        <div class="px-4">
          <div class="mb-2">
            Будет импортирован файл: "{{ params.fileName }}" <br>
          </div>

          <div>
            Количество строк: {{ params.AllRowsCount }}
          </div>
        </div>

        <div class="crm-container-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button-file"
            @click="onClose"
          >
            <span>Отменить</span>
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="button-import"
            @click="startImport"
          >
            <span>Импортировать</span>
          </base-button>
        </div>
      </template>

      <template v-else>
        <template v-if="isImportingState">
          <div class="crm-info-text">
            Идет импорт файла. Загрузка может занять до 15 мин. Не прерывайте загрузку
          </div>
          <preloader style="margin-top: 20px" />
        </template>

        <template v-if="isSuccessCompleteState">
          <div class="crm-info-text">
            Импортирование успешно завершено. Загружено: {{ importedData }} из {{ totalData }}
          </div>
          <div class="crm-container-buttons">
            <base-button
              :type="$const.PRIMARY_BUTTON"
              class="button-import"
              @click="onFinished"
            >
              <span>ОК</span>
            </base-button>
          </div>
        </template>
        <template v-if="isErrorCompleteState">
          <div class="crm-info-text">
            Импорт частично завершен<br>
            Загружено: {{ importedData }} из {{ totalData }}<br>
            <span style="color: #e76666;">Невозможно импортировать: {{ notImportedData }} из {{ totalData }}</span>
          </div>
          <div class="crm-container-buttons">
            <base-button
              :type="$const.PRIMARY_BUTTON"
              class="button-import"
              @click="downloadTable"
            >
              <span>Скачать таблицу</span>
            </base-button>
            <base-button
              :type="$const.SECONDARY_BUTTON"
              class="crm-button-file"
              @click="onClose"
            >
              <span>Отменить</span>
            </base-button>
          </div>
          <div class="additional-text mt-4">
            Таблица содержит неимпортированные данные.
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
} from '@/components/base';

import Preloader from '@/components/Preloader';
import { api } from '@/helpers/api';
import {
  saveFile,
  showMessage,
} from '@/helpers/utils';

export default {
  name: 'ReferralDutyConsultationImportModal',
  components: {
    BaseButton,
    Preloader,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isImported: false,
      isImportingState: false,
      isSelectImportTypeState: true,
      isSuccessCompleteState: false,
      isErrorCompleteState: false,

      importedData: 0,
      notImportedData: 0,
      totalData: 0,
      fileGUIDForDownload: '',
    };
  },
  methods: {
    clearState() {
      this.isImportingState = false;
      this.isSelectImportTypeState = false;
      this.isSuccessCompleteState = false;
      this.isErrorCompleteState = false;
    },
    async downloadTable() {
      try {
        const { data: fileName } = await api.get(`File/name/${this.fileGUIDForDownload}`);
        const { data: file } = await api.get(`File/${this.fileGUIDForDownload}`, { responseType: 'blob' });
        saveFile(file, { name: fileName });
      } catch (error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: `
            Не удалось скачать файл
            ${error}
          `,
        });
      }
    },
    onClose() {
      this.$emit('vuedals:close');
    },
    onFinished() {
      this.$emit('vuedals:close');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    async startImport() {
      try {
        this.clearState();
        this.isImported = true;
        this.isImportingState = true;

        const {
          data: {
            allRowsCount, errorsCount, successCount, fileWithErrorsId,
          },
        } = await api.post(`v2/consultations/dutyDoctor/${this.params.queue}/import`, this.params);

        this.notImportedData = errorsCount;
        this.totalData = allRowsCount;
        this.importedData = successCount;

        this.clearState();
        if (errorsCount) {
          this.fileGUIDForDownload = fileWithErrorsId;
          this.isErrorCompleteState = true;
        } else {
          this.isSuccessCompleteState = true;
        }
      } catch (e) {
        this.clearState();
        this.isErrorCompleteState = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .crm-container-buttons {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .crm-info-text, .additional-text {
    color: #908F8D;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
  }
  .additional-text {
    font-size: 12px;
    line-height: 16px;
  }
</style>
