<template>
  <div class="policy-activate">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите активировать полис?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            :loading="isActivating"
            class="crm-button"
            @click="onClickActivate"
          >
            Активировать
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PolicyActivateModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isActivating: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickActivate() {
      this.isActivating = true;

      try {
        await this.$store.dispatch(this.$types.PATIENT_POLICY_ACTIVATE, this.policy);

        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
      } catch (e) {
        console.log(e);
      } finally {
        this.isActivating = false;
        this.$emit('vuedals:close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
