<template>
  <loading v-if="isLoading">
    Загрузка данных
  </loading>
  <div
    v-else
    class="configuration-template-add"
  >
    <div class="crm-form-row">
      <base-input
        v-model="saveData.title"
        :input-style="{
          padding: '11px 10px 12px',
          resize: 'none',
        }"
        class="crm-form-field"
        placeholder="Введите название шаблона"
      />
    </div>
    <div class="crm-form-row">
      <div class="crm-form-field">
        <v-select
          v-model="saveData.region"
          :options="regions"
          :clearable="false"
          placeholder="Выберите регион"
          label="name"
        />
      </div>
    </div>
    <div class="crm-form-row">
      <v-select
        v-model="saveData.clinic"
        :options="clinics"
        label="name"
        class="crm-form-field"
        placeholder="Выберите клинику"
      />
    </div>
    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.PRIMARY_BUTTON"
        class="crm-button"
        :loading="isSaving"
        @click="onSave"
      >
        Сохранить шаблон
      </base-button>
    </div>
  </div>
</template>

<script>
import Bus from '@/eventBus';

import { showMessage } from '@/helpers/utils';

import {
  BaseButton,
  BaseInput,
} from '@/components/base';
import Loading from '@/components/Loading';

export default {
  name: 'ConfigurationTemplateAddModal',
  components: {
    BaseButton,
    BaseInput,
    Loading,
  },
  props: {},
  data() {
    return {
      isSaving: false,
      isLoading: false,
      regions: [],
      clinics: [],
      saveData: {
        title: null,
        region: null,
        clinic: null,
      },
    };
  },
  async created() {
    this.isLoading = true;
    this.regions = await this.$store.dispatch(this.$types.DOCTOR_FETCH_REGIONS);
    this.clinics = await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH);
    this.isLoading = false;
  },
  methods: {
    async onSave() {
      if (!this.saveData.title) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Введите название шаблона',
        });
        return;
      }

      if (!this.saveData.region) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Выберите регион',
        });
        return;
      }

      if (!this.saveData.clinic) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Выберите клинику',
        });
        return;
      }

      try {
        this.isSaving = true;
        const requestObject = {
          title: this.saveData.title,
          region: this.saveData.region?.id,
          clinicIds: this.saveData.clinic?.id ? [this.saveData.clinic?.id] : [],
          url: '',
        };

        await this.$store.dispatch(this.$types.CONFIGURATION_TEMPLATE_CREATE, requestObject);

        Bus.$emit('update-insurance-configuration-template');
        this.$emit('vuedals:close');
      } catch (error) {
        console.dir(error);
      } finally {
        this.isSaving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-wrapper-buttons {
  margin-top: 30px;
}

.crm-form-row + .crm-form-row {
  margin-top: 20px;
}

.crm-button {
  width: unset;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
