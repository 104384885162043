<template>
  <div
    :class="[variant,'crm-wrap-block',{['crm-fluid']: fluid }]"
  >
    <!-- <template v-if="material">
      <masked-input
        v-if="mask"
        ref="input"
        v-model="localValue"
        :mask="mask"
        :guide="guide"
        :style="{
          ...inputStyle,
        }"
        :class="{ error }"
        class="crm-base-input-material"
        v-on="eventHandlers"
      />

      <input
        v-else
        ref="input"
        v-model="localValue"
        type="text"
        :class="{ error }"
        class="crm-base-input-material"
        required
        v-on="eventHandlers"
      >
      <span class="bar-material" />

      <label class="label-material">{{ label }}</label>

      <div
        class="crm-input-suffix"
      >
        <i
          v-if="clearable && localValue && localValue.length"
          :style="{
            marginRight: $slots.suffix ? '10px' : '',
            cursor: disabled ? 'not-allowed' : '',
          }"
          class="clear-icon"
          @click.stop="onClickClear"
        />
      </div>
    </template> -->

    <!-- <template v-else> -->
    <div
      v-if="label && !material"
      :class="{ required }"
      class="crm-label"
    >
      <span>{{ label }}</span>

      <span
        v-if="sublabel"
        class="crm-sublabel"
      >
        {{ sublabel }}
      </span>
    </div>

    <div
      :class="{
        'with-prefix': !!$slots.prefix,
        'with-suffix': !!$slots.suffix,
        'with-clearable': clearable,
      }"
      class="crm-wrap-input"
    >
      <textarea
        v-if="type === 'textarea'"
        ref="input"
        v-model="localValue"
        v-bind="{
          ...inputProps,
          ...textareaProps
        }"
        :style="{
          ...inputStyle,
        }"
        :class="{ error, 'crm-base-input_material': material }"
        class="crm-base-input"
        :autocomplete="autocomplete"
        v-on="eventHandlers"
      />

      <masked-input
        v-else-if="mask"
        ref="input"
        v-model="localValue"
        v-bind="inputProps"
        :mask="mask"
        :guide="guide"
        :style="{
          ...inputStyle,
        }"
        :class="{ error, 'crm-base-input_material': material, }"
        class="crm-base-input"
        :autocomplete="autocomplete"
        v-on="eventHandlers"
      />

      <input
        v-else
        ref="input"
        v-model="localValue"
        v-bind="inputProps"
        :style="{
          ...inputStyle,
        }"
        :class="{ error, 'crm-base-input_material': material }"
        class="crm-base-input"
        :autocomplete="autocomplete"
        v-on="eventHandlers"
      >

      <label
        v-if="material"
        :class="{ required }"
        class="label_material"
      >{{ label }}</label>

      <div
        class="crm-input-prefix"
      >
        <slot name="prefix" />
      </div>

      <div
        class="crm-input-suffix"
      >
        <i
          v-if="clearable && localValue && localValue.length"
          :style="{
            marginRight: $slots.suffix ? '10px' : '',
            cursor: disabled ? 'not-allowed' : '',
          }"
          class="clear-icon"
          @click.stop="onClickClear"
        />

        <div
          v-if="type === 'number' && !disableArrows"
          class="crm-input-arrows"
        >
          <div
            :class="{ disabled }"
            class="crm-input-arrow-block"
            @mousedown="onMouseDown('up')"
            @mouseup="onMouseUp('up')"
            @mouseout="onMouseOut"
          >
            <icon-arrow-up
              class="crm-input-arrow"
            />
          </div>

          <div
            :class="{ disabled }"
            class="crm-input-arrow-block"
            @mousedown="onMouseDown('down')"
            @mouseup="onMouseUp('down')"
            @mouseout="onMouseOut"
          >
            <icon-arrow-down
              class="crm-input-arrow"
            />
          </div>
        </div>

        <slot name="suffix" />
      </div>
    </div>
    <!-- </template> -->

    <template v-if="error && errors.length">
      <div
        class="validation-errors"
      >
        <span
          class="validation-error-text"
        >
          {{ errors[0] }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import IconArrowUp from 'assets/images/input-arrow-up.svg';
import IconArrowDown from 'assets/images/input-arrow-down.svg';

export default {
  name: 'BaseInput',
  components: {
    IconArrowUp,
    IconArrowDown,
    MaskedInput,
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    base: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    inputStyle: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    sublabel: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    textareaProps: {
      type: Object,
      default: () => ({}),
    },
    material: {
      type: Boolean,
      default: false,
    },
    knowledgeTemplate: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: [Array, Function, Object, String],
      default: null,
    },
    guide: {
      type: Boolean,
      default: false,
    },
    disableArrows: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
    min: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      localValue: this.value,
      isArrowPressed: false,
      arrowInterval: null,
      arrowTimeout: null,
    };
  },
  computed: {
    inputProps() {
      const props = {
        placeholder: this.material ? '' : this.placeholder,
        required: this.material ? true : this.required,
        type: this.type,
        readonly: this.readonly,
        disabled: this.disabled,
      };

      if (this.min || this.min === 0) props.min = this.min;

      return props;
    },
    eventHandlers() {
      return {
        input: this.oninputValue,
        focus: (event) => this.$emit('focus', event),
        keydown: (event) => this.$emit('keydown', event),
        blur: (event) => this.$emit('blur', event),
        change: () => this.$emit('change', this.localValue),
      };
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    oninputValue() {
      if (this.maxLength !== null) {
        this.localValue = this.localValue.substring(0, this.maxLength);
      }

      this.$emit('input',
        this.type === 'number'
          ? parseInt(this.localValue, 10) || 0
          : this.localValue);
    },
    onClickClear() {
      this.localValue = '';

      this.$emit('input', this.localValue);
    },
    onMouseDown(arrow) {
      if (this.disabled) return;

      this.arrowTimeout = setTimeout(() => {
        this.isArrowPressed = true;
      }, 101);

      this.arrowInterval = setInterval(() => {
        if (this.isArrowPressed) {
          this.changeValArrow(arrow);
        }
      }, 100);
    },
    onMouseUp(arrow) {
      if (this.disabled) return;

      this.changeValArrow(arrow);

      this.isArrowPressed = false;
      clearInterval(this.arrowInterval);
    },
    onMouseOut() {
      clearInterval(this.arrowInterval);
    },
    changeValArrow(arrow) {
      if (arrow === 'up') {
        this.localValue = (this.localValue || 0) + 1;
      }
      if (arrow === 'down') {
        this.localValue = (this.localValue || 0) - 1;
      }

      this.oninputValue();
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-wrap-block {
  display: flex;
  flex-direction: column;
  position: relative;

  &.crm-fluid {
    flex-grow: 1;

    .crm-wrap-input {
      width: 100%;
    }
  }

  //RM = remote monitoring
  &.rm {
    input {
      height: 32px;
    }
  }
}
.crm-label-material {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #999;
  font-size: 18px;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.crm-base-input-material {
  width: 100%;
  box-sizing: border-box;
  font-size: 16px;
  padding: 10px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  &:focus {
    outline: none;
  }

  &:focus ~ label,
  &:valid ~ label
    {
      top: -15px;
      font-size: 14px;
      color: #5264AE;
    }
  &:focus,
  &.error {
    & ~ .bar-material:before,
    & ~ .bar-material:after {
      width: 50%;
    }
  }

  &.error {
    & ~ .bar-material:before,
    & ~ .bar-material:after {
      background: $red;
    }
  }
}
.bar-material {
  position: relative;
  display: block;
  width: 100%;

  &:before,
  &:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0;
    position: absolute;
    background: #5264AE;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  &:before {
    left: 50%;
  }
  &:after {
    right: 50%
  }
}
.crm-base-input {
  @include base-input;
  width: 100%;

  &[disabled] {
    cursor: not-allowed;
    background-color: $gray-disabled;
  }
  &.error {
    border: 1px solid $red;
  }

  &::placeholder {
    color: $gray-dark;
  }

  &:focus {
    border-color: $blue;
  }

  &_material {
    height: 50px;
    font-size: 14px;
    line-height: 14px;
    padding: 19px 10px 17px;

    &:focus ~ label,
    &:valid ~ label {
        top: 3px;
        font-size: 10px;
        line-height: 14px;
      }
  }
}
.label_material {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color:#908F8D;
  font-size: 14px;
  line-height: 14px;
  position: absolute;
  pointer-events: none;
  left: 10px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;

  &.required {
    &::after {
      content: '*';
      color: $red;
      margin-left: 5px;
    }
  }
}
.crm-wrap-input {
  position: relative;

  &.with-prefix {
    .crm-base-input {
      padding-left: 32px !important;
    }
  }
  &.with-suffix {
    .crm-base-input {
      padding-right: 42px !important;
    }

    &.with-clearable {
      .crm-base-input {
        padding-right: 56px !important;
      }
    }
  }
}
.crm-input-prefix,
.crm-input-suffix {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 16px;
  color: $gray;
}
.crm-input-prefix {
  left: 10px;
}
.crm-input-suffix {
  right: 16px;
}
.crm-input-arrows {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.crm-input-arrow-block:not(.disabled) {
  cursor: pointer;

  &:hover {
    path {
      fill: $blue;
    }
  }
}
.crm-input-arrow-block.disabled {
  cursor: not-allowed;
}
// removing arrows from type number

//Chrome and other
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Firefox
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}
.clear-icon {
  cursor: pointer;
  background-image: url("~assets/images/clear_icon.svg?inline");
  width: 16px;
  height: 16px;
}
</style>
