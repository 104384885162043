import axios from 'axios';
import store from '@/store';

class AxiosClient {
  constructor($axios) {
    this.instance = $axios;

    this.instance.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${store.state.Auth.token}`,
      },
    }));
  }
}

const AxiosUpdatedClient = new AxiosClient(axios.create());
export default AxiosUpdatedClient;
