<template>
  <div class="d-flex bg-white pr-4 pt-4 pb-4 border rounded">
    <div
      class="mr-4 text-center"
      style="width: 170px; flex-shrink: 0;"
    >
      <div
        class="btn-link h6 p-0 mb-2"
      >
        <!-- :href="`tel:${message.number}`" -->
        {{ message.number }}
      </div>
      <div class="text-secondary mb-2">
        {{ time }}
      </div>
      <div
        :class="[ successfullySended ? 'text-success' : 'text-danger']"
      >
        {{ status }}
      </div>
    </div>

    <div class="mr-4">
      {{ messageText }}
    </div>

    <b-button
      variant="outline-primary"
      class="ml-auto align-self-start"
      size="sm"
      style="flex-shrink: 0;"
      @click="openSmsSendModal"
    >
      Отправить новое
    </b-button>
  </div>
</template>

<script>
import {
  parseISO, format,
} from 'date-fns';

export default {
  name: 'SmsInfo',
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    time() {
      return this.message.sendDate ? format(parseISO(this.message.sendDate), 'dd.MM.yyyy HH:mm') : '—';
    },
    successfullySended() {
      return this.message.isSent && !this.message.isError;
    },
    status() {
      return this.successfullySended ? 'отправлено' : 'не отправлено';
    },
    messageText() {
      return this.message.message.replace(/\\n/gmi, '\n');
    },
  },
  methods: {
    openSmsSendModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'SmsSendModal',
        props: {
          numberProp: this.message.number,
          name: null,
        },
      });
    },
  },
};
</script>
