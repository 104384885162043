<template>
  <div class="clinic-info">
    <Loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка клиники
    </Loading>

    <template v-else>
      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Сеть клиник
            </p>
            <span class="crm-info-text">
              {{ clinicNetworkTitle }}
            </span>
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Название клиники
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.title || '—' }}
            </span>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Название клиники для приложения
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.appTitle || '—' }}
            </span>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              ФИО руководителя
              <span class="crm-sublabel">
                (в родительном падеже)
              </span>
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.leader.fio || '—' }}
            </span>
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Должность руководителя

              <span class="crm-sublabel">
                (в родительном падеже)
              </span>
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.leader.position || '—' }}
            </span>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Город
            </p>
            <span class="crm-info-text">
              {{ cityTitle }}
            </span>
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Адрес
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.address || '—' }}
            </span>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Координаты
            </p>

            <div class="crm-form-fields">
              <div class="crm-form-field">
                <span class="coord-label">Широта</span>
                <span class="coord-text">Тестовая широта</span>
              </div>
              <div class="crm-form-field">
                <span class="coord-label">Долгота</span>
                <span class="coord-text">Тестовая долгота</span>
              </div>
            </div>
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              ОГРН

              <span class="crm-sublabel">
                (без пробелов)
              </span>
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.legalInformation.ogrn || '—' }}
            </span>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <div class="crm-form-field">
          <div class="crm-form-fields">
            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  ИНН
                  <span class="crm-sublabel">
                    (без пробелов)
                  </span>
                </p>
                <span class="crm-info-text">
                  {{ clinicInfo.legalInformation.inn || '—' }}
                </span>
              </div>
            </div>

            <div class="crm-form-field">
              <div class="crm-form-block">
                <p class="crm-info-title">
                  КПП
                  <span class="crm-sublabel">
                    (без пробелов)
                  </span>
                </p>
                <span class="crm-info-text">
                  {{ clinicInfo.legalInformation.kpp || '—' }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-info-title">
              Веб-сайт
            </p>
            <span class="crm-info-text">
              {{ clinicInfo.webSite }}
            </span>
          </div>
        </div>
      </div>

      <div
        v-if="hasFiles"
        class="clinics-form-fields"
      >
        <div class="crm-form-field">
          <div class="crm-form-block">
            <p class="crm-label">
              Договор
            </p>

            <div class="clinics-form-fields documents">
              <div
                v-for="(file) in computedFiles"
                :key="file.title"
                class="crm-form-field"
              >
                <base-file
                  :file="file"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="clinics-form-fields">
        <span class="crm-info-title">Контакты</span>
      </div>

      <div class="clinics-form-fields contacts">
        <contacts-info
          v-for="(contactType, i) in contactTypes"
          :key="i"
          :title="contactType.title"
          class="clinics-contact-info"
        >
          <div
            v-for="(contact) in contacts[contactType.type]"
            :key="contact.phoneNumber"
            class="contact-form"
          >
            <div class="contact-form-item">
              <p class="contact-form-label">
                Телефон
              </p>

              <p class="contact-form-info">
                {{ contact.phoneNumber || '—' }}
              </p>
            </div>

            <div class="contact-form-item">
              <p class="contact-form-label">
                E-mail
              </p>

              <p class="contact-form-info">
                {{ contact.email || '—' }}
              </p>
            </div>

            <div class="contact-form-item">
              <p class="contact-form-label">
                Комментарий
              </p>

              <p class="contact-form-info">
                {{ contact.comment || '—' }}
              </p>
            </div>
          </div>
        </contacts-info>
      </div>
    </template>
  </div>
</template>

<script>
import { BaseFile } from '@/components/base';

import { CONTACT_TYPE } from '@/helpers/consts';

import ContactsInfo from '@/components/Clinics/ContactsInfo';
import Loading from '@/components/Loading';

export default {
  name: 'ClinicInfoModal',
  components: {
    BaseFile,
    ContactsInfo,
    Loading,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      clinicInfo: null,
      contactTypes: CONTACT_TYPE,
    };
  },
  computed: {
    hasFiles() {
      return Object.keys(this.clinicInfo.agreementFiles).length > 0;
    },
    computedFiles() {
      let files = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.clinicInfo.agreementFiles)) {
        files = [...files, { guid: key, title: value }];
      }

      return files;
    },
    clinicNetworkTitle() {
      return this.clinicInfo.clinicNetwork?.title || '—';
    },
    cityTitle() {
      return this.clinicInfo.city?.title || '—';
    },
    contacts() {
      const contacts = {};

      this.clinicInfo.contacts.forEach((contact) => {
        const { type } = this.contactTypes.find((contactType) => contactType.index === contact.type);

        if (!contacts[type]) {
          contacts[type] = [contact];
        } else {
          contacts[type] = [...contacts[type], contact];
        }
      });

      return contacts;
    },
  },
  async created() {
    try {
      this.isLoading = true;

      const clinicInfo = await this.$store.dispatch(this.$types.CLINIC_FETCH, this.id);

      this.clinicInfo = clinicInfo;
    } catch (e) {
      console.warn(e);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.clinic-info {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 1px;
}
.crm-form-block {
  width: 100%;
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.crm-info {
  &-title, &-text {
    font-size: 16px;
    line-height: 20px;
  }

  &-title {
    color: $black-light;
    margin: 0 0 15px 0;
    font-weight: 500;
  }

  &-text {
    font-weight: 600;
    color: $blue-magenta;
  }
}
.coord-label {
  color: #908F8D;
  margin-right: 15px;
}
.coord-text {
  color: $blue-magenta;
}
.contact-form {
  width: 100%;

  &-label {
    margin: 0 0 3px 0;
    font-size: 10px;
    line-height: 12px;
    color: $gray-dark;
  }

  &-info {
    margin: 0;
    color: $black;
  }

  &-item {
    padding-bottom: 5px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(218, 218, 218, 0.7);
  }
}
</style>
