<template>
  <div class="crm-file-modal">
    <div
      v-if="files.length"
      class="files-wrapper"
    >
      <custom-scrollbar>
        <div
          v-if="filesPending.length"
          class="files"
        >
          <p class="files-title files-title_pending">
            В очереди на отправку
          </p>

          <div
            v-for="(file, index) in filesPending"
            :key="index"
            class="crm-file-wrapper"
          >
            <span class="crm-file-name">
              {{ file.name }}
            </span>
          </div>
        </div>

        <div
          v-if="filesSuccess.length"
          class="files"
        >
          <p class="files-title files-title_success">
            Успешно загружены
          </p>

          <div
            v-for="(file, index) in filesSuccess"
            :key="index"
            class="crm-file-wrapper"
          >
            <span class="crm-file-name">
              {{ file.name }}
            </span>
          </div>
        </div>

        <div
          v-if="filesError.length"
          class="files"
        >
          <p class="files-title files-title_error">
            Ошибка загрузки
          </p>

          <div
            v-for="(file, index) in filesError"
            :key="index"
            class="crm-file-wrapper"
          >
            <span class="crm-file-name">
              {{ file.name }}
            </span>
          </div>
        </div>
      </custom-scrollbar>
    </div>

    <base-file-input
      v-if="multiple || !files.length"
      :value="computedFilesForInput"
      :multiple="multiple"
      :type="type"
      @change="onAddFiles"
    >
      Загрузить файл
    </base-file-input>

    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickClose"
      >
        Отменить
      </base-button>

      <base-button
        :type="$const.PRIMARY_BUTTON"
        :disabled="isDisabledContinueButton"
        class="crm-button"
        @click="onClickContinue"
      >
        Продолжить
      </base-button>
    </div>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';

import {
  BaseButton,
  BaseFileInput,
} from '@/components/base';

export default {
  name: 'FileModal',
  components: {
    BaseButton,
    BaseFileInput,
  },
  props: {
    saveFileCallback: {
      type: Function,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    fileApi: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    otherProps: null,
  },
  data() {
    return {
      files: [],
      filesInput: [],

      isSaving: false,
    };
  },
  computed: {
    filesPending() {
      return this.files.filter((file) => file.status === 'pending');
    },
    filesSuccess() {
      return this.files.filter((file) => file.status === 'success');
    },
    filesError() {
      return this.files.filter((file) => file.status === 'error');
    },
    computedFilesForInput() {
      return this.filesInput.map((file) => file.file);
    },
    isDisabledContinueButton() {
      return !!this.filesPending.length;
    },
  },
  methods: {
    onAddFiles(event) {
      this.files = [...this.files, ...event.map((file) => ({ name: file.name, status: 'pending', file }))];

      this.filesPending.forEach((fileObject) => this.sendFile(fileObject));
    },
    async sendFile(fileObject) {
      const extension = getFileExtension(fileObject.name);

      try {
        const fd = new FormData();

        if (this.fileApi) {
          fd.append('file', fileObject.file);
        }
        const params = new URLSearchParams();
        params.append('fileName', fileObject.name);

        const { data: { guid } } = await api.post(this.fileApi || `File/${extension}`, this.fileApi ? fd : fileObject.file, {
          headers: {
            'Content-Type': fileObject.file.type,
          },
          params,
        });

        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'success', guid });
      } catch (e) {
        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'error' });
      }
    },
    onClickClose() {
      this.$emit('vuedals:close');
    },
    onClickContinue() {
      this.saveFileCallback(this.filesSuccess, this.otherProps);
      this.$emit('vuedals:close');
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-file-wrapper {
  margin-bottom: 20px;
}

.files-wrapper {
  height: 400px;
}
.crm-file-name {
  font-weight: 500;
}
.crm-wrapper-buttons {
  margin-top: 30px;
}
.files-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0;

  &_pending {
    color: $orange;
  }

  &_success {
    color: $green;
  }

  &_error {
    color: $red;
  }
}
label{
  display: block;
}
</style>
