import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.GET_NPS_OPTIONS_COUNT]: (state) => state.optionsCount,
  [localTypes.GET_NPS_SURVEYS]: (state) => state.surveysList,
  [localTypes.GET_NPS_LOADING]: (state) => state.isLoading,
};
