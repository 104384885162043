/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import { removeNamespaces, showMessage /* appendFormData */ } from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.ADMIN_CLINICS_FETCH]() {
    NProgress.start();
    try {
      const { data: clinics } = await api.get('v2/clinic/adminClinics');

      NProgress.done();
      return clinics;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_HISTORY_FETCH](_, clinicId) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/clinic/history?clinicId=${clinicId}`);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_PRICES_IMPORT](ctx, {
    clinicId, type, importType, file, startDate,
  }) {
    NProgress.start();

    const params = new URLSearchParams();
    if (startDate) params.append('startDate', startDate);
    params.append('clinicId', clinicId);
    params.append('type', type);
    params.append('importType', importType);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await api.post('v2/price/import', formData, {
        'Content-Type': 'multipart/form-data',
        params,
      });

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Прайс добавлен.',
      });

      return data;
    } catch (e) {
      console.warn(e);
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось добавить прайс.',
      });
      throw e;
    } finally {
      NProgress.done();
    }
  },
  // async [localTypes.FETCH_PRICES_LIST]({ commit }, {
  //   code,
  //   name,
  //   type,
  //   isBind,
  //   isActive = true,
  //   skip,
  //   take = 25,
  // }) {
  //   NProgress.start();
  //   commit(localTypes.SET_LIST_PRICES_LOADING, true);
  //
  //   try {
  //     const body = {
  //       code,
  //       name,
  //       type,
  //       isBind,
  //       isActive,
  //       skip,
  //       take,
  //     };
  //
  //     const { data } = await api.post('v2/price/list-prices', body);
  //
  //     commit(localTypes.SET_LIST_PRICES, data.data.paginatedCollection);
  //     commit(localTypes.SET_LIST_PRICES_PAGINATION_COUNT, data.data.originalCount);
  //   } catch (e) {
  //     console.error(e);
  //   } finally {
  //     NProgress.done();
  //     commit(localTypes.SET_LIST_PRICES_LOADING, false);
  //   }
  // },
  // async [localTypes.CLINIC_PRICES_FETCH](ctx, {
  //   clinicId,
  //   name,
  //   code,
  //   type,
  //   take = 10,
  //   skip,
  // }) {
  //   NProgress.start();
  //
  //   try {
  //     const body = {
  //       clinicId,
  //       take,
  //     };
  //     if (name != null) body.name = name;
  //     if (code != null) body.code = code;
  //     if (type != null) body.type = type;
  //     if (skip != null) body.skip = skip;
  //
  //     const { data: { data } } = await api.post('v2/price/list-clinic-prices', body);
  //
  //     return data;
  //   } catch (e) {
  //     console.warn(e);
  //     showMessage({
  //       type: 'error',
  //       title: 'Ошибка',
  //       message: 'Не удалось загрузить список прайсов.',
  //     });
  //     throw e;
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  // async [localTypes.CLINIC_PRICES_HISTORY_FETCH](_, clinicId) {
  //   NProgress.start();
  //
  //   try {
  //     const { data } = await api.get(`v2/clinic/services/history?clinicId=${clinicId}`);
  //     NProgress.done();
  //     return data;
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  // async [localTypes.CLINIC_PRICES_LIST_FETCH](_, params) {
  //   NProgress.start();
  //   let searchString = '';
  //   if (params.search) searchString = params.search;
  //   try {
  //     const { data } = await api.get(`v2/clinic/services/list?clinicId=${params.clinicId}&searchString=${searchString}`);
  //     NProgress.done();
  //     return data;
  //   } finally {
  //     NProgress.done();
  //   }
  // },
  async [localTypes.CITY_CREATE](_, params) {
    NProgress.start();
    try {
      await api.post('/Cities', params);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Город успешно сохранен!',
      });
      NProgress.done();
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось сохранить город',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINICS_FETCH]({ commit }, {
    skip,
    take,
    filter,
    clear,
    save,
    isDeleted,
  }) {
    NProgress.start();

    if (clear) {
      commit(localTypes.CLINICS_SET, []);
    }

    try {
      const { data: clinics } = await api.post('v2/clinic/search', {
        searchString: filter.query,
        clinicNetworkId: filter.network && filter.network.id,
        cityId: filter.city && filter.city.id,
        services: filter.services,
        inn: filter.inn,
        // пока не исправили на массив - отправляю один
        clinicAttribute: filter.clinicAttribute,
        skip,
        take,
        isDeleted,
      });

      if (save) {
        commit(localTypes.CLINICS_ADD, clinics);
      }
      NProgress.done();
      return clinics;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINICS_NAME_FETCH]({ commit }, { searchString, save } = {}) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      if (searchString) params.append('searchString', searchString);

      const { data: clinics } = await api.get('v2/clinic/getListNames', { params });

      if (save) commit(localTypes.CLINIC_LIST_NAMES_SET, clinics);

      NProgress.done();
      return clinics;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.NETWORK_FETCH](ctx, { query, skip, take }) {
    try {
      const params = new URLSearchParams();
      if (query) params.append('text', query);
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      const { data } = await api.get('/clinic/networks/search/', { params });
      return Array.isArray(data) ? data : [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.NETWORK_V2_FETCH](ctx, { query, skip, take }) {
    try {
      const params = new URLSearchParams();
      if (query) params.append('title', query);
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);
      const { data } = await api.get('v2/clinicNetworks/search', { params });

      return Array.isArray(data) ? data : [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.NETWORK_LIST_FETCH]() {
    try {
      const { data } = await api.get('v2/clinicNetworks/list');

      return Array.isArray(data) ? data : [];
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.CLINICS_PACKAGES_FETCH]() {
    try {
      const { data } = await api.get('v2/clinicPackages/listForSearch?withPackages=true');

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.LEGAL_PERSONS_FETCH](ctx, {
    skip,
    take,
    query,
  }) {
    NProgress.start();
    try {
      const params = new URLSearchParams();
      if (query) params.append('name', query);
      if (take) params.append('take', take);
      if (skip) params.append('skip', skip);

      params.append('skip', skip ?? undefined);

      const { data } = await api.get('v2/clinic/listLegalPerson', { params });
      return Array.isArray(data) ? data : [];
      // NProgress.done();
    } catch (e) {
      console.warn(e);
      return [];
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CITIES_FETCH](cxt, { query }) {
    try {
      const params = new URLSearchParams();
      params.append('name', query);
      const { data } = await api.get('Cities/list', { params });

      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.REGIONS_FETCH](_, { isActive = true } = {}) {
    try {
      const params = new URLSearchParams();
      params.append('isActive', isActive);

      const { data } = await api.get('v2/regions/list', { params });
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.CITIES_ALL_FETCH](cxt, query) {
    try {
      // const params = new URLSearchParams();
      // params.append('name', query);
      const { data } = await api.get('Cities/list', { name: query });
      return data;
    } catch (e) {
      console.warn(e);
      return [];
    }
  },
  async [localTypes.CLINIC_FETCH](ctx, id) {
    NProgress.start();

    try {
      const { data: clinic } = await api.get(`v2/clinic/${id}`);

      return clinic;
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информацию о клинике',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_RECOVERY]({ commit }, id) {
    NProgress.start();

    try {
      await api.post(`v2/clinic/remove?clinicId=${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Клиника успешно восстановлена!',
      });
      commit(localTypes.CLINIC_REMOVE_BY_ID, id);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось восстановить клинику',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_DELETE]({ commit }, id) {
    NProgress.start();

    try {
      await api.post(`v2/clinic/remove?clinicId=${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Клиника успешно удалена!',
      });
      commit(localTypes.CLINIC_REMOVE_BY_ID, id);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить клинику',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_REMOVE]({ commit }, id) {
    NProgress.start();

    try {
      await api.post(`v2/clinic/remove?clinicId=${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Клиника успешно удалена!',
      });
      commit(localTypes.CLINIC_REMOVE_BY_ID, id);
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить клинику',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_CREATE](ctx, clinic) {
    NProgress.start();

    try {
      // const fomattedClinic = new FormData();
      // appendFormData(fomattedClinic, clinic);

      const { data: { data: createdClinic } } = await api.post('v2/clinic', clinic);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Клиника успешно добавлена!',
      });

      return createdClinic;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_UPDATE](ctx, clinic) {
    NProgress.start();

    try {
      // const fomattedClinic = new FormData();
      // eslint-disable-next-line no-param-reassign
      // delete clinic.specializations;

      // appendFormData(fomattedClinic, clinic);
      const prefomattedClinic = {
        ...clinic,
        // doctors: clinic.doctors.map((doc) => ({ id: doc.id })),
      };
      const { data: { data: updatedClinic } } = await api.put('v2/clinic', prefomattedClinic);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Клиника успешно изменена!',
      });

      return updatedClinic;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.CLINIC_DISMONITORING_BIND_TOGGLE](ctx, { clinicId, isBind }) {
    NProgress.start();

    try {
      const params = new URLSearchParams();
      params.append('clinicId', clinicId);
      params.append('isBind', isBind);

      await api.put('v2/account/bind-or-remove-clinic-to-dismonitoring-operator', null, { params });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  [localTypes.CLINICS_PINNED_DOCTORS_TOGGLE]({ state, commit }, doctor) {
    if (state.pinnedDoctors.find((doc) => doc.id === doctor.id)) {
      commit(localTypes.CLINICS_PINNED_DOCTORS_SET, state.pinnedDoctors.filter((doc) => doc.id !== doctor.id));
    } else {
      commit(localTypes.CLINICS_PINNED_DOCTORS_SET, [...state.pinnedDoctors, doctor]);
    }
  },
  async [localTypes.REGION_CREATE](_, region) {
    NProgress.start();

    try {
      await api.post('v2/regions', region);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Регион успешно создан!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REGION_UPDATE](_, region) {
    NProgress.start();

    try {
      await api.put('v2/regions', region);

      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Регион успешно изменен!',
      });
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.REGION_DELETE](_, id) {
    NProgress.start();

    try {
      await api.delete(`v2/regions?id=${id}`);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Регион успешно удален!',
      });
    } catch (e) {
      console.warn(e);

      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось удалить регион',
      });

      throw new Error(e);
    } finally {
      NProgress.done();
    }
  },
};
