<template>
  <div class="crm-table-wrapper">
    <custom-scrollbar>
      <div class="services-table-container">
        <div
          v-if="servicesData.services && !servicesData.services.length"
          class="crm-table-empty"
        >
          Нет данных
        </div>
        <div
          v-if="servicesData.services && servicesData.services.length"
          class="crm-table"
        >
          <div class="crm-table-row crm-table-row--header">
            <div
              v-for="item in rowData"
              :key="item.name"
              class="crm-table-cell"
              :style="{ width: item.width }"
              :class="item.class"
            >
              {{ item.name }}
            </div>
          </div>

          <div
            v-for="(item, idx) in servicesData.services"
            :key="idx"
            class="crm-table-row"
          >
            <div
              class="crm-table-cell"
              :style="{ width: rowData[0].width }"
              :class="rowData[0].class"
            >
              {{ serviceDate(item.date) }}
            </div>
            <div
              class="crm-table-cell"
              :style="{ width: rowData[1].width }"
              :class="rowData[1].class"
            >
              {{ item.legalPerson?.name }}
            </div>
            <div
              class="crm-table-cell"
              :style="{ width: rowData[2].width }"
              :class="rowData[2].class"
            >
              {{ item.name }}
            </div>
            <div
              class="crm-table-cell"
              :style="{ width: rowData[3].width }"
              :class="rowData[3].class"
            >
              {{ item.price }} ₽
            </div>
            <div
              class="crm-table-cell"
              :style="{ width: rowData[4].width }"
              :class="rowData[4].class"
            >
              {{ item.count }}
            </div>
            <div
              class="crm-table-cell"
              :style="{ width: rowData[5].width }"
              :class="rowData[5].class"
            >
              {{ item.totalCost }} ₽
            </div>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import {
  format,
  parseISO,
} from 'date-fns';

export default {
  name: 'PersonServicesTable',
  data() {
    return {
      rowData: [
        {
          name: 'Дата',
          width: '102px',
        },
        {
          name: 'ЮрЛицо',
          width: '',
          class: 'crm-fluid',
        },
        {
          name: 'Название услуги',
          width: '',
          class: 'crm-fluid',
        },
        {
          name: 'Стоимость',
          width: '112px',
        },
        {
          name: 'Кол-во',
          width: '82px',
        },
        {
          name: 'Итого',
          width: '108px',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      servicesData: types.GET_PATIENT_SERVICES,
    }),
  },
  beforeDestroy() {
    this.$store.commit(this.$types.PATIENT_SERVICES_DEL);
  },
  methods: {
    serviceDate(date) {
      return format(parseISO(date), 'dd.MM.yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-table-wrapper {
  height: 500px;
  margin-top: 20px;
}
.crm-table{
  border-radius: 10px;
  border: 1px solid #E0E0E0;
  overflow: hidden;
}
.crm-table-row{
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  & + & {
    border-top: 1px solid #E0E0E0;
  }
  &--header{
    background: #F3F3F3;
    color: #6E88F3;
  }
}
.crm-table-cell{
  padding: 10px;
  flex-shrink: 0;
  & + & {
    border-left: 1px solid #E0E0E0;
  }
  &.crm-fluid {
    flex-shrink: 1;
    width: 100%;
  }
}
.crm-table-empty{
  padding: 20px 0px 0px 10px;
}
</style>
