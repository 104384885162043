<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="queue.id ? 'Редактирование очереди' : 'Добавление очереди'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-form-group
        label="Название"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.queueData.name.$error"
      >
        <b-form-input
          v-model="$v.queueData.name.$model"
          placeholder="Введите название"
          required
        />
      </b-form-group>

      <b-form-group
        label="Описание"
        label-class="required"
        invalid-feedback="Поле является обязательным"
        :state="!$v.queueData.description.$error"
      >
        <b-form-input
          v-model="$v.queueData.description.$model"
          placeholder="Введите описание"
          required
        />
      </b-form-group>

      <b-form-group class="my-4">
        <div class="d-flex mb-2">
          <label>
            Прикрепление врачей через клинику
          </label>

          <b-button
            :variant="clinicTableShow ? 'secondary' : 'primary'"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleClinicTableShow"
          >
            {{ clinicTableShow ? 'Скрыть' : 'Выбрать клинику' }}
          </b-button>
        </div>

        <template v-if="clinicTableShow">
          <b-form-input
            v-model="clinicSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск клиники по названию"
            size="sm"
            trim
            class="mb-2"
          />

          <div
            v-if="clinicsFiltered.length"
            style="max-height: 200px; overflow-y: auto;"
            class="mb-4"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="clinic in clinicsFiltered"
                  :key="clinic.id"
                >
                  <b-td>
                    {{ clinic.name }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      :variant="isClinicSelected(clinic.id) ? 'danger' : 'success'"
                      size="sm"
                      :disabled="isClinicLoading"
                      @click="onClinicSelect(clinic.id, clinic.name)"
                    >
                    <b-icon :icon="isClinicSelected(clinic.id) ? 'trash' : 'plus'" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <small
            v-if="clinicSearchString && !clinicsFiltered.length"
            class="ml-2 mb-4"
          >
            Клиника не найдена
          </small>
        </template>
        <div v-if="clinicsSelected.length">
          <label>
            Выбранные клиники
          </label>
          <b-table-simple
            bordered
            small
          >
            <b-tbody>
              <b-tr
                v-for="clinic in clinicsSelected"
                :key="clinic.id"
              >
                <b-td>
                  {{ clinic.name }}
                </b-td>
                <b-td style="width: 40px">
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="onClinicSelect(clinic.id, clinic.name)"
                  >
                    <b-icon icon="trash" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </b-form-group>

      <b-form-group>
        <div class="d-flex mb-2">
          <label>
            Прикрепленные врачи
          </label>

          <b-button
            :variant="doctorTableShow ? 'secondary' : 'primary'"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleDoctorTableShow"
          >
            {{ doctorTableShow ? 'Скрыть' : 'Добавить врача' }}
          </b-button>
        </div>

        <b-table-simple
          v-if="queueData.doctors.length"
          bordered
          small
        >
          <b-tbody>
            <b-tr
              v-for="doctor in queueData.doctors"
              :key="doctor.id"
            >
              <b-td>
                {{ doctor.name }}
              </b-td>
              <b-td style="width: 40px">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="onDoctorRemove(doctor.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-else
          class="text-secondary"
        >
          Врачи не добавлены
        </div>

        <template v-if="doctorTableShow">
          <b-form-input
            v-model="doctorSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск врача по фио"
            size="sm"
            trim
            class="mt-4 mb-2"
          />

          <div
            v-if="filteredDoctors.length"
            style="max-height: 200px; overflow-y: auto;"
            class="mb-4"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="doctor in filteredDoctors"
                  :key="doctor.id"
                >
                  <b-td>
                    {{ doctor.name }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="success"
                      size="sm"
                      @click="onDoctorAdd(doctor)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="doctorSearchString && !filteredDoctors.length"
            class="ml-2 mb-4"
          >
            Врач не найден
          </small>
        </template>
      </b-form-group>

      <b-form-group>
        <div class="d-flex mb-2">
          <label>
            Прикрепленные тэги
          </label>

          <b-button
            :variant="tagTableShow ? 'secondary' : 'primary'"
            class="ml-auto"
            size="sm"
            style="width: 150px"
            @click="toggleTagTableShow"
          >
            {{ tagTableShow ? 'Скрыть' : 'Добавить тэг' }}
          </b-button>
        </div>

        <b-table-simple
          v-if="queueData.tags.length"
          bordered
          small
        >
          <b-tbody>
            <b-tr
              v-for="tag in queueData.tags"
              :key="tag.id"
            >
              <b-td>
                {{ tag.name }}
              </b-td>
              <b-td>
                {{ tag.comment || 'Нет комментария' }}
              </b-td>
              <b-td style="width: 40px">
                <b-button
                  variant="danger"
                  size="sm"
                  @click="onTagRemove(tag.id)"
                >
                  <b-icon icon="trash" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <div
          v-else
          class="text-secondary"
        >
          Тэги не добавлены
        </div>

        <template v-if="tagTableShow">
          <b-form-input
            v-model="tagSearchString"
            type="text"
            debounce="500"
            placeholder="Поиск тэга по названию"
            size="sm"
            trim
            class="mb-2 mt-4"
          />

          <div
            v-if="filteredTags.length"
            style="max-height: 200px; overflow-y: auto;"
          >
            <b-table-simple
              small
              bordered
              class="mb-0"
            >
              <b-tbody>
                <b-tr
                  v-for="tag in filteredTags"
                  :key="tag.id"
                >
                  <b-td>
                    {{ tag.name }}
                  </b-td>
                  <b-td>
                    {{ tag.comment || 'Нет комментария' }}
                  </b-td>
                  <b-td style="width: 40px">
                    <b-button
                      variant="success"
                      size="sm"
                      @click="onTagAdd(tag)"
                    >
                      <b-icon icon="plus" />
                    </b-button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>

          <small
            v-if="tagSearchString && !filteredTags.length"
            class="ml-2"
          >
            Тэг не найден
          </small>
        </template>
      </b-form-group>
    </template>

    <template #modal-footer>
      <b-button
        variant="success"
        class="float-right"
        @click="onSave"
      >
        Сохранить
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showMessage } from '@/helpers/utils';

import Preloader from '@/components/Preloader';

export default {
  name: 'QueueEditModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    queue: {
      type: Object,
      default: () => ({}),
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterSave: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      isClinicLoading: false,
      clinicSearchString: '',
      doctorSearchString: '',
      tagSearchString: '',
      clinicTableShow: false,
      doctorTableShow: false,
      tagTableShow: false,
      clinics: [],
      clinicsFiltered: [],
      clinicsSelected: [],
      doctors: [],
      tags: [],
      queueData: {
        id: null,
        name: null,
        description: null,
        doctors: [],
        tags: [],
      },
    };
  },
  validations() {
    return {
      queueData: {
        id: {},
        name: { required },
        description: { required },
        tags: {},
      },
    };
  },
  computed: {
    filteredDoctors() {
      return this.doctors.filter((d) => {
        if (this.doctorSearchString && !d.name.toLowerCase().includes(this.doctorSearchString.toLowerCase())) return false; // search by doctor name from doctorSearchString
        return !this.queueData.doctors.find((dd) => dd.id === d.id); // return false if doctor added to userdata
      });
    },
    filteredTags() {
      return this.tags.filter((t) => {
        if (this.tagSearchString && !t.name.toLowerCase().includes(this.tagSearchString.toLowerCase())) return false; // search by tag name from tagSearchString
        return !this.queueData.tags.find((tt) => tt.id === t.id); // return false if tag added to userdata
      });
    },
  },
  watch: {
    clinicSearchString() {
      this.filteredClinics();
    },
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.queue.id) {
        this.queueData = await this.$store.dispatch(this.$types.QUEUE_FETCH, { id: this.queue.id });
        this.queueData.doctors = this.queueData.doctors.map((d) => ({ id: d.id, name: d.fullName }));
      }

      this.tags = await this.$store.dispatch(this.$types.QUEUES_TAGS_FETCH);
      this.clinics = await this.$store.dispatch(this.$types.CLINICS_NAME_FETCH);
      this.clinicsFiltered = this.clinics;
      this.doctors = (await this.$store.dispatch(this.$types.DOCTORS_FETCH))
        .map((doctor) => ({ id: doctor.id, name: doctor.fullName }));
    } catch (error) {
      console.log(error);
    }

    this.isLoading = false;
  },
  methods: {
    filteredClinics() {
      this.clinicsFiltered = this.clinics.filter((c) => {
        if (!c?.name || (this.clinicSearchString && !c?.name.toLowerCase().includes(this.clinicSearchString.toLowerCase()))) return false;
        return true;
      });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    isClinicSelected(clinicId) {
      return this.clinicsSelected.some((clinic) => clinic.id === clinicId);
    },
    async onClinicSelect(id, name) {
      // Проверяем, добавлена ли клиника
      const clinicIndex = this.clinicsSelected.findIndex((clinic) => clinic.id === id);

      if (clinicIndex !== -1) {
        // Удаляем клинику
        this.clinicsSelected.splice(clinicIndex, 1);
        this.queueData.doctors = this.queueData.doctors.filter((doctor) => doctor.clinicId !== id);
      } else {
        // Добавляем клинику
        this.isClinicLoading = true;
        this.clinicsSelected.push({ id, name }); // Сохраняем объект с id и name

        try {
          const { doctors } = await this.$store.dispatch(this.$types.CLINIC_FETCH, id);
          if (doctors.length) {
            doctors.forEach((d) => {
              if (this.queueData.doctors.find((dd) => dd.id === d.id)) return; // Пропускаем, если врач уже есть

              const doctor = {
                id: d.id,
                clinicId: id, // Привязываем врача к клинике
                name: `${d.lastName} ${d.firstName} ${d.middleName}`,
              };

              this.queueData.doctors.push(doctor);
            });
          } else {
            this.$bvToast.toast('У клиники нет врачей', {
              title: 'Предупреждение',
              autoHideDelay: 5000,
              variant: 'warning',
            });
          }
        } catch (error) {
          console.error('Ошибка при загрузке врачей:', error);
        } finally {
          this.isClinicLoading = false;
        }
      }
    },
    onDoctorRemove(id) {
      this.queueData.doctors = this.queueData.doctors.filter((d) => d.id !== id);
    },
    onDoctorAdd(doctor) {
      if (this.queueData.doctors.find((d) => d.id === doctor.id)) return; // return if doctor already in array
      if (doctor) this.queueData.doctors.push(doctor);
    },
    onTagRemove(id) {
      this.queueData.tags = this.queueData.tags.filter((t) => t.id !== id);
    },
    onTagAdd(tag) {
      if (this.queueData.tags.find((t) => t.id === tag.id)) return; // return if tag already in array
      if (tag) this.queueData.tags.push(tag);
    },
    toggleClinicTableShow() {
      this.clinicTableShow = !this.clinicTableShow;
      this.clinicSearchString = '';
    },
    toggleDoctorTableShow() {
      this.doctorTableShow = !this.doctorTableShow;
      this.doctorSearchString = '';
    },
    toggleTagTableShow() {
      this.tagTableShow = !this.tagTableShow;
      this.tagSearchString = '';
    },
    async onSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types[this.queue.id ? 'QUEUE_UPDATE' : 'QUEUE_CREATE'], {
          ...this.queueData,
          doctors: this.queueData.doctors.map((d) => d.id),
          tags: this.queueData.tags.map((t) => t.id),
        });

        this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
        this.afterSave();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
