<template>
  <div class="d-flex flex-wrap">
    <div
      v-for="(document, docIndex) in documents"
      :key="`document-${docIndex}`"
      class="document-item d-flex h6 align-items-center mr-4 mb-1"
    >
      <legal-entity-document-item :document="document" />

      <div class="actions d-flex align-items-center ml-2">
        <b-button
          size="sm"
          variant="primary"
          :disabled="loading === docIndex"
          class="p-1"
        >
          <b-icon
            title="Скачать"
            icon="cloud-download"
            @click="downloadDocument(document, docIndex)"
          />
        </b-button>
        <b-button
          v-if="edit"
          size="sm"
          variant="danger"
          :disabled="loading === docIndex"
          class="p-1 ml-2"
        >
          <b-icon
            title="Удалить"
            icon="x-circle"
            @click="$emit('removeDocument', docIndex)"
          />
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import LegalEntityDocumentItem from '@/components/LegalEntities/LegalEntityDocumentItem';
import { saveFile } from '@/helpers/utils';
import { api } from '@/helpers/api';

export default {
  name: 'LegalEntityDocuments',
  components: {
    LegalEntityDocumentItem,
  },
  props: {
    documents: {
      type: Array,
      default: () => null,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: null,
  }),
  methods: {
    async downloadDocument(fileToDownload, index) {
      this.loading = index;
      try {
        const { data: file } = await api.get(`File/${fileToDownload.fileId}`, { responseType: 'blob' });
        saveFile(file, { name: fileToDownload.name });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;

  .document-item {
    width: 25%;
    color: $black-light;
    font-size: 14px;
    line-height: 18px;
  }
}
</style>
