<template>
  <div class="crm-file-modal">
    <div v-if="filesPending.length">
      <div class="crm-info-text">
        Идет импорт файла. Загрузка может занять до 15 мин. Не прерывайте загрузку
      </div>
      <preloader style="margin-top: 20px" />
    </div>

    <div v-if="filesSuccess.length">
      <div class="crm-info-text">
        Копирование файла успешно завершено.
        Все данные будут отражены на основной странице.
        Названия колонок можно изменять.
      </div>
      <div class="uploaded-document-name">
        <document-icon />
        <span>{{ files[0].name }}</span>
      </div>
    </div>

    <div
      v-if="filesError.length"
      class="files"
    >
      <div
        class="crm-info-text"
        style="color: #EB5757"
      >
        Что-то пошло не так.<br>
        Копирование файла не выполнено.
      </div>

      <base-file-input
        :value="computedFilesForInput"
        :multiple="multiple"
        :type="type"
        @change="onAddFiles"
      >
        Выбрать файл
      </base-file-input>
    </div>

    <base-file-input
      v-if="multiple || !files.length"
      :value="computedFilesForInput"
      :multiple="multiple"
      :type="type"
      @change="onAddFiles"
    >
      Выбрать файл
    </base-file-input>

    <div class="crm-wrapper-buttons">
      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="crm-button"
        @click="onClickClose"
      >
        Отменить
      </base-button>

      <base-button
        v-if="!isDisabledContinueButton"
        :type="$const.PRIMARY_BUTTON"
        class="crm-button"
        @click="onClickContinue"
      >
        ОК
      </base-button>
    </div>
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { getFileExtension } from '@/helpers/utils';
import Preloader from '@/components/Preloader';
import DocumentIcon from 'assets/images/document_icon.svg';

import {
  BaseButton,
  BaseFileInput,
} from '@/components/base';

export default {
  name: 'FileModalModern',
  components: {
    BaseButton,
    BaseFileInput,
    Preloader,
    DocumentIcon,
  },
  props: {
    saveFileCallback: {
      type: Function,
      default: () => {},
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    fileApi: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [],

      isSaving: false,
    };
  },
  computed: {
    filesPending() {
      return this.files.filter((file) => file.status === 'pending');
    },
    filesSuccess() {
      return this.files.filter((file) => file.status === 'success');
    },
    filesError() {
      return this.files.filter((file) => file.status === 'error');
    },
    computedFilesForInput() {
      return this.files.map((file) => file.file);
    },
    isDisabledContinueButton() {
      return !!this.filesPending.length || !this.files.length;
    },
  },
  methods: {
    onAddFiles(event) {
      this.files = [...this.files, ...event.map((file) => ({ name: file.name, status: 'pending', file }))];

      this.filesPending.forEach((fileObject) => this.sendFile(fileObject));
    },
    async sendFile(fileObject) {
      const extension = getFileExtension(fileObject.name);

      try {
        const fd = new FormData();

        if (this.fileApi) {
          fd.append('file', fileObject.file);
        }

        const { data } = await api.post(this.fileApi || `File/${extension}`, this.fileApi ? fd : fileObject.file, {
          headers: {
            'Content-Type': fileObject.file.type,
          },
        });

        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'success', guid: data.guid || data });
      } catch (e) {
        const existingFileIndex = this.files.indexOf(fileObject);

        this.files.splice(existingFileIndex, 1, { ...fileObject, status: 'error' });
      }
    },
    onClickClose() {
      this.$emit('vuedals:close');
    },
    onClickContinue() {
      this.saveFileCallback(this.filesSuccess);
      this.$emit('vuedals:close');
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-file-wrapper {
  margin-bottom: 20px;
}
.crm-file-name {
  font-weight: 500;
}
.crm-wrapper-buttons {
  margin-top: 30px;
}
.files-title {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 20px 0;

  &_pending {
    color: $orange;
  }

  &_success {
    color: $green;
  }

  &_error {
    color: $red;
  }
}
.crm-info-text {
  color: #908F8D;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  margin: 10px 0px;
}
.uploaded-document-name {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
   span {
     margin-left: 20px;
   }
}
label{
  display: block;
}

.crm-button {
  width: 170px !important;
}
</style>
