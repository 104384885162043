import { removeNamespaces } from '@/helpers/utils';
import * as types from '@/store/types';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.ANALYSIS_PATIENT_SET](state, payload) {
    state.patient = payload;
  },
  [localTypes.ANALYSIS_PATIENT_FULL_DATA_SET](state, payload) {
    state.patientFullData = payload;
  },
  [localTypes.ANALYSIS_MEDTESTS_SET](state, payload) {
    state.medtests = payload;
  },
  [localTypes.ANALYSIS_CLINICS_SET](state, payload) {
    state.clinics = payload;
  },
  [localTypes.ANALYSIS_SELECTED_CLINIC_SET](state, payload) {
    state.selectedClinic = payload;
  },
  [localTypes.ANALYSIS_CLINIC_QUERY_SET](state, payload) {
    state.clinicQuery = payload;
  },
  [localTypes.ANALYSIS_STATE_DEFAULT_SET](state) {
    state.patient = null;
    state.patientFullData = null;
    state.medtests = [];
    state.clinicQuery = '';
    state.selectedClinic = null;
  },
};
