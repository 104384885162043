import NProgress from 'nprogress/nprogress';
// import axios from 'axios';

import * as types from '@/store/types';
import {
  removeNamespaces, showMessage,
} from '@/helpers/utils';
import { api } from '@/helpers/api';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.INFO_MESSAGES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data: infoMessages } = await api.get('informational-message');

      if (infoMessages) {
        commit(localTypes.INFO_MESSAGES_SET, infoMessages);
      }
    } catch {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось получить информационные сообщения',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.INFO_MESSAGES_CREATE](_, payload) {
    NProgress.start();

    try {
      const { data } = await api.post('informational-message', payload);

      if (data.id) {
        return data;
      }
      return { id: null };
    } catch {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать информационное сообщение',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.INFO_MESSAGES_EDIT](_, payload) {
    NProgress.start();

    try {
      const { data } = await api.put(`informational-message/${payload.id}`, payload.data);

      if (data.id) {
        return data;
      }
      return { id: null };
    } catch {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать информационное сообщение',
      });
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.INFO_MESSAGES_DELETE](_, payload) {
    NProgress.start();

    try {
      const { status } = await api.delete(`informational-message/${payload.id}`);

      return status === 200;
    } catch {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: 'Не удалось создать информационное сообщение',
      });
    } finally {
      NProgress.done();
    }
  },
};
