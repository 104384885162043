<template>
  <div class="policy-delete">
    <custom-scrollbar>
      <div
        class="crm-container policy-container"
      >
        <div class="description">
          Вы уверены, что хотите удалить полис?
        </div>
        <div class="crm-wrapper-buttons">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button>

          <base-button
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            class="crm-button"
            @click="onClickDelete"
          >
            Удалить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { mixinRoles } from '@/mixins';
import Bus from '@/eventBus';

import {
  BaseButton,
} from '@/components/base';

export default {
  name: 'PolicyDeleteModal',
  components: {
    BaseButton,
  },
  mixins: [mixinRoles],
  props: {
    policyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isDeleting: false,
    };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    async onClickDelete() {
      this.isDeleting = true;

      if (!this.policyId) {
        Bus.$emit('patient:update');
        this.$emit('vuedals:close');
      } else {
        try {
          await this.$store.dispatch(this.$types.PATIENT_POLICY_DELETE, this.policyId);
        } catch (e) {
          console.log(e);
        } finally {
          this.isDeleting = false;
          Bus.$emit('patient:update');
          Bus.$emit('patients:update');
          this.$emit('vuedals:close');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
