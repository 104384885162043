<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Удаление устройств врача"
    :hide-footer="isLoading"
    @hidden="onCancel"
    @ok="onCancel"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      Вы уверены?
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        class="float-right"
        @click="onDelete"
      >
        Удалить
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        @click="onCancel"
      >
        Отменить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'DoctorDeviceDeleteModal',
  components: {
    Preloader,
  },
  props: {
    personId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    afterDelete: {
      type: [Function],
      default: () => {},
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
    };
  },
  methods: {
    onCancel() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
      this.afterDelete();
    },
    async onDelete() {
      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.DELETE_USER_DEVICE, this.personId);
        this.onClose();
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
