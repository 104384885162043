<template>
  <div class="containerLabsPage pt-4 w-100">
    <custom-scrollbar>
      <b-container fluid>
        <div class="h3 mb-4">
          Города
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <b-button
            variant="primary"
            @click="openAddCityModal"
          >
            Добавить город
          </b-button>
        </div>
        <b-overlay
          v-if="!cities || Object.keys(cities).length === 0"
          :show="isLoading"
          no-wrap
        />
        <div v-if="cities">
          <div v-if="Object.keys(cities).length !== 0">
            <b-table
              v-if="cities"
              :fields="fields"
              :items="cities"
              :busy="isLoading"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              class="bg-white crm-table mt-4"
              bordered
              hover
              :small="true"
            >
              <template #cell(actions)="row">
                <b-button
                  v-b-tooltip.hover
                  class="mr-2"
                  variant="primary"
                  size="sm"
                  title="Редактировать"
                  @click="openEditCityModal(row)"
                >
                  <b-icon
                    icon="pencil-fill"
                  />
                </b-button>
              </template>
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-2" />
                  <strong>Загрузка...</strong>
                </div>
              </template>
            </b-table>
            <div
              class="mt-3"
            >
              <div
                v-show="cities"
                class="crm-pagination"
              >
                <b-dropdown
                  id="dropdown-1"
                  :text="`${perPage}`"
                  split
                  class="m-md-2"
                  size="sm"
                  split-variant="outline-primary"
                  variant="primary"
                >
                  <b-dropdown-item @click="tableSizeHandler(10)">
                    10
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(25)">
                    25
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="tableSizeHandler(50)">
                    50
                  </b-dropdown-item>
                </b-dropdown>
                <b-pagination
                  v-model="currentPage"
                  pills
                  :total-rows="rows"
                  :per-page="perPage"
                  style="padding-top: 16px"
                />
              </div>
            </div>
          </div>
          <span v-else>Ничего не найдено</span>
        </div>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
// import { mapGetters } from 'vuex';
// import * as types from '@/store/types';
// eslint-disable-next-line no-unused-vars
import { format, parseISO } from 'date-fns';

export default {
  name: 'LaboratoryPartners',
  page: {
    title: 'CRM Doctis - Города',
  },
  components: {},
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Город',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: '',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      rows: 1,
      cities: [],
      isLoading: false,
    };
  },
  async created() {
    await this.fetchCities();
    this.rows = this.cities.length;
  },
  methods: {
    async tableSizeHandler(mount) {
      this.perPage = mount;
    },
    async fetchCities() {
      this.isLoading = true;
      this.cities = (await this.$store.dispatch(this.$types.CITIES_FETCH, { query: '' })).sort((a, b) => a.name.localeCompare(b.name));
      this.isLoading = false;
    },
    openAddCityModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'CityCreateModal',
        props: {
        },
      });
    },
    openEditCityModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'CityEditModal',
        props: {
          cityId: row.item.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

.policy-list-content {
  height: calc(100vh - #{$header-height});
  transition: height 0.3s linear;
}

.crm-table {
  overflow: hidden;
}

.crm-input {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.crm-pagination {
  display: flex;
  align-items: center;
}

.crm-icon-delete {
  cursor: pointer;
  transition: .3s all;

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}

.crm-icon-edit {
  cursor: pointer;
  transition: .3s all;

  path {
    fill: $blue;
  }

  &:hover {
    rect {
      stroke: $blue;
      opacity: 0.5;
    }
  }
}
</style>
