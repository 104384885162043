import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.CLINICS_SET](state, payload) {
    state.clinics = payload;
  },
  [localTypes.CLINIC_LIST_NAMES_SET](state, payload) {
    state.clinicListNames = payload;
  },
  [localTypes.CLINIC_REMOVE_BY_ID](state, payload) {
    state.clinics = state.clinics.filter((clinic) => clinic.id !== payload);
  },
  [localTypes.CLINICS_ADD](state, payload) {
    state.clinics = [...state.clinics, ...payload];
  },
  [localTypes.CLINICS_PINNED_DOCTORS_SET](state, payload) {
    state.pinnedDoctors = payload;
  },
  [localTypes.CLINICS_SET_IS_CHANGED_CLINIC_INFO](state, payload) {
    state.isChangedClinicInfo = payload;
  },
  [localTypes.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO](state, payload) {
    state.isChangedClinicDoctorsInfo = payload;
  },
  [localTypes.CLINIC_SET](state, payload) {
    state.clinic = payload;
  },
  [localTypes.CLINIC_IS_LOADING_SET](state, payload) {
    state.isClinicLoading = payload;
  },
  [localTypes.CLINIC_INFO_IS_EDIT_SET](state, payload) {
    state.isEditClinicInfo = payload;
  },
  [localTypes.CLINIC_DOCTORS_IS_EDIT_SET](state, payload) {
    state.isEditClinicDoctors = payload;
  },
  [localTypes.SET_LIST_PRICES](state, payload) {
    state.pricesList = payload;
  },
  [localTypes.SET_LIST_PRICES_PAGINATION_COUNT](state, payload) {
    state.pricesListPaginationCount = payload;
  },
  [localTypes.SET_LIST_PRICES_LOADING](state, payload) {
    state.pricesListLoading = payload;
  },
};
