v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Загрузить прайсы в Клинику?'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div v-else>
      <b-button
        variant="primary"
        class="w-25"
        @click="onClinicReestrImport"
      >
        Да
      </b-button>
      <b-button
        variant="danger"
        class="w-25 ml-2"
        @click="onClose"
      >
        Нет
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  format,
  parseISO,
  addHours,
} from 'date-fns';
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';

export default {
  name: 'PricesImportInClinic',
  components: {
    Loading,
  },
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      userData: {},
      rows: [],
      // modalName: 'PricesImportInClinic',
    };
  },
  async created() {
    // this.isLoading = true;
    // this.rows = await this.$store.dispatch(this.$types.CLINIC_HISTORY_FETCH, this.clinicId);
    // this.isLoading = false;
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClinicReestrImport() {
      try {
        const params = {
          ...this.params,
        };
        await api.post('v2/clinic/services/import', params);

        showMessage({
          type: 'success',
          title: 'Успешно',
          message: 'Прайсы успешно обновлены',
        });

        this.onClose();
      } catch (error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: error,
        });
      }
    },
    formatDate(date) {
      const dateAdded = addHours(parseISO(date), 3);
      return format(dateAdded, 'dd.MM.yyyy HH:mm');
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}

</style>
