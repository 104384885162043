v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="s"
    :title="'Изменение категории'"
    @hidden="onClose"
    @ok="onClose"
  >
    <b-overlay
      v-if="isLoading"
      :show="isLoading"
      variant="transparent"
      rounded="lg"
      style="height: 10rem"
      opacity="0.6"
    />
    <div v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Название категории"
          >
            <b-form-input
              v-model="tagData.name"
              type="text"
              :placeholder="'Введите название'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Тип категории">
            <b-form-select
              v-model="tagData.tagType"
              :options="optionsTag"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          @click="onClickUpdatePartner"
        >
          Изменить
        </b-button>
        <b-button
          variant="danger"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'EditTagModal',
  components: {},
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    tagId: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      tagData: { name: null, tagType: null },
      optionsTag: [
        { value: null, text: 'Выберите состояние' },
        { value: 1, text: 'Категория' },
        { value: 2, text: 'Популярное' },
      ],
    };
  },
  async created() {
    await this.fetchTagInfo();
  },
  methods: {
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async fetchTagInfo() {
      this.isLoading = true;
      const { data } = await this.$store.dispatch(this.$types.GET_LABORATORY_TAG_FOR_ID, this.tagId);
      this.tagData = data;
      this.isLoading = false;
    },
    async onClickUpdatePartner() {
      const params = {
        id: this.tagData.id,
        name: this.tagData.name,
        tagType: this.tagData.tagType,
      };
      await this.$store.dispatch(this.$types.LABORATORY_TAG_UPDATE, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, this.params);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped></style>
