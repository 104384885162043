<template>
  <div class="nps-options w-100 pt-4 pb-4 overflow-auto">
    <b-container fluid="xl">
      <NpsTitle />
      <TabsNavigation
        :selected-tab="selectedTab"
        :badge-number="optionsCount"
      />
      <div class="d-flex mb-4">
        <VSearch
          v-model="searchQuery"
          class="mr-4 flex-grow-1 nps-options_search"
          placeholder="Поиск ответа"
        />
        <VButton
          label="Добавить ответ"
          size="m"
          :left-icon="{name: 'plus', size: 'm'}"
          type="light"
          @click="openAddModal"
        />
      </div>
      <Preloader v-if="isLoading" />
      <template v-else >
        <OptionsList
          v-if="hasOptions"
          :options-list="filteredOptionsList"
        />
        <div class="nps-options_no-answer" v-else>
          <IconBaloon />
          <p>Пока нет ни одного ответа</p>
        </div>
      </template>
    </b-container>
    <NpsOptionsModal
      v-if="isModalOpen"
      v-model="isModalOpen"
      @addAnswer="addAnswer"
    />
  </div>
</template>

<script>
import { api } from '@/helpers/api';
import { VButton, VSearch } from '@doctis.front/doctis.designsystem';
import { mapMutations, mapGetters } from 'vuex';
import { GET_NPS_OPTIONS_COUNT, SET_NPS_OPTIONS_COUNT } from '@/store/types';
import { showMessage } from '@/helpers/utils';
import NpsTitle from '@/components/Statistics/Nps/NpsTitle.vue';
import TabsNavigation from '@/components/Statistics/Nps/TabsNavigation.vue';
import OptionsList from '@/components/Statistics/Nps/OptionsList.vue';
import NpsOptionsModal from '@/components/Statistics/Nps/Options/OptionsModal.vue';
import IconBaloon from '@/assets/images/baloon-icon.svg';
import Preloader from '@/components/Preloader';

export default {
  name: 'NpsOptionsPage',
  page: {
    title: 'NPS МП Пациента - Опросы',
  },
  components: {
    NpsTitle,
    TabsNavigation,
    OptionsList,
    VButton,
    VSearch,
    IconBaloon,
    NpsOptionsModal,
    Preloader,
  },
  data() {
    return {
      isLoading: false,
      searchQuery: '',
      isModalOpen: false,
      optionsList: [],
    };
  },
  computed: {
    filteredOptionsList() {
      const query = this.searchQuery.toLowerCase();
      return this.optionsList.filter((option) => (
        option.title.toLowerCase().includes(query)
        || option.description.toLowerCase().includes(query)
      ));
    },
    ...mapGetters({
      optionsCount: GET_NPS_OPTIONS_COUNT,
    }),
    selectedTab() {
      return {
        label: 'Варианты ответов',
        value: '/statistics/nps/options',
        badge: this.optionsCount,
      };
    },
    hasOptions() {
      return this.filteredOptionsList.length > 0;
    },
  },
  async mounted() {
    await this.getSurveysOptions();
  },
  methods: {
    ...mapMutations({
      setOptionsCount: SET_NPS_OPTIONS_COUNT,
    }),
    async getSurveysOptions({ query = '', take = 100, skip = 0 } = {}) {
      this.isLoading = true;
      try {
        const { data } = await api.get('v3/survey/option', {
          params: {
            query,
            take,
            skip,
          },
        });
        const response = data?.data;
        if (response && response.options) {
          this.optionsList = response.options.map((option) => ({
            id: option.id,
            title: option.title,
            description: option.description,
          }));
          this.setOptionsCount(response.total);
        }
      } catch (e) {
        console.error('Error fetching options:', e);
      } finally {
        this.isLoading = false;
      }
    },
    async addAnswer(answer) {
      if (!answer) {
        return;
      }
      try {
        const response = await this.createOption(answer);
        if (response.status === 200) {
          await this.getSurveysOptions();
          this.setOptionsCount(this.optionsList.length);
          this.showSuccessMessage();
        }
      } catch (e) {
        console.error('Ошибка добавления ответа', e);
      }
    },
    async createOption(answer) {
      return api.post('v3/survey/option', answer);
    },
    showSuccessMessage() {
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Ответ добавлен',
      });
    },
    openAddModal() {
      this.isModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
  .nps-options {
    &_no-answer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-top: 170px;
      font-weight: 500;
      color: #161E2F;
      p {
        margin-bottom: 0;
      }
    }
    &_search {
      height: 40px;
      margin: 0;
    }
  }
</style>
