<template>
  <div class="insurance-program-patients">
    <search-panel
      v-model="filter.query"
      placeholder="Введите имя пациента или номер его полиса"
      @search="patientsFetch(true)"
    >
      <template #filter>
        <icon-filter
          id="popover-filter"
          class="disable-outline"
        />

        <b-popover
          custom-class="mt-4 insurance-subprogram-filter-popover"
          :show.sync="filter.isPopoverOpen"
          target="popover-filter"
          triggers="click focus blur"
          placement="bottom"
        >
          <div class="font-weight-bold">
            Период
          </div>

          <div class="crm-filter-popover d-flex flex-column">
            <div class="crm-datepickers-container">
              <div>
                <div class="crm-datepicker-caption mb-2">
                  Дата начала
                </div>
                <base-date-picker
                  v-model="filter.policyStartDate"
                  class="crm-datepicker"
                />
              </div>

              <div class="divider">
                <div class="divider-row" />
              </div>

              <div>
                <div class="crm-datepicker-caption mb-2">
                  Дата окончания
                </div>
                <base-date-picker
                  v-model="filter.policyEndDate"
                  class="crm-datepicker"
                />
              </div>
            </div>

            <base-checkbox
              v-model="filter.hasMobileAccount"
              class="crm-checkbox mt-3"
            >
              Наличие приложения
            </base-checkbox>
            <base-checkbox
              v-model="filter.hasConsultations"
              class="crm-checkbox mt-1"
            >
              Наличие консультаций
            </base-checkbox>
            <base-checkbox
              v-model="filter.hasActivePolicy"
              class="crm-checkbox mt-1"
            >
              Активация полиса
            </base-checkbox>
          </div>
        </b-popover>
      </template>

      <template #button>
        <base-button
          v-if="false"
          :type="$const.SECONDARY_BUTTON"
          class="load-excel-button"
        >
          <span>
            Скачать Excel
          </span>
          <icon-excel
            class="crm-icon-excel"
          />
        </base-button>
      </template>
    </search-panel>
    <async-list
      class="patient-list"
      :is-all-items-loaded="isAllItemsLoaded "
      @load-more-items="loadMoreItems"
    >
      <div
        v-if="!totalCount && isAllItemsLoaded"
        class="crm-container"
      >
        <span
          class="page-subtitle"
        >
          Ничего не найдено
        </span>
      </div>

      <div
        v-if="totalCount"
        class="crm-container"
      >
        <div class="result-row">
          <span
            class="result-title"
          >
            Результаты поиска
          </span>

          <!-- <span class="result-count">
            Найдено: {{ totalCount }}
          </span> -->

          <div
            v-if="subProgramInfo"
            class="result-count-wrapper"
          >
            <span class="result-count">
              Найдено полисов со скаченным приложением: {{ subProgramInfo.downloadedAppCount }}
            </span>
            <span class="result-count">
              Найдено полисов с полученными консультациями: {{ subProgramInfo.hadConsultationCount }}
            </span>
            <span class="result-count">
              Найдено активированных полисов: {{ subProgramInfo.activatedPolicyCount }}
            </span>
          </div>
        </div>
      </div>

      <div
        v-for="(patient, index) in patients"
        :key="index"
      >
        <patient-info

          :patient="patient"
        />
      </div>
    </async-list>
  </div>
</template>

<script>
import {
  // BaseAsyncSelect,
  BaseButton,
  BaseCheckbox,
  BaseDatePicker,
} from '@/components/base';

import SearchPanel from '@/components/SearchPanel';
import AsyncList from '@/components/AsyncList';
import PatientInfo from '@/components/Patients/PatientInfo';

import IconExcel from 'assets/images/exel_icon.svg';
import IconFilter from 'assets/images/filter.svg';
import { formatISO } from 'date-fns';

export default {
  name: 'InsuranceSubprogramPatients',
  components: {
    BaseButton,
    // BaseAsyncSelect,
    BaseDatePicker,
    BaseCheckbox,
    AsyncList,
    SearchPanel,
    PatientInfo,
    IconExcel,
    IconFilter,
  },
  props: {
    subprogramId: {
      type: null,
      default: null,
    },
  },
  data() {
    return {
      isAllItemsLoaded: false,
      isLoading: false,
      limit: 15,
      filter: {
        query: '',
        hasMobileAccount: false,
        hasConsultations: false,
        hasActivePolicy: false,
        policyStartDate: null,
        policyEndDate: null,
        isPopoverOpen: false,
      },
      patients: [],
    };
  },
  computed: {
    totalCount() {
      return this.patients.length;
    },
    subProgramInfo() {
      return this.$store.state.Insurance.insuranceSubprogramsInfo;
    },
  },
  methods: {
    async patientsFetch(clear) {
      if (this.isLoading) return;
      if (!clear && this.isAllItemsLoaded) return; // костыль на остановку запроса, так как функция вызывается вне компонента и дублирует пациентов
      if (clear) this.patients = [];

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = this.totalCount;
      const prevQuery = this.filter.query;
      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        query: prevQuery,
        clear: !!clear,
        save: true,
        filter: {
          ...this.filter,
          subProgramId: this.subprogramId,
          policyStartDate: this.filter.policyStartDate && formatISO(this.filter.policyStartDate),
          policyEndDate: this.filter.policyEndDate && formatISO(this.filter.policyEndDate),
        },
      };

      try {
        const actionType = requestObject.filter.isDeleted ? 'PATIENTS_DELETED_FETCH' : 'PATIENTS_FETCH';
        const patients = await this.$store.dispatch(this.$types[actionType], requestObject);
        this.patients = [...this.patients, ...patients];

        this.$store.dispatch(this.$types.SUBPROGRAM_INFO_FETCH, {
          subProgramId: this.subprogramId,
        });

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.filter.query !== prevQuery) this.patientsFetch(true);
      }
    },
    loadMoreItems() {
      this.patientsFetch(false);
    },
    togglePopover(val) {
      this.filter.isPopoverOpen = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-program-patients {
  width: 100%;
}

.result-title {
  @extend .page-title;
  margin-right: auto;
  margin-bottom: 10px;
}

::v-deep.crm-icon-search:hover {
  cursor: pointer;
  path {
    fill: $blue;
  }
}

::v-deep.crm-info-wrapper {
  padding-top: 10px;
  padding-bottom: 30px;
}

.patient-list {
  min-height: 50px;
  box-sizing: border-box;
}

.result-row {
  flex: 1;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  flex-flow: wrap;
  width: 100%;
}

.result-count-wrapper{
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  color: #707070;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}

.crm-datepickers-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.load-list-button {
  margin-left: 20px;
  width: unset;
  padding: 0 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  align-self: stretch;

  .crm-icon-reload {
    margin-right: 15px;
  }
}

.load-excel-button {
  margin-left: 20px;
  width: unset;
  padding: 0 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  align-self: stretch;
  background: transparent;

  .crm-icon-excel {
    margin-left: 15px;
  }
}
</style>

<style>
.insurance-subprogram-filter-popover {
  max-width: 400px;
}
</style>
