<template>
  <div
    class="person-policy"
    :class="{
      active: isActive,
      pending: isPending,
      inactive: isInactive,
    }"
  >
    <div class="policy-wrapper">
      <div class="policy-info policy-info-main">
        <div class="policy-name">
          <template v-if="!policyEditData.isOMS">
            <template v-if="policyEditData.companyName">
              {{ policy.companyName }}. <br>
            </template>
            <template v-if="policyEditData.programName">
              {{ policyEditData.programName }}. <br>
            </template>
            {{ policyEditData.insuranceName }}
          </template>
          <template v-else>
            {{ policyEditData.insuranceName }}
          </template>
        </div>
        <div class="policy-fullname">
          {{ policy.lastName }} {{ policy.firstName }} {{ policy.middleName }}
        </div>
        <div class="policy-ensurerName">
          {{ policyEditData.ensurerName }}
        </div>
        <div class="policy-id">
          № {{ policyEditData.policyId }}
        </div>
      </div>
      <div class="policy-info">
        <div
          v-if="policyStartDate"
          class="policy-info-item"
        >
          <div class="policy-info-title">
            Дата начала
          </div>
          <div class="policy-info-value">
            {{ policyStartDate }}
          </div>
        </div>
        <div
          v-if="policyEndDate"
          class="policy-info-item"
        >
          <div class="policy-info-title">
            Дата окончания
          </div>
          <div class="policy-info-value">
            {{ policyEndDate }}
          </div>
        </div>
      </div>
      <div class="policy-letter-history">
        <!-- v-if="!checkRoleAccess_(['polis.operator.dop', 'Dis.monitoring.admin'])" -->
        <base-button
          v-if="checkFeatureAccess({ name: 'Пациент детальная информация. История ГП', url: '/patients' })"
          :type="$const.SECONDARY_BUTTON"
          class="crm-button button_transparent letter-history-btn width-unset"
          @click="() => openIndemnityHistoryModal()"
        >
          История ГП
        </base-button>
        <!-- v-if="!checkRoleAccess_(['polis.operator.dop', 'Dis.monitoring.admin'])" -->
        <base-button
          v-if="checkFeatureAccess({ name: 'Пациент детальная информация. История направлений', url: '/patients' })"
          :type="$const.SECONDARY_BUTTON"
          class="crm-button button_transparent letter-history-btn width-unset з"
          @click="() => openIndemnityHistoryModal(true)"
        >
          История направлений
        </base-button>
      </div>
      <div class="policy-controls">
        <!-- v-if="isPending && !checkRoleAccess_(['polis.operator', 'polis.operator.dop'])" -->
        <b-button
          v-if="isPending && checkFeatureAccess({ name: 'Возможность активировать полис пациента', url: '/patients' })"
          variant="light"
          size="sm"
          class="p-0 border"
          title="Активировать полис"
          @click="openPolicyActivateModal(policy)"
        >
          <icon-check />
        </b-button>
        <!-- v-if="isActiveDate && showNewIndemnityButton && !checkRoleAccess_(['polis.operator', 'polis.operator.dop'])" -->
        <b-button
          v-if="isActiveDate && showNewIndemnityButton && checkFeatureAccess({
            name: 'Возможность создать ГП',
            url: '/patients',
          })"
          variant="light"
          size="sm"
          class="mt-2 p-0 border"
          title="Создать ГП"
          @click="openIndemnityModal(policy.id, policy.subProgramV2Id)"
        >
          <icon-worksheet />
        </b-button>
        <!-- v-if="isActiveDate && showNewIndemnityButton && !checkRoleAccess_(['polis.operator', 'polis.operator.dop'])" -->
        <b-button
          v-if="isActiveDate && showNewIndemnityButton && checkFeatureAccess({
            name: 'Возможность создать направление',
            url: '/patients',
          })"
          variant="light"
          size="sm"
          class="mt-2 p-1 border"
          title="Создать направление"
          @click="openIndemnityModal(policy.id, policy.subProgramV2Id, true)"
        >
          <b-icon
            icon="file-earmark-medical"
            class="text-info"
          />
        </b-button>
        <b-button
          variant="light"
          size="sm"
          class="mt-2 p-1 border"
          title="Открыть лимиты полиса"
          @click="openPolicyLimitsModal"
        >
          <b-icon
            icon="list-ul"
            class="text-info"
          />
        </b-button>
        <b-button
          class="mt-2 p-0 border"
          variant="light"
          size="sm"
          title="Детальная информация"
          @click="togglePolicyInfo"
        >
          <icon-info />
        </b-button>
        <b-button
          class="mt-2 p-0 border"
          variant="light"
          title="Очные оказанные услуги"
          style="height: 30px; width: 30px"
          @click="openPersonServices"
        >
          <IconServices />
        </b-button>
      </div>
    </div>
    <template v-if="isOpen">
      <div
        v-if="isEdit"
        class="policy-detail"
      >
        <div class="policy-form-fields">
          <base-input
            :value="policy.lastName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Фамилия"
          />
          <base-input
            :value="policy.firstName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Имя"
          />
        </div>
        <div class="policy-form-fields">
          <base-input
            :value="policy.middleName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Отчество"
          />
          <div class="crm-form-field" />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая компания
              </p>
              <v-select
                v-model="policyEditData.companyName"
                :reduce="company => company.title"
                :options="insuranceCompanies"
                class="select-specialization"
                placeholder="Выберите страховую компанию"
                label="title"
                @input="changeCompany"
              />
            </div>
          </div>
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая программа
              </p>
              <v-select
                v-model="policyEditData.programName"
                :reduce="program => program.name"
                :options="insurancePrograms"
                class="select-specialization"
                placeholder="Выберите страховую программу"
                label="name"
                @input="changeProgram"
              />
            </div>
          </div>
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая подпрограмма
              </p>
              <v-select
                v-model="policyEditData.subProgramV2Id"
                :reduce="subprogram => subprogram.id"
                :options="insuranceSubprograms"
                class="select-specialization"
                placeholder="Выберите страховую подпрограмму"
                label="name"
                @input="onSubprogramChange($event)"
              />
            </div>
          </div>
          <base-input
            v-model="policyEditData.policyId"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field w-50"
            label="Серийный номер полиса"
          />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата начала
              </p>
              <base-date-picker
                v-model="policyEditData.startDate"
                :max-date="maxPolicyEditData"
              />
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата окончания
              </p>
              <base-date-picker
                v-model="policyEditData.endDate"
                :min-date="minPolicyEditDateTo"
              />
            </div>
          </div>
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата покупки полиса
              </p>
              <base-date-picker
                v-model="policyEditData.buyDate"
              />
            </div>
          </div>

          <base-input
            v-model="policyEditData.ensurerName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
              backgroundColor: '#ffffff',
            }"
            class="crm-form-field"
            label="Страхователь"
          />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Статус полиса
              </p>
              <div
                class="policy-info-value"
                :class="{ red: !policyEditData.isActivated, green: policyEditData.isActivated }"
              >
                {{ policyEditData.isActivated ? 'Активирован' : 'Не доступен' }}
              </div>
            </div>
          </div>
        </div>

        <base-dropdown
          v-if="policy.clinics && policy.clinics.length"
          header="Прикрепленные клиники"
          class="crm-form-step"
          :open="false"
          :show-line="false"
        >
          <div class="clinic-list">
            <a
              v-for="clinic in policyEditData.clinics"
              :key="clinic.id"
              class="clinic-item"
              :href="`/clinics/${clinic.id}/`"
              target="_blank"
            >
              {{ clinic.name }}
              <template v-if="clinic.cityName">
                , {{ clinic.cityName }}
              </template>
            </a>
          </div>
        </base-dropdown>

        <div class="d-flex justify-content-end mt-2">
          <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button button_transparent"
            @click="cancelPolicyEdit"
          >
            Отмена
          </base-button>

          <base-button
            :type="$const.PRIMARY_BUTTON"
            :loading="isSaving"
            class="crm-button width-unset"
            @click="savePolicy"
          >
            Сохранить изменения
          </base-button>
        </div>
      </div>
      <div
        v-else
        class="policy-detail"
      >
        <div class="policy-form-fields">
          <base-input
            :value="policy.lastName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Фамилия"
          />
          <base-input
            :value="policy.firstName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Имя"
          />
        </div>
        <div class="policy-form-fields">
          <base-input
            :value="policy.middleName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Отчество"
          />
          <div class="crm-form-field" />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая компания
              </p>
              <v-select
                v-model="policyEditData.companyName"
                :reduce="company => company.title"
                :options="insuranceCompanies"
                class="select-specialization"
                placeholder="Выберите страховую компанию"
                label="title"
                disabled
                :clearable="false"
                @input="changeCompany"
              />
            </div>
          </div>
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая программа
              </p>
              <v-select
                v-model="policyEditData.programName"
                :reduce="program => program.name"
                :options="insurancePrograms"
                class="select-specialization"
                placeholder="Выберите страховую программу"
                label="name"
                disabled
                :clearable="false"
                @input="changeProgram"
              />
            </div>
          </div>
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field w-50">
            <div class="crm-form-block">
              <p class="crm-label">
                Страховая подпрограмма
              </p>
              <v-select
                v-model="policyEditData.subProgramV2Id"
                :reduce="subprogram => subprogram.id"
                :options="insuranceSubprograms"
                class="select-specialization"
                placeholder="Выберите страховую подпрограмму"
                label="name"
                :clearable="false"
                disabled
                @input="onSubprogramChange($event)"
              />
            </div>
          </div>
          <base-input
            v-model="policyEditData.policyId"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            class="crm-form-field w-50"
            label="Серийный номер полиса"
            :clearable="false"
            disabled
          />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата начала
              </p>
              <base-date-picker
                v-model="policyEditData.startDate"
                :clearable="false"
                disabled
              />
            </div>
          </div>
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата окончания
              </p>
              <base-date-picker
                v-model="policyEditData.endDate"
                :clearable="false"
                disabled
              />
            </div>
          </div>
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Дата покупки полиса
              </p>
              <base-date-picker
                v-model="policyEditData.buyDate"
                :clearable="false"
                disabled
              />
            </div>
          </div>

          <base-input
            v-model="policyEditData.ensurerName"
            :input-style="{
              padding: '11px 10px 12px',
              resize: 'none',
            }"
            disabled
            class="crm-form-field"
            label="Страхователь"
          />
        </div>
        <div class="policy-form-fields">
          <div class="crm-form-field">
            <div class="crm-form-block">
              <p class="crm-label">
                Статус полиса
              </p>
              <div
                class="policy-info-value"
                :class="{ red: !policyEditData.isActivated, green: policyEditData.isActivated }"
              >
                {{ policyEditData.isActivated ? 'Активирован' : 'Не доступен' }}
              </div>
            </div>
          </div>
        </div>

        <base-dropdown
          v-if="policyEditData.prolongationDates && policyEditData.prolongationDates.length"
          header="Старые полисы"
          class="crm-form-step"
          :open="false"
          :show-line="false"
        >
          <div class="dates-list mt-3">
            <div class="mb-5">
              <b-table
                :bordered="true"
                :hover="true"
                :fields="prolongationFields"
                :items="policyEditData.prolongationDates"
              >
                <template v-slot:cell(startDate)="row">
                  <span>{{ parseData(row.item.startDate) }}</span>
                </template>
                <template v-slot:cell(endDate)="row">
                  <span>{{ parseData(row.item.endDate) }}</span>
                </template>
              </b-table>
            </div>
          </div>
        </base-dropdown>

        <base-dropdown
          v-if="historyPolicyData && historyPolicyData.length"
          header="История полиса"
          class="crm-form-step"
          :open="false"
          :show-line="false"
        >
          <div class="dates-list mt-3">
            <div class="mb-5">
              <b-table
                :bordered="true"
                :hover="true"
                :fields="fields"
                :items="historyPolicyData"
              >
                <template v-slot:cell(date)="row">
                  <span v-if="row.item.date">
                    {{ historyTime(row.item.date) }}
                  </span>
                </template>
              </b-table>
            </div>
          </div>
        </base-dropdown>

        <base-dropdown
          v-if="policy.clinics && policy.clinics.length"
          header="Прикрепленные клиники"
          class="crm-form-step"
          :open="false"
          :show-line="false"
        >
          <div class="clinic-list">
            <a
              v-for="clinic in policy.clinics"
              :key="clinic.id"
              class="clinic-item"
              :href="`/clinics/${clinic.id}/`"
              target="_blank"
            >
              {{ clinic.name }}
              <template v-if="clinic.cityName">
                , {{ clinic.cityName }}
              </template>
            </a>
          </div>
        </base-dropdown>

        <div
          v-if="personId === policy.personId"
          class="crm-wrapper-buttons"
        >
          <!-- v-if="!checkRoleAccess_(['Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Пациент детальная информация. Открепить полис', url: '/patients' })"
            :type="$const.WARNING_BUTTON"
            :loading="isDeleting"
            class="crm-button width-unset mr-auto"
            @click="openPolicyDetachModal"
          >
            Открепить полис
          </base-button>
        </div>

        <div
          v-else
          class="crm-wrapper-buttons"
        >
          <!-- v-if="!checkRoleAccess_(['Dis.monitoring.admin'])" -->
          <base-button
            v-if="checkFeatureAccess({ name: 'Пациент детальная информация. Открепить полис', url: '/patients' })"
            :type="$const.WARNING_BUTTON"
            class="crm-button width-unset mr-auto"
            @click="openPolicyDetachDopModal"
          >
            Открепить полис
          </base-button>
        </div>
      </div>

      <template>
        <base-button
          :loading="isSubprogramDetailLoading"
          class="subprogram-toggle-btn"
          @click="toggleSubprogramDetail"
        >
          <icon-arrow-dropdown
            class="crm-icon-arrow-down"
            :class="{ reverse: isSubprogramDetailOpen }"
          />
        </base-button>
        <template v-if="policyEditData.subProgramV2Id">
          <div
            v-if="isSubprogramDetailOpen && subprogramDetailData"
            class="policy-subprogram-detail"
          >
            <div class="crm-title-row">
              <div class="program-title">
                Подпрограмма: {{ subprogramDetailData.subprogram.name }}
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Страховая компания
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.company.name }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Программа
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.program.name }}
                </div>
              </div>
              <!--              <div class="program-info-block">-->
              <!--                <div class="program-info-title">-->
              <!--                  Срок действия полиса-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  v-if="subprogramDetailData.subprogram.policyPeriod"-->
              <!--                  class="program-info-text"-->
              <!--                >-->
              <!--                  {{ periodText }}-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  v-else-->
              <!--                  class="program-info-text"-->
              <!--                >-->
              <!--                  —-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="program-info-block">
                <div class="program-info-title">
                  Возрастные ограничения
                </div>
                <div class="program-info-text">
                  {{
                    subprogramDetailData.subprogram.ageRestrictions !== null
                      ? subprogramDetailData.subprogram.ageRestrictions.lowLimit + ' - '
                        + subprogramDetailData.subprogram.ageRestrictions.highLimit + ' лет'
                      : '—'
                  }}
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Стоимость прикрепления
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.subprogram.attachmentCost !== null ? subprogramDetailData.subprogram.attachmentCost + ' ₽' : '—' }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Страховая выплата
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.subprogram.insurancePayment !== null ? subprogramDetailData.subprogram.insurancePayment + ' ₽' : '—' }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Сумма страхового покрытия
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.subprogram.insuranceCoverSum !== null ? subprogramDetailData.subprogram.insuranceCoverSum + ' ₽' : '—' }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Срок франшизы
                </div>
                <div class="program-info-text">
                  {{ subprogramDetailData.subprogram.franchiseTerm !== null ? subprogramDetailData.subprogram.franchiseTerm + ' день/дней' : '—' }}
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Назначение полиса
                </div>
                <div class="program-info-text">
                  <template v-if="subprogramDetailData.subprogram.policyPurposes && subprogramDetailData.subprogram.policyPurposes.length">
                    <div
                      v-for="purpose in subprogramDetailData.subprogram.policyPurposes"
                      :key="purpose"
                    >
                      {{ policyPurposes[purpose] }}
                    </div>
                  </template>
                  <template v-else>
                    —
                  </template>
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Особенности активации
                </div>
                <div class="program-info-text">
                  {{
                    subprogramDetailData.subprogram.activationType !== null
                      ? activationTypes[subprogramDetailData.subprogram.activationType]
                      : '—'
                  }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Вид программы
                </div>
                <div class="program-info-text">
                  {{
                    subprogramDetailData.subprogram.subProgramType !== null
                      ? subProgramTypes[subprogramDetailData.subprogram.subProgramType]
                      : '—'
                  }}
                </div>
              </div>
              <div class="program-info-block">
                <div class="program-info-title">
                  Кол-во членов семьи
                </div>
                <div
                  v-if="subprogramDetailData.subprogram.familyMembersCount !== null"
                  class="program-info-text"
                >
                  {{ subprogramDetailData.subprogram.familyMembersCount !== 999 ? subprogramDetailData.subprogram.familyMembersCount : '∞' }}
                </div>
                <div
                  v-else
                  class="program-info-text"
                >
                  —
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Специальности врачей по телемедицине
                </div>
                <template v-if="getAllSelectedDutySpecializations.length > 0 || getAllSelectedNarrowSpecializations.length > 0">
                  <div
                    v-if="subprogramDetailData.subprogram.isLimitation"
                    class="program-info-text"
                  >
                    Не ограничены любые дежурные врачи: {{ getAllSelectedDutySpecializationsSting }}<br>
                    Ограничено общее число консультаций к узкопрофильным специалистам.
                    <template v-if="!isAllSpecializationsSelected">
                      {{ subprogramDetailData.subprogram.limitationCount }} консультаций к узкопрофильным специалистам:
                      {{
                        `${joinDoctorsArrayToString(getAllNarrowLimited)}${getAllNarrowNotLimited.length
                          ? `, в рамках оставшегося лимита - ${ joinDoctorsArrayToString(getAllNarrowNotLimited) }` : ''}`
                      }}
                    </template>
                    <template v-else>
                      {{ subprogramDetailData.subprogram.limitationCount }} консультаций к любым узкопрофильным специалистам
                    </template>
                  </div>
                  <div
                    v-else
                    class="program-info-text"
                  >
                    Не ограничены любые дежурные врачи:
                    {{ getAllNarrowNotLimited.length > 0 ?
                      `${getAllSelectedDutySpecializationsSting}; Узкопрофильные специалисты: ${joinDoctorsArrayToString(getAllNarrowNotLimited)}`
                      : getAllSelectedDutySpecializationsSting
                    }}

                    <div v-if="getAllNarrowLimited.length > 0">
                      Ограничены: {{ joinDoctorsArrayToString(getAllNarrowLimited) }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="program-info-text"
                  >
                    —
                  </div>
                </template>
                <!-- <div
                  v-if="subprogramDetailData.subprogram.specializations && Object.keys(subprogramDetailData.subprogram.specializations).length"
                  class="program-info-text"
                >
                  {{ limitlessSpecializations }}
                  <br>
                  {{ limitedSpecializations }}
                </div>
                <div
                  v-else
                  class="program-info-text"
                >
                  —
                </div> -->
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Считать лимит телемедицинских консультаций.
                </div>
                <div
                  class="program-info-text"
                >
                  {{ subprogramDetailData.subprogram.isCountConsultationLimit ? 'Да' : 'Нет' }}
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Узкопрофильные блоки
                </div>
                <div
                  v-if="subprogramDetailData.subprogram.narrowSpecializations && subprogramDetailData.subprogram.narrowSpecializations.length"
                  class="program-info-text"
                >
                  {{ narrowSpecializations }}
                </div>
                <div
                  v-else
                  class="program-info-text"
                >
                  —
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Покрытие лабораторных и инструментальных исследований
                </div>

                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.researchCover)"
                />
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Лечение
                </div>

                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.therapy)"
                />
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Гарантийные письма
                </div>

                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.indemnityLetters)"
                />
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Транспортные расходы
                </div>

                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.fares)"
                />
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Канал реализации
                </div>

                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.channel)"
                />
              </div>
            </div>
            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Описание программы
                </div>
                <div
                  class="program-info-text"
                  v-html="ifFound(subprogramDetailData.subprogram.description)"
                />
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Документы
                </div>
                <div
                  v-if="subprogramDetailData.subprogram.documentIds && subprogramDetailData.subprogram.documentIds.length"
                  class="crm-file-list"
                >
                  <base-file
                    v-for="document in documents"
                    :key="document.id"
                    :file="document"
                    file-api="File/"
                  />
                </div>
                <div
                  v-else
                  class="program-info-text"
                >
                  —
                </div>
              </div>
            </div>

            <div class="program-info-row">
              <div class="program-info-block">
                <div class="program-info-title">
                  Полезные ссылки
                </div>
                <div
                  v-if="subprogramDetailData.subprogram.usefulLinks && subprogramDetailData.subprogram.usefulLinks.length"
                  class="program-info-links"
                >
                  <div
                    v-for="(link, index) in subprogramDetailData.subprogram.usefulLinks"
                    :key="index"
                    class="program-info-link-item"
                  >
                    <a
                      :href="link"
                      target="_blank"
                      class="program-info-link"
                    >
                      {{ link }}
                    </a>
                  </div>
                </div>
                <div
                  v-else
                  class="program-info-text"
                >
                  —
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import {
  format, formatISO, isAfter, isBefore, parseISO,
} from 'date-fns';

import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { dateWithoutTime } from '@/helpers/utils';

import IconWorksheet from 'assets/images/worksheet.svg';
import IconCheck from 'assets/images/check.svg';
import IconInfo from 'assets/images/info.svg';
import IconArrowDropdown from 'assets/images/arrow-dropdown.svg';
import IconServices from 'assets/images/policy-services.svg';

import {
  BaseButton, BaseDatePicker, BaseDropdown, BaseFile, BaseInput,
} from '@/components/base';

export default {
  name: 'PatientDetailPolicy',
  components: {
    IconInfo,
    IconWorksheet,
    IconCheck,
    BaseInput,
    BaseButton,
    BaseDropdown,
    BaseDatePicker,
    IconServices,
    IconArrowDropdown,
    BaseFile,
  },
  mixins: [mixinRoles],
  props: {
    policy: {
      type: Object,
      default: null,
    },
    personId: {
      type: Number,
      default: undefined,
    },
    showNewIndemnityButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      historyPolicyData: null,
      policyStatuses: {
        inactive: [0, 1, 4, 5],
        pending: [2],
        active: [3],
      },
      fields: [
        {
          key: 'date',
          label: 'Дата',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Изменения',
          sortable: true,
        },
      ],
      prolongationFields: [
        {
          key: 'startDate',
          label: 'Дата начала',
          sortable: true,
        },
        {
          key: 'endDate',
          label: 'Дата окончания',
          sortable: true,
        },
      ],
      policyEditData: null,
      isOpen: false,
      isEdit: false,
      isDeleting: false,
      isSaving: false,
      insurancePrograms: [],
      insuranceSubprograms: [],
      subprogramDetailData: null,
      isSubprogramDetailOpen: false,
      isSubprogramDetailLoading: false,
      activationTypes: {
        0: 'По номеру полиса',
        1: 'По страхователю',
        2: 'Автоматическая активация',
      },
      attachmentTypes: {
        0: 'Дмс',
        1: 'ОМС',
      },
      policyPurposes: {
        1: 'Телемедицина',
        2: 'Диагностика',
        3: 'Госпитализация',
        4: 'Лечение',
        5: 'Роды',
        6: 'Ведение беременности',
      },
      subProgramTypes: {
        0: 'Индивидуальная',
        1: 'Семейная',
      },
      periodTypes: [
        {
          type: 'day',
          text: 'день/дней',
        },
        {
          type: 'week',
          text: 'неделя/недель',
        },
        {
          type: 'month',
          text: 'месяц/месяцев',
        },
        {
          type: 'year',
          text: 'год/лет',
        },
      ],
    };
  },
  computed: {
    isAllSpecializationsSelected() {
      return Object.keys(this.subprogramDetailData.subprogram.specializations).length === this.subprogramDetailData.specializations.length;
    },
    minPolicyEditDateTo() {
      return this.policyEditData.startDate || null;
    },
    maxPolicyEditData() {
      return this.policyEditData.endDate || new Date();
    },
    getAllSelectedSpecializationsSting() {
      const arr = [];
      Object.keys(this.subprogramDetailData.subprogram.specializations).map((key) => {
        const doctor = this.subprogramDetailData.specializations.find((s) => s.id === +key);
        arr.push({
          ...doctor,
          limit: this.subprogramDetailData.subprogram.specializations[key],
        });
        return null;
      });
      return arr;
    },

    getAllSelectedDutySpecializations() {
      return this.getAllSelectedSpecializationsSting.filter((doctor) => doctor.name?.includes('Дежурный'));
    },

    getAllSelectedNarrowSpecializations() {
      return this.getAllSelectedSpecializationsSting.filter((doctor) => !doctor.name?.includes('Дежурный'));
    },

    getAllNarrowLimited() {
      return this.getAllSelectedNarrowSpecializations.filter((doctor) => doctor.limit !== 999);
    },

    getAllNarrowNotLimited() {
      return this.getAllSelectedNarrowSpecializations.filter((doctor) => doctor.limit === 999);
    },

    getAllSelectedDutySpecializationsSting() {
      const arr = this.getAllSelectedDutySpecializations;
      const doctorsNameString = arr.map((doctor) => doctor.name);
      return doctorsNameString.join(', ');
    },

    isActive() {
      const statement = this.policyStatuses.active.includes(this.policy.policyStatus);
      return statement;
    },
    isPending() {
      const statement = this.policyStatuses.pending.includes(this.policy.policyStatus);
      return statement;
    },
    isInactive() {
      const statement = this.policyStatuses.inactive.includes(this.policy.policyStatus);
      return statement;
    },
    policyStartDate() {
      return this.policy.startDate ? this.parseData(this.policy.startDate) : null;
    },
    policyEndDate() {
      return this.policy.endDate ? this.parseData(this.policy.endDate) : null;
    },
    isActiveDate() {
      const { startDate, endDate } = this.policyEditData;
      const now = new Date();

      if (!(startDate && endDate)) return false;

      return isBefore(startDate, now) && isAfter(endDate, now);
    },
    insuranceCompanies() {
      return this.$store.state.Patients.insuranceCompanies.map(({ company: { id, title } }) => ({ id, title }));
    },
    periodText() {
      const day = this.subprogramDetailData.subprogram.policyPeriod.count;
      const { text } = this.periodTypes.find((p) => p.type === this.subprogramDetailData.subprogram.policyPeriod.type);
      return `${day} ${text}`;
    },
    limitlessSpecializations() {
      const limitlessSpecializations = [];
      Object.keys(this.subprogramDetailData.subprogram.specializations).map((key) => {
        const value = +this.subprogramDetailData.subprogram.specializations[key];
        const { name } = this.subprogramDetailData.specializations.find((s) => s.id === +key);
        if (value === 999) {
          limitlessSpecializations.push(name);
        }
        return null;
      });

      if (!limitlessSpecializations.length) {
        return null;
      }

      const textSpecs = limitlessSpecializations.map((spec, index) => {
        if (index > 0) {
          const text = `, ${spec}`;
          return text;
        }
        return spec;
      }).join('');

      return `Неограничено: ${textSpecs}`;
    },
    limitedSpecializations() {
      const limitedSpecializations = [];
      Object.keys(this.subprogramDetailData.subprogram.specializations).map((key) => {
        const value = +this.subprogramDetailData.subprogram.specializations[key];
        const { name } = this.subprogramDetailData.specializations.find((s) => s.id === +key);
        if (value !== 999) {
          limitedSpecializations.push({ name, value });
        }
        return null;
      });

      if (!limitedSpecializations.length) {
        return null;
      }

      const textSpecs = limitedSpecializations.map((spec, index) => {
        if (index > 0) {
          const text = `, ${spec.name} — ${spec.value}`;
          return text;
        }
        return `${spec.name} — ${spec.value}`;
      }).join('');

      return `Ограничено: ${textSpecs}`;
    },
    narrowSpecializations() {
      return this.subprogramDetailData.subprogram.narrowSpecializations.map((spec, index) => {
        if (index > 0) {
          const text = `, ${spec}`;
          return text;
        }
        return spec;
      }).join('');
    },
  },
  created() {
    this.setPolicyEditData();
    if (!this.policy.id) {
      this.togglePolicyInfo();
      this.editPolicy();
    }

    if (this.policy.id === this.$store.state.Patients.activePolicyId) {
      this.togglePolicyInfo();
      this.$store.commit(this.$types.PATIENT_ACTIVE_POLICY_ID_SET, null);
    }
  },
  methods: {
    openPolicyLimitsModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'PolicyLimitsModal',
        props: {
          personId: this.personId,
          policyId: this.policy.id,
        },
      });
    },
    async historyLoad() {
      this.historyPolicyData = await this.$store.dispatch(this.$types.POLICY_HISTORY_FETCH, this.policyEditData.id);
    },
    historyTime(date) {
      return this.parseData(dateWithoutTime(date));
    },
    addExpiredValidity() {
      this.policyEditData.validities.push({
        startDate: null,
        endDate: null,
      });
    },
    joinDoctorsArrayToString(arr) {
      // const arr = this.getAllSelectedNarrowSpecializations;
      const doctorsNameArr = arr.map((doctor) => {
        const str = doctor.limit !== 999 ? `${doctor.name} — ${doctor.limit}` : doctor.name;
        return str;
      });
      return doctorsNameArr.join(', ');
    },
    setInsuranceEntities() {
      if (!this.policyEditData.companyName) return;

      const companyObject = this.$store.state.Patients.insuranceCompanies
        .filter(({ company: { title } }) => title === this.policyEditData.companyName)[0];

      const programs = companyObject.programsWithSubprograms.map(({ program: { id, name } }) => ({ id, name }));
      this.insurancePrograms = programs;

      if (!this.policyEditData.programName) return;

      const programObject = companyObject.programsWithSubprograms.filter(({ program: { name } }) => name === this.policyEditData.programName)[0];
      this.insuranceSubprograms = programObject.subPrograms.map(({ subprogramId, name }) => ({ id: subprogramId, name }));
    },
    setPolicyEditData() {
      const validities = this.policy.validities?.map(({ startDate, endDate, counter }) => ({
        counter,
        startDate: startDate ? parseISO(startDate) : null,
        endDate: endDate ? parseISO(endDate) : null,
      })) || [];

      this.policyEditData = {
        ...this.policy,
        startDate: this.policy.startDate ? parseISO(this.policy.startDate) : null,
        endDate: this.policy.endDate ? parseISO(this.policy.endDate) : null,
        buyDate: this.policy.buyDate ? parseISO(this.policy.buyDate) : null,
        validities,
      };

      this.setInsuranceEntities();
    },
    togglePolicyInfo() {
      this.setPolicyEditData();
      this.isSubprogramDetailOpen = false;
      this.isEdit = false;
      this.isOpen = !this.isOpen;
      if (this.policy.id) this.historyLoad();
    },
    editPolicy() {
      this.isEdit = !this.isEdit;
    },
    cancelPolicyEdit() {
      if (!this.policy.id) {
        Bus.$emit('patient:update');
      } else {
        this.setPolicyEditData();
        this.isEdit = false;
      }
    },
    async savePolicy() {
      this.isSaving = true;
      const formattedPolicyData = { ...this.policyEditData };
      formattedPolicyData.policyId = formattedPolicyData.policyId.trim();
      formattedPolicyData.startDate = formattedPolicyData.startDate ? formatISO(formattedPolicyData.startDate) : null;
      formattedPolicyData.buyDate = formattedPolicyData.buyDate ? formatISO(formattedPolicyData.buyDate) : null;
      formattedPolicyData.endDate = formattedPolicyData.endDate
        ? formatISO(formattedPolicyData.endDate.setHours(23, 59, 0, 0)) // set time to 23:59
        : null;
      formattedPolicyData.validities = formattedPolicyData.validities.map(({ startDate, endDate, counter }) => ({
        counter,
        startDate: startDate ? formatISO(startDate) : null,
        endDate: endDate ? formatISO(endDate) : null,
      }));

      try {
        if (!this.policy.id) {
          await this.$store.dispatch(this.$types.PATIENT_POLICY_CREATE, formattedPolicyData);
        } else {
          await this.$store.dispatch(this.$types.PATIENT_POLICY_UPDATE, formattedPolicyData);
        }
      } finally {
        Bus.$emit('patient:update');
        Bus.$emit('patients:update');
      }
    },
    openIndemnityModal(policyId, subProgramId, isReferral = false) {
      Bus.$emit('open-modal:indemnity-modal', {
        personId: this.personId, policyId, subProgramId, isReferral,
      });
    },
    openIndemnityHistoryModal(isReferral = false) {
      Bus.$emit('open-modal:indemnity-history-modal', {
        policyId: this.policy.id,
        subProgramId: this.policy.subProgramV2Id,
        personId: this.personId,
        isReferral,
      });
    },
    openPolicyActivateModal(policy) {
      Bus.$emit('open-modal:policy-activate', policy);
    },
    openPolicyDeleteModal() {
      Bus.$emit('open-modal:policy-delete', { policyId: this.policy.id });
    },
    async openPersonServices() {
      Bus.$emit('open-modal:policy-person-services', {
        subprogramId: this.policy.subProgramV2Id,
      });
      await this.$store.dispatch(this.$types.PATIENT_SERVICES_FETCH, {
        policyId: this.policy.policyId,
        subprogramId: this.policy.subProgramV2Id,
        legalPersonId: '',
      });
    },
    openPolicyDetachModal() {
      Bus.$emit('open-modal:policy-detach', { policyId: this.policy.id });
    },
    openPolicyDetachDopModal() {
      Bus.$emit('open-modal:policy-detach-dop', { personId: this.personId, policyId: this.policy.id });
    },
    parseData(ISODate) {
      return format(parseISO(ISODate), 'dd.MM.yyyy');
    },
    changeCompany() {
      this.policyEditData.programName = null;
      this.policyEditData.subProgramV2Id = null;
      this.insurancePrograms = [];
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    changeProgram() {
      this.policyEditData.subProgramV2Id = null;
      this.insuranceSubprograms = [];
      this.setInsuranceEntities();
    },
    onSubprogramChange(subprogramId) {
      this.subprogramDetailData = null;
      this.isSubprogramDetailOpen = false;
      if (!subprogramId) return;

      const subprogram = this.insuranceSubprograms.filter((subprog) => subprog.id === subprogramId)[0];
      this.policyEditData.description = subprogram.name;
      this.policyEditData.subProgramV2Id = subprogram.id;
    },
    async toggleSubprogramDetail() {
      if (!this.subprogramDetailData) {
        await this.loadSubprogramDetail();
      }

      this.isSubprogramDetailOpen = !this.isSubprogramDetailOpen;
    },
    async loadSubprogramDetail() {
      this.isSubprogramDetailLoading = true;

      const { company, program, subProgram: subprogram } = await this.$store.dispatch(this.$types.SUBPROGRAM_FETCH,
        this.policyEditData.subProgramV2Id);
      const specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');

      this.subprogramDetailData = {
        company,
        program,
        subprogram,
        specializations,
      };

      const documentsName = await Promise.all(this.subprogramDetailData.subprogram.documentIds
        .map((docId) => this.$store.dispatch(this.$types.FILENAME_BY_ID_FETCH, docId)));
      this.documents = documentsName.map((title, i) => ({ title, guid: this.subprogramDetailData.subprogram.documentIds[i] }));

      this.isSubprogramDetailLoading = false;
    },
    removeValiditiesDate(index) {
      this.policyEditData.validities.splice(index, 1);
    },
    ifFound(data) {
      if (data) return data;

      return '—';
    },
  },
};
</script>

<style lang="scss" scoped>
.person-policy {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px;
  min-height: 100px;
  border: 1px solid #E0E0E0;
  position: relative;

  &.active {
    border: 1px solid #219653;
  }
  &.pending {
    border: 1px solid #F2994A;
  }
  &.inactive {
    border: 1px solid #EB5757;
  }

  & + & {
    margin-top: 10px;
  }
}
.policy-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.policy-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.policy-info {
  word-break: break-word;
  margin-right: auto;
}

.policy-info-main {
  font-size: 16px;
  line-height: 20px;
}

.policy-name {
  font-weight: 600;
}

.crm-control-icon {
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &.crm-border {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
  }
}

.policy-detail {
  margin-top: 20px;
  border-top: 1px solid #E0E0E0;
}

.crm-wrapper-buttons {
  margin-top: 40px;
}

.button_transparent {
  border: none;
}
.crm-wrapper-buttons .crm-button.mr-auto {
  margin-right: auto;
}
.crm-button.width-unset {
  width: unset;
  padding-left: 20px;
  padding-right: 20px;
}

::v-deep.crm-form-field {
  flex: 1;
  &:not(:first-child) {
    margin-left: 10px;
  }

  &.w-50 {
    width: calc(50% - 5px);
  }

  .crm-label {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #707070;
  }
  .base-input {
    height: 40px;
  }
}
.policy-form-fields {
  margin-top: 20px;
  display: flex;
}
.policy-info-value {
  &.red {
    color: $red;
  }
  &.green {
    color: $green;
  }
}
.letter-history-btn {
  font-weight: normal;
}

.policy-info-item {
  & + & {
    margin-top: 10px;
  }
}

.policy-info-title {
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #707070;
}

.subprogram-toggle-btn {
  background: rgba(110, 136, 243, 0.1);
  border-radius: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 20px -20px -20px;
  box-sizing: border-box;
  width: calc(100% + 40px) !important;
  border: none;
}

.crm-icon-arrow-down {
  path {
    fill: #6E88F3;
  }

  &.reverse{
    transform: rotate(180deg);
  }
}

.policy-subprogram-detail {
  margin-top: 50px;
}

.crm-title-row {
  display: flex;
  justify-content: space-between;
}

.program-title {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-right: 30px;
}

.program-info-row {
  display: flex;
  margin-top: 30px;
}

.program-info-block {
  flex: 1;

  & + & {
    margin-left: 30px;
  }
}

.program-info-title {
  font-size: 14px;
  line-height: 18px;
  color: #707070;
}

.program-info-text {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  white-space: pre-line;
}

::v-deep.crm-form-step {
  margin-top: 30px;
  .dropdown-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

::v-deep.crm-form-field--dates {
  display: flex;
  margin-left: 0!important;

  .date-picker {
    max-width: 150px;
  }

  .crm-icon-delete {
    margin-left: 10px;
    flex-shrink: 0;
    cursor: pointer;
    align-self: center;
  }
}

.dates-list {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: space-between;

  .dates-item {
    width: calc(50% - 10px);
    margin-bottom: 30px
  }
}

::v-deep.clinic-item {
  display: block;
  text-decoration: none;
  color: #323232;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  & + & {
    margin-top: 15px;
  }
}

.person-services-icon {
  border: 1px solid #E0E0E0;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}
</style>
