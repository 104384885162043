<template>
  <div class="analysis-block-wrapper">
    <div class="crm-form-title">
      <span class="crm-form-title-inner">
        Выбранный пакет анализов
      </span>
    </div>

    <div class="crm-form-row">
      <div class="crm-form-field w-50">
        <base-async-select
          :value="selectedTemplate || { name: 'Введите название' }"
          class="crm-select"
          label="Название шаблона ГП"
          loading-title="Загрузка шаблонов"
          no-options-title="Шаблоны не найдены"
          :clearable="false"
          :fetch-function="fetchTemplates"
          :placeholder="'Введите название'"
          :is-search="true"
          server-paginated
          @change="addTemplate"
        />
      </div>
      <div class="crm-form-field w-50">
        <base-async-select
          :value="{ name: 'Введите название' }"
          class="crm-select"
          label="Анализы"
          loading-title="Загрузка анализов"
          no-options-title="Анализы не найдены"
          :fetch-function="fetchMedtests"
          :clearable="false"
          :is-search="true"
          server-paginated
          @change="addMedtest"
        />
      </div>
    </div>
    <template v-if="medtests.length !== 0">
      <div class="medtest-list">
        <custom-scrollbar>
          <div class="medtest-list-inner">
            <div
              v-if="medtests.length === 0"
              class="medtest-item empty"
            >
              <div class="name">
                Пусто
              </div>
            </div>
            <div
              v-for="medtest in medtests"
              :key="medtest.id"
              class="medtest-item"
            >
              <div class="name">
                {{ medtest.name }}
              </div>

              <div class="price">
                {{ medtest.vendor_price }} ₽
              </div>

              <div
                class="delete-btn"
                @click="removeMedtestById(medtest.id)"
              >
                <icon-cross
                  height="10"
                  width="10"
                  viewBox="0 0 15 15"
                  class="crm-icon-cross"
                />
              </div>
            </div>
          </div>
        </custom-scrollbar>
      </div>
      <div class="medtest-total">
        <div class="title">
          Итого
        </div>
        <div class="cost">
          {{ totalCost }} ₽
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {
  BaseAsyncSelect,
} from '@/components/base';

import IconCross from 'assets/images/cross.svg';

export default {
  name: 'PatientData',
  components: {
    BaseAsyncSelect,
    IconCross,
  },
  data() {
    return {
      selectedTemplate: null,
    };
  },
  computed: {
    medtests() {
      return this.$store.state.Analysis.medtests;
    },
    totalCost() {
      return this.medtests.reduce((total, value) => total + value.vendor_price, 0);
    },
  },
  methods: {
    async fetchTemplates({ query }) {
      const templates = await this.$store.dispatch(this.$types.ANALYSIS_TEMPLATES_FETCH, {
        take: 100,
        query,
        subprogramId: null,
      });

      return templates;
    },
    async fetchMedtests({ skip, take, query }) {
      const medtests = await this.$store.dispatch(this.$types.ANALYSIS_MEDTEST_FETCH, {
        take,
        query,
        skip,
      });

      return medtests;
    },
    addTemplate(template) {
      this.selectedTemplate = template;
      template.med_tests.map((medtest) => this.addMedtest(medtest));
    },
    addMedtest(medtest) {
      const isInArray = this.medtests.find((test) => test.id === medtest.id);
      if (isInArray) return;

      this.$store.commit(this.$types.ANALYSIS_MEDTESTS_SET, [...this.medtests, medtest]);
    },
    removeMedtestById(id) {
      this.selectedTemplate = null;
      const array = this.medtests.filter((medtest) => medtest.id !== id);
      this.$store.commit(this.$types.ANALYSIS_MEDTESTS_SET, [...array]);
    },
  },
};
</script>

<style lang="scss" scoped>

.analysis-block-wrapper {
  margin-top: 40px;
}

.crm-form-title {
  position: relative;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #979797;

  &::before {
    position: absolute;
    content: '';
    display: block;
    height: 1px;
    background: #E0E0E0;
    top: calc(50% + 1px);
    left: 0;
    right: 0;
    z-index: 0;
  }

  &-inner {
    position: relative;
    z-index: 1;
    background-color: #fff;
    padding-right: 20px;
  }
}

.crm-form-row {
  display: flex;
  width: 100%;
  margin-top: 20px;
}

::v-deep.crm-form-field {
  display: flex;
  flex: 1;

  &:not(:first-child) {
    margin-left: 20px;
  }

  &.w-25 {
    width: 25%;
    max-width: calc((100% - 60px)/ 4);
  }

  &.w-33 {
    width: 33.3%;
    max-width: calc((100% - 40px)/ 3);
  }

  &.w-50 {
    width: 50%;
    max-width: calc((100% - 20px)/ 2);
  }

  .crm-form-block {
    width: 100%;
  }

  .crm-label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    font-weight: 400;
  }
  .crm-base-input {
    height: 40px;
  }

  .crm-select {
    width: 100%;
  }
}

.medtest-list {
  margin-top: 20px;
  background: #F9F9F9;
  border-radius: 10px;
  height: 200px;
}

.medtest-item {
  padding: 15px 15px 15px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EDEDED;

  &.empty {
    border-bottom: none;
  }

  .name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: auto;
  }

  .price {
    margin-left: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #6E88F3;
    white-space: nowrap;
  }

  .delete-btn {
    cursor: pointer;
    padding: 5px 15px;
    transition: .3s all;

    &:hover {
      opacity: 0.5;
    }

    .crm-icon-cross {
      transform: rotate(45deg);
      path {
        fill: #373429;
      }
    }
  }
}

.medtest-total {
  padding: 10px 55px 0 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  .cost {
    color: #6E88F3;
    margin-left: auto;
  }
}
</style>
