v<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Создание новой услуги'"
    @hidden="onClose"
    @ok="onClose"
  >
    <loading v-if="isLoading">
      Загрузка данных
    </loading>
    <div>
      <b-row>
        <b-col>
          <b-form-group
            label="Название услуги"
          >
            <b-form-input
              v-model="$v.serviceName.$model"
              type="text"
              :state="validateState('serviceName')"
              :placeholder="'Введите название услуги'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Описание услуги"
          >
            <b-form-textarea
              id="nested-street"
              v-model="$v.serviceDescription.$model"
              type="text"
              :state="validateState('serviceDescription')"
              :placeholder="'Введите описание услуги'"
              class="w-100"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Срок изготовления">
            <b-form-input
              v-model="$v.serviceProductionTime.$model"
              type="text"
              :state="validateState('serviceProductionTime')"
              :placeholder="'Введите срок изготовления услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Подготовка к анализу">
            <b-form-textarea
              v-model="$v.servicePreparation.$model"
              type="text"
              :state="validateState('servicePreparation')"
              :placeholder="'Введите описание подготовки к анализу'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Противопоказания">
            <b-form-input
              v-model="$v.serviceContraindications.$model"
              type="text"
              :state="validateState('serviceContraindications')"
              :placeholder="'Введите противопоказания к услуге'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Расшифровка">
            <b-form-textarea
              v-model="$v.serviceDecoding.$model"
              type="text"
              :state="validateState('serviceDecoding')"
              :placeholder="'Введите расшифровку услуги'"
              trim
            />
            <b-form-invalid-feedback id="input-1-live-feedback">
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Код услуги"
          >
            <b-form-input
              v-model="$v.serviceCode.$model"
              type="text"
              :state="validateState('serviceCode')"
              :placeholder="'Введите код услуги'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-form-group
            label="Стоимость услуги"
          >
            <b-form-input
              v-model="serviceCost"
              type="text"
              :placeholder="'Введите стоимость услуги'"
              trim
            />
            <b-form-invalid-feedback
              id="input-1-live-feedback"
            >
              Данное поле обязательно*
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row>
        <b-col>
          <b-form-group label="Активирован">
            <b-form-select
              v-model="isActive"
              :options="optionsActivate"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <b-form-checkbox
            v-model="isCheckup"
            size="sm"
            @change="onCheckUp"
          >
            <span class="text-secondary font-weight-bold"> Чекап </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
      <transition name="fade">
        <b-row v-if="isCheckup">
          <b-col>
            <b-form-group label="Дочерние услуги">
              <multiselect
                v-model="$v.selected.$model"
                :multiple="true"
                placeholder="Выберите дочернюю услугу"
                :close-on-select="false"
                label="name"
                track-by="id"
                :searchable="true"
                :options="serviceList"
                @search-change="fetchServices"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </transition>
      <b-row
        v-if="$v.selected.$error"
        class="mb-5"
      >
        <b-col>
          <div
            class="validation-errors"
          >
            <span class="validation-error-text">
              Данное поле обязательно*
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Категории">
            <multiselect
              v-model="serviceTags"
              :multiple="true"
              placeholder="Выберите категорию"
              :close-on-select="false"
              label="name"
              track-by="id"
              :options="tags"
              @search-change="fetchTags"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div>
        <b-button
          variant="primary"
          @click="onClickCreateService"
        >
          Создать
        </b-button>
        <b-button
          variant="danger"
          class="ml-2"
          @click="onClose"
        >
          Отмена
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import { api } from '@/helpers/api';
import debounce from 'lodash.debounce';

const FETCH_DELAY = 700;

export default {
  name: 'AddServiceModal',
  components: {
    Loading,
  },
  mixins: [validationMixin],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    params: {
      type: Object,
      default: null,
    },
    cities: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      rows: [],
      selected: [],
      serviceName: '',
      serviceCode: '',
      serviceCost: null,
      serviceDescription: '',
      serviceProductionTime: '',
      servicePreparation: '',
      serviceContraindications: '',
      serviceDecoding: '',
      serviceTags: [],
      tags: [],
      isActive: null,
      isCheckup: false,
      // legalPartnerId: '',
      // optionsLegalPartner: [],
      optionsActivate: [
        { value: null, text: 'Выберите состояние' },
        { value: true, text: 'Активирован' },
        { value: false, text: 'Деактивирован' },
      ],
      serviceList: [],
    };
  },
  validations() {
    return {
      serviceName: { required },
      serviceCode: { required },
      serviceDescription: { required },
      serviceProductionTime: { required },
      servicePreparation: { required },
      serviceContraindications: { required },
      serviceDecoding: { required },
      selected: {
        required: this.isCheckup ? required : () => true,
      },
    };
  },
  async created() {
    await this.fetchServices();
    await this.fetchTags();
  },
  methods: {
    onCheckUp() {
      this.selected = [];
    },
    async onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    fetchTags: debounce(async function fetchTags(query) {
      const { data: { data } } = await this.$store.dispatch(this.$types.GET_LABORATORY_TAGS, { isActive: true, Name: query });

      this.tags = data;
    }, FETCH_DELAY),
    fetchServices: debounce(async function fetchServices(query) {
      const params = new URLSearchParams();

      params.append('isMerged', true);
      if (query) params.append('Name', query);

      const { data: { data } } = await api.get('v3/laboratory/service/list', {
        params,
      });
      this.serviceList = data;
    }, FETCH_DELAY),
    async onClickCreateService() {
      if (!this.onCheckValidation()) return;
      const tagsIds = this.serviceTags.map((item) => item.id);
      const childIds = this.selected.map((item) => item.id);

      const params = {
        name: this.serviceName,
        code: this.serviceCode,
        cost: +this.serviceCost,
        description: this.serviceDescription,
        productionTime: this.serviceProductionTime,
        preparation: this.servicePreparation,
        contraindications: this.serviceContraindications,
        decoding: this.serviceDecoding,
        childIds,
        isActive: this.isActive ?? false,
        tags: tagsIds,
      };
      await this.$store.dispatch(this.$types.LABORATORY_SERVICE_CREATE, params);
      await this.$store.dispatch(this.$types.GET_LABORATORY_SERVICES, this.params);
      this.onClose();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    onCheckValidation() {
      this.$v.serviceName.$touch();
      this.$v.serviceCode.$touch();
      this.$v.serviceDescription.$touch();
      this.$v.serviceProductionTime.$touch();
      this.$v.servicePreparation.$touch();
      this.$v.serviceContraindications.$touch();
      this.$v.serviceDecoding.$touch();
      this.$v.selected.$touch();
      if (this.$v.serviceName.$anyError
        || this.$v.serviceCode.$anyError
        || this.$v.serviceDescription.$anyError
        || this.$v.serviceProductionTime.$anyError
        || this.$v.servicePreparation.$anyError
        || this.$v.serviceContraindications.$anyError
        || this.$v.serviceDecoding.$anyError
        || this.$v.selected.$anyError
      ) {
        return false;
      }
      return true;
    },
    formatInn(e) {
      return String(e).substring(0, 12);
    },
    formatKpp(e) {
      return String(e).substring(0, 9);
    },
  },
};
</script>

<style lang="scss" scoped>

.crm-table {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &-row {
    display: flex;

    &--header {
      background: #F3F3F3;
      color: #6E88F3;
    }

    & + & {
      border-top: 1px solid #E0E0E0;
    }
  }

  &-cell {
    padding: 10px;
    width: 100%;

    &--date {
      flex-shrink: 0;
      width: 150px;
    }

    & + & {
      border-left: 1px solid #E0E0E0;
    }
  }
}
.error-text{
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
