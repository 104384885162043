<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="title"
    size="md"
    no-close-on-backdrop
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div>
      <div
        v-if="specializations.length"
        class="mb-3"
      >
        <div class="mb-2 font-weight-bold">
          Специальности врача:
        </div>
        <div>
          <template v-for="(specialization, index) in specializations">
            <span :key="index">
              <span v-if="index">, </span>
              {{ specialization }}
            </span>
          </template>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-2 font-weight-bold">
          ФИО пациента
        </div>
        <div>
          {{ fio }}
        </div>
      </div>
      <div class="d-flex mb-3">
        <div>
          <div class="mb-2 font-weight-bold text-success">
            Время создания
          </div>
          <div>
            {{ createDate }}
          </div>
        </div>
        <div class="ml-4">
          <div class="mb-2 font-weight-bold text-warning">
            Время взятия
          </div>
          <div>
            {{ takeDate }}
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="mb-2 font-weight-bold">
          Текст обращения
        </div>
        <div>
          {{ consultation.body }}
        </div>
      </div>
      <div
        v-if="consultation.protocolText"
        class="mb-3"
      >
        <div class="mb-2 font-weight-bold">
          Протокол
        </div>
        <div>
          {{ consultation.protocolText || '-' }}
        </div>
      </div>
    </div>
    <div class="my-divider" />
    <div class="d-flex justify-content-between">
      <b-button
        v-if="status !== 'close'"
        size="sm"
        variant="danger"
        @click="openCancelModal"
      >
        Отменить консультацию
      </b-button>
      <b-button
        v-if="status !== 'close' && status !== 'work'"
        size="sm"
        variant="primary"
        class="ml-2"
        @click="openTransferModal"
      >
        Перенести консультацию
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { format, parseISO } from 'date-fns';

export default {
  name: 'ScheduleInformationModal',

  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: 'Информация о консультации',
    },
    specializations: {
      type: Array,
      default: () => ([]),
    },
    consultation: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isOpen: true,
    consultationStatuses: {
      new: [0],
      work: [4, 1, 3],
      close: [9, 8, 6, 7, 2],
    },
  }),
  computed: {
    fio() {
      const { firstName, lastName, middleName } = this.consultation.patient;
      return `${lastName}\n${firstName}\n${middleName}`;
    },
    createDate() {
      return (this.consultation.createDate && format(parseISO(this.consultation.createDate), 'dd.MM.yyyy HH:mm:ss')) || '-';
    },
    takeDate() {
      return (this.consultation.takeOutDate && format(parseISO(this.consultation.takeOutDate), 'dd.MM.yyyy HH:mm:ss')) || '-';
    },
    status() {
      if (!this.consultation) return;

      let status;
      if (this.consultationStatuses.new.includes(this.consultation.status)) {
        status = 'new';
      }

      if (this.consultationStatuses.work.includes(this.consultation.status)) {
        status = 'work';
      }

      if (this.consultationStatuses.close.includes(this.consultation.status)) {
        status = 'close';
      }

      return status;
    },
  },

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    openTransferModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationTransferModal',
        props: {
          consultationProp: {
            ...this.consultation,
            id: this.consultation.consultationId,
          },
        },
      });
      this.onClose();
      // Bus.$emit('open-modal:consultation-transfer', {
      //   ...this.consultation,
      //   id: this.consultation.consultationId,
      // });
    },
    openCancelModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'ConsultationCancelModal',
        props: {
          consultation: {
            ...this.consultation,
            id: this.consultation.consultationId,
          },
        },
      });
      this.onClose();
      // Bus.$emit('open-modal:consultation-cancel', {
      //   ...this.consultation,
      //   id: this.consultation.consultationId,
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.my-divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: rgba(136, 136, 136, 0.5);
  margin: 10px 0;
}
</style>
