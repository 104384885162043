/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
import NProgress from 'nprogress/nprogress';

import * as types from '@/store/types';
import {
  removeNamespaces,
  showMessage,
  saveFile,
} from '@/helpers/utils';
import { api } from '@/helpers/api';
import { format, parseISO } from 'date-fns';

const localTypes = removeNamespaces(types);

export default {
  async [localTypes.APPEAL_RESPONSIBLES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/appeal/responsibles');
      // const data = [];
      commit(localTypes.APPEAL_RESPONSIBLES_ADD, data);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.APPEAL_HISTORY_FETCH](_, appealId) {
    NProgress.start();

    try {
      const { data } = await api.get(`v2/appeal/getAppealHistory?appealId=${appealId}`);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.APPEAL_TYPES_FETCH]() {
    NProgress.start();

    try {
      const { data } = await api.get('v2/appeal/types');
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_INFORMATION_TYPES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/appeal/types?type=0');
      commit(localTypes.APPEAL_INFORMATION_TYPES_ADD, data);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_TREATMENT_TYPES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/appeal/types?type=1');
      commit(localTypes.APPEAL_TREATMENT_TYPES_ADD, data);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_GOSTELEMED_TYPES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/appeal/types?type=2');
      commit(localTypes.APPEAL_GOSTELEMED_TYPES_ADD, data);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_CHECKUP_PROFILES_FETCH]({ commit }) {
    NProgress.start();

    try {
      const { data } = await api.get('v2/specializations/checkup_profiles');
      commit(localTypes.APPEAL_CHECKUP_PROFILES_ADD, data);
      NProgress.done();
      return data;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_ADD](ctx, appeal) {
    NProgress.start();

    try {
      const data = await api.post('v2/appeal', appeal);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Обращение успешно добавлено!',
      });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEAL_UPDATE](ctx, appeal) {
    NProgress.start();

    try {
      const data = await api.put('v2/appeal', appeal);
      showMessage({
        type: 'success',
        title: 'Успешно',
        message: 'Обращение успешно обновлено!',
      });

      return data;
    } catch (e) {
      console.warn(e);
      throw e;
    } finally {
      NProgress.done();
    }
  },

  async [localTypes.APPEALS_FETCH]({ commit }, {
    skip,
    take,
    filter,
    clear,
  }) {
    NProgress.start();
    try {
      if (clear) commit(localTypes.APPEALS_SET, []);

      const params = new URLSearchParams();
      if (skip) params.append('skip', skip);
      if (take) params.append('take', take);

      const body = {};
      if (filter.patientFio) body.patientFio = filter.patientFio;
      if (filter.hasReconciliation) body.hasReconciliation = filter.hasReconciliation;
      if (filter.phoneNumber) body.phoneNumber = filter.phoneNumber;
      if (filter.creationDateFrom) body.creationDateFrom = filter.creationDateFrom;
      if (filter.creationDateTo) body.creationDateTo = filter.creationDateTo;
      if (filter.responsibleId) body.responsibleId = filter.responsibleId;
      body.status = filter.status ?? undefined;
      body.purpose = filter.purpose ?? undefined;
      if (filter.clinicId) body.clinicId = filter.clinicId;
      if (filter.policyNumber) body.policyNumber = filter.policyNumber;
      if (filter.id) body.id = filter.id;
      if (filter.type) body.type = filter.type;
      if (filter.insuranceCompanyId) body.insuranceCompanyId = filter.insuranceCompanyId;
      if (filter.insuranceProgramId) body.insuranceProgramId = filter.insuranceProgramId;
      if (filter.insuranceSubprogramId) body.insuranceSubprogramId = filter.insuranceSubprogramId;

      const { data } = await api.post('v2/appeal/search', body, { params });
      const appeals = data.map((appeal) => ({
        ...appeal,
        clinic: appeal.clinic || {},
        person: appeal.person || {},
        type: appeal.type || {},
        checkupProfile: appeal.checkupProfile || {},
        city: appeal.city || {},
      }));

      commit(localTypes.APPEALS_ADD, appeals);

      return appeals;
    } finally {
      NProgress.done();
    }
  },
  async [localTypes.APPEALS_EXPORT](_, params) {
    NProgress.start();

    try {
      const { data } = await api.post('v2/appeal/export', params, { responseType: 'blob' });

      const from = params.dateFrom ? format(parseISO(params.dateFrom), 'dd.MM.yyyy') : null;
      const to = params.dateTo ? format(parseISO(params.dateTo), 'dd.MM.yyyy') : null;

      let fileName = 'Выгрузка обращений';
      if (from && to) fileName = `Выгрузка обращений ${from} - ${to}.xlsx`;
      if (from && !to) fileName = `Выгрузка обращений от ${from}.xlsx`;
      if (!from && to) fileName = `Выгрузка обращений до ${to}.xlsx`;

      saveFile(data, { name: fileName });
    } catch (e) {
      console.warn(e);

      throw (e);
    } finally {
      NProgress.done();
    }
  },
};
