import Bus from '@/eventBus';

import { BaseModalHeader } from '@/components/base';

import DoctorInfoModal from '@/components/Doctors/Modals/DoctorInfoModal';
import DoctorEditModal from '@/components/Doctors/Modals/DoctorEditModal';
import DoctorConsultationsInfoModal from '@/components/Doctors/Modals/DoctorConsultationsInfoModal';

import DoctorUnloadModal from '@/components/Doctors/Modals/DoctorUnloadModal';
import ClinicEditModal from '@/components/Clinics/Modals/ClinicEditModal';
import ClinicInfoCardDeleteModal from '@/components/Clinics/Modals/ClinicInfoCardDeleteModal';
import DoctorsPinModal from '@/components/Clinics/Modals/DoctorsPinModal';
import ClinicInfoModal from '@/components/Clinics/Modals/ClinicInfoModal';
import ClinicPackageModal from '@/components/Clinics/Modals/ClinicPackageModal';
import ConsultationCancelModal from '@/components/Consultations/Modals/ConsultationCancelModal';
import SmsSendModal from '@/components/Sms/Modals/SmsSendModal';

import PatientAddModal from '@/components/Patients/Modals/PatientAddModal';
import PatientDetailModal from '@/components/Patients/Modals/PatientDetailModal';
import PatientDeleteModal from '@/components/Patients/Modals/PatientDeleteModal';
import PatientReestablishModal from '@/components/Patients/Modals/PatientReestablishModal';
import PatientDepersonalizateModal from '@/components/Patients/Modals/PatientDepersonalizateModal';
import PatientServicesModal from '@/components/Patients/Modals/PatientServicesModal';
import PatientTransferModal from '@/components/Patients/Modals/PatientTransferModal';

import PromocodeFilterModal from '@/components/Promocodes/Modals/PromocodeFilterModal';
import PromocodeDeleteModal from '@/components/Promocodes/Modals/PromocodeDeleteModal';
import PromocodeInfoModal from '@/components/Promocodes/Modals/PromocodeInfoModal';
import FileModal from '@/components/FileModal';
import FileModalModern from '@/components/FileModalModern';

import IndemnityModal from '@/components/Indemnity/Modals/IndemnityModal';
import IndemnityTemplateDeleteModal from '@/components/Indemnity/Modals/IndemnityTemplateDeleteModal';
import IndemnityHistoryModal from '@/components/Indemnity/Modals/IndemnityHistoryModal';
import IndemnityLetterDeleteModal from '@/components/Indemnity/Modals/IndemnityLetterDeleteModal';
import IndemnityLetterAnnulmentModal from '@/components/Indemnity/Modals/IndemnityLetterAnnulmentModal';

import ClinicDocumentEdit from '@/components/Clinics/Modals/ClinicDocumentEdit';
import SpecializationDeleteModal from '@/components/Specializations/Modals/SpecializationDeleteModal';

import PolicyActivateModal from '@/components/Patients/Modals/PolicyActivateModal';
import PolicyDeleteModal from '@/components/Patients/Modals/PolicyDeleteModal';
import PolicyDetachModal from '@/components/Patients/Modals/PolicyDetachModal';
import PolicyDetachDopModal from '@/components/Patients/Modals/PolicyDetachDopModal';

import InsuranceCompanyPhotoModal from '@/components/InsurancePrograms/Modals/InsuranceCompanyPhotoModal';
import InsuranceImportModal from '@/components/InsurancePrograms/Modals/InsuranceImportModal';
import InsuranceProgramDeactivateModal from '@/components/InsurancePrograms/Modals/InsuranceProgramDeactivateModal';
import InsuranceSubProgramDeactivateModal from '@/components/InsurancePrograms/Modals/InsuranceSubProgramDeactivateModal';
import InsurancePolicyExport from '@/components/InsurancePrograms/Modals/InsurancePolicyExport';
import AddClinicDownloadFileModal from '@/components/Clinics/Modals/AddClinicDownloadFileModal';

import UnloadingModal from '@/components/Statistics/Modals/UnloadingModal';

import AppealEditorModal from '@/components/Appeals/Modals/AppealEditorModal';
import AppealDeleteFileModal from '@/components/Appeals/Modals/AppealDeleteFileModal';
import AppealsExportModal from '@/components/Appeals/Modals/AppealsExportModal';
import ConfigurationTemplateAddModal from '@/components/InsurancePrograms/Modals/ConfigurationTemplateAddModal';

import LegalEntityReestrImportModal from '@/components/LegalEntities/Modals/LegalEntityReestrImportModal';
import InsuranceProgramReestrImportModal from '@/components/InsurancePrograms/Modals/InsuranceProgramReestrImportModal';
import LegalEntityReestrExportModal from '@/components/LegalEntities/Modals/LegalEntityReestrExportModal';
import ClinicHistoryModal from '../components/Clinics/Modals/ClinicHistoryModal';
import ReferralDutyConsultationImportModal from '../components/Consultations/Modals/ReferralDutyConsultationImportModal';

const CLOSE_ON_BACKDROP = false;
const SHOW_CLOSE_BUTTON = true;
// eslint-disable-next-line import/prefer-default-export
export const mixinModals = {
  created() {
    Bus.$on('open-modal:doctor-watch', this.openDoctorWatchModal);
    Bus.$on('open-modal:doctor-edit', this.openDoctorEditModal);
    Bus.$on('open-modal:doctor-consultations', this.openDoctorConsultationsModal);
    Bus.$on('open-modal:doctor-unload', this.openDoctorUnloadModal);

    Bus.$on('open-modal:clinic-edit', this.openClinicsEditModal);
    Bus.$on('open-modal:clinic-history', this.openClinicHistoryModal);
    Bus.$on('open-modal:doctors-pin', this.openDoctorsPinModal);
    Bus.$on('open-modal:clinic-info', this.openClinicsInfoModal);
    Bus.$on('open-modal:clinic-card-delete', this.openClinicCardDeleteModal);
    Bus.$on('open-modal:template-indemnity-delete', this.openTemplateIndemnityDeleteModal);
    Bus.$on('open-modal:consultation-cancel', this.openConsultationCancelModal);
    Bus.$on('open-modal:sms-send', this.openSmsSendModal);

    Bus.$on('open-modal:promocode-filter', this.openPromocodeFilterModal);
    Bus.$on('open-modal:promocode-delete', this.openPromocodeDeleteModal);
    Bus.$on('open-modal:promocode-watch', this.openPromocodeWatchModal);
    Bus.$on('open-modal:file-upload', this.openFileUploadModal);

    Bus.$on('open-modal:patient-add', this.openPatientAddModal);
    Bus.$on('open-modal:patient-detail', this.openPatientDetailModal);
    Bus.$on('open-modal:patient-delete', this.openPatientDeleteModal);
    Bus.$on('open-modal:patient-depersonalizate', this.openPatientDepersonalizateModal);
    Bus.$on('open-modal:patient-reestablish', this.openPatientReestablishModal);
    Bus.$on('open-modal:patient-transfer', this.openPatientTransferModal);

    Bus.$on('open-modal:indemnity-modal', this.openIndemnityModal);
    Bus.$on('open-modal:indemnity-history-modal', this.openIndemnityHistoryModal);
    Bus.$on('open-modal:indemnity-letter-delete-modal', this.openIndemnityLetterDeleteModal);
    Bus.$on('open-modal:indemnity-letter-annulment-modal', this.openIndemnityLetterAnnulmentModal);

    Bus.$on('open-modal:clinic-document-edit', this.openClinicDocumentEdit);
    Bus.$on('open-modal:specialization-delete', this.openSpecializationDeleteModal);

    Bus.$on('open-modal:policy-activate', this.openPolicyActivateModal);
    Bus.$on('open-modal:policy-delete', this.openPolicyDeleteModal);
    Bus.$on('open-modal:policy-detach', this.openPolicyDetachModal);
    Bus.$on('open-modal:policy-detach-dop', this.openPolicyDetachDopModal);
    Bus.$on('open-modal:policy-person-services', this.openPersonServices);

    Bus.$on('open-modal:insurance-company-photo', this.openInsuranceCompanyPhotoModal);
    Bus.$on('open-modal:insurance-import', this.openInsuranceImportModal);
    Bus.$on('open-modal:clinic-package', this.openClinicPackageModal);
    Bus.$on('open-modal:insurance-program-deactivation', this.openInsuranceProgramDeactivateModal);
    Bus.$on('open-modal:insurance-sub-program-deactivation', this.openInsuranceSubProgramDeactivateModal);
    Bus.$on('open-modal:insurance-policy-export', this.openPolicyExportModal);
    Bus.$on('open-modal:file-upload-modern', this.openFileUploadModern);
    Bus.$on('open-modal:file-upload-clinic', this.openFileUploadModernClinic);

    Bus.$on('open-modal:statistic-unload', this.openStatisticUnloadingModal);

    Bus.$on('open-modal:appeal-editor', this.openAppealEditorModal);
    Bus.$on('open-modal:appeal-delete-file', this.openAppealFileDeleteModal);
    Bus.$on('open-modal:appeals-export', this.openAppealsExportModal);
    Bus.$on('open-modal:add-configuration-template', this.openConfigurationTemplateAddModal);

    Bus.$on('open-modal:legal-entity-reestr-import', this.openLegalEntityReestrImportModal);
    Bus.$on('open-modal:insurance-program-reestr-import', this.openInsuranceProgramReestrImportModal);
    Bus.$on('open-modal:export-rigisters', this.openLegalEntityReestrExportModal);
    Bus.$on('open-modal:referral-duty-consultation-import-modal', this.openReferralDutyConsultationImportModal);
  },
  beforeDestroy() {
    Bus.$off('open-modal:doctor-watch', this.openDoctorWatchModal);
    Bus.$off('open-modal:doctor-edit', this.openDoctorEditModal);
    Bus.$off('open-modal:doctor-consultations', this.openDoctorConsultationsModal);
    Bus.$off('open-modal:doctor-unload', this.openDoctorUnloadModal);
    Bus.$off('open-modal:file-upload-clinic', this.openFileUploadModernClinic);

    Bus.$off('open-modal:clinic-edit', this.openClinicsEditModal);
    Bus.$off('open-modal:clinic-history', this.openClinicHistoryModal);
    Bus.$off('open-modal:doctors-pin', this.openDoctorsPinModal);
    Bus.$off('open-modal:clinic-info', this.openClinicsInfoModal);
    Bus.$off('open-modal:clinic-card-delete', this.openClinicCardDeleteModal);
    Bus.$off('open-modal:template-indemnity-delete', this.openTemplateIndemnityDeleteModal);
    Bus.$off('open-modal:doctor-unload', this.openDoctorUnloadModal);
    Bus.$off('open-modal:consultation-cancel', this.openConsultationCancelModal);
    Bus.$off('open-modal:sms-send', this.openSmsSendModal);

    Bus.$off('open-modal:promocode-filter', this.openPromocodeFilterModal);
    Bus.$off('open-modal:promocode-delete', this.openPromocodeDeleteModal);
    Bus.$off('open-modal:promocode-watch', this.openPromocodeWatchModal);
    Bus.$off('open-modal:file-upload', this.openFileUploadModal);

    Bus.$off('open-modal:patient-add', this.openPatientAddModal);
    Bus.$off('open-modal:patient-detail', this.openPatientDetailModal);
    Bus.$off('open-modal:patient-delete', this.openPatientDeleteModal);
    Bus.$off('open-modal:patient-depersonalizate', this.openPatientDepersonalizateModal);
    Bus.$off('open-modal:patient-transfer', this.openPatientTransferModal);
    Bus.$off('open-modal:patient-reestablish', this.openPatientReestablishModal);

    Bus.$off('open-modal:indemnity-modal', this.openIndemnityModal);
    Bus.$off('open-modal:indemnity-history-modal', this.openIndemnityHistoryModal);
    Bus.$off('open-modal:indemnity-letter-delete-modal', this.openIndemnityLetterDeleteModal);
    Bus.$off('open-modal:indemnity-letter-annulment-modal', this.openIndemnityLetterAnnulmentModal);

    Bus.$off('open-modal:clinic-document-edit', this.openClinicDocumentEdit);
    Bus.$off('open-modal:specialization-delete', this.openSpecializationDeleteModal);

    Bus.$off('open-modal:insurance-import', this.openInsuranceImportModal);
    Bus.$off('open-modal:clinic-package', this.openClinicPackageModal);
    Bus.$off('open-modal:insurance-program-deactivation', this.openInsuranceProgramDeactivateModal);
    Bus.$off('open-modal:insurance-sub-program-deactivation', this.openInsuranceSubProgramDeactivateModal);
    Bus.$off('open-modal:insurance-policy-export', this.openPolicyExportModal);
    Bus.$off('open-modal:file-upload-modern', this.openFileUploadModern);

    Bus.$off('open-modal:statistic-unload', this.openStatisticUnloadingModal);

    Bus.$off('open-modal:appeal-editor', this.openAppealEditorModal);
    Bus.$on('open-modal:appeal-delete-file', this.openAppealFileDeleteModal);
    Bus.$off('open-modal:appeals-export', this.openAppealsExportModal);
    Bus.$off('open-modal:add-configuration-template', this.openConfigurationTemplateAddModal);

    Bus.$off('open-modal:policy-activate', this.openPolicyActivateModal);
    Bus.$off('open-modal:policy-delete', this.openPolicyDeleteModal);
    Bus.$off('open-modal:policy-detach', this.openPolicyDetachModal);
    Bus.$off('open-modal:policy-detach-dop', this.openPolicyDetachDopModal);

    Bus.$off('open-modal:legal-entity-reestr-import', this.openLegalEntityReestrImportModal);
    Bus.$off('open-modal:insurance-program-reestr-import', this.openInsuranceProgramReestrImportModal);
    Bus.$off('open-modal:export-rigisters', this.openLegalEntityReestrExportModal);
    Bus.$off('open-modal:referral-duty-consultation-import-modal', this.openReferralDutyConsultationImportModal);
  },
  methods: {
    openDoctorWatchModal({ title, id }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title,
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: DoctorInfoModal,
        props: {
          doctorId: id,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'md',
      });
    },
    openPromocodeWatchModal({ title, id }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title,
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PromocodeInfoModal,
        props: {
          doctorId: id,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'md',
      });
    },
    openDoctorEditModal(id) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: id ? 'Редактирование врача' : 'Добавление врача',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: DoctorEditModal,
        props: {
          doctorId: id,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'md',
      });
    },
    openPromocodeFilterModal() {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Фильтры списка промокодов',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PromocodeFilterModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'md',
      });
    },
    openPromocodeDeleteModal(promocode) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление промокода',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PromocodeDeleteModal,
        props: {
          promocode,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openDoctorConsultationsModal(id) {
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Консультации',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: DoctorConsultationsInfoModal,
        props: {
          doctorId: id,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'md',
      });
    },
    openDoctorUnloadModal() {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Отчет по врачам',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: DoctorUnloadModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'lg',
      });
    },
    openConsultationCancelModal(consultation) {
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Вы уверены, что хотите удалить консультацию?',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          consultation,
        },
        component: ConsultationCancelModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openClinicsEditModal(id) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: `${id ? 'Редактирование' : 'Добавление'} клиники`,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ClinicEditModal,
        props: {
          clinicId: id,
        },
        size: 'xl',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openClinicHistoryModal(clinicId) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'История изменений',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ClinicHistoryModal,
        props: {
          clinicId,
        },
        size: 'lg',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openPatientAddModal() {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Добавление пациента',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientAddModal,
        size: 'xl',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openPatientDetailModal(patientId) {
      this.$vuedals.open({
        name: 'bg-gray',
        header: {
          component: BaseModalHeader,
          props: {
            title: '',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientDetailModal,
        props: {
          patientId,
        },
        size: 'xl',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openPatientTransferModal() {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Объединение пациентов',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientTransferModal,
        size: 'sm',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openPatientDeleteModal({ patientId, callback }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Блокировка пациента',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientDeleteModal,
        props: {
          patientId,
          callback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPatientDepersonalizateModal({ patientId, callback }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Деперсонализация пациента',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientDepersonalizateModal,
        props: {
          patientId,
          callback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openAppealFileDeleteModal({ fileId, deleteFileCallback, saveAppealCallback }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление файла',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: AppealDeleteFileModal,
        props: {
          fileId,
          deleteFileCallback,
          saveAppealCallback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPatientReestablishModal({ id }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Восстановление пациента',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientReestablishModal,
        props: {
          id,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openSmsSendModal({ number, name }) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Новое СМС-сообщение',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          numberProp: number,
          name,
        },
        component: SmsSendModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openDoctorsPinModal(pinFunc) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Прикрепить врача к клинике',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
            classes: ['dark'],
          },
        },
        props: {
          pinFunc,
        },
        component: DoctorsPinModal,
        size: 'xl dark',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openClinicsInfoModal(id) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Просмотр клиники',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ClinicInfoModal,
        props: {
          clinicId: id,
        },
        size: 'xl',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openClinicCardDeleteModal({
      clinicId,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление клиники',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ClinicInfoCardDeleteModal,
        props: {
          clinicId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'm',
      });
    },
    openTemplateIndemnityDeleteModal({
      templateId,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление шаблона ГП',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: IndemnityTemplateDeleteModal,
        props: {
          templateId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'm',
      });
    },
    openFileUploadModal({
      saveFileCallback,
      multiple,
      fileApi,
      otherProps,
      type,
    }) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Загрузить файлы',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          saveFileCallback,
          multiple,
          fileApi,
          otherProps,
          type,
        },
        component: FileModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openFileUploadModern({
      saveFileCallback, multiple, fileApi, type,
    }) {
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Импорт файла',
            alignLeft: false,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          saveFileCallback,
          multiple,
          fileApi,
          type,
        },
        component: FileModalModern,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openFileUploadModernClinic({
      saveFileCallback,
      multiple,
      fileApi,
    }) {
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Загрузка документов',
            alignLeft: false,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          saveFileCallback,
          multiple,
          fileApi,
        },
        component: AddClinicDownloadFileModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openIndemnityModal({
      personId,
      subProgramId,
      policyId,
      indemnityLetterId,
      clinicId,
      isSent,
      panelTabId,
      isReferral,
    }) {
      this.$vuedals.open({
        name: 'no-min-height',
        header: {
          component: BaseModalHeader,
          props: {
            alignLeft: true,
            closeButton: false,
            minimizeButton: true,
            minimizeFunc: () => Bus.$emit('indemnity:save-to-tab'),
          },
        },
        props: {
          personId,
          subProgramId,
          policyId,
          indemnityLetterId,
          clinicId,
          isSent,
          panelTabId,
          isReferral,
        },
        component: IndemnityModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xl',
      });
    },
    openIndemnityHistoryModal({
      subProgramId, policyId, personId, isReferral,
    }) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: '',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          subProgramId,
          policyId,
          personId,
          isReferral,
        },
        component: IndemnityHistoryModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xl',
      });
    },
    openIndemnityLetterDeleteModal({
      indemnityLetterId, policyId, clinicId, isReferral,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: `Удаление ${isReferral ? 'направления' : 'гарантийного письма'}`,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: IndemnityLetterDeleteModal,
        props: {
          indemnityLetterId,
          policyId,
          clinicId,
          isReferral,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openIndemnityLetterAnnulmentModal({
      indemnityLetterId, policyId, clinicId, isReferral,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: `Аннулирование ${isReferral ? 'направления' : 'гарантийного письма'}`,
            closeButton: SHOW_CLOSE_BUTTON,
            alignLeft: true,
          },
        },
        component: IndemnityLetterAnnulmentModal,
        props: {
          indemnityLetterId,
          policyId,
          clinicId,
          isReferral,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openClinicDocumentEdit({ document }) {
      this.$vuedals.open({
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Редактирование документа',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        props: {
          documentProp: document,
        },
        component: ClinicDocumentEdit,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openSpecializationDeleteModal(specialization) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление специализации',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: SpecializationDeleteModal,
        props: {
          specialization,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPolicyActivateModal(policy) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Активация полиса',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PolicyActivateModal,
        props: {
          policy,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPolicyDeleteModal({ policyId }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Удаление полиса',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PolicyDeleteModal,
        props: {
          policyId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPolicyDetachDopModal({ personId, policyId }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Открепление полиса',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PolicyDetachDopModal,
        props: {
          personId,
          policyId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPolicyDetachModal({ personId, policyId }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Открепление полиса',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PolicyDetachModal,
        props: {
          personId,
          policyId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openPersonServices({ subprogramId }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Очные оказанные услуги',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: PatientServicesModal,
        props: {
          subprogramId,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xl',
      });
    },
    openInsuranceCompanyPhotoModal({
      photoUrlProp,
      imageHeight,
      imageWidth,
      saveCallback,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Изменение фото',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsuranceCompanyPhotoModal,
        props: {
          photoUrlProp,
          imageHeight,
          imageWidth,
          saveCallback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'sm',
      });
    },
    openInsuranceImportModal({
      params,
      hideModeSelector,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Импорт файла',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsuranceImportModal,
        props: {
          params,
          hideModeSelector,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openClinicPackageModal({
      params,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Создание пакета клиник',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ClinicPackageModal,
        props: {
          params,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openLegalEntityReestrImportModal({
      body,
      forAndrey,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Проверка данных',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: LegalEntityReestrImportModal,
        props: {
          body,
          forAndrey,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openInsuranceProgramReestrImportModal({
      params,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Проверка данных',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsuranceProgramReestrImportModal,
        props: {
          params,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openLegalEntityReestrExportModal() {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Экспорт реестров',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: LegalEntityReestrExportModal,
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xm',
      });
    },

    openInsuranceProgramDeactivateModal({
      program,
      deactivationCallback,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Деактивация программы',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsuranceProgramDeactivateModal,
        props: {
          program,
          deactivationCallback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openInsuranceSubProgramDeactivateModal({
      subprogram,
      deactivationCallback,
    }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Деактивация подпрограммы',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsuranceSubProgramDeactivateModal,
        props: {
          subprogram,
          deactivationCallback,
        },
        closeOnBackdrop: CLOSE_ON_BACKDROP,
        size: 'xs',
      });
    },
    openPolicyExportModal() {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Экспорт полисов',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: InsurancePolicyExport,
        props: {},
        size: 'xs',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openStatisticUnloadingModal(params) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Скачать статистику в формате Excel',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: UnloadingModal,
        props: {
          dateFrom: params.dateFrom,
          dateTo: params.dateTo,
        },
        size: 'sm',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },

    openAppealEditorModal({
      initAppealData,
      initPatientData,
      initDoctorData,
      panelTabId,
      afterSaveFunc,
    } = {}) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: initAppealData ? `Обращение ${initAppealData.id}` : 'Обращение',
            alignLeft: true,
            closeButton: SHOW_CLOSE_BUTTON,
            minimizeButton: true,
            minimizeFunc: () => Bus.$emit('appeal:save-to-tab'),
          },
        },
        component: AppealEditorModal,
        props: {
          initPatientData,
          initAppealData,
          panelTabId,
          initDoctorData,
          afterSaveFunc,
        },
        size: 'sm',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },

    openAppealsExportModal() {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Экспорт обращений',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: AppealsExportModal,
        props: {},
        size: 'xs',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },

    openConfigurationTemplateAddModal() {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Создание шаблона клиники',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        // component: AppealsExportModal,
        component: ConfigurationTemplateAddModal,
        props: {},
        size: 'xs',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
    openReferralDutyConsultationImportModal({ params }) {
      // eslint-disable-next-line no-unreachable
      this.$vuedals.open({
        name: 'vertical-center',
        header: {
          component: BaseModalHeader,
          props: {
            title: 'Импорт файла',
            closeButton: SHOW_CLOSE_BUTTON,
          },
        },
        component: ReferralDutyConsultationImportModal,
        props: {
          params,
        },
        size: 'xs',
        closeOnBackdrop: CLOSE_ON_BACKDROP,
      });
    },
  },
};
