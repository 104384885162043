<template>
  <div :class="['search-panel', variant]">
    <div class="search-panel-inner mb-2">
      <base-input
        v-model="query"
        :input-style="{
          padding: '12.7px 30px',
          boxShadow: '0px 1px 2px #C4C4C4',
        }"
        :variant="variant"
        :placeholder="placeholder"
        fluid
        @keyup.native.enter="$emit('search')"
      >
        <template #suffix>
          <slot name="inline-button" />
          <div class="search-buttons">
            <icon-search
              class="mr-3"
              @click="$emit('search')"
            />

            <slot name="filter" />
          </div>
        </template>
      </base-input>

      <slot name="button" />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import {
  BaseInput,
} from '@/components/base';

import IconSearch from 'assets/images/search.svg';

import { mixinRoles } from '@/mixins';

export default {
  name: 'SearchPanel',
  components: {
    BaseInput,
    IconSearch,
  },
  mixins: [mixinRoles],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: '',
    },
  },
  data() { return {}; },
  computed: {
    query: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.search-panel {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 40px;

  &.rm {
    margin-bottom: 0;
    input {
      height: 32px !important;
    }
    .search-panel-inner  {
      margin-bottom: 0;
    }

  }
}

.search-panel-inner {
  display: flex;
  align-items: center;
  width: 100%;
}

::v-deep.search-buttons {
  display: flex;
  height: 20px;

  svg:hover {
    cursor: pointer;
    path {
      fill: $blue;
    }
  }
}
</style>
