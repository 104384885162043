<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    :title="specializationId ? 'Редактирование специализации' : 'Добавление специализации'"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <loading
      v-if="isLoading"
      class="crm-loading-wrapper"
    >
      Загрузка специализации
    </loading>

    <div
      v-else
      class="specialization-form"
    >
      <div
        v-if="specializationId"
        class="specialization-id"
      >
        ID: {{ specializationId }}
      </div>
      <div class="crm-form-field">
        <b-form-checkbox
          v-model="specialization.isHidden"
          class="ml-1"
          switch
          size="sm"
        >
          Скрыть специализацию на сайте
        </b-form-checkbox>
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.title.$model"
          :error="$v.specialization.title.$error"
          :errors="errorsValidation.title"
          placeholder="Введите Название специализации"
          label="Название специализации"
          fluid
          material
          required
          @blur="nameBlurHandle"
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.slug.$model"
          :error="$v.specialization.slug.$error"
          :errors="errorsValidation.slug"
          placeholder="Введите Slug - URL страницы"
          label="Название Slug - URL страницы"
          fluid
          material
          required
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model="specialization.weight"
          placeholder="Введите приоритет"
          label="Приоритет специализации (приоритет выше у большего значения)"
          fluid
          material
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.seoTitle.$model"
          :error="$v.specialization.seoTitle.$error"
          :errors="errorsValidation.seoTitle"
          placeholder="Введите Название страницы"
          label="Название страницы"
          fluid
          material
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.h1.$model"
          :error="$v.specialization.h1.$error"
          :errors="errorsValidation.h1"
          placeholder="Введите Заголовок"
          label="Заголовок"
          fluid
          material
          required
        />
      </div>
      <div class="crm-form-field">
        <base-input
          v-model.trim="$v.specialization.description.$model"
          :error="$v.specialization.description.$error"
          :errors="errorsValidation.description"
          :input-style="{
            padding: '15px 10px',
            resize: 'none',
            height: '130px',
          }"
          placeholder="Введите Описание"
          label="Описание"
          type="textarea"
          fluid
          material
        />
      </div>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :disabled="isLoading || isSaving"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
        <b-spinner
          v-if="isSaving"
          variant="light"
          small
        />
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import { showMessage } from '@/helpers/utils';
import { required } from 'vuelidate/lib/validators';
import Loading from '@/components/Loading';
import CyrillicToTranslit from 'cyrillic-to-translit-js';

import { BaseInput } from '@/components/base';

export default {
  name: 'SpecializationEditModal',
  components: {
    BaseInput,
    Loading,
  },
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
    specializationId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isOpen: true,
      specialization: {
        title: null,
        description: null,
        slug: null,
        isHidden: false,
        weight: 0,
        seoTitle: null,
        h1: null,
      },
    };
  },
  validations() {
    return {
      specialization: {
        title: {
          required,
        },
        description: {},
        slug: {
          required,
        },
        seoTitle: {},
        h1: {
          required,
        },
      },
    };
  },
  computed: {
    formattedSpecializationWeight() {
      return this.specialization.weight === '' ? 0 : this.specialization.weight;
    },
    errorsValidation() {
      const errors = {};

      errors.title = [];
      if (!this.$v.specialization.title.required) {
        errors.title.push('Поле не может быть пустым');
      }

      errors.description = [];
      if (!this.$v.specialization.description.required) {
        errors.description.push('Поле не может быть пустым');
      }

      errors.slug = [];
      if (!this.$v.specialization.slug.required) {
        errors.slug.push('Поле не может быть пустым');
      }

      errors.seoTitle = [];
      if (!this.$v.specialization.seoTitle.required) {
        errors.seoTitle.push('Поле не может быть пустым');
      }

      errors.h1 = [];
      if (!this.$v.specialization.h1.required) {
        errors.h1.push('Поле не может быть пустым');
      }

      return errors;
    },
  },
  async created() {
    if (this.specializationId) {
      try {
        this.isLoading = true;

        const specialization = await this.$store.dispatch(this.$types.SPECIALIZATION_FETCH_BY_ID, this.specializationId);

        this.specialization = {
          ...specialization,
        };
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    }
  },
  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onClickSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      this.isSaving = true;

      this.specialization.weight = this.formattedSpecializationWeight;

      try {
        if (this.specializationId) {
          await this.$store.dispatch(this.$types.SPECIALIZATION_UPDATE, this.specialization);
        } else {
          await this.$store.dispatch(this.$types.SPECIALIZATION_CREATE, this.specialization);
        }

        Bus.$emit('specializations:update');
        this.onClose();
      } finally {
        this.isSaving = false;
      }
    },
    nameBlurHandle() {
      const cyrillicToTranslit = new CyrillicToTranslit();
      const translit = cyrillicToTranslit.transform(this.specialization.title, '-').toLowerCase();
      this.specialization.slug = translit;
      this.specialization.h1 = this.specialization.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.specialization-id {
  font-size: 14px;
  line-height: 14px;
  color: #908F8D;
  margin-bottom: 15px;
}
.specialization-form {
  width: 100%;

  .crm-form-field + .crm-form-field {
    margin-top: 15px;
  }
}
.crm-wrapper-buttons {
  margin-top: 50px;
}

::v-deep .validation-errors {
  top: calc(100% + -1.5px);
}
::placeholder {
  font-size: 0.8em;
}
</style>
