<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="`${messageItem.id ? 'Изменение' : 'Создание'} информационного сообщения`"
    :hide-footer="isSaving"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isSaving">
      <preloader style="margin-top: 20px" />
    </template>
    <template v-else>
      <b-form-group invalid-feedback="Поле является обязательным" :state="!$v.title.$error">
        <div class="d-flex flex-column mb-2">
          <label
            class="col-form-label required"
            v-text="'Заголовок'"
          />
        </div>
        <b-form-input
          v-model="$v.title.$model"
          placeholder="Введите заголовок"
          class="form-control"
          required
        />
      </b-form-group>
      <b-form-group
        invalid-feedback="Поле является обязательным"
        :state="!$v.message.$error"
      >
        <div class="d-flex flex-column mb-2">
          <label
            class="col-form-label required"
            v-text="'Текст сообщения'"
          />
          <b-form-textarea
            v-model="$v.message.$model"
            placeholder="Введите сообщение"
            rows="4"
            required
          />
          <small v-text="`Осталось символов ${availableCharacters}/${maxMessageLength}`" />
        </div>
      </b-form-group>
      <b-form-group
        label="Ссылка"
        :state="!$v.link.$error"
        invalid-feedback="Не введена ссылка и/или заголовок ссылки"
      >
        <b-form-input
          v-model="$v.link.$model"
          placeholder="Ссылка"
          class="form-control"
        />
      </b-form-group>
      <b-form-group
        label="Заголовок ссылки"
        :state="!$v.linkTitle.$error"
        invalid-feedback="Не введен заголовок ссылки и/или ссылка"
      >
        <b-form-input
          v-model="$v.linkTitle.$model"
          placeholder="Введите заголовок ссылки"
          class="form-control"
        />
      </b-form-group>
      <b-form-group
        label="Регион"
        label-class="required"
        :state="!$v.regionId.$error"
        invalid-feedback="Выберите регион"
      >
        <b-form-select
          v-model="$v.regionId.$model"
          :options="regions"
          text-field="name"
          value-field="id"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
              v-text="'Выберите регион'"
            />
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group
        label="Тип сообщения"
        label-class="required"
        :state="!$v.typeId.$error"
        invalid-feedback="Выберите тип"
      >
        <b-form-select
          v-model="$v.typeId.$model"
          :options="infoMessageTypes"
          text-field="name"
          value-field="id"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
              v-text="'Выберите тип'"
            />
          </template>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Показывать">
        <b-form-select
          v-model="$v.isActive.$model"
          :options="messageStates"
          text-field="name"
          value-field="isActive"
        />
      </b-form-group>
      <div class="mb-3 d-flex justify-content-between">
        <div class="w-50 required">
          <div class="mb-2 font-weight-normal">
            Дата начала
          </div>
          <base-date-picker v-model="$v.startDate.$model" />
        </div>
        <div class="w-50 ml-3">
          <div class="mb-2 font-weight-normal">
            Дата окончания
          </div>
          <base-date-picker v-model="endDate" />
        </div>
      </div>
    </template>
    <template #modal-footer>
      <b-button
        v-if="messageItem.id"
        variant="success"
        class="float-right"
        @click="onClickEdit"
        v-text="'Изменить сообщение'"
      />
      <b-button
        v-else
        variant="success"
        class="float-right"
        @click="onClickSave"
        v-text="'Создать сообщение'"
      />
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { showMessage } from '@/helpers/utils';
import { BaseDatePicker } from '@/components/base';
import Preloader from '@/components/Preloader';

export default {
  name: 'InfoMessageCreateModal',
  components: {
    Preloader,
    BaseDatePicker,
  },
  mixins: [validationMixin],
  props: {
    regions: {
      type: Array,
      default: () => ([]),
    },
    messageItem: {
      type: Object,
      default: () => ({
        id: null,
      }),
    },
  },
  data() {
    return {
      title: '',
      message: '',
      link: '',
      linkTitle: '',
      regionId: null,
      typeId: null,
      isActive: false,
      isOpen: true,
      isSaving: false,
      startDate: null,
      endDate: null,
      maxMessageLength: 1000,
      infoMessageTypes: [
        { id: 0, name: 'Критичное', type: 'Danger' },
        { id: 1, name: 'Требует внимания', type: 'Warning' },
        { id: 2, name: 'Информационное', type: 'Info' },
      ],
      messageStates: [
        { isActive: false, name: 'Нет' },
        { isActive: true, name: 'Да' },
      ],
      newInfoMessage: {
        id: null,
      },
      editedInfoMessage: {
        id: null,
      },
    };
  },
  validations() {
    return {
      title: {
        required,
        valid: (title) => title.length <= 255,
      },
      message: {
        required,
        valid: (message) => message.length <= 1000,
      },
      link: {
        valid: (link) => (link.length <= 2083 && link.length > 0 && this.linkTitle.length > 0) || this.isLinkDataEmpty,
      },
      linkTitle: {
        valid: (linkTitle) => (linkTitle.length <= 255 && linkTitle.length > 0 && this.link.length > 0) || this.isLinkDataEmpty,
      },
      regionId: {
        required,
        valid: (regionId) => regionId !== null,
      },
      typeId: {
        required,
        valid: (typeId) => typeId !== null,
      },
      isActive: {
        valid: (isActive) => [true, false].includes(isActive),
      },
      startDate: {
        valid: (startDate) => startDate !== null,
      },
    };
  },
  computed: {
    availableCharacters() {
      return this.maxMessageLength - this.message.length;
    },
    isLinkDataEmpty() {
      return this.link.length === 0 && this.linkTitle.length === 0;
    },
  },
  mounted() {
    if (this.messageItem.id) {
      const {
        title, message, link, linkTitle, region, type, isActive,
      } = this.messageItem;

      this.title = title;
      this.message = message;
      this.link = link;
      this.linkTitle = linkTitle;
      this.regionId = region.id;
      this.typeId = type;
      this.isActive = isActive;
    }
  },
  methods: {
    onClose(modalName = 'InfoMessageCreateModal') {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName });
    },
    async onClickSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      this.isSaving = true;

      try {
        // const now = new Date();
        this.newInfoMessage = await this.$store.dispatch(this.$types.INFO_MESSAGES_CREATE, {
          title: this.title,
          message: this.message,
          link: this.link,
          linkTitle: this.linkTitle,
          regionId: this.regionId,
          type: this.infoMessageTypes.find((t) => t.id === this.typeId)?.type,
          isActive: this.isActive,
          // startTime: new Date(),
          // endTime: new Date(now.setFullYear(now.getFullYear() + 10)),
          startTime: this.startDate,
          endTime: this.endDate,
        });
        if (this.newInfoMessage.id) {
          await this.$store.dispatch(this.$types.INFO_MESSAGES_FETCH);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaving = false;
        this.onClose('reset');
      }
    },
    async onClickEdit() {
      this.$v.$touch();

      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      this.isSaving = true;

      try {
        this.editedInfoMessage = await this.$store.dispatch(this.$types.INFO_MESSAGES_EDIT, {
          id: this.messageItem.id,
          data: {
            title: this.title,
            message: this.message,
            link: this.link,
            linkTitle: this.linkTitle,
            regionId: this.regionId,
            type: this.infoMessageTypes.find((t) => t.id === this.typeId)?.type,
            isActive: this.isActive,
            // startTime: this.messageItem.startTimeUtc,
            // endTime: this.messageItem.endTimeUtc,
            startTime: this.startDate,
            endTime: this.endDate,
            // startTime: this.startDate,
            // endTime: this.endDate,
          },
        });

        if (this.editedInfoMessage.id) {
          await this.$store.dispatch(this.$types.INFO_MESSAGES_FETCH);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isSaving = false;
        this.onClose('reset');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.table-cell-overflow {
  max-width: 1px;
}
</style>
