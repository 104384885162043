<template>
  <div>
    <b-container fluid="xl">
      <div
        style="min-height: 270px;"
        class="doctor-list-inner"
      >
        <slot />
        <div class="d-flex justify-content-between mb-2">
          <b-input-group class="bg-white rounded shadow-light">
            <b-form-input
              v-model="query"
              type="search"
              placeholder="Введите ФИО врача"
              class="border-0"
              @keyup.enter="doctorsFetch"
            />
            <b-button
              variant="outline-primary"
              class="rounded-0 border-0"
              @click="doctorsFetch"
            >
              <b-icon icon="search" />
            </b-button>
          </b-input-group>
          <slot name="button" />
        </div>

        <div class="d-flex mt-3">
          <div class="w-25">
            <div>
              <div class="mb-1">
                Специальность
              </div>
              <v-select
                v-model="form.doctorSpecializationId"
                :reduce="item => item.id"
                :options="specializations"
                placeholder="Выберите специальность"
                label="name"
                class="crm-select"
              >
                <template #no-options>
                  Ничего не найдено
                </template>
              </v-select>
            </div>
          </div>
          <div class="w-25 mx-2">
            <div>
              <div class="mb-1">
                Регион
              </div>
              <v-select
                v-model="form.regionId"
                :reduce="item => item.id"
                :options="regions"
                placeholder="Выберите регион"
                label="name"
                class="crm-select"
              >
                <template #no-options>
                  Ничего не найдено
                </template>
              </v-select>
            </div>
          </div>
          <div class="w-25 mx-2">
            <div>
              <div class="mb-1">
                Тип прикрепления
              </div>

              <!-- :disabled="checkRoleAccess_(['Dis.monitoring.operator'])" -->
              <v-select
                v-model="form.medicalInsuranceType"
                :reduce="item => item.id"
                :searchable="false"
                :options="medicalInsuranceTypes"
                placeholder="Тип прикрепления"
                :disabled="!checkFeatureAccess({ name: 'Поиск. Тип прикрепления', url: '/doctors' })"
                label="name"
                class="crm-select"
              >
                <template #no-options>
                  Ничего не найдено
                </template>
              </v-select>
            </div>
          </div>
          <div class="w-25">
            <div>
              <div class="mb-1">
                ID врача
              </div>

              <b-form-input
                v-model.trim="form.id"
                size="sm"
                placeholder="Введите id врача"
              />
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end my-3">
          <!--          <b-form-checkbox-->
          <!--            v-model="isDeleted"-->
          <!--            size="sm"-->
          <!--          >-->
          <!--            <span class="text-secondary">-->
          <!--              Искать в удаленных-->
          <!--            </span>-->
          <!--          </b-form-checkbox>-->

          <b-button
            variant="outline-primary"
            class="ml-auto border-0"
            size="sm"
            @click="clearFilters"
          >
            Очистить фильтр
          </b-button>

          <b-button
            :disabled="isLoading"
            variant="primary"
            size="sm"
            class="ml-2"
            @click="doctorsFetch(true)"
          >
            Поиск с фильтром
          </b-button>
        </div>

        <!--        <div-->
        <!--          v-if="isLoading"-->
        <!--          class="text-align-center mb-4"-->
        <!--        >-->
        <!--          <preloader />-->
        <!--        </div>-->
        <div
          v-if="nothingFound"
          class="crm-container"
        >
          Ничего не найдено
        </div>

        <slot
          name="doctors"
          :doctors="doctors"
        />
      </div>
    </b-container>
  </div>
</template>

<script>

import debounce from 'lodash/debounce';
// import Preloader from '@/components/Preloader';
import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';

const LIMIT = 25;
const FETCH_DELAY = 700;

export default {
  name: 'DoctorSearch',
  components: {
    // Preloader,
  },
  mixins: [mixinRoles],
  props: {
    modalMode: {
      type: Boolean,
      default: false,
    },
    searchTrigger: {
      type: Boolean,
      default: false,
    },
    onlyActiveDoctors: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // query: '', // still empty
      // isDeleted: false,
      limit: LIMIT,
      linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      form: {
        surname: '',
        regionId: '',
        medicalInsuranceType: '',
        doctorSpecializationId: '',
        id: '',
      },
      isCheckDeleted: false,
      medicalInsuranceTypes: [
        {
          id: 0,
          name: 'ДМС',
        },
        {
          id: 1,
          name: 'ОМС',
        },
        {
          id: 2,
          name: 'ДМС и ОМС',
        },
      ],
      specializations: [],
      regions: [],
      requestMade: false, // становится true после того как произведен запрос и больше не меняется
    };
  },
  computed: {
    doctors() {
      return this.$store.state.Doctor.doctors;
    },
    doctorsDeleted() {
      return this.doctors.filter((item) => item.isDeleted);
    },
    totalCount() {
      return this.doctors.length;
    },
    totalCountDeleted() {
      return this.doctorsDeleted.length;
    },
    query: {
      get() {
        return this.$store.state.Doctor.query;
      },
      set(newVal) {
        this.$store.state.Doctor.query = newVal;
      },
    },
    isDeleted: {
      get() {
        return this.$store.state.Doctor.isDeleted;
      },
      set(newVal) {
        this.$store.state.Doctor.isDeleted = newVal;
      },
    },
    isAllItemsLoaded: {
      get() {
        return this.$store.state.Doctor.isAllItemsLoaded;
      },
      set(newVal) {
        this.$store.state.Doctor.isAllItemsLoaded = newVal;
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Doctor.isLoading;
      },
      set(newVal) {
        this.$store.state.Doctor.isLoading = newVal;
      },
    },
    filtersNotEmpty() {
      const formValues = Object.values(this.form);

      return formValues.some((item) => item || item === 0);
    },
    nothingFound() {
      return this.requestMade
        && !this.totalCount
        && this.isAllItemsLoaded
        && this.filtersNotEmpty;
    },
  },
  watch: {
    query() {
      this.doctorsFetchDebounced(true);
    },
    async searchTrigger() {
      await this.doctorsFetch(false);
    },
  },
  async created() {
    // this.checkRoleAccess_(['Dis.monitoring.operator'])
    if (!this.checkFeatureAccess({ name: 'Поиск. Тип прикрепления', url: '/doctors' })) this.form.medicalInsuranceType = 1;

    Bus.$on('doctors:update', this.updateDoctors);
    this.doctorsFetchDebounced = debounce(this.doctorsFetch, FETCH_DELAY);

    this.specializations = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, '');
    this.regions = await this.$store.dispatch(this.$types.REGIONS_FETCH);
  },
  beforeDestroy() {
    Bus.$off('doctors:update', this.updateDoctors);
    this.$store.commit(this.$types.DOCTORS_SET, []);
  },
  methods: {
    async doctorsFetch(clear) {
      if (this.isLoading) return;

      if (clear) this.isCheckDeleted = false;

      this.requestMade = true;
      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;
      const prevTotalDeleted = clear ? 0 : this.totalCountDeleted;
      const prevQuery = this.query;

      const requestObject = {
        skip: prevTotal,
        take: this.limit,
        query: prevQuery,
        clear: !!clear,
        isDeleted: false,
        save: true,
        medicalInsuranceType: this.form.medicalInsuranceType,
        doctorSpecializationId: this.form.doctorSpecializationId,
        regionId: this.form.regionId,
        id: this.form.id,
      };

      const requestObjectDeleted = {
        ...requestObject,
        skip: prevTotalDeleted,
        isDeleted: true,
        save: true,
        clear: false,
      };

      try {
        if (!this.isCheckDeleted) await this.$store.dispatch(this.$types.DOCTORS_FETCH, requestObject);

        if (prevTotal + this.limit > this.totalCount) {
          this.isCheckDeleted = true;

          if (this.onlyActiveDoctors) {
            this.isAllItemsLoaded = true;
            return;
          }
        }

        if (this.isCheckDeleted) {
          await this.$store.dispatch(this.$types.DOCTORS_FETCH, requestObjectDeleted);

          if (prevTotalDeleted + this.limit > this.totalCountDeleted) {
            this.isAllItemsLoaded = true;
          }
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;

        if (this.query !== prevQuery) this.doctorsFetch(true);
      }
    },
    async clearFilters() {
      const formKeys = Object.keys(this.form);
      formKeys.forEach((key) => { this.form[key] = ''; });

      // this.checkRoleAccess_(['Dis.monitoring.operator'])
      if (!this.checkFeatureAccess({ name: 'Поиск. Тип прикрепления', url: '/doctors' })) this.form.medicalInsuranceType = 1;

      await this.doctorsFetch(true);
    },
    loadMoreItems() {
      this.doctorsFetch(false);
    },
    updateDoctors(id) {
      if (this.doctors.find((doctor) => doctor.id === id)) {
        this.doctorsFetch(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-select {
  .vs__dropdown-toggle {
    background: $white;
    height: 31px;
  }

  .vs__search {
    background: $white;
  }
}

.doctor-list-inner {
  padding: 30px 0;
}

.shadow-light {
  // max-width: 80%;
  //margin-right: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
</style>
