<template>
  <b-nav-item
    v-if="childrenLinks.length === 0"
    :to="link.url"
    :disabled="link.url === '#'"
    exact
    exact-active-class="text-primary"
    class="text-secondary"
  >
    {{ link.name }}
  </b-nav-item>

  <div v-else>
    <div
      class="d-flex align-items-center justify-content-between"
      @click="() => isDropdownOpen = !isDropdownOpen"
    >
      <b-nav-item
        :to="link.url"
        :disabled="link.url === '#'"
        exact
        exact-active-class="text-primary"
        class="text-secondary"
      >
        {{ link.name }}
      </b-nav-item>

      <b-icon
        :icon="isDropdownOpen ? 'folder2-open' :'folder'"
        class="text-secondary mr-2"
        style="font-size: 1.2rem;"
      />
    </div>

    <transition
      name="fade"
    >
      <div
        v-if="isDropdownOpen"
        class="pl-3"
      >
        <navbar-item
          v-for="childrenLink in childrenLinks"
          :key="childrenLink.id"
          :link="childrenLink"
        />
      </div>
    </transition>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-self-import
import NavbarItem from '@/components/NavbarItem';

export default {
  name: 'NavbarItem',
  components: {
    NavbarItem,
  },
  props: {
    link: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    childrenLinks() {
      // const { pages } = this.$store.state.Auth.user.roles[0];
      // const links = pages.filter((p) => p.isMenuItem && p.pageId === this.link.id);
      // return links.sort((a, b) => a.index - b.index);

      return this.link.pages.filter((p) => p.isMenuItem);
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
