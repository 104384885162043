<template>
  <button
    :class="[type, { loading, transparent, 'without-border': withoutBorder, 'content-width': contentWidth }]"
    :disabled="disabled || loading"
    class="crm-button"
    @click="onClick"
  >
    <div
      v-if="loading"
      class="crm-preloader"
    />

    <template v-else>
      <slot />
    </template>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default() { return this.$const.PRIMARY_BUTTON; },
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    withoutBorder: {
      type: Boolean,
      default: false,
    },
    contentWidth: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.crm-button {
  @include default-flex-prop;

  outline: none;
  border-radius: 20px;
  width: 170px;
  padding: 10px 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  //&.transparent {
  //  background-color: transparent !important;
  //}
  &.without-border {
    border: none !important;
  }
  &.content-width {
    padding: 10px 20px;
    width: fit-content;
  }

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    opacity: 0.45;
    cursor: not-allowed;

    &.loading {
      cursor: wait;
    }
  }
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.primary {
  color: $white;
  background-color: $blue;
  border: 1px solid $blue;

  .crm-preloader {
    border-color: $white;
    border-top-color: $blue;
  }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.warning {
  color: $white;
  background-color: $red;
  border: 1px solid $red;

  .crm-preloader {
    border-color: $white;
    border-top-color: $red;
  }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.warning-secondary {
  color: $red;
  background-color: $white;
  border: 1px solid $red;

  .crm-preloader {
    border-color: $white;
    border-top-color: $red;
  }
}
// eslint-disable-next-line vue-scoped-css/no-unused-selector
.secondary {
  color: $blue;
  background-color: $white;
  border: 1px solid $blue;

  .crm-preloader {
    border-color: $blue;
    border-top-color: $white;
  }
}
.crm-preloader {
  border: 2px solid;
  border-top: 2px solid;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 1s linear infinite;
}

.monitoring {
  background: #347AFB;
  border-radius: 6px;
  color: #fff;
  border: none;
  width: 140px;
  height: 32px;
  font-weight: 500;
font-size: 16px;
line-height: 120%;
}
</style>
