import { APP_HOST } from '@/app.config';
import AxiosClientService from '@/services/core/axios/axios';

class StoriesApi {
  constructor() {
    this.api = AxiosClientService.instance;
  }

  async getStoriesList(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/story/list`, params);

    return data;
  }

  async deleteStory(id) {
    await this.api.post(`${APP_HOST}v3/story/delete`, { storyId: id });
  }

  async activateStory(id) {
    await this.api.post(`${APP_HOST}v3/story/activate`, { storyId: id });
  }

  async deactivateStory(id) {
    await this.api.post(`${APP_HOST}v3/story/deactivate`, { storyId: id });
  }

  async getDictionaryEvent() {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/dictionary/event`);
    return data;
  }

  async getStoryInfo(params) {
    const { data } = await this.api.get(`${APP_HOST}v3/story/info`, { params });

    return data;
  }

  async storyCreate(params) {
    const result = await this.api.post(`${APP_HOST}v3/story/create`, params);

    return result;
  }

  async storyUpdate(params) {
    const result = await this.api.post(`${APP_HOST}v3/story/update`, params);

    return result;
  }

  async storyFileUpload(file) {
    const { data: { data } } = await this.api.post(`${APP_HOST}v3/story/file/upload`, file);
    return data.fileName;
  }

  async getStoryImage(fileName) {
    const { data } = await this.api.get(`${APP_HOST}v3/story/file/download?fileName=${fileName}`, { responseType: 'blob' });

    return URL.createObjectURL(data);
  }

  async getMkbList(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/mkb/list`, { params });
    return data;
  }
}
const storiesApi = new StoriesApi();
export default storiesApi;
