<template>
  <div
    class="knowledge-content"
  >
    <custom-scrollbar>
      <div class="knowledge-content-inner">
        <div class="crm-container">
          <div class="title">
            <router-link to="/knowledge-base">
              <IconBack />
            </router-link>
            <!--            {{ this.$store.state.KnowledgeBase.sectionType.name[0] }}-->
            {{ knowledgeBaseName }}
          </div>
          <div class="knowledge-list-wrapper">
            <knowledge-base-documents />
            <div class="knowledge-documents-input-wrapper">
              <div class="upload-button">
                <icon-cross
                  class="plus-icon"
                  @click="onClickOpenFileModal"
                />
              </div>
              <div>
                <base-input
                  v-model="$v.documentData.name.$model"
                  class="crm-form-field crm-form-field--new-placeholder"
                  :input-style="{
                    border: 'none',
                  }"
                  :error="$v.documentData.name.$error"
                  :errors="errorsValidation.name"
                  :disabled="disabledField.name"
                  placeholder="Введите наименование"
                />
                <div
                  v-if="$v.documentData.name.$error"
                  class="crm-error-text"
                >
                  {{ printError(errorsValidation.name) }}
                </div>
              </div>
              <div>
                <base-input
                  v-model="$v.documentData.description.$model"
                  class="crm-form-field crm-form-field--new-placeholder"
                  :input-style="{
                    border: 'none',
                  }"
                  :error="$v.documentData.description.$error"
                  :errors="errorsValidation.description"
                  :disabled="disabledField.description"
                  placeholder="Введите описание"
                />
                <div
                  v-if="$v.documentData.description.$error"
                  class="crm-error-text"
                >
                  {{ printError(errorsValidation.description) }}
                </div>
              </div>
            </div>
            <button
              class="knowledge-base-document-download-button"
              @click="createKnowledgeDocument(documentData.name, documentData.description)"
            >
              Добавить запись
            </button>
          </div>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { KNOWLEDGE_BASE_SECTIONS } from '@/helpers/consts';
import { showMessage } from '@/helpers/utils';
import Bus from '@/eventBus';

import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import {
  BaseInput,
} from '@/components/base';
import IconCross from 'assets/images/cross.svg';

import IconBack from 'assets/images/knowledge-base-back.svg';
import KnowledgeBaseDocuments from './KnowledgeBaseDocuments';

export default {
  name: 'KnowledgeBaseSectionPage',
  components: {
    KnowledgeBaseDocuments,
    IconBack,
    BaseInput,
    IconCross,
  },
  mixins: [validationMixin],
  props: {
    sectionName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      documentData: {
        name: '',
        description: '',
        selectedFile: null,
        fileid: [],
      },
      fileId: [],
      sectionTypes: KNOWLEDGE_BASE_SECTIONS,
    };
  },
  validations() {
    return {
      documentData: {
        name: { required },
        description: { required },
      },
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.name = [];
      if (!this.$v.documentData.name.required) {
        errors.name.push('Введите наименование');
      }

      errors.description = [];
      if (!this.$v.documentData.description.required) {
        errors.description.push('Введите описание');
      }
      return errors;
    },
    disabledField() {
      if (!this.isEditMode) {
        return {};
      }

      return {
        name: true,
        description: false,
      };
    },
    section() {
      return this.sectionTypes.filter((s) => s.link === this.sectionName)[0];
    },
    knowledgeBaseName() {
      if (this.section.name === 1) return this.section.name[0];
      let longName = '';
      for (let i = 0; i < this.section.name.length; i += 1) {
        if (i !== this.section.name.length - 1) longName += `${this.section.name[i]} и `;
        else longName += `${this.section.name[i]}`;
      }
      return longName;
    },
  },
  mounted() {
    this.$store.dispatch(this.$types.GET_KNOWLEDGE_DOCUMENTS, this.section.type);
  },
  beforeDestroy() {
    this.$store.commit(this.$types.KNOWLEDGE_DOCUMENTS_SET, []);
    this.checkInputs();
  },
  methods: {
    checkInputs() {
      if (this.documentData.name.length > 0 && this.documentData.description.length > 0) {
        this.$store.dispatch(this.$types.KNOWLEDGE_DOCUMENT_CREATE, {
          name: this.documentData.name,
          description: this.documentData.description,
          type: this.section.type,
          fileIds: this.fileId,
        });
      }
    },
    saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.documentData.fileid = newFile.guid;
      this.fileId.push(newFile.guid);
      // this.$v.documentData.fileId.$model = newFile.guid;
      // this.$v.documentData.name.$model = newFile.name;
    },
    printError(errArray) {
      return errArray ? errArray.toString() : null;
    },
    onClickOpenFileModal() {
      Bus.$emit('open-modal:file-upload',
        {
          saveFileCallback: this.saveFileCallback,
          multiple: false,
          fileApi: 'File/uploadKnowledgeDocument',
        });
    },
    createKnowledgeDocument(name, desc) {
      this.$v.$touch();
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
      } else {
        console.log(this.fileId);
        this.$store.dispatch(this.$types.KNOWLEDGE_DOCUMENT_CREATE, {
          name,
          description: desc,
          type: this.section.type,
          fileIds: this.fileId,
        }).then(() => {
          this.$store.dispatch(this.$types.GET_KNOWLEDGE_DOCUMENTS, this.section.type);
          this.documentData.name = '';
          this.documentData.description = '';
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.crm-form-field--new-placeholder{
  .crm-base-input::placeholder{
    color: #C7C7C7;
  }
}

::v-deep .plus-icon{
  cursor: pointer;
  rect {
    stroke: #6E88F3;
  }
  path {
    fill: #4B62C1;
  }
  transform: scale(1.499);
  padding-left: 7px;
}

.knowledge-content {
  height: 100vh;
  transition: height 0.3s linear;
}

.knowledge-content-inner {
  padding: 30px 0px;
}

.crm-container {
  width: 100%;
  flex-direction: column;
}

.title {
  width: 100%;
  margin-bottom: 20px;
  @extend .page-title;
}

.knowledge-list-wrapper {
  background-color: #FFFFFF;
  padding: 30px;
  width: 95%;
  border-radius: 10px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-item {
  display: flex;
  justify-content: space-between;
  background-color: #DDE3FC;
  border-radius: 4px;
  padding: 40px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-item-label {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-item-name {
  & + & {
    margin-top: 12px;
  }

// eslint-disable-next-line vue-scoped-css/no-unused-selector
  a {
    color: #4B62C1;
    text-decoration: none;
  }
}

// eslint-disable-next-line vue-scoped-css/no-unused-selector
.knowledge-item-icon {
  flex-shrink: 0;
  align-self: flex-end;
}

.knowledge-documents-input-wrapper {
  display: grid;
  grid-template-columns: 100px 400px 1fr;
  border-bottom: 0.5px solid #C7C7C7;
  align-items: center;
  height: 55px;
}

.knowledge-base-document-download-button {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #6E88F3;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

.crm-error-text {
  color: $red;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

</style>
