<template>
  <div class="family-policies-search-panel">
    <b-input-group class="mb-2">
      <b-form-input
        v-model="form.policyCode"
        placeholder="Номер полиса"
        @keyup.enter="$emit('search', filteredForm)"
      />
      <b-button
        :disabled="loading"
        variant="primary"
        class="ml-2"
      >
        <b-icon
          icon="search"
          @click="$emit('search', filteredForm)"
        />
      </b-button>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'FamilyPoliciesSearchPanel',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      phone: '',
      policyCode: '',
    },
    filtersKeys: ['phone', 'policyCode'],
    showFCS: false,
  }),
  computed: {
    filteredForm() {
      const newFormData = JSON.parse(JSON.stringify(this.form));

      if (this.showFCS) {
        delete newFormData.searchString;
      } else {
        delete newFormData.lastName;
        delete newFormData.firstName;
        delete newFormData.middleName;
      }

      return newFormData;
    },
  },

  methods: {
    resetFilter() {
      Object.keys(this.form).forEach((item) => {
        if (this.form[item]) this.form[item] = '';
      });
    },
  },
};
</script>

<style scoped>

</style>
