<template>
  <div class="family-polices">
    <template>
      <AsyncList
        :is-all-items-loaded="isAllItemsLoaded"
        class="patient-list"
        @load-more-items="familyPatientsFetch(searchParams)"
      >
        <b-container fluid="xl">
          <div class="py-4">
            <div class="h3 mb-0">
              Семейные полисы
            </div>
          </div>

          <FamilyPoliciesSearchPanel
            :loading="isLoading"
            @search="search"
          />

          <template v-if="patients.length">
            <div
              v-for="patient in patients"
              :key="patient.person.id"
              class="p-2 border rounded mt-4 bg-white"
            >
              <PatientInfo :patient="patient" />

              <div class="mt-1 py-3 border-top">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="font-weight-bold">
                    Дополнительные
                  </p>

                  <b-button
                    variant="primary"
                    @click="addPatient(patient)"
                  >
                    Добавить
                  </b-button>
                </div>

                <div
                  v-if="patient.childrens.length"
                  class="mt-3"
                >
                  <PatientInfo
                    v-for="child in patient.childrens"
                    :key="child.person.id"
                    :patient="child"
                  >
                    <template v-slot:policy-header="{id: mainPolicyId}">
                      <div class="d-flex justify-content-end border-bottom p-1 policy-header">
                        <b-button
                          variant="outline-danger"
                          size="sm"
                          @click="removeFamilyItem(patient, child, mainPolicyId)"
                        >
                          Открепить
                        </b-button>
                      </div>
                    </template>
                  </PatientInfo>
                </div>

                <p v-else>
                  Дополнительные полисы отсутствуют
                </p>
              </div>
            </div>
          </template>
        </b-container>
      </AsyncList>
    </template>
  </div>
</template>

<script>
import FamilyPoliciesSearchPanel from '@/components/FamilyPolicies/FamilyPoliciesSearchPanel';
import AsyncList from '@/components/AsyncList';
import PatientInfo from '@/components/Patients/PatientInfo';
import { api } from '@/helpers/api';
import { showMessage } from '@/helpers/utils';

const LIMIT = 10;
export default {
  name: 'FamilyPolicies',
  components: {
    FamilyPoliciesSearchPanel,
    AsyncList,
    PatientInfo,
  },

  data: () => ({
    isAllItemsLoaded: true,
    isLoading: false,
    searchParams: {},
    limit: LIMIT,
  }),
  computed: {
    patients() {
      return this.$store.state.Patients.patients;
    },
    totalCount() {
      return this.patients.length;
    },
  },

  beforeDestroy() {
    this.$store.commit(this.$types.PATIENTS_SET, []);
  },

  methods: {
    async search(searchParams) {
      this.searchParams = searchParams;
      await this.familyPatientsFetch(searchParams, true);
    },
    async familyPatientsFetch(params, clear = false) {
      if (this.isLoading) return;

      this.isAllItemsLoaded = false;
      this.isLoading = true;

      const prevTotal = clear ? 0 : this.totalCount;

      const requestData = {
        skip: prevTotal,
        take: this.limit,
        ...params,
        save: true,
        clear,
      };

      try {
        await this.$store.dispatch(this.$types.FAMILY_PATIENTS_FETCH, requestData);

        if (prevTotal + this.limit > this.totalCount) {
          this.isAllItemsLoaded = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async requestRemoveFamilyItem(requestData) {
      try {
        const response = await api.post('v2/policy/bind-or-delete-family-person', requestData);

        if (response.status === 200) {
          showMessage({
            type: 'success',
            title: 'Успех!',
          });

          await this.familyPatientsFetch(this.searchParams, true);
        }
      } catch {
        showMessage({
          type: 'warning',
          title: 'Что-то пошло не так',
        });
      }
    },
    removeFamilyItem(patient, child, mainPolicyId) {
      const requestData = {
        mainPolicyId,
        personId: child.person.id,
        mainPersonId: patient.person.id,
        isBind: false,
      };

      this.openAgreeModal(this.requestRemoveFamilyItem, [requestData]);
    },
    openAgreeModal(cb, cbArgs) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb, cbArgs },
        },
      );
    },
    skipIdLIst(patient) {
      return [...patient.childrens.map((childItem) => childItem.person.id), patient.person.id];
    },
    addPatient(patient) {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AttachPatientModal',
          props: {
            skipIdLIst: this.skipIdLIst(patient),
            listAvailablePolicies: patient.policies,
            mainPersonId: patient.person.id,
            cb: this.familyPatientsFetch,
            cbArgs: [this.searchParams, true],
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.family-polices {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.patient-list {
  height: 100%;
  transition: height 0.3s linear;
  box-sizing: border-box;
}

.policy-header {
  position: relative;
  z-index: 2;
}
</style>
