<template>
  <div class="promocode-filter">
    <custom-scrollbar>
      <div
        class="promocode-form"
      >
        <div class="crm-form-fields">
          <div class="crm-form-block">
            <p class="crm-label">
              Фильтр по актуальности
            </p>
            <div class="crm-radio-buttons">
              <base-radio-button
                v-model="filter.isActual"
                :name="true"
                class="crm-radio-button"
              >
                Только актуальные промокоды
              </base-radio-button>

              <base-radio-button
                v-model="filter.isActual"
                :name="false"
                class="crm-radio-button"
              >
                Все промокоды
              </base-radio-button>
            </div>
          </div>
        </div>

        <div class="crm-form-fields">
          <div class="crm-form-block">
            <p class="crm-label">
              Признак промокода
            </p>
            <div class="crm-radio-buttons">
              <base-radio-button
                v-model="filter.attribute"
                :name="0"
                class="crm-radio-button"
              >
                Мать и дитя
              </base-radio-button>

              <base-radio-button
                v-model="filter.attribute"
                :name="1"
                class="crm-radio-button"
              >
                Доктис
              </base-radio-button>

              <base-radio-button
                v-model="filter.attribute"
                :name="2"
                class="crm-radio-button"
              >
                Депозит
              </base-radio-button>

              <base-radio-button
                v-model="filter.attribute"
                :name="null"
                class="crm-radio-button"
              >
                Не указывать признак
              </base-radio-button>
            </div>
          </div>
        </div>

        <div class="crm-wrapper-buttons">
          <!-- <base-button
            :type="$const.SECONDARY_BUTTON"
            class="crm-button"
            @click="onClickClose"
          >
            Отменить
          </base-button> -->

          <base-button
            :type="$const.PRIMARY_BUTTON"
            class="crm-button"
            @click="onClickApply"
          >
            Применить
          </base-button>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import {
  BaseRadioButton,
  BaseButton,
} from '@/components/base';

export default {
  name: 'PromocodeFilterModal',
  components: {
    BaseRadioButton,
    BaseButton,
  },
  data() {
    return {
      filter: {
        isActual: 0,
        attribute: null,
      },
    };
  },
  created() {
    this.filter = { ...this.$store.state.Promocodes.filter };
  },
  methods: {
    onClickClose() {
      this.$emit('vuedals:close');
    },
    onClickApply() {
      this.$store.commit(this.$types.PROMOCODES_FILTER_SET, this.filter);
      this.$store.dispatch(this.$types.PROMOCODES_FETCH, true);
      this.$emit('vuedals:close');
    },
  },
};
</script>

<style lang="scss" scoped>
.promocode-form {
  flex-direction: column;
}

.promocode-filter {
  margin-top: 30px;
  max-height: calc(100vh - 152px);
}

.crm-form-fields {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.crm-radio-buttons {
  position: relative;
  display: flex;

  .crm-radio-button {
    &:not(:first-child) {
      margin-left: 35px;
    }
  }
}
.crm-wrapper-buttons {
  margin-top: 50px;
}
</style>
