import * as types from '@/store/types';
import { removeNamespaces } from '@/helpers/utils';

const localTypes = removeNamespaces(types);

export default {
  [localTypes.APPEAL_RESPONSIBLES_ADD](state, payload) {
    state.responsibles = payload;
  },
  [localTypes.APPEAL_INFORMATION_TYPES_ADD](state, payload) {
    state.informationTypes = payload;
  },
  [localTypes.APPEAL_TREATMENT_TYPES_ADD](state, payload) {
    state.treatmentTypes = payload;
  },
  [localTypes.APPEAL_GOSTELEMED_TYPES_ADD](state, payload) {
    state.gostelemedTypes = payload;
  },
  [localTypes.APPEAL_CHECKUP_PROFILES_ADD](state, payload) {
    state.checkupProfiles = payload;
  },
  [localTypes.APPEALS_ADD](state, payload) {
    state.appeals = [...state.appeals, ...payload];
  },
  [localTypes.APPEALS_SET](state, payload) {
    state.appeals = payload;
  },
};
