var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"knowledge-base-documents"},[_c('div',[(_vm.documentData.fileIds.length)?_c('icon-document',{staticClass:"document-icon",on:{"click":_vm.downloadDocument}}):_vm._e()],1),(_vm.isEditMode)?_c('base-input',{staticClass:"edit-input-name",attrs:{"type":"textarea","input-style":{
      border: 'none',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      color: '#323232',
      outline: 'none',
      resize: 'none',
      maxHeight: '100px',
    }},model:{value:(_vm.nameEditor),callback:function ($$v) {_vm.nameEditor=$$v},expression:"nameEditor"}}):_c('span',{staticClass:"document-name-text"},[_vm._v(" "+_vm._s(_vm.documentData.name)+" ")]),(_vm.isEditMode)?_c('base-input',{staticClass:"edit-input-desc",attrs:{"input-style":{
      border: 'none',
      color: '#323232',
      fontSize: '16px',
      outline: 'none',
      resize: 'none',
    },"type":"textarea"},model:{value:(_vm.descEditor),callback:function ($$v) {_vm.descEditor=$$v},expression:"descEditor"}}):_c('span',{staticClass:"document-description-text"},[_vm._v(" "+_vm._s(_vm.documentData.description)+" ")]),(_vm.isEditMode)?_c('div',{staticClass:"crm-icon-delete",on:{"click":_vm.removeDocument}},[_c('icon-delete',{on:{"click":_vm.removeDocument}})],1):_vm._e(),_c('div',{staticClass:"edit-btn"},[_c('icon-edit',{staticClass:"edit-icon crm-icon",class:{'edit-document-active': _vm.isEditMode},on:{"click":_vm.openKnowledgeBaseEditor}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }