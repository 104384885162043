<template>
  <div class="import-branches">
    <custom-scrollbar>
      <div class="crm-container">
        <loading
          v-if="isLoading"
          class="mt-4"
        >
          Загрузка
        </loading>

        <template v-else>
          <div
            class="crm-header-block"
          >
            <div class="d-flex mb-4">
              <router-link
                class="mr-4"
                to="/laboratory-branches"
              >
                <ArrowIcon />
              </router-link>
              <h3>
                Импорт отделений
              </h3>
            </div>
            <div class="mt-4 mb-4 d-flex align-items-center">
              <v-select
                v-model="data.partnerId"
                :reduce="partner => partner.id"
                placeholder="Выберите Юр. лицо"
                :options="legalPartners"
                label="legalName"
                class="w-50 ml-2"
                @input="changeParthersValue(data.partnerId)"
              />

              <v-select
                v-model="data.importType"
                :reduce="type => type.id"
                placeholder="Выберите тип импорта"
                label="text"
                :options="importTypeOptions"
                :disabled="!data.partnerId"
                class="w-25 ml-2"
                @input="onImportTypeChange"
              />

              <b-button
                class="ml-auto"
                variant="primary text-nowrap"
                :disabled="!data.partnerId"
                @click="onClickOpenFileModal"
              >
                <b-icon
                  icon="plus"
                  aria-hidden="true"
                />
                Добавить
              </b-button>
            </div>
            <div class="d-flex align-items-center">
              <b-button
                class="ml-auto"
                variant="info"
                :disabled="!data.partnerId"
                @click="onClickOpenImportModal"
              >
                Загрузить
              </b-button>
              <b-button
                class="ml-3"
                variant="outline-secondary"
                :disabled="!data.partnerId"
                @click="onClickSave"
              >
                Скачать
              </b-button>
            </div>
          </div>

          <div
            v-if="!isLoadingFromFile"
            class="branches-table"
          >
            <div class="branches-table-wrapper">
              <div
                ref="table-header"
                class="branches-table-header"
              >
                <div
                  v-for="(title, i) in selectedColumnNames"
                  :key="i"
                  class="branches-table-header-cell"
                >
                  <v-select
                    :value="filteredValueInTable(i)"
                    :append-to-body="true"
                    :reduce="name => name.target"
                    :searchable="false"
                    :clearable="true"
                    :options="filteredAvailableColumnNames"
                    :calculate-position="setMaxHeight"
                    class="select-col-name"
                    label="name"
                    :disabled="!fileGUID"
                    placeholder="Игнорировать"
                    @input="selectColumnName($event, i)"
                  />
                </div>
              </div>
              <div class="branches-table-body">
                <vuescroll
                  :ops="ops"
                  class="vuescroll-custom"
                  @handle-scroll="handleScroll"
                >
                  <div class="branches-table-inner">
                    <div
                      v-for="(row, rowIndex) in tableRows"
                      :key="rowIndex"
                      class="branches-table-row"
                    >
                      <input
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        readonly="readonly"
                        class="branches-table-cell"
                        type="text"
                        :value="cell"
                      >
                    </div>
                  </div>
                </vuescroll>
              </div>
            </div>
            <div
              v-if="tableRows.length < totalRowsInFile"
              class="show-more-btn"
              @click="showAllRows"
            >
              Показать полностью
            </div>
          </div>
        </template>
      </div>
    </custom-scrollbar>
  </div>
</template>
<script>
import Bus from '@/eventBus';
import Loading from '@/components/Loading';
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import { showMessage } from '@/helpers/utils';
import vuescroll from 'vuescroll';
import { api } from '@/helpers/api';

const COLS = 5;
const ROWS = 8;

export default {
  name: 'LaboratoryImportBranches',
  components: {
    Loading,
    ArrowIcon,
    vuescroll,
  },
  data() {
    return {
      isLoading: false,
      isLoadingFromFile: false,
      fileGUID: undefined,
      fileName: undefined,
      fileKeyAfterUpload: null,
      saveFlag: false,
      totalRowsInFile: 0,
      miniDisplayedRows: 10,
      tableRows: [],
      selectedColumnNames: [],
      availableColumnNames: [],
      filteredAvailableColumnNames: [],
      data: {
        partnerId: null,
        importType: null,
      },
      importTypeOptions: [
        {
          id: 0,
          text: 'Добавить',
        },
        {
          id: 1,
          text: 'Обновить',
        },
        {
          id: 2,
          text: 'Удалить',
        },
      ],
      ops: {
        vuescroll: {
          mode: 'native',
        },
        scrollPanel: {
          scrollingX: true,
          scrollingY: true,
        },
        rail: {
          opacity: 0,
        },
        bar: {
          background: '#E0E0E0',
          size: '5px',
          minSize: 0.15,
          keepShow: true,
          onlyShowBarOnScroll: false,
        },
      },
    };
  },
  computed: {
    legalPartners() {
      return this.$store.state.Laboratories.legalPartnerList.data;
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchLegalPartners();
    this.tableRows = this.generateTableRows(COLS, ROWS);
    this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
    this.filteredAvailableColumnNames = this.availableColumnNames;
    this.isLoading = false;
  },
  methods: {
    generateTableRows(col, row) {
      return new Array(row).fill(new Array(col).fill(''));
    },
    generateSelectedColumnNames(quantity, value) {
      return new Array(quantity).fill(value);
    },
    filteredValueInTable(i) {
      return this.availableColumnNames.filter((name) => name.target === this.selectedColumnNames[i])[0];
    },
    async fetchLegalPartners() {
      const data = await this.$store.dispatch(this.$types.GET_LABORATORY_LEGAL_PARTNERS, {});
      this.allCount = data.xTotalCount;
    },
    async getColumnList() {
      this.saveFlag = false;
      const { data: { data } } = await
      api.get(`v3/laboratory/import/branches/columns?legalPartnerId=${this.data.partnerId}
      &importType=${this.data.importType}`);
      this.availableColumnNames = data;
      this.filteredAvailableColumnNames = this.availableColumnNames;
    },
    changeParthersValue(value) {
      if (value === null) {
        this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
      } else {
        this.data.importType = null;
        this.saveFlag = false;
      }
    },
    onImportTypeChange(e) {
      if (e === null) return;
      this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
      this.getColumnList();
    },
    onClickOpenFileModal() {
      if (this.data.importType === null) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо выбрать тип импорта',
        });
        return;
      }

      Bus.$emit('open-modal:file-upload-modern', {
        saveFileCallback: this.saveFileCallback,
        multiple: false,
        fileApi: 'v3/laboratory/import/branches/upload-file',
        type: '.xlsx',
      });
      this.getColumnList();
    },
    async onClickOpenImportModal() {
      this.saveFlag = false;
      const hasFile = this.tableRows.length && this.totalRowsInFile > 0 && !this.isLoadingFromFile;
      if (!hasFile) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо добавить файл',
        });
        return;
      }

      if (this.data.importType === null) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Необходимо выбрать тип импорта',
        });
        return;
      }

      if (this.filteredAvailableColumnNames.length !== 0) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Выбраны не все поля в таблице',
        });
        return;
      }

      const columns = this.selectedColumnNames.map((target, i) => {
        const container = {};
        container.target = this.availableColumnNames.find((col) => col.target === target);
        container.number = i + 1;
        return container;
      }).filter((item) => item.target !== undefined).map((item) => ({
        ...item,
        target: item.target?.target,
      }));

      const { data: { fileKey } } = await this.$store.dispatch(this.$types.LABORATORY_IMPORT_BRANCHES, {
        fileKey: this.fileGUID,
        legalPartnerId: this.data.partnerId,
        importType: this.data.importType,
        columns,
      });

      this.fileKeyAfterUpload = fileKey;
      this.data.importType = null;
      this.selectedColumnNames = this.generateSelectedColumnNames(COLS, null);
      this.saveFlag = true;
    },
    async onClickSave() {
      if (!this.saveFlag) {
        showMessage({
          type: 'warning',
          title: 'Ошибка',
          message: 'Скачивание доступно после загрузки файла',
        });
        return;
      }

      if (this.saveFlag) {
        await this.$store.dispatch(this.$types.GET_LABORATORY_DOWNLOAD_BRANCHES,
          { fileKey: this.fileKeyAfterUpload, fileName: this.fileName });
      }
    },
    async saveFileCallback(newFilesArray) {
      const [newFile] = newFilesArray;
      this.fileGUID = newFile.guid?.data?.fileKey;
      this.fileName = newFile.name;
      await this.getTableRows(10, true);
    },
    async getTableRows(limitRows, needClearSelectedColumns) {
      this.isLoadingFromFile = true;
      try {
        const { data: { rows, allRowsCount } } = await api.get(`v2/Excel/rows-new?key=${this.fileGUID}&take=${limitRows}`);
        this.totalRowsInFile = allRowsCount;
        const rowsCount = rows.length;
        const colsCount = rows[0].length;
        const rowsCountDelta = this.miniDisplayedRows - rowsCount;
        if (needClearSelectedColumns) {
          if (this.selectedColumnNames.length > rows[0].length) {
            this.selectedColumnNames = this.selectedColumnNames.slice(0, rows[0].length);
          } else if (this.selectedColumnNames.length < rows[0].length) {
            const newColumns = new Array(rows[0].length - this.selectedColumnNames.length).fill(null);
            this.selectedColumnNames = [...this.selectedColumnNames, ...newColumns];
          }
        }
        this.tableRows = rowsCountDelta > 0 ? [...rows, ...this.generateEmptyRows(rowsCountDelta, colsCount)] : rows;
      } catch (e) {
        console.log('cant get rows from file');
      }
      this.isLoadingFromFile = false;
    },
    handleScroll(vertical, horizontal) {
      const header = this.$refs['table-header'];
      const headerScrollValue = Math.round(horizontal.process * (header.scrollWidth - header.clientWidth));
      header.scrollLeft = headerScrollValue;
    },
    generateEmptyRows(rowsCount, colsCount) {
      const rows = new Array(rowsCount).fill([]);

      for (let i = 0; i < rowsCount; i += 1) {
        rows[i] = new Array(colsCount).fill('');
      }

      return rows;
    },
    showAllRows() {
      this.getTableRows(this.totalRowsInFile, false);
    },
    setMaxHeight(dropdownList, component, { width, top, left }) {
      dropdownList.style.maxHeight = `calc(100vh - ${top} - 20px)`;
      dropdownList.style.top = top;
      dropdownList.style.left = left;
      dropdownList.style.width = width;
    },
    selectColumnName(e, i) {
      this.selectedColumnNames[i] = e;
      this.filteredAvailableColumnNames = this.availableColumnNames.filter((columnName) => !this.selectedColumnNames.includes(columnName.target));
    },
  },
};
</script>
<style lang="scss" scoped>
  .import-branches{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
  }
  .crm-container{
    flex-direction: column;
  }

  .crm-header-block {
    margin-top: 20px;
    background: #FFF;
    width: 100%;
    box-shadow: 0px 1px 2px #C4C4C4;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  .crm-container-buttons {
    margin-top: 15px;
    display: flex;
    margin-left: auto;
  }

  .branches-table {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #C4C4C4;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 30px  55px 30px;

  * {
    box-sizing: border-box;
  }
}

.branches-table-wrapper {
  width: 100%;
}

.branches-table-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
  overflow: hidden;
  background-color: #fff;
}

.branches-table-header-cell {
  padding: 0 10px;
  width: 250px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: $blue;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  flex-shrink: 0;

  .crm-icon-dropdown {
    margin-left: 10px;
  }
}
.show-more-btn {
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    color: lighten($blue, 10);
  }
}

.branches-table-body {
  height: 350px;
  position: relative;
}

::v-deep.branches-table-row {
  display: flex;
  position: relative;
  flex: 1;
  border-bottom: 1px solid #E0E0E0;
}

.branches-table-button-row-delete {
  height: 40px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep.branches-table-cell {
  width: 250px;
  flex-shrink: 0;
  border: none;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  padding: 10px;

  &:focus {
    outline: 1px solid $blue;
    position: relative;
    z-index: 1;
  }

  & + .branches-table-cell {
    border-left: 1px solid #E0E0E0;
  }
}

.select-col-name {
  width: 250px;
}

::v-deep.select-col-name {
  .vs__dropdown-toggle {
    border: 0px !important;
    padding-bottom: 0px;
  }
  .vs__selected-options {
    align-items: center;

    input::placeholder {
      font-size: 16px;
      color: #6E88F3;
      font-weight: 500;
    }
  }
  .vs__selected {
    font-size: 16px;
    color: #6E88F3;
    font-weight: 500;
  }
}

.vuescroll-custom {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

</style>
