<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    title="Лимиты полиса"
    :no-close-on-backdrop="true"
    trigger="ok"
    size="xl"
    @ok="onClose"
  >
    <template v-if="isFetching">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <div class="text-right">
        <div class="mb-2">
          Сумма страхового покрытия: <b>{{ limitsData.insuranceCoverage }} р.</b>
        </div>
        <div class="mb-2">
          Общая сумма затрат: <b>{{ limitsData.sum }} р.</b>
        </div>
        <div class="mb-2">
          Остаток: <b>{{ limitsData.balance }} р.</b>
        </div>
        <b-button
          variant="primary"
          size="sm"
          @click="onOpenAllToggle"
        >
          {{ isAllOpen ? 'Свернуть' : 'Развернуть' }} всё
        </b-button>
      </div>

      <div class="mt-5">
        <div
          class="d-flex mb-3 align-items-center"
        >
          <div class="h4 mb-0">
            Гарантийные письма
          </div>

          <div
            v-if="limitsData.limits"
            class="text-right ml-auto"
          >
            Сумма ГП: <b>{{ limitsData.limits.sum }} р.</b>
          </div>

          <b-button
            variant="primary"
            class="ml-3"
            size="sm"
            style="line-height: 0;"
            @click="limitsData.limits.open = !limitsData.limits.open"
          >
            <b-icon
              icon="chevron-down"
              :flip-v="limitsData.limits.open"
            />
          </b-button>
        </div>

        <div
          v-if="limitsData.limits.open"
          class="pl-3 mb-5"
        >
          <div v-if="!limitsData.limits?.limitsByClinic?.length">
            Нет клиник
          </div>

          <div
            v-for="limitByClinic in limitsData.limits.limitsByClinic"
            v-else
            :key="limitByClinic.clinicId"
            class="mb-3"
          >
            <div class="d-flex align-items-center mb-3">
              <div class="h6 font-weight-bold mb-0">
                Клиника: {{ limitByClinic.clinicName }}
              </div>

              <b-button
                variant="primary"
                class="ml-auto px-2 py-1"
                style="line-height: 0;"
                size="sm"
                @click="limitByClinic.open = !limitByClinic.open"
              >
                <b-icon
                  icon="chevron-down"
                  :flip-v="limitByClinic.open"
                  font-scale="0.9"
                />
              </b-button>
            </div>

            <div v-if="limitByClinic.open">
              <b-table
                v-if="limitByClinic.limitsByIndemnityLetters?.length"
                :items="limitByClinic.limitsByIndemnityLetters"
                :fields="clinicLimitFields"
                bordered
                small
                foot-clone
              >
                <template #cell(sendDate)="data">
                  {{ new Date(data.item.sendDate).toLocaleString() }}
                </template>

                <template #cell(sum)="data">
                  {{ data.item.sum }} р.
                </template>

                <template #foot(documentNumber)>
                  Итого:
                </template>

                <template #foot(sendDate)>
                  {{ ' ' }}
                </template>

                <template #foot(sum)>
                  {{ limitByClinic.sum }} р.
                </template>
              </b-table>

              <div v-else>
                Нет лимитов
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="d-flex mb-3 align-items-center">
          <div class="h4 mb-0 mr-auto">
            Очные услуги
          </div>

          <div
            v-if="limitsData.intramuralServices?.sum"
            class="text-right"
          >
            Сумма лимитов очных услуг: <b>{{ limitsData.intramuralServices?.sum }} р.</b>
          </div>

          <b-button
            variant="primary"
            class="ml-3"
            style="line-height: 0;"
            size="sm"
            @click="limitsData.intramuralServices.open = !limitsData.intramuralServices.open"
          >
            <b-icon
              icon="chevron-down"
              :flip-v="limitsData.intramuralServices.open"
            />
          </b-button>
        </div>

        <div
          v-if="limitsData.intramuralServices.open"
          class="pl-3 mb-5"
        >
          <div v-if="!limitsData.intramuralServices?.intramuralServiceByLegalPerson?.length">
            Нет очных услуг
          </div>

          <div
            v-for="limitLegalPerson in limitsData.intramuralServices.intramuralServiceByLegalPerson"
            v-else
            :key="limitLegalPerson.legalPersonId"
            class="mb-3"
          >
            <div class="d-flex align-items-center mb-3">
              <div class="h6 font-weight-bold mb-0">
                Юр. лицо: {{ limitLegalPerson.legalPersonName }}
              </div>

              <b-button
                variant="primary"
                class="ml-auto px-2 py-1"
                style="line-height: 0;"
                size="sm"
                @click="limitLegalPerson.open = !limitLegalPerson.open"
              >
                <b-icon
                  icon="chevron-down"
                  :flip-v="limitLegalPerson.open"
                  font-scale="0.9"
                />
              </b-button>
            </div>

            <template v-if="limitLegalPerson.open">
              <b-table
                v-if="limitLegalPerson.intramuralServiceByRegisrty?.length"
                :items="limitLegalPerson.intramuralServiceByRegisrty"
                :fields="legalPersonLimitFields"
                bordered
                small
                foot-clone
              >
                <template #cell(date)="data">
                  {{ formatDate(data.item.date) }}
                </template>

                <template #cell(sum)="data">
                  {{ data.item.sum }} р.
                </template>

                <template #foot(date)>
                  Итого:
                </template>
                <template #foot(name)>
                  {{ '' }}
                </template>
                <template #foot(sum)>
                  {{ limitLegalPerson.sum }} р.
                </template>
              </b-table>

              <div v-else>
                Нет очных услуг
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <div class="d-flex mb-3 align-items-center">
          <div class="h4 mb-0 mr-auto">
            Телемедицинские консультации
          </div>

          <b-button
            variant="primary"
            class="ml-3"
            style="line-height: 0;"
            size="sm"
            @click="limitsData.telemedicineConsultations.open = !limitsData.telemedicineConsultations.open"
          >
            <b-icon
              icon="chevron-down"
              :flip-v="limitsData.telemedicineConsultations.open"
            />
          </b-button>
        </div>

        <template v-if="limitsData.telemedicineConsultations.open">
          <b-table
            v-if="limitsData.telemedicineConsultations?.telemedicineConsultations?.length"
            :items="limitsData.telemedicineConsultations.telemedicineConsultations"
            :fields="telemedLimitFields"
            bordered
            small
            foot-clone
            class="mb-5"
          >
            <template #cell(doctorPrice)="data">
              {{ data.item.doctorPrice }} р.
            </template>

            <template #foot(consultationId)>
              Итого:
            </template>
            <template #foot(doctorFIO)>
              {{ ' ' }}
            </template>
            <template #foot(doctorSpecialization)>
              {{ ' ' }}
            </template>
            <template #foot(doctorPrice)>
              {{ limitsData.telemedicineConsultations.sum }} р.
            </template>
          </b-table>
          <div
            v-else
            class="pl-3 mb-5"
          >
            Нет лимитов
          </div>
        </template>
      </div>

      <div class="mt-4">
        <div class="d-flex mb-3 align-items-center">
          <div class="h4 mb-0 mr-auto">
            Семейные лимиты
          </div>

          <b-button
            variant="primary"
            class="ml-3"
            style="line-height: 0;"
            size="sm"
            @click="limitsData.childrenLimits.open = !limitsData.childrenLimits.open"
          >
            <b-icon
              icon="chevron-down"
              :flip-v="limitsData.childrenLimits.open"
            />
          </b-button>
        </div>

        <template v-if="limitsData.childrenLimits.open">
          <b-table
            v-if="limitsData.childrenLimits?.length"
            :items="limitsData.childrenLimits"
            :fields="childrenLimitFields"
            bordered
            small
            foot-clone
          >
            <template #cell(sum)="data">
              {{ data.item.sum }} р.
            </template>

            <template #foot(fullName)>
              Итого:
            </template>
            <template #foot(sum)>
              {{ limitsData.childrenLimits.reduce((accum, l) => accum + +l.sum, 0) }} р.
            </template>
          </b-table>

          <div
            v-else
            class="pl-3 mb-5"
          >
            Нет лимитов
          </div>
        </template>
      </div>

      <div class="d-flex align-items-center mt-4 mb-3">
        <div class="h4 mb-0 mr-auto">
          Транспортные и прочие услуги
        </div>

        <b-button
          v-if="limitsOpen"
          variant="primary"
          class="ml-2"
          size="sm"
          :disabled="isLoading"
          @click="addNewLimit"
        >
          Добавить лимит
        </b-button>

        <b-button
          variant="primary"
          class="ml-3"
          style="line-height: 0;"
          size="sm"
          @click="limitsOpen = !limitsOpen"
        >
          <b-icon
            icon="chevron-down"
            :flip-v="limitsOpen"
          />
        </b-button>
      </div>

      <template v-if="limitsOpen">
        <b-table
          v-if="limits?.length"
          :items="limits"
          :fields="isEdit ? transportAndOtherFields : transportAndOtherFields.filter((field) => field.key != 'button')"
          small
          foot-clone
          bordered
        >
          <template #cell(name)="data">
            <b-form-input
              v-if="isEdit"
              v-model="limits[data.index].name"
              type="text"
              size="sm"
              placeholder="Введите наименование"
              :state="$v.limits.$each[data.index].name.required"
            />
            <div
              v-else
              class="p-2"
            >
              {{ data.item.name }}
            </div>
          </template>

          <template #cell(cost)="data">
            <b-form-input
              v-if="isEdit"
              v-model="limits[data.index].cost"
              type="number"
              size="sm"
              placeholder="Введите цену"
              :state="$v.limits.$each[data.index].cost.required"
            />
            <div
              v-else
              class="p-2"
            >
              {{ data.item.cost }} р.
            </div>
          </template>

          <template #cell(button)="data">
            <b-button
              v-if="isEdit"
              variant="danger"
              class="ml-auto"
              size="sm"
              @click="removeLimit(data.index)"
            >
              <b-icon icon="trash" />
            </b-button>
          </template>

          <template #foot(name)>
            <div class="text-right py-2">
              Итого:
            </div>
          </template>
          <template #foot(cost)>
            <div class="p-2">
              {{ limits.reduce((accum, l) => accum + +l.cost, 0) }} р.
            </div>
          </template>
        </b-table>

        <div
          v-else
          class="pl-3"
        >
          Нет лимитов
        </div>

        <div class="d-flex mt-3">
          <template v-if="isEdit">
            <b-button
              variant="success"
              class="ml-auto"
              size="sm"
              :disabled="isLoading"
              @click="onSave"
            >
              Сохранить
            </b-button>
            <b-button
              variant="primary"
              class="ml-2"
              size="sm"
              :disabled="isLoading"
              @click="onCancelEdit"
            >
              Отмена
            </b-button>
          </template>

          <template v-else>
            <b-button
              variant="primary"
              class="ml-auto"
              size="sm"
              :disabled="isLoading"
              @click="onEdit"
            >
              Редактировать
            </b-button>
          </template>
        </div>
      </template>
    </template>

    <template #modal-footer>
      <div class="d-flex align-items-center justify-content-between w-100">
        <b-button
          variant="success"
          class="ml-2"
          :disabled="isLoading || exportLoading"
          @click="onExport"
        >
          Экспорт
          <b-spinner
            v-if="exportLoading"
            variant="light"
            small
            label="Spinning"
          />
        </b-button>

        <b-button
          variant="primary"
          class="float-right"
          @click="onClose"
        >
          Закрыть
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import {
  format,
  parseISO,
} from 'date-fns';

import { showMessage, saveFile } from '@/helpers/utils';

import Preloader from '@/components/Preloader';

export default {
  name: 'PolicyLimitsModal',
  components: {
    Preloader,
  },
  mixins: [validationMixin],
  props: {
    policyId: {
      type: [String, Number],
      default: null,
    },
    personId: {
      type: [String, Number],
      default: null,
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      exportLoading: false,
      defaultLimits: [],
      limits: [],
      isFetching: false,
      isEdit: false,
      limitsOpen: false,
      clinicLimitFields: [
        {
          key: 'documentNumber',
          label: 'Номер ГП',
        },
        {
          key: 'sendDate',
          label: 'Дата отправки',
        },
        {
          key: 'sum',
          label: 'Сумма',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      legalPersonLimitFields: [
        {
          key: 'date',
          label: 'Дата',
        },
        {
          key: 'name',
          label: 'Наименование услуги',
        },
        {
          key: 'sum',
          label: 'Сумма',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      telemedLimitFields: [
        {
          key: 'consultationId',
          label: 'Номер консультации',
        },
        {
          key: 'doctorFIO',
          label: 'ФИО врача',
        },
        {
          key: 'doctorSpecialization',
          label: 'Специализация',
        },
        {
          key: 'doctorPrice',
          label: 'Стоимость',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      childrenLimitFields: [
        {
          key: 'fullName',
          label: 'ФИО',
        },
        {
          key: 'sum',
          label: 'Сумма',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
      transportAndOtherFields: [
        {
          key: 'name',
          label: 'Наименование',
        },
        {
          key: 'cost',
          label: 'Цена',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'button',
          label: '',
          thStyle: { width: '45px' },
        },
      ],
      limitsData: null,
    };
  },
  validations() {
    return {
      limits: {
        $each: {
          name: {
            required,
          },
          cost: {
            required: (value) => {
              const isNum = /^\d+$/.test(value);
              return isNum && value >= 0;
            },
          },
        },
      },
    };
  },
  computed: {
    isAllOpen() {
      return this.limitsData.limits.open
        && this.limitsData.intramuralServices.open
        && this.limitsData.telemedicineConsultations.open
        && this.limitsData.childrenLimits.open
        && this.limitsOpen;
    },
  },
  async created() {
    this.fetchLimits();
  },
  methods: {
    formatDate(date) {
      return date ? format(parseISO(date), 'dd.MM.yyyy') : '';
    },
    async fetchLimits() {
      this.isEdit = false;
      this.isFetching = true;
      this.limits = await this.$store.dispatch(this.$types.PATIENT_LIMITS_FETCH, {
        personId: this.personId,
        policyId: this.policyId,
      });
      this.defaultLimits = JSON.parse(JSON.stringify(this.limits));

      const limitsData = await this.$store.dispatch(this.$types.PATIENT_LIMITS_BY_PERSON_ID_FETCH, {
        personId: this.personId,
        policyId: this.policyId,
      });
      this.limitsData = {
        ...limitsData,
        limits: {
          ...limitsData.limits,
          open: false,
          limitsByClinic: limitsData?.limits?.limitsByClinic?.map((clinic) => ({
            ...clinic,
            open: false,
          })),
        },
        intramuralServices: {
          ...limitsData.intramuralServices,
          intramuralServiceByLegalPerson: limitsData?.intramuralServices?.intramuralServiceByLegalPerson?.map((person) => ({
            ...person,
            open: false,
          })),
          open: false,
        },
        telemedicineConsultations: {
          ...limitsData.telemedicineConsultations,
          open: false,
        },
        childrenLimits: {
          ...limitsData.childrenLimits,
          open: false,
        },
      };

      this.isFetching = false;
    },
    onOpenAllToggle() {
      const { isAllOpen } = this;
      this.limitsData.limits.open = !isAllOpen;
      this.limitsData.intramuralServices.open = !isAllOpen;
      this.limitsData.telemedicineConsultations.open = !isAllOpen;
      this.limitsData.childrenLimits.open = !isAllOpen;
      this.limitsOpen = !isAllOpen;
    },
    addNewLimit() {
      const newLimit = {
        id: 0,
        personId: this.personId,
        policyId: this.policyId,
        name: null,
        cost: null,
      };

      this.limits.push(newLimit);
      this.isEdit = true;
    },
    removeLimit(index) {
      this.limits.splice(index, 1);
    },
    onEdit() {
      this.isEdit = true;
    },
    async onCancelEdit() {
      this.isEdit = false;
      this.limits = JSON.parse(JSON.stringify(this.defaultLimits));
    },
    async onExport() {
      this.exportLoading = true;

      try {
        const { data: file } = await this.$store.dispatch(this.$types.PATIENT_ALL_LIMITS_EXPORT, {
          personId: this.personId,
          policyId: this.policyId,
        });
        saveFile(file, { name: 'limits.xlsx' });
      } catch {
        showMessage({
          type: 'error',
          message: 'Не удалось экспортировать лимиты',
        });
      } finally {
        this.exportLoading = false;
      }
    },
    async onSave() {
      this.$v.$touch();
      if (this.$v.$error) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Проверьте правильность заполнения полей',
        });
        return;
      }

      try {
        this.isLoading = true;
        await this.$store.dispatch(this.$types.PATIENT_LIMITS_SAVE, {
          personId: this.personId,
          limits: this.limits,
        });

        this.fetchLimits();
      } finally {
        this.isLoading = false;
      }
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
