<template>
  <div class="containerLabsPage w-100">
    <custom-scrollbar>
      <b-container fluid>
        <b-overlay
          v-if="isLoading"
          :show="isLoading"
          no-wrap
          variant="transparent"
        />
        <transition
          mode="out-in"
          name="component-fade"
        >
          <div
            v-if="!isLoading"
            class="pt-4"
          >
            <div class="d-flex mb-4">
              <router-link
                class="mr-4"
                to="/laboratory-orders"
              >
                <ArrowIcon />
              </router-link>
              <h4 class="font-weight-bold">
                Информация о заказе<span style="color: #0D6EFD"> № {{ currentOrderData.number }}</span>
              </h4>
            </div>
            <b-row>
              <b-col>
                <b-form-group
                  label="Статус заказа"
                  label-class="font-weight-bold pt-0"
                >
                  <b-form-select
                    v-model="selectedStatus"
                    :options="statusOptions"
                    class="w-50"
                    disabled
                    size="sm"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Адрес"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.branch.address }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Время создания"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ createDateFormat(currentOrderData.createdAt) }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="ФИО"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.person.lastName }}
                  </span>
                  <span>
                    {{ currentOrderData.person.firstName }}
                  </span>
                  <span>
                    {{ currentOrderData.person.middleName }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Юридическое лицо"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.branch.legalPartner.legalName }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Ключ оплаты"
                  label-class="font-weight-bold pt-0"
                >
                  <span>
                    {{ currentOrderData.paymentOrderId }}
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Информация о гарантийных письмах"
                  label-class="font-weight-bold pt-0"
                >
                  <b-row>
                    <b-col>
                      <div
                        v-for="(item, id) in currentOrderData.guaranteeLetters"
                        :key="id"
                        class="mt-2"
                      >
                        <b-button
                          :id="`popover-1-${id}`"
                          class="d-flex align-items-center"
                          size="sm"
                          variant="outline-primary"
                          @click="onClickDownloadGuarantee(item)"
                        >
                          <b-icon
                            class="mr-2"
                            font-scale="0.85"
                            icon="file-earmark-fill"
                          />
                          Гарантийное письмо
                        </b-button>
                        <b-popover
                          :target="`popover-1-${id}`"
                          placement="righttop"
                          triggers="hover focus"
                          :content="`${item.originalName}`"
                        />
                      </div>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col>
                <b-form-group>
                  <h5 class="font-weight-bold">
                    Информация о заказе<span style="color: #0D6EFD"> № {{ currentOrderData.number }}</span>
                  </h5>
                  <b-table
                    :fields="fields"
                    :items="currentOrderData.orderItems"
                    :small="true"
                    bordered
                    class="bg-white border-secondary mt-4"
                    hover
                  >
                    <template #cell(actions)="row">
                      <b-button
                        v-if="row.item.analysis"
                        size="sm"
                        variant="outline-primary"
                        @click="openUploadOrderAnalysisModal(row.item)"
                      >
                        <b-icon
                          class="mr-2"
                          font-scale="1"
                          icon="check2"
                        />
                        Загружен анализ
                      </b-button>
                      <b-button
                        v-else
                        size="sm"
                        variant="primary"
                        @click="openUploadOrderAnalysisModal(row.item)"
                      >
                        <b-icon
                          class="mr-2"
                          font-scale="1"
                          icon="upload"
                        />
                        Загрузите анализ
                      </b-button>
                    </template>
                    <template #cell(cost)="row">
                      <span v-if="row.item.cost !== null">
                        {{ `${row.item.cost}р` }}
                      </span>
                    </template>
                  </b-table>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-button
                    v-if="checkFeatureAccess({ name: 'Завершить заказ', url: '/laboratory-order-info/:id' })"
                    class="mr-2"
                    size="sm"
                    variant="primary"
                    @click="onClickCompleteOrder"
                  >
                    Завершить заказ
                  </b-button>
                  <b-button
                    v-if="checkFeatureAccess({ name: 'Отменить заказ', url: '/laboratory-order-info/:id' })"
                    size="sm"
                    variant="danger"
                    @click="onClickCancelOrder"
                  >
                    Отменить заказ
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </transition>
      </b-container>
    </custom-scrollbar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import * as types from '@/store/types';
import { format, parseISO } from 'date-fns';
import ArrowIcon from 'assets/images/indemnity-arrow-icon.svg';
import { mixinRoles } from '@/mixins';

export default {
  name: 'LaboratoryOrderInfo',
  components: { ArrowIcon },
  mixins: [validationMixin, mixinRoles],
  props: {
    orderId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedStatus: null,
      fields: [
        {
          key: 'service.code',
          label: 'Код',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'service.name',
          label: 'Наименование услуги',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'cost',
          label: 'Цена',
          sortable: true,
          tdClass: 'align-middle',
          class: 'text-center',
        },
        {
          key: 'actions',
          label: 'Загрузка анализов',
          tdClass: 'align-middle',
          class: 'text-center',
        },
      ],
      statusOptions: [
        {
          value: null,
          text: 'Выберете статус заказа',
        },
        {
          value: 'WaitPay',
          text: 'Ожидает оплаты',
        },
        {
          value: 'Paid',
          text: 'Оплаченный',
        },
        {
          value: 'Completed',
          text: 'Завершенный',
        },
        {
          value: 'Canceled',
          text: 'Отмененный',
        },
      ],
    };
  },
  validations: {
    serviceName: { required },
    serviceCode: { required },
    serviceDescription: { required },
  },
  computed: {
    serviceListStore() {
      return this.$store.state.Laboratories.serviceList;
    },
    currentOrderData() {
      return this.$store.state.Laboratories.currentOrder.data;
    },
    ...mapGetters({
      partnerServicesList: types.PARTNER_SERVICES_LIST_GET,
    }),
  },
  async created() {
    this.isLoading = true;

    // await this.fetchPartnerServices();
    await this.currentOrderDataFetch();
    // console.log(this.currentOrderStore);
    this.selectedStatus = this.currentOrderData.status;

    this.isLoading = false;
  },
  beforeDestroy() {
    this.$store.commit(this.$types.CURRENT_ORDER_SET, []);
  },
  methods: {
    async currentOrderDataFetch() {
      await this.$store.dispatch(this.$types.GET_LABORATORY_ORDER_FOR_ID, {
        orderId: this.orderId,
        save: true,
      });
    },
    onClickDownloadGuarantee(item) {
      this.$store.dispatch(this.$types.LABORATORY_DOWNLOAD_GUARANTEE, item);
    },
    async updateOrderStatus() {
      await this.$store.dispatch(this.$types.LABORATORY_ORDER_STATUS_UPDATE,
        {
          id: this.currentOrderData.id,
          status: this.selectedStatus,
        });
      await this.currentOrderDataFetch();
    },
    async onClickCancelOrder() {
      const data = await this.$store.dispatch(this.$types.LABORATORY_ORDER_CANCEL, this.currentOrderData.id);

      console.log(data);
      if (data.status === 'success') {
        await this.$router.push('/laboratory-orders');
      }
    },
    async onClickCompleteOrder() {
      const data = await this.$store.dispatch(this.$types.LABORATORY_ORDER_COMPLETE,
        this.currentOrderData.id);
      if (data.status === 'success') {
        await this.$router.push('/laboratory-orders');
      }
    },
    uploadDateFormat(row) {
      return row ? format(parseISO(row), 'dd.MM.yyyy') : null;
    },
    createDateFormat(row) {
      return row ? format(parseISO(row), 'dd.MM.yyyy') : null;
    },
    openUploadOrderAnalysisModal(row) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'UploadOrderAnalysis',
        props: {
          analysisId: row.analysis ? row.analysis.id : null,
          fileName: row.analysis ? row.analysis.originalName : null,
          orderItemId: row.id,
          orderId: row.orderId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
