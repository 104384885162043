<template>
  <b-modal
    v-model="isOpen"
    scrollable
    centered
    size="lg"
    :title="'Создание нового города'"
    :hide-footer="isLoading"
    @hidden="onClose"
    @ok="onClose"
  >
    <template v-if="isLoading">
      <preloader style="margin-top: 20px" />
    </template>

    <template v-else>
      <b-row>
        <b-col>
          <b-form-group
            label="Полное название города"
          >
            <b-form-input
              v-model="cityData.fullName"
              type="text"
              :placeholder="'Введите полное название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Название города"
            label-class="required"
          >
            <b-form-input
              v-model="cityData.name"
              type="text"
              :placeholder="'Введите название города'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Широта"
          >
            <b-form-input
              v-model="cityData.latitude"
              type="text"
              :placeholder="'Введите широту'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Долгота"
          >
            <b-form-input
              v-model="cityData.longitude"
              type="text"
              :placeholder="'Введите долготу'"
              trim
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>

    <template #modal-footer>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="isLoading"
        @click="onCreateCity"
      >
        Сохранить город
      </b-button>
      <b-button
        variant="danger"
        class="float-right ml-2"
        :disabled="isLoading"
        @click="onClose"
      >
        Отмена
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import {
//   // formatISO,
//   format,
// } from 'date-fns';
import { showMessage } from '@/helpers/utils';
import Preloader from '@/components/Preloader';

export default {
  name: 'CityCreateModal',
  components: {
    Preloader,
  },
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isLoading: false,
      cityData: {
        name: '',
        fullName: '',
        longitude: null,
        latitude: null,
      },
    };
  },
  methods: {
    async onCreateCity() {
      if (!this.cityData.name) {
        showMessage({
          type: 'warning',
          title: 'Ошибка валидации',
          message: 'Заполните обязательные поля',
        });

        return;
      }
      await this.$store.dispatch(this.$types.CITY_CREATE, this.cityData);
      this.onClose();
    },
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordBtn:focus {
  box-shadow: none;
}
</style>
