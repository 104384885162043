<template>
  <div
    class="clinic-doctors-edit-wrapper"
  >
    <div class="clinics-doctors-scroll-content">
      <custom-scrollbar>
        <div
          class="clinic-doctors-edit"
        >
          <Loading
            v-if="isLoading"
            class="loading-wrapper"
          >
            Загрузка клиники
          </Loading>

          <template v-else>
            <!-- v-if="!checkRoleAccess_(['ClinicAdministrator', 'MedicalCurator', 'Operator', 'Tech.operator'])" -->
            <template v-if="checkFeatureAccess({ name: 'Редактирование врачей клиники', url: '/clinics/:clinicId' })">
              <icon-edit
                v-if="isEdit"
                class="crm-icon edit-btn active"
                @click="onClickCancel"
              />
              <icon-edit
                v-else
                class="crm-icon edit-btn"
                @click="editDoctors"
              />
            </template>

            <template v-if="false">
              <div class="clinic-subtitle">
                Специализации для очного приема:
              </div>
              <div
                v-if="isEdit"
                class="clinics-speciality-list"
              >
                <speciality-item
                  v-for="(specialization, index) in specializationsEditData"
                  :key="index"
                  :speciality="specialization"
                  edit
                  class="clinics-speciality"
                  @delete="deleteSpecialization(specialization)"
                />
                <base-async-select
                  :value="specialityNull"
                  :fetch-function="fetchSpecializations"
                  placeholder="Добавить специализацию"
                  class="clinics-speciality specialization-select"
                  fluid
                  @change="selectSpecialization($event)"
                />
              </div>
              <template v-else>
                <div
                  class="clinics-speciality-list"
                >
                  <speciality-item
                    v-for="(specialization, index) in sortedSpecializations"
                    :key="index"
                    :speciality="specialization"
                    class="clinics-speciality"
                    @delete="deleteSpecialization(specialization)"
                  />
                </div>

                <div
                  v-if="sortedSpecializations.length === 0"
                  class="empty-text"
                >
                  Не найдено
                </div>
              </template>
            </template>

            <div class="clinic-subtitle clinic-subtitle--doctors">
              Врачи по телемедицине:
            </div>
            <div
              v-if="isEdit"
              class="clinics-doctor-list"
            >
              <doctor-info-item
                v-for="doctor in doctorsEditData"
                :key="doctor.id"
                class="doctor-item"
                :doctor="doctor"
                edit
                @toggle-pin="togglePin(doctor)"
              />

              <div
                class="doctor-add-btn"
                @click="openPinModal"
              >
                Привязать врача
              </div>
            </div>

            <template v-else>
              <div
                class="clinics-doctor-list"
              >
                <doctor-info-item
                  v-for="doctor in doctors"
                  :key="doctor.id"
                  class="doctor-item"
                  :doctor="doctor"
                  @toggle-pin="togglePin(doctor)"
                />
              </div>

              <div
                v-if="doctors.length === 0"
                class="empty-text"
              >
                Не найдено
              </div>
            </template>

            <div
              v-if="isEdit"
              class="crm-wrapper-buttons crm-wrapper-buttons_clinic-edit"
            >
              <base-button
                :type="$const.SECONDARY_BUTTON"
                class="crm-button"
                @click="onClickCancel"
              >
                Отменить
              </base-button>

              <base-button
                :type="$const.PRIMARY_BUTTON"
                :loading="isSaving"
                class="crm-button"
                @click="onClickSave"
              >
                Сохранить
              </base-button>
            </div>
          </template>
        </div>
      </custom-scrollbar>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import { alphabeticSort } from '@/helpers/utils';

import { sortBy } from 'lodash';

import Bus from '@/eventBus';
import { mixinRoles } from '@/mixins';
import Loading from '@/components/Loading';

import { BaseButton, BaseAsyncSelect } from '@/components/base';
import IconEdit from 'assets/images/edit_icon.svg';

import DoctorInfoItem from './DoctorInfoItem';
import SpecialityItem from './SpecialityItem';

export default {
  name: 'ClinicDoctors',
  components: {
    BaseButton,
    BaseAsyncSelect,
    IconEdit,
    SpecialityItem,
    DoctorInfoItem,
    Loading,
  },
  mixins: [mixinRoles],
  props: {},
  data() {
    return {
      unwatch: null,
      specialityNull: null,
      isSaving: false,
      specializationsEditData: [],
      doctorsEditData: [],
    };
  },
  computed: {
    clinicInfo() {
      return this.$store.state.Clinic.clinic.info;
    },
    specializations() {
      return this.$store.state.Clinic.clinic.specializations || [];
    },
    doctors() {
      return sortBy(this.$store.state.Clinic.clinic.doctors, ['lastName', 'firstName', 'middleName']);
    },
    isChangedDoctorsInfo() {
      return this.$store.state.Clinic.isChangedClinicDoctorsInfo;
    },
    sortedSpecializations() {
      return [...this.specializations].sort((a, b) => alphabeticSort(a.title, b.title));
    },
    resultDoctorsInfo() {
      return {
        specializations: this.specializationsEditData,
        doctors: this.doctorsEditData,
      };
    },
    isEdit: {
      get() {
        return this.$store.state.Clinic.isEditClinicDoctors;
      },
      set(newVal) {
        this.$store.commit(this.$types.CLINIC_DOCTORS_IS_EDIT_SET, newVal);
      },
    },
    isLoading: {
      get() {
        return this.$store.state.Clinic.isClinicLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.CLINIC_IS_LOADING_SET, newVal);
      },
    },
  },
  watch: {
    doctors() {
      this.doctorsEditData = [...this.doctors];
    },
    doctorsEditData() {
      this.$store.commit(this.$types.CLINICS_PINNED_DOCTORS_SET, [...this.doctorsEditData]);
    },
  },
  methods: {
    onClickCancel() {
      if (this.isChangedDoctorsInfo) {
        if (!confirm('Несохраненная информация будет удалена, продолжить?')) {
          return;
        }
      }

      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);
      this.isEdit = false;
      this.specializationsEditData = [];
      this.doctorsEditData = [];
    },
    async onClickSave() {
      this.setWatcher();

      const preformattedClinicInfo = {
        ...this.clinicInfo,
        specializations: this.specializationsEditData,
        doctors: this.doctorsEditData,
      };

      try {
        this.isSaving = true;
        const clinicInfo = await this.$store.dispatch(this.$types.CLINIC_UPDATE, preformattedClinicInfo);
        this.$store.commit(this.$types.CLINIC_SET, { ...clinicInfo, info: clinicInfo });

        this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);

        // что бы обновлялось после сохранения (временное решение)
        await this.$emit('updateClinicInfo');

        this.isEdit = false;
        this.specializationsEditData = [];
        this.doctorsEditData = [];
      } finally {
        this.isSaving = false;
      }
    },
    openPinModal() {
      Bus.$emit('open-modal:doctors-pin', this.togglePin);
    },
    setWatcher() {
      this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, false);
      this.unwatch = this.$watch('resultDoctorsInfo',
        () => {
          if (this.unwatch) {
            this.unwatch();
          }
          this.$store.commit(this.$types.CLINICS_SET_IS_CHANGED_CLINIC_DOCTORS_INFO, true);
        },
        {
          deep: true,
        });
    },
    deleteSpecialization(spec) {
      this.specializationsEditData = this.specializationsEditData.filter((item) => item.id !== spec.id);
    },
    async fetchSpecializations(query) {
      const data = await this.$store.dispatch(this.$types.SPECIALIZATIONS_SEARCH, query);

      return data;
    },
    selectSpecialization({ id, name }) {
      const isSpecializaitonSelected = this.specializationsEditData.find((spec) => spec.id === id);
      if (isSpecializaitonSelected) return;

      this.specializationsEditData = [...this.specializationsEditData, { id, title: name }];
    },

    editDoctors() {
      this.isEdit = true;
      this.specializationsEditData = [...this.sortedSpecializations];
      this.doctorsEditData = [...this.doctors];

      this.setWatcher();
    },
    togglePin(doctor) {
      if (this.doctorsEditData.find((doc) => doc.id === doctor.id)) {
        this.doctorsEditData = this.doctorsEditData.filter((doc) => doc.id !== doctor.id);
      } else {
        this.doctorsEditData = [...this.doctorsEditData, doctor];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic-doctors-edit {
  width: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

::v-deep.edit-btn {
  border-radius: 5px;
  margin-left: auto;
  display: block;
  cursor: pointer;

  &.active {
    background-color: $blue;

    path {
      fill: white;
    }
  }
}

.clinic-subtitle {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #707070;
  margin-bottom: 10px;

  &--doctors {
    margin-top: 20px;
  }
}

.clinics-speciality-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.clinics-speciality {
  margin: 5px;
}
.specialization-select {
  max-width: 300px;
}

.clinics-doctor-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.doctor-item {
  width: calc(50% - 20px);
  margin: 5px 10px;
  box-sizing: border-box;
}

.doctor-add-btn {
  margin: 5px 10px;
  padding: 10px 15px;
  border: none;
  background-color: none;
  color: #6E88F3;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  align-self: center;
}
</style>
