<template>
  <div class="login">
    <div class="login-form">
      <img
        class="logo"
        :src="require(`assets/images/${logo}.svg?inline`)"
      >

      <div class="crm-input-block">
        <base-input
          v-model="$v.login.$model"
          :error="$v.login.$error"
          :errors="loginErrors"
          :input-style="{
            padding: '21px 20px',
          }"
          placeholder="Логин"
          class="login-form__input"
          fluid
        />

        <base-input
          ref="password"
          v-model="$v.password.$model"
          :error="$v.password.$error"
          :errors="passwordErrors"
          :input-style="{
            padding: '21px 20px',
          }"
          :type="showPassword ? 'text' : 'password'"
          placeholder="Пароль"
          class="login-form__input"
          fluid
          v-on="{
            blur: onblurPassword,
          }"
        >
          <template
            v-slot:suffix
          >
            <icon-eye
              :class="[ 'icon-eye', { active: showPassword }]"
              @click="toggleShowPassword"
            />
          </template>
        </base-input>
      </div>

      <base-checkbox
        v-model="saveAuth"
        class="login-form__checkbox"
        align-top
      >
        Сохранить логин и пароль для входа в профиль
      </base-checkbox>

      <base-button
        :type="$const.SECONDARY_BUTTON"
        class="login-form__button"
        @click="signIn"
      >
        ВОЙТИ
      </base-button>

      <hr>

      <div class="gosuslugi">
        <p class="gosuslugi-text">
          или авторизоваться через личный кабинет портала «Госуслуги»:
        </p>

        <div
          class="gosuslugi-button"
          @click="openEsiaAuth"
        >
          <div>войти через портал</div>
          <IconEsia />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BaseButton,
  BaseInput,
  BaseCheckbox,
} from '@/components/base';

import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { showMessage, domain } from '@/helpers/utils';

import IconEye from 'assets/images/eye_1.svg';
import IconEsia from 'assets/images/esia.svg';

export default {
  name: 'Login',
  page: {
    title: 'CRM Doctis - Вход',
  },
  components: {
    BaseInput,
    BaseButton,
    BaseCheckbox,
    IconEye,
    IconEsia,
  },
  mixins: [validationMixin],
  props: {
    redirectFrom: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      login: '',
      password: '',
      saveAuth: true,
      showPassword: false,
      passwordSelection: {
        start: 0,
        end: 0,
      },
    };
  },
  validations: {
    login: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    logo() {
      return domain.logo;
    },
    loginErrors() {
      const errors = [];

      if (!this.$v.login.required) {
        errors.push('Поле не может быть пустым');
      }

      return errors;
    },
    passwordErrors() {
      const errors = [];

      if (!this.$v.password.required) {
        errors.push('Поле не может быть пустым');
      }

      return errors;
    },
  },
  async created() {
    if (this.$route.query?.token) {
      await this.signInWithToken();
    }
    if (this.$route.query?.error) {
      showMessage({
        type: 'error',
        title: 'Ошибка',
        message: this.$route.query?.error,
      });
    }
  },
  methods: {
    async focusPassword() {
      this.$refs.password.$refs.input.focus();
      await this.$nextTick();
      this.$refs.password.$refs.input.setSelectionRange(this.passwordSelection.start, this.passwordSelection.end);
    },
    async toggleShowPassword() {
      this.showPassword = !this.showPassword;
      await this.$nextTick();
      this.focusPassword();
    },
    onblurPassword(event) {
      this.passwordSelection.start = event.target.selectionStart;
      this.passwordSelection.end = event.target.selectionEnd;
    },
    openEsiaAuth() {
      this.$store.dispatch(this.$types.LOGIN_ESIA, {
        redirectUrl: window.location.origin + window.location.pathname,
      });
    },
    async signIn() {
      this.$v.$touch();
      if (this.$v.$error) return;

      try {
        await this.$store.dispatch(this.$types.LOGIN, {
          login: this.login,
          password: this.password,
          saveAuth: this.saveAuth,
        });

        // this.$router.push(this.redirectFrom || '/');
        this.$router.push('/');
      } catch (err) {
        console.error(1, err?.response?.data?.error);

        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const error = err?.response?.data?.error || '';
        const errorWithLink = error.replace(urlRegex, (url) => `<a href="${url}" target="_blank">${url}</a>`);

        showMessage({
          type: 'error',
          title: 'Ошибка',
          message: errorWithLink,
        });
      }
    },
    async signInWithToken() {
      try {
        const jwtToken = this.$route.query?.token;
        const redirectPath = this.$route.query?.redirect;
        await this.$store.dispatch(this.$types.LOGIN, { saveAuth: this.saveAuth, jwtToken });
        this.$router.push(redirectPath || '/');
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  @include default-flex-prop;
  width: 100%;
  height: 100%;
}
.login-form {
  color: $black-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 70px;
  width: 430px;
  background: $white;
  box-shadow: 0px 1px 2px $gray-shadow;
  box-sizing: border-box;
  border-radius: 4px;
}
.logo {
  width: 100%;
  max-height: 70px;
  margin-bottom: 50px;
}
.crm-input-block {
  width: 100%;
}
.login-form__input:first-child {
  margin-bottom: 25px;
}
.login-form__button {
  margin-top: 30px;
  font-weight: 500;
  font-size: 16px;
}
.login-form__checkbox {
  margin-top: 20px;
  width: 100%;
}
::v-deep.icon-eye {
  cursor: pointer;

  &:hover,
  &.active {
    path {
      fill: $blue;
    }
  }
}

.gosuslugi {
  font-size: 10px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;

  p {
    margin: 0 -30px;
    color: #888888;
  }
}

.gosuslugi-button {
    border: 1px solid #0063b0;
    height: 42px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 226px;
    background: #ffffff;
    border-radius: 5px;
    margin: 10px auto 0;
    cursor: pointer;

    div {
      margin-bottom: 3px;
      font-size: 10px;
      color: #0063b0;
    }
}
</style>
