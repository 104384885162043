<template>
  <!--
    @image-added="handleImageAdded"
-->
  <vue-editor
    v-model="model"
    :disabled="disabled"
    :editor-options="editorSettings"
    :editor-toolbar="customToolbar"
    @blur="$emit('blur', $event)"
  />
</template>

<script>
import { VueEditor } from 'vue2-editor';
// import { ImageDrop } from 'quill-image-drop-module';
// import ImageResize from 'quill-image-resize-vue';

// import { imagesMixin } from '@/mixins/filesMixin';
// import { IMAGE_URL } from '@/utils/constants/filesConstants';
//
// Quill.register("modules/imageDrop", ImageDrop);
// Quill.register("modules/imageResize", ImageResize);

export default {
  name: 'TextEditor',
  components: {
    VueEditor,
  },

  props: {
    value: {
      type: String,
      default: '<p>Здесь вы можете модифицировать текст</p>',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customToolbar: {
      type: Array,
      default: () => ([
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['my'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ]),
    },
  },
  data: () => ({
    editorSettings: {
      modules: {},
    },
  }),

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(newValue) {
        return this.$emit('input', newValue);
      },
    },
  },

  methods: {
    // async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
    //   const imageFilename = await this.uploadImage(file);
    //
    //   if (!imageFilename) return;
    //
    //   const imageUrl = IMAGE_URL + '/' + imageFilename
    //
    //   Editor.insertEmbed(cursorLocation, 'image', imageUrl);
    //   resetUploader();
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ql-container {
  max-height: 500px;
  overflow: auto;
}

::v-deep .ql-disabled {
  opacity: 0.5;
}

::v-deep img {
  display: block;
  margin: 20px 0;
}
</style>
