<template>
  <b-modal
    v-model="isOpen"
    centered
    :title="title"
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="p-2 content-container">
      <BannerItem
        :banner="banner"
        :prop-image-url="propImageUrl"
      />
    </div>

    <template #modal-footer>
      <b-button
        class="float-right"
        variant="danger"
        :disabled="loading"
        @click="onClose"
      >
        Отмена
      </b-button>

      <b-button
        variant="primary"
        class="float-right"
        :disabled="loading"
        @click="onSave"
      >
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import BannerItem from '@/components/BannerManagement/BannerItem';

export default {
  name: 'BannerPreviewModal',
  components: {
    BannerItem,
  },

  props: {
    title: {
      type: String,
      default: 'Превью баннера',
    },
    modalName: {
      type: [String, Number],
      default: null,
    },
    cb: {
      type: Function,
      default: () => {},
    },
    cbArgs: {
      type: Array,
      default: () => ([]),
    },
    banner: {
      type: Object,
      default: () => ({}),
    },
    propImageUrl: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isOpen: true,
    loading: false,
  }),

  methods: {
    onClose() {
      this.$store.commit(this.$types.CLOSE_MODAL, { modalName: this.modalName });
    },
    async onSave() {
      this.loading = true;

      try {
        await this.cb(...this.cbArgs);
      } finally {
        this.onClose();
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.content-container {
  background-color: ghostwhite;
}
</style>
