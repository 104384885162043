import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
  namespaced: true,
  state: {
    doctors: [],
    consultations: [],
    isAllItemsLoaded: true,
    isLoading: false,
    isDeleted: false,
    query: '',
  },
  actions,
  getters,
  mutations,
};
