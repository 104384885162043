import AxiosClientService from '@/services/core/axios/axios';
import { APP_HOST } from '@/app.config';

class MedicalOrganizationApi {
  constructor() {
    this.api = AxiosClientService.instance;
  }
  /**
   *  Метод для получения списка медицицинских организаций из справочника НСИ
   * «Реестр медицинских организаций Российской Федерации» (OID 1.2.643.5.1.13.13.11.1461)
   * @returns
   */

  async getNSIList(params) {
    const { data: { data } } = await this.api.get(`${APP_HOST}v3/reference-books/medical-organization`, { params });
    return data;
  }
}
export default MedicalOrganizationApi;
